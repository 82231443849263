import React, { Component } from 'react';
import { Admin } from './Admin';

import { Button, Col, FormGroup, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Question = <FontAwesomeIcon icon={faQuestionCircle} className="avensRed" />;

const OBJECT = 'object';

export class Admins extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: this.props.counter || 0,
            admins: [],
            lang: this.props.lang || 'fr',
            usersList: [],
        };
        this.addHeirForm = this.addHeirForm.bind(this);
        this.removeHeirForm = this.removeHeirForm.bind(this);
    }

    genLang(key = 'missing') {
        const lang = (this.state.lang === '' || this.state.lang === null || this.state.lang === undefined) ? 'fr' : this.state.lang;

        if (typeof key === OBJECT) {
            if (lang && key[lang]) {
                return key[lang];
            } else {
                return '';
            }
        } else {
            if (!this.props.trad) {
                return key;
            }

            if (!this.props.trad[key]) {
                return this.props.trad['wrongKey'][lang] + ' ' + key;
            }

            return this.props.trad[key][lang];
        }

    }

    componentDidUpdate(prevProps) {
        // console.log(this)
        let props = this.props;
        // debugger;
        if (props.formData && JSON.stringify(props.formData) !== JSON.stringify(prevProps.formData) || this.state.force) {

            let fd = this.props.formData;
            let obj = {};
            let reg = new RegExp('^' + this.props.name);
            const usersList = [];

            for (let f in fd) {
                if (f.match(reg)) {
                    let split = f.split(reg);
                    obj[split[1].match(/\d+/)[0]] = true;
                }
            }

            let arr = [];

            for (let h in obj) {
                let key = this.props.name + '_' + h;
                usersList.push({
                    key: key,
                    counter: h
                });

                arr.push(
                    <div key={key}>
                        <Row>
                            <Admin
                                add={this.props.add}
                                public={this.props.public}
                                ticket={this.props.ticket}
                                target={this.props.target}
                                address={this.props.address}
                                version={this.props.version}
                                put={this.props.put}
                                lang={this.state.lang}
                                trad={this.props.trad}
                                formData={{ ...this.props.formData }}
                                remove={this.removeHeirForm}
                                name={key}
                                counter={h}
                                noCard={this.props.noCard}
                                isPrivate={this.props.isPrivate}
                                title={this.props.title}
                                toggleLoading={this.props.toggleLoading}
                                pushWaitingList={
                                    this.props.pushWaitingList
                                }
                                pullWaitingList={
                                    this.props.pullWaitingList
                                }
                            >
                            </Admin>
                        </Row>
                    </div>
                );
            }
            // debugger;
            this.setState({
                admins: arr,
                counter: arr.length + 10,
                force: false
                ,
                usersList: usersList
            });
        }

        if (props.lang !== prevProps.lang) {

            let obj = {};

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }
            if (!_.isEmpty(obj)) {
                this.setState(obj);
            }
        }
    }

    componentDidMount() {
        // set Default Data in here :)
        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);
        const usersList = [];

        for (let f in fd) {
            if (f.match(reg)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }

        let arr = [];
        for (let h in obj) {
            let key = this.props.name + '_' + h;
            usersList.push({
                key: key,
                counter: h
            });
            arr.push(
                <Row key={key}>
                    <Admin
                        add={this.props.add}
                        removeFileFromGCloud={this.props.removeFileFromGCloud}
                        public={this.props.public}
                        ticket={this.props.ticket}
                        target={this.props.target}
                        address={this.props.address}
                        put={this.props.put}
                        lang={this.state.lang}
                        trad={this.props.trad}
                        formData={{ ...this.props.formData }}
                        remove={this.removeHeirForm}
                        name={key}
                        counter={h}
                        noCard={this.props.noCard}
                        isPrivate={this.props.isPrivate}
                        title={this.props.title}
                        toggleLoading={this.props.toggleLoading}
                        pushWaitingList={
                            this.props.pushWaitingList
                        }
                        pullWaitingList={
                            this.props.pullWaitingList
                        }
                    />
                </Row>
            );
        }

        this.setState({
            admins: arr,
            usersList: usersList
        });
    }

    addHeirForm() {

        this.setState(state => {

            let counter = this.state.counter;
            let key = this.props.name + '_' + counter++;

            state.counter = counter;
            state.usersList.push({
                key: key,
                counter: counter
            });

            let province = key + '_state',
                country = key + '_country';

            this.props.put({
                params: {
                    key: province,
                    value: 'Québec'
                }
            });
            this.props.put({
                params: {
                    key: country,
                    value: 'Canada'
                }
            });

            return state;
        });
    }

    removeHeirForm(id) {
        this.props.remove({ params: { key: id } });
        this.setState({
            admins: _.reject(this.state.admins, { key: id }),
            usersList: _.reject(this.state.usersList, { key: id }),
        });
    }

    renderInfo(info, id) {
        if (!info) {
            return '';
        }

        return (
            <>
                <b id={id}>{Question}</b>
                <UncontrolledPopover placement="top" target={id}>
                    <PopoverBody>{info}</PopoverBody>
                </UncontrolledPopover>
            </>
        );
    }

    render() {
        const usersList = this.state.usersList;
        return (
            <>
                {usersList.map(form => (
                    <div key={form.key}>
                        <Row>
                            <Admin
                                add={this.props.add}
                                removeFileFromGCloud={this.props.removeFileFromGCloud}
                                public={this.props.public}
                                ticket={this.props.ticket}
                                target={this.props.target}
                                noCard={this.props.noCard}
                                isPrivate={this.props.isPrivate}
                                address={this.props.address}
                                put={this.props.put}
                                lang={this.state.lang}
                                trad={this.props.trad}
                                formData={{ ...this.props.formData }}
                                remove={this.removeHeirForm}
                                name={form.key}
                                counter={form.counter}
                                title={this.props.title}
                                toggleLoading={this.props.toggleLoading}
                                pushWaitingList={
                                    this.props.pushWaitingList
                                }
                                pullWaitingList={
                                    this.props.pullWaitingList
                                }
                            />
                        </Row>
                    </div>
                ))}
                {this.props.children}
                <FormGroup>
                    <Button
                        className="tw-mt-3"
                        block
                        outline
                        color="primary"
                        onClick={() => this.addHeirForm()}
                    >
                        {this.genLang('_btnadd') || 'Ajouter'}
                    </Button>
                </FormGroup>
            </>
        );
    }
}
