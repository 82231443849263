import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface Folder {
    folder_id: string;
    note: string;
}

interface State {
    notes: Folder[];
}

interface Actions {
    setNote: (id: string, note: string) => void;
    removeNote: (id: string) => void;
    getNote: (id: string) => string;
}

export const useFolderStore = create<State & Actions>()(
    devtools(
        persist(
            (set, get) => ({
                notes: [] as Folder[],
                setNote: (id, note) => {
                    if (note && note !== '<p><br></p>') {
                        const currentFolders = get().notes ?? [];
                        const folderExists = currentFolders.some((f) => f.folder_id === id);
                        if (folderExists) {
                            const updatedFolders = currentFolders.map((f) => {
                                if (f.folder_id === id) {
                                    return {
                                        ...f,
                                        note
                                    };
                                } else {
                                    return { ...f };
                                }
                            });
                            set({ notes: updatedFolders });
                        } else {
                            const updatedFolders = [
                                ...currentFolders,
                                {
                                    folder_id: id,
                                    note
                                }
                            ];
                            set({ notes: updatedFolders });
                        }
                    }
                },
                removeNote: (id) => {
                    const currentFolders = get().notes ?? [];
                    const updatedFolders = currentFolders.filter(
                        (f) => f.folder_id !== id
                    );
                    set({ notes: updatedFolders });
                },
                getNote: (id) => {
                    const currentFolders = get().notes ?? [];
                    const selectedFolder = currentFolders.find((f) => f.folder_id === id);
                    if (selectedFolder) return selectedFolder?.note;
                    return '';
                }
            }),
            {
                name: 'folder'
            }
        )
    )
);
