import React, { useEffect, useMemo, useRef } from 'react';
import { type NodeApi, type NodeRendererProps, Tree, type TreeApi } from 'react-arborist';
import _ from 'lodash';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox';
import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons';

export interface TreeNode {
    id: string;
    name: TranslationObject | string;
    children?: TreeNode[];
}

interface Props {
    nodes: TreeNode[];
    maximal?: boolean;
    searchTerm?: string;
    height?: number;
    width?: number;
    className?: string;
    disabled?: boolean;
    value: string[];
    onChange?: (value: string[]) => void;
}

function traverseNodeChildren(node: TreeNode, action: (node: TreeNode) => void, postorder = false) {
    node.children?.forEach(n => {
        if (!postorder) {
            action(n);
        }
        traverseNodeChildren(n, action, postorder);
        if (postorder) {
            action(n);
        }
    });
}

function traverseNodeParents(
    node: NodeApi<TreeNode>,
    action: (node: NodeApi<TreeNode>) => void
) {
    if (node.parent) {
        action(node.parent);
        traverseNodeParents(node.parent, action);
    }
}

function minimalSelection(selection: string[]) {
    let arr = selection.sort();
    for (let i = 0; i < arr.length; i++) {
        arr = arr.filter(n => !n.startsWith(`${arr[i]}.`) || n === arr[i]);
    }
    return arr;
}

export function TreeViewSelect(props: Props) {
    const treeRef = useRef<TreeApi<TreeNode>>();
    const nodeList = useMemo(
        () => props.nodes.reduce<Record<string, TreeNode>>((obj, node) => {
            obj[node.id] = node;
            traverseNodeChildren(node, n => {
                obj[n.id] = n;
            });
            return obj;
        }, {}),
        [props.nodes]
    );

    const findNode = (id: string) => {
        return nodeList[id];
    };

    const maximalSelection = (selection: string[]) => {
        const selectionSet = new Set(selection);
        selection.forEach(id => {
            const node = findNode(id);
            if (node) {
                traverseNodeChildren(node, n => selectionSet.add(n.id));
            }
        });
        return selectionSet;
    };

    const normalizeSelection = (selection: string[]) => {
        return props.maximal
            ? Array.from(maximalSelection(minimalSelection(selection)))
            : minimalSelection(selection);
    };

    const handleSelectNodes = (nodes: string[]) => {
        const selection = normalizeSelection(nodes);
        props.onChange?.(selection);
    };

    const handleUpdateNode = (
        tree: TreeApi<TreeNode>,
        node: NodeApi<TreeNode>,
        state: boolean
    ) => {
        const selection = new Set(tree.selectedIds);
        if (state) {
            selection.add(node.id);
            traverseNodeChildren(node.data, n => selection.add(n.id));
            traverseNodeParents(node, n => {
                if (!n.isRoot && n.children?.every(c => selection.has(c.id))) {
                    selection.add(n.id);
                }
            });
        } else {
            selection.delete(node.id);
            traverseNodeChildren(node.data, n => selection.delete(n.id));
            traverseNodeParents(node, n => selection.delete(n.id));
        }
        const selectionArr = Array.from(selection);
        tree.setSelection({
            ids: selectionArr,
            anchor: node.id,
            mostRecent: node.id
        });
        handleSelectNodes(selectionArr);
    };

    const TreeNode = ({ tree, node, style }: NodeRendererProps<TreeNode>) => {
        const isRootNode = node.parent?.isRoot ?? false;
        const { to } = useTranslation();
        return (
            <div
                key={node.id}
                className="tw-flex tw-items-center tw-text-lg"
                style={style}
                onClick={e => e.stopPropagation()}
            >
                <Button
                    type="button"
                    className={cn(
                        '!tw-rounded-full tw-mr-1',
                        (!node.children || node.children.length === 0) &&
                            'tw-opacity-0 !tw-cursor-default'
                    )}
                    variant="ghost"
                    size="icon"
                    onClick={(e) => {
                        e.stopPropagation();
                        node.toggle();
                    }}
                >
                    {node.isOpen
                        ? <ChevronDownIcon width={18} height={18} />
                        : <ChevronRightIcon width={18} height={18} />
                    }
                </Button>
                <div className="tw-inline-flex tw-items-center tw-gap-2">
                    <Checkbox
                        id={node.id}
                        disabled={props.disabled}
                        checked={node.isSelected}
                        onCheckedChange={(checked) => handleUpdateNode(tree, node, Boolean(checked))}
                    />
                    <Label htmlFor={node.id} className="tw-text-base">
                        <span className={cn(isRootNode && 'tw-font-semibold')}>
                            {to(node.data.name)}
                        </span>
                    </Label>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const tree = treeRef.current;
        const first = tree?.root?.children?.[0];
        if (first) {
            tree?.openSiblings(first);
        }
    }, [props.nodes]);

    useEffect(() => {
        const tree = treeRef.current;
        if (!tree) return;
        const maxSelection = maximalSelection(props.value);
        if (!_.isEqual(tree.selectedIds, maxSelection)) {
            const selection = Array.from(maxSelection);
            tree.setSelection({
                ids: selection,
                anchor: selection[0] ?? null,
                mostRecent: selection[0] ?? null
            });
            handleSelectNodes(selection);
        }
        const minSelection = minimalSelection(props.value);
        minSelection.forEach(n => tree.openParents(n));
    }, [props.value]);

    useEffect(() => {
        if (!props.maximal) {
            return;
        }
        const selectionSet = maximalSelection(props.value);
        props.nodes.forEach(rn => {
            const action = (n: TreeNode) => {
                const children = (n?.children ?? []).map(c => c.id);
                if (children.length > 0 && children.every(c => selectionSet.has(c))) {
                    selectionSet.add(n.id);
                }
            };
            traverseNodeChildren(rn, action, true);
            action(rn);
        });
        props.onChange?.(Array.from(selectionSet));
    }, []);

    return (
        <>
            <Tree
                data={props.nodes}
                openByDefault={false}
                width={props.width}
                height={props.height}
                rowHeight={30}
                className={props.className}
                disableDrag
                disableEdit
                disableDrop
                searchTerm={props.searchTerm}
                ref={treeRef}
            >
                {TreeNode}
            </Tree>
        </>
    );
}
