import React from 'react';
import {
    NeoFormComponent,
    type NeoFormComponentProps
} from '@/components/neoform/NeoFormComponent';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { combine } from '@/composables/translation';
import { ADMIN_POSITIONS, RELATIONSHIPS } from '@/components/utils/translations';
import { useNeoForm } from '@/composables/neoform';
import { Row } from '@/components/ui/row';
import { cn } from '@/lib/utils';

interface Props {
    noName?: boolean;
    noFirstname?: boolean;
    noRelation?: boolean;
    noGender?: boolean;
    contact?: boolean;
    position?: boolean;
    birthday?: boolean;
    address?: boolean;
    child?: boolean;
}

export function User(props: Props & NeoFormComponentProps) {
    const { form, getChildFieldName } = useNeoForm();
    const isPositionOther = form?.watch(getChildFieldName('position')) === 'other';
    return (
        <div className="tw-flex tw-flex-col tw-gap-1">
            <Row>
                <NeoFormComponent
                    name="name"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.last-name')}
                    display={!props.noName}
                    col={4}
                />
                <NeoFormComponent
                    name="firstname"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.first-name')}
                    display={!props.noFirstname}
                    col={4}
                />
                <NeoFormComponent
                    name="relationship"
                    componentName="InputSelect"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.relationship')}
                    display={!props.noRelation}
                    col={2}
                    options={RELATIONSHIPS}
                />
                <NeoFormComponent
                    name="gender"
                    componentName="InputSelect"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.gender')}
                    display={!props.noGender}
                    col={props.noRelation ? 4 : 2}
                    options={[
                        {
                            value: 'M',
                            label: combine(props.child ? 'neoform.boy' : 'neoform.sir')
                        },
                        {
                            value: 'F',
                            label: combine(props.child ? 'neoform.girl' : 'neoform.madam')
                        },
                        {
                            value: '-',
                            label: combine('neoform.other')
                        }
                    ]}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="position"
                    componentName="InputSelect"
                    title={combine('neoform.position.title')}
                    titleType={NeoFormTitleType.LABEL}
                    options={ADMIN_POSITIONS}
                    display={!!props.position}
                    col={isPositionOther ? 2 : 6}
                />
                <NeoFormComponent
                    name="positionValue"
                    componentName="InputText"
                    title={combine('neoform.position.other')}
                    titleType={NeoFormTitleType.LABEL}
                    display={!!props.position && isPositionOther}
                    required
                    col={4}
                />
                <NeoFormComponent
                    name="status"
                    componentName="InputSelect"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.status')}
                    display={!!props.position}
                    col={6}
                    options={[
                        {
                            value: 'citizen',
                            label: combine('neoform.canadian-citizen')
                        },
                        {
                            value: 'permanent_resident',
                            label: combine('neoform.permanent-resident')
                        },
                        {
                            value: 'not_resident',
                            label: combine('neoform.non-resident')
                        }
                    ]}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="birthday"
                    componentName="InputDate"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.birthday')}
                    display={!!props.birthday}
                    col={4}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="address"
                    componentName="Address"
                    title={combine('neoform.address.address')}
                    display={!!props.address}
                    displayPreface={false}
                    contact={props.contact}
                    country
                />
            </Row>
            <Row className={cn({ '!tw-mt-2': !!props.position })}>
                <NeoFormComponent
                    name="files"
                    componentName="InputDropContainer"
                    display={!!props.position}
                    last
                />
            </Row>
        </div>
    );
}
