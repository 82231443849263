import React, { useEffect } from 'react';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { getFieldError, useValidation } from '@/composables/validation';
import { Checkbox } from '@/components/ui/checkbox';
import { TextFieldTranslate } from '@/components/TextFieldTranslate';
import { CrudNextSteps } from '@/components/psj/process/CrudNextSteps';
import { Button } from '@/components/ui/button';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { type UpdateDialogProps } from '@/components/ui/crud-table';
import { type Product } from '@/types/api/product';
import { useForm } from 'react-hook-form';
import type { User, UserGroup } from '@/types/api/user';
import type { CalendarConfig } from '@/types/api/calendar';
import { useTranslation } from '@/composables/translation';
import type { NextStepInstance, Process } from '@/types/api/process';
import { useGroups } from '@/composables/groups';
import { Combobox } from '@/components/ui/combobox';
import { getProcessList } from '@/composables/api';
import { Col, Row } from '@/components/ui/row';
import { type Macro } from '@/types/api/macro';
import { ConfirmDialogButton } from '@/components/ConfirmDialogButton';

interface UpdateProductDialogProps extends UpdateDialogProps<Product> {
    users: User[];
    groups: UserGroup[];
    macros: Macro[];
    calendarConfig?: CalendarConfig;
}

export function UpdateProductDialog(props: UpdateProductDialogProps) {
    const { ct, to, t } = useTranslation('settings.products.crud-dialog');
    const { required, requiredTranslate } = useValidation();
    const { specialties, groupBy } = useGroups({ groups: props.groups });
    const form = useForm<Product & { ns_list: NextStepInstance[] }>({
        defaultValues: {
            ...props.value,
            ns_list: props.value?.process?.ns_list?.map<NextStepInstance>(
                ({ ...ns }) => ({ ...ns, parent: ns })
            ) ?? []
        }
    });
    const isUpdateDialog = props.isUpdateDialog ?? false;

    useEffect(() => {
        form.reset({
            ...props.value,
            ns_list: props.value?.process.ns_list.map(({ ...ns }) => ({ ...ns, parent: ns })) ?? []
        });
    }, [props.value]);

    function onSubmit(value: Product & { ns_list: NextStepInstance[] }) {
        const { ns_list, ...product } = value;
        if (!product.process) {
            product.process = {} as Process;
        }
        product.process.ns_list = ns_list.map((ns, index) => ({
            ...ns,
            index,
            parent: typeof ns.parent === 'object'
                ? ns.parent._id
                : ns.parent
        }));
        return props.onSubmit?.(product);
    }

    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            <DialogContent className="md:tw-max-w-5xl">
                <DialogHeader>
                    <DialogTitle>{isUpdateDialog ? ct('edit') : ct('create')}</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={(event) => {
                        event.stopPropagation();
                        form.handleSubmit(onSubmit)(event);
                    }}>
                        <div className="tw-pb-6 tw-flex tw-flex-col tw-gap-3">
                            {isUpdateDialog &&
                                <FormField
                                    name="active"
                                    render={({ field }) =>
                                        <FormItem>
                                            <div className="tw-flex tw-items-center tw-py-1">
                                                <FormControl>
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        onCheckedChange={field.onChange}
                                                    />
                                                </FormControl>
                                                <FormLabel className="tw-pl-2">
                                                    {t('fields.active')}
                                                </FormLabel>
                                            </div>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            }
                            <FormField
                                name="process"
                                render={({ field: { ref, ...field }, formState }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.parent-product')}
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox<Process, Process>
                                                getOptionsAsync={(search) =>
                                                    getProcessList({ title: search })
                                                        .then((res) => res.data.map((p) => {
                                                            p.ns_list = p.ns_list.map(ns => ({ ...ns, parent: ns }));
                                                            return p;
                                                        }))
                                                }
                                                getOptionLabel={(opt) => to(opt.title)}
                                                getValueLabel={(opt) => to(opt.title)}
                                                {...field}
                                                innerRef={ref}
                                                onChange={(value) => {
                                                    if (value) {
                                                        form.setValue(
                                                            'title',
                                                            value.title,
                                                            { shouldValidate: true }
                                                        );
                                                        form.setValue(
                                                            'ns_list',
                                                            value.ns_list,
                                                            { shouldValidate: true }
                                                        );
                                                    }
                                                    field.onChange(value);
                                                }}
                                                error={!!getFieldError(field.name, formState)}
                                            />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                            />
                            <Row>
                                <Col col={6}>
                                    <FormField
                                        name="title"
                                        render={({ field, formState }) =>
                                            <FormItem>
                                                <FormLabel>
                                                    {t('fields.name')}
                                                </FormLabel>
                                                <FormControl>
                                                    <TextFieldTranslate
                                                        {...field}
                                                        error={!!getFieldError(field.name, formState)}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        }
                                        rules={{ validate: { requiredTranslate } }}
                                    />
                                </Col>
                                <Col col={3}>
                                    <FormField
                                        name="price"
                                        render={({ field, formState }) =>
                                            <FormItem>
                                                <FormLabel>
                                                    {t('fields.price')}
                                                </FormLabel>
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        value={field.value ?? ''}
                                                        error={!!getFieldError(field.name, formState)}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        }
                                    />
                                </Col>
                                <Col col={3}>
                                    <FormField
                                        name="fees"
                                        render={({ field, formState }) =>
                                            <FormItem>
                                                <FormLabel>
                                                    {t('fields.fees')}
                                                </FormLabel>
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        value={field.value ?? ''}
                                                        error={!!getFieldError(field.name, formState)}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        }
                                    />
                                </Col>
                            </Row>
                            <FormField
                                name="groups"
                                render={({ field: { ref, ...field }, formState }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.groups')}
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox
                                                options={specialties}
                                                getOptionValue={(opt) => opt._id.$oid}
                                                getOptionLabel={(opt) => to(opt.name)}
                                                groupBy={groupBy}
                                                multiple clearable
                                                {...field}
                                                innerRef={ref}
                                                error={!!getFieldError(field.name, formState)}
                                            />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                            />
                            <FormField
                                name="ns_list"
                                render={(context) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.step-list')}
                                        </FormLabel>
                                        <FormControl>
                                            <CrudNextSteps
                                                context={context}
                                                users={props.users}
                                                groups={props.groups}
                                                macros={props.macros}
                                                calendarConfig={props.calendarConfig}
                                            />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                                rules={{ validate: { required } }}
                            />
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary">
                                    {ct('cancel')}
                                </Button>
                            </DialogClose>
                            {props.onDelete &&
                                <ConfirmDialogButton
                                    title={t('delete-dialog.title')}
                                    message={t('delete-dialog.message')}
                                    confirmText={ct('delete')}
                                    onConfirm={async() => {
                                        await props.onDelete?.(props.value as any);
                                    }}
                                >
                                    <Button variant="destructive">
                                        {to(props.translation?.delete, ct('delete'))}
                                    </Button>
                                </ConfirmDialogButton>
                            }
                            <ButtonSubmit>
                                {ct('save')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
