import React from 'react';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { useNeoForm } from '@/composables/neoform';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';
import useHtml from '@/composables/html';

interface RadioOption {
    label: TranslationObject;
    value: string;
}

interface Props {
    options: RadioOption[];
    vertical?: boolean;
    indent?: boolean;
}

export function InputRadio(props: NeoFormComponentProps & Props) {
    const { to } = useTranslation();
    const { form, id, getValidationRules } = useNeoForm();
    const validate = getValidationRules(props);
    return (
        <FormField
            name={id}
            defaultValue={props.default}
            render={({ field }) => {
                return (
                    <FormItem className="tw-pt-2">
                        <FormControl>
                            <RadioGroup
                                defaultValue={props.default}
                                className={cn(
                                    'tw-pb-2',
                                    props.vertical && 'tw-gap-3',
                                    !props.vertical && '!tw-flex tw-gap-4'
                                )}
                                {...field}
                                value={String(field.value ?? '')}
                                ref={(ref) => {
                                    field.ref(ref);
                                    form?.ref(id, ref);
                                }}
                                onValueChange={(value) => {
                                    let newValue: any = value;
                                    if (newValue === 'true') {
                                        newValue = true;
                                    } else if (newValue === 'false') {
                                        newValue = false;
                                    }
                                    field.onChange(newValue);
                                    form?.set(id, newValue);
                                }}
                            >
                                {props.options.map((option) => {
                                    const sanitizedHtml = useHtml(to(option.label));
                                    return <div
                                        key={option.value}
                                        className="tw-flex tw-items-center"
                                    >
                                        <RadioGroupItem id={`${id}.${option.value}`} value={String(option.value)} />
                                        <Label
                                            className="tw-pl-2"
                                            htmlFor={`${id}.${option.value}`}
                                            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                                        />
                                    </div>;
                                }
                                )}
                            </RadioGroup>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                    // <FormControl
                    //     className={c(
                    //         '!tw-my-[-0.25rem]',
                    //         { '!tw-pl-4': props.indent }
                    //     )}
                    //     error={!!error}
                    // >
                    //     <RadioGroup
                    //         id={id}
                    //         row={!props.vertical}
                    //         {...field}
                    //         ref={ref => {
                    //             field.ref(ref);
                    //             form?.ref(id, ref);
                    //         }}
                    //         value={field.value ?? ''}
                    //         onChange={e => {
                    //             field.onChange(e.target.value);
                    //             form?.set(id, e.target.value);
                    //         }}
                    //     >
                    //         {props.options.map((o, i) => (
                    //             <FormControlLabel
                    //                 key={i}
                    //                 disabled={props.readonly}
                    //                 control={<Radio disabled={props.readonly} />}
                    //                 value={o.value}
                    //                 label={to(o.label)}
                    //             />
                    //         ))}
                    //     </RadioGroup>
                    //     {error &&
                    //         <FormHelperText>{error}</FormHelperText>
                    //     }
                    // </FormControl>
                );
            }}
            rules={{ validate }}
        />
    );
}
