import React, { useState } from 'react';
import { Button, type ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { writeClipboardJSON } from '@/composables/clipboard';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useTranslation } from '@/composables/translation';

interface Props extends ButtonProps {
    value?: any;
    resetDelay?: number;
}

export function ButtonCopy(props: Props) {
    const { ct } = useTranslation();
    const resetDelay = props.resetDelay ?? 3000;
    const [copied, setCopied] = useState(false);
    if (!props.value) return null;
    return (
        <Tooltip>
            <TooltipTrigger asChild>
                <Button
                    {...props}
                    variant="outline"
                    className={cn(
                        copied && 'tw-text-success hover:tw-text-success',
                        props.className
                    )}
                    onClick={() => {
                        writeClipboardJSON(props.value).then(() => {
                            setCopied(true);
                            setTimeout(() => setCopied(false), resetDelay);
                        });
                    }}
                >
                    <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
                </Button>
            </TooltipTrigger>
            <TooltipContent>
                {copied ? ct('copied') : ct('copy')}
            </TooltipContent>
        </Tooltip>
    );
}
