import React, { useEffect, useMemo } from 'react';
import { useTranslation } from '@/composables/translation';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { DatePicker } from '@/components/ui/date-picker';
import { TimePicker } from '@/components/ui/time-picker';
import { Button } from '@/components/ui/button';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { postBookAppointment } from '@/composables/api';
import { toast } from 'react-toastify';
import { useError } from '@/composables/error';
import { useUserStore } from '@/store/user';
import { getFieldError, useValidation } from '@/composables/validation';
import { type User } from '@/types/api/user';
import { Combobox } from '@/components/ui/combobox';

interface Props {
    users: User[];
    open?: boolean;
    onOpenChange?: React.Dispatch<boolean>;
}

export function CreateHolidayDialog(props: Props) {
    const { t, ct } = useTranslation('schedule');
    const { handleNetworkError } = useError();
    const { required } = useValidation();
    const { user: loggedUser, lang } = useUserStore(state => ({ lang: state.lang, user: state.user }));
    const typeOptions = useMemo(() => [
        t('add-holidays.type-option-0'),
        t('add-holidays.type-option-1')
    ], [lang]);
    interface FormType { title: string; user?: User; start: DateTime; end: DateTime }
    const form = useForm<FormType>({
        defaultValues: {
            title: typeOptions[0],
            user: props.users.find(u => u.id.$oid === loggedUser?.user_id),
            start: DateTime.now().startOf('day'),
            end: DateTime.now().startOf('day').plus({ days: 1 })
        }
    });
    useEffect(() => {
        form.resetField('user', {
            defaultValue: props.users.find(u => u.id.$oid === loggedUser?.user_id)
        });
    }, [props.users]);
    function validateStartDate(value: DateTime) {
        if (value > form.getValues('end')) {
            return 'Must be prior to end date';
        }
        return true;
    }
    function validateEndDate(value: DateTime) {
        if (value < form.getValues('start')) {
            return 'Must be after start date';
        }
        return true;
    }
    function handleSubmit({ title, user, start, end }: FormType) {
        return postBookAppointment({
            timezone: DateTime.local().zoneName,
            metadata: {
                client: navigator.userAgent
            },
            users: [{
                id: user?.id.$oid ?? loggedUser?.user_id ?? '',
                role: user?.role ?? loggedUser?.role ?? '',
                email: user?.username ?? loggedUser?.email ?? ''
            }],
            title,
            where: '',
            start: start.toISO({ suppressMilliseconds: true }),
            end: end.toISO({ suppressMilliseconds: true }),
            duration: end.diff(start, 'minutes').minutes ?? 0,
            holiday: true
        })
            .then(() => {
                toast('Success', { type: 'success' });
                props.onOpenChange?.(false);
            })
            .catch(handleNetworkError);
    }
    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {t('add-holidays.title')}
                    </DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)}>
                        <div className="tw-flex tw-flex-col tw-gap-3 tw-pb-6">
                            <FormField
                                name="title"
                                render={({ field: { ref, ...field }, formState }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('add-holidays.type')}
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox<string>
                                                options={typeOptions}
                                                {...field}
                                                error={!!getFieldError(field.name, formState)}
                                            />
                                        </FormControl>
                                    </FormItem>
                                }
                                rules={{ validate: { required } }}
                            />
                            <FormField
                                name="user"
                                render={({ field: { ref, ...field }, formState }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('add-holidays.user')}
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox<User, User>
                                                options={props.users}
                                                getOptionLabel={(opt) => `${opt.firstname} ${opt.lastname}`}
                                                getValueLabel={(value) => `${value.firstname} ${value.lastname}`}
                                                {...field}
                                                innerRef={ref}
                                                error={!!getFieldError(field.name, formState)}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                                rules={{ validate: { required } }}
                            />
                            <FormField
                                name="start"
                                render={({ field, formState }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('add-holidays.start')}
                                        </FormLabel>
                                        <FormControl>
                                            <div className="tw-flex tw-gap-2">
                                                <DatePicker
                                                    className="tw-flex-1"
                                                    {...field}
                                                    error={!!getFieldError(field.name, formState)}
                                                />
                                                <TimePicker
                                                    className="tw-flex-1"
                                                    {...field}
                                                    error={!!getFieldError(field.name, formState)}
                                                />
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                                rules={{ validate: { required, validateStartDate } }}
                            />
                            <FormField
                                name="end"
                                render={({ field, formState }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('add-holidays.end')}
                                        </FormLabel>
                                        <FormControl>
                                            <div className="tw-flex tw-gap-2">
                                                <DatePicker
                                                    className="tw-flex-1"
                                                    {...field}
                                                    error={!!getFieldError(field.name, formState)}
                                                />
                                                <TimePicker
                                                    className="tw-flex-1"
                                                    {...field}
                                                    error={!!getFieldError(field.name, formState)}
                                                />
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                                rules={{ validate: { required, validateEndDate } }}
                            />
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary">
                                    {ct('cancel')}
                                </Button>
                            </DialogClose>
                            <ButtonSubmit>
                                {ct('save')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
