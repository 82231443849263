import React, { Component } from 'react';

import { Col, FormGroup, Input, Label } from 'reactstrap';

import _ from 'lodash';

import { RequestThatField } from './RequestThatField';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const DEFAULT_STYLE = {};

export class SelectBootstrap extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData || [];

        this.state = {
            value:
                fd && fd[this.props.name] ? fd[this.props.name] : this.props.default,
            lang: this.props.lang || FR,
        };
        // debugger;
        // debugger;
        this.props.put({
            params: {
                key: this.props.name,
                value: this.state.value ? this.state.value.toString() : '',
                title: this.props.title,
            },
        });

        // if(!props.onChange)
        //     console.error('!!! onChange is missing !!!');

        if (!props.name) console.error('!!! name is missing !!!');

        if (!props.title) console.error('!!! title is missing !!!');

        if (!props.title) console.error('!!! no options for a select O_o?? !!!');

        this.state.display = this.props.defaultDisplay === false ? false : true;
        // console.log('Assimov ====>',props.version)
        this.renderOptions = this.renderOptions.bind(this);
    }

    renderOptions() {
        if (!this.props.options || !this.props.options.length) {
            return <option>Aucun choix disponible</option>;
        }

        // console.log('wowow---=====>>',this.state.value)
        return (
            <>
                {_.map(this.props.options, (e) => {
                    let f = { ...e };
                    f.label = this.genLang(f.label || '', this.state.lang);

                    if (f.default && this.state.value !== 0 && !this.state.value) {
                        setTimeout(() => {
                            this.props.put({
                                params: {
                                    key: this.props.name,
                                    value: f.value,
                                    title: this.props.title,
                                },
                            });
                        }, 500);
                    }
                    return (
                        <option {...f} key={this.props.name + '_' + f.value}>
                            {f.label}
                        </option>
                    );
                })}
            </>
        );
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        // debugger;
        if (props.version !== prevprops.version) {
            props.put({
                params: {
                    key: props.name,
                    value: props.default,
                    title: this.props.title,
                },
            });
            obj['value'] = props.formData[props.name] || props.default;
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            obj['value'] = props.formData[props.name];

            props.put({
                params: {
                    key: this.props.name,
                    value: props.formData[this.props.name] || props.default,
                    title: this.props.title,
                },
            });
            // debugger;
        }
        // else if(!props.formData[props.name] && (props.default !== this.state.value)){
        //     debugger
        //     props.put({params:{key:props.name,value:props.default}});
        //     obj['value'] = props.default;
        // }

        if (props.lang !== prevprops.lang) obj['lang'] = props.lang;
        // console.log(this.props.formData)
        if (!_.isEmpty(obj)) {
            // debugger;
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        return (
            <>
                <div
                    className={
                        this.state.value
                            ? ''
                            : 'p-1 bg-' + (props.color || 'white') + ' rounded'
                    }
                >
                    <FormGroup>
                        <Input
                            tabIndex={props.tabindex}
                            value={this.state.value || ''}
                            type={'select'}
                            {...(this.props.required ? { required: true } : {})}
                            name={props.name}
                            onChange={(e) => {
                                e.persist();

                                this.setState({ value: parseInt(e.target.value) }, () => {
                                    props.put({
                                        params: {
                                            key: props.name,
                                            value: parseInt(e.target.value),
                                            title: this.props.title,
                                        },
                                    });
                                    if (props.onChange) {
                                        props.onChange(e.target.value, props.name);
                                    }
                                });
                            }}
                        >
                            {this.renderOptions()}
                        </Input>
                    </FormGroup>
                </div>
            </>
        );
    }
}
