import * as customersActions from './customers-actions';
import * as usersActions from './users-actions';
import * as formsActions from './forms-actions';
import * as spinnerActions from './spinner-actions';
import * as requestActions from './requestDocuments-actions';
import * as menuActions from './menu-actions';

import * as loadingActions from './loading-actions';

export default {
    customersActions,
    usersActions,
    formsActions,
    spinnerActions,
    loadingActions,
    requestActions,
    menuActions
};
