import React from 'react';
import { Button, type ButtonProps } from '@/components/ui/button';
import { useFormContext } from 'react-hook-form';

export interface Props extends ButtonProps {
    loading?: boolean;
}

const ButtonSubmit = React.forwardRef<HTMLButtonElement, Props>(
    ({ loading, ...props }, ref) => {
        const context = useFormContext();
        const isLoading = loading ?? context?.formState.isSubmitting;
        return (
            <Button
                ref={ref}
                type="submit"
                loading={isLoading}
                spinnerClassName="tw-mx-3"
                {...props}
            >
                {props.children}
            </Button>
        );
    }
);
ButtonSubmit.displayName = 'ButtonSubmit';

export { ButtonSubmit };
