import React from 'react';
import { useTranslation } from '@/composables/translation';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { Feature, useFeatures } from '@/composables/features';
import { Permission, usePermissions } from '@/composables/permissions';

export function SettingsLayout() {
    const location = useLocation();
    const navigate = useNavigate();
    const { t, ct } = useTranslation('settings');
    const { isFeatureEnabled } = useFeatures();
    const { hasPermissions, isMaster } = usePermissions();

    const NAV_LINKS = [
        { label: t('account.link'), to: '/settings/account', fullWidth: false },
        { label: t('password.link'), to: '/settings/password', fullWidth: false },
        // { label: 'Single Sign-On (SSO)', to: '/settings/sso' },
        // { label: t('billing.link'), to: '/settings/billing' },
        ...(
            hasPermissions(Permission.PSJ_ADMIN_PANEL)
                ? [
                    { label: t('users.link'), to: '/settings/users', fullWidth: true },
                    { label: t('groups.link'), to: '/settings/groups', fullWidth: true },
                    { label: ct('org'), to: '/settings/org', fullWidth: false },
                    { label: t('invoices.link'), to: '/settings/invoices', fullWidth: true }
                ]
                : []
        ),
        ...(
            isFeatureEnabled(Feature.PSJ) && hasPermissions(Permission.PSJ_ADMIN_PANEL)
                ? [
                    { label: t('processes.link'), to: '/settings/processes', fullWidth: true },
                    { label: t('products.link'), to: '/settings/products', fullWidth: true },
                    { label: t('macros.link'), to: '/settings/macros', fullWidth: true },
                    { label: t('views.link'), to: '/settings/views', fullWidth: true }
                ]
                : []
        ),
        ...(isMaster ? [{ label: t('orgs.create-org-short'), to: '/settings/create-org', fullWidth: false }] : [])
    ] as const;

    const currentLink = NAV_LINKS.find((link) => link.to === location.pathname);

    return (
        <div className="tw-container tw-min-h-full tw-p-4">
            <h3 className="tw-text-2xl tw-font-semibold tw-text-primary tw-leading-none tw-mb-3">
                {t('title')}
            </h3>
            <div className={cn(
                !currentLink?.fullWidth && 'tw-max-w-screen-md',
                'tw-mx-auto tw-flex tw-flex-col tw-items-center'
            )}>
                <Tabs
                    value={location.pathname}
                    onValueChange={(value) => navigate(value)}
                >
                    <TabsList className="tw-bg-slate-200 tw-mb-3 ">
                        {NAV_LINKS.map((l) =>
                            <TabsTrigger key={l.to} value={l.to} className="sm:tw-px-4 md:tw-px-8">
                                {l.label}
                            </TabsTrigger>
                        )}
                    </TabsList>
                </Tabs>
                <Card className="tw-w-full">
                    <Outlet/>
                </Card>
            </div>
        </div>
    );
}
