import { List } from '@/components/neoform/complex/List';
import { StakeholderRecursive } from '@/components/neoform/complex/StakeholderRecursive';
import { useTranslation } from '@/composables/translation';
import { useNeoForm } from '@/composables/neoform';
import React from 'react';
import { Button } from '@/components/ui/button';

export const Stakeholder2 = List(
    StakeholderRecursive,
    {
        renderAddButton: (props) => {
            const { t, ct } = useTranslation('neoform');
            const { component, form } = useNeoForm();
            const path = [...(component?.path.slice(0, component?.path.length - 1) ?? []), 'nom'];
            const name = form?.watch<string>(path.join('.')) ?? '';
            return (
                <Button
                    type="button" variant="outline"
                    className="tw-text-primary tw-w-full"
                    {...props}
                >
                    {ct('add')} ({t('shareholder')} {ct('of')} <b className="tw-ml-1">{name}</b>)
                </Button>
            );
        }
    }
);
