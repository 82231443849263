import React, { Component } from 'react';

import { Col, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, UncontrolledDropdown } from 'reactstrap';

import _ from 'lodash';

const DEFAULT_SIZE = 12;
const DEFAULT_STYLE = { textAlign: 'left' };
const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class Help extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lang: props.lang || FR,
            links: this.props.links || [],
            dropdownOpen: false
        };

        this.toggle = this.toggle.bind(this);

    }

    toggle() {
        this.setState({
            toggle: !this.state.dropdownOpen
        });
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (props.links !== prevprops.links) {
            obj['links'] = props.links;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;
        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (!this.state.links || !this.state.links.length) {
            return (<></>);
        }

        return (
            <div>
                <Navbar color="light" light expand="md" className="justify-content-end">
                    <Nav style={{ marginRight: '50px' }} navbar className="mr-auto justify-content-end">
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav>
                                <i className="far fa-question-circle" color={'#efefef'}></i>
                            </DropdownToggle>
                            <DropdownMenu
                                right
                                className=""
                                style={
                                    { background: '#1b55e2' }
                                }
                            >
                                {_.map(this.state.links, (e) => {
                                    return (
                                        <div
                                            key={'menu_' + e.title}>
                                            <DropdownItem>
                                                <a
                                                    className="nav-link active"
                                                    target="_blank"
                                                    style={{
                                                        margin: '10px',
                                                        color: '#efefef'
                                                    }}
                                                    href={e.link}
                                                >{e.title}</a>
                                            </DropdownItem>
                                        </div>);
                                })}
                                <div
                                    className="sub-menu-icon"
                                    style={{
                                        position: 'absolute',
                                        fontSize: '80px',
                                        color: 'rgba(255, 255, 255, 0.1)',
                                        bottom: '-30px',
                                        right: '-10px',
                                        display: 'block'
                                    }}
                                ><i className="far fa-question-circle" aria-hidden="true"></i></div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Navbar>
            </div>
        );
    }
}
