import React from 'react';
import { useTranslation } from '@/composables/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import {
    containsLowerCase,
    containsNumber,
    containsSpecialChar,
    containsUpperCase,
    is8Chars
} from '@/composables/validation';

interface PasswordValidatorProps {
  password: string;
}

interface Rule {
  rule: string;
  regex: RegExp;
}

export function PasswordValidator(props: PasswordValidatorProps) {
    const { t } = useTranslation('validation.password');
    const rules: Rule[] = [
        { rule: t('special-char'), regex: containsSpecialChar },
        { rule: t('uppercase'), regex: containsUpperCase },
        { rule: t('lowercase'), regex: containsLowerCase },
        { rule: t('number'), regex: containsNumber },
        { rule: t('min-8-chars'), regex: is8Chars }
    ];

    const checkRule = (regex: RegExp): boolean => regex.test(props.password);

    if (!props?.password?.length) return <></>;

    return (
        <ul className='tw-flex tw-flex-wrap tw-gap-2'>
            {rules.map(({ rule, regex }) => {
                const valid = checkRule(regex);
                return (
                    <li
                        key={rule}
                        className='tw-flex tw-flex-[1_1_45%] tw-gap-2 tw-items-center tw-text-xs'
                        style={{ color: valid ? 'green' : '#fa4b4b' }}
                    >
                        <FontAwesomeIcon size="sm" icon={valid ? faCheck : faX}/>
                        <div>{rule}</div>
                    </li>
                );
            }
            )}
        </ul>
    );
}

export default PasswordValidator;
