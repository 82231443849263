import React, { Component } from 'react';
import { Good } from './Good';

import { Button, Col, FormGroup, Label, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Question = <FontAwesomeIcon icon={faQuestionCircle} className="avensRed" />;

export class Goods extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: this.props.counter || 0,
            lang: 'fr',
            users: [],
        };
        this.addHeirForm = this.addHeirForm.bind(this);
        this.removeHeirForm = this.removeHeirForm.bind(this);
    }

    genLang() {
        // to Implement
    }

    componentDidMount() {
        // set Default Data in here :)
        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);

        for (let f in fd) {
            if (f.match(reg)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }

        let arr = [];
        for (let h in obj) {
            let key = this.props.name + '_' + h;
            arr.push(
                <>
                    <Row key={key}>
                        <Good
                            put={this.props.put}
                            lang={this.state.lang}
                            trad={this.props.trad}
                            formData={{ ...this.props.formData }}
                            remove={this.removeHeirForm}
                            name={key}
                            counter={h}
                            target={this.props.title}
                        />
                    </Row>
                </>
            );
        }

        this.setState({ users: arr });

    }

    addHeirForm() {
        this.setState(state => {

            let counter = this.state.counter;
            let key = this.props.name + '_' + counter++;

            state.counter = counter;
            state.users.push(
                <>
                    <Row key={key}>
                        <Good
                            put={this.props.put}
                            lang={this.state.lang}
                            trad={this.props.trad}
                            formData={{ ...this.props.formData }}
                            remove={this.removeHeirForm}
                            name={key}
                            counter={counter}
                            percent={this.state.percent}
                            target={this.props.title}
                        />
                    </Row>
                </>
            );

            let province = key + '_state',
                country = key + '_country';

            this.props.put({
                params: {
                    key: province,
                    value: 'Québec'
                }
            });
            this.props.put({
                params: {
                    key: country,
                    value: 'Canada'
                }
            });

            return state;
        });
    }

    removeHeirForm(id) {
        this.props.remove({ params: { key: id } });
        this.setState({
            users: _.reject(this.state.users, { key: id })
        });
    }

    renderInfo(info, id) {
        if (!info) {
            return '';
        }

        return (
            <>
                <b id={id}>{Question}</b>
                <UncontrolledPopover placement="top" target={id}>
                    <PopoverBody>{info}</PopoverBody>
                </UncontrolledPopover>
            </>
        );
    }

    render() {

        let users = this.state.users;
        return (
            <>
                <FormGroup>
                    {users.map(form => (
                        <div key={form.key}>{form}</div>
                    ))}
                </FormGroup>
                {this.props.children ? this.props.children : ''}
                <FormGroup>
                    <Button
                        className="tw-mt-3"
                        block
                        outline
                        color="primary"
                        onClick={() => this.addHeirForm()}
                    >
                        {this.props.buttonText || 'Ajouter'}
                    </Button>
                </FormGroup>
            </>
        );
    }
}
