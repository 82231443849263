import React from 'react';
import { Outlet } from 'react-router-dom';
import { cn } from '@/lib/utils';

export function IFrameLayout() {
    return (
        <main className={cn(
            'tw-flex-1 !tw-bg-slate-100 md:tw-p-6',
            'tw-flex tw-justify-center tw-items-center'
        )}>
            <Outlet />
        </main>
    );
}
