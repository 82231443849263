import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BeatLoader } from 'react-spinners';

class LoadingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };
    }

    componentDidUpdate(prevprops) {
        if (this.props.loading !== prevprops.loading) {
            this.setState({ loading: this.props.loading });
        }
    }

    render() {
        if (!this.state.loading) {
            return (<></>);
        }
        return (
            <div className="tw-fixed tw-inset-0 tw-bg-gray-700/30 tw-flex tw-justify-center tw-items-center"
                 style={{ zIndex: 99999999 }}>
                <BeatLoader size={50} margin={5} loading={this.state.loading} color="#0052BB" />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.loading.loading
});

export const Loading = connect(mapStateToProps)(LoadingComponent);
