import React, { Component } from 'react';
import { UserChildrenDivorce } from './UserChildrenDivorce';

import { Button, Col, FormGroup, Label, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { RequestThatField } from './RequestThatField';

const Question = <FontAwesomeIcon icon={faQuestionCircle} className="avensRed" />;

const DEFAULT_STYLE = {};

const MIS_EN_CAUSE_OPTIONS = [
    {
        label: 'Non',
        value: 0,
    }, {
        label: 'Procureure générale du Québec',
        value: 1,
        name: 'PROCUREURE GÉNÉRALE DU QUÉBEC',
        title: 'F',
        street: '1200, route de l\'Église, 2e étage',
        city: 'Québec',
        state: 'Québec',
        postal: 'G1V 4M1',
        district: 'Québec',
        preposition: 0,
        bussiness: 'true'

    }, {

        label: 'directeur de l’état civil',
        value: 2,
        name: 'DIRECTEUR DE L\'ÉTAT CIVIL',
        title: 'M',
        street: '2050, rue De Bleury',
        city: 'Montréal',
        state: 'Québec',
        postal: 'H3A 2J5',
        district: 'Montréal',
        preposition: 0,
        bussiness: 'true'

    }
    , {

        label: 'revenu Québec (pension alimentaire)',
        value: 3,
        name: 'REVENU QUÉBEC (PENSION ALIMENTAIRE)',
        title: 'M',
        street: 'Complexe Desjardins, Tour Nord, basilaire 1, 150 Saint-Catherine O',
        city: 'Montréal',
        state: 'Québec',
        postal: 'H2X 3Y2;',
        district: 'Montréal',
        preposition: 0,
        bussiness: 'true'

    }
];
const LABEL = 'label';
const VALUE = 'value';

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const DISPLAY = 'display';

export class UsersChildrenDivorce extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: this.props.counter || 0,
            //lang:'fr',
            lang: this.props.lang || 'fr',
            users: [],
            childrenList: [],
            display: this.props.defaultDisplay === false ? false : true
        };

        this.addHeirForm = this.addHeirForm.bind(this);
        this.removeHeirForm = this.removeHeirForm.bind(this);
        this.setSpecialParty = this.setSpecialParty.bind(this);
        this.resetUsers = this.resetUsers.bind(this);
    }

    genLang00000() {
        // to Implement
    }

    genLang000000(text) {
        const lang = (this.state.lang === '' || this.state.lang === null || this.state.lang === undefined) ? 'fr' : this.state.lang;

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    genLang(key = 'missing') {
        const lang = (this.state.lang === '' || this.state.lang === null || this.state.lang === undefined) ? 'fr' : this.state.lang;

        if (typeof key === OBJECT) {
            if (lang && key[lang]) {
                return key[lang];
            } else {
                return '';
            }
        } else {
            if (!this.props.trad) {
                return key;
            }

            if (!this.props.trad[key]) {
                return this.props.trad['wrongKey'][lang] + ' ' + key;
            }

            return this.props.trad[key][lang];
        }

    }

    genLangNew(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    setSpecialParty(value, name) {
        let current = _.find(MIS_EN_CAUSE_OPTIONS, { value: parseInt(value) });

        name.split(this.props.name);
        for (let c in current) {
            if (c === LABEL || c === VALUE) {
                continue;
            }

            this.props.put({
                params: {
                    key: name + '_' + c,
                    value: current[c]
                }
            });

        }

        this.resetUsers();
    }

    resetUsers() {
        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);
        const childrenList = [];

        for (let f in fd) {
            if (f.match(reg)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }

        let arr = [];
        for (let h in obj) {
            let key = this.props.name + '_' + h;
            childrenList.push({
                key: key,
                counter: h
            });
            arr.push(
                <div key={key}>
                    <Row>
                        <UserChildrenDivorce
                            put={this.props.put}
                            lang={this.state.lang}
                            trad={this.props.trad}
                            formData={{ ...this.props.formData }}
                            remove={this.removeHeirForm}
                            name={key}
                            counter={h}
                            ticket={this.props.ticket}
                            parentingTimeGroup={this.props.parentingTimeGroup || false}
                            toggleLoading={this.props.toggleLoading}
                            pushWaitingList={
                                this.props.pushWaitingList
                            }
                            pullWaitingList={
                                this.props.pullWaitingList
                            }
                            questionned={this.props.misEnCause || []}
                            target={this.props.target}
                            noRelation={true}
                            {...this.props}
                        >
                        </UserChildrenDivorce>
                    </Row>
                </div>
            );

        }
        this.setState({
            users: arr,
            counter: arr.length,
            force: false,
            childrenList: childrenList
        });
    }

    componentDidUpdate(prevProps) {
        let props = this.props;
        let obj = {};

        if (props.display !== prevProps.display) {

            if (props.display === false && prevProps.display === true) {
                obj.display = false;
            } else if (props.display === true && prevProps.display === false) {
                obj.display = true;
            } else if (prevProps.display === undefined) {
                obj.display = props.display;
            }

        }

        if (props.formData && JSON.stringify(props.formData) !== JSON.stringify(prevProps.formData) || this.state.force) {
            let fd = this.props.formData;
            let hamza = {};
            let reg = new RegExp('^' + this.props.name);
            const childrenList = [];

            for (let f in fd) {
                if (f.match(reg) && !f.match(DISPLAY)) {

                    let split = f.split(reg);
                    hamza[split[1].match(/\d+/)[0]] = true;
                }
            }

            let arr = [];
            for (let h in hamza) {

                let key = this.props.name + '_' + h;
                childrenList.push({
                    key: key,
                    counter: h
                });

                console.log('kkkkeyyy', key);
                arr.push(
                    <div key={key}>
                        <Row>
                            <div style={{ border: 'solid 2px #454545' }}>{key + '<====='}</div>

                            <UserChildrenDivorce
                                removeFileFromGCloud={this.props.removeFileFromGCloud}
                                public={this.props.public}
                                version={this.props.version}
                                put={this.props.put}
                                lang={this.state.lang}
                                trad={this.props.trad}
                                formData={{ ...this.props.formData }}
                                remove={this.removeHeirForm}
                                name={key}
                                counter={h}
                                target={this.props.target}
                                noRelation={true}
                                ticket={this.props.ticket}
                                title={this.props.title + key}
                                parentingTimeGroup={this.props.parentingTimeGroup || false}
                                toggleLoading={this.props.toggleLoading}
                                pushWaitingList={
                                    this.props.pushWaitingList
                                }
                                pullWaitingList={
                                    this.props.pullWaitingList
                                }
                                questionned={this.props.misEnCause || []}
                                {...this.props}
                            >
                            </UserChildrenDivorce>
                        </Row>
                    </div>
                );
            }
            //
            obj = {
                ...obj,
                users: arr,
                counter: arr.length + 10,
                force: false,
                childrenList: childrenList
            };
        }

        if (props.lang !== prevProps.lang) {

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }

    }

    componentDidMount() {
        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);
        const childrenList = [];

        for (let f in fd) {
            if (f.match(reg) && !f.match(DISPLAY)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }
        ;
        let arr = [];

        for (let h in obj) {
            let key = this.props.name + '_' + h;
            childrenList.push({
                key: key,
                counter: h
            });
            arr.push(<>
                    <div key={key}>
                        <div style={{ border: 'solid 2px #454545' }}>{key + '<====='}</div>
                        <Row>
                            <UserChildrenDivorce
                                ticket={this.props.ticket}
                                public={this.props.public}
                                removeFileFromGCloud={this.props.removeFileFromGCloud}
                                put={this.props.put}
                                lang={this.state.lang}
                                trad={this.props.trad}
                                formData={{ ...this.props.formData }}
                                remove={this.removeHeirForm}
                                name={key}
                                counter={h}
                                target={this.props.target}
                                title={this.props.title}
                                parentingTimeGroup={this.props.parentingTimeGroup || false}
                                toggleLoading={this.props.toggleLoading}
                                pushWaitingList={
                                    this.props.pushWaitingList
                                }
                                pullWaitingList={
                                    this.props.pullWaitingList
                                }
                                questionned={this.props.misEnCause || []}
                                noRelation={true}
                                {...this.props}
                            >
                            </UserChildrenDivorce>
                        </Row>
                    </div>
                </>
            );
        }

        this.setState({
            users: arr,
            childrenList: childrenList,
            // counter:arr.length+10
        });

    }

    addHeirForm() {
        this.setState(state => {

            let counter = this.state.counter;
            let key = this.props.name + '_' + counter++;

            state.counter = counter;
            state.childrenList.push({
                key: key,
                counter: counter
            });

            let province = key + '_state',
                country = key + '_country';

            this.props.put({
                params: {
                    key: province,
                    value: 'Québec'
                }
            });
            this.props.put({
                params: {
                    key: country,
                    value: 'Canada'
                }
            });

            return state;
        });
    }

    removeHeirForm(id) {
        this.props.remove({ params: { key: id } });
        this.setState({
            users: _.reject(this.state.users, { key: id }),
            childrenList: _.reject(this.state.childrenList, { key: id }),
        });
    }

    renderInfo(info, id) {
        if (!info) {
            return '';
        }

        return (
            <>
                <b id={id}>{Question}</b>
                <UncontrolledPopover placement="top" target={id}>
                    <PopoverBody>{info}</PopoverBody>
                </UncontrolledPopover>
            </>
        );
    }

    render() {
        let props = this.props;
        const buttonText = this.genLang('_btnadd');
        const childrenList = this.state.childrenList;
        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';

        } else {
            delete style.display;
        }

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true && window.location.host.match('localhost')) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        console.log('dix play', this.state.display, style);
        return (
            <>
                <FormGroup>
                    {childrenList.map(form => (
                        <div key={form.key}>
                            <Row>
                                <UserChildrenDivorce
                                    {...this.props}
                                    ticket={this.props.ticket}
                                    public={this.props.public}
                                    removeFileFromGCloud={this.props.removeFileFromGCloud}
                                    put={this.props.put}
                                    lang={this.state.lang}
                                    trad={this.props.trad}
                                    formData={{
                                        ...this.props.formData
                                    }}
                                    remove={this.removeHeirForm}
                                    name={form.key}
                                    counter={form.counter}
                                    target={this.props.target}

                                    title={this.props.title}
                                    questionned={this.props.misEnCause || []}
                                    noRelation={true}
                                    toggleLoading={this.props.toggleLoading}
                                    pushWaitingList={
                                        this.props.pushWaitingList
                                    }
                                    pullWaitingList={
                                        this.props.pullWaitingList
                                    }

                                >
                                </UserChildrenDivorce>
                            </Row>
                        </div>
                    ))}
                </FormGroup>
                <FormGroup className="buttonHolder">
                    <Button
                        className="tw-mt-3"
                        block
                        outline
                        color="primary"
                        onClick={() => this.addHeirForm()}
                    >
                        {buttonText || 'Ajouter'}
                    </Button>
                </FormGroup>
            </>
        );
    }
}
