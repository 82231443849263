import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useError } from '@/composables/error';
import { useValidation } from '@/composables/validation';
import { useTranslation } from '@/composables/translation';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { Col, Row } from '@/components/ui/row';
import { CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { type FormValues, type InvoiceFeature, type PostOrgDto, roleOptions } from '@/types/api/orgs';
import { postCreateOrg } from '@/composables/api';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useLanguage } from '@/composables/language';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { ButtonCopy } from '@/components/ui/button-copy';
import { DateTime } from 'luxon';
import { DatePicker } from '@/components/ui/date-picker';
import { OptionalMarker } from '@/components/ui/optional-marker';
import ObjectId from 'bson-objectid';
import { Feature } from '@/composables/features';
import { BillingNeodesk } from '@/components/billing/features/BillingNeodesk';
import { useFormFieldArray } from '@/composables/form';
import { BillingNeoform } from '@/components/billing/features/BillingNeoform';
import { Combobox } from '@/components/ui/combobox';

type ConfirmOrgInfos = Record<'organization' | 'username' | 'password', string> | null;
  interface OrgCreatedAlertProps {
    org: ConfirmOrgInfos;
}

function OrgCreatedAlert({ org }: OrgCreatedAlertProps): JSX.Element | null {
    const { t } = useTranslation('settings.orgs');
    if (!org) return null;
    return (
        <Row className="tw-mt-0 tw-mr-4 tw-mb-4 tw-ml-4">
            <Alert variant="success">
                <AlertTitle>{t('org-created')}</AlertTitle>
                <div className="tw-text-sm tw-flex tw-gap-5">
                    {Object.entries(org).map(([key, value]) => (
                        <AlertDescription key={key} className="tw-flex tw-gap-1 tw-py-1 tw-items-center">
                            <div className="tw-font-semibold">{t(key as keyof typeof org)}:</div>
                            <div>{value}</div>
                            <ButtonCopy
                                className="tw-border-none"
                                size="xs"
                                value={value}
                            />
                        </AlertDescription>
                    ))}
                </div>
            </Alert>
        </Row>
    );
}

export function CreateOrg() {
    const { t, ct } = useTranslation('settings.orgs');
    const error = useError();
    const { required, email, optional } = useValidation();
    const { options } = useLanguage();

    const [userConfirm, setUserConfirm] = useState<ConfirmOrgInfos | null>(null);

    const defaultValues: FormValues = {
        title: '',
        name: '',
        description: '',
        features: [],
        notify: '',
        lang: 'fr',
        user_infos: { firstname: '', lastname: '', email: '', external_id: '', role: '' },
        contract: {
            start_date: DateTime.now(),
            duration_months: 12,
            free_months: 1,
            billing: {
                start_date: DateTime.now().startOf('month').plus({ month: 1 }),
                features_invoice: []
            }
        }
    };
    const form = useForm({
        defaultValues
    });
    const features = [
        { label: t('psj'), value: Feature.NEODESK, Component: BillingNeodesk },
        { label: t('neoform'), value: Feature.NEOFORM, Component: BillingNeoform }
    ];
    const invoiceFeatures = useFormFieldArray<InvoiceFeature, FormValues>({
        form,
        name: 'contract.billing.features_invoice'
    }).map((f) => ({ ...f, feature: features.find(ft => ft.value === f.name) }));
    const pwdRef = useRef('');

    function handleSubmit({ description, name, title, features, notify, lang, user_infos, contract }: FormValues) {
        const postObj: PostOrgDto = {
            org: {
                lang,
                description,
                name,
                folder: name,
                title,
                env: {
                    features: [
                        ...(features.includes(Feature.NEODESK) ? [Feature.NEODESK, Feature.PSJ, Feature.CALENDAR] : []),
                        ...(features.includes(Feature.NEOFORM) ? [Feature.NEOFORM, Feature.NEODOC] : [])
                    ]
                },
                contract: {
                    start_date: contract.start_date,
                    duration_months: contract.duration_months,
                    billing: {
                        start_date: (contract.start_date as DateTime).plus({ months: contract.free_months }),
                        features_invoice: contract.billing.features_invoice.map((feature) => ({
                            ...feature,
                            rules: feature.rules.map((rule) => ({
                                ...rule,
                                ...(feature.name === Feature.NEODESK && {
                                    definition: {
                                        users_roles: {
                                            in: rule.definition.license === 'professional'
                                                ? ['admin']
                                                : ['lawyer']
                                        }
                                    }
                                }),
                                ...(feature.name === Feature.NEOFORM && {
                                    definition: {
                                        first_form_save: true
                                    }
                                })
                            }))
                        }))
                    }
                }
            },
            notify: notify?.length ? notify : null,
            user_infos: user_infos?.email ? user_infos : null,
            is_externe: !!user_infos?.external_id
        };
        return postCreateOrg(postObj)
            .then((res) => {
                const { user } = res.data || null;
                if (user?.lang) {
                    delete user.lang;
                }
                setUserConfirm(user);
                form.reset();
                toast.success(ct('messages.success'));
            })
            .catch(error.handleNetworkError);
    }

    const handleFeatureChange = (feature: Feature) => {
        const features = form.getValues('features') || [];
        let invoiceFeatures = form.getValues('contract.billing.features_invoice') || [];
        let newFeatures: Feature[];
        if (features.includes(feature)) {
            newFeatures = features.filter((f) => f !== feature);
            invoiceFeatures = invoiceFeatures.filter((f) => f.name !== feature);
        } else {
            newFeatures = [...features, feature];
            invoiceFeatures = [
                ...invoiceFeatures,
                {
                    name: feature,
                    rules: [{
                        name: 'rule_0',
                        definition: {
                            ...(feature === Feature.PSJ && { license: null })
                        },
                        stages: [{
                            id: ObjectId().toHexString(),
                            start: 0,
                            unit_price: 0
                        }]
                    }]
                }
            ];
        }
        form.setValue('features', newFeatures);
        form.setValue(
            'contract.billing.features_invoice',
            invoiceFeatures
        );
    };

    return (
        <>
            <CardHeader>
                <CardTitle>{t('create-org')}</CardTitle>
            </CardHeader>
            <Form {...form}>
                <OrgCreatedAlert org={userConfirm} />
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <CardContent className="tw-flex tw-flex-col tw-gap-3">
                        <label className="tw-text-sm tw-font-medium">
                            Informations sur l&apos;organisation
                        </label>
                        <Row>
                            <Col col={6}>
                                <FormField
                                    name="title"
                                    rules={{ validate: { required } }}
                                    render={({ field }) =>
                                        <FormItem className="tw-flex-1">
                                            <FormLabel>{t('title')}</FormLabel>
                                            <FormControl>
                                                <Input
                                                    id=""
                                                    type=""
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                            <Col col={6}>
                                <FormField
                                    name="name"
                                    rules={{ validate: { required } }}
                                    render={({ field }) =>
                                        <FormItem className="tw-flex-1">
                                            <FormLabel>{t('name')}</FormLabel>
                                            <FormControl>
                                                <Input
                                                    id="name"
                                                    type=""
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        pwdRef.current = e.target.value;
                                                    }}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                        </Row>
                        <FormField
                            name="description"
                            rules={{ validate: { required } }}
                            render={({ field }) =>
                                <FormItem className="tw-flex-1">
                                    <FormLabel>{t('description')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="description"
                                            type="textarea"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />
                        <Row>
                            <Col col={6}>
                                <FormField
                                    name="notify"
                                    rules={{ validate: { email: optional(email) } }}
                                    render={({ field }) =>
                                        <FormItem className="tw-flex-1">
                                            <FormLabel>
                                                {t('notify')}
                                                <OptionalMarker/>
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    id="notify"
                                                    type="textarea"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                            <Col col={6}>
                                <FormField
                                    name="lang"
                                    rules={{ validate: { required } }}
                                    render={({
                                        field: {
                                            ref,
                                            ...field
                                        }
                                    }) =>
                                        <FormItem className="tw-flex-1">
                                            <FormLabel>{ct('language')}</FormLabel>
                                            <FormControl>
                                                <Select {...field} onValueChange={field.onChange}>
                                                    <SelectTrigger>
                                                        <SelectValue id="lang" ref={ref}></SelectValue>
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        {options.map(o =>
                                                            <SelectItem key={o.value} value={o.value}>
                                                                {o.label}
                                                            </SelectItem>
                                                        )}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                        </Row>

                        <label className="tw-text-sm tw-font-medium">
                            {t('default-user')}
                            <OptionalMarker/>
                        </label>
                        <Row>
                            <Col col={6}>
                                <FormField
                                    name="user_infos.firstname"
                                    render={({ field }) =>
                                        <FormItem className="tw-flex-1">
                                            <FormLabel>
                                                {ct('first-name')}
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    id="firstname"
                                                    type="textarea"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                            <Col col={6}>
                                <FormField
                                    name="user_infos.lastname"
                                    render={({ field }) =>
                                        <FormItem className="tw-flex-1">
                                            <FormLabel>
                                                {ct('last-name')}
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    id="lastname"
                                                    type="textarea"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col col={6}>
                                <FormField
                                    name="user_infos.email"
                                    rules={{ validate: { email: optional(email) } }}
                                    render={({ field }) =>
                                        <FormItem className="tw-flex-1">
                                            <FormLabel>
                                                {ct('email')}
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    id="email"
                                                    type="textarea"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                            <Col col={3}>
                                <FormField
                                    name="user_infos.role"
                                    render={({
                                        field: {
                                            ref,
                                            ...field
                                        }
                                    }) =>
                                        <FormItem className="tw-flex-1">
                                            <FormLabel>{t('role')}</FormLabel>
                                            <FormControl>
                                                <Select {...field} onValueChange={field.onChange}>
                                                    <SelectTrigger>
                                                        <SelectValue id="role" ref={ref}></SelectValue>
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        {roleOptions.map(o =>
                                                            <SelectItem key={o} value={o}>
                                                                {o}
                                                            </SelectItem>
                                                        )}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                            <Col col={3}>
                                <FormField
                                    name="user_infos.external_id"
                                    render={({ field }) =>
                                        <FormItem className="tw-flex-1">
                                            <FormLabel>
                                                {t('external-id')}
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    id="external_id"
                                                    type="textarea"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                        </Row>

                        <label className="tw-text-sm tw-font-medium">
                            Facturation
                        </label>
                        <Row>
                            <Col col={6}>
                                <FormField
                                    name="contract.start_date"
                                    rules={{ validate: { required } }}
                                    render={({ field }) =>
                                        <FormItem>
                                            <FormLabel>
                                                Date de début du contrat
                                            </FormLabel>
                                            <FormControl>
                                                <DatePicker
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                            <Col col={6}>
                                <FormField
                                    name="contract.duration_months"
                                    rules={{ validate: { required } }}
                                    render={({ field }) =>
                                        <FormItem>
                                            <FormLabel>
                                                Durée du contrat en mois
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            </Col>
                        </Row>
                        <FormField
                            name="contract.free_months"
                            rules={{ validate: { required } }}
                            render={({ field: { ref, ...field }, fieldState }) =>
                                <FormItem>
                                    <FormLabel>
                                        Nombre de mois gratuits
                                    </FormLabel>
                                    <FormControl>
                                        <Combobox 
                                            options={[1,2,3,4,5,6,7,8,9,10,11,12]}
                                            {...field}
                                            innerRef={ref}
                                            error={!!fieldState.error}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />

                        <FormField
                            name="features"
                            render={({ field }) =>
                                <FormItem className="tw-flex-1">
                                    <FormLabel className="tw-font-semibold">
                                        {t('features')}
                                    </FormLabel>
                                    <FormControl>
                                        <Row className="!tw-mt-3">
                                            {features.map((feature) => (
                                                <Col
                                                    col={Math.floor(12 / features.length)}
                                                    key={feature.value}
                                                    className="tw-flex tw-items-center tw-gap-2 tw-mb-1"
                                                >
                                                    <Checkbox
                                                        id={feature.value}
                                                        checked={field.value?.includes(feature.value)}
                                                        onCheckedChange={() => handleFeatureChange(feature.value)}
                                                    />
                                                    <Label htmlFor={feature.value}>
                                                        {feature.label}
                                                    </Label>
                                                </Col>
                                            ))}
                                        </Row>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />

                        {invoiceFeatures.map((feature, idx) => <div
                            key={feature.id} className="tw-flex tw-flex-col tw-gap-2"
                        >
                            <label className="tw-font-medium tw-text-sm">
                                {feature.feature?.label}
                            </label>
                            <FormField
                                name={`contract.billing.features_invoice.${idx}`}
                                render={feature.feature?.Component as any}
                            />
                        </div>)}
                    </CardContent>
                    <CardFooter className="tw-flex-col tw-gap-2">
                        <ButtonSubmit className="tw-self-stretch">
                            {t('submit')}
                        </ButtonSubmit>
                    </CardFooter>
                </form>
            </Form>
        </>
    );
}
