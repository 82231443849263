import React, { useState } from 'react';
import { FolderContext } from '@/pages/psj/Folder';
import { type Note } from '@/types/api/note';
import { type NeoFormLayout } from '@/types/neoform';
import { Notes } from '@/components/psj/notes/Notes';

export function UnassignedNotes() {
    const [notes, setNotes] = useState<Note[]>([]);
    return (
        <FolderContext.Provider value={{
            folder_id: '',
            notes,
            users: [],
            groups: [],
            macros: [],
            next_steps: [],
            parties: [],
            files: [],
            input: {
                data: {},
                layout: {} as NeoFormLayout
            },
            loading: false,
            setParties: () => {},
            setCaseInfo: () => {},
            setNotes,
            setFiles: () => {},
            setInput: () => {}
        }}>
            <main className="tw-p-4 tw-flex tw-flex-col tw-gap-3">
                <Notes
                    unassignedNotes
                    options={{
                        pin: false,
                        transfer: true,
                        delete: true
                    }}
                />
            </main>
        </FolderContext.Provider>
    );
}
