import React, { Component } from 'react';

import Select from 'react-select/creatable';

import { NotesInput } from './NotesInput';
import _ from 'lodash';
import { Col, FormGroup, Label, } from 'reactstrap';

import { RequestThatField } from './RequestThatField';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const DEFAULT_STYLE = {
    textAlign: 'left'
};

export class Select2Creatable extends Component {

    constructor(props) {
        super(props);

        let fd = this.props.formData;
        let isNotUndefined = !!this.props.formData && this.props.formData[this.props.name] !== undefined;

        if (isNotUndefined && this.props.options && !_.find(this.props.options, { value: fd[this.props.name] })) {
            this.state = {
                value: {
                    label: this.genLang(fd[this.props.name], this.props.lang),
                    value: fd[this.props.name],
                }
            };
        } else if (isNotUndefined && this.props.options && _.find(this.props.options, { value: fd[this.props.name] })) {
            this.state = {
                value: _.find(this.props.options, { value: fd[this.props.name] })
            };
            this.state.value.label = this.genLang(this.state.value.label, this.props.lang);
        } else if (this.props.default !== undefined && !isNotUndefined) {

            this.state = {
                value: this.props.default !== undefined ? _.find(this.props.options, { value: this.props.default }) : (props.isMulti) ? [] : {}
            };

            this.state.value.label = this.genLang(this.state.value.label, this.props.lang);

            this.props.put({
                params: {
                    key: props.name,
                    value: this.props.isMulti ? this.props.default : this.state.value.value
                }
            });
        } else {
            this.state = {
                value: isNotUndefined ? fd[this.props.name] : (props.isMulti) ? [] : {},
                focus: false
            };
        }
        this.state.display = this.props.defaultDisplay === false ? false : true;

        if (!props.name) {
            console.error('!!! name is missing !!!');
        }

        if (!props.title) {
            console.error('!!! title is missing !!!');
        }

        if (!props.title) {
            console.error('!!! no options for a select O_o?? !!!');
        }

        this.onChange = this.onChange.bind(this);
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {

            let value = props.formData && props.formData[this.props.name];
            if (this.props.default !== undefined && !props.formData[this.props.name]) {

                obj['value'] = _.find(this.props.options, { value: this.props.default });
                // debugger
            } else {
                obj['value'] = props.formData && (value === 0 || value) ? value : (props.isMulti) ? [] : {};
                obj.value = _.find(this.props.options, { value: obj.value });
            }
            // debugger;
            if (JSON.stringify(obj.value) === '{}') {
                delete obj['value'];
            }

            if (obj.value && obj.value.value !== undefined) {

                props.put({
                    params: {
                        key: props.name,
                        value: obj.value.value
                    }
                });
                obj.value.label = this.genLang(obj.value.label, this.props.lang);
            }
        }

        if (JSON.stringify(props.formData[props.name]) !== JSON.stringify(prevprops.formData[props.name]) && !obj.value) {

            if (props.isMulti) {

                let values = props.formData[props.name] || [];
                let form = _.map(values, (e) => {
                    if (!e) {
                        return;
                    }
                    let find = _.find(props.options, { value: e.value });
                    if (find) {
                        return find;
                    } else if (e.value) {
                        return {
                            value: e.value,
                            label: e.value
                        };
                    }

                });
                obj['value'] = form;

                for (let v in obj.value) {
                    obj.value[v].label = this.genLang(obj.value[v].label, this.props.lang);
                }

                if (form && form.length) {
                    props.put({
                        params: {
                            key: props.name,
                            value: form
                        }
                    });
                }
            } else {

                obj.value = _.find(props.options, {
                    value: props.formData[props.name]
                });

                if (obj && obj.value) {
                    obj.value.label = this.genLang(obj.value.label, this.props.lang);
                } else {
                    obj.value = {
                        value: props.formData[props.name],
                        label: props.formData[props.name]
                    };
                }

                if (!props.isMulti && obj.value && obj.value.value) {

                    props.put({
                        params: {
                            key: props.name,
                            value: Array.isArray(obj.value.value) ? obj.value.value[0] : obj.value.value,
                        },
                    });
                }
            }

            if (!obj.value && obj.value < 0) {
                if (props.isMulti) {
                    obj.value = [{
                        value: props.formData[props.name],
                        label: props.formData[props.name]
                    }];
                } else {
                    obj.value = {
                        value: props.formData[props.name],
                        label: props.formData[props.name]
                    };
                }
            }

        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    onChange(e) {
        this.setState({
            value: e
        });

        if (!e || JSON.stringify(e.value) === '{}' || JSON.stringify(e.value) === '[]') {
            this.props.remove({ params: { key: this.props.name } });
            return false;
        }

        if (e.value !== undefined) {
            this.props.put({
                params: {
                    key: this.props.name,
                    value: e.value
                }
            });
        } else if (Array.isArray(e)) {
            this.props.put({
                params: {
                    key: this.props.name,
                    value: e
                }
            });
        }

    }

    render() {
        let props = this.props;
        let style = {};

        let empty = this.state.value;

        if (Array.isArray(empty)) {
            empty = !this.state.value.length;
        } else if (JSON.stringify(empty) === '{}') {
            empty = true;
        } else {
            empty = !(this.state.value || this.state.value === 0);
        }

        if (this.state.focus || this.props.placeholder) {
            empty = false;
        }

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        let options = [...props.options];

        options = _.map(options, (e) => {
            return {
                ...e,
                label: this.genLang(e.label, this.state.lang),
                value: e.value
            };
        });

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let default_value = this.props.isMulti ? [] : {};

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        return (
            <>
                <FormGroup>
                    <Select
                        Component
                        placeholder={'magie des réservoirs'}
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({
                                ...provided,
                                zIndex: props.zIndex || 9999,
                                position: 'relative'
                            })
                        }}

                        noOptionsMessage={this.genLang(this.props.noOptionsMessage || 'no data')}
                        id={this.props.name}
                        tabIndex={props.tabindex}
                        onChange={(e) => this.onChange(e)}
                        onFocus={() => {
                            this.setState({ focus: true });
                        }
                        }
                        onBlur={() => {
                            this.setState({ focus: false });
                        }
                        }
                        options={options || []}
                        value={this.state.value || default_value}
                        // defaultValue={_.filter(this.props.options,_.map(this.state))}
                        name={this.props.name}
                        isClearable
                        isSearchable
                        {...this.props.isMulti ? { isMulti: true } : {}}
                    />
                </FormGroup>
            </>
        );
    }

}
