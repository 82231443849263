import {
    type FieldValues,
    type FieldArrayPath,
    useFieldArray,
    type UseFormReturn,
    type UseFieldArrayProps, type Path, useFormContext
} from 'react-hook-form';

interface Options<
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>
> extends UseFieldArrayProps<TFieldValues, TFieldArrayName> {
    name: TFieldArrayName;
    form?: UseFormReturn<TFieldValues>;
}

export function useFormFieldArray<
    TArrayType = any,
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>
>({ form, ...opts }: Options<TFieldValues, TFieldArrayName>) {
    form = form ?? useFormContext();
    const { fields } = useFieldArray({ ...opts, control: form.control });
    const values = (form.watch(opts.name as Path<TFieldValues>) ?? []) as TArrayType[];
    return fields.map((field, idx) => ({
        ...field,
        ...values[idx]
    }));
}
