import React from 'react';
import { cn } from '@/lib/utils';
import { useDropzone } from 'react-dropzone';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useControllableState } from '@/composables/controllable';
import ObjectId from 'bson-objectid';
import { useTranslation } from '@/composables/translation';

export interface UploadedFile {
    id: string;
    filename: string;
    content_type: string;
    data: Blob;
    url?: string;
}

interface Props {
    id?: string;
    className?: string;
    children?: React.ReactNode;
    accept?: Record<string, string[]>;
    value?: UploadedFile[];
    onChange?: React.Dispatch<UploadedFile[]>;
    maxFiles?: number;
    multiple?: boolean;
}

const MESSAGE_DEFAULT = {
    en: '<b>Click to upload</b> or drag and drop files',
    fr: '<b>Cliquez pour téléverser</b> ou faites glisser et déposez des fichiers'
};

export function InputFile(props: Props) {
    const { to } = useTranslation();
    const [files, setFiles] = useControllableState<UploadedFile[]>([], props.value, props.onChange as any);
    const { getRootProps, getInputProps } = useDropzone({
        accept: props.accept,
        multiple: props.multiple,
        maxFiles: props.maxFiles,
        onDropAccepted: (acceptedFiles: File[]) => {
            setFiles(files.concat(acceptedFiles.map((f) => ({
                id: ObjectId().toHexString(),
                filename: f.name,
                content_type: f.type,
                data: f
            }))));
        }
    });
    const { onClick, ...rootProps } = getRootProps();
    const inputProps = getInputProps();
    return (
        <label
            className={cn(
                'tw-p-16 tw-rounded-lg',
                'tw-border-dashed tw-border-2 tw-border-slate-300',
                'hover:tw-bg-slate-100 hover:tw-border-slate-600',
                'tw-flex tw-flex-col tw-items-center tw-justify-center',
                'tw-cursor-pointer tw-text-slate-600 tw-text-center',
                props.className
            )}
            {...rootProps}
            htmlFor={props.id}
        >
            <input id={props.id} type="file" {...inputProps} />
            <FontAwesomeIcon
                className="tw-text-5xl tw-text-slate-400 tw-mb-4"
                icon={faCloudArrowUp}
            />
            {props.children
                ? props.children
                : <div dangerouslySetInnerHTML={{ __html: to(MESSAGE_DEFAULT) }}/>}
        </label>
    );
}
