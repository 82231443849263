import { useUserStore } from '@/store/user';
import { redirect } from 'react-router-dom';
import { isJWT, popReturnUrl } from '@/composables/utils';
import { type LoaderFunction } from '@remix-run/router/utils';

enum AuthStatus {
    AUTHORIZED = 'authorized',
    ACTION_REQUIRED = 'action_required',
    UNAUTHORIZED = 'unauthorized'
}

export const AuthSSOLoader: LoaderFunction = ({ request }) => {
    const url = new URL(request.url);
    const token = url.searchParams.get('token');
    const user_str = (url.searchParams.get('user') ?? '{}')
        .replaceAll("'", '"')
        .replaceAll('False', 'false')
        .replaceAll('True', 'true');
    const user = JSON.parse(user_str);
    const status = (url.searchParams.get('status') as AuthStatus) ?? AuthStatus.UNAUTHORIZED;
    switch (status) {
    case AuthStatus.AUTHORIZED:
        if (token && isJWT(token)) {
            useUserStore.getState().setToken(token, user.logo);
        }
        return redirect(popReturnUrl());
    case AuthStatus.ACTION_REQUIRED:
        return redirect('/action-required');
    case AuthStatus.UNAUTHORIZED:
        return redirect('/unauthorized');
    }
    return null;
};
