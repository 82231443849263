import type React from 'react';
import { useState } from 'react';

export function useControllableState<T>(
    defaultValue: T,
    propValue?: T,
    propSetter?: React.Dispatch<React.SetStateAction<T>>
) {
    const [value, setter] = useState<T>(defaultValue);
    return [propValue ?? value, propSetter ?? setter] as const;
}
