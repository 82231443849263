import React from 'react';
import { useTranslation } from '@/composables/translation';
import { useSettingsStore } from '@/store/settings';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import useHtml from '@/composables/html';

export function Footer() {
    const { t, ct } = useTranslation('footer');
    const templates = useSettingsStore(state => state.templates);
    const html = templates?.assistance_footer?.html ?? '';
    const sanitizedHtml = useHtml(html);
    const button = (
        <Button variant="link" className="!tw-text-base !tw-p-0">
            {ct('click-here').toLowerCase()}
        </Button>
    );
    return (
        <div className="tw-bg-slate-200 tw-p-1 tw-px-4 tw-z-10 tw-h-[44px] print:tw-hidden">
            {t('help')}
            {html
                ? <Dialog>
                    <DialogTrigger asChild>
                        {button}
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>{t('title')}</DialogTitle>
                        </DialogHeader>
                        <div
                            className="tw-prose tw-text-muted-foreground"
                            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                        />
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button>
                                    {ct('ok')}
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
                : button
            }
            .
        </div>
    );
}
