import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    faCheck,
    faCopy,
    faEnvelope,
    faHouse,
    faPhone,
    faUser,
    type IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@/composables/translation';
import { FolderContext } from '@/pages/psj/Folder';
import { CardCollapse } from '@/components/CardCollapse';
import { CardContent } from '@/components/ui/card';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { type GetConflictDto, type Party, type PartyType } from '@/types/api/party';
import { writeClipboardText } from '@/composables/clipboard';
import { cn } from '@/lib/utils';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog';
import { OpenInNewWindowIcon, Pencil2Icon } from '@radix-ui/react-icons';
import { CrudInputType, type CrudSchema, CrudTable, type UpdateDialogProps } from '@/components/ui/crud-table';
import { clone } from '@/composables/utils';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Combobox } from '@/components/ui/combobox';
import {
    getClientConflict,
    getPartyTypesList,
    postAddNote,
    genForgotPasswordLinkForParty
} from '@/composables/api';
import { Col, Row } from '@/components/ui/row';
import { getFieldError, useValidation } from '@/composables/validation';
import { BindProps } from '@/components/utils/BindProps';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { useUserStore } from '@/store/user';
import { toast } from 'react-toastify';
import { useError } from '@/composables/error';
import { Badge } from '@/components/ui/badge';
import { PartyConflictDialog } from '@/components/psj/PartyConflictDialog';
import { MetadataTags } from '@/components/psj/MetadataTags';
import { getPartyName } from '@/composables/party';
import { PartySelect } from '@/components/psj/party/PartySelect';
import { Permission, usePermissions } from '@/composables/permissions';
import { InputUrl } from '../ui/input-url';
import { Label } from '../ui/label';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Link } from 'react-router-dom';
import axios from 'axios';

const SUMMARY_FIELDS: Array<{
    accessor: keyof Party;
    icon?: IconDefinition;
    render?: (value: Party) => string;
}> = [
    {
        accessor: 'fullname',
        icon: faUser
    },
    {
        accessor: 'phone',
        icon: faPhone,
        render: (i) => {
            return [i.phone, i.extension]
                .filter((i) => i)
                .join(' ext. ');
        }
    },
    {
        accessor: 'email',
        icon: faEnvelope
    },
    {
        accessor: 'address',
        icon: faHouse,
        render: (i) => {
            return [i.address.street, i.address.city, i.address.province, i.address.country]
                .filter((i) => i)
                .join(', ');
        }
    }
];

export function Parties() {
    const { to } = useTranslation('psj.parties');
    const context = useContext(FolderContext);
    const [partyTypes, setPartyTypes] = useState<PartyType[]>([]);
    useEffect(() => {
        getPartyTypesList({ limit: 10000 })
            .then((res) => setPartyTypes(res.data));
    }, []);
    return (
        <>
            <CardCollapse
                innerTitle
                title={to({
                    en: 'Parties',
                    fr: 'Parties'
                })}
                titleSlot={
                    <div className="tw-ml-auto">
                        <CrudParties partyTypes={partyTypes} />
                    </div>
                }
            >
                <CardContent className="tw-px-4 tw-pb-2">
                    {context?.loading
                        ? (
                            <div className="tw-flex tw-justify-center tw-pt-2 tw-pb-4">
                                <Spinner className="tw-text-primary"/>
                            </div>
                        )
                        : <Accordion type="multiple">
                            {context?.parties?.map((p) => (
                                <PartyInfo
                                    key={`${p._id}-${p.type}`}
                                    partyTypes={partyTypes}
                                    party={p}
                                />
                            ))}
                        </Accordion>}
                </CardContent>
            </CardCollapse>
        </>
    );
}

interface PartyInfoProps {
    partyTypes: PartyType[];
    party: Party;
}

function PartyInfo({ partyTypes, party }: PartyInfoProps) {
    const { to, ct } = useTranslation();
    const partyType = partyTypes.find(t => t.name === party.type);
    const clientUrl = useMemo(() => axios.getUri({
        url: '/dashboard',
        params: {
            tab: `${ct('folders')} - ${party.fullname}`,
            party_id: party._id,
            my_folders: false
        }
    }), [party]);
    return (
        <AccordionItem className="last:tw-border-0" value={`${party._id}-${party.type}`}>
            <AccordionTrigger className="!tw-py-1 tw-text-zinc-900 tw-font-normal tw-text-sm">
                <div className="tw-flex tw-items-center tw-gap-2">
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button variant="ghost" className="!tw-size-7 !tw-p-0" asChild>
                                <Link to={clientUrl} target="_blank" onClick={(e) => e.stopPropagation()}>
                                    <OpenInNewWindowIcon />
                                </Link>
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {ct('open')}
                        </TooltipContent>
                    </Tooltip>
                    {partyType &&
                        <Badge
                            variant="secondary" size="sm"
                            className="tw-bg-slate-200 hover:tw-bg-slate-200"
                        >
                            {to(partyType.title)}
                        </Badge>
                    }
                    <span className="tw-font-medium">{getPartyName(party)}</span>
                </div>
            </AccordionTrigger>
            <AccordionContent className="!tw-pb-1">
                {SUMMARY_FIELDS.map((f, i) => {
                    const value: any = f.render
                        ? f.render(party)
                        : party[f.accessor];
                    if (!value) {
                        return null;
                    }
                    return (
                        <div
                            className="tw-mb-1 tw-flex tw-justify-between tw-items-center"
                            key={i}
                        >
                            <SummaryItem icon={f.icon} value={value} />
                        </div>
                    );
                })}
                {party.metadata && <MetadataTags value={party.metadata} />}
            </AccordionContent>
        </AccordionItem>
    );
}

interface SummaryItemProps {
    value: string | number;
    icon?: IconProp;
    renderedValue?: React.ReactNode;
}

export function SummaryItem(props: SummaryItemProps) {
    const { ct } = useTranslation();
    const [hover, setHover] = useState(false);
    const [copied, setCopied] = useState(false);
    function handleCopyText() {
        writeClipboardText(String(props.value)).then();
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }
    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {props.icon && (
                <FontAwesomeIcon
                    width="1em"
                    height="1em"
                    className="tw-text-primary tw-mr-2"
                    icon={props.icon}
                />
            )}
            {props.renderedValue ?? props.value}
            {hover && (
                <Tooltip>
                    <TooltipTrigger asChild>
                        <FontAwesomeIcon
                            className={cn(
                                'tw-cursor-pointer tw-ml-2 tw-text-muted-foreground',
                                copied && 'tw-text-success'
                            )}
                            icon={copied ? faCheck : faCopy}
                            onClick={handleCopyText}
                        />
                    </TooltipTrigger>
                    <TooltipContent>
                        {copied ? ct('copied') : ct('copy')}
                    </TooltipContent>
                </Tooltip>
            )}
        </div>
    );
}

interface UpdatePartyDialogProps extends UpdateDialogProps<Party> {
    parties: Party[];
    partyTypes: PartyType[];
}

function UpdatePartyDialog(props: UpdatePartyDialogProps) {
    interface FormType { type: string; client: Party }
    const { to, ct, t } = useTranslation('psj.parties.update-dialog');
    const { required } = useValidation();
    const isUpdateDialog = props.isUpdateDialog;
    const form = useForm<FormType>({
        defaultValues: {
            type: props.value?.type as string,
            client: props.value as Party
        }
    });
    const [conflictOpen, setConflictOpen] = useState(false);
    const [conflictClient, setConflictClient] = useState<Party>();
    const [conflictCases, setConflictCases] = useState<GetConflictDto['cases']>();

    useEffect(() => {
        if (props.value) {
            form.reset({
                type: props.value.type,
                client: props.value
            });
        }
    }, [props.value]);

    async function onSubmit({ type, client }: FormType) {
        try {
            const res = await getClientConflict({ party_type_name: type, party_id: client._id });
            if (res.data.has_conflict_of_interest) {
                setConflictCases(res.data.cases);
                setConflictClient(client);
                setConflictOpen(true);
                return;
            }
        } catch {}
        return await props.onSubmit?.({ ...client, type });
    }

    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {isUpdateDialog
                            ? ct('edit')
                            : ct('add')}
                    </DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={(event) => {
                        event.stopPropagation();
                        form.handleSubmit(onSubmit)(event);
                    }}>
                        <Row className="tw-pb-6">
                            <Col col={4}>
                                <FormField
                                    name="type"
                                    render={({ field: { ref, ...field }, formState }) => (
                                        <FormItem>
                                            <FormLabel>{t('fields.type')}</FormLabel>
                                            <FormControl>
                                                <Combobox<PartyType, string>
                                                    options={props.partyTypes}
                                                    getOptionValue={(opt) => opt.name}
                                                    getOptionLabel={(opt) => to(opt.title)}
                                                    {...field}
                                                    innerRef={ref}
                                                    error={!!getFieldError(field.name, formState)}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                    rules={{ validate: { required } }}
                                />
                            </Col>
                            <Col col={8}>
                                <FormField
                                    name="client"
                                    render={(props) => PartySelect({
                                        ...props,
                                        label: t('fields.party')
                                    })}
                                    rules={{ validate: { required } }}
                                />
                            </Col>
                        </Row>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary">{ct('cancel')}</Button>
                            </DialogClose>
                            <ButtonSubmit>
                                {isUpdateDialog ? ct('edit') : ct('add')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
                <PartyConflictDialog
                    open={conflictOpen}
                    onOpenChange={setConflictOpen}
                    client={conflictClient}
                    cases={conflictCases}
                    onSubmit={() => {
                        const { client, type } = form.getValues();
                        return props.onSubmit?.({ ...client, type });
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}

interface CrudPartiesProps {
    partyTypes: PartyType[];
}

function CrudParties({ partyTypes }: CrudPartiesProps) {
    const { to, ct } = useTranslation();
    const { required } = useValidation();
    const { handleNetworkError } = useError();
    const { lang } = useUserStore(state => ({ lang: state.lang }));
    const folder = useContext(FolderContext);
    const [open, setOpen] = useState(false);
    const form = useForm({
        defaultValues: {
            parties: [] as Party[]
        }
    });
    const parties = form.watch('parties');
    useEffect(() => {
        if (folder?.parties) {
            form.reset({
                parties: clone(folder.parties)
            });
        }
    }, [folder?.parties, open]);
    const schema = useMemo<CrudSchema<Party>>(() => [
        {
            id: '_id',
            type: CrudInputType.TEXT,
            name: 'ID',
            columnDef: {
                id: '_id',
                header: 'ID',
                accessorKey: '_id'
            }
        },
        {
            id: 'type',
            type: CrudInputType.TEXT,
            name: 'Type',
            columnDef: {
                id: 'type',
                header: 'Type',
                accessorKey: 'type',
                cell: ({ cell }) => to(partyTypes.find(p => p.name === cell.getValue<string>())?.title)
            }
        },
        {
            id: 'firstname',
            type: CrudInputType.TEXT,
            name: 'First Name',
            col: 6,
            columnDef: {
                id: 'firstname',
                header: 'First Name',
                accessorKey: 'firstname'
            }
        },
        {
            id: 'lastname',
            type: CrudInputType.TEXT,
            name: 'Last Name',
            col: 6,
            columnDef: {
                id: 'lastname',
                header: 'Last Name',
                accessorKey: 'lastname'
            }
        },
        {
            id: 'phone',
            type: CrudInputType.TEXT,
            name: 'Phone number',
            col: 4,
            columnDef: {
                id: 'phone',
                header: 'Phone number',
                accessorKey: 'phone'
            }
        },
        {
            id: 'email',
            type: CrudInputType.TEXT,
            name: 'Email',
            col: 8,
            columnDef: {
                id: 'email',
                header: 'Email',
                accessorKey: 'email'
            }
        }
    ], [partyTypes]);
    const DialogComponent = useMemo(
        () => BindProps(UpdatePartyDialog, { partyTypes, parties }),
        [partyTypes, parties]
    );
    function handleSubmit({ parties }: { parties: Party[] }) {
        return postAddNote({
            case: {
                _id: folder?.case?._id ?? '',
                parties: parties.map((p) => ({ _id: p._id, type: p.type ?? '' }))
            },
            note: '',
            type: 'update',
            lang,
            is_public: false,
            active: true
        })
            .then(() => {
                setOpen(false);
                toast(ct('messages.success'), { type: 'success' });
                folder?.setParties(clone(parties));
            })
            .catch(handleNetworkError);
    }
    return (
        <Tooltip>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    <TooltipTrigger asChild>
                        <Button
                            className="!tw-rounded-full tw-text-primary hover:tw-text-primary"
                            variant="ghost" size="icon"
                            disabled={folder?.loading}
                        >
                            <Pencil2Icon className="tw-size-4" />
                        </Button>
                    </TooltipTrigger>
                </DialogTrigger>
                <DialogContent className="md:tw-max-w-5xl">
                    <DialogHeader>
                        <DialogTitle>Parties</DialogTitle>
                    </DialogHeader>
                    <Form {...form}>
                        <form onSubmit={(event) => {
                            event.stopPropagation();
                            form.handleSubmit(handleSubmit)(event);
                        }}>
                            <div className="tw-pb-6">
                                <FormField
                                    name="parties"
                                    render={({ field, formState }) => (
                                        <FormItem>
                                            <FormControl>
                                                <CrudTable<Party, '_id'>
                                                    actions={({ row }) => <ResetPartyPassword party={row?.original} />}
                                                    idKey="_id"
                                                    schema={schema}
                                                    getRowId={(row) => `${row._id}-${row.type}`}
                                                    initialState={{ columnVisibility: { _id: false } }}
                                                    enablePagination={false}
                                                    list={field.value}
                                                    onRead={async() => folder?.parties ?? []}
                                                    onCreate={async(value) => field.onChange([
                                                        ...field.value,
                                                        value as Party
                                                    ])}
                                                    onUpdate={async(value) => {
                                                        const parties = field.value as Party[];
                                                        const partyIndex = parties
                                                            .findIndex((p) => p._id === value._id);
                                                        if (partyIndex >= 0) {
                                                            parties.splice(partyIndex, 1, value);
                                                            field.onChange([...parties]);
                                                        }
                                                    }}
                                                    onDelete={async(values) => {
                                                        const parties = field.value as Party[];
                                                        const newParties = parties.filter(p =>
                                                            !values.find(v => v._id === p._id && v.type === p.type)
                                                        );
                                                        field.onChange(newParties);
                                                    }}
                                                    dialogComponent={DialogComponent}
                                                    error={!!getFieldError(field.name, formState)}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                    rules={{ validate: { required } }}
                                />
                            </div>
                            <DialogFooter>
                                <DialogClose asChild>
                                    <Button variant="secondary">{ct('cancel')}</Button>
                                </DialogClose>
                                <ButtonSubmit>{ct('save')}</ButtonSubmit>
                            </DialogFooter>
                        </form>
                    </Form>
                </DialogContent>
            </Dialog>
            <TooltipContent>
                {to({
                    en: 'Edit parties',
                    fr: 'Modifier les parties'
                })}
            </TooltipContent>
        </Tooltip>
    );
}

interface ResetPartyPasswordProps {
    party: Party;
}

function ResetPartyPassword({ party }: ResetPartyPasswordProps) {
    const [open, setOpen] = useState(false);
    const [link, setLink] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const { hasPermissions } = usePermissions();
    const user = useUserStore(state => state.user);
    const isAdmin = hasPermissions(Permission.PSJ_ADMIN_PANEL);
    const { to } = useTranslation();

    const genResetUrl = () => {
        setLoading(true);
        genForgotPasswordLinkForParty(user?.org_id ?? '', party.email)
            .then((res) => {
                if (res?.data?.success) {
                    setLink(res?.data?.url ?? '');
                    toast.success(to({ en: 'Link generated!', fr: 'Lien généré!' }));
                }
            }).catch(e => {
                console.error(e);
                toast.success(to({ en: 'Unable to generate link', fr: 'Impossible de générer le lien' }));
            }).finally(() => {
                setLoading(false);
            });
    };

    const prompt = `${to({
        en: 'Generate a password reset link for',
        fr: 'Générez un lien de réinitialisation de mot de passe pour'
    })} ${party?.firstname ?? ''} ${party.lastname ?? ''} ${party.email && `(${party.email})`}`;

    if (!isAdmin) return null;
    return (
        <Tooltip>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    <TooltipTrigger asChild>
                        <Button
                            onClick={() => setOpen(!open)}
                            variant="ghost" size="icon"
                            className='tw-'
                        >
                            <LockResetIcon style={{ color: '#3B3B3B' }} />
                        </Button>
                    </TooltipTrigger>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>
                            {to({
                                en: 'Resetting a party\'s password',
                                fr: 'Réinitialisation du mot de passe d\'une partie'
                            })}
                        </DialogTitle>
                    </DialogHeader>
                    <div>{prompt}</div>
                    {link &&
                        <div>
                            <Label htmlFor="url">
                                {to({
                                    en: 'Here is the URL, be sure to send it to the party',
                                    fr: 'Voici l\'URL, assurez-vous de l\'envoyer à la partie'
                                })}
                            </Label>
                            <InputUrl id="url" value={link} />
                        </div>
                    }
                    <Button onClick={genResetUrl} variant={'default'} loading={loading}>
                        {to({ en: 'Generate link', fr: 'Générer un lien' })}
                    </Button>
                </DialogContent>
            </Dialog>
            <TooltipContent>
                {to({ en: 'Reset password', fr: 'Réinitialiser le mot de passe' })}
            </TooltipContent>
        </Tooltip>
    );
}
