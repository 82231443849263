import { useMemo } from 'react';
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

export function sanitizeHtml(html: string) {
    const decodedHtml = decode(html);
    return DOMPurify.sanitize(decodedHtml);
}

export function useHtml(html: string) {
    return useMemo(() => sanitizeHtml(html), [html]);
}

export default useHtml;
