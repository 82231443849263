import * as React from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';

import { cn } from '@/lib/utils';

const HoverCard = HoverCardPrimitive.Root;

const HoverCardTrigger = HoverCardPrimitive.Trigger;

const HoverCardContent = React.forwardRef<
    React.ElementRef<typeof HoverCardPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Content>
>(({
    className,
    align = 'center',
    sideOffset = 4,
    ...props
}, ref) => (
    <HoverCardPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
            'tw-z-50 tw-w-64 tw-rounded-md tw-border tw-bg-popover tw-p-4 tw-text-popover-foreground tw-shadow-md',
            'tw-outline-none data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out',
            'data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95',
            'data-[state=open]:tw-zoom-in-95 data-[side=bottom]:tw-slide-in-from-top-2',
            'data-[side=left]:tw-slide-in-from-right-2 data-[side=right]:tw-slide-in-from-left-2',
            'data-[side=top]:tw-slide-in-from-bottom-2',
            className
        )}
        {...props}
    />
));
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;

export { HoverCard, HoverCardTrigger, HoverCardContent };
