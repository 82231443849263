import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { omit } from 'lodash';
import { Settings } from 'luxon';

import * as utils from '@/composables/utils';
import { getNavigatorLanguage } from '@/composables/utils';
import { type Language } from '@/composables/translation';
import { type Feature } from '@/composables/features';
import { type JwtPayload } from '@/types/api/token';
import { useTabStore } from '@/store/tabs';

export interface UserState {
    type: 'admin' | 'customer';
    logo?: string;
    user_id: string;
    org: string;
    org_id: string;
    role: string;
    email: string;
    fullname: string;
    features: Feature[];
    permissions: string;
}

interface State {
    lang: Language;
    user?: UserState;
    token?: string;
    tokenPayload?: JwtPayload;
}

interface Actions {
    setLang: (lang: Language) => void;
    setToken: (token: string, logo?: string) => void;
    logout: () => void;
}

export const useUserStore = create<State & Actions>()(
    devtools(
        persist(
            (set) => ({
                lang: getNavigatorLanguage(),
                setLang: (lang) => {
                    Settings.defaultLocale = lang;
                    set({ lang });
                },
                setToken: (token, logo) => {
                    const payload = utils.parseJWT(token);
                    // *TODO: Remove this in the future when JSONForm is fully ported
                    localStorage.setItem('token', token);
                    localStorage.setItem('user_id', payload.sub);
                    localStorage.setItem('org_id', payload.scope.org_id);
                    localStorage.setItem('org', payload.scope.org);
                    useTabStore.getState().reset();
                    set({
                        token,
                        tokenPayload: payload,
                        user: {
                            type: payload.scope.resource === 'customer' ? 'customer' : 'admin',
                            logo: logo ?? '',
                            user_id: payload.sub,
                            org_id: payload.scope.org_id,
                            org: payload.scope.org,
                            role: payload.scope.role,
                            email: payload.scope.login ?? payload.scope.client_email,
                            fullname: payload.scope.fullname ?? '',
                            features: payload.scope.features ?? [],
                            permissions: payload.scope.permissions ?? '0'
                        },
                        ...(payload?.scope?.lang && { lang: payload.scope.lang })
                    });
                },
                logout: () => {
                    // *TODO: Remove this in the future when JSONForm is fully ported
                    localStorage.removeItem('token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('org_id');
                    localStorage.removeItem('org');
                    set(state => omit(state, ['user', 'token']), true);
                }
            }),
            {
                name: 'user'
            }
        )
    )
);

Settings.defaultLocale = useUserStore.getState().lang;
