import React from 'react';
import { Linear } from '@/components/ui/linear';
// @ts-expect-error 7016
import { useAxiosLoader } from 'use-axios-loader';
import { instance } from '@/composables/api';

export function AxiosLoader() {
    const [loading] = useAxiosLoader(instance);
    if (!loading) return null;
    return (
        <Linear className="!tw-fixed tw-inset-x-0 tw-bottom-0" />
    );
}
