import React, { Component } from 'react';

import InputMask from 'react-input-mask';

import _ from 'lodash';

export class Postal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value || '',
            display: this.props.defaultDisplay === false ? false : true,
        };
    }

    componentDidUpdate(prevprops) {
        let obj = {},
            props = this.props;

        if (props.version !== prevprops.version) {
            obj['value'] = props.value || props.default;
            this.props.put({
                params: {
                    key: this.props.name,
                    value: obj['value'],
                    title: this.props.title,
                },
            });
        }

        if (prevprops.value !== this.props.value) {
            // this.forceUpdate();

            obj.value = this.props.value;
        }
        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (!_.isEmpty(obj)) this.setState(obj);
    }

    render() {
        if (this.state.display === false) return <></>;

        return (
            <InputMask
                name={this.props.name}
                // defaultValue={this.props.defaultValue}
                type="text"
                value={this.state.value || ''}
                onChange={(e) => {
                    if (e.target.value.match(/\S/)) {
                        this.setState({ value: e.target.value });
                    } else {
                        console.log(this.props.name, ' TRÈS VIDE');

                        this.setState({ value: '' });
                        this.props.put({
                            params: {
                                key: this.props.name,
                                value: '',
                                title: this.props.title,
                            },
                        });
                    }
                }}
                mask="*** ***"
                maskChar=" "
                className="form-control"
            />
        );
    }
}
