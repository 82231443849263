import { useNeoForm } from '@/composables/neoform';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import { NeoFormActionBar } from '@/components/neoform/NeoFormActionBar';
import { NeoFormSection } from '@/components/neoform/NeoFormSection';
import { combine, useTranslation } from '@/composables/translation';
import { InfoMessage } from '@/components/InfoMessage';
import { NeoForm, type NeoFormRenderProps } from '@/components/neoform/NeoForm';
import { type Context } from '@/components/neoform/context/NeoFormContext';
import { useUserStore } from '@/store/user';
import {
    getFormData, getTemplateSettings,
    postCompleteFormData
} from '@/composables/api';
import { delay, parseJWT } from '@/composables/utils';
import { NeoFormPublicAuth } from '@/components/neoform/NeoFormPublicAuth';
import { useError } from '@/composables/error';
import { cn } from '@/lib/utils';
import { useSettingsStore } from '@/store/settings';
import useHtml from '@/composables/html';
import { useBeforeUnload } from '@/composables/unload';

interface Props {
    forceTestForm?: string;
}

export function NeoFormPage(pageProps: Props) {
    const { t, to } = useTranslation('neoform');
    const { handleNetworkError } = useError();
    const navigate = useNavigate();
    const userStore = useUserStore();
    const ref = useRef<Context>();
    const settings = useSettingsStore();

    const {
        name,
        task_id,
        token
    } = useParams<{
        name: string;
        task_id: string;
        token: string;
    }>();

    const [message, setMessage] = useState('');
    const [messageOpen, setMessageOpen] = useState(false);

    useEffect(() => {
        if (token) {
            const tokenPayload = parseJWT(token ?? '');
            const lang = tokenPayload?.scope?.lang;
            if (lang && userStore.lang !== lang) {
                userStore.setLang(lang);
            }
        }
        getTemplateSettings(token)
            .then((res) => settings.setSettings({
                ...res.data,
                default: false
            }));
    }, []);

    const RenderNeoForm = useCallback(function(props: NeoFormRenderProps) {
        const { hookForm, form } = useNeoForm();
        const { lang, setLang } = useUserStore(state => ({
            lang: state.lang,
            setLang: state.setLang
        }));

        useBeforeUnload(() => hookForm.formState.isDirty);

        useEffect(() => {
            if (!pageProps.forceTestForm) {
                getFormData(name, task_id, token)
                    .then(res => {
                        const result = token ? res.data.data : res.data.result;
                        const permissions = res.data.form_permissions;
                        if (Array.isArray(permissions) && permissions.length === 0) {
                            throw new Error();
                        }

                        form?.setLayout(result.json);
                        form?.setInfo({
                            is_public: !!token,
                            ticket_id: result.ticket_id,
                            task_id: result.task_id,
                            form_id: result.form_id,
                            case_id: result.case_id,
                            target:
                                (result.json?.header?.identity as string ?? '') + '_' +
                                 (result.json?.header?.formName as string ?? ''),
                            name: result.json?.header?.formName ?? '',
                            title: result.json?.header?.formTitle,
                            description: result.json?.header?.formDescription,
                            actions: result.json?.header?.pdfOptions ?? [],
                            permissions: token ? permissions : undefined,
                            status: res.data.status
                        });

                        if (!result.data) {
                            delay(1000)
                                .then(() => postCompleteFormData(
                                    result.task_id ?? '',
                                    hookForm.getValues(),
                                    !!token
                                ))
                                .then((res: any) => hookForm.reset(
                                    res.data.post,
                                    { keepDefaultValues: true }
                                ))
                                .finally(() => form?.setLoading(false));
                        } else {
                            hookForm.reset(result.data, { keepDefaultValues: true });
                            form?.setLoading(false);
                        }
                    })
                    .catch(() => {
                        userStore.logout();
                        navigate('/not-found');
                    });
            } else {
                import(`../../assets/forms/${pageProps.forceTestForm}.json`)
                    .then(res => {
                        form?.setLoading(false);
                        form?.setLayout(res.default);
                        form?.setInfo({
                            is_public: false,
                            target: (res.default?.header?.identity as string ?? '') + '_' +
                                (res.default?.header?.formName as string ?? ''),
                            name: res.default?.header?.formName,
                            title: res.default?.header?.formTitle,
                            description: res.default?.header?.formDescription,
                            actions: res.default?.header?.pdfOptions ?? []
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        navigate('/not-found');
                    });
            }
        }, []);

        if (
            form?.layout?.header?.formLanguage &&
            form.layout.header.formLanguage.length > 0 &&
            !form.layout.header.formLanguage.includes(lang)
        ) {
            setLang(form.layout.header.formLanguage[0]);
        }

        const imagePath = form?.layout?.header?.imagePath;
        const description = to(form?.info?.description);
        const showPreview = form?.info?.preview_open ?? true;
        const isFormLoading = !!form?.loading;
        const isFormPublic = !!form?.info?.is_public;
        const showSecondaryActionBar = !isFormLoading && (
            (isFormPublic && !!form?.info?.preview_url) ||
            !isFormPublic
        );
        const preview_url = showPreview && form?.info?.preview_url
            ? axios.getUri({
                baseURL: form?.info?.preview_url,
                params: {
                    c: form?.info?.preview_count ?? 0
                }
            })
            : undefined;

        const sanitizedDescription = useHtml(description);

        return (
            <>
                <NeoFormActionBar className="tw-z-20" />
                <div className="tw-relative tw-flex-1 tw-w-full tw-flex tw-flex-wrap tw-p-3 md:tw-p-6">
                    <div
                        className={cn('tw-w-full', preview_url && 'md:tw-w-7/12')}
                    >
                        <div className={cn(
                            'tw-container !tw-px-0',
                            'tw-flex tw-flex-col tw-gap-2 md:tw-gap-6'
                        )}>
                            {imagePath &&
                                <div className="tw-flex tw-justify-center">
                                    <img
                                        className="tw-my-4 tw-mx-2"
                                        src={imagePath}
                                        alt="logo"
                                        width="300"
                                    />
                                </div>
                            }
                            {description &&
                                <div
                                    className="tw-pb-2 tw-pr-2 md:tw-pr-5"
                                >
                                    <div
                                        className="tw-contents tw-prose tw-container"
                                        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                                    />
                                </div>
                            }
                            {props.sections.map((s, i) =>
                                <NeoFormSection
                                    key={i}
                                    prefix={s.header.sectionPrefix ?? ''}
                                    name={s.header.legend}
                                    info={s.header.sectionInfo}
                                    last={i === props.sections.length - 1}
                                    private={s.header.private}
                                    rows={s.rows}
                                />
                            )}
                            {!form?.loading &&
                                !form?.layout?.header?.config?.hide_document_options &&
                                !form?.info?.is_public &&
                                <NeoFormSection
                                    prefix="document"
                                    name={combine('neoform.document-options')}
                                    rows={[[{
                                        col: 12,
                                        name: 'lawyer_signature',
                                        componentName: 'InputCheckbox',
                                        title: {
                                            en: 'Add lawyer signature to document',
                                            fr: 'Ajouter la signature de l\'avocat au document'
                                        }
                                    }]]}
                                />
                            }
                        </div>
                    </div>
                    {preview_url &&
                     <div
                         className="tw-mt-4 tw-w-full md:tw-w-5/12 md:tw-mt-0 tw-min-h-[85vh]"
                     >
                         <iframe
                             src={
                                 `${preview_url}${
                                     form?.info?.is_public
                                         ? '#toolbar=0#navpanes=0'
                                         : '#navpanes=0'}`
                             }
                             className={cn(
                                 'md:tw-fixed md:tw-right-[10px]',
                                 'md:tw-w-[40vw]',
                                 'md:tw-bottom-[10px]',
                                 {
                                     'md:tw-h-[calc(100vh_-_132px)]': !showSecondaryActionBar,
                                     'md:tw-h-[calc(100vh_-_192px)]': showSecondaryActionBar
                                 }
                             )}
                             height="100%"
                             width="100%"
                         />
                     </div>
                    }
                </div>
                <InfoMessage
                    open={messageOpen}
                    onOpenChange={setMessageOpen}
                    message={message}
                />
            </>
        );
    }, [token, task_id]);

    if (token) {
        const tokenPayload = parseJWT(token ?? '');
        const showAuth = tokenPayload?.scope?.resource !== 'public';
        if (showAuth) {
            return (<NeoFormPublicAuth />);
        }
        if (userStore.token !== token) {
            userStore.setToken(token);
        }
    }

    if (token && userStore.token !== token) {
        userStore.setToken(token);
    }

    function handleSubmit() {
        return ref.current?.saveForm()
            .then((res) => {
                if (!res.data.updated) {
                    toast(t('messages.no-changes'), { type: 'warning' });
                    return;
                }
                if (ref.current?.info?.is_public) {
                    if (res.data.description) {
                        localStorage.setItem('message', res.data.description.content);
                        navigate('/message');
                    } else {
                        navigate(`/thanks/${userStore.lang}`);
                    }
                } else {
                    if (res.data.description) {
                        setMessage(res.data.description.content);
                        setMessageOpen(true);
                    } else {
                        toast(t('messages.saved'), { type: 'success' });
                    }
                }
            })
            .catch(handleNetworkError);
    }

    return (
        <NeoForm
            formRef={ref}
            render={RenderNeoForm}
            isPublicDefault={!!token}
            onSubmit={handleSubmit}
        />
    );
}
