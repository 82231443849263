import { useEffect, useRef } from 'react';
import { clone } from '@/composables/utils';

export interface PageMeta {
    title: string;
    description: string;
}

let PAGE_META: PageMeta = {
    title: 'Neodoc',
    description: ''
};

function updatePageMeta() {
    document.title = PAGE_META.title;
    if (PAGE_META.description) {
        document.title += ` — ${PAGE_META.description}`;
    }
}

export function usePageMeta(meta: Partial<PageMeta>) {
    const originalMeta = useRef(clone(PAGE_META));
    useEffect(() => {
        Object.assign(PAGE_META, meta);
        updatePageMeta();
        return () => {
            PAGE_META = originalMeta.current;
            updatePageMeta();
        };
    }, []);
}
