import React from 'react';
import 'react-quill/dist/quill.snow.css';

import { Editor } from '@tinymce/tinymce-react';

import { FormGroup } from 'reactstrap';

import _ from 'lodash';
import { NotesInput } from './NotesInput';

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const DEFAULT_STYLE = {};

export class ContentEditableTiny extends React.Component {
    constructor(props) {
        super(props);
        let fd = this.props.formData;

        this.state = {
            value: fd && fd[this.props.name] ? fd[this.props.name] : this.genLang(props.formData[props.name]) || this.genLang(props.default, this.props.lang) || '<p></p>',
            label: this.props.label || '',
            display: this.props.defaultDisplay === false ? false : true,
            update: false
        };
    }

    stripHtml(html) {
        let tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    }

    componentDidUpdate(prevprops) {
        let props = this.props, state = this.state;
        let obj = {};

        if (props.label !== prevprops.label) {
            obj.label = this.props.label;
        }

        if (props.version !== prevprops.version) {
            obj['value'] = this.genLang(props.formData[props.name] || props.default, this.state.lang);

            this.props.put({
                params: {
                    key: this.props.name,
                    value: props.formData[props.name] || this.genLang(props.default, obj.lang)
                }
            });
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            obj['value'] = this.genLang(props.formData[props.name], this.state.lang);

            this.props.put({
                params: {
                    key: this.props.name,
                    value: props.formData[props.name] || this.genLang(props.default, obj.lang)
                }
            });
        }

        if (state.value !== props.formData[props.name]) {
            if (state.update === false) {
                obj['value'] = props.formData[props.name];
            }
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
            // debugger;
            if (this.stripHtml(state.value) === this.genLang(props.default, props.formData['pa_lang']).replace(/\n+/g, ' ')) {
                obj.value = this.genLang(props.default, obj.lang);

                this.props.put({
                    params: {
                        key: this.props.name,
                        value: props.formData[props.name] || this.genLang(props.default, obj.lang)
                    }
                });
            }
        }
        // console.log(this.props.formData)

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING || text === '' || text == null) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {

        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            // return(<></>)
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;
        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        return (
            <>
                <FormGroup>
                    <NotesInput  {...props} onChange={props.onChange} formData={props.data} />
                    <Editor
                        tinymceScriptSrc="/tinymce/tinymce.min.js"
                        readOnly={this.props.disabled}
                        name={props.name}
                        //   initialValue={this.state.value}
                        value={this.state.value || ''}
                        init={{
                            content_css: this.props.customCss || undefined,
                            plugins: 'link image code table fullscreen preview lists', // toolbar: 'insertfile a11ycheck undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image tinydrive | customInsertButton | customInsertButton1 | table | fullscreen | preview',
                            toolbar: 'insertfile a11ycheck undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image tinydrive | table | fullscreen | preview',
                            setup: (editor) => {
                                editor.ui.registry.addButton('customInsertButton', {
                                    icon: 'new-document',
                                    tooltip: 'New Page Portrait',
                                    onAction: function (_) {
                                        editor.insertContent('[*portrait*]');
                                    }
                                });

                                editor.ui.registry.addButton('customInsertButton1', {
                                    icon: 'orientation',
                                    tooltip: 'New Page Paysage',
                                    onAction: function (_) {
                                        editor.insertContent('[*paysage*]');
                                    }

                                });
                            }

                        }}

                        onClick={(e) => {
                            this.setState({ update: true });
                        }}

                        onEditorChange={(e) => {
                            this.props.put({
                                params: {
                                    key: this.props.name,
                                    value: e
                                }
                            });
                            this.setState({ value: e });
                        }}
                    />

                </FormGroup>
            </>
        );

    }
}
