import * as React from 'react';
import { CheckIcon } from '@radix-ui/react-icons';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from '@/lib/utils';

const RadioGroup = React.forwardRef<
    React.ElementRef<typeof RadioGroupPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({
    className,
    ...props
}, ref) => {
    return (
        <RadioGroupPrimitive.Root
            className={cn('tw-grid tw-gap-2', className)}
            {...props}
            ref={ref}
        />
    );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
    React.ElementRef<typeof RadioGroupPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({
    className,
    ...props
}, ref) => {
    return (
        <RadioGroupPrimitive.Item
            ref={ref}
            className={cn(
                'tw-aspect-square tw-h-4 tw-w-4 tw-rounded-full tw-border tw-border-primary tw-text-primary',
                'tw-shadow focus:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring',
                'disabled:tw-cursor-not-allowed disabled:tw-opacity-50 [&[data-state=checked]]:tw-bg-primary',
                className
            )}
            {...props}
        >
            <RadioGroupPrimitive.Indicator className="tw-flex tw-items-center tw-justify-center">
                <CheckIcon className="tw-h-3.5 tw-w-3.5 tw-text-primary-foreground"/>
            </RadioGroupPrimitive.Indicator>
        </RadioGroupPrimitive.Item>
    );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
