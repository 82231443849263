import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@/components/ui/button';

import { getInvoiceInfo } from '@/composables/api';
import { useTranslation } from '@/composables/translation';
import { formatCurrency, formatInteger, formatPercentage } from '@/composables/format';
import { useError } from '@/composables/error';

interface Stat {
    value: number;
    label: string;
    formatter?: (value: number) => string;
    color?: string;
    percentage?: number;
}

interface Section {
    label: string;
    stats: Stat[];
}

export function Billing() {
    const { t } = useTranslation('settings.billing');
    const { handleNetworkError } = useError();
    const currentDate = DateTime.now().startOf('month');

    const [loading, setLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState<any>({});
    const [monthIndex, setMonthIndex] = useState(0);

    useEffect(() => {
        setLoading(true);
        const start = currentDate.plus({ months: monthIndex });
        const end = currentDate.plus({ months: monthIndex + 1 });
        getInvoiceInfo(start, end)
            .then(res => {
                setInvoiceData(res.data);
                setLoading(false);
            })
            .catch(handleNetworkError);
    }, [monthIndex]);

    const generateSections = () => [
        {
            label: t('forms'),
            stats: [
                {
                    value: invoiceData.sended,
                    label: t('forms-sent'),
                    formatter: formatInteger
                },
                {
                    value: invoiceData.completed,
                    label: t('forms-completed'),
                    color: 'green',
                    percentage: invoiceData.completed / invoiceData.sended,
                    formatter: formatInteger
                },
                {
                    value: invoiceData.not_completed,
                    label: t('forms-incomplete'),
                    color: 'red',
                    percentage: invoiceData.not_completed / invoiceData.sended,
                    formatter: formatInteger
                }
            ]
        },
        {
            label: t('pricing'),
            stats: [
                {
                    value: invoiceData.unit_price,
                    label: t('unit-price'),
                    formatter: formatCurrency('CAD')
                },
                {
                    value: invoiceData.unit_price * invoiceData.sended,
                    label: t('total-price'),
                    formatter: formatCurrency('CAD')
                }
            ]
        }
    ] satisfies Section[];

    const renderStat = (stat: Stat) => {
        return (
            <div className="tw-flex-1 tw-flex tw-flex-col tw-items-center" key={stat.label}>
                <h1 className="tw-font-bold tw-mb-0">
                    {stat.formatter?.(stat.value) ?? stat.value}
                    {
                        typeof stat.percentage === 'number' &&
                        <span className="tw-ml-1 tw-font-normal tw-text-[0.4em]"
                            style={{ color: stat.color ?? 'inherit' }}>
                            ({formatPercentage(stat.percentage)})
                        </span>
                    }
                </h1>
                <div className="tw-uppercase tw-opacity-[0.6]">
                    {stat.label}
                </div>
            </div>
        );
    };

    const renderStatSection = (section: Section) => {
        return (
            <section key={section.label}>
                <h6 className="tw-uppercase tw-font-bold tw-opacity-95">{section.label}</h6>
                <hr className="tw-mt-0 !tw-border-t !tw-border-black/15 tw-opacity-100" />
                <div className="tw-flex tw-gap-2">
                    {section.stats.map(s => renderStat(s))}
                </div>
            </section>
        );
    };

    return (
        <>
            <div className="tw-flex tw-flex-wrap tw-items-center tw-mb-6">
                <h5 className="tw-text-primary tw-mr-auto">{t('title')}</h5>
                <div className="tw-flex tw-items-center tw-w-fit">
                    <Button
                        variant="ghost" size="icon"
                        className="!tw-rounded-full tw-text-primary"
                        onClick={() => setMonthIndex(monthIndex - 1)}
                    >
                        <FontAwesomeIcon width="1em" icon={faAngleLeft} />
                    </Button>
                    <h5 className="tw-mx-3 tw-my-0">
                        {currentDate.plus({ months: monthIndex }).toFormat('MMMM kkkk')}
                    </h5>
                    <Button
                        variant="ghost" size="icon"
                        className="!tw-rounded-full tw-text-primary"
                        onClick={() => setMonthIndex(monthIndex + 1)}
                    >
                        <FontAwesomeIcon width="1em" icon={faAngleRight} />
                    </Button>
                </div>
            </div>

            {!loading
                ? (
                    <div className="tw-flex tw-flex-col tw-gap-[3rem]">
                        {generateSections().map(s => renderStatSection(s))}
                    </div>
                )
                : (
                    <div className="tw-flex tw-justify-center tw-items-center tw-p-20">
                        <div className="spinner-border" />
                    </div>
                )}
        </>
    );
}
