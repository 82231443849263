import { type TypeID } from '@/components/WeekSelection/WeekSelection';
import { type TranslationObject } from '@/composables/translation';
import { type ObjectId } from '@/types/api/objectId';
import { type DateTime } from 'luxon';

export interface Duration {
    id: ObjectId;
    value: number;
    label: string;
}

export interface Category {
    id: number;
    name: TranslationObject;
    color?: string;
}

export interface Domain {
    _id: string;
    title: TranslationObject | string;
    groups: string[];
    types: Type[];
}

export interface Type {
    _id: string;
    title: TranslationObject | string;
    type?: CalendarAvailabilityType;
    category?: string | number;
    duration?: string | number;
    max_book?: never[];
}

export interface CalendarConfig {
    _id: string;
    org_id: string;
    durations: Duration[];
    domains: Domain[];
    categories: Category[];
    global_types?: Type[];
    org_settings: {
        external_folder_url: string;
    };
}

export interface CalendarUserDispo {
    _id: ObjectId;
    org_id: ObjectId;
    user: ObjectId;
    type: TypeID;
    start: string;
    end: string;
    duration: number;
    hidden: boolean;
    metadata: any;
}

export interface CalendarEntry {
    user: TypeID;
    start: string;
    end: string;
    type: TypeID;
    hidden: boolean;
    metadata: any;
}

export interface CalendarUpdateData {
    start: string;
    end: string;
    permanent?: number;
    users: TypeID[];
    calendar: CalendarEntry[];
}

export enum CalendarAvailabilityType {
    AVAILABLE = 0,
    UNAVAILABLE = 1,
    ALL = 2
}

export const CALENDAR_AVAILABILITY_VALUES =
    Object.values(CalendarAvailabilityType)
        .filter((v) => typeof v === 'number')
        .map<CalendarAvailabilityType>((v) => v as CalendarAvailabilityType);

export const CALENDAR_AVAILABILITY_LABELS: Record<CalendarAvailabilityType, TranslationObject> = {
    [CalendarAvailabilityType.AVAILABLE]: {
        fr: 'Dispo',
        en: 'Available'
    },
    [CalendarAvailabilityType.UNAVAILABLE]: {
        fr: 'Hors-dispo',
        en: 'Unavailable'
    },
    [CalendarAvailabilityType.ALL]: {
        fr: 'Toutes les dispos',
        en: 'All availabilities'
    }
};

export interface CalendarAvailability {
    type: CalendarAvailabilityType;
    user: string;
    busy: number;
    busyservice: Record<string, number>;
    start: string;
    end: string;
    duration: number;
    label?: string;
    danger?: boolean;
    multi?: boolean;
}

export interface CalendarAvailabilityData {
    availabilities: CalendarAvailability[];
}

export interface BookAppointmentClient {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
}

export interface BookAppointmentTaken {
    timezone: string;
    title: string;
    description?: string;
    where: string;
    users: BookAppointmentOrganizer[];
    metadata: BookAppointmentMetadata;
    domain_id?: string;
    service_id?: string;
    type?: CalendarAvailabilityType;
    category?: number;
    service?: string;
    start: string;
    end: string;
    duration: number;
    ics_url?: string;
    ticket_id?: string;
    app_description?: string;
    clients?: BookAppointmentClient[];
    multi?: boolean;
    holiday?: boolean;
    color?: string;
}

export interface BookAppointmentMetadata {
    client: string;
}

export interface BookAppointmentOrganizer {
    id: string;
    external_id?: string;
    role?: string;
    email?: string;
    name?: string;
}

export interface ScheduleTaken extends BookAppointmentTaken {
    _id: ObjectId;
    users: Array<BookAppointmentOrganizer & { id: ObjectId }>;
}

export interface AppointmentScheduleOptions {
    start_date?: DateTime;
    end_date?: DateTime;
    users?: string[];
    ticket_id?: string;
}

export interface AppointmentScheduleData {
    takens: ScheduleTaken[];
}
