import React from 'react';
import { useTranslation } from '@/composables/translation';
import { CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import useHtml from '@/composables/html';

export function Message() {
    const { t } = useTranslation('thanks');
    const message = localStorage.getItem('message') ?? t('description');
    const sanitizedMessage = useHtml(message);
    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CardDescription dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
            </CardContent>
        </>
    );
}
