import React from 'react';
import { useTranslation } from '@/composables/translation';
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

export function ActionRequired() {
    const { t, ct } = useTranslation('action-required');
    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent className="tw-flex tw-flex-col tw-gap-3">
                <CardDescription>{t('description')}</CardDescription>
                <CardDescription>{ct('contact-admin')}</CardDescription>
            </CardContent>
            <CardFooter>
                <Button className="!tw-p-0 !tw-h-4" variant="link" asChild>
                    <Link to="/login">{ct('return-to-login')}</Link>
                </Button>
            </CardFooter>
        </>
    );
}
