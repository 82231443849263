import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { type Language, useTranslation } from '@/composables/translation';
import { useSettingsStore } from '@/store/settings';
import { Button } from '@/components/ui/button';
import { CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import useHtml from '@/composables/html';

export function ThankYou() {
    const { lang } = useParams<{ lang: Language }>();
    const { t } = useTranslation('thanks', lang);
    const { logo, templates } = useSettingsStore(state => ({
        logo: state.logo,
        templates: state.templates
    }));
    const buttonText = templates.thks?.btnRedirect?.text;
    const buttonUrl = templates.thks?.btnRedirect?.url;
    const sanitizedHtml = useHtml(templates.thks?.html ?? t('description'));
    return (
        <>
            {logo && typeof logo === 'string' && (
                <div className="tw-flex tw-justify-center tw-mt-8">
                    <img className="tw-h-[85px]" src={logo} alt="logo" />
                </div>
            )}
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <div
                    className="tw-prose tw-text-foreground-muted"
                    dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                >
                </div>
            </CardContent>
            {buttonUrl &&
                <CardFooter className="tw-flex tw-justify-end">
                    <Button asChild>
                        <Link to={buttonUrl}>
                            {buttonText}
                        </Link>
                    </Button>
                </CardFooter>
            }
        </>
    );
}
