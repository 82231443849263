import React from 'react';
import {
    NeoFormComponent,
    type NeoFormComponentProps
} from '@/components/neoform/NeoFormComponent';
import { useNeoForm } from '@/composables/neoform';
import { combine } from '@/composables/translation';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { Row } from '@/components/ui/row';
import { cn } from '@/lib/utils';

interface Props {
    sumTargets?: {
        total: string;
        voting: string;
    };
}

export function CategoryActions(props: NeoFormComponentProps & Props) {
    const { form, getChildFieldName } = useNeoForm();
    const value = form?.watch<string[]>(getChildFieldName('categories')) ?? [];

    const renderCategory = (name: string, index: number) => {
        return (
            <Row className={cn({ 'tw-mb-2': index !== value.length - 1 })} key={name}>
                <NeoFormComponent
                    name={`${name}_voting`}
                    componentName="InputSelect"
                    default="no"
                    col={3} last="md"
                    titleType={NeoFormTitleType.LABEL}
                    title={{
                        en: `Class ${name.toUpperCase()}`,
                        fr: `Catégorie ${name.toUpperCase()}`
                    }}
                    options={[
                        { value: 'yes', label: combine('neoform.shares.voting') },
                        { value: 'no', label: combine('neoform.shares.non-voting') }
                    ]}
                />
                <NeoFormComponent
                    name={`${name}_numShares`}
                    componentName="InputNumber"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.shares.number')}
                    col={3}
                    last
                />
            </Row>
        );
    };

    if (props.sumTargets) {
        const currentTotal = form?.get<number>(props.sumTargets.total) ?? 0;
        const currentVoting = form?.get<number>(props.sumTargets.voting) ?? 0;
        const computedTotal = value
            ?.reduce(
                // eslint-disable-next-line
                (sum, cat) => sum + (form?.watch<number>(getChildFieldName(`${cat}_numShares`)) || 0),
                0
            ) || 0;
        const computedVoting = value
            ?.filter(c => form?.watch(getChildFieldName(`${c}_voting`)) === 'yes')
            .reduce(
                // eslint-disable-next-line
                (sum, cat) => sum + (form?.watch<number>(getChildFieldName(`${cat}_numShares`)) || 0),
                0
            ) || 0;
        setTimeout(() => {
            if (props.sumTargets && currentTotal !== computedTotal) {
                form?.set(props.sumTargets.total, computedTotal);
            }
            if (props.sumTargets && currentVoting !== computedVoting) {
                form?.set(props.sumTargets.voting, computedVoting);
            }
        }, 0);
    }

    return (
        <div className="tw-flex tw-flex-col tw-gap-1">
            <Row>
                <NeoFormComponent
                    name="categories"
                    componentName="InputSelect"
                    multiple sorted
                    options={[
                        { value: 'a', label: { en: 'A', fr: 'A' } },
                        { value: 'b', label: { en: 'B', fr: 'B' } },
                        { value: 'c', label: { en: 'C', fr: 'C' } },
                        { value: 'd', label: { en: 'D', fr: 'D' } },
                        { value: 'e', label: { en: 'E', fr: 'E' } }
                    ]}
                    showInOutline={false}
                    last={value?.length <= 0}
                />
            </Row>
            {value?.map(renderCategory)}
        </div>
    );
}
