import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Loading } from '@/components/old/Loading';
import { AxiosLoader } from '@/components/AxiosLoader';
import { usePageMeta } from '@/composables/meta';
import { useTranslation } from '@/composables/translation';
import { useUserStore } from '@/store/user';
import { DocumentStatusPolling } from '@/components/DocumentStatusPolling';
import { cn } from '@/lib/utils';

export function RootLayout() {
    const { t } = useTranslation();
    usePageMeta({
        description: t('description')
    });
    const lang = useUserStore(state => state.lang);
    return (
        <div className={cn('tw-contents', `lang-${lang}`)}>
            <Outlet />
            <ToastContainer position="bottom-left" theme="colored" />
            <Loading />
            <DocumentStatusPolling />
            <AxiosLoader />
        </div>
    );
}
