import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { CalendarIcon, Link2Icon, PersonIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { downloadArrayBuffer } from '@/composables/utils';
import { Spinner } from './spinner';
import MsgReader from '@kenjiuno/msgreader';

interface Props {
    url: string;
}

export function Msg(props: Props) {
    const [reader, setReader] = useState<MsgReader>();
    const data = useMemo(() => reader?.getFileData(), [reader]);

    useEffect(() => {
        axios.get(props.url, { responseType: 'arraybuffer' })
            .then((res) => {
                const reader = new MsgReader(res.data);
                setReader(reader);
            });
    }, [props.url]);

    if (!data) {
        return (<Spinner className="tw-text-primary" />);
    }

    const bodyClassName = cn(
        'tw-p-2 tw-border tw-border-input tw-prose [&>p]:tw-my-0',
        'tw-max-w-none tw-text-wrap tw-text-sm tw-rounded-sm'
    );

    return (
        <div className="tw-w-full tw-flex tw-flex-col tw-gap-2">
            <div className="tw-font-semibold">{data.subject}</div>
            <div className="tw-flex tw-gap-6">
                <div className="tw-flex tw-items-center tw-text-primary">
                    <PersonIcon className="tw-size-4 tw-mr-2"/>
                    {data.senderName} [{data.creatorSMTPAddress}]
                </div>
                {data.messageDeliveryTime &&
                    <div className="tw-flex tw-items-center">
                        <CalendarIcon className="tw-size-4 tw-mr-2"/>
                        {data.messageDeliveryTime}
                    </div>
                }
            </div>
            <div className="tw-overflow-auto">
                {data.bodyHtml
                    ? <div className={bodyClassName}>
                        {data.bodyHtml}
                    </div>
                    : <pre className={bodyClassName}>
                        {data.body}
                    </pre>}
            </div>
            {data.attachments?.map((a) => (
                !a.attachmentHidden && <Button
                    key={a.name} variant="link"
                    className={cn(
                        '!tw-w-fit !tw-h-fit tw-flex tw-items-center tw-gap-2',
                        '!tw-px-0 !tw-py-1'
                    )}
                    onClick={() => {
                        const attachment = reader?.getAttachment(a);
                        if (attachment) {
                            downloadArrayBuffer(attachment.content.buffer, attachment.fileName);
                        }
                    }}
                >
                    <Link2Icon className="tw-mt-0.5" />
                    {a.name}
                </Button>
            ))}
        </div>
    );
}
