export const SET_MENU = 'menu:set';

export default function customersReducer(state = {}, {
    type,
    params
}) {
    if (type === SET_MENU) {
        return { ...params };
    }

    return state;
}
