export function writeClipboardText(text: string) {
    return navigator.clipboard.writeText(text);
}

export function readClipboardText() {
    return navigator.clipboard.readText();
}

export function writeClipboardJSON(data?: any) {
    const content = typeof data === 'string' ? data : JSON.stringify(data) || '';
    return writeClipboardText(content);
}

export function readClipboardJSON() {
    return new Promise<any>((resolve, reject) => {
        readClipboardText()
            .then((text) => resolve(JSON.parse(text)))
            .catch(() => reject());
    });
}
