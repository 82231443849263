export const PUT_DOCUMENT = 'PUT_DOCUMENT';
export const PUT_FIELD = 'PUT_FIELD';
export const SET_LIST_ATTACH = 'SET_LIST_ATTACH';
export const INIT = 'INIT';

export function init() {
    return {
        type: INIT
    };
}

export function putDocument({ params }) {
    return {
        type: PUT_DOCUMENT,
        params
    };
}

export function putField({ params }) {
    return {
        type: PUT_FIELD,
        params
    };
}

export function setListAttach({ params }) {
    return {
        type: SET_LIST_ATTACH,
        params
    };
}
