import React, { useState } from 'react';
import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';

type CardProps = Parameters<typeof Card>[0];

export interface Props {
    title?: string;
    innerTitle?: boolean;
    cardProps?: CardProps;
    children?: React.ReactNode;
    collapsed?: boolean;
    titleSlot?: React.ReactNode;
}

export function CardCollapse(props: Props) {
    const [open, setOpen] = useState(!props.collapsed);

    if (props.innerTitle) {
        return (
            <Card {...props.cardProps}>
                <Collapsible open={open} onOpenChange={setOpen}>
                    <CardHeader className="!tw-flex-row !tw-space-y-0 !tw-p-3">
                        <CollapsibleTrigger asChild>
                            <CardTitle className="tw-flex tw-items-center tw-cursor-pointer tw-h-[36px]">
                                {open && <ChevronDownIcon className="tw-mr-2" width="22" height="22" />}
                                {!open && <ChevronRightIcon className="tw-mr-2" width="22" height="22" />}
                                {props.title}
                            </CardTitle>
                        </CollapsibleTrigger>
                        {props.titleSlot}
                    </CardHeader>
                    <CollapsibleContent>
                        {props.children}
                    </CollapsibleContent>
                </Collapsible>
            </Card>
        );
    }

    return (
        <Collapsible open={open} onOpenChange={setOpen}>
            <CollapsibleTrigger asChild>
                <div className="tw-mb-1 md:tw-mb-2 tw-flex tw-items-center">
                    <h3
                        className={cn(
                            'tw-mb-0 tw-cursor-pointer tw-select-none',
                            'tw-font-semibold tw-text-primary',
                            'tw-inline-flex tw-items-center tw-text-lg'
                        )}
                        onClick={() => setOpen(!open)}
                    >
                        {open && <ChevronDownIcon className="tw-mr-2" width="22" height="22" />}
                        {!open && <ChevronRightIcon className="tw-mr-2" width="22" height="22" />}
                        {props.title}
                    </h3>
                    {props.titleSlot}
                </div>
            </CollapsibleTrigger>
            <CollapsibleContent>
                <Card {...props.cardProps}>
                    {props.children}
                </Card>
            </CollapsibleContent>
        </Collapsible>
    );
}
