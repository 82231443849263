import React, { Component } from 'react';

import _ from 'lodash';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';

import { DropZoneGcloud } from './DropZoneGcloud';

import { RequestThatField } from './RequestThatField';
import { v4 as uuid } from 'uuid';

import { SortableContainer } from './SortableContainerTest';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const UPLOAD_DOCUMENT = '_uploadDocument';

const ORIGINAL_NAME = '_originalName';
const BOOLEAN = '_boolean';
const DEFAULT_STYLE = { textAlign: 'left' };
const REAL_NAME = '_realName';
const BUCKET = '_bucket';

const TIMEOUT_DURATION = 50;
const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const UNDERSCORE_REPLACEMENT = '*$?$*';
const UNDERSCORE = /_/g;

const SPACE_REPLACEMENT = '!(#)!';
const SPACE = /\s/g;

const INDEX = '_index';

export class DropContainer extends Component {

    constructor(props) {
        super(props);

        let fd = this.props.formData;
        let match = 0;
        let name = this.props.name;
        let value = [];
        for (let i in props.formData) {
            if (i.match(name) && i.match(BOOLEAN)) {
                if (props.formData[i]) {

                    let decoded = i.split(name + '_')[1];

                    decoded = decodeURIComponent(decoded);
                    let temp = i.replace(BOOLEAN, INDEX);

                    value.push({
                        realName: this.props.formData[i.replace(BOOLEAN, ORIGINAL_NAME)],
                        bucket: this.props.formData[i.replace(BOOLEAN, BUCKET)],
                        id: props.formData[temp] || (match).toString(),
                        key: i,
                        checked: props.formData[i] === 'true',
                        value: props.formData[i + UPLOAD_DOCUMENT],
                        name: decodeURI(i.split(name + '_')[1]).replaceAll(/\*\$\?\$\*/g, '_').replaceAll(SPACE_REPLACEMENT, ' ').replace(BOOLEAN, '')
                    });

                    this.props.put({
                        params: {
                            key: i.replace(BOOLEAN, INDEX),
                            value: (props.formData[i + INDEX] || (match).toString()).toString()
                        }
                    });
                    match++;
                }
            } else if (i.match(REAL_NAME)) {
                value[i] = props.formData[i];
            }

        }
        value = _.sortBy(value, [(e) => {
            return parseInt(e.id);
        }]);
        this.state = {
            lang: props.lang || FR,
            value: value,
            ticket: this.props.ticket || '',
            // display: this.props.defaultDisplay !== false
        };

        if (!props.name) {
            console.error('!!! name is missing !!!');
        }

        if (!props.title) {
            console.error('!!! title is missing !!!');
        }

        this.fileCounter = 0;
        this.fileArray = [];

        this.setFile = this.setFile.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.setJointFilesList = this.setJointFilesList.bind(this);
    }

    setJointFilesList(innerValue) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            let str = '';

            let value;

            if (innerValue) {
                value = innerValue;
                this.savedValue = value;
            } else if (this.savedValue) {
                this.value = this.savedValue;
                delete this.savedValue;
            } else {
                value = this.state.value;
            }

            let data = this.state;
            const PREFIX = data[this.props.name + '_party'];

            for (let v in value) {

                let convertedName = value[v].key.replace(BOOLEAN, REAL_NAME);
                if (value[v].bundle) {
                    convertedName = value[v].bundleId;
                }
                let convertedReal;
                if (value[v].bundle) {
                    convertedReal = (convertedName + REAL_NAME).replace(/[*+?^${}()|[\]\\]/g, '\\$&');
                } else {
                    convertedReal = convertedName.replace(/[*+?^${}()|[\]\\]/g, '\\$&');
                }

                for (let dk in value[v]) {
                    if (dk.match(convertedReal)) {

                        str += PREFIX + '-' + (parseInt(v) + 1) + ' ';
                        str += value[v][dk];

                        if (value[v].ammend === 'true') {

                            str += ' a_';
                        }

                        str += '\n';

                        break;
                    }
                }
            }
            // debugger;
            if (innerValue) {

                this.setState({
                    // pjList:str,
                    value: innerValue
                });
            } else {
                // debugger;
                let temp;
                if (this.savedValue) {
                    this.setState({
                        // pjList:str,
                        value: this.savedValue
                    });
                    this.savedValue = null;
                } else {
                    this.setState({
                        // pjList:str,
                    });
                }
            }
        }, TIMEOUT_DURATION);
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};
        let name = props.name;
        let value = [];

        if (prevprops.ticket !== this.props.ticket) {
            obj['ticket'] = this.props.ticket;
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (JSON.stringify(props.formData) !== JSON.stringify(prevprops.formData) && props.version !== prevprops.version) {
            let match = 0;
            for (let i in props.formData) {
                if (i.match(name) && i.match(BOOLEAN)) {
                    if (props.formData[i]) {
                        let decoded = i.split(name + '_')[1];

                        decoded = decodeURIComponent(decoded);
                        // console.log('abcde',props.formData[i+INDEX],i,props.formData);
                        let temp = i.replace(BOOLEAN, INDEX);
                        const obj = {
                            realName: this.props.formData[i.replace(BOOLEAN, ORIGINAL_NAME)],
                            id: props.formData[temp] || (match).toString(),
                            key: i,
                            checked: props.formData[i] === 'true',
                            value: props.formData[i + UPLOAD_DOCUMENT],
                            name: decodeURI(i.split(name + '_')[1]).replaceAll(/\*\$\?\$\*/g, '_').replaceAll(SPACE_REPLACEMENT, ' ').replace(BOOLEAN, '')
                        };
                        value.push(obj);

                        this.props.put({
                            params: {
                                key: i.replace(BOOLEAN, INDEX),
                                value: (props.formData[i + INDEX] || (match).toString()).toString()
                            }
                        });
                        match++;
                    }
                } else if (i.match(REAL_NAME)) {
                    obj[i] = props.formData[i];
                }
            }

            obj['value'] = _.sortBy(value, [(e) => {
                return parseInt(e.id);
            }]);
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj, () => this.setJointFilesList(this.state.value));
        }
    }

    setFile({
        value,
        key,
        index
    }) {
        this.setState(state => {
            if (state.value && Array.isArray(state.value)) {
                for (let v in value) {
                    let noAccent = this.accentsTidy(value[v].name);
                    let key = this.props.name + '_' + uuid();
                    let encoded = encodeURI(noAccent.replaceAll(UNDERSCORE, UNDERSCORE_REPLACEMENT).replaceAll(SPACE, SPACE_REPLACEMENT));
                    let obj = {
                        ...value[v],
                        name: noAccent,
                        id: (parseInt(this.state.value.length) + value[v].index).toString(),
                        checked: true,
                        ammend: false,
                        key: key + BOOLEAN,
                        encoded: encoded
                    };
                    obj[key + REAL_NAME] = noAccent;
                    state[key + REAL_NAME] = noAccent;
                    state.value.push(obj);

                    this.props.put({
                        params: {
                            key: key + REAL_NAME,
                            value: noAccent,
                        }
                    });

                    this.props.put({
                        params: {
                            key: key + ORIGINAL_NAME,
                            value: noAccent
                        }
                    });

                    this.props.put({
                        params: {
                            key: key + UPLOAD_DOCUMENT,
                            value: value[v].content,
                            title: this.props.title
                        }
                    });

                    this.props.put({
                        params: {
                            key: key + INDEX,
                            value: (state.value.length - 1).toString()
                        }
                    });

                }
            } else {
                console.error('value is not an array in dropfile');
            }

            return state;

        }, () => this.setJointFilesList(this.state.value));
    }

    accentsTidy(s) {
        var r = s;

        if (typeof r !== 'string') {
            r = r.replace(new RegExp(/\s/g), '');
        }
        r = r.replace(new RegExp(/[àáâãäå]/g), 'a');
        r = r.replace(new RegExp(/æ/g), 'ae');
        r = r.replace(new RegExp(/ç/g), 'c');
        r = r.replace(new RegExp(/[èéêë]/g), 'e');
        r = r.replace(new RegExp(/[ìíîï]/g), 'i');
        r = r.replace(new RegExp(/ñ/g), 'n');
        r = r.replace(new RegExp(/[òóôõö]/g), 'o');
        r = r.replace(new RegExp(/œ/g), 'oe');
        r = r.replace(new RegExp(/[ùúûü]/g), 'u');
        r = r.replace(new RegExp(/[ýÿ]/g), 'y');
        // r = r.replace(new RegExp(/\W/g),"");
        return r;
    };

    removeItem(e) {
        var key = e.currentTarget.getAttribute('removekey');
        var notencoded = e.currentTarget.getAttribute('notencoded');

        this.props.removeFileFromGCloud({
            params: {
                key: key + UPLOAD_DOCUMENT,
                value: ' '
            }
        });

        this.setState(
            (state) => {
                let { value } = state;
                _.remove(value, {
                    name: notencoded
                });
                state.value = value;
                return state;
            });
    }

    render() {
        let props = this.props;
        let state = this.state;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }
        let counter = 0;

        let multiple = true;

        if (this.props.multiple === false) {
            multiple = false;
        }

        let request;
        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        return (
            <>
                <div style={{ display: (this.props.hidePJ === false) ? 'block' : 'none' }}>
                    <Row>
                        <Col>
                            <Button
                                color="primary"
                                onClick={
                                    (e) => this.setJointFilesList(e)
                                }
                            >
                                {this.genLang({
                                        fr: 'Générer la liste des pièces jointes',
                                        en: 'generate joint files list'
                                    },
                                    this.state.lang)
                                }
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col key={props.name} style={style}>
                        <FormGroup>
                            <DropZoneGcloud
                                multiple={multiple}
                                maxFiles={this.props.maxFiles || 0}
                                index={this.state.value.length}
                                lang={this.state.lang}
                                tabindex={props.tabindex}
                                name={props.name}
                                id={'files' + props.name}
                                maxSize={Infinity}
                                accept={props.accept || '.pdf, .jpg, .jpeg, .png'}
                                dropMessage={props.dropMessage}
                                handleFile={(e, length) => {
                                    if (!this.fileCounter) {
                                        this.props.toggleLoading();
                                    }

                                    this.fileCounter++;
                                    this.fileArray.push(e);

                                    if (this.fileCounter >= length) {

                                        this.setFile({
                                            value: _.sortBy(this.fileArray, ['index']),
                                            key: 'files',
                                            index: e.index
                                        });
                                        this.fileCounter = 0;
                                        this.fileArray = [];
                                    }
                                }}
                                displayFiles={false}
                                onDropAccepted={(e) => {
                                    if (!this.props.add) {
                                        this.setState({ value: [] });
                                    }
                                }}
                            />
                        </FormGroup>
                        <Row>
                            <Col>
                                <SortableContainer
                                    public={this.props.public}
                                    token={this.props.token}
                                    ticket={this.state.ticket}
                                    setJointFilesList={(e) => {
                                        this.setState({ value: e }, () => {
                                            this.setJointFilesList(this.state.value);
                                        });
                                    }}
                                    version={this.state.version}
                                    simpleList={true}
                                    value={state.value}
                                    removeItem={this.removeItem}
                                    pushWaitingList={
                                        this.props.pushWaitingList
                                    }
                                    pullWaitingList={
                                        this.props.pullWaitingList
                                    }
                                    {...props}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }

}
