import React, { useContext, useState } from 'react';
import { useTranslation } from '@/composables/translation';
import { FolderContext } from '@/pages/psj/Folder';
import { CardCollapse } from '@/components/CardCollapse';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle
} from '@/components/ui/dialog';
import { Spinner } from '@/components/ui/spinner';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { type NextStepHistory as NextStepHistoryType } from '@/types/api/process';
import { DateTime } from 'luxon';
import { CardContent } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { EnterFullScreenIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';

interface Props {
    steps: NextStepHistoryType;
}

function NextStepList({ steps }: Props) {
    const { to } = useTranslation('psj.next-steps');
    return (
        <div className="tw-flex tw-pb-3 tw-pt-2 tw-px-4 tw-gap-6 tw-overflow-x-auto">
            {steps.map((s, i) => {
                const date = DateTime.fromISO(s.updated_at);
                return (
                    <div key={i} className="tw-flex tw-items-center tw-gap-2">
                        <Avatar className={cn('!tw-bg-secondary !tw-size-7', date.isValid && 'tw-mt-1')}>
                            <AvatarFallback className="tw-bg-primary tw-text-primary-foreground">
                                {i + 1}
                            </AvatarFallback>
                        </Avatar>
                        <div className="tw-mt-[-2px]">
                            <h5 className="tw-m-0 tw-text-base tw-font-bold tw-whitespace-nowrap">
                                {to(s.data?.title)}
                            </h5>
                            {date.isValid &&
                                <p className="tw-m-0 tw-text-xs tw-text-slate-500 tw-whitespace-nowrap">
                                    {date.toFormat('ff')}
                                </p>
                            }
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export function NextStepHistory() {
    const folder = useContext(FolderContext);
    const { t, ct } = useTranslation('psj.next-steps');
    const [isOpen, setIsOpen] = useState(false);
    const isHistoryEmpty = (folder?.case?.history?.nextstep?.length ?? 0) <= 0;
    const isProcessEmpty = (folder?.case?.product?.ns_list?.length ?? 0) <= 0;
    return (
        <>
            <CardCollapse
                title={t('title')} innerTitle
                titleSlot={
                    <div className="tw-ml-auto">
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    variant="ghost" size="icon"
                                    className="!tw-rounded-full tw-text-primary hover:tw-text-primary"
                                    disabled={folder?.loading}
                                    onClick={() => setIsOpen(true)}
                                >
                                    <EnterFullScreenIcon className="tw-size-5" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                {ct('fullscreen')}
                            </TooltipContent>
                        </Tooltip>
                    </div>
                }
            >
                {folder?.loading &&
                    <div className="tw-flex tw-justify-center tw-pt-2 tw-pb-8">
                        <Spinner className="tw-text-primary" />
                    </div>
                }
                {!folder?.loading &&
                    <CardContent className="!tw-p-4 !tw-pt-0">
                        <Label>{t('history.title')}</Label>
                        {isHistoryEmpty
                            ? <div className="tw-p-4 tw-text-center tw-text-sm tw-italic tw-text-muted-foreground">
                                {t('history.empty')}
                            </div>
                            : <NextStepList steps={folder?.case?.history?.nextstep ?? []} />
                        }
                        <Label>{t('process.title')}</Label>
                        {isProcessEmpty
                            ? <div className="tw-p-4 tw-text-center tw-text-sm tw-italic tw-text-muted-foreground">
                                {t('process.empty')}
                            </div>
                            : <NextStepList steps={
                                (folder?.case?.product?.ns_list ?? [])
                                    .map(ns => ({ data: ns, updated_at: '' }))
                            } />
                        }
                    </CardContent>
                }
            </CardCollapse>
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogContent className="!tw-max-w-4xl">
                    <DialogHeader>
                        <DialogTitle>{t('title')}</DialogTitle>
                    </DialogHeader>
                    <div className="tw-overflow-auto">
                        <Label>{t('history.title')}</Label>
                        {isHistoryEmpty
                            ? <div className="tw-p-4 tw-text-center tw-text-sm tw-italic tw-text-muted-foreground">
                                {t('history.empty')}
                            </div>
                            : <NextStepList steps={folder?.case?.history?.nextstep ?? []} />
                        }
                        <Label>{t('process.title')}</Label>
                        {isProcessEmpty
                            ? <div className="tw-p-4 tw-text-center tw-text-sm tw-italic tw-text-muted-foreground">
                                {t('process.empty')}
                            </div>
                            : <NextStepList steps={
                                (folder?.case?.product?.ns_list ?? [])
                                    .map(ns => ({ data: ns, updated_at: '' }))
                            } />
                        }
                    </div>
                    <DialogFooter>
                        <DialogClose asChild>
                            <Button>
                                {ct('ok')}
                            </Button>
                        </DialogClose>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
}
