import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
// eslint-disable-next-line
// @ts-ignore
import FilePreviewer from 'react-file-previewer';

import {
    SegmentedControlButton
} from '@/components/neoform/SegmentedControlButton/SegmentedControlButton';

import { type TranslationObject, useTranslation } from '@/composables/translation';

interface Meta {
    text?: string;
    json?: string;
}

interface Props {
    meta?: Meta;
    open: boolean;
    url: string;
    mime: string;
    data: string;
    name: string;
    hideControls: boolean;
    title: TranslationObject;
    onChange?: (value: boolean) => void;
}

export function PreviewModal(props: Props) {
    const [tab, setTab] = useState('document');
    const { to } = useTranslation();
    const options = [
        {
            value: 'document',
            label: 'Document'
        },
        {
            value: 'text',
            label: 'Text'
        },
        {
            value: 'json',
            label: 'JSON'
        }
    ];

    const parseText = (data: string, formatAsJson?: boolean) => {
        let text = window.atob(data.split('base64,')[1]);
        if (formatAsJson) {
            text = JSON.stringify(JSON.parse(text), undefined, 4);
        }
        return text;
    };

    const hasMeta = !!props.meta?.text || !!props.meta?.json;

    return (
        <Modal
            isOpen={props.open}
            toggle={() => props.onChange?.(!props.open)}>
            <ModalHeader>{to(props.title, 'Prévisualiser')}</ModalHeader>
            <ModalBody className="tw-max-h-[80vh] tw-overflow-auto">
                {hasMeta &&
                    <SegmentedControlButton
                        size="sm"
                        options={options}
                        selected={tab}
                        onChange={setTab}
                    />
                }
                {tab === 'document'
                    ? <FilePreviewer
                        file={{
                            url: props.url,
                            data: props.data,
                            mimeType: props.mime,
                            name: props.name
                        }}
                        hideControls={props.hideControls}
                    />
                    : <div className="tw-mt-4 tw-text-sm">
                        <pre>
                            {parseText((props.meta as any)[tab], tab === 'json')}
                        </pre>
                    </div>
                }
            </ModalBody>
        </Modal>
    );
}
