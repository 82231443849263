import React, { Component } from 'react';
import _ from 'lodash';
import { Card, CardBody, Col, Row } from 'reactstrap';

const BLOCK = 'block';
const NONE = 'none';

export class Section extends Component {

    constructor(props) {
        super(props);

        this.state = {
            display: this.props.display,
            title: this.props.title,
            description: this.props.description,
            ticket: this.props.ticket
        };
    }

    componentDidUpdate(prevProps) {

        let obj = {};

        if (this.state.display !== this.props.display) {
            obj.display = this.props.display;
        }

        if (this.state.title !== this.props.title) {
            obj.title = this.props.title;
        }

        if (this.state.description !== this.props.description) {
            obj.description = this.props.description;
        }

        if (prevProps.ticket !== this.props.ticket) {
            obj.ticket = this.props.ticket;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }

    }

    render() {

        let header;

        if (this.state.title || this.state.description) {
            header = (
                <Row className="tw-mb-4">
                    <Col md={12}>
                        {this.state.title ? <h5 className="form-header">
                            {this.state.title}
                        </h5> : ''}
                        {this.state.description ? <div className="form-desc">
                            {this.state.description}
                        </div> : ''}
                    </Col>
                </Row>
            );
        }

        return (
            <Card className="tw-mt-5" style={{ display: this.state.display ? BLOCK : NONE }}>
                <CardBody>
                    {header}
                    <div>
                        {this.props.children}
                    </div>
                </CardBody>
            </Card>
        );
    }
}
