import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { DocumentStatus } from '@/types/document';
import { toast } from 'react-toastify';

interface DocumentMeta {
    status: DocumentStatus;
    url?: string;
    created_at?: string;
}

interface FormDocuments {
    task_id: string;
    document_types: Record<string, DocumentMeta>;
}

interface State {
    documents: FormDocuments[];
}

interface Actions {
    getDocumentMeta: (taskId: string, docType: string) => DocumentMeta | null;
    addDocument: (taskId: string, docType: string, status?: DocumentStatus) => void;
    removeDocument: (taskId: string, docType: string) => void;
    updateDocumentStatus: (taskId: string, data: any) => void;
}

export const useDocumentStore = create<State & Actions>()(
    devtools(
        persist(
            (set, get) => (
                {
                    documents: [] as FormDocuments[],
                    getDocumentMeta: (taskId: string, docType: string) => {
                        const doc = get().documents.find(d => d.task_id === taskId);
                        return doc?.document_types[docType] ?? null;
                    },
                    addDocument: (
                        taskId,
                        docType,
                        status = DocumentStatus.PENDING
                    ) => {
                        set(state => {
                            const documents = [...state.documents];
                            const existingDoc = documents.find(d => d.task_id === taskId);
                            if (existingDoc) {
                                existingDoc.document_types[docType] = { status };
                            } else {
                                documents.push({
                                    task_id: taskId,
                                    document_types: { [docType]: { status } }
                                });
                            }
                            return { documents };
                        });
                    },
                    removeDocument: (taskId, docType) => {
                        set(state => {
                            const documents = [...state.documents];
                            const docIndex = documents.findIndex(d => d.task_id === taskId);
                            if (docIndex >= 0) {
                                const doc = documents[docIndex];
                                delete doc.document_types[docType];
                                if (Object.keys(doc.document_types).length <= 0) {
                                    documents.splice(docIndex, 1);
                                }
                            }
                            return { documents };
                        });
                    },
                    updateDocumentStatus: (taskId, data) => {
                        set(state => {
                            const documents = [...state.documents];
                            const docIndex = documents.findIndex(d => d.task_id === taskId);
                            if (docIndex >= 0) {
                                const doc = documents[docIndex];
                                Object.keys(doc.document_types)
                                    .forEach((type) => {
                                        const status = data[type]?.status ??
                                                       DocumentStatus.COMPLETED;
                                        if (doc.document_types[type]?.status !==
                                            DocumentStatus.COMPLETED &&
                                            status ===
                                            DocumentStatus.COMPLETED) {
                                            toast(`Document '${type}' généré`, { type: 'success' });
                                        }
                                        doc.document_types[type] = {
                                            status,
                                            url: data[type]?.url ?? undefined,
                                            created_at: data[type]?.created_at ?? undefined
                                        };
                                    });
                                if (Object.keys(doc.document_types).length <= 0) {
                                    documents.splice(docIndex, 1);
                                }
                            }
                            return { documents };
                        });
                    }
                }
            ),
            {
                name: 'document'
            }
        )
    )
);
