import React from 'react';
import './SegmentedControlButton.css';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { cn } from '@/lib/utils';

interface Item {
    value: string;
    label: string | TranslationObject;
    count?: number;
}

interface Props {
    selected: string;
    options: Item[];
    className?: string;
    size?: 'md' | 'sm' | 'lg';
    onChange?: (value: string) => void;
}

export function SegmentedControlButton(
    { selected, options, className, size = 'md', onChange }: Props
) {
    const postfix = size ? `-${size}` : '';
    const { to } = useTranslation();
    return (
        <div className={cn(`control-container${postfix}`, className)}>
            {options.map((i) => {
                return (
                    <button
                        key={i.value}
                        type="button"
                        className={cn(
                            `option-container${postfix}`,
                            { 'seg-selected': selected === i.value }
                        )}
                        value={i.value}
                        onClick={() => onChange?.(i.value)}
                    >
                        {to(i.label)}
                        {i.count !== undefined && (
                            <div className={`option-container-count${postfix}`}>{i.count}</div>
                        )}
                    </button>
                );
            })}
        </div>
    );
}
