import React, { Component } from 'react';
import { Card, CardBody, CardText, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import Select from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

import { Address } from './Address';
import { DropContainer } from './DropContainer';
import { Calendar } from './Calendar';
import { Legend } from './Legend';

const DEFAULT_STYLE = {};

const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;
const fr = 'fr';
const TEXT = 'text';
const lang = fr;
const options = [
    {
        value: '',
        label: '-'
    },
    {
        value: 'fils',
        label: 'fils/fille'
    },
    {
        value: 'conjoint',
        label: 'Conjoint'
    },
    {
        value: 'conjoint-de-fait',
        label: 'Conjoint.e de fait'
    },
    {
        value: 'petit-fils',
        label: 'petit-fils/ petite-fille'
    },
    {
        value: 'frère',
        label: 'frère/soeur'
    },
    {
        value: 'père',
        label: 'père/mère'
    },
    {
        value: 'neveu',
        label: 'neveu/nièce'
    },
    {
        value: 'petit-neveu',
        label: 'petit-neveu/petite-nièce'
    },
    {
        value: 'oncle',
        label: 'oncle/tante'
    },
    {
        value: 'cousin',
        label: 'cousin/cousine'
    },
    {
        value: 'cousin germain',
        label: 'cousin/cousine germain.e'
    },
    {
        value: 'cousin issu de germain',
        label: 'cousin issu de germain'
    },
    {
        value: 'grand-père',
        label: 'grand-père/grand-mère'
    },
    {
        value: 'beau-frère',
        label: 'belle soeur/beau frère'
    },
    {
        value: 'beau-père',
        label: 'beau-père/belle-mère'
    },
    {
        value: 'beau-fils',
        label: 'beau-fils/belle-fille'
    },
    {
        value: 'autre',
        label: 'Autre'
    }
];

const optionsEN = [
    {
        value: '',
        label: '-'
    },
    {
        value: 'fils',
        label: 'Child'
    },
    {
        value: 'conjoint',
        label: 'Spouse'
    },
    {
        value: 'petit-fils',
        label: 'Grandchild'
    },
    {
        value: 'frère',
        label: 'Sibling'
    },
    {
        value: 'père',
        label: 'Parent'
    },
    {
        value: 'neveu',
        label: 'Nephew/niece'
    },
    {
        value: 'petit-neveu',
        label: 'Great nephew/ great niece'
    },
    {
        value: 'oncle',
        label: 'Uncle/Aunt'
    },
    {
        value: 'cousin',
        label: 'Cousin'
    },
    {
        value: 'cousin germain',
        label: 'First cousin'
    },
    {
        value: 'cousin issu de germain',
        label: 'Second cousin'
    },
    {
        value: 'grand-père',
        label: 'Grandparent'
    },
    {
        value: 'beau-frère',
        label: 'Sibling in-law'
    },
    {
        value: 'beau-père',
        label: 'Parent in-law'
    },
    {
        value: 'beau-fils',
        label: 'Stepchild'
    },
    {
        value: 'autre',
        label: 'Other'
    }
];

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class User extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '';
        this.state = {
            lang: this.props.lang || 'fr',
            name: fd[this.props.name + '_name'] || '',
            firstname: fd[this.props.name + '_firstname'] || '',
            gender: this.props.formData[this.props.name + '_gender'],
            relation: this.props.formData[this.props.name + '_relation'],
            display: this.props.defaultDisplay === false ? false : true,
            position: this.props.formData[this.props.name + '_position'],
            statut: this.props.formData[this.props.name + '_statut'],
            data: fd
        };

        this.option = this.option.bind(this);
        this.genLang = this.genLang.bind(this);
    }

    genLang(key = 'missing') {
        if (!this.props.trad) return key;

        if (!this.props.trad[key]) {
            return this.props.trad['wrongKey'][this.state.lang] + ' ' + key;
        }
        return this.props.trad[key][this.state.lang];
    }

    genLangNew(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevProps) {
        let props = this.props,
            obj = {};

        if (
            (prevProps.formData && props.formData) ||
            props.version !== prevProps.version
        ) {
            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }

            if (
                props.formData[props.name + '_name'] !==
                prevProps.formData[props.name + '_name']
            ) {
                obj['name'] = props.formData[props.name + '_name'] || '';
            }

            if (
                props.formData[props.name + '_firstname'] !==
                prevProps.formData[props.name + '_firstname']
            ) {
                obj['firstname'] = props.formData[props.name + '_firstname'] || '';
            }

            if (
                props.formData[props.name + '_assistant'] !==
                prevProps.formData[props.name + '_assistant']
            ) {
                obj['assistant'] = props.formData[props.name + '_assistant'] || '';
            }

            if (
                props.formData[props.name + '_gender'] !==
                prevProps.formData[props.name + '_gender']
            ) {
                obj['gender'] = props.formData[props.name + '_gender'] || '';
            }

            if (
                props.formData[props.name + '_relation'] !==
                prevProps.formData[props.name + '_relation']
            ) {
                obj['relation'] = props.formData[props.name + '_relation'] || '';
            }

            if (
                props.formData[props.name + '_position'] !==
                prevProps.formData[props.name + '_position']
            ) {
                obj['position'] = props.formData[props.name + '_position'] || '';
            }

            if (props.display !== prevProps.display) {
                if (props.display === false && prevProps.display === true) {
                    obj.display = false;
                } else if (props.display === true && prevProps.display === false) {
                    obj.display = true;
                } else if (prevProps.display === undefined) {
                    obj.display = props.display;
                }
            }
        }

        if (!_.isEmpty(obj)) this.setState(obj);
    }

    option() {
        if (this.props.assistant) {
            return (
                <Col md={6}>
                    <FormGroup>
                        <Label>Assistant</Label>
                        <Input
                            type="text"
                            name={this.props.name + '_assistant'}
                            onChange={(e) => {
                                this.props.put({
                                    params: {
                                        key: e.target.name,
                                        value: e.target.value,
                                        title: this.props.title
                                    }
                                });
                                this.setState({ assistant: e.target.value });
                            }}
                            value={this.state.assistant || ''}
                        />
                    </FormGroup>
                </Col>
            );
        }
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        // console.log('properties of user name',this.props.name,this.props.noFirstname)
        if (this.props.noCard) {
            return (
                <Col md={this.props.col || 12} style={style}>
                    {this.props.remove &&
                        <span
                            className="remove"
                            onClick={() => this.props.remove(this.props.name)}
                        >
                            {cross}
                        </span>
                    }
                    <Row>
                        {!this.props.noName &&
                            <Col md={4}>
                                <FormGroup>
                                    <Label>{this.genLang('_name')}</Label>
                                    <Input
                                        type={TEXT}
                                        name={this.props.name + '_name'}
                                        onChange={(e) => {
                                            this.setState({ name: e.target.value });
                                            this.props.put({
                                                params: {
                                                    key: e.target.name,
                                                    value: e.target.value,
                                                    title: this.props.trad['_name']
                                                }
                                            });
                                        }}
                                        value={this.state.name}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {!this.props.noFirstname &&
                            <Col md={4}>
                                <FormGroup>
                                    <Label>{this.genLang('_firstname')}</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_firstname'}
                                        onChange={(e) => {
                                            this.setState({ firstname: e.target.value });
                                            this.props.put({
                                                params: {
                                                    key: e.target.name,
                                                    value: e.target.value,
                                                    title: this.props.trad['_firstname']
                                                }
                                            });
                                        }}
                                        value={this.state.firstname}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {!this.props.noRelation &&
                            <Col md={2}>
                                <FormGroup>
                                    <Label>{this.genLang('relationship2')}</Label>
                                    <Select
                                        style={{ zIndex: 1000 }}
                                        id={this.props.name + '_relation'}
                                        name={this.props.name + '_relation'}
                                        value={_.find(options, (e) => {
                                            return e.value === this.state.relation;
                                        })}
                                        onChange={(e) => {
                                            this.setState({ relation: e.value });
                                            this.props.put({
                                                params: {
                                                    key: this.props.name + '_relation',
                                                    value: e.value,
                                                    title: this.props.trad['relationship2']
                                                }
                                            });
                                        }}
                                        options={lang === fr ? options : optionsEN}
                                    />
                                </FormGroup>
                            </Col>
                        }
                        {!this.props.noGender &&
                            <Col md={this.props.noRelation ? 4 : 2}>
                                <FormGroup>
                                    <Label>{this.genLang('_title')}</Label>
                                    <Input
                                        type="select"
                                        name={this.props.name + '_gender'}
                                        onChange={(e) => {
                                            this.setState({ gender: e.target.value });
                                            this.props.put({
                                                params: {
                                                    key: e.target.name,
                                                    value: e.target.value,
                                                    title: this.props.trad['_title']
                                                }
                                            });
                                        }}
                                        value={this.state.gender || ''}
                                    >
                                        <option value=""></option>
                                        <option value="-">{this.genLang('_otherGender')}</option>
                                        <option value="M">{this.genLang('_maleGender')}</option>
                                        <option value="F">{this.genLang('_femaleGender')}</option>
                                        {/* <option value="NA" >Autre</option> */}
                                    </Input>
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    {this.props.position &&
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{this.genLang('position')}</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_position'}
                                        onChange={(e) => {
                                            this.setState({ position: e.target.value });
                                            this.props.put({
                                                params: {
                                                    key: e.target.name,
                                                    value: e.target.value,
                                                    title: this.props.trad['position']
                                                }
                                            });
                                        }}
                                        value={this.state.position}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{this.genLang('status')}</Label>
                                    <Input
                                        type="select"
                                        name={this.props.name + '_statut'}
                                        onChange={(e) => {
                                            this.setState({ statut: e.target.value });
                                            this.props.put({
                                                params: {
                                                    key: e.target.name,
                                                    value: e.target.value,
                                                    title: this.props.trad['status']
                                                }
                                            });
                                        }}
                                        value={this.state.statut || '-'}
                                    >
                                        <option value={this.genLang('_citizen')}>
                                            {this.genLang('_citizen')}
                                        </option>
                                        <option value={this.genLang('_permanentResident')}>
                                            {this.genLang('_permanentResident')}
                                        </option>
                                        <option value={this.genLang('_notResident')}>
                                            {this.genLang('_notResident')}
                                        </option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                    {this.props.birthday &&
                        <Row>
                            <Col md={12}>
                                <Label>{this.genLang('dateOfBirth')}</Label>
                                <Calendar
                                    put={this.props.put}
                                    remove={this.props.remove}
                                    style={{ paddingLeft: '0px' }}
                                    lang={this.state.lang}
                                    version={this.state.version}
                                    title={this.props.trad['dateOfBirth']}
                                    key={this.props.name + '_Birthday'}
                                    name={this.props.name + '_Birthday'}
                                    // {...params}
                                    formData={{ ...this.state.data }}
                                />
                            </Col>
                        </Row>
                    }
                    {this.props.address &&
                        <Row>
                            <Address
                                col={12}
                                put={this.props.put}
                                lang={this.state.lang}
                                trad={this.props.trad}
                                formData={{ ...this.props.formData }}
                                remove={this.removeHeirForm}
                                name={this.props.name}
                                version={this.state.version}
                                target={this.props.title}
                                country={true}
                                contact={this.props.noContact ? false : true}
                            />
                        </Row>
                    }

                    {this.props.position &&
                        <Row>
                            <DropContainer
                                removeFileFromGCloud={this.props.removeFileFromGCloud}
                                wow="wow"
                                public={this.props.public}
                                add={this.props.add}
                                col={12}
                                name={this.props.name + '_atta'}
                                title={this.genLang('_actdoclbl')}
                                // formData={this.props.formData}
                                put={this.props.put}
                                ticket={this.props.ticket}
                                toggleLoading={this.props.toggleLoading}
                                target={this.props.target}
                                lang={this.state.lang}
                                version={this.state.version}
                                key={this.props.formData + '_atta'}
                                // onChange={this.onChange}
                                formData={this.state.data}
                                // put={this.props.put}
                                remove={this.props.remove}
                                // removeFileFromGCloud={this.props.removeFileFromGCloud}

                                pushWaitingList={this.props.pushWaitingList}
                                pullWaitingList={this.props.pullWaitingList}
                            />
                        </Row>
                    }
                    {this.props.children}
                </Col>
            );
        }

        return (
            <Col md={this.props.col || 12} style={style}>
                <Card
                    // className="neoGray"
                    style={{ marginBottom: '5px' }}
                >
                    {this.props.remove &&
                        <span className="remove"
                              onClick={() => this.props.remove(this.props.name)}>
                          {cross}
                        </span>
                    }
                    <CardBody>
                        <CardText tag="div">
                            {this.props.title.fr || this.props.title.en &&
                                <Row>
                                    <Col md={12}>
                                        <FormGroup tag="fieldset" className="!tw-mb-0">
                                            <Legend>
                                                <h5>
                                                    {this.genLangNew(this.props.title, this.state.lang)}
                                                </h5>
                                            </Legend>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                {!this.props.noName &&
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{this.genLang('_name')}</Label>
                                            <Input
                                                type={TEXT}
                                                name={this.props.name + '_name'}
                                                onChange={(e) => {
                                                    this.setState({ name: e.target.value });
                                                    this.props.put({
                                                        params: {
                                                            key: e.target.name,
                                                            value: e.target.value,
                                                            title: this.props.trad['_name']
                                                        }
                                                    });
                                                }

                                                }
                                                value={this.state.name}
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                {!this.props.noFirstname &&
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{this.genLang('_firstname')}</Label>
                                            <Input
                                                type="text"
                                                name={this.props.name + '_firstname'}
                                                onChange={(e) => {
                                                    this.setState({ firstname: e.target.value });
                                                    this.props.put({
                                                        params: {
                                                            key: e.target.name,
                                                            value: e.target.value,
                                                            title: this.props.trad['_firstname']
                                                        }
                                                    });
                                                }

                                                }
                                                value={this.state.firstname}
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                {!this.props.noRelation &&
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label>{this.genLang('relationship2')}</Label>
                                            <Select
                                                style={{ zIndex: 1000 }}
                                                id={this.props.name + '_relation'}
                                                name={this.props.name + '_relation'}
                                                value={_.find(options, (e) => {
                                                    return e.value === this.state.relation;
                                                })}
                                                onChange={(e) => {
                                                    this.setState({ relation: e.value });
                                                    this.props.put({
                                                        params: {
                                                            key: this.props.name + '_relation',
                                                            value: e.value,
                                                            title: this.props.trad['relationship2']
                                                        }
                                                    });
                                                }}
                                                options={lang === fr ? options : optionsEN}
                                            />
                                        </FormGroup>
                                    </Col>
                                }

                                {!this.props.noGender &&
                                    <Col md={this.props.noRelation ? 4 : 2}>
                                        <FormGroup>
                                            <Label>{this.genLang('_title')}</Label>
                                            <Input
                                                type="select"
                                                name={this.props.name + '_gender'}
                                                onChange={(e) => {
                                                    this.setState({ gender: e.target.value });
                                                    this.props.put({
                                                        params: {
                                                            key: e.target.name,
                                                            value: e.target.value,
                                                            title: this.props.trad['_title']
                                                        }
                                                    });
                                                }

                                                }
                                                value={this.state.gender || ''}
                                            >
                                                <option value=""></option>
                                                <option value="-">{this.genLang('_Autre')}</option>
                                                <option value="M">{this.genLang('_sir')}</option>
                                                <option value="F">{this.genLang('_madam')}</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                }
                            </Row>
                            {this.props.position &&
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>{this.genLang('position')}</Label>
                                            <Input
                                                type="text"
                                                name={this.props.name + '_position'}
                                                onChange={(e) => {
                                                    this.setState({ position: e.target.value });
                                                    this.props.put({
                                                        params: {
                                                            key: e.target.name,
                                                            value: e.target.value,
                                                            title: this.props.trad['position']
                                                        }
                                                    });
                                                }

                                                }
                                                value={this.state.position}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>{this.genLang('status')}</Label>
                                            <Input
                                                type="select"
                                                name={this.props.name + '_statut'}
                                                onChange={(e) => {
                                                    this.setState({ statut: e.target.value });
                                                    this.props.put({
                                                        params: {
                                                            key: e.target.name,
                                                            value: e.target.value,
                                                            title: this.props.trad['status']
                                                        }
                                                    });
                                                }

                                                }
                                                value={this.state.statut || '-'}
                                            >
                                                <option value={this.genLang('_citizen')}>
                                                    {this.genLang('_citizen')}
                                                </option>
                                                <option value={this.genLang('_permanentResident')}>
                                                    {this.genLang('_permanentResident')}
                                                </option>
                                                <option value={this.genLang('_notResident')}>
                                                    {this.genLang('_notResident')}
                                                </option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                            {this.props.birthday &&
                                <Row>
                                    <Col md={12}>
                                        <Label>{'Date de naissance'}</Label>
                                        <Calendar
                                            put={this.props.put}
                                            remove={this.props.remove}
                                            style={{ paddingLeft: '0px' }}
                                            lang={this.state.lang}
                                            version={this.state.version}
                                            title={''}
                                            key={this.props.name + '_Birthday'}
                                            name={this.props.name + '_Birthday'}
                                            // {...params}
                                            formData={{ ...this.state.data }}
                                        />
                                    </Col>
                                </Row>
                            }

                            {this.props.address &&
                                <Row>
                                    <Address
                                        col={12}
                                        put={this.props.put}
                                        lang={this.state.lang}
                                        trad={this.props.trad}
                                        formData={{ ...this.props.formData }}
                                        remove={this.removeHeirForm}
                                        name={this.props.name}
                                        version={this.state.version}
                                        target={this.props.title}
                                        country={true}
                                    />
                                </Row>
                            }

                            {this.props.position &&
                                <Row>
                                    <DropContainer
                                        removeFileFromGCloud={this.props.removeFileFromGCloud}
                                        wow="wow"
                                        public={this.props.public}
                                        add={this.props.add}
                                        col={12}
                                        name={this.props.name + '_atta'}
                                        title={
                                            'Ajouter l\'un des documents suivants (permis de conduire, carte d’assurance maladie ou passeport)'
                                        }
                                        // formData={this.props.formData}
                                        put={this.props.put}
                                        ticket={this.props.ticket}
                                        toggleLoading={this.props.toggleLoading}
                                        target={this.props.target}
                                        lang={this.state.lang}
                                        version={this.state.version}
                                        key={this.props.formData + '_atta'}
                                        // onChange={this.onChange}
                                        formData={this.state.data}
                                        // put={this.props.put}
                                        remove={this.props.remove}
                                        // removeFileFromGCloud={this.props.removeFileFromGCloud}

                                        pushWaitingList={this.props.pushWaitingList}
                                        pullWaitingList={this.props.pullWaitingList}
                                    />
                                </Row>
                            }
                            {this.props.children}
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}
