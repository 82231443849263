import React, { useMemo, useState } from 'react';
import { SummaryItem } from '@/components/psj/Parties';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { useTranslation } from '@/composables/translation';

interface Props {
    value: any;
}

export function MetadataTags(props: Props) {
    const { ct } = useTranslation();
    const [open, setOpen] = useState(false);
    const entries = useMemo(
        () => Object.entries<any>(props.value).map(([k, v]) => [k, String(v)]),
        [props.value]
    );
    return (
        <Collapsible open={open} onOpenChange={setOpen}>
            <CollapsibleTrigger className={cn(
                'tw-flex tw-gap-1 tw-items-center tw-text-sm',
                'tw-text-muted-foreground tw-font-semibold hover:tw-underline'
            )}>
                {open
                    ? <ChevronDownIcon className="tw-relative tw-top-[1px]" />
                    : <ChevronRightIcon className="tw-relative tw-top-[1px]" />}
                {ct('metadata')}
            </CollapsibleTrigger>
            <CollapsibleContent className="tw-text-sm">
                {entries.map(([key, value]) =>
                    <SummaryItem
                        key={key}
                        value={value}
                        renderedValue={<>
                            <span className="tw-mr-1 tw-font-semibold">{key}:</span>
                            {value}
                        </>}
                    />
                )}
            </CollapsibleContent>
        </Collapsible>
    );
}
