import * as React from 'react';
import {
    CaretSortIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon
} from '@radix-ui/react-icons';
import * as SelectPrimitive from '@radix-ui/react-select';

import { cn } from '@/lib/utils';

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({
    className,
    children,
    ...props
}, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
            'tw-flex tw-h-9 tw-w-full tw-items-center tw-justify-between tw-whitespace-nowrap tw-rounded-md',
            'tw-border tw-border-input tw-bg-transparent tw-px-3 tw-py-2 tw-text-sm tw-shadow-sm',
            'tw-ring-offset-background placeholder:tw-text-muted-foreground focus:tw-outline-none',
            'focus:tw-ring-1 focus:tw-ring-ring disabled:tw-cursor-not-allowed disabled:tw-opacity-50',
            '[&>span]:tw-line-clamp-1',
            className
        )}
        {...props}
    >
        {children}
        <SelectPrimitive.Icon asChild>
            <CaretSortIcon className="tw-h-4 tw-w-4 tw-opacity-50"/>
        </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({
    className,
    ...props
}, ref) => (
    <SelectPrimitive.ScrollUpButton
        ref={ref}
        className={cn(
            'tw-flex tw-cursor-default tw-items-center tw-justify-center tw-py-1',
            className
        )}
        {...props}
    >
        <ChevronUpIcon/>
    </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({
    className,
    ...props
}, ref) => (
    <SelectPrimitive.ScrollDownButton
        ref={ref}
        className={cn(
            'tw-flex tw-cursor-default tw-items-center tw-justify-center tw-py-1',
            className
        )}
        {...props}
    >
        <ChevronDownIcon/>
    </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
    SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({
    className,
    children,
    position = 'popper',
    ...props
}, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={cn(
                'tw-relative tw-z-50 tw-max-h-96 tw-min-w-[8rem] tw-overflow-hidden tw-rounded-md tw-border',
                'tw-bg-popover tw-text-popover-foreground tw-shadow-md data-[state=open]:tw-animate-in',
                'data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0',
                'data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95',
                'data-[side=bottom]:tw-slide-in-from-top-2 data-[side=left]:tw-slide-in-from-right-2',
                'data-[side=right]:tw-slide-in-from-left-2 data-[side=top]:tw-slide-in-from-bottom-2',
                position === 'popper' &&
                ('data-[side=bottom]:tw-translate-y-1 data-[side=left]:tw--translate-x-1 ' +
                    'data-[side=right]:tw-translate-x-1 data-[side=top]:tw--translate-y-1'),
                className
            )}
            position={position}
            {...props}
        >
            <SelectScrollUpButton/>
            <SelectPrimitive.Viewport
                className={cn(
                    'tw-p-1',
                    position === 'popper' &&
                    'tw-h-[var(--radix-select-trigger-height)] tw-w-full tw-min-w-[var(--radix-select-trigger-width)]'
                )}
            >
                {children}
            </SelectPrimitive.Viewport>
            <SelectScrollDownButton/>
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Label>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({
    className,
    ...props
}, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={cn('tw-px-2 tw-py-1.5 tw-text-sm tw-font-semibold', className)}
        {...props}
    />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({
    className,
    children,
    ...props
}, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cn(
            'tw-relative tw-flex tw-w-full tw-cursor-default tw-select-none tw-items-center tw-rounded-sm tw-py-1.5',
            'tw-pl-2 tw-pr-8 tw-text-sm tw-outline-none focus:tw-bg-accent focus:tw-text-accent-foreground',
            'data-[disabled]:tw-pointer-events-none data-[disabled]:tw-opacity-50',
            className
        )}
        {...props}
    >
        <span
            className="tw-absolute tw-right-2 tw-flex tw-h-3.5 tw-w-3.5 tw-items-center tw-justify-center">
            <SelectPrimitive.ItemIndicator>
                <CheckIcon className="tw-h-4 tw-w-4"/>
            </SelectPrimitive.ItemIndicator>
        </span>
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({
    className,
    ...props
}, ref) => (
    <SelectPrimitive.Separator
        ref={ref}
        className={cn('tw--mx-1 tw-my-1 tw-h-px tw-bg-muted', className)}
        {...props}
    />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
    Select,
    SelectGroup,
    SelectValue,
    SelectTrigger,
    SelectContent,
    SelectLabel,
    SelectItem,
    SelectSeparator,
    SelectScrollUpButton,
    SelectScrollDownButton
};
