import React, { Component } from 'react';
import Select from 'react-select/creatable';
import _ from 'lodash';
import { Col, FormGroup, Label, } from 'reactstrap';
import { connect } from 'react-redux';
import rootsActions from '@/store/old/_actions/root-actions';

import { NotesInput } from './NotesInput';

const DEFAULT_TITLE = {
    en: 'Request these documents to the client',
    fr: 'Documents à demander au client'
};
const DEFAULT_SIZE = 12;

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const DEFAULT_STYLE = { textAlign: 'left' };

export class RequestedDocumentsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { value: [] };
        this.state.display = this.props.defaultDisplay === false ? false : true;
        this.onChange = this.onChange.bind(this);
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            obj['value'] = [];
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    onChange(e) {
        this.setState({
            value: e
        }, () => {

            let test = _.map(e, (f) => {
                let obj = {};
                obj[f.value] = true;
                return obj;
            });

            this.props.putDocument({ params: Object.assign({}, ...test) });
        });
    }

    render() {
        let props = this.props;
        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let default_value = this.props.isMulti ? [] : {};

        let options = this.props.request && this.props.request.listAttach ? this.props.request.listAttach : [];

        options = _.map(options, (e) => {

            return {
                ...e,
                key: this.props.name + '_option' + e.value,
                label: this.genLang(e.label, this.state.lang),
                value: this.genLang(e.value, this.state.lang)
            };
        });

        return (<Col md={props.col || DEFAULT_SIZE} key={props.name} style={style}>
            <FormGroup>
                <Label><h5>{this.genLang(props.title || DEFAULT_TITLE, this.state.lang)}</h5></Label>
                <NotesInput
                    {...props}
                    onChange={props.onChange}
                    formData={props.data}
                    note={true}
                    name={'requested_documents'}
                />
                <Select
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({
                            ...provided,
                            zIndex: props.zIndex || 9999
                        })
                    }}
                    id={this.props.name}
                    tabIndex={props.tabindex}
                    onChange={(e) => this.onChange(e)}
                    options={options}
                    value={this.state.value || default_value}
                    name={this.props.name}
                    isClearable
                    isSearchable
                    isMulti={true}
                />
            </FormGroup>
        </Col>);
    }

}

const mapStateToProps = state => ({
    rootsActions: rootsActions,
    loading: state.loading.loading,
    request: state.request,
});

const mapActionsToProps = {
    put: rootsActions.formsActions.putWill,
    putField: rootsActions.requestActions.putField,
    putDocument: rootsActions.requestActions.putDocument,
};

export const RequestedDocuments = connect(mapStateToProps, mapActionsToProps)(RequestedDocumentsComponent);
