import React, { Component } from 'react';

export class Legend extends Component {
    render() {
        let style = {
            marginBottom: '15px',
            cursor: 'pointer'
        };

        if (this.props.style) {
            style = {
                style, ...this.props.style
            };
        }

        if (this.props.color) {
            style.color = this.props.color;
        }

        if (this.props.size) {
            style.fontSize = this.props.size;
        }

        return (<legend
            onClick={() => {
                if (this.props.callback) {
                    this.props.callback(this.props.target);
                } else {
                    console.log('pas de callback dans legend :(');
                }
            }}
            className="tw-mt-3 m-b-20 p-l-15 p-r-15"
            style={style}
        >
            <span style={{ backgroundColor: '#f7f6f6' }}>{this.props.children || ''}</span>
        </legend>);
    }
}
