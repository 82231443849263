import { type TranslationObject } from '@/composables/translation';
import { faFolder, faTable, faNoteSticky } from '@fortawesome/free-solid-svg-icons';

export type TabType = 'folder' | 'view' | 'notes';

export interface Tab {
    url: string;
    label: string | TranslationObject;
    type: TabType;
}

export const iconMap = {
    folder: faFolder,
    view: faTable,
    notes: faNoteSticky
} as const;
