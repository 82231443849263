import React, { useEffect, useMemo, useState } from 'react';
import PostalMime, { type Email } from 'postal-mime';
import axios from 'axios';
import { CalendarIcon, Link2Icon, PersonIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { downloadArrayBuffer } from '@/composables/utils';
import { DateTime } from 'luxon';
import { Spinner } from './spinner';
import useHtml from '@/composables/html';

interface Props {
    url: string;
}

export function Eml(props: Props) {
    const [data, setData] = useState<Email>();
    const html = useHtml(data?.html ?? '');
    const date = useMemo(
        () => DateTime.fromISO(data?.date ?? ''),
        [data]
    );

    useEffect(() => {
        axios.get(props.url, { responseType: 'blob' })
            .then((res) => PostalMime.parse(res.data))
            .then((res) => setData(res));
    }, [props.url]);

    if (!data) {
        return <Spinner className="tw-text-primary" />;
    }

    const bodyClassName = cn(
        'tw-p-2 tw-border tw-border-input tw-prose [&>p]:tw-my-0',
        'tw-max-w-none tw-text-wrap tw-text-sm tw-rounded-sm'
    );

    return (
        <div className="tw-w-full tw-flex tw-flex-col tw-gap-2">
            <div className="tw-font-semibold">{data.subject}</div>
            <div className="tw-flex tw-gap-6">
                <div className="tw-flex tw-items-center tw-text-primary">
                    <PersonIcon className="tw-size-4 tw-mr-2"/>
                    {data.from.name} [{data.from.address}]
                </div>
                {date.isValid &&
                    <div className="tw-flex tw-items-center">
                        <CalendarIcon className="tw-size-4 tw-mr-2"/>
                        {date.toFormat('ff')}
                    </div>
                }
            </div>
            <div className="tw-overflow-auto">
                {html
                    ? <div className={bodyClassName} dangerouslySetInnerHTML={{ __html: html }}>
                    </div>
                    : <pre className={bodyClassName}>
                        {data.text}
                    </pre>
                }
            </div>
            {data.attachments.map((a) => (
                <Button
                    key={a.filename} variant="link"
                    className={cn(
                        '!tw-w-fit !tw-h-fit tw-flex tw-items-center tw-gap-2',
                        '!tw-px-0 !tw-py-1'
                    )}
                    onClick={() => downloadArrayBuffer(a.content, a.filename ?? undefined)}
                >
                    <Link2Icon className="tw-mt-0.5"/>
                    {a.filename}
                </Button>
            ))}
        </div>
    );
}
