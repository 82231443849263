import React, { Component } from 'react';
import { Card, CardBody, CardText, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { DropContainer } from './DropContainer';
import { Address } from './Address';
import { CheckInput } from './CheckInput';
import { Calendar } from './Calendar';
import { Legend } from './Legend';

const DEFAULT_STYLE = {};

const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;

const certificatCheckListFR = [
    { value: 'soumises à des restrictions sur le transfert;' },
    { value: 'sans valeur nominale;' },
    { value: 'entièrement libérées;' },
    { value: 'soumises à une convention unanime des actionnaires;' },
    { value: 'grevées de droits en faveur de tiers;' }
];

const declarationFR = [
    { value: 'Un dirigeant, administrateur, salarié ou fondateur de la Société ou une personne participant au contrôle de la Société' },
    { value: 'Un dirigeant, administrateur ou salarié d’une société du même groupe que la Société' },
    { value: 'Le ou la conjoint(e), père et mère, grands-parents, frères, sœurs, enfants ou petits-enfants des administrateurs, membres de la haute direction, fondateurs ou personnes participant au contrôle de la Société' },
    { value: 'Les père et mère, grands-parents, frères, sœurs, enfants ou petits-enfants du conjoint des administrateurs, membres de la haute direction, fondateurs ou personnes participant au contrôle de la Société' },
    { value: 'Les amis très proches des administrateurs, membres de la haute direction, fondateurs ou personnes participant au contrôle de la Société' },
    { value: 'Les proches partenaires des administrateurs, membres de la haute direction, fondateurs ou personnes participant au contrôle de la Société' },
    { value: 'Les conjoints, père et mère, grands-parents, frères, sœurs, enfants ou petits-enfants du porteur vendeur ou du conjoint de celui-ci' },
    { value: 'Les porteurs (actionnaires) de la Société' },
    { value: 'Un investisseur qualifié au sens du Règlement 45-106 sur les dispenses de prospectus' },
    { value: 'Une personne dont les titres comportant droit de vote sont en majorité la propriété véritable de personnes visées aux sous-paragraphes a) à i) ou dont les administrateurs sont en majorité des personnes visées aux sous-paragraphes a) à i)' },
    { value: 'Une fiducie ou une succession dont tous les bénéficiaires ou une majorité des fiduciaires ou des liquidateurs sont des personnes visées aux sous-paragraphes a) à i)' },
    { value: 'Une personne qui n’est pas du public' },
];

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class Actionnaire extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '';
        this.state = {
            lang: this.props.lang || 'fr',
            name: fd[this.props.name + '_name'] || '',
            firstname: fd[this.props.name + '_firstname'] || '',
            gender: this.props.formData[this.props.name + '_gender'],
            certificatNumber: this.props.formData[this.props.name + '_certificatNumber'],
            actionNumber: this.props.formData[this.props.name + '_actionNumber'],
            serialNumber: this.props.formData[this.props.name + '_serialNumber'],
            display: this.props.defaultDisplay === false ? false : true,
            categorie: this.props.formData[this.props.name + '_categorie'],
            certuficatCheckList: this.props.formData[this.props.name + '_certuficatCheckList'],
            declarationList: this.props.formData[this.props.name + '_declarationList'],
            Birthday: this.props.formData[this.props.name + '_Birthday'],
            certificatDate: this.props.formData[this.props.name + '_certificatDate'],
            checkEndDate: (this.props.formData[this.props.name + '_checkEndDate'] == true || this.props.formData[this.props.name + '_checkEndDate'] == 'true') ? true : false,
            data: fd
        };

        this.option = this.option.bind(this);
        this.genLang = this.genLang.bind(this);

    }

    genLang(key = 'missing') {

        if (!this.props.trad) {
            return key;
        }

        if (!this.props.trad[key]) {
            return this.props.trad['wrongKey'][this.state.lang] + ' ' + key;
        }
        return this.props.trad[key][this.state.lang];
    }

    genLangNew(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevProps) {
        let props = this.props,
            obj = {};

        if (prevProps.formData && props.formData || props.version !== prevProps.version) {

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }

            if (props.formData[props.name + '_name'] !== prevProps.formData[props.name + '_name']) {
                obj['name'] = props.formData[props.name + '_name'] || '';
            }

            if (props.formData[props.name + '_firstname'] !== prevProps.formData[props.name + '_firstname']) {
                obj['firstname'] = props.formData[props.name + '_firstname'] || '';
            }

            if (props.formData[props.name + '_assistant'] !== prevProps.formData[props.name + '_assistant']) {
                obj['assistant'] = props.formData[props.name + '_assistant'] || '';
            }

            if (props.formData[props.name + '_gender'] !== prevProps.formData[props.name + '_gender']) {
                obj['gender'] = props.formData[props.name + '_gender'] || '';
            }

            if (props.formData[props.name + '_Birthday'] !== prevProps.formData[props.name + '_Birthday']) {
                obj['Birthday'] = props.formData[props.name + '_Birthday'] || '';
            }

            if (props.formData[props.name + '_certificatDate'] !== prevProps.formData[props.name + '_certificatDate']) {
                obj['certificatDate'] = props.formData[props.name + '_certificatDate'] || '';
            }

            if (props.formData[props.name + '_certificatNumber'] !== prevProps.formData[props.name + '_certificatNumber']) {
                obj['certificatNumber'] = props.formData[props.name + '_certificatNumber'] || '';
            }

            if (props.formData[props.name + '_actionNumber'] !== prevProps.formData[props.name + '_actionNumber']) {
                obj['actionNumber'] = props.formData[props.name + '_actionNumber'] || '';
            }

            if (props.formData[props.name + '_serialNumber'] !== prevProps.formData[props.name + '_serialNumber']) {
                obj['serialNumber'] = props.formData[props.name + '_serialNumber'] || '';
            }

            if (props.formData[props.name + '_categorie'] !== prevProps.formData[props.name + '_categorie']) {
                obj['categorie'] = props.formData[props.name + '_categorie'] || '';
            }

            if (props.formData[props.name + '_certuficatCheckList'] !== prevProps.formData[props.name + '_certuficatCheckList']) {
                obj['certuficatCheckList'] = props.formData[props.name + '_certuficatCheckList'] || '';
            }

            if (props.formData[props.name + '_declarationList'] !== prevProps.formData[props.name + '_declarationList']) {
                obj['declarationList'] = props.formData[props.name + '_declarationList'] || '';
            }

            if (props.display !== prevProps.display) {

                if (props.display === false && prevProps.display === true) {
                    obj.display = false;
                } else if (props.display === true && prevProps.display === false) {
                    obj.display = true;
                } else if (prevProps.display === undefined) {
                    obj.display = props.display;
                }
            }

            if (prevProps.formData !== props.formData) {
                obj.data = this.props.formData;
            }
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }

        if (props.lang !== prevProps.lang) {

            let obj = {};

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }
            if (!_.isEmpty(obj)) {
                this.setState(obj);
            }
        }
    }

    option() {
        if (this.props.assistant) {
            return (
                <Col md={6}>
                    <FormGroup>
                        <Label>Assistant</Label>
                        <Input
                            type="text"
                            name={this.props.name + '_assistant'}
                            onChange={(e) => this.setState({ assistant: e.target.value })}
                            value={this.state.assistant || ''}
                        />
                    </FormGroup>
                </Col>
            );
        }
    }

    updateDate(e) {
        var date = moment().format('DD/MM/YYYY');
        this.setState({
            Birthday: date,
            certificatDate: date
        });
    }

    checkEnddate(e) {
        this.setState({ checkEndDate: e.target.checked });
    }

    render() {

        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        if (this.props.noCard) {
            return (
                <Col md={this.props.col || 12} style={style}>
                    {this.props.remove ? <span className="remove" style={{ zIndex: 9999 }}
                                               onClick={() => this.props.remove(this.props.name)}>
                        {cross}
                    </span> : ''}
                    {
                        <Row>
                            <Col md={12}>
                                <FormGroup tag="fieldset" className="!tw-mb-0">
                                    <legend><h5>{this.genLangNew(this.props.title, this.state.lang)}</h5></legend>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                    <Row>

                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_name')}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_name'}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    value={this.state.name}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_firstname')}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_firstname'}
                                    onChange={(e) => this.setState({ firstname: e.target.value })}
                                    value={this.state.firstname}
                                />
                            </FormGroup>
                        </Col>


                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_title')}</Label>
                                <Input
                                    type="select"
                                    name={this.props.name + '_gender'}
                                    onChange={(e) => this.setState({ gender: e.target.value })}
                                    value={this.state.gender || '-'}>
                                    <option value="-">{this.genLang('_Autre')}</option>
                                    <option value="M">{this.genLang('_sir')}</option>
                                    <option value="F">{this.genLang('_madam')}</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Label>{this.genLang('dateOfBirth')}</Label>
                            <Calendar

                                put={this.props.put}
                                remove={this.props.remove}
                                style={{ paddingLeft: '0px' }}
                                lang={this.state.lang}
                                version={this.state.version}
                                title={''}
                                key={this.props.name + '_Birthday'}
                                name={this.props.name + '_Birthday'}
                                callback={(e) => this.updateDate(e)}
                                formData={{ ...this.state.data }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={3}>
                            <Label>{this.genLang('_startdate')}</Label>
                            <Calendar
                                put={this.props.put}
                                remove={this.props.remove}
                                style={{ paddingLeft: '0px' }}
                                lang={this.state.lang}
                                version={this.state.version}
                                title={''}
                                key={this.props.name + '_startDate'}
                                name={this.props.name + '_startDate'}
                                // {...params}
                                formData={{ ...this.state.data }}
                            />
                        </Col>
                        <Col md={3} style={{ display: (this.props.isPrivate) ? 'BLOCK' : 'NONE' }}>
                            <br /><br />
                            <CheckInput
                                col={12}
                                name={this.props.name + '_checkEndDate'}
                                title={this.genLang('_enddate')}
                                formData={this.props.formData}
                                put={this.props.put}
                                callback={(e) => this.checkEnddate(e)}
                            />
                        </Col>
                        {(this.state.checkEndDate == true || this.state.checkEndDate == 'true') ?
                            <Col md={3} style={{ display: (this.props.isPrivate) ? 'BLOCK' : 'NONE' }}>
                                <Label>{this.genLang('_enddate')}</Label>
                                <Calendar
                                    put={this.props.put}
                                    remove={this.props.remove}
                                    style={{ paddingLeft: '0px' }}
                                    lang={this.state.lang}
                                    version={this.state.version}
                                    title={''}
                                    key={this.props.name + '_endDate'}
                                    name={this.props.name + '_endDate'}
                                    // {...params}
                                    formData={{ ...this.state.data }}
                                />
                            </Col> : <></>}
                    </Row>

                    <Row>

                        <Address
                            col={12}
                            put={this.props.put}
                            lang={this.state.lang}
                            trad={this.props.trad}
                            formData={{ ...this.props.formData }}
                            remove={this.removeHeirForm}
                            name={this.props.name}
                            version={this.state.version}
                            target={this.props.title}
                            country={true}
                            contact={this.props.noContact ? false : true}
                        />
                    </Row>

                    <Row>
                        <DropContainer
                            removeFileFromGCloud={this.props.removeFileFromGCloud}
                            wow="wow"
                            public={this.props.public}
                            add={this.props.add}
                            col={12}
                            name={this.props.name + '_actionnaireAttachment'}
                            title={this.genLang('_actdoclbl')}
                            formData={this.props.formData}
                            put={this.props.put}
                            ticket={this.props.ticket}
                            toggleLoading={this.props.toggleLoading}
                            target={this.props.target}
                            lang={this.state.lang}
                            version={this.state.version}
                            key={this.props.formData + '_actionnaireAttachment'}
                            // onChange={this.onChange}
                            // formData={this.state.data}
                            remove={this.props.remove}
                            pushWaitingList={this.props.pushWaitingList}
                            pullWaitingList={this.props.pullWaitingList}
                        />
                    </Row>

                    <div style={{ display: (this.props.isPrivate) ? 'BLOCK' : 'NONE' }}>

                        <Legend
                            style={
                                this.props.color ? {
                                    color: this.props.color
                                } : {}
                            }
                        >
                            {this.genLang('_certificatInfos')}
                        </Legend>

                        <Row>

                            <Col md={4}>
                                <FormGroup>
                                    <Label>{this.genLang('_certificatNumber')}</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_certificatNumber'}
                                        onChange={(e) => this.setState({ certificatNumber: e.target.value })}
                                        value={this.state.certificatNumber}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={4}>
                                <FormGroup>
                                    <Label>{this.genLang('_certificatDate')}</Label>
                                    <Calendar
                                        put={this.props.put}
                                        remove={this.props.remove}
                                        style={{ paddingLeft: '0px' }}
                                        lang={this.state.lang}
                                        version={this.state.version}
                                        title={''}
                                        key={this.props.name + '_certificatDate'}
                                        name={this.props.name + '_certificatDate'}
                                        callback={(e) => this.updateDate(e)}
                                        // {...params}
                                        formData={{ ...this.state.data }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={4}>
                                <FormGroup>
                                    <Label>{this.genLang('_actionNumber')}</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_actionNumber'}
                                        onChange={(e) => this.setState({ actionNumber: e.target.value })}
                                        value={this.state.actionNumber}
                                    />
                                </FormGroup>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>{this.genLang('_serialNumber')}</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_serialNumber'}
                                        onChange={(e) => this.setState({ serialNumber: e.target.value })}
                                        value={this.state.serialNumber}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={4}>
                                <FormGroup>
                                    <Label>{this.genLang('_categories')}</Label>
                                    <Input
                                        type="select"
                                        name={this.props.name + '_categorie'}
                                        onChange={(e) => this.setState({ categorie: e.target.value })}
                                        isMulti={true}
                                        value={this.state.categorie || '-'}>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="E">E</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>{this.genLang('_certificatList')}</Label>

                                    {certificatCheckListFR.map((data, index) => (
                                        <CheckInput
                                            col={12}
                                            name={this.props.name + '_certificatCheckList_' + index}
                                            title={data.value}
                                            formData={this.props.formData}
                                            put={this.props.put}
                                        />
                                    ))}

                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>{this.genLang('_declarationList')}</Label>
                                    {declarationFR.map((data, index) => (
                                        <CheckInput
                                            col={12}
                                            name={this.props.name + '_declarationList_' + index}
                                            title={data.value}
                                            formData={this.props.formData}
                                            put={this.props.put}
                                        />
                                    ))}

                                </FormGroup>
                            </Col>


                        </Row>

                    </div>

                </Col>
            );
        }

        return (
            <Col md={this.props.col || 12} style={style}>
                <Card
                    style={{ marginBottom: '5px' }}
                >
                    {this.props.remove
                        ? <span className="remove"
                                onClick={() => this.props.remove(this.props.name)}>
                            {cross}
                        </span>
                        : ''
                    }
                    <CardBody>
                        <CardText tag="div">
                            <Row>
                                <Col md={12}>
                                    <FormGroup tag="fieldset" className="!tw-mb-0">
                                        <legend><h5>{this.genLangNew(this.props.title, this.state.lang)}</h5></legend>
                                        {/* <legend><span>{this.props.target+ ' ' +this.props.counter}</span></legend> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_name')}</Label>
                                        <Input
                                            type="text"
                                            name={this.props.name + '_name'}
                                            onChange={(e) => this.setState({ name: e.target.value })}
                                            value={this.state.name}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_firstname')}</Label>
                                        <Input
                                            type="text"
                                            name={this.props.name + '_firstname'}
                                            onChange={(e) => this.setState({ firstname: e.target.value })}
                                            value={this.state.firstname}
                                        />
                                    </FormGroup>
                                </Col>


                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_title')}</Label>
                                        <Input
                                            type="select"
                                            name={this.props.name + '_gender'}
                                            onChange={(e) => this.setState({ gender: e.target.value })}
                                            value={this.state.gender || '-'}>
                                            <option value="-">{this.genLang('_Autre')}</option>
                                            <option value="M">{this.genLang('_sir')}</option>
                                            <option value="F">{this.genLang('_madam')}</option>
                                            {/* <option value="NA" >Autre</option> */}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Label>{this.genLang('dateOfBirth')}</Label>
                                    <Calendar

                                        put={this.props.put}
                                        remove={this.props.remove}
                                        style={{ paddingLeft: '0px' }}
                                        lang={this.state.lang}
                                        version={this.state.version}
                                        title={''}
                                        key={this.props.name + '_Birthday'}
                                        name={this.props.name + '_Birthday'}
                                        callback={(e) => this.updateDate(e)}
                                        // {...params}
                                        formData={{ ...this.state.data }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={3}>
                                    <Label>{this.genLang('_startdate')}</Label>
                                    <Calendar
                                        put={this.props.put}
                                        remove={this.props.remove}
                                        style={{ paddingLeft: '0px' }}
                                        lang={this.state.lang}
                                        version={this.state.version}
                                        title={''}
                                        key={this.props.name + '_startDate'}
                                        name={this.props.name + '_startDate'}
                                        // {...params}
                                        formData={{ ...this.state.data }}
                                    />
                                </Col>
                                <Col md={3} style={{ display: (this.props.isPrivate) ? 'BLOCK' : 'NONE' }}>
                                    <br /><br />
                                    <CheckInput
                                        col={12}
                                        name={this.props.name + '_checkEndDate'}
                                        title={this.genLang('_enddate')}
                                        formData={this.props.formData}
                                        put={this.props.put}
                                        callback={(e) => this.checkEnddate(e)}
                                    />
                                </Col>
                                {(this.state.checkEndDate == true || this.state.checkEndDate == 'true') ?
                                    <Col md={3} style={{ display: (this.props.isPrivate) ? 'BLOCK' : 'NONE' }}>
                                        <Label>{this.genLang('_enddate')}</Label>
                                        <Calendar
                                            put={this.props.put}
                                            remove={this.props.remove}
                                            style={{ paddingLeft: '0px' }}
                                            lang={this.state.lang}
                                            version={this.state.version}
                                            title={''}
                                            key={this.props.name + '_endDate'}
                                            name={this.props.name + '_endDate'}
                                            // {...params}
                                            formData={{ ...this.state.data }}
                                        />
                                    </Col> : <></>}
                            </Row>

                            <Row>

                                <Address
                                    col={12}
                                    put={this.props.put}
                                    lang={this.state.lang}
                                    trad={this.props.trad}
                                    formData={{ ...this.props.formData }}
                                    remove={this.removeHeirForm}
                                    name={this.props.name}
                                    version={this.state.version}
                                    target={this.props.title}
                                    country={true}
                                />
                            </Row>

                            <Row>
                                <DropContainer
                                    removeFileFromGCloud={this.props.removeFileFromGCloud}
                                    wow="wow"
                                    public={this.props.public}
                                    add={this.props.add}
                                    col={12}
                                    name={this.props.name + '_actionnaireAttachment'}
                                    title={this.genLang('_actdoclbl')}
                                    formData={this.props.formData}
                                    put={this.props.put}
                                    ticket={this.props.ticket}
                                    toggleLoading={this.props.toggleLoading}
                                    target={this.props.target}
                                    lang={this.state.lang}
                                    version={this.state.version}
                                    key={this.props.formData + '_actionnaireAttachment'}
                                    // onChange={this.onChange}
                                    // formData={this.state.data}
                                    remove={this.props.remove}
                                    // removeFileFromGCloud={this.props.removeFileFromGCloud}

                                    pushWaitingList={this.props.pushWaitingList}
                                    pullWaitingList={this.props.pullWaitingList}
                                />
                            </Row>

                            <div style={{ display: (this.props.isPrivate) ? 'BLOCK' : 'NONE' }}>

                                <Legend
                                    style={
                                        this.props.color ? {
                                            color: this.props.color
                                        } : {}
                                    }
                                >
                                    {this.genLang('_certificatInfos')}
                                </Legend>

                                <Row>

                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{this.genLang('_certificatNumber')}</Label>
                                            <Input
                                                type="text"
                                                name={this.props.name + '_certificatNumber'}
                                                onChange={(e) => this.setState({ certificatNumber: e.target.value })}
                                                value={this.state.certificatNumber}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{this.genLang('_certificatDate')}</Label>
                                            <Calendar
                                                put={this.props.put}
                                                remove={this.props.remove}
                                                style={{ paddingLeft: '0px' }}
                                                lang={this.state.lang}
                                                version={this.state.version}
                                                title={''}
                                                key={this.props.name + '_certificatDate'}
                                                name={this.props.name + '_certificatDate'}
                                                callback={(e) => this.updateDate(e)}
                                                // {...params}
                                                formData={{ ...this.state.data }}
                                            />
                                        </FormGroup>
                                    </Col>


                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{this.genLang('_actionNumber')}</Label>
                                            <Input
                                                type="text"
                                                name={this.props.name + '_actionNumber'}
                                                onChange={(e) => this.setState({ actionNumber: e.target.value })}
                                                value={this.state.actionNumber}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{this.genLang('_serialNumber')}</Label>
                                            <Input
                                                type="text"
                                                name={this.props.name + '_serialNumber'}
                                                onChange={(e) => this.setState({ serialNumber: e.target.value })}
                                                value={this.state.serialNumber}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{this.genLang('_categories')}</Label>
                                            <Input
                                                type="select"
                                                name={this.props.name + '_categorie'}
                                                onChange={(e) => this.setState({ categorie: e.target.value })}
                                                value={this.state.categorie || '-'}>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                                <option value="D">D</option>
                                                <option value="E">E</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>{this.genLang('_certificatList')}</Label>

                                            {certificatCheckListFR.map((data, index) => (
                                                <CheckInput
                                                    col={12}
                                                    name={this.props.name + '_certificatCheckList_' + index}
                                                    title={data.value}
                                                    formData={this.props.formData}
                                                    put={this.props.put}
                                                />
                                            ))}

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>{this.genLang('_declarationList')}</Label>
                                            {declarationFR.map((data, index) => (
                                                <CheckInput
                                                    col={12}
                                                    name={this.props.name + '_declarationList_' + index}
                                                    title={data.value}
                                                    formData={this.props.formData}
                                                    put={this.props.put}
                                                />
                                            ))}

                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}
