import React from 'react';
import { Button, Col, FormGroup, Label } from 'reactstrap';
import { RequestThatField } from './RequestThatField';
import { UserChildInfo } from './UserChildInfo';
import _ from 'lodash';
import moment from 'moment';

export function genLang(text, lang = 'fr') {
    if (typeof text === 'string') {
        return text;
    }
    if (typeof text === 'object') {
        if (lang && text[lang]) {
            return text[lang];
        } else {
            console.error('lang missing');
            return '';
        }
    }
    return '';
}

function buildFormDataArray(data, key, filter) {
    const regex = new RegExp(`^${key}_(\\d+)`);
    const set = new Set([]);
    const indexes = {};
    let highest = 0;
    Object.keys(data)
        .forEach(k => {
            const match = k.match(regex);
            if (match) {
                const key = match[0];
                const index = Number(match[1]);
                set.add(match[0]);
                indexes[key] = index;
                if (index > highest) {
                    highest = index;
                }
            }
        });

    let arr = Array.from(set);
    if (filter) {
        arr = arr.filter((key) => filter(data, key));
    }
    const obj = arr.reduce((obj, v) => ({ ...obj, [v]: indexes[v] }), {});
    return {
        counter: highest,
        data: obj
    };
}

function filterAdultChildren(data, key) {
    const isAdult = moment(data[`${key}_dateOfBirth`], 'DD/MM/YYYY')
        .isBefore(moment().subtract(18, 'years'));
    const isAutonomous = data[`${key}_autonomous`] === 'true';
    const isWorking = data[`${key}_working`] === 'true';
    const isGraduated = data[`${key}_graduated`] === 'true';
    return isAdult && !isAutonomous && !isWorking && !isGraduated;
}

export class UsersChildrenInfoDivorce extends React.Component {

    constructor(props) {
        super(props);
        const childrenInfo = buildFormDataArray(props.formData, props.name);
        const childrenList = buildFormDataArray(
            props.formData,
            props.childrenListTarget || '',
            filterAdultChildren
        );
        this.state = {
            childrenInfo,
            childrenList
        };
        this.addChildInfo = this.addChildInfo.bind(this);
        this.removeChildInfo = this.removeChildInfo.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let data = buildFormDataArray(
            this.props.formData,
            this.props.childrenListTarget || '',
            filterAdultChildren
        );
        if (!_.isEqual(data, this.state.childrenList)) {
            this.setState({
                childrenList: data
            });
        }
    }

    addChildInfo() {
        const counter = this.state.childrenInfo.counter + 1;
        this.setState({
            childrenInfo: {
                counter,
                data: {
                    ...this.state.childrenInfo.data,
                    [`${this.props.name}_${counter}`]: counter
                }
            }
        });
    }

    removeChildInfo(index) {
        const entries = Object.entries(this.state.childrenInfo.data)
            .filter(([_, v]) => v !== index);
        const data = entries
            .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {});
        if (this.props.remove) {
            this.props.remove({ params: { key: `${this.props.name}_${index}` } })
        }
        this.setState({
            childrenInfo: {
                counter: entries.length,
                data
            }
        });
    }

    render() {
        let request;
        if (this.props.requestFields === false) {
            request = !!(this.props.requestable === true && window.location.host.match('localhost'));
        } else {
            request = this.props.requestable !== false;
        }
        if (this.props.display === false) {
            return null;
        }
        return (
            <Col md={this.props.col || 12}>
                <FormGroup>
                    {Object.entries(this.state.childrenInfo.data).map(([key, index]) => {
                        return (<UserChildInfo
                            key={key}
                            {...this.props}
                            name={key}
                            removeChild={() => this.removeChildInfo(index)}
                            childrenList={this.state.childrenList}
                        />);
                    })}
                </FormGroup>
                <FormGroup className="buttonHolder">
                    <Button
                        color="primary"
                        block
                        outline
                        onClick={() => this.addChildInfo()}
                    >
                        Ajouter
                    </Button>
                </FormGroup>
            </Col>
        );
    }

}
