import React from 'react';
import { Row } from '@/components/ui/row';
import {
    NeoFormComponent,
    type NeoFormComponentProps
} from '@/components/neoform/NeoFormComponent';
import { combine } from '@/composables/translation';

export function ParentalTime(props: NeoFormComponentProps) {
    return (
        <>
            <Row>
                <NeoFormComponent
                    name="shareTime"
                    componentName="InputTextArea"
                    title={combine('neoform.parental-time.share-time')}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="askCourt"
                    componentName="InputTextArea"
                    title={combine('neoform.parental-time.ask-court')}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="shareDescription"
                    componentName="InputContentEditableTiny"
                    title={combine('neoform.parental-time.share-description')}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="otherPoints"
                    componentName="InputContentEditableTiny"
                    title={combine('neoform.parental-time.other-points')}
                    last
                />
            </Row>
        </>
    );
}
