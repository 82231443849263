import React, { useState } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useTranslation } from '@/composables/translation';
import { useValidation } from '@/composables/validation';
import { toast } from 'react-toastify';
import { Input } from '@/components/ui/input';
import { type Note, type PostAddNoteDto } from '@/types/api/note';
import { useUserStore } from '@/store/user';
import { useError } from '@/composables/error';
import { postAddNote } from '@/composables/api';

interface Props {
    // case: CaseType | CaseInfo;
    case_id: string;
    case_name: string;
    onUpdated?: (name?: string, note?: Note) => void;
}

export function EditFolderDialog(props: Props) {
    const { ct, t } = useTranslation('psj');
    const { lang } = useUserStore((state) => ({ lang: state.lang }));
    const form = useForm<{ new_name: string }>({
        defaultValues: { new_name: props.case_name }
    });
    const { required } = useValidation();
    const { handleNetworkError } = useError();

    const [open, setOpen] = useState(false);

    function handleSubmit({ new_name }: { new_name: string }) {
        const newCase = {
            _id: props.case_id ?? '',
            name: new_name
        };
        const obj: PostAddNoteDto = {
            type: 'update',
            is_public: false,
            lang,
            active: true,
            origin: { source: 'neodesk' },
            case: newCase,
            note: `${t('actions.edit.case-name-updated')}: ${new_name}`
        };
        return postAddNote(obj)
            .then((res) => {
                toast(ct('messages.success'), { type: 'success' });
                setOpen(false);
                if (props.onUpdated) props.onUpdated(new_name, res.data.note);
            })
            .catch(handleNetworkError);
    }

    function onOpenChange(open: boolean) {
        if (!open) {
            form.reset({ new_name: props.case_name });
        }
        setOpen(open);
    }

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <Tooltip>
                <TooltipTrigger asChild>
                    <DialogTrigger asChild>
                        <Button
                            type="button"
                            variant="ghost" size="icon"
                            className="tw-text-muted-foreground"
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </DialogTrigger>
                </TooltipTrigger>
                <TooltipContent>
                    {t('actions.edit.title')}
                </TooltipContent>
            </Tooltip>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {t('actions.edit.title')}
                    </DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)}>
                        <div className="tw-flex tw-flex-col tw-gap-3 tw-pb-6">
                            <FormField
                                name="new_name"
                                render={({ field }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {ct('title')}
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                                rules={{ validate: { required } }}
                            />
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button type="button" variant="secondary">
                                    {ct('cancel')}
                                </Button>
                            </DialogClose>
                            <ButtonSubmit>
                                {ct('save')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
