import React, { Component } from 'react';

import { Button, FormGroup, Input, Label, } from 'reactstrap';

import _ from 'lodash';
import axios from 'axios';

const DEFAULT_STYLE = {
    textAlign: 'left',
    resize: 'vertical !important'
};

const DEFAULT_ROW = 3;
const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

const PATH = import.meta.env.VITE_API_URL;
const PATHX = PATH;

export class NotesInput extends Component {

    constructor(props) {
        super(props);

        let fd = this.props.formData;

        this.state = {
            disabled: this.props.disabled,
            value: fd && fd[this.props.name] ? fd[this.props.name] : '',
            lang: this.props.lang || FR,
            row: DEFAULT_ROW,
            display: this.props.defaultDisplay === false ? false : true,
            isListDdisplayed: true,
            isNewNoteActive: false,
            noteId: 0,
            status: 1,
            internalNote: '',
        };

        if (!props.name) {
            console.error('!!! name is missing !!!');
        }
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.display !== prevprops.display) {

            if (props.display === false && prevprops.display === true) {
                obj.display = false;
            } else if (props.display === true && prevprops.display === false) {
                obj.display = true;
            } else if (prevprops.display === undefined) {
                obj.display = props.display;
            }
        }

        if (props.disabled !== prevprops.disabled) {
            obj['disabled'] = props.disabled;
        }

        if (props.forcedValue !== prevprops.forcedValue) {
            obj['value'] = props.forcedValue;
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    handleOpenDisplay = () => {
        this.setState({ ['isListDdisplayed']: true, });
    };

    handleCloseDisplay = () => {
        this.setState({ ['isListDdisplayed']: false, });
    };

    handleOpenNewNote = (event) => {
        const noteId = this.state.noteId;
        if (noteId > 0) {
            this.setState({ ['status']: 1, });
            //handleNoteFocusout(event);
            setTimeout(() => {
                this.handleNoteFocusout(event);
            }, 600);
        }
        this.setState({ ['isNewNoteActive']: true, });
    };

    handleCloseNewNote = (event) => {
        const noteId = this.state.noteId;
        if (noteId > 0) {
            this.setState({ ['status']: 0, });
            setTimeout(() => {
                this.handleNoteFocusout(event);
            }, 600);
        }
        this.setState({ ['isNewNoteActive']: false, });
    };

    handleNoteChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleNoteFocusout = (event) => {
        const id = this.state.noteId;
        const note = this.state.value;
        const ticket = this.props.allFieldsNotesInfo.ticket;
        const nameField = this.props.name;
        let status = this.state.status;

        const form_name0 = this.props.allFieldsNotesInfo.form_name;
        const form_name2 = this.props.allFieldsNotesInfo.form_nameback;
        let form_name = '';

        if (form_name0 !== null && form_name0 !== '') {
            form_name = form_name0;
        } else if (form_name2 !== null && form_name2 !== '') {
            form_name = form_name2;
        }

        if (this.props.public) {
            console.log('we will not save, no access to public form');
            return false;
        }
        if (ticket === undefined || ticket === null || ticket === '') {
            return false;
        }
        if (form_name === undefined || form_name === null || form_name === '') {
            return false;
        }
        if (nameField === undefined || nameField === null || nameField === '') {
            return false;
        }

        const noteObj = {
            id: id,
            ticket: ticket,
            form_name: form_name,
            field_name: nameField,
            status: status,
            data_raw: note,
        };

        if (id > 0) {
        } else if (id === 0 && note.length === 0) {
            return false;
        }

        this.creatOrUpdateNote(noteObj);
    };

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    alertClose() {
        this.setState({ addSuccess: false });
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        const isNoteDefined = this.props.note;
        if (isNoteDefined === true) {
        } else {
            return null;
        }

        let notesList = this.props.allFieldsNotes;

        const form_name0 = this.props.allFieldsNotesInfo.form_name;
        const form_name2 = this.props.allFieldsNotesInfo.form_nameback;
        const ticket = this.props.allFieldsNotesInfo.ticket;
        const nameField = this.props.name;

        let form_name = '';

        if (form_name0 !== null && form_name0 !== '') {
            form_name = form_name0;
        } else if (form_name2 !== null && form_name2 !== '') {
            form_name = form_name2;
        }

        if (this.props.public) {
            console.log('no access to public form');
            return null;
        }
        if (ticket === undefined || ticket === null || ticket === '') {
            console.error('ticket vide et amlie poulin ');
            return null;
        }
        if (form_name === undefined || form_name === null || form_name === '') {
            console.error('form name vide');
            return null;
        }
        if (notesList.length > 0) {
            notesList = notesList.filter(note => (note.ticket === ticket && note.form_name === form_name));
        }

        let countQty = 0;
        let allNotes = notesList.length > 0 && notesList.map((item, i) => {
            if (item.field_name !== nameField) {
                return null;
            }
            countQty++;
            const pvcomment = ' pvcomment';
            const author = <span className="btnuser"><i className="fas fa-user"></i> {item.author}</span>;
            const created = item.creation_date;
            let descBody = item.data_raw;
            return (<div className="zendcomment" key={'cmt' + i}>
                <div className={'tcomment' + pvcomment} style={{ backgroundColor: item.color }}>
                    <div dangerouslySetInnerHTML={{ __html: descBody }}></div>
                </div>
                <div className="usernote-view">
                    <small><i>{author}</i> &nbsp; &nbsp;  {created}</small>
                </div>

            </div>);

            return null;

        }, this);

        if (countQty === 0) {
            const descBody = 'Pas de Notes/ No notes';
            allNotes = (<div className="zendcommentQQQ" key={'cmt00000'}>
                <div className={'tcomment'}>
                    <div dangerouslySetInnerHTML={{ __html: descBody }}></div>
                </div>
            </div>);
        }

        const isListDdisplayed = this.state.isListDdisplayed;
        const isNewNoteActive = this.state.isNewNoteActive;
        const noteId = this.state.noteId;

        const notes = {
            fr: 'Notes',
            en: 'Notes'
        };

        const addObj = {
            fr: 'Ajout',
            en: 'Add'
        };
        const delObj = {
            fr: 'Annuler',
            en: 'Cancel'
        };
        const listeObj = {
            fr: 'Liste',
            en: 'List'
        };
        const notesin = {
            fr: 'Notes Internes',
            en: 'Internal Notes'
        };
        const txtNotesin = this.genLang(notesin, this.state.lang);
        let countQtyNote = countQty;
        if (noteId > 0) {
            countQtyNote = countQty + 1;
        }
        const noteBadge = (countQtyNote > 0) ? <span className="badge badge-danger navbar-badge">{countQtyNote}</span> :
            <span />;

        return (<div>
            <div className="neoHLMenu">
                <ul>
                    {/* <li className="neoHLli neoHLli0">{txtNotes}</li> */}
                    <li className="neoHLli">{isNewNoteActive === true ?
                        <Button type="button" color="primary" onClick={this.handleCloseNewNote}> <i
                            className="fas fa-minus btn-block"></i></Button> :
                        <Button type="button" color="primary" onClick={this.handleOpenNewNote}> <i
                            className="fas fa-plus btn-block"></i></Button>}</li>
                    <li className="neoHLli">{isListDdisplayed === true ?
                        <Button type="button" color="info" onClick={this.handleCloseDisplay}> <i
                            className="fas fa-list btn-block"></i> </Button> :
                        <Button type="button" color="light" onClick={this.handleOpenDisplay}> <i
                            className="fas fa-list btn-block"></i></Button>}
                        <span className="p-l-10">{noteBadge}</span></li>

                    {/* <li><a href="#news">News <span class="badge badge-danger navbar-badge">3</span></a></li> */}
                </ul>
            </div>

            <FormGroup>
                {isListDdisplayed === true ? <div className="tw-mt-3 card">
                    {/* <span> isListDdisplayed</span> */}
                    <div className="card-body">
                        {allNotes}
                    </div>
                </div> : ''}
            </FormGroup>
            {isNewNoteActive === true ? <FormGroup>
                {/* <Label><h5>{this.genLang(props.title||DEFAULT_TITLE,this.state.lang)}</h5></Label> */}
                <Label><h5>{txtNotesin}</h5></Label>
                <Input
                    // bsSize={'lg'}
                    className={'neotextarea rich_textTA'}
                    rows={this.state.row}

                    tabIndex={props.tabindex}
                    value={this.state.value || ''}
                    disabled={this.state.disabled}
                    type={'textarea'}
                    name="internalNote"
                    //onChange={this.handleNoteChange}
                    onChange={(e) => {
                        let row;
                        row = e.target.value.split('\n');

                        if (!row.length || row < DEFAULT_ROW) {
                            row = DEFAULT_ROW;
                        } else {
                            row = row.length;
                        }

                        this.setState({
                            value: e.target.value,
                            row: row
                        }, () => {
                            // if(this.props.usePut&& this.props.put){

                            //     this.props.put({
                            //         params:{
                            //             key:this.props.name,
                            //             value:this.state.value
                            //         }
                            //     })
                            // }
                        });
                    }}
                    onBlur={this.handleNoteFocusout}
                    // name={props.name}
                />

                {(this.state.addSuccess) ?
                    <div className="m-t-5 alert alert-info">La note est ajouté avec succès!
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                                onClick={() => this.alertClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div> : <></>}

            </FormGroup> : ''}
            <br />
        </div>);
    }

    creatOrUpdateNote = (objNotex) => {
        const noteId = objNotex.id;
        const objNote = {
            AUTH_ID: localStorage.getItem('user_id'),
            data: objNotex
        };

        if (this.props.public && this.props.token) {
        } else {
            axios
                .post(PATHX + '/desk/notesfields', objNote, {
                    auth: {
                        username: 'admin',
                        password: '6d21b4833368d61c31275092886a4d9add8f7801abd7892d017b3f19a37b30c8'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'x-jwt-token': localStorage.getItem('token')
                    }
                })
                .then((e) => {
                    const result = e.data.result;
                    this.setState({ addSuccess: result.success });

                    const neoId = parseInt(result.id);
                    if (noteId === 0 && neoId > 0) {
                        this.setState({ ['noteId']: neoId });
                    }

                }, () => {
                    this.setState({ alert: 'INCAPABLE AJOUETR OU ACTUALISÉ les notes' });
                });
        }

    };

}
