import React, { useState } from 'react';
import { NeoFormComponent } from '@/components/neoform/NeoFormComponent';
import { useNeoForm } from '@/composables/neoform';
import { combine, useTranslation } from '@/composables/translation';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Row, Col } from '@/components/ui/row';

const IMPLICATED_OPTIONS = [
    {
        label: combine('neoform.user-endos.implicated-0'),
        value: 0,
        data: {
            name: '',
            juridicalPerson: false,
            user: {
                name: '',
                firstname: '',
                gender: '',
                address: {
                    street: '',
                    city: '',
                    state: '',
                    postal: '',
                    country: '',
                    phone: ''
                }
            },
            district: '',
            preposition: 0,
            business: false,
            neoclient: false,
            receiptCopy: false,
            job: '',
            employer: '',
            revenue: '',
            index: undefined
        }
    },
    {
        label: combine('neoform.user-endos.implicated-1'),
        value: 1,
        data: {
            name: '',
            juridicalPerson: false,
            user: {
                name: 'PROCUREURE GÉNÉRALE DU QUÉBEC',
                gender: 'F',
                address: {
                    street: '1200, route de l\'Église, 2e étage',
                    city: 'Québec',
                    state: 'Québec',
                    postal: 'G1V 4M1',
                    country: 'Canada',
                    phone: ''
                }
            },
            district: 'Québec',
            preposition: 0,
            business: true,
            neoclient: false,
            receiptCopy: false,
            job: '',
            employer: '',
            revenue: '',
            index: undefined
        }
    },
    {
        label: combine('neoform.user-endos.implicated-2'),
        value: 2,
        data: {
            name: '',
            juridicalPerson: false,
            user: {
                name: 'DIRECTEUR DE L\'ÉTAT CIVIL',
                gender: 'M',
                address: {
                    street: '2050, rue De Bleury',
                    city: 'Montréal',
                    state: 'Québec',
                    postal: 'H3A 2J5',
                    country: 'Canada',
                    phone: ''
                }
            },
            district: 'Montréal',
            preposition: 0,
            business: true,
            neoclient: false,
            receiptCopy: false,
            job: '',
            employer: '',
            revenue: '',
            index: undefined
        }
    },
    {
        label: combine('neoform.user-endos.implicated-3'),
        value: 3,
        data: {
            name: '',
            juridicalPerson: false,
            user: {
                name: 'REVENU QUÉBEC',
                gender: 'M',
                address: {
                    street: 'Complexe Desjardins, Tour Nord, basilaire 1, 150 Saint-Catherine O',
                    city: 'Montréal',
                    state: 'Québec',
                    postal: 'H2X 3Y2',
                    country: 'Canada',
                    phone: ''
                }
            },
            preposition: 0,
            clientType: 4,
            district: 'Montréal',
            business: true,
            neoclient: false,
            receiptCopy: false,
            job: '',
            employer: '',
            revenue: '',
            index: undefined
        }
    }
];

export function UserEndos() {
    const {
        form,
        id,
        getChildFieldName
    } = useNeoForm();
    const { t, to } = useTranslation('neoform.user-endos');
    const juridicalPerson = form?.watch<boolean>(getChildFieldName('juridicalPerson')) ?? false;
    const [misEnCause, setMisEnCause] = useState(0);
    const isReadOnly = misEnCause !== 0;
    const onChangeSelect = (value: number) => {
        setMisEnCause(value);
        const option = IMPLICATED_OPTIONS.find(o => o.value === value);
        if (option) {
            form?.set(id, option.data);
        }
    };
    return (
        <>
            <Row>
                <Col>
                    <Label>{t('implicated')}</Label>
                    <Select
                        value={String(misEnCause)}
                        onValueChange={(value) => onChangeSelect(Number(value))}
                    >
                        <SelectTrigger>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {IMPLICATED_OPTIONS.map(o =>
                                    <SelectItem key={o.value} value={String(o.value)}>
                                        {to(o.label)}
                                    </SelectItem>
                                )}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </Col>
            </Row>
            <Row>
                <NeoFormComponent
                    name="juridicalPerson"
                    componentName="InputCheckbox"
                    title={combine('neoform.user-endos.juridical-person')}
                    readonly={isReadOnly}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="name"
                    componentName="InputText"
                    title={combine('neoform.user-endos.juridical-person-name')}
                    titleType={NeoFormTitleType.LABEL}
                    readonly={isReadOnly}
                    display={juridicalPerson}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="user"
                    componentName="User"
                    noRelation
                    address
                    readonly={isReadOnly}
                    display={!juridicalPerson}
                    last
                />
            </Row>
            <Row>
                <NeoFormComponent
                    col={6}
                    name="preposition"
                    componentName="InputSelect"
                    title={combine('neoform.user-endos.preposition')}
                    titleType={NeoFormTitleType.LABEL}
                    readonly={isReadOnly}
                    default={0}
                    options={[
                        {
                            value: 0,
                            label: combine('neoform.user-endos.preposition-0')
                        },
                        {
                            value: 1,
                            label: combine('neoform.user-endos.preposition-1')
                        }
                    ]}
                />
                <NeoFormComponent
                    col={6}
                    name="clientType"
                    componentName="InputSelect"
                    title={combine('neoform.user-endos.client-type')}
                    titleType={NeoFormTitleType.LABEL}
                    readonly={isReadOnly}
                    default={0}
                    options={[
                        {
                            value: 0,
                            label: combine('neoform.user-endos.client-type-0')
                        },
                        {
                            value: 1,
                            label: combine('neoform.user-endos.client-type-1')
                        },
                        {
                            value: 2,
                            label: combine('neoform.user-endos.client-type-2')
                        },
                        {
                            value: 3,
                            label: combine('neoform.user-endos.client-type-3')
                        },
                        {
                            value: 4,
                            label: combine('neoform.user-endos.client-type-4')
                        }
                    ]}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    col={3}
                    name="district"
                    componentName="InputText"
                    title={combine('neoform.user-endos.district')}
                    titleType={NeoFormTitleType.LABEL}
                    readonly={isReadOnly}
                />
                <NeoFormComponent
                    col={3}
                    name="business"
                    componentName="InputCheckbox"
                    title={combine('neoform.user-endos.business')}
                    readonly={isReadOnly}
                />
                <NeoFormComponent
                    col={3}
                    name="neoClient"
                    componentName="InputCheckbox"
                    title={combine('neoform.user-endos.neoclient')}
                    readonly={isReadOnly}
                />
                <NeoFormComponent
                    col={3}
                    name="receiptCopy"
                    componentName="InputCheckbox"
                    title={combine('neoform.user-endos.receipt-copy')}
                    readonly={isReadOnly}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    col={4}
                    name="job"
                    componentName="InputText"
                    title={combine('neoform.user-endos.job')}
                    titleType={NeoFormTitleType.LABEL}
                    readonly={isReadOnly}
                />
                <NeoFormComponent
                    col={4}
                    name="employer"
                    componentName="InputText"
                    title={combine('neoform.user-endos.employer')}
                    titleType={NeoFormTitleType.LABEL}
                    readonly={isReadOnly}
                />
                <NeoFormComponent
                    col={4}
                    name="revenue"
                    componentName="InputNumber"
                    title={combine('neoform.user-endos.revenue')}
                    titleType={NeoFormTitleType.LABEL}
                    readonly={isReadOnly}
                    amount
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="index"
                    componentName="InputSelect"
                    col={4}
                    title={combine('neoform.user-endos.order')}
                    titleType={NeoFormTitleType.LABEL}
                    readonly={isReadOnly}
                    options={[...Array(6)].map((_, i) => ({
                        value: i + 1,
                        label: {
                            en: String(i + 1),
                            fr: String(i + 1)
                        }
                    }))}
                    last
                />
            </Row>
        </>
    );
}
