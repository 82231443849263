import React, { Component } from 'react';

import { Col, FormGroup, Input, Label, Toast, ToastBody } from 'reactstrap';

import _ from 'lodash';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const TRUE = 'true';

const FALSE = 'false';

const DEFAULT_STYLE = { textAlign: 'left' };

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class CheckInputColor extends Component {

    constructor(props) {
        super(props);

        let fd = this.props.formData;

        let checked;

        if (fd && fd[this.props.name]) {
            checked = fd[this.props.name];
        } else if (this.props.checked) {
            checked = TRUE;
        } else {
            checked = FALSE;
        }

        this.props.put(
            {
                params: {
                    key: encodeURI(this.props.name),
                    value: checked
                }
            }
        );

        this.state = {
            value: checked,
            display: this.props.defaultDisplay === false ? false : true
        };

        // debugger;
        // if(!props.onChange)
        //     console.error('!!! onChange is missing !!!');

        if (!props.name) {
            console.error('!!! name is missing !!!');
        }

        if (!props.title) {
            console.error('!!! title is missing !!!');
        }
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            obj['value'] = props.formData[props.name] || props.default;
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            obj['value'] = props.formData[props.name];
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }
        // console.log(this.props.formData)
        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        return (
            <>
                <div className={'p-3 bg-' + (props.color || 'success') + ' my-2 rounded'}>
                    <Toast className={'mw-100'}>
                        <ToastBody>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        tabIndex={props.tabindex}
                                        checked={this.state.value === TRUE}
                                        type="checkbox"
                                        name={props.name}
                                        {...this.props.required ? { required: true } : {}}
                                        onChange={(e) => {
                                            let checked = e.target.checked.toString();
                                            this.setState({ value: checked });

                                            this.props.put(
                                                {
                                                    params: {
                                                        key: encodeURI(this.props.name),
                                                        value: checked
                                                    }
                                                }
                                            );

                                            if (this.props.callback) {
                                                this.props.callback({ ...e, ...props });
                                            }
                                        }
                                        }

                                    />{' '}
                                    {this.genLang(props.title || DEFAULT_TITLE, this.state.lang)}
                                </Label>
                            </FormGroup>
                        </ToastBody>
                    </Toast>
                </div>
            </>
        );
    }

}
