import { type Party } from '@/types/api/party';
import _ from 'lodash';

interface Options {
    include_company?: boolean;
    include_phone?: boolean;
}

export function getPartyName(party: Party, opts?: Options) {
    opts = _.defaults(opts, { include_phone: false, include_company: true });
    let isCompanyUsed = false;
    let name = '';
    if (party.firstname) {
        name += `${party.firstname}`;
        if (party.lastname) {
            name += ` ${party.lastname}`;
        }
    } else if (party.fullname) {
        name += party.fullname;
    } else if (party.company) {
        name += party.company;
        isCompanyUsed = true;
    }
    if (opts?.include_phone && party.phone) {
        name += ` — ${party.phone}`;
    }
    if (opts.include_company && !isCompanyUsed && party.company) {
        name += ` (${party.company})`;
    }
    return name;
}
