import { type DateTime } from 'luxon';
import React from 'react';
import { cn } from '@/lib/utils';

interface Props {
    date: DateTime;
    selected?: boolean;
    className?: string;
}

export function Header({ date, selected, className }: Props) {
    return (
        <div
            className={cn(
                'tw-text-center tw-select-none',
                'tw-bg-white tw-border-b tw-border-black/30 tw-z-10',
                className
            )}
        >
            <div className="tw-text-base md:tw-text-lg">
                {date.toFormat('EEE')}
            </div>
            <h1 className={cn(
                'tw-text-2xl xl:tw-text-3xl',
                { 'tw-text-blue-500': selected }
            )}>
                {date.toFormat('d')}
            </h1>
        </div>
    );
}
