import React, { Component } from 'react';
import _ from 'lodash';

import { Col, FormGroup, Input, Label } from 'reactstrap';
import { NotesInput } from './NotesInput';
import { RequestThatField } from './RequestThatField';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const DEFAULT_TYPE = 'text';
const DEFAULT_STYLE = { textAlign: 'left' };

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class TextInput extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData;

        // debugger;
        this.state = {
            value:
                fd && fd[props.forcedName || this.props.name]
                    ? fd[props.forcedName || this.props.name]
                    : '',
            lang: this.props.lang || FR,
            placeholder: this.props.placeholder
                ? this.genLang(props.placeholder, props.lang)
                : '',
            display: this.props.defaultDisplay === false ? false : true,
        };

        // if(!props.onChange)
        //     console.error('!!! onChange is missing !!!');

        if (!props.name) console.error('!!! name is missing !!!');
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            obj['value'] =
                props.formData[props.forcedName || props.name] || props.default;
            this.props.put({
                params: {
                    key: props.forcedName || this.props.name,
                    value:
                        props.formData[props.forcedName || props.name] || props.default,
                    title: this.props.title,
                },
            });
        }

        if (
            props.formData[props.forcedName || props.name] !==
            prevprops.formData[props.forcedName || props.name]
        ) {
            obj['value'] = props.formData[props.forcedName || props.name];
        }

        if (props.placeholder !== prevprops.placeholder) {
            obj['placeholder'] = props.placeholder;
        }

        if (props.lang !== prevprops.lang) obj['lang'] = props.lang;

        // if(props.display===false&& prevprops.display!==false){
        //     obj.display = false;
        // }
        // else if(props.display===true&& prevprops.display!==true){
        //     obj.display = true;
        // }
        if (props.display !== prevprops.display) {
            if (props.display === false && prevprops.display === true) {
                obj.display = false;
            } else if (props.display === true && prevprops.display === false) {
                obj.display = true;
            } else if (prevprops.display === undefined) {
                obj.display = props.display;
            }
        }

        if (!_.isEmpty(obj)) this.setState(obj);
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;

        if (this.props.requestFields === false) {
            if (
                this.props.requestable === true &&
                window.location.host.match('localhost')
            ) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        if (this.props.noFormGroup === true) {
            return (
                <Col md={props.col || DEFAULT_SIZE} style={style}>
                    <Input
                        // bsSize={'lg'}
                        tabIndex={props.tabindex}
                        value={this.state.value || ''}
                        placeholder={this.genLang(this.state.placeholder)}
                        type={props.type || DEFAULT_TYPE}
                        name={props.forcedName || props.name}
                        onChange={(e) => {
                            this.setState(
                                {
                                    value: e.target.value,
                                },
                                () => {
                                    if (this.props.usePut && this.props.put) {
                                        this.props.put({
                                            params: {
                                                key: props.forcedName || this.props.name,
                                                value: this.state.value,
                                                title: this.props.title,
                                            },
                                        });
                                    }

                                    if (this.props.callback) {
                                        this.props.callback({
                                            value: this.state.value,
                                            name: props.forcedName || this.props.name,
                                        });
                                    }
                                }
                            );
                            // props.onChange?
                            //     props.onChange({key:props.name,value:e.target.value}):
                            //     console.error('Jay, onChange is not there, don\'t even try!')
                        }}
                    />
                </Col>
            );
        }
        return (
            <>
                <FormGroup>
                    <NotesInput
                        {...props}
                        onChange={props.onChange}
                        formData={props.data}
                    />
                    <Input
                        tabIndex={props.tabindex}
                        value={this.state.value || ''}
                        placeholder={this.genLang(this.props.placeholder) || ''}
                        type={props.type || DEFAULT_TYPE}
                        name={props.forcedName || props.name}
                        onChange={(e) => {
                            this.setState(
                                {
                                    value: e.target.value,
                                },
                                () => {
                                    if (this.props.usePut && this.props.put) {
                                        this.props.put({
                                            params: {
                                                key: props.forcedName || this.props.name,
                                                value: this.state.value,
                                                title: this.props.title,
                                            },
                                        });
                                    }

                                    if (this.props.callback) {
                                        this.props.callback({
                                            value: this.state.value,
                                            name: props.forcedName || this.props.name,
                                            title: this.props.title,
                                        });
                                    }
                                }
                            );
                        }}
                    />
                </FormGroup>
            </>
        );
    }
}
