export const SET_COSTUMER = 'customers:set';
export const SET_CONFLICT = 'conflict:set';

export default function customersReducer(state = {}, {
    type,
    params
}) {
    if (type === SET_COSTUMER) {
        return { ...params };
    } else if (type === SET_CONFLICT) {
        return {
            ...params,
            conflict: params.conflict
        };
    }

    return state;
}
