import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button, type ButtonProps } from '@/components/ui/button';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import useHtml from '@/composables/html';

interface Props extends ButtonProps {
    text: string;
}

export function InfoButton({ text, ...props }: Props) {
    const sanitizedText = useHtml(text);
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant="ghost"
                    {...props}
                    className={cn(
                        'tw-text-primary !tw-p-0 tw-size-9',
                        'hover:tw-text-primary',
                        props.className
                    )}
                    onClick={(e) => e.stopPropagation()}
                >
                    <InfoCircledIcon
                        className="tw-relative"
                        width="22" height="22"
                    />
                </Button>
            </PopoverTrigger>
            <PopoverContent side="right" align="center">
                <div
                    className="tw-prose-sm"
                    dangerouslySetInnerHTML={{ __html: sanitizedText }}
                />
            </PopoverContent>
        </Popover>
    );
}
