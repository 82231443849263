import React, { Component } from 'react';
import { Alert } from 'reactstrap';

export class AlertNotification extends Component {
    constructor(props) {
        super(props);

        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
            this.setState({ visible: this.props.visible });
        }
    }

    onDismiss() {
        this.props.setParent({ alert: '' });
    }

    render() {
        return (
            <Alert
                style={{ width: this.props.width ? this.props.width : 'auto' }}
                color={this.props.color || 'info'}
                isOpen={!!this.props.visible}
                toggle={(this.props.setParent) ? this.onDismiss : () => {
                    return false;
                }}
            >
                {this.props.text}
            </Alert>
        );
    }
}
