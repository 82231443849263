import { type NeoForm, type NeoFormData } from '@/types/neoform';
import { type TranslationObject } from '@/composables/translation';
import { type File } from '@/types/api/files';
import { type UserGroup } from '@/types/api/user';
import { type History } from '@/types/api/history';
import { type NextStepInstance } from '@/types/api/process';
import { type Party } from '@/types/api/party';

export const CASE_STATUS_LIST = ['open', 'pending', 'closed', 'created'] as const;
export type CaseStatus = typeof CASE_STATUS_LIST[number];

export interface CaseUserGroup extends Omit<UserGroup, '_id' | 'org_id' | 'parent'> {
    _id: string;
    org_id: string;
    parent?: string;
}

export interface CaseProduct {
    _id: string;
    ns_list?: NextStepInstance[];
    price: string;
    fees: string;
    process_id?: string | null;
}

export interface Case {
    _id: string;
    folder_id: string;
    name: string;
    assign?: CaseAssign;
    nextstep?: NextStepInstance;
    future_nextstep?: NextStepInstance;
    macro?: CaseMacro;
    product?: CaseProduct;
    groups: CaseUserGroup[];
    files: File[];
    main_client?: Party;
    parties: Party[];
    input: NeoFormData;
    layout: NeoForm;
    status: CaseStatus;
    history?: {
        nextstep: History<NextStepInstance>;
        assign: History<CaseAssign>;
        status: History<CaseStatus>;
    };
    metadata?: any;
    updated_at: string;
    created_at: string;
}

export interface CaseAssign {
    _id: string;
    name: string | TranslationObject;
    type: 'user' | 'group';
}

export interface CaseMacro {

}

export type CaseInfo = Omit<Case, 'parties' | 'files' | 'input' | 'layout'>;

export interface GetFolderOptions {
    search_index?: boolean;
    search_value?: string;
    search_type?: 'text' | 'phrase';
    folder_id?: string;
    case_name?: string;
    case_status?: CaseStatus;
    case_created_at_start?: string;
    case_created_at_end?: string;
    case_updated_at_start?: string;
    case_updated_at_end?: string;
    party_id?: string;
    party_client_id?: string;
    party_email?: string;
    party_fullname?: string;
    party_phone?: string;
    assign_name?: string;
    assign_id?: string;
    group_id?: string;
    group_name?: string;
    product_id?: string;
    ns_id?: string;
    ns_title?: string;
    sort_by?: string;
    lang?: string;
    my_folders?: boolean;
    or_filter?: boolean;
    parse_all_folders_case?: boolean;
}

export interface GetExternalFoldersOptions {
    folder_id?: string;
    case_name?: string;
    case_status?: string;
    priority?: string;
    case_created_at_start?: string;
    case_created_at_end?: string;
    party_id?: string;
    party_email?: string;
    party_phone?: string;
    party_fullname?: string;
}

export interface GetCaseDto extends Case {}

export interface ExternalCase extends Pick<
    Case,
    'folder_id' | 'name' | 'status' |
    'parties' | 'updated_at' | 'created_at'
> {
    url: string;
}
