import React, { useEffect } from 'react';
import { type NextStepInstance, type Process } from '@/types/api/process';
import {
    type UpdateDialogProps
} from '@/components/ui/crud-table';
import { useForm } from 'react-hook-form';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { TextFieldTranslate } from '@/components/TextFieldTranslate';
import { useTranslation } from '@/composables/translation';
import { type User, type UserGroup } from '@/types/api/user';
import { Checkbox } from '@/components/ui/checkbox';
import { getFieldError, useValidation } from '@/composables/validation';
import {
    type CalendarConfig
} from '@/types/api/calendar';
import { CrudNextSteps } from '@/components/psj/process/CrudNextSteps';
import { type Macro } from '@/types/api/macro';

export interface UpdateProcessDialogProps extends UpdateDialogProps<Process> {
    users: User[];
    groups: UserGroup[];
    macros: Macro[];
    calendarConfig?: CalendarConfig;
}

export function UpdateProcessDialog(props: UpdateProcessDialogProps) {
    const { ct, t } = useTranslation('settings.processes.crud-dialog');
    const form = useForm<Process>({
        defaultValues: {
            ...props.value,
            ns_list: props.value?.ns_list?.map<NextStepInstance>(
                ({ ...ns }) => ({ ...ns, parent: ns })
            ) ?? []
        }
    });
    const isUpdateDialog = props.isUpdateDialog ?? false;
    const { required, requiredTranslate } = useValidation();

    useEffect(() => {
        if (props.value) {
            form.reset({
                ...props.value,
                ns_list: props.value.ns_list.map(({ ...ns }) => ({ ...ns, parent: ns }))
            });
        }
    }, [props.value]);

    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            <DialogContent className="md:tw-max-w-5xl">
                <DialogHeader>
                    <DialogTitle>{isUpdateDialog ? ct('edit') : ct('create')}</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={(event) => {
                        event.stopPropagation();
                        form.handleSubmit(props.onSubmit)(event);
                    }}>
                        <div className="tw-pb-6 tw-flex tw-flex-col tw-gap-3">
                            {isUpdateDialog &&
                                <FormField
                                    name="active"
                                    render={({ field }) =>
                                        <FormItem>
                                            <div className="tw-flex tw-items-center tw-py-1">
                                                <FormControl>
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value}
                                                        onCheckedChange={field.onChange}
                                                    />
                                                </FormControl>
                                                <FormLabel className="tw-pl-2">
                                                    {t('fields.active')}
                                                </FormLabel>
                                            </div>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                            }
                            <FormField
                                name="title"
                                render={({ field, formState }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.name')}
                                        </FormLabel>
                                        <FormControl>
                                            <TextFieldTranslate
                                                {...field}
                                                error={!!getFieldError(field.name, formState)}
                                            />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                                rules={{ validate: { requiredTranslate } }}
                            />
                            <FormField
                                name="ns_list"
                                render={(context) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.step-list')}
                                        </FormLabel>
                                        <FormControl>
                                            <CrudNextSteps
                                                context={context}
                                                users={props.users}
                                                groups={props.groups}
                                                macros={props.macros}
                                                calendarConfig={props.calendarConfig}
                                            />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                                rules={{ validate: { required } }}
                            />
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary">
                                    {ct('cancel')}
                                </Button>
                            </DialogClose>
                            <ButtonSubmit>
                                {ct('save')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
