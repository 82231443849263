import React, { Component } from 'react';
import { Actionnaire2 } from './Actionnaire2';

import { Button, Col, FormGroup, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Question = <FontAwesomeIcon icon={faQuestionCircle} className="avensRed" />;

const OBJECT = 'object';

export class Actionnaires2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: this.props.counter || 0,
            display: this.props.defaultDisplay !== false,
            actionnaires: [],
            lang: this.props.lang || 'fr',
            actionnairesList: [],
        };
        this.addHeirForm = this.addHeirForm.bind(this);
        this.removeHeirForm = this.removeHeirForm.bind(this);
    }

    genLang(key = 'missing') {
        const lang = (this.state.lang === '' || this.state.lang === null || this.state.lang === undefined) ? 'fr' : this.state.lang;

        if (typeof key === OBJECT) {
            if (lang && key[lang]) {
                return key[lang];
            } else {
                return '';
            }
        } else {
            if (!this.props.trad) {
                return key;
            }

            if (!this.props.trad[key]) {
                return this.props.trad['wrongKey'][lang] + ' ' + key;
            }

            return this.props.trad[key][lang];
        }

    }

    componentDidUpdate(prevProps) {
        let props = this.props;
        let obj = {};
        if (props.formData && JSON.stringify(props.formData) !== JSON.stringify(prevProps.formData) || this.state.force) {
            let fd = this.props.formData;
            let reg = new RegExp('^' + this.props.name);
            const actionnairesList = [];
            for (let f in fd) {
                if (f.match(reg)) {
                    let split = f.split(reg);
                    if (split[1] != '') {
                        obj[split[1].match(/\d+/)[0]] = true;
                    }
                }
            }

            let arr = [];

            for (let h in obj) {
                let key = this.props.name + '_' + h;
                actionnairesList.push({
                    key: key,
                    counter: h
                });

                arr.push(
                    <div key={key}>
                        <Row>
                            <Actionnaire2
                                onChange={this.props.onChange}
                                onSelectChange={this.props.onSelectChange}
                                removeWill={this.props.removeWill}
                                display={this.props.display}
                                defaultDisplay={this.props.defaultDisplay}
                                moreThan25={this.props.moreThan25}
                                add={this.props.add}
                                public={this.props.public}
                                ticket={this.props.ticket}
                                target={this.props.target}
                                address={this.props.address}
                                version={this.props.version}
                                put={this.props.put}
                                lang={this.state.lang}
                                trad={this.props.trad}
                                formData={this.props.formData}
                                remove={this.removeHeirForm}
                                name={key}
                                counter={h}
                                noCard={this.props.noCard}
                                isPrivate={this.props.isPrivate}
                                title={this.props.title}
                                toggleLoading={this.props.toggleLoading}
                                pushWaitingList={
                                    this.props.pushWaitingList
                                }
                                pullWaitingList={
                                    this.props.pullWaitingList
                                }
                            >
                            </Actionnaire2>
                        </Row>
                    </div>
                );
            }
            this.setState({
                actionnaires: arr,
                counter: arr.length + 10,
                force: false,
                actionnairesList: actionnairesList
            });
        }

        if (props.lang !== prevProps.lang) {

            let obj = {};

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }
            if (!_.isEmpty(obj)) {
                this.setState(obj);
            }
        }
    }

    componentDidMount() {
        // set Default Data in here :)
        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);
        // console.log(this.props)
        const actionnairesList = [];

        for (let f in fd) {
            if (f.match(reg)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }

        let arr = [];
        for (let h in obj) {
            let key = this.props.name + '_' + h;
            actionnairesList.push({
                key: key,
                counter: h
            });
            arr.push(
                <Row key={key}>
                    <Actionnaire2
                        onChange={this.props.onChange}
                        onSelectChange={this.props.onSelectChange}
                        removeWill={this.props.removeWill}
                        removeActionnaires={this.props.removeActionnaires}
                        display={this.props.display}
                        defaultDisplay={this.props.defaultDisplay}
                        moreThan25={this.props.moreThan25}
                        changeFormData={this.props.changeFormData}
                        add={this.props.add}
                        removeFileFromGCloud={this.props.removeFileFromGCloud}
                        public={this.props.public}
                        ticket={this.props.ticket}
                        target={this.props.target}
                        address={this.props.address}
                        put={this.props.put}
                        lang={this.state.lang}
                        trad={this.props.trad}
                        formData={{ ...this.props.formData }}
                        remove={this.removeHeirForm}
                        name={key}
                        counter={h}
                        noCard={this.props.noCard}
                        isPrivate={this.props.isPrivate}
                        title={this.props.title}
                        toggleLoading={this.props.toggleLoading}
                        pushWaitingList={
                            this.props.pushWaitingList
                        }
                        pullWaitingList={
                            this.props.pullWaitingList
                        }
                    />
                </Row>
            );
        }
        this.setState({
            actionnaires: arr,
            actionnairesList: actionnairesList
        });

    }

    addHeirForm() {
        this.setState(state => {

            let counter = this.state.counter;
            let key = this.props.name + '_' + counter++;

            state.counter = counter;
            state.actionnairesList.push({
                key: key,
                counter: counter
            });

            let province = key + '_state',
                country = key + '_country';

            this.props.put({
                params: {
                    key: province,
                    value: 'Québec'
                }
            });
            this.props.put({
                params: {
                    key: country,
                    value: 'Canada'
                }
            });
            return state;
        });
    }

    removeHeirForm(id) {
        this.props.remove({ params: { key: id } });
        this.setState({
            actionnaires: _.reject(this.state.actionnaires, { key: id }),
            actionnairesList: _.reject(this.state.actionnairesList, { key: id }),
        });
    }

    renderInfo(info, id) {
        if (!info) {
            return '';
        }

        return (
            <>
                <b id={id}>{Question}</b>
                <UncontrolledPopover placement="top" target={id}>
                    <PopoverBody>{info}</PopoverBody>
                </UncontrolledPopover>
            </>
        );
    }

    render() {
        const actionnairesList = this.state.actionnairesList;
        return (
            <>
                {this.props.display !== false && (
                    <Col md={this.props.col || 12}>
                        {actionnairesList.map((form) => (
                            <div key={form.key}>
                                <Row>
                                    <Actionnaire2
                                        onChange={this.props.onChange}
                                        onSelectChange={this.props.onSelectChange}
                                        removeWill={this.props.removeWill}
                                        removeActionnaires={this.props.removeActionnaires}
                                        display={this.props.display}
                                        defaultDisplay={this.props.defaultDisplay}
                                        moreThan25={this.props.moreThan25}
                                        add={this.props.add}
                                        removeFileFromGCloud={this.props.removeFileFromGCloud}
                                        public={this.props.public}
                                        ticket={this.props.ticket}
                                        target={this.props.target}
                                        noCard={this.props.noCard}
                                        isPrivate={this.props.isPrivate}
                                        address={this.props.address}
                                        put={this.props.put}
                                        lang={this.state.lang || 'fr'}
                                        trad={this.props.trad}
                                        formData={{ ...this.props.formData }}
                                        remove={this.removeHeirForm}
                                        name={form.key}
                                        counter={form.counter}
                                        title={this.props.title}
                                        toggleLoading={this.props.toggleLoading}
                                        pushWaitingList={this.props.pushWaitingList}
                                        pullWaitingList={this.props.pullWaitingList}
                                    />
                                </Row>
                            </div>
                        ))}
                        {this.props.children}
                        <FormGroup>
                            <Button
                                className="tw-mt-3"
                                color="primary"
                                block
                                outline
                                onClick={() => this.addHeirForm()}
                            >
                                {this.genLang('_addActionnaire') || 'Ajouter'}
                            </Button>
                        </FormGroup>
                    </Col>
                )}
            </>
        );
    }
}
