import React, { useState } from 'react';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { useTranslation } from '@/composables/translation';
import { useValidation } from '@/composables/validation';
import { Combobox } from '@/components/ui/combobox';
import { postPayInvoice } from '@/composables/api';
import { type PaymentMethod } from '@/types/api/invoice';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { Textarea } from '@/components/ui/textarea';
import { PAYMENT_METHODS } from '@/components/utils/translations';

interface Props {
    invoice_number: string;
    transaction_amount: number;
    children?: React.ReactNode;
    onSuccess?: () => void;
}

interface FormValues {
    // transaction_type: TransactionType;
    transaction_method: PaymentMethod;
    notes: string;
    // transaction_amount: number;
}

export function PayInvoiceDialog(props: Props) {
    const { t, ct, to } = useTranslation('settings.invoices.pay');
    const { required } = useValidation();
    const form = useForm<FormValues>();
    const [open, setOpen] = useState(false);
    function handleSubmit({ transaction_method, notes }: FormValues) {
        return postPayInvoice({
            invoice_number: props.invoice_number,
            transaction_type: 'pay',
            transaction_amount: props.transaction_amount,
            transaction_method,
            transaction_date: DateTime.now().toFormat('yyyy-MM-dd'),
            notes
        }).then(() => {
            toast.success(ct('messages.success'));
            props.onSuccess?.();
            setOpen(false);
        });
    }
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            {props.children}
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>{t('title')}</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)}>
                        <div className="tw-flex tw-flex-col tw-gap-3 tw-pb-6">
                            {/* <FormField */}
                            {/*     name="transaction_type" */}
                            {/*     defaultValue="pay" */}
                            {/*     rules={{ validate: { required } }} */}
                            {/*     render={({ field: { ref, ...field }, fieldState }) => ( */}
                            {/*         <FormItem> */}
                            {/*             <FormLabel> */}
                            {/*                 {t('type')} */}
                            {/*             </FormLabel> */}
                            {/*             <FormControl> */}
                            {/*                 <Combobox */}
                            {/*                     options={TRANSACTION_TYPES} */}
                            {/*                     getOptionLabel={(opt) => to(opt.label)} */}
                            {/*                     getOptionValue={(opt) => opt.value} */}
                            {/*                     {...field} */}
                            {/*                     innerRef={ref} */}
                            {/*                     error={!!fieldState.error} */}
                            {/*                 /> */}
                            {/*             </FormControl> */}
                            {/*             <FormMessage /> */}
                            {/*         </FormItem> */}
                            {/*     )} */}
                            {/* /> */}
                            <FormField
                                name="transaction_method"
                                rules={{ validate: { required } }}
                                render={({ field: { ref, ...field }, fieldState }) => (
                                    <FormItem>
                                        <FormLabel>
                                            {t('method')}
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox
                                                options={PAYMENT_METHODS}
                                                getOptionLabel={(opt) => to(opt.label)}
                                                getOptionValue={(opt) => opt.value}
                                                {...field}
                                                innerRef={ref}
                                                error={!!fieldState.error}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            {/* <FormField */}
                            {/*     name="transaction_amount" */}
                            {/*     rules={{ validate: { required } }} */}
                            {/*     render={({ field, fieldState }) => ( */}
                            {/*         <FormItem> */}
                            {/*             <FormLabel> */}
                            {/*                 {t('amount')} */}
                            {/*             </FormLabel> */}
                            {/*             <FormControl> */}
                            {/*                 <InputNumber */}
                            {/*                     {...field} */}
                            {/*                     error={!!fieldState.error} */}
                            {/*                     maskOptions={{ */}
                            {/*                         scale: 2, */}
                            {/*                         min: 0 */}
                            {/*                     }} */}
                            {/*                 /> */}
                            {/*             </FormControl> */}
                            {/*             <FormMessage /> */}
                            {/*         </FormItem> */}
                            {/*     )} */}
                            {/* /> */}
                            <FormField
                                name="notes"
                                render={({ field, fieldState }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('notes')}
                                        </FormLabel>
                                        <FormControl>
                                            <Textarea
                                                {...field}
                                                error={!!fieldState.error}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary">
                                    {ct('cancel')}
                                </Button>
                            </DialogClose>
                            <ButtonSubmit>
                                {ct('submit')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
