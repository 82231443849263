import React from 'react';
import { NeoFormComponent } from '@/components/neoform/NeoFormComponent';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { useTranslation, combine, type TranslationObject } from '@/composables/translation';
import { useNeoForm } from '@/composables/neoform';
import { Col, Row } from '@/components/ui/row';

interface Props {
    homeAndProfessionalAddress?: boolean;
    noCertificate?: boolean;
    actionPrice?: boolean;
    certificateChecklist?: Array<TranslationObject | string>;
    declarationChecklist?: Array<TranslationObject | string>;
}

export function Stakeholder(props: Props) {
    const { form, getChildFieldName } = useNeoForm();
    const { t, to } = useTranslation('neoform.stakeholder');

    const certificateChecklist = props.certificateChecklist ?? [
        t('certificate.item-0'),
        t('certificate.item-1'),
        t('certificate.item-2'),
        t('certificate.item-3'),
        t('certificate.item-4')
    ];

    const declarationChecklist = props.declarationChecklist ?? [
        t('declaration.item-0'),
        t('declaration.item-1'),
        t('declaration.item-2'),
        t('declaration.item-3'),
        t('declaration.item-4'),
        t('declaration.item-5'),
        t('declaration.item-6'),
        t('declaration.item-7'),
        t('declaration.item-8'),
        t('declaration.item-9'),
        t('declaration.item-10'),
        t('declaration.item-11'),
        t('declaration.item-12')
    ];

    const isFormPrivate = (form?.info && !form?.info.is_public);

    return (
        <div className="tw-flex tw-flex-col tw-gap-1">
            <Row>
                <NeoFormComponent
                    name="name"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.last-name')}
                    col={4}
                />
                <NeoFormComponent
                    name="firstname"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.first-name')}
                    col={4}
                />
                <NeoFormComponent
                    name="gender"
                    componentName="InputSelect"
                    col={4}
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.gender')}
                    options={[
                        { value: 'M', label: combine('neoform.sir') },
                        { value: 'F', label: combine('neoform.madam') },
                        { value: '-', label: combine('neoform.other') }
                    ]}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="birthday"
                    componentName="InputDate"
                    col={4}
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.birthday')}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="date_start"
                    componentName="InputDate"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.date-start')}
                    col={4}
                />
                <NeoFormComponent
                    name="date_end_check"
                    componentName="InputCheckbox"
                    title={combine('neoform.date-end')}
                    size="small"
                    col={4}
                />
                <NeoFormComponent
                    name="date_end"
                    componentName="InputDate"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.date-end')}
                    display={!!form?.watch(getChildFieldName('date_end_check'))}
                    col={4}
                />
            </Row>
            {props.homeAndProfessionalAddress
                ? <>
                    <Row className="!tw-mt-2">
                        <NeoFormComponent
                            name="home_address"
                            componentName="Address"
                            title={combine('neoform.home-address')}
                            country
                        />
                    </Row>
                    <Row className="!tw-mt-2">
                        <NeoFormComponent
                            name="professional_address"
                            componentName="Address"
                            title={combine('neoform.professional-address')}
                            country
                        />
                    </Row>
                </>
                : <Row>
                    <NeoFormComponent
                        name="address"
                        componentName="Address"
                        title={combine('neoform.address.address')}
                        displayPreface={false}
                        country
                    />
                </Row>
            }
            <Row className="!tw-mt-2">
                <NeoFormComponent
                    name="files"
                    componentName="InputDropContainer"
                    title={combine('neoform.common.id.title')}
                    description={combine('neoform.common.id.description')}
                    last={!isFormPrivate}
                />
            </Row>
            {isFormPrivate && !props.noCertificate && <div className="tw-flex tw-flex-col tw-gap-1">
                <h5 className="tw-mt-2">{t('certificate.title')}</h5>
                <Row>
                    <NeoFormComponent
                        name="certificateNumber"
                        componentName="InputText"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('neoform.stakeholder.certificate.number')}
                        col={4}
                    />
                    <NeoFormComponent
                        name="certificateDate"
                        componentName="InputDate"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('neoform.stakeholder.certificate.date')}
                        col={4}
                    />
                    <NeoFormComponent
                        name="certificateNumShares"
                        componentName="InputNumber"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('neoform.stakeholder.certificate.num-shares')}
                        col={4}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="serialNumber"
                        componentName="InputText"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('neoform.stakeholder.certificate.serial-number')}
                        col={4}
                        last
                    />
                    <NeoFormComponent
                        name="certificateCategory"
                        componentName="InputSelect"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('neoform.stakeholder.certificate.category')}
                        col={4}
                        last
                        options={[
                            {
                                value: 'A',
                                label: 'A'
                            },
                            {
                                value: 'B',
                                label: 'B'
                            },
                            {
                                value: 'C',
                                label: 'C'
                            },
                            {
                                value: 'D',
                                label: 'D'
                            },
                            {
                                value: 'E',
                                label: 'E'
                            }
                        ]}
                    /> {props.actionPrice &&
                        <NeoFormComponent
                            name="actionPrice"
                            componentName="InputNumber"
                            titleType={NeoFormTitleType.LABEL}
                            min="0"
                            title={combine('neoform.stakeholder.certificate.price-action')}
                            col={4}
                        />
                    }
                </Row>
                <Row>
                    <Col>
                        <h6>{t('certificate.list-title')}</h6>
                        {certificateChecklist.map((label, i) =>
                            <NeoFormComponent
                                key={i}
                                name={String(i)}
                                componentName="InputCheckbox"
                                label={to(label)}
                                group="certificateList"
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col className="tw-flex tw-flex-col tw-gap-1">
                        <h6>{t('declaration.list-title')}</h6>
                        {declarationChecklist.map((label, i) =>
                            <NeoFormComponent
                                key={i}
                                name={String(i)}
                                componentName="InputCheckbox"
                                label={to(label)}
                                size="small"
                                group="declarationList"
                                last={i === declarationChecklist.length - 1}
                            />
                        )}
                    </Col>
                </Row>
            </div>}
        </div>
    );
}
