import c, { type ArgumentArray } from 'classnames';
import { twMerge } from 'tailwind-merge';
import _ from 'lodash';

export function cn(...inputs: ArgumentArray) {
    return twMerge(c(inputs));
}

export function col(value?: string | number) {
    if (!value) {
        return '12';
    }
    const num = Math.floor(Number(value));
    return _.clamp(num, 0, 12).toString();
}
