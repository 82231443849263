import React from 'react';
import { OverrideConfigurationContext } from '@/composables/override';
import { Outlet } from 'react-router-dom';

export function PortalLayout() {
    return (
        <OverrideConfigurationContext.Provider
            value={{
                is_customer_portal: true,
                urls: {
                    post_login: '/v1/customer/login',
                    post_forgot_password: '/v1/customer/login/password/forgot',
                    put_reset_password: '/v1/customer/login/password/reset',
                    get_folder: '/v1/customer/folder',
                    get_folder_list: '/v1/customer/folder/folders',
                    get_note_list: '/v1/customer/note/list'
                }
            }}
        >
            <Outlet/>
        </OverrideConfigurationContext.Provider>
    );
}
