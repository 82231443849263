export function formatInteger(value: number) {
    return Number.isNaN(value) ? '0' : Math.floor(value).toString();
}

export function formatPercentage(value: number) {
    return `${Number.isNaN(value) ? '0' : Math.floor(100 * value)}%`;
}

export function formatCurrency(currency: string) {
    return (value: number) =>
        new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency
        })
            .format(value);
}
