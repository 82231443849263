import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Button } from './button';
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { Calendar } from './calendar';
import { Input, type InputProps } from '@/components/ui/input';
import { IMask, useIMask } from 'react-imask';
import { assignRef, combineDateTime } from '@/composables/utils';
import { useControllableState } from '@/composables/controllable';
import { type DayPickerSingleProps } from 'react-day-picker';
import { Combobox } from '@/components/ui/combobox';
import _ from 'lodash';
import { enUS, fr } from 'date-fns/locale';
import { useUserStore } from '@/store/user';
import { type Language } from '@/composables/translation';

interface Props extends Omit<InputProps, 'onChange' | 'value'> {
    value?: DateTime | null;
    onChange?: React.Dispatch<DateTime | null>;
    calendarProps?: Partial<DayPickerSingleProps>;
}

const DATE_FORMAT = 'dd-MM-yyyy';
const NOW = DateTime.now();
const YEARS_START = NOW.year - 100;
const YEARS_END = NOW.year + 100;

function getDayPickerLocale(lang: Language) {
    switch (lang) {
    case 'fr':
        return fr;
    case 'en':
        return enUS;
    }
}

const DatePicker = React.forwardRef<HTMLInputElement, Props>(({ calendarProps, ...props }, inputRef) => {
    const input = useRef<HTMLInputElement>(null);
    const lang = useUserStore(state => state.lang);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useControllableState(
        null, props.value,
        props.onChange as React.Dispatch<React.SetStateAction<DateTime | null>>
    );
    const mask = useIMask(
        {
            lazy: false,
            overwrite: true,
            autofix: false,
            mask: 'DD{-}MM{-}YYYY',
            blocks: {
                YYYY: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'y',
                    from: 1900,
                    to: 2500,
                    maxLength: 4
                },
                MM: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'm',
                    from: 1,
                    to: 12,
                    maxLength: 2
                },
                DD: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'd',
                    from: 1,
                    to: 31,
                    maxLength: 2
                }
            }
        },
        {
            onAccept: (newValue) => {
                const date = DateTime.fromFormat(newValue, DATE_FORMAT);
                let dateValue = date.isValid ? date : null;
                if (dateValue && value?.isValid === true) {
                    dateValue = combineDateTime(date, value);
                }
                setValue(dateValue);
                if (dateValue) {
                    mask.setValue(newValue);
                }
            }
        }
    );
    const [month, setMonth] = useState<Date>(value?.toJSDate() ?? new Date());
    const monthValue = useMemo(() => month.getMonth(), [month]);
    const yearValue = useMemo(() => month.getFullYear(), [month]);
    useEffect(() => {
        if (value) {
            mask.setValue(value.toFormat(DATE_FORMAT));
            setMonth(value.toJSDate());
        } else {
            mask.setUnmaskedValue('');
        }
    }, [value]);
    return (
        <Popover modal open={open} onOpenChange={setOpen}>
            <PopoverTrigger className="tw-w-full">
                <Input
                    {...props}
                    ref={(ref) => {
                        mask.ref.current = ref;
                        assignRef(inputRef, ref);
                        assignRef(input, ref);
                    }}
                    value={mask.value}
                    onChange={(e) => mask.setUnmaskedValue(e.target.value)}
                    prependIcon={<CalendarIcon />}
                />
            </PopoverTrigger>
            <PopoverContent
                className="tw-w-auto !tw-p-0 tw-min-w-[300px] tw-h-[340px]"
                side="bottom" align="start"
                onOpenAutoFocus={(e) => {
                    input.current?.select();
                    e.preventDefault();
                }}
            >
                <Calendar
                    mode="single"
                    selected={props.value?.toJSDate()}
                    month={month}
                    onMonthChange={setMonth}
                    onSelect={(value) => {
                        const newValue = value && props.value
                            ? combineDateTime(DateTime.fromJSDate(value), props.value)
                            : value
                                ? DateTime.fromJSDate(value)
                                : null;
                        setValue(newValue);
                        setOpen(false);
                    }}
                    components={{
                        Caption: ({ ...props }) => <div className="tw-flex tw-items-center tw-gap-x-1">
                            <Button
                                variant="outline" size="icon"
                                className="tw-mr-auto"
                                onClick={() => {
                                    const newMonth = new Date(month.getTime());
                                    newMonth.setMonth(monthValue - 1);
                                    setMonth(newMonth);
                                }}
                                tabIndex={-1}
                            >
                                <ChevronLeftIcon />
                            </Button>
                            <Combobox
                                className="tw-flex-1 !tw-w-[90px]"
                                placeholder=""
                                value={monthValue}
                                onChange={(value) => {
                                    const newMonth = new Date(month.getTime());
                                    newMonth.setMonth(value ?? DateTime.now().month - 1);
                                    setMonth(newMonth);
                                }}
                                getOptionLabel={
                                    (opt) => DateTime.fromFormat(
                                        _.padStart(String(opt + 1), 2, '0'),
                                        'MM'
                                    ).toFormat('MMM')
                                }
                                options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                                tabIndex={-1}
                            />
                            <Combobox
                                className="tw-flex-1 !tw-w-[90px]"
                                placeholder=""
                                value={yearValue}
                                onChange={(value) => {
                                    const newMonth = new Date(month.getTime());
                                    newMonth.setFullYear(value ?? DateTime.now().year);
                                    setMonth(newMonth);
                                }}
                                options={_.range(YEARS_START, YEARS_END)}
                                tabIndex={-1}
                            />
                            <Button
                                variant="outline" size="icon"
                                className="tw-ml-auto"
                                onClick={() => {
                                    const newMonth = new Date(month.getTime());
                                    newMonth.setMonth(monthValue + 1);
                                    setMonth(newMonth);
                                }}
                                tabIndex={-1}
                            >
                                <ChevronRightIcon />
                            </Button>
                        </div>
                    }}
                    locale={getDayPickerLocale(lang)}
                    {...calendarProps}
                />
            </PopoverContent>
        </Popover>
    );
});
DatePicker.displayName = 'DatePicker';

export { DatePicker };
