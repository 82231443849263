import React, { useEffect, useMemo, useState } from 'react';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import { getMacrosList, getOrgGroups, postCreateMacro, putUpdateMacro } from '@/composables/api';
import { type UpdateMacroDto } from '@/types/api/macro';
import { type UserGroup } from '@/types/api/user';
import { useGroups } from '@/composables/groups';
import { CheckboxCell } from '@/components/ui/cells';

export function Macros() {
    const { t, ct, to } = useTranslation('settings.macros');
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const { domains } = useGroups({ groups });

    useEffect(() => {
        getOrgGroups()
            .then((res) => setGroups(res.data));
    }, []);

    const folderStatusOptions = useMemo(() => [
        { label: t('open'), value: 'open' },
        { label: t('pending'), value: 'pending' },
        { label: t('closed'), value: 'closed' }
    ], [ct]);

    const folderPublicOptions = useMemo(() => [
        { label: t('public'), value: true },
        { label: t('private'), value: false }
    ], [t]);

    const domainOptions = useMemo(() =>
        domains
            .filter(g => g.domain)
            .map(i => ({ label: to(i.name), value: i?._id?.$oid })),
    [groups, to]);

    const schema = useMemo<CrudSchema<UpdateMacroDto>>(() => [
        {
            id: 'active',
            type: CrudInputType.CHECKBOX,
            name: t('table.active'),
            defaultValue: true,
            create: false,
            columnDef: {
                id: 'active',
                header: t('table.active'),
                accessorKey: 'active',
                cell: CheckboxCell
            }
        },
        {
            id: 'title',
            type: CrudInputType.TEXT,
            name: t('table.title'),
            translate: true,
            required: true,
            columnDef: {
                id: 'title',
                header: t('table.title'),
                accessorKey: 'title',
                cell: ({ cell }) => to(cell.getValue<string>())
            }
        },
        {
            id: 'description',
            type: CrudInputType.TEXT,
            name: t('table.description'),
            translate: true,
            columnDef: {
                id: 'description.en',
                header: t('table.description'),
                accessorKey: 'description.en',
                cell: ({ cell }) => to(cell.getValue<string>())
            }
        },
        {
            id: 'actions.case.status',
            type: CrudInputType.SELECT,
            name: t('table.status'),
            col: 4,
            options: folderStatusOptions,
            getOptionLabel: (option) => option.label,
            getOptionValue: (option) => option.value,
            columnDef: {
                id: 'actions.case.status',
                header: t('table.status'),
                accessorKey: 'actions.case.status',
                cell: ({ cell }) => t(cell.getValue() as 'open' | 'pending' | 'closed')
            }
        },
        {
            id: 'actions.is_public',
            type: CrudInputType.SELECT,
            name: t('note-type'),
            col: 4,
            options: folderPublicOptions,
            defaultValue: false,
            getOptionLabel: (option) => option.label,
            getOptionValue: (option) => option.value,
            columnDef: {
                id: 'actions.is_public',
                header: t('note-type'),
                accessorKey: 'actions.is_public',
                cell: ({ cell }) => to(cell.getValue<boolean>() ? t('public') : t('private'))
            }
        },
        {
            id: 'domain',
            type: CrudInputType.SELECT,
            name: t('domain'),
            col: 4,
            required: true,
            options: domainOptions,
            getOptionLabel: (option) => option.label,
            getOptionValue: (option) => option.value,
            columnDef: {
                id: 'domain',
                header: t('domain'),
                accessorKey: 'domain',
                cell: ({ cell }) => to(domainOptions.find(o => o.value === cell.getValue<string>())?.label)
            }
        },
        {
            id: 'content_template.en',
            type: CrudInputType.QUILL,
            name: t('text-en')
        },
        {
            id: 'content_template.fr',
            type: CrudInputType.QUILL,
            name: t('text-fr')
        }
    ], [domainOptions]);

    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CrudTable<UpdateMacroDto, '_id'>
                    idKey="_id"
                    schema={schema}
                    initialState={{ columnVisibility: { id: true } }}
                    onRead={() => getMacrosList().then((res) => res.data)}
                    onCreate={(value) => postCreateMacro(value).then()}
                    singleItemDeletion
                    onUpdate={(value) => putUpdateMacro(value).then()}
                />
            </CardContent>
        </>
    );
}
