import React from 'react';
import {
    Controller,
    type ControllerProps,
    type FieldPath,
    type FieldValues,
    useFieldArray,
    useFormContext
} from 'react-hook-form';
import { type InvoiceRule } from '@/types/api/orgs';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { CrudBillingModel } from '@/components/billing/CrudBillingModel';
import { getFieldError, useValidation } from '@/composables/validation';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useTranslation } from '@/composables/translation';
import ObjectId from 'bson-objectid';
import { Combobox } from '@/components/ui/combobox';
import { LICENSE_OPTIONS } from '@/composables/license';
import { cn } from '@/lib/utils';
import { Cross1Icon } from '@radix-ui/react-icons';
import { BindProps } from '@/components/utils/BindProps';

interface BillingRuleProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends React.ComponentProps<ControllerProps<TFieldValues, TName>['render']> {
    hideRemove?: boolean;
    onRemove?: React.Dispatch<void>;
}

function BillingRule<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(props: BillingRuleProps<TFieldValues, TName>) {
    const { to, t } = useTranslation('settings.billing.features.neodesk');
    const { required } = useValidation();
    return (
        <Card className="tw-relative">
            {!props.hideRemove && <Button
                type="button" variant="ghost" size="icon"
                className={cn(
                    '!tw-absolute tw-top-0 tw-right-0 !tw-m-1 !tw-size-7',
                    'tw-text-destructive'
                )}
                onClick={() => props.onRemove?.()}
            >
                <Cross1Icon/>
            </Button>}
            <CardContent className="!tw-p-4 tw-flex tw-flex-col tw-gap-3">
                <FormField
                    name={`${props.field.name}.definition.license`}
                    rules={{ validate: { required } }}
                    render={({ field: { ref, ...field }, formState }) =>
                        <FormItem>
                            <FormLabel>
                                Type de license
                            </FormLabel>
                            <FormControl>
                                <Combobox
                                    options={LICENSE_OPTIONS}
                                    getOptionLabel={(opt) => to(opt.label)}
                                    {...field}
                                    innerRef={ref}
                                    error={!!getFieldError(field.name, formState)}
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    }
                />
                <FormField
                    name={`${props.field.name}.stages`}
                    rules={{ validate: { required } }}
                    render={(context) =>
                        <FormItem>
                            <FormLabel>
                                Grille de facturation ({context.field.value?.length ?? 0})
                            </FormLabel>
                            <FormControl>
                                <CrudBillingModel
                                    {...context}
                                    translations={{
                                        stage: t('stage'),
                                        price: t('price')
                                    }}
                                />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    }
                />
            </CardContent>
        </Card>
    );
}

interface BillingNeodeskProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends React.ComponentProps<ControllerProps<TFieldValues, TName>['render']> {
}

export function BillingNeodesk({ field }: BillingNeodeskProps) {
    const { ct } = useTranslation();
    const form = useFormContext();
    const { required } = useValidation();
    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: `${field.name}.rules`,
        rules: {
            validate: { required }
        }
    });
    const rules = (form.watch(`${field.name}.rules`) ?? []) as InvoiceRule[];
    return (
        <div className="tw-flex tw-flex-col tw-gap-3">
            {fields.map((f, idx) => <Controller
                key={f.id}
                name={`${field.name}.rules.${idx}`}
                render={BindProps(BillingRule, {
                    hideRemove: fields.length <= 1,
                    onRemove: () => remove(idx)
                }) as any}
            />)}
            <Button
                variant="outline" className="tw-text-primary"
                onClick={() => append({
                    name: `rule_${rules.length}`,
                    definition: {
                        license: null
                    },
                    stages: [{
                        id: ObjectId().toHexString(),
                        start: 0,
                        unit_price: 0
                    }]
                } satisfies InvoiceRule)}
            >
                {ct('add')}
            </Button>
        </div>
    );
}
