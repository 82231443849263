import * as React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '@/lib/utils';

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({
    className,
    ...props
}, ref) => (
    <TabsPrimitive.List
        ref={ref}
        className={cn(
            'tw-inline-flex tw-h-9 tw-items-center tw-justify-center tw-rounded-lg tw-bg-muted tw-p-1',
            'tw-text-muted-foreground',
            className
        )}
        {...props}
    />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({
    className,
    ...props
}, ref) => (
    <TabsPrimitive.Trigger
        ref={ref}
        className={cn(
            'tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-md tw-px-3 tw-py-1',
            'tw-text-sm tw-font-medium tw-ring-offset-background tw-transition-all focus-visible:tw-outline-none',
            'focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2',
            'disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[state=active]:tw-bg-background',
            'data-[state=active]:tw-text-foreground data-[state=active]:tw-shadow',
            className
        )}
        {...props}
    />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({
    className,
    ...props
}, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={cn(
            'tw-mt-2 tw-ring-offset-background focus-visible:tw-outline-none focus-visible:tw-ring-2',
            'focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2',
            className
        )}
        {...props}
    />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
