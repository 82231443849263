import React, { Component } from 'react';
import { Card, CardBody, CardText, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_STYLE = {};

const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;
const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class InputField extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '';
        this.state = {
            lang: this.props.lang || 'fr',
            name: fd[this.props.name + '_name'] || '',
            feild: fd[this.props.name + '_feild'] || '',
            feildTextarea: fd[this.props.name + '_feildTextarea'] || '',

            display: this.props.defaultDisplay === false ? false : true
        };

        this.option = this.option.bind(this);
        this.genLang = this.genLang.bind(this);

    }

    genLang(key = 'missing') {

        if (!this.props.trad) {
            return key;
        }

        if (!this.props.trad[key]) {
            return this.props.trad['wrongKey'][this.state.lang] + ' ' + key;
        }
        return this.props.trad[key][this.state.lang];
    }

    genLangNew(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevProps) {
        let props = this.props,
            obj = {};

        if (prevProps.formData && props.formData || props.version !== prevProps.version) {

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }

            if (props.formData[props.name + '_name'] !== prevProps.formData[props.name + '_name']) {
                obj['name'] = props.formData[props.name + '_name'] || '';
            }

            if (props.formData[props.name + '_firstname'] !== prevProps.formData[props.name + '_firstname']) {
                obj['firstname'] = props.formData[props.name + '_firstname'] || '';
            }

            if (props.formData[props.name + '_assistant'] !== prevProps.formData[props.name + '_assistant']) {
                obj['assistant'] = props.formData[props.name + '_assistant'] || '';
            }

            if (props.formData[props.name + '_gender'] !== prevProps.formData[props.name + '_gender']) {
                obj['gender'] = props.formData[props.name + '_gender'] || '';
            }

            if (props.formData[props.name + '_cartificatDate'] !== prevProps.formData[props.name + '_cartificatDate']) {
                obj['cartificatDate'] = props.formData[props.name + '_cartificatDate'] || '';
            }

            if (props.display !== prevProps.display) {

                if (props.display === false && prevProps.display === true) {
                    obj.display = false;
                } else if (props.display === true && prevProps.display === false) {
                    obj.display = true;
                } else if (prevProps.display === undefined) {
                    obj.display = props.display;
                }
            }
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    option() {
    }

    render() {

        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        if (this.props.noCard) {
            return (
                <Col md={this.props.col || 12} style={style}>

                    {this.props.remove &&
                        <span className="remove"
                              onClick={() => this.props.remove(this.props.name)}>
                            {cross}
                        </span>
                    }

                    {this.props.feildType === 'inputText' &&
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>{this.genLangNew(this.props.title, this.state.lang)}</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_feild'}
                                        onChange={(e) => this.setState({ feild: e.target.value })}
                                        value={this.state.feild}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                    {this.props.feildType === 'textArea' &&
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>{this.genLangNew(this.props.title, this.state.lang)}</Label>
                                    <Input
                                        type="textarea"
                                        name={this.props.name + '_feildTextarea'}
                                        rows={4}
                                        defaultValue={this.props.formData[this.props.name + '_feildTextarea'] || ''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                </Col>
            );
        }

        return (
            <Col md={this.props.col || 12} style={style}>
                <Card style={{ marginBottom: '5px' }}>
                    {this.props.remove &&
                        <span className="remove" style={{ zIndex: 9999 }}
                              onClick={() => this.props.remove(this.props.name)}>
                            {cross}
                        </span>
                    }
                    <CardBody>
                        <CardText tag="div">
                            {this.props.feildType === 'inputText' &&
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>{this.genLangNew(this.props.title, this.state.lang)}</Label>
                                            <Input
                                                type="text"
                                                name={this.props.name + '_feild'}
                                                onChange={(e) => this.setState({ feild: e.target.value })}
                                                value={this.state.feild}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                            {this.props.feildType === 'textArea' &&
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>{this.genLangNew(this.props.title, this.state.lang)}</Label>
                                            <Input
                                                type="textarea"
                                                name={this.props.name + '_feildTextarea'}
                                                rows={4}
                                                defaultValue={this.props.formData[this.props.name + '_feildTextarea'] || ''}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}
