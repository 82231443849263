import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { ActionBar } from '@/components/ActionBar';
import { LanguageSelect } from '@/components/neoform/helper/LanguageSelect';
import { MenuButton } from '@/components/MenuButton';
import { Button } from '@/components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from '@/composables/translation';

export function PortalPrivateLayout() {
    const { t, ct } = useTranslation('nav');
    return (
        <>
            <ActionBar className="tw-top-0">
                <Link to="/portal">
                    <h4 className="tw-font-medium tw-text-xl">Portail Client</h4>
                </Link>
                <LanguageSelect
                    className="tw-ml-auto tw-text-muted-foreground"
                />
                <MenuButton
                    id="account"
                    tooltip={t('account')}
                    renderButton={props => (
                        <Button
                            variant="ghost" size="icon"
                            className="tw-text-muted-foreground"
                            {...props}
                        >
                            <FontAwesomeIcon icon={faUser} width="1em" />
                        </Button>
                    )}
                    options={[
                        {
                            value: 'logout',
                            label: ct('logout'),
                            icon: faRightFromBracket,
                            url: '/logout'
                        }
                    ]}
                />
            </ActionBar>
            <Outlet/>
        </>
    );
}
