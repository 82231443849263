import React, { Component } from 'react';
import { Card, CardBody, CardText, Col, Input, Row } from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { TextAreaInput } from './TextAreaInput';
import { ContentEditableTiny } from './ContentEditableTiny';

const DEFAULT_STYLE = {};

const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class ParentalTime extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '';
        this.state = {
            lang: this.props.lang || 'fr',
            name: fd[this.props.name + '_name'] || '',
            shareTime: fd[this.props.name + '_shareTime'] || '',
            askCourt: fd[this.props.name + '_askCourt'] || '',
            shareDescription: fd[this.props.name + '_shareDescription'] || '',
            otherPoints: fd[this.props.name + '_otherPoints'] || '',
            children: fd[this.props.name + '_children'] || '',

            display: this.props.defaultDisplay === false ? false : true,
            data: fd
        };

        this.genLang = this.genLang.bind(this);

    }

    genLang(key = 'missing') {

        if (!this.props.trad) {
            return key;
        }

        if (!this.props.trad[key]) {
            return this.props.trad['wrongKey'][this.state.lang] + ' ' + key;
        }
        return this.props.trad[key][this.state.lang];
    }

    genLangNew(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevProps) {
        let props = this.props,
            obj = {};

        if (prevProps.formData && props.formData || props.version !== prevProps.version) {

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }

            if (props.formData[props.name + '_shareTime'] !== prevProps.formData[props.name + '_shareTime']) {
                obj['shareTime'] = props.formData[props.name + '_shareTime'] || '';
            }

            if (props.formData[props.name + '_askCourt'] !== prevProps.formData[props.name + '_askCourt']) {
                obj['askCourt'] = props.formData[props.name + '_askCourt'] || '';
            }

            if (props.formData[props.name + '_shareDescription'] !== prevProps.formData[props.name + '_shareDescription']) {
                obj['shareDescription'] = props.formData[props.name + '_shareDescription'] || '';
            }

            if (props.formData[props.name + '_otherPoints'] !== prevProps.formData[props.name + '_otherPoints']) {
                obj['otherPoints'] = props.formData[props.name + '_otherPoints'] || '';
            }

            if (props.display !== prevProps.display) {
                if (props.display === false && prevProps.display === true) {
                    obj.display = false;
                } else if (props.display === true && prevProps.display === false) {
                    obj.display = true;
                } else if (prevProps.display === undefined) {
                    obj.display = props.display;
                }
            }

            if (prevProps.formData !== props.formData) {
                obj.data = this.props.formData;
            }
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    render() {

        let props = this.props;
        let state = this.state;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        if (this.props.noCard) {
            return (
                <Col md={this.props.col || 12} style={style}>

                    {this.props.remove ? <span className="remove" style={{ zIndex: 9999 }}
                                               onClick={() => this.props.remove(this.props.name)}>
                        {cross}
                    </span> : ''}


                    <Row>
                        <TextAreaInput
                            lang={this.state.lang}
                            name={props.name + '_shareTime'}
                            col={12}
                            formData={props.formData}
                            put={this.props.put}
                            title={{
                                fr: 'Le partager du temps parental',
                                en: 'Share parenting time'
                            }}
                        />
                    </Row>

                    <Row>
                        <TextAreaInput
                            lang={this.state.lang}
                            name={props.name + '_askCourt'}
                            col={12}
                            formData={props.formData}
                            put={this.props.put}
                            title={{
                                fr: 'La demande',
                                en: 'Request'
                            }}
                        />
                    </Row>

                    <Row>
                        <ContentEditableTiny
                            lang={this.state.lang}
                            name={props.name + '_shareDescription'}
                            col={12}
                            formData={props.formData}
                            put={this.props.put}
                            title={{
                                fr: 'Description de la demande',
                                en: 'Description of the request'
                            }}
                        />
                    </Row>

                    <Row>
                        <ContentEditableTiny
                            lang={this.state.lang}
                            name={props.name + '_otherPoints'}
                            col={12}
                            formData={props.formData}
                            put={this.props.put}
                            title={{
                                fr: 'Autre points',
                                en: 'Other points'
                            }}
                        />
                    </Row>

                    <Row>
                        <Input
                            style={{ display: 'none' }}
                            type="text"
                            name={this.props.name + '_children'}
                            value={this.state.children}
                        />
                    </Row>

                </Col>
            );
        }

        return (
            <Col md={this.props.col || 12} style={style}>
                <Card
                    style={{ marginBottom: '5px' }}
                >
                    {this.props.remove ? <span className="remove" style={{ zIndex: 9999 }}
                                               onClick={() => this.props.remove(this.props.name)}>
                    {cross}
                </span> : ''}
                    <CardBody>
                        <CardText tag="div">

                            <Row>
                                <TextAreaInput
                                    lang={this.state.lang}
                                    name={props.name + '_shareTime'}
                                    col={12}
                                    formData={props.formData}
                                    put={this.props.put}
                                    title={{
                                        fr: 'Le partager du temps parental',
                                        en: 'Share parenting time'
                                    }}
                                />
                            </Row>

                            <Row>
                                <TextAreaInput
                                    lang={this.state.lang}
                                    name={props.name + '_askCourt'}
                                    col={12}
                                    formData={props.formData}
                                    put={this.props.put}
                                    title={{
                                        fr: 'La demande',
                                        en: 'Request'
                                    }}
                                />
                            </Row>

                            <Row>
                                <ContentEditableTiny
                                    lang={this.state.lang}
                                    name={props.name + '_shareDescription'}
                                    col={12}
                                    formData={props.formData}
                                    put={this.props.put}
                                    title={{
                                        fr: 'Description de la demande',
                                        en: 'Description of the request'
                                    }}
                                />
                            </Row>

                            <Row>
                                <ContentEditableTiny
                                    lang={this.state.lang}
                                    name={props.name + '_otherPoints'}
                                    col={12}
                                    formData={props.formData}
                                    put={this.props.put}
                                    title={{
                                        fr: 'Autre points',
                                        en: 'Other points'
                                    }}
                                />
                            </Row>

                            <Row>
                                <Input
                                    style={{ display: 'none' }}
                                    type="text"
                                    name={this.props.name + '_children'}
                                    value={this.state.children}
                                />
                            </Row>

                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}
