import {
    customersReducer,
    formsReducer,
    loadingReducer,
    menuReducer,
    resquestReducer,
    usersReducer
} from '../_reducers/root-reducer';

import { combineReducers, createStore } from 'redux';

import { form as defaultWill } from '../_formsDefault/will';

const reducers = combineReducers({
    forms: formsReducer,
    loading: loadingReducer,
    users: usersReducer,
    request: resquestReducer,
    customer: customersReducer,
    menu: menuReducer
});

const store = createStore(reducers, {
    forms: {
        will: {
            ...defaultWill
        }
    },
    request: {
        fields: {},
        documents: {},
        listAttach: []
    },
    users: {
        // ...local.users
    },
    loading: {
        loading: false,
        waitingList: {}
    },
    menu: [
        {
            name: {
                en: 'Home',
                fr: 'Accueil'
            },
            url: '/',
            title: 'Lien vers le dashbord',
            activeName: 'home'
        },
        {
            name: {
                en: 'Leads',
                fr: 'Leads'
            },
            url: '/leads',
            title: 'Lien vers les leads',
            activeName: 'leads'
        },
        {
            name: {
                en: 'Contact',
                fr: 'Contact'
            },
            url: '/contact',
            title: 'Lien vers les contacts',
            activeName: 'contact'
        }
    ],
    customer: {}
});

window.store = store;

//    const store = createStore(rootReducers, loadFromLocalStorage());

// listen for store changes and use saveToLocalStorage to
// save them to localStorage
// store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
