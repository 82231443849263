import { combine } from '@/composables/translation';

export const LICENSE_LIST = [
    'professional',
    'support',
    'execution'
] as const;

export type License = typeof LICENSE_LIST[number];

export const LICENSE_OPTIONS = LICENSE_LIST.map((license) => ({
    value: license,
    label: combine(`common.licenses.${license}`)
}));
