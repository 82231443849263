import React, { useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from '@/composables/translation';
import { instance } from '@/composables/api';
import { useValidation } from '@/composables/validation';
import { useError } from '@/composables/error';
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle
} from '@/components/ui/card';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { LockClosedIcon } from '@radix-ui/react-icons';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { toast } from 'react-toastify';
import { useOverrideConfig } from '@/composables/override';
import type { PutResetPasswordResponse } from '@/types/api/user';
import PasswordValidator from '@/components/PasswordValidator';
import { Alert, AlertDescription } from '@/components/ui/alert';

export function ResetPassword() {
    const {
        ct,
        t
    } = useTranslation('reset-password');
    // *NOTE: This is needed to retrieve the current password by reference
    // which only works with objects, otherwise the validation would use
    // an outdated value if the original password field is changed
    const pwdRef = useRef<string>('');
    const form = useForm({
        defaultValues: {
            password: '',
            password2: ''
        }
    });
    const { token } = useParams() as { token: string };
    const { required, passwordMatches } = useValidation();
    const { handleNetworkError } = useError();
    const { passwordSecure } = useValidation();
    const navigate = useNavigate();
    const override = useOverrideConfig();

    function handleSubmit({ password }: { password: string }) {
        const isSecure = passwordSecure(password);
        if (isSecure === true) {
            return instance.put<PutResetPasswordResponse>(
                override?.urls?.put_reset_password ?? '/v1/reset-user-password',
                {
                    new_password: password
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
                .then(() => {
                    navigate(override?.is_customer_portal ? '/portal/login' : '/login');
                    toast(ct('messages.success'), { type: 'success' });
                })
                .catch(handleNetworkError);
        }
        return toast.error(isSecure);
    }
    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
                <CardDescription>{t('description')}</CardDescription>
            </CardHeader>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <CardContent className="tw-flex tw-flex-col tw-gap-3">
                        <Alert variant="info">
                            <AlertDescription className='tw-text-xs'>
                                {t('password-prompt')}
                            </AlertDescription>
                        </Alert>
                        <FormField
                            name="password"
                            rules={{ validate: { required } }}
                            render={({ field }) =>
                                <FormItem>
                                    <FormLabel>{t('new-password')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="password"
                                            type="password"
                                            prependIcon={<LockClosedIcon/>}
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                pwdRef.current = e.target.value;
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                    <PasswordValidator password={field.value} />
                                </FormItem>
                            }
                        />
                        <FormField
                            name="password2"
                            rules={{
                                validate: {
                                    required,
                                    pwd: passwordMatches(pwdRef)
                                }
                            }}
                            render={({ field }) =>
                                <FormItem>
                                    <FormLabel>{t('confirm-new-password')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="password2"
                                            type="password"
                                            prependIcon={<LockClosedIcon/>}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />
                    </CardContent>
                    <CardFooter className="tw-flex-col tw-gap-2">
                        <Button variant="link" className="!tw-px-0 tw-self-start" asChild>
                            <Link to={`${override?.is_customer_portal ? '/portal' : ''}/login`}>
                                {ct('return-to-login')}
                            </Link>
                        </Button>
                        <ButtonSubmit className="tw-self-stretch">
                            {t('submit')}
                        </ButtonSubmit>
                    </CardFooter>
                </form>
            </Form>
        </>
    );
}
