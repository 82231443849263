import React, { type HTMLAttributes } from 'react';
import logoImage from '@/assets/img/logo.png';

interface Props extends HTMLAttributes<HTMLImageElement> {}

export function Logo(props: Props) {
    return (
        <img
            src={logoImage} alt="logo"
            {...props}
        />
    );
}
