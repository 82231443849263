import React, { type MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Tab, iconMap } from '@/types/tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Cross1Icon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useTranslation } from '@/composables/translation';

interface Props extends Tab {
    onDelete?: () => void;
}

export function PSJTab(props: Props) {
    const { to, ct } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const icon = iconMap[props.type] || '';
    const selected = props.url === (location.pathname + location.search);
    const onDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        props.onDelete?.();
    };
    return (
        <Badge
            key={props.url}
            onClick={() => navigate(props.url, { replace: true })}
            variant={selected ? 'default' : 'outline'}
            className={cn(
                'tw-cursor-pointer !tw-rounded-full tw-px-4 tw-flex tw-gap-3',
                !selected && 'tw-bg-slate-200 hover:tw-bg-slate-300'
            )}
        >
            {icon && <FontAwesomeIcon icon={icon} size="lg"/>}
            {to(props.label)}
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        type="button"
                        className={cn(
                            'tw-bg-transparent hover:tw-text-destructive hover:tw-bg-transparent',
                            '!tw-p-0'
                        )}
                        variant="ghost"
                        onClick={onDelete}
                    >
                        <Cross1Icon />
                    </Button>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                    {ct('close')}
                </TooltipContent>
            </Tooltip>
        </Badge>
    );
}
