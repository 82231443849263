import React, { type MutableRefObject } from 'react';

interface Props {
    index: number;
    rowIndex: number;
    debugDayStartUtc?: boolean;
    dashed?: boolean;
    cellRef?: MutableRefObject<HTMLDivElement | null>;
}

export function Cell({ rowIndex, index, debugDayStartUtc, dashed, cellRef }: Props) {
    return (
        <div
            ref={cellRef}
            className="tw-text-center tw-select-none tw-h-[30px]"
            style={{
                gridRow: rowIndex,
                gridColumn: index,
                borderTop: debugDayStartUtc ? '2px solid rgba(0, 0, 0, 1)' : undefined,
                borderBottom: `1px ${dashed ? 'dashed' : 'solid'} rgba(0, 0, 0, 0.3)`,
                borderRight: '1px solid rgba(0, 0, 0, 0.4)'
            }}
        >
            <div className="tw-h-full">
            </div>
        </div>
    );
}
