import React, { Component } from 'react';

import { Col, FormGroup } from 'reactstrap';

import _ from 'lodash';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const DEFAULT_STYLE = { textAlign: 'left' };

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class Informative extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lang: props.lang || FR,
            display: this.props.defaultDisplay === false ? false : true
        };

        if (!props.name) {
            console.error('!!! name is missing !!!');
        }

        if (!props.title) {
            console.error('!!! title is missing !!!');
        }

    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (props.display !== prevprops.display) {

            if (props.display === false && prevprops.display === true) {
                obj.display = false;
            } else if (props.display === true && prevprops.display === false) {
                obj.display = true;
            } else if (prevprops.display === undefined) {
                obj.display = props.display;
            }
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;

        let style = {};
        let styleTitle = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        styleTitle = [];
        styleTitle['color'] = (this.props.color) ?? '#0052BB';
        styleTitle['fontSize'] = (this.props.size) ?? '18pt';
        styleTitle['backgroundColor'] = '#efefff';
        styleTitle['borderLeft'] = '3px solid ' + ((this.props.color) ?? '#0052BB');
        styleTitle['padding'] = '7px 7px 7px 25px';
        styleTitle['borderRadius'] = '5px';

        return (
            <Col md={props.col || DEFAULT_SIZE} style={style} className="tw-mt-3">
                <FormGroup>
                    {(props.title != '' && (props.title.fr || props.title.en)) ?
                        <div style={styleTitle}>
                            {this.genLang(props.title || DEFAULT_TITLE, this.state.lang)}
                        </div> : <></>}

                    {props.text ? <h4>{this.genLang(props.text, this.state.lang)}</h4> : ''}

                    {props.description
                        ? <div className="form-desc" style={{ paddingLeft: '30px' }}
                               dangerouslySetInnerHTML={{ __html: this.genLang(props.description, this.state.lang) }}>
                        </div>
                        : <></>}
                </FormGroup>
            </Col>
        );
    }
}
