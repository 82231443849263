import React, { Component } from 'react';
import TimePicker from 'react-time-picker';

import { Col, FormGroup, Label, } from 'reactstrap';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import _ from 'lodash';

import { RequestThatField } from './RequestThatField';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const DEFAULT_STYLE = { textAlign: 'left' };

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

moment.locale('fr');
let m = moment().year();

let date = new Date().toISOString();
const years = [
    <option
        key={date + '_date' + m}
        value={m + 1}>{m + 1}</option>
];

for (let i = 100; m--; i--) {
    if (i === 100) {
        years.push(
            <option
                key={date + '_date' + m}
                value={m}
            >{m}</option>
        );
    } else {
        years.push(
            <option
                value={m}
                key={date + '_date' + m}
            >{m}</option>
        );
    }
}

export class Time extends Component {

    constructor(props) {
        super(props);

        let fd = this.props.formData;
        this.state = {
            value: fd[this.props.name] || this.props.default || '',
            lang: this.props.lang || FR,
            placeholder: this.props.placeholder ? this.genLang(props.placeholder, props.lang) : '',
            focused: false,
        };

        this.state.display = this.props.defaultDisplay !== false;
        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};
        let fd = this.props.formData || [];

        if (props.version !== prevprops.version) {
            if (fd && fd[this.props.name]) {
                obj['value'] = fd[this.props.name] || this.props.default || '';
            } else {
                obj['value'] = '';
            }

            this.props.put({
                params: {
                    key: this.props.name,
                    value: obj['value']
                }
            });
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            // debugger;
            if (fd && fd[this.props.name]) {
                obj['value'] = fd[this.props.name] || this.props.default || '';
            } else {
                obj['value'] = '';
            }

            this.props.put({
                params: {
                    key: this.props.name,
                    value: obj['value']
                }
            });
        }

        if (props.placeholder !== prevprops.placeholder) {
            obj['placeholder'] = props.placeholder;
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }
        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    onChange(e) {
        this.setState({
            value: e
        }, () => {
            if (!this.state.value) {
                this.props.remove({
                    params: {
                        key: this.props.name
                    }
                });
            } else {
                this.props.put({
                    params: {
                        key: this.props.name,
                        value: e
                    }
                });
            }
        });
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;
        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        return (
            <>
                <FormGroup>
                    <TimePicker
                        disableClock={this.props.disableClock}
                        id={this.props.name}
                        name={this.props.name}
                        style={{ zIndex: 99999999 }}
                        onChange={this.onChange}
                        value={this.state.value}
                    />
                </FormGroup>
            </>
        );

    }
}
