import React from 'react';
import { Row } from '@/components/ui/row';
import { NeoFormComponent } from '@/components/neoform/NeoFormComponent';
import { useTranslation } from '@/composables/translation';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';

export function UserEmail() {
    const { ct } = useTranslation();
    return (
        <>
            <Row>
                <NeoFormComponent
                    name="user"
                    componentName="User"
                    position={false}
                    address={false}
                    birthday={false}
                    noRelation={true}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="email"
                    componentName="InputText"
                    title={ct('email')}
                    titleType={NeoFormTitleType.LABEL}
                    last
                />
            </Row>
        </>
    );
}
