import React, { Component } from 'react';
import { UserEmail as User } from './UserEmail';

import { Button, FormGroup, Row } from 'reactstrap';
import _ from 'lodash';

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class UsersEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: this.props.counter || 0,
            lang: 'fr',
            users: [],
            display: this.props.defaultDisplay !== false
        };

        this.addHeirForm = this.addHeirForm.bind(this);
        this.removeHeirForm = this.removeHeirForm.bind(this);
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidMount() {
        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);

        for (let f in fd) {
            if (f.match(reg)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }

        let arr = [];
        for (let h in obj) {
            let key = this.props.name + '_' + h;
            arr.push(
                <Row key={key}>
                    <User
                        put={this.props.put}
                        lang={this.state.lang}
                        trad={this.props.trad}
                        formData={{ ...this.props.formData }}
                        remove={this.removeHeirForm}
                        name={key}
                        counter={h}
                        target={this.props.title}
                    />
                </Row>
            );
        }

        this.setState({ users: arr });

    }

    componentDidUpdate(prevprops) {
        let props = this.props,
            obj = {};

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    addHeirForm() {
        this.setState(state => {
            let counter = this.state.counter;
            let key = this.props.name + '_' + counter++;
            state.counter = counter;
            state.users.push(
                <div key={key}>
                    <User
                        put={this.props.put}
                        lang={this.state.lang}
                        trad={this.props.trad}
                        formData={{ ...this.props.formData }}
                        remove={this.removeHeirForm}
                        name={key}
                        counter={counter}
                        target={this.props.title}
                    />
                </div>
            );
            return state;
        });
    }

    removeHeirForm(id) {
        this.props.remove({ params: { key: id } });
        this.setState({
            users: _.reject(this.state.users, { key: id })
        });
    }

    render() {
        return (
            <>
                <FormGroup>
                    {this.state.users.map(form => (
                        <div key={form.key}>{form}</div>
                    ))}
                </FormGroup>
                {this.props.children}
                <FormGroup className="buttonHolder">
                    <Button
                        className="tw-mt-3"
                        block
                        outline
                        color="primary"
                        onClick={() => this.addHeirForm()}
                    >
                        {this.genLang(this.props.buttonText) || 'Ajouter'}
                    </Button>
                </FormGroup>
            </>
        );
    }
}
