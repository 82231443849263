import React, { useEffect } from 'react';
import { useNeoForm } from '@/composables/neoform';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { useIMask } from 'react-imask';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

interface Props {
    percentage?: boolean;
    amount?: boolean;
    scale?: number;
    min?: number;
    max?: number;
    noSeparator?: boolean;
    monospace?: boolean;
}

type InputNumberProps = NeoFormComponentProps & Props;

function getEndAdornment(props: InputNumberProps) {
    if (props.percentage) {
        return '%';
    }
    return null;
}

function getStartAdornment(props: InputNumberProps) {
    if (props.amount) {
        return '$';
    }
    return null;
}

function getMin(props: InputNumberProps) {
    if (props.amount != null) {
        return 0;
    }
    return props.min;
}

function getMax(props: InputNumberProps) {
    if (props.amount) { return 999999999999.99; }
    if (props.percentage) { return 100; }
    return props.max;
}

function getScale(props: InputNumberProps) {
    if (props.percentage) { return props.scale ?? 0; }
    if (props.amount) { return 2; }
    return props.scale;
}

function getMonospace(props: InputNumberProps) {
    return props.monospace ?? true;
}

export function InputNumber(props: InputNumberProps) {
    const { form, id, getValidationRules } = useNeoForm();
    const validate = getValidationRules(props);
    const start = getStartAdornment(props);
    const end = getEndAdornment(props);
    return (
        <FormField
            name={id}
            defaultValue={props.default}
            render={({ field }) => {
                const mask = useIMask(
                    {
                        mask: Number,
                        scale: getScale(props),
                        min: getMin(props),
                        max: getMax(props),
                        radix: '.',
                        thousandsSeparator: props.noSeparator ? '' : ' '
                    },
                    {
                        onAccept: (_, mask) => {
                            const value = mask.value.trim() === '' ? NaN : mask.typedValue;
                            field.onChange(value);
                            form?.set(id, value);
                        }
                    }
                );
                let value = field.value !== mask.typedValue ? field.value : mask.value;
                if (Number.isNaN(value)) {
                    value = '';
                }
                useEffect(() => {
                    mask.setTypedValue(field.value ?? NaN);
                }, [field.value]);
                return (
                    <FormItem>
                        <FormControl>
                            <Input
                                id={id}
                                disabled={props.readonly}
                                inputMode="numeric"
                                style={{
                                    position: 'relative',
                                    ...(getMonospace(props) && { fontFamily: 'monospace' }),
                                    bottom: 0.5
                                }}
                                prependIcon={
                                    start
                                        ? <span className="tw-px-1">{start}</span>
                                        : undefined
                                }
                                appendIcon={
                                    end
                                        ? <span className="tw-px-1">{end}</span>
                                        : undefined
                                }
                                {...field}
                                ref={ref => {
                                    field.ref(ref);
                                    form?.ref(id, ref);
                                    mask.ref.current = ref;
                                }}
                                value={value ?? ''}
                                onChange={e => mask.setValue(e.target.value)}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                );
            }}
            rules={{ validate }}
        />
    );
}
