import React, { useContext, useEffect } from 'react';
import { NeoForm, type NeoFormRenderProps } from '@/components/neoform/NeoForm';
import { NeoFormSection } from '@/components/neoform/NeoFormSection';
import { useNeoForm } from '@/composables/neoform';
import { Button } from '@/components/ui/button';
import { FolderContext } from '@/pages/psj/Folder';
import { type NeoFormData } from '@/types/neoform';
import { postAddNote } from '@/composables/api';
import { toast } from 'react-toastify';
import { useTranslation } from '@/composables/translation';
import { useError } from '@/composables/error';
import { useUserStore } from '@/store/user';

function QuickActionsForm({ sections }: NeoFormRenderProps) {
    const { ct } = useTranslation();
    const folder = useContext(FolderContext);
    const { form, hookForm } = useNeoForm();
    const isLoading = hookForm.formState.isSubmitting;
    useEffect(() => {
        if (form && folder?.input?.layout) {
            form.setLayout(folder.input.layout);
        }
        if (hookForm && folder?.input.data) {
            hookForm.reset(folder.input.data, { keepDefaultValues: true });
        }
    }, [folder?.input?.layout, folder?.input?.data]);
    return (
        <NeoFormSection
            collapseProps={{ innerTitle: true }}
            prefix={sections?.[0]?.header?.sectionPrefix ?? 'default'}
            rows={sections?.[0]?.rows ?? []}
            name={sections?.[0]?.header?.legend ?? 'Actions Rapides'}
            endSlot={
                <Button
                    type="submit"
                    className="tw-w-full tw-mt-2"
                    loading={isLoading}
                >
                    {ct('save')}
                </Button>
            }
        />
    );
}

export function QuickActions() {
    const folder = useContext(FolderContext);
    const { ct } = useTranslation();
    const { handleNetworkError } = useError();
    const { lang } = useUserStore(state => ({ lang: state.lang }));

    function handleSubmit(input: NeoFormData) {
        return postAddNote({
            case: {
                _id: folder?.case?._id ?? '',
                create_new_case: false,
                input
            },
            type: 'update',
            hidden: true,
            note: '',
            is_public: false,
            active: true,
            lang
        })
            .then((res) => {
                folder?.setNotes([res.data.note, ...(folder?.notes ?? [])]);
                folder?.setInput(input);
                toast(ct('messages.success'), { type: 'success' });
            })
            .catch(handleNetworkError);
    }

    if (folder?.loading) {
        return null;
    }
    return (
        <NeoForm
            render={QuickActionsForm}
            onSubmit={handleSubmit}
        />
    );
}
