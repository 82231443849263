import { DateTime } from 'luxon';

declare module 'luxon' {
    interface TSSettings {
        throwOnInvalid: true;
    }
}

DateTime.prototype.toJSON = function() {
    return this.isValid ? this.toUTC().toISO({ suppressMilliseconds: true }) : 'Invalid DateTime';
};
