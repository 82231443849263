import { redirect } from 'react-router-dom';

import { useUserStore } from '@/store/user';
import { useSettingsStore } from '@/store/settings';
import type { LoaderFunction } from '@remix-run/router/utils';
import { postLogout } from '@/composables/api';

export const LogoutLoader: LoaderFunction = () => {
    postLogout().finally();
    useUserStore.getState().logout();
    useSettingsStore.getState().reset();
    return redirect('/login');
};
