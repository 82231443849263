import React, { Component } from 'react';
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';

import { Postal } from './Postal';
import { Phone } from './Phone';

const BLOCK = 'block';
const NONE = 'NONE';

const QUEBEC = 'Québec';
const CANADA = 'Canada';
const COUNTRY = '_country';
const PROVINCE = '_province';

export class Address extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '';
        this.state = {
            lang: this.props.lang || 'fr',
            name: fd[this.props.name + '_name'] || '',
            firstname: fd[this.props.name + '_firstname'] || '',
            gender: this.props.formData[this.props.name + '_gender'],
            relation: this.props.formData[this.props.name + '_relation'],
            email: this.props.formData[this.props.name + '_email'],
            city: this.props.formData[this.props.name + '_city'],
            street: this.props.formData[this.props.name + '_street'],
            phone: this.props.formData[this.props.name + '_phone'],
            postal: this.props.formData[this.props.name + '_postal'],
            country: this.props.formData[this.props.name + COUNTRY] || CANADA,
            province: this.props.formData[this.props.name + PROVINCE] || QUEBEC,
        };

        // debugger;
        if (this.state.country === CANADA) {
            // debugger;
            this.props.put({
                params: {
                    key: this.props.name + COUNTRY,
                    value: CANADA
                }
            });
        }

        if (this.state.province === QUEBEC) {
            // debugger;
            this.props.put({
                params: {
                    key: this.props.name + PROVINCE,
                    value: QUEBEC
                }
            });
        }

        this.genLang = this.genLang.bind(this);
    }

    genLang(key = 'missing') {

        if (!this.props.trad) {
            return key;
        }

        if (!this.props.trad[key]) {
            return this.props.trad['wrongKey'][this.state.lang] + ' ' + key;
        }
        return this.props.trad[key][this.state.lang];
    }

    componentDidUpdate(prevProps) {
        if (prevProps && prevProps.formData && this.props && this.props.formData) {

            let props = this.props, obj = {};

            if (props.version !== prevProps.version) {
                obj = {
                    assistant: '',
                    gender: '',
                    relation: '',
                    email: '',
                    postal: '',
                    street: '',
                    city: '',
                    phone: '',
                    version: this.props.version
                };
            }

            if (prevProps.lang !== this.props.lang) {
                obj.lang = this.props.lang;
            }

            if (prevProps.trad !== this.props.trad) {
                obj.trad = this.props.trad;
            }

            if (props.formData[props.name + '_assistant'] !== prevProps.formData[props.name + '_assistant']) {
                obj['assistant'] = props.formData[props.name + '_assistant'] || '';
            }

            if (props.formData[props.name + '_gender'] !== prevProps.formData[props.name + '_gender']) {
                obj['gender'] = props.formData[props.name + '_gender'] || '';
            }

            if (props.formData[props.name + '_relation'] !== prevProps.formData[props.name + '_relation']) {
                obj['relation'] = props.formData[props.name + '_relation'] || '';
            }

            if (props.formData[props.name + '_email'] !== prevProps.formData[props.name + '_email']) {
                obj['email'] = props.formData[props.name + '_email'] || '';
            }

            if (props.formData[props.name + '_street'] !== prevProps.formData[props.name + '_street']) {
                obj['street'] = props.formData[props.name + '_street'] || '';
            }

            if (props.formData[props.name + '_phone'] !== prevProps.formData[props.name + '_phone']) {
                obj['phone'] = props.formData[props.name + '_phone'] || '';
            }

            if (props.formData[props.name + '_city'] !== prevProps.formData[props.name + '_city']) {
                obj['city'] = props.formData[props.name + '_city'] || '';
            }

            if (props.formData[props.name + '_postal'] !== prevProps.formData[props.name + '_postal']) {
                obj['postal'] = props.formData[props.name + '_postal'] || '';
            }

            if (props.formData[props.name + COUNTRY] !== prevProps.formData[props.name + COUNTRY]) {
                obj['country'] = props.formData[props.name + COUNTRY] || CANADA;
                if (obj.country === CANADA) {

                    this.props.put({
                        params: {
                            key: this.props.name + COUNTRY,
                            value: CANADA
                        }
                    });
                }
            }

            if (props.formData[props.name + PROVINCE] !== prevProps.formData[props.name + PROVINCE]) {
                obj['province'] = props.formData[props.name + PROVINCE] || QUEBEC;

                if (obj.province === QUEBEC) {

                    this.props.put({
                        params: {
                            key: this.props.name + PROVINCE,
                            value: QUEBEC
                        }
                    });
                }
            }

            if (!_.isEmpty(obj)) {
                this.setState(obj);
            }
        }

    }

    render() {

        let postal = this.props.formData[this.props.name + '_postal'];
        // console.log(this.props.name,"1=============>", this.state);
        // console.log(this.props.name,"2=============>", this.props);
        // console.log(this.props.name,"3=============>",this.state.city);
        // console.log(this.props.name,"4=============>", this.props.trad);
        // console.log(this.props.name,"5=============>", this.genLang('_street'));

        return (
            <>
                <FormGroup tag={'fieldset'}>
                    {this.props.label ? <Label><h5>{this.props.label}</h5></Label> : ''}
                    {this.props.text ? <FormText>{this.props.text}</FormText> : ''}
                    <Row>
                        <Col md={12} style={{ display: (this.props.noStreet) ? 'none' : 'block' }}>
                            <FormGroup>
                                <Label>{this.genLang('_street2')}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_street'}
                                    onChange={(e) => {
                                        this.props.put({
                                            params: {
                                                key: e.target.name,
                                                value: e.target.value,
                                                title: this.props.trad['_street2'],
                                            },
                                        });
                                        this.setState({ street: e.target.value });
                                    }}
                                    placeholder={this.genLang('_street2')}
                                    value={this.state.street || ''}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {!this.props.noCity ? <Col md={6}>
                            <FormGroup>
                                <Label>{this.genLang('_city')}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_city'}
                                    onChange={(e) => {
                                        this.props.put({
                                            params: {
                                                key: e.target.name,
                                                value: e.target.value,
                                                title: this.props.trad['_city'],
                                            },
                                        });
                                        this.setState({ city: e.target.value });
                                    }}
                                    placeholder={this.genLang('_city')}
                                    value={this.state.city || ''}
                                />
                            </FormGroup>
                        </Col> : ''}
                        <Col md={6} style={{ display: this.props.postal === false ? NONE : BLOCK }}>
                            {/* {postal} */}
                            <FormGroup>
                                <Label>{this.genLang('_postal')}</Label>
                                <Postal
                                    put={this.props.put}
                                    version={this.state.version}
                                    name={this.props.name + '_postal'}
                                    value={postal}
                                    title={this.props.trad['_postal']}
                                    // formData={this.props.formData}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{ display: this.props.country ? BLOCK : NONE }}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{this.genLang(PROVINCE)}</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + PROVINCE}
                                        onChange={(e) => {
                                            this.props.put({
                                                params: {
                                                    key: e.target.name,
                                                    value: e.target.value,
                                                    title: this.props.trad['_province'],
                                                },
                                            });
                                            this.setState({ province: e.target.value });
                                        }}
                                        // placeholder={"province"}
                                        value={this.state.province || ''}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{this.genLang(COUNTRY)}</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + COUNTRY}
                                        onChange={(e) => {
                                            this.props.put({
                                                params: {
                                                    key: e.target.name,
                                                    value: e.target.value,
                                                    title: this.props.trad['_country'],
                                                },
                                            });
                                            this.setState({ country: e.target.value });
                                        }}
                                        // placeholder={"Country"}
                                        value={this.state.country || ''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ display: this.props.contact === false ? NONE : BLOCK }}>

                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label>{this.genLang('_email')}</Label>
                                    <Input
                                        type="text"
                                        onChange={(e) => {
                                            this.props.put({
                                                params: {
                                                    key: e.target.name,
                                                    value: e.target.value,
                                                    title: this.props.trad['_email'],
                                                },
                                            });
                                            this.setState({ email: e.target.value });
                                        }}
                                        name={this.props.name + '_email'}
                                        value={this.state.email || ''}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label>{this.genLang('_phone')}</Label>
                                    <Phone
                                        formData={this.props.formData}
                                        put={this.props.put}
                                        title={this.props.trad['_phone']}
                                        // onChange={(e) => this.setState({phone:e.target.value})}
                                        name={this.props.name + '_phone'}
                                        value={this.state.phone || ''}
                                        noCol={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </FormGroup>
            </>
        );
    }
}
