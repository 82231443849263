import { type RefProxy } from 'pdfjs-dist/types/src/display/api';
import { type PDFDocumentProxy, type PDFPageProxy } from 'pdfjs-dist';
import * as pdfjs from 'pdfjs-dist';
import { PDFDocument } from 'pdf-lib';
import type { UploadedFile } from '@/components/ui/input-file';
import _ from 'lodash';
import { type Tuple } from '@/types/utils';
import { type SortableItem } from '@/types/sortable';

export const LETTER_ASPECT = 1.294;
const THUMBNAIL_WIDTH = 250;
const THUMBNAIL_HEIGHT = Math.floor(THUMBNAIL_WIDTH * LETTER_ASPECT);
const PREVIEW_WIDTH = 1250;
const PREVIEW_HEIGHT = Math.floor(PREVIEW_WIDTH * LETTER_ASPECT);

interface Base<T, S> { type: T; spec: S }
// These are types from the PDF 1.7 reference manual; Adobe
// Table 151 – Destination syntax
// (Coordinates origin is bottom left of page)
type XYZ = Base<'XYZ', [left: number, top: number, zoom: number]>;
type Fit = Base<'Fit', []>;
type FitH = Base<'FitH', [top: number]>;
type FitV = Base<'FitV', [left: number]>;
type FitR = Base<'FitR', [left: number, bottom: number, right: number, top: number]>;
type FitB = Base<'FitB', []>;
type FitBH = Base<'FitBH', [top: number]>;
type FitBV = Base<'FitBV', [left: number]>;

type PdfLocation = XYZ | Fit | FitH | FitV | FitR | FitB | FitBH | FitBV;

export interface PdfDestination {
    pageIndex: number;
    location: PdfLocation;
}

export interface PdfFile extends UploadedFile {
    color: string;
    proxy: PDFDocumentProxy;
    document: PDFDocument;
    pages: PdfPage[];
}

export type PdfItemType = 'page' | 'chapter';

export interface PdfItemBase {
    id: string;
    type: PdfItemType;
    file_id: string;
    thumbnail_url: string;
    preview_url?: string;
}

export interface PdfPage extends PdfItemBase {
    type: 'page';
    page_number: number;
}

export interface PdfChapter extends PdfItemBase {
    type: 'chapter';
    title: string;
    page_range: {
        start: number;
        end: number;
    };
    pages: Array<SortableItem<PdfPage>>;
    collapsed?: boolean;
}

export type PdfItem = PdfPage | PdfChapter;

export interface OutlineNode {
    title: string;
    bold: boolean;
    italic: boolean;
    color: Uint8ClampedArray;
    dest: string | any[] | null;
    destination?: PdfDestination | null;
    url: string | null;
    unsafeUrl?: string;
    newWindow?: boolean;
    count?: number;
    items: OutlineNode[];
}

function isRefProxy(obj: unknown): obj is RefProxy {
    return Boolean(typeof obj === 'object' && obj && 'gen' in obj && 'num' in obj);
}

async function getDestinationArray(
    doc: PDFDocumentProxy,
    dest: string | any[] | null
): Promise<any[] | null> {
    return typeof dest === 'string' ? await doc.getDestination(dest) : dest;
}

async function getDestinationRef(
    doc: PDFDocumentProxy,
    destArray: any[] | null
): Promise<RefProxy | null> {
    if (destArray && isRefProxy(destArray[0])) {
        return destArray[0];
    }
    return null;
}

const TYPE_LENGTHS = {
    XYZ: 3,
    Fit: 0,
    FitH: 1,
    FitV: 1,
    FitR: 4,
    FitB: 0,
    FitBH: 1,
    FitBV: 1
} as const;

function is<
    K extends keyof typeof TYPE_LENGTHS,
    N extends number = typeof TYPE_LENGTHS[K],
>(ty: K, obj: Base<string, number[]>): obj is Base<K, Tuple<number, N>> {
    const len = TYPE_LENGTHS[ty];
    return obj.type === ty && obj.spec.length === len;
}

function getLocation(type: string, spec: number[]): PdfLocation | null {
    const obj = { type, spec };
    if (is('XYZ', obj)) return obj;
    if (is('Fit', obj)) return obj;
    if (is('FitH', obj)) return obj;
    if (is('FitV', obj)) return obj;
    if (is('FitR', obj)) return obj;
    if (is('FitB', obj)) return obj;
    if (is('FitBH', obj)) return obj;
    if (is('FitBV', obj)) return obj;
    return null;
}

function isSpecLike(list: any[]): list is number[] {
    return list?.every(v => !isNaN(v));
}

export async function getPdfDestination(
    document: PDFDocumentProxy, destination: string | any[] | null
): Promise<PdfDestination | null> {
    const destArray = await getDestinationArray(document, destination);
    const destRef = await getDestinationRef(document, destArray);
    if (!destRef || !destArray) return null;

    const pageIndex = await document.getPageIndex(destRef);
    const name = destArray[1].name;
    const rest = destArray.slice(2);
    const location = isSpecLike(rest) ? getLocation(name, rest) : null;

    return { pageIndex, location: location ?? { type: 'Fit', spec: [] } };
}

async function traverseOutline(document: PDFDocumentProxy, node: OutlineNode) {
    node.destination = await getPdfDestination(document, node.dest);
    for (const item of node.items) {
        await traverseOutline(document, item);
    }
}

export async function getDocumentOutline(document: PDFDocumentProxy): Promise<OutlineNode[]> {
    const items = (await document.getOutline()) ?? [];
    for (const item of items) {
        await traverseOutline(document, item);
    }
    return items;
}

export async function loadDocument(data: Blob) {
    const [proxy, document] = await Promise.all([
        data.arrayBuffer().then(buffer => pdfjs.getDocument(buffer).promise),
        data.arrayBuffer().then(buffer => PDFDocument.load(buffer))
    ]);
    return {
        proxy,
        document
    };
}

export async function renderPageThumbnail(
    page: PDFPageProxy,
    options?: { width: number; height: number }
): Promise<string> {
    const vp = page.getViewport({ scale: 1 });
    const canvas = document.createElement('canvas');
    canvas.width = options?.width ?? THUMBNAIL_WIDTH;
    canvas.height = options?.height ?? THUMBNAIL_HEIGHT;
    const scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
    return await page.render({
        canvasContext: canvas.getContext('2d') as CanvasRenderingContext2D,
        viewport: page.getViewport({ scale })
    }).promise
        .catch((err) => console.trace(err))
        .then(() => canvas.toDataURL('image/png'));
}

export async function generatePdfPages(file: PdfFile) {
    const thumbnails: PdfPage[] = [];
    for (let pageNumber = 1; pageNumber <= file.proxy.numPages; pageNumber++) {
        const page = await file.proxy.getPage(pageNumber);
        const thumbnail_url = await renderPageThumbnail(page);
        thumbnails.push({
            id: `${file.id}_p${pageNumber}`,
            type: 'page',
            file_id: file.id,
            page_number: pageNumber,
            thumbnail_url
        });
    }
    return thumbnails;
}

export async function generatePdfChapters(file: PdfFile): Promise<[PdfChapter[], PdfPage[]]> {
    const items = await getDocumentOutline(file.proxy);
    const pages = await generatePdfPages(file);
    let pageIndex = -1;
    let firstIteration = true;
    const chapters: PdfChapter[] = [];
    const setLastChapterEndPage = (pageNumber: number) => {
        const last = _.last(chapters);
        if (last) {
            last.thumbnail_url = pages[last.page_range.start - 1].thumbnail_url;
            last.page_range.end = pageNumber;
            last.pages = pages.slice(last.page_range.start - 1, pageNumber);
        }
    };
    for (const item of items) {
        if (!item.destination) { continue; }
        if (pageIndex !== item.destination.pageIndex) {
            if (firstIteration) {
                firstIteration = false;
                if (item.destination.pageIndex !== 0) {
                    chapters.push({
                        id: `${file.id}_c${chapters.length}`,
                        type: 'chapter',
                        file_id: file.id,
                        title: 'Prologue',
                        page_range: {
                            start: 1,
                            end: 1
                        },
                        thumbnail_url: '',
                        pages: []
                    });
                }
            }
            setLastChapterEndPage(item.destination.pageIndex);
            chapters.push({
                id: `${file.id}_c${chapters.length}`,
                type: 'chapter',
                file_id: file.id,
                title: item.title,
                page_range: {
                    start: item.destination.pageIndex + 1,
                    end: item.destination.pageIndex + 1
                },
                thumbnail_url: '',
                pages: []
            });
            pageIndex = item.destination.pageIndex;
        }
    }
    if (items.length <= 0) {
        chapters.push({
            id: `${file.id}_c${chapters.length}`,
            type: 'chapter',
            file_id: file.id,
            title: file.filename,
            page_range: {
                start: 1,
                end: 1
            },
            thumbnail_url: '',
            pages: []
        });
    }
    setLastChapterEndPage(file.proxy.numPages);
    return [chapters, pages];
}

export async function generatePdfFromPages(files: PdfFile[], pages: PdfPage[]) {
    const result = await PDFDocument.create();
    for (const page of pages) {
        const fileIndex = files.findIndex(f => f.id === page.file_id);
        const doc = files[fileIndex].document;
        const [copiedPage] = await result.copyPages(doc, [page.page_number - 1]);
        result.addPage(copiedPage);
    }
    return result;
}

const PDF_PREVIEWS: Record<string, Promise<string>> = {};

export async function generatePreviewForPage(file: PdfFile, page: PdfPage) {
    if (file.id !== page.file_id) {
        throw new Error('page is not part of specified file');
    }
    if (PDF_PREVIEWS[page.id] != null) {
        return await PDF_PREVIEWS[page.id];
    }
    const pageProxy = await file.proxy.getPage(page.page_number);
    const promise = renderPageThumbnail(pageProxy, { width: PREVIEW_WIDTH, height: PREVIEW_HEIGHT });
    PDF_PREVIEWS[page.id] = promise;
    return await promise;
}
