import React from 'react';
import { useTranslation } from '@/composables/translation';
import { useUserStore } from '@/store/user';
import { useNavigate } from 'react-router-dom';
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';

export function Unauthorized() {
    const { t, ct } = useTranslation('unauthorized');
    const logout = useUserStore(state => state.logout);
    const navigate = useNavigate();
    const handleClick = () => {
        logout();
        navigate('/login');
    };
    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent className="tw-flex tw-flex-col tw-gap-3">
                <CardDescription>{t('description')}</CardDescription>
                <CardDescription>{ct('contact-admin')}</CardDescription>
            </CardContent>
            <CardFooter>
                <Button className="!tw-p-0 !tw-h-4" variant="link" onClick={handleClick}>
                    {t('back-to-login')}
                </Button>
            </CardFooter>
        </>
    );
}
