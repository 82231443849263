import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';

const spinnerVariants = cva(
    'tw-relative tw-overflow-hidden',
    {
        variants: {
            size: {
                default: 'tw-size-5 [--spinner-border-width:3px]',
                sm: 'tw-size-4 [--spinner-border-width:3px]',
                lg: 'tw-size-8 [--spinner-border-width:4px]'
            }
        },
        defaultVariants: {
            size: 'default'
        }
    }
);

interface Props extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof spinnerVariants> {
}

export function Spinner({ size, ...props }: Props) {
    return (
        <span {...props} className={cn(
            spinnerVariants({ size }),
            props.className
        )}>
            <span className={cn(
                'tw-absolute tw-inset-0 tw-rounded-full tw-opacity-15 tw-grayscale',
                'tw-border-[length:var(--spinner-border-width)]',
                'tw-border-[color:currentColor]'
            )} />
            <span className={cn(
                'tw-absolute tw-overflow-hidden',
                'tw-top-[-100em] tw-left-[-100em]',
                'tw-pt-[100em] tw-pl-[100em]',
                'tw-bottom-1/2 tw-right-1/2 tw-origin-bottom-right',
                'tw-animate-spinner',
                'before:tw-block before:tw-border-[length:var(--spinner-border-width)]',
                'before:tw-border-[color:currentColor]',
                'before:tw-size-[200%] before:tw-box-border before:tw-rounded-full',
                'before:tw-content-[\'\'] before:tw-animate-spinner-counter'
            )} />
        </span>
    );
}
