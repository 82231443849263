import React, { useMemo } from 'react';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { type View } from '@/types/api/views';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import { CheckboxCell, DateCell, TranslateCell } from '@/components/ui/cells';
import { UpdateViewDialog, VIEW_TYPES } from '@/components/psj/views/UpdateViewDialog';
import { getViews, postCreateView, putUpdateView } from '@/composables/api';
import { OpenInNewWindowIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Link } from 'react-router-dom';
import { useViews } from '@/composables/views';

export function Views() {
    const { t, to } = useTranslation('settings.views');
    const schema = useMemo<CrudSchema<View>>(() => [
        {
            id: '_id',
            type: CrudInputType.TEXT,
            name: t('table.id'),
            readonly: true,
            columnDef: {
                id: '_id',
                accessorKey: '_id',
                header: t('table.id')
            }
        },
        {
            id: 'active',
            type: CrudInputType.CHECKBOX,
            name: 'Actif',
            columnDef: {
                id: 'active',
                header: 'Actif',
                accessorKey: 'active',
                cell: CheckboxCell
            }
        },
        {
            id: 'title',
            type: CrudInputType.TEXT,
            name: t('table.title'),
            columnDef: {
                id: 'title',
                accessorKey: 'title',
                header: t('table.title'),
                cell: TranslateCell({ to })
            }
        },
        {
            id: 'definition.url',
            type: CrudInputType.TEXT,
            name: t('table.type'),
            columnDef: {
                id: 'definition.url',
                accessorKey: 'definition.url',
                header: t('table.type'),
                cell: ({ cell }) => to(VIEW_TYPES.find(t => t.url === cell.getValue<string>())?.label)
            }
        },
        {
            id: 'updated_at',
            type: CrudInputType.TEXT,
            name: t('table.updated_at'),
            columnDef: {
                id: 'updated_at',
                header: t('table.updated_at'),
                accessorKey: 'updated_at',
                cell: DateCell
            }
        },
        {
            id: 'created_at',
            type: CrudInputType.TEXT,
            name: t('table.created_at'),
            columnDef: {
                id: 'created_at',
                header: t('table.created_at'),
                accessorKey: 'created_at',
                cell: DateCell
            }
        }
    ], []);
    const { buildViewUrl } = useViews();
    return (
        <Card>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CrudTable<View, '_id'>
                    idKey="_id"
                    schema={schema}
                    initialState={{ columnVisibility: { _id: false } }}
                    actions={({ row }) => <>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button variant="ghost" size="icon" asChild>
                                    <Link to={buildViewUrl(row.original)} target="_blank">
                                        <OpenInNewWindowIcon />
                                    </Link>
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                Ouvrir dans un nouvel onglet
                            </TooltipContent>
                        </Tooltip>
                    </>}
                    onRead={() => getViews().then(res => res.data)}
                    onUpdate={(value) => putUpdateView(value).then()}
                    onCreate={(value) => postCreateView({ ...value, name: to(value.title) }).then()}
                    dialogComponent={UpdateViewDialog}
                />
            </CardContent>
        </Card>
    );
}
