import React, { useContext } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import {
    faEye,
    faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import { MenuButton } from '../../MenuButton';
import { FolderContext } from '@/pages/psj/Folder';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { DotsVerticalIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';

interface Props {
    search?: string;
    onChangeSearch?: React.Dispatch<string>;
    onChangeVisibility?: React.Dispatch<Record<string, boolean>>;
}

export function HeaderNotes(props: Props) {
    const context = useContext(FolderContext);
    const { ct, t } = useTranslation('psj.notes');

    function toggleNotesVisibility(visible: boolean) {
        const notes = (context?.notes ?? []).map((i) => i._id);
        props.onChangeVisibility?.(notes.reduce(
            (obj, id) => ({ ...obj, [id]: visible }),
            {}
        ));
    }

    return (
        <Card>
            <CardContent className="!tw-p-3 tw-flex tw-justify-between tw-items-center">
                <h6 className="tw-mb-0 tw-text-primary tw-font-semibold tw-text-md">
                    {t('title')}
                </h6>
                <Input
                    className="[&>input]:!tw-rounded-full"
                    prependIcon={<MagnifyingGlassIcon />}
                    prependBorder={false}
                    value={props.search ?? ''}
                    onChange={(e) => props.onChangeSearch?.(e.target.value)}
                    placeholder={t('search-hint')}
                />
                <MenuButton
                    id="account"
                    tooltip={ct('more-actions')}
                    renderButton={(props) => (
                        <Button
                            {...props}
                            variant="ghost" size="icon"
                            className="!tw-rounded-full"
                        >
                            <DotsVerticalIcon className="tw-size-5" />
                        </Button>
                    )}
                    options={[
                        {
                            value: 'showAllNotes',
                            label: t('show-all'),
                            icon: faEye,
                            onClick: () => toggleNotesVisibility(true)
                        },
                        {
                            value: 'hideAllNotes',
                            label: t('hide-all'),
                            icon: faEyeSlash,
                            onClick: () => toggleNotesVisibility(false)
                        }
                    ]}
                />
            </CardContent>
        </Card>
    );
}
