import { useTranslation } from '@/composables/translation';

interface Options {
    shortenLabels?: boolean;
}

export function useLanguage(options?: Options) {
    const { ct } = useTranslation();
    return {
        options: [
            {
                value: 'fr',
                label: options?.shortenLabels ? 'FR' : ct('lang.fr')
            },
            {
                value: 'en',
                label: options?.shortenLabels ? 'EN' : ct('lang.en')
            }
        ]
    };
}
