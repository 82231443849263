import * as React from 'react';

import { cn } from '@/lib/utils';

const Table = React.forwardRef<
    HTMLTableElement,
    React.HTMLAttributes<HTMLTableElement>
>(({
    className,
    ...props
}, ref) => (
    <div className="tw-relative tw-w-full tw-overflow-auto">
        <table
            ref={ref}
            className={cn('tw-w-full tw-caption-bottom tw-text-sm', className)}
            {...props}
        />
    </div>
));
Table.displayName = 'Table';

const TableHeader = React.forwardRef<
    HTMLTableSectionElement,
    React.HTMLAttributes<HTMLTableSectionElement>
>(({
    className,
    ...props
}, ref) => (
    <thead ref={ref} className={cn('[&_tr]:tw-border-b', className)} {...props} />
));
TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<
    HTMLTableSectionElement,
    React.HTMLAttributes<HTMLTableSectionElement>
>(({
    className,
    ...props
}, ref) => (
    <tbody
        ref={ref}
        className={cn('[&_tr:last-child]:tw-border-0', className)}
        {...props}
    />
));
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<
    HTMLTableSectionElement,
    React.HTMLAttributes<HTMLTableSectionElement>
>(({
    className,
    ...props
}, ref) => (
    <tfoot
        ref={ref}
        className={cn(
            'tw-border-t tw-bg-muted/50 tw-font-medium [&>tr]:last:tw-border-b-0',
            className
        )}
        {...props}
    />
));
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<
    HTMLTableRowElement,
    React.HTMLAttributes<HTMLTableRowElement>
>(({
    className,
    ...props
}, ref) => (
    <tr
        ref={ref}
        className={cn(
            'tw-border-b tw-transition-colors hover:tw-bg-muted/50 data-[state=selected]:tw-bg-muted',
            className
        )}
        {...props}
    />
));
TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<
    HTMLTableCellElement,
    React.ThHTMLAttributes<HTMLTableCellElement>
>(({
    className,
    ...props
}, ref) => (
    <th
        ref={ref}
        className={cn(
            'tw-h-10 tw-px-2 tw-text-left tw-align-middle tw-font-medium tw-text-muted-foreground',
            className
        )}
        {...props}
    />
));
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<
    HTMLTableCellElement,
    React.TdHTMLAttributes<HTMLTableCellElement>
>(({
    className,
    ...props
}, ref) => (
    <td
        ref={ref}
        className={cn(
            'tw-p-2 tw-align-middle [&:has([role=checkbox])]:tw-pr-0 [&>[role=checkbox]]:tw-translate-y-[2px]',
            className
        )}
        {...props}
    />
));
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<
    HTMLTableCaptionElement,
    React.HTMLAttributes<HTMLTableCaptionElement>
>(({
    className,
    ...props
}, ref) => (
    <caption
        ref={ref}
        className={cn('tw-mt-4 tw-text-sm tw-text-muted-foreground', className)}
        {...props}
    />
));
TableCaption.displayName = 'TableCaption';

export {
    Table,
    TableHeader,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    TableCell,
    TableCaption
};
