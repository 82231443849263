import React, { useRef, useState } from 'react';
import { Input, type InputProps } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { CheckIcon, ClipboardCopyIcon } from '@radix-ui/react-icons';
import { writeClipboardText } from '@/composables/clipboard';
import { assignRef } from '@/composables/utils';
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import { useTranslation } from '@/composables/translation';

interface Props extends InputProps {
    resetDelayMs?: number;
}

export const InputUrl = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { ct } = useTranslation();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [copied, setCopied] = useState(false);
    const resetDelay = props.resetDelayMs ?? 3000;
    const [timeout, setTimeout] = useState(0);
    function handleCopy() {
        writeClipboardText(String(props.value) ?? '').then(() => {
            inputRef.current?.select();
            setCopied(true);
            window.clearTimeout(timeout);
            setTimeout(window.setTimeout(() => setCopied(false), resetDelay));
        });
    }
    return (
        <div className="tw-relative">
            <Input
                ref={(r) => {
                    assignRef(ref, r);
                    assignRef(inputRef, r);
                }}
                {...props}
                className={cn('tw-pr-12', props.className)}
            />
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        className={cn(
                            'tw-absolute tw-right-[1px] tw-inset-y-[1px] !tw-h-[34px]',
                            '!tw-pl-3 !tw-pr-2.5 tw-rounded-l-none tw-border-l tw-z-[1]',
                            copied && 'tw-text-success hover:tw-text-success'
                        )}
                        type="button"
                        variant="ghost"
                        disabled={props.disabled}
                        onClick={handleCopy}
                    >
                        {copied ? <CheckIcon/> : <ClipboardCopyIcon/>}
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {copied ? ct('copied-link') : ct('copy')}
                </TooltipContent>
            </Tooltip>
        </div>
    );
});
InputUrl.displayName = 'InputUrl';
