import React, { Component } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import Select from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

import InputMask from 'react-input-mask';

import { TextAreaInput } from './TextAreaInput';
import { User } from './User';
import { Address } from './Address';

const DEFAULT_STYLE = {};

const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;
const fr = 'fr';
const NONE = 'none', BLOCK = 'block';
const lang = fr;
const options = [
    {
        value: '',
        label: '-'
    },
    {
        value: 'fils',
        label: 'fils/fille'
    },
    {
        value: 'conjoint',
        label: 'Conjoint'
    },
    {
        value: 'conjoint-de-fait',
        label: 'Conjoint.e de fait'
    },
    {
        value: 'petit-fils',
        label: 'petit-fils/ petite-fille'
    },
    {
        value: 'frère',
        label: 'frère/soeur'
    },
    {
        value: 'père',
        label: 'père/mère'
    },
    {
        value: 'neveu',
        label: 'neveu/nièce'
    },
    {
        value: 'petit-neveu',
        label: 'petit-neveu/petite-nièce'
    },
    {
        value: 'oncle',
        label: 'oncle/tante'
    },
    {
        value: 'cousin',
        label: 'cousin/cousine'
    },
    {
        value: 'cousin germain',
        label: 'cousin/cousine germain.e'
    },
    {
        value: 'cousin issu de germain',
        label: 'cousin issu de germain'
    },
    {
        value: 'grand-père',
        label: 'grand-père/grand-mère'
    },
    {
        value: 'beau-frère',
        label: 'belle soeur/beau frère'
    },
    {
        value: 'beau-père',
        label: 'beau-père/belle-mère'
    },
    {
        value: 'beau-fils',
        label: 'beau-fils/belle-fille'
    },
    {
        value: 'autre',
        label: 'Autre'
    }
];

const optionsEN = [
    {
        value: '',
        label: '-'
    },
    {
        value: 'fils',
        label: 'Son/daughter'
    },
    {
        value: 'conjoint',
        label: 'Spouse'
    },
    {
        value: 'petit-fils',
        label: 'Grandson/granddaughter'
    },
    {
        value: 'frère',
        label: 'Brother/sister'
    },
    {
        value: 'père',
        label: 'Father/mother'
    },
    {
        value: 'neveu',
        label: 'Nephew/niece'
    },
    {
        value: 'petit-neveu',
        label: 'Little nephew/ little niece'
    },
    {
        value: 'oncle',
        label: 'Uncle/Aunt'
    },
    {
        value: 'cousin',
        label: 'Cousin'
    },
    {
        value: 'cousin germain',
        label: 'First cousin'
    },
    {
        value: 'cousin issu de germain',
        label: 'Second cousin'
    },
    {
        value: 'grand-père',
        label: 'Grandfather/Grandmother'
    },
    {
        value: 'beau-frère',
        label: 'Brother-in-law/sister-in-law'
    },
    {
        value: 'beau-père',
        label: 'Stepfather/Stepmother'
    },
    {
        value: 'beau-fils',
        label: 'Stepson/Stepdaughter'
    },
    {
        value: 'autre',
        label: 'Other'
    }
];

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class Good extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '';
        this.state = {
            lang: this.props.lang || 'fr',
            name: fd[this.props.name + '_name'] || '',
            firstname: fd[this.props.name + '_firstname'] || '',
            gender: this.props.formData[this.props.name + '_gender'],
            relation: this.props.formData[this.props.name + '_relation'],
            display: this.props.defaultDisplay === false ? false : true
        };

        this.genLang = this.genLang.bind(this);

    }

    genLang(key = 'missing') {

        if (!this.props.trad) {
            return key;
        }

        if (!this.props.trad[key]) {
            return this.props.trad['wrongKey'][this.state.lang] + ' ' + key;
        }
        return this.props.trad[key][this.state.lang];
    }

    genLangNew(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevProps) {
        let props = this.props,
            obj = {};

        if (prevProps.formData && props.formData || props.version !== prevProps.version) {

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }

            if (props.formData[props.name + '_name'] !== prevProps.formData[props.name + '_name']) {
                obj['name'] = props.formData[props.name + '_name'] || '';
            }

            if (props.formData[props.name + '_firstname'] !== prevProps.formData[props.name + '_firstname']) {
                obj['firstname'] = props.formData[props.name + '_firstname'] || '';
            }

            if (props.formData[props.name + '_assistant'] !== prevProps.formData[props.name + '_assistant']) {
                obj['assistant'] = props.formData[props.name + '_assistant'] || '';
            }

            if (props.formData[props.name + '_gender'] !== prevProps.formData[props.name + '_gender']) {
                obj['gender'] = props.formData[props.name + '_gender'] || '';
            }

            if (props.formData[props.name + '_relation'] !== prevProps.formData[props.name + '_relation']) {
                obj['relation'] = props.formData[props.name + '_relation'] || '';
            }

            if (props.formData[props.name + '_percent'] !== prevProps.formData[props.name + '_percent']) {
                obj['name'] = props.formData[props.name + '_percent'] || '';
            }

            if (props.display !== prevProps.display) {

                if (props.display === false && prevProps.display === true) {
                    obj.display = false;
                } else if (props.display === true && prevProps.display === false) {
                    obj.display = true;
                } else if (prevProps.display === undefined) {
                    obj.display = props.display;
                }
            }
        }

        if (props.percent !== prevProps.percent) {

            obj.percent = props.percent;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    isBuilding(value) {
        switch (value) {
        case '4':
        case '17':
        case '18':
        case '22':
            return { display: BLOCK };
        default:
            return { display: NONE };
        }
    }

    isUnderResponsability(value) {
        switch (value) {
        case '3':
        case '4':
        case '16':
        case '17':
        case '18':
            return { display: BLOCK };
        default:
            return { display: NONE };
        }
    }

    needDebtField(value) {
        switch (value) {
        case '16':
        case '4':
        case '18':
        case '21':
            return { display: BLOCK };
        default:
            return { display: NONE };
        }

    }

    isFurniture(value) {
        switch (value) {
        case '18':
            return { display: BLOCK };
        default:
            return { display: NONE };
        }
    }

    isCharity(type) {
        if (type === '19') {
            return true;
        } else {
            return false;
        }
    }

    isSum(type) {
        if (type === '1') {
            return true;
        } else {
            return false;
        }
    }

    isCompany(type) {
        if (type === '21') {
            return true;
        } else {
            return false;
        }
    }

    isAction(type) {
        if (type === '23') {
            return true;
        } else {
            return false;
        }
    }

    render() {

        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let gl = this.genLang;
        if (true || this.props.noCard) {
            return (
                <Col md={this.props.col || 12} style={style}>
                    {this.props.remove ? <span className="remove" onClick={() => this.props.remove(this.props.name)}>
                        {cross}
                    </span> : ''}
                    <Row>
                        {this.props.remove ? <span className="remove" style={{ zIndex: 9999 }} onClick={() => {
                            this.props.remove(this.props.name);
                        }}>
                        {cross}
                    </span> : ''}
                        <Col md={8}>
                            <FormGroup>
                                <Label>{gl('bequestCategory')}</Label>
                                <Input
                                    type="select"
                                    name={this.props.name + '_legacyType'}
                                    onChange={(e) => {
                                        this.setState({
                                            type: e.target.value
                                        }, console.log(this.state));
                                    }}
                                    defaultValue={this.props.formData[this.props.name + '_legacyType'] || '-1'}>
                                    <option value="-1">-</option>
                                    <option value="1">{gl('sum')}</option>
                                    <option value="16">{'Véhicule spécifique'}</option>
                                    <option value="4">{'Résidence principale'}</option>
                                    <option value="18">{'Immeuble avec mobilier'}</option>
                                    <option value="12">{'RÉÉÉ'}</option>
                                    <option value="13">{gl('celi')}</option>
                                    <option value="14">{gl('registeredRetirement')}</option>
                                    <option value="19">{'Organisme de bienfaisance'}</option>
                                    <option value="20">{'Remise de dettes'}</option>
                                    <option value="21">{'Une entreprise'}</option>
                                    <option value="22">{'Droit d\'usage'}</option>
                                    <option value="23">{'Action'}</option>
                                    <option value="24">{'Legs des droits du patrimoine à mon conjoint'}</option>
                                    <option value="999">{'Autre'}</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div
                        style={{ display: !this.isCharity(this.state.type) && this.state.type !== '24' ? BLOCK : NONE }}>

                        <User
                            put={this.props.put}
                            lang={this.state.lang}
                            trad={this.props.trad}
                            formData={{ ...this.props.formData }}
                            remove={false}
                            name={this.props.name + '_primary'}
                            target={'À qui ira ce bien'}
                        />
                    </div>
                    <div style={{
                        display:
                            this.state.type === '999' ? BLOCK : NONE
                    }}
                    >
                        <Row>
                            <Col md={12}>
                                <em><strong>Je lègue à titre particulier à Prénom, NOM (relation),
                                    [DESCRIPTION]</strong></em>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ display: this.isCompany(this.state.type) ? BLOCK : NONE }}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Nom de l'entreprise</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_company_name'}
                                        defaultValue={this.props.formData[this.props.name + '_company_name'] || ''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ display: this.isSum(this.state.type) ? BLOCK : NONE }}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Somme</Label>
                                    <Input
                                        type="number"
                                        name={this.props.name + '_sum'}
                                        defaultValue={this.props.formData[this.props.name + '_sum'] || ''}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Devise</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_devise'}
                                        defaultValue={this.props.formData[this.props.name + '_devise'] || ''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </div>
                    <div style={{ display: this.isCharity(this.state.type) ? BLOCK : NONE }}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>organisme</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_charity_name'}
                                        defaultValue={this.props.formData[this.props.name + '_charity_name'] || ''}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Somme</Label>
                                    <Input
                                        type="number"
                                        name={this.props.name + '_charity_sum'}
                                        defaultValue={this.props.formData[this.props.name + '_charity_sum'] || ''}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </div>

                    <Row>
                        <Col md={12} style={{ display: (this.state.type !== '24') ? BLOCK : NONE }}>
                            <FormGroup>
                                <Label>{this.state.type === '20' ? 'Désignation de la dette' : 'Description'}</Label>
                                <Input
                                    type="textarea"
                                    name={this.props.name + '_legacyDescription'}
                                    defaultValue={this.props.formData[this.props.name + '_legacyDescription'] || ''}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div
                        style={{ display: (this.state.type === '17' || this.state.type === '18' || this.state.type === '22') ? BLOCK : NONE }}>

                        <Row className="m-t-10">
                            <Address
                                lang={this.state.lang}
                                trad={this.props.trad}
                                formData={{ ...this.props.formData }}
                                col={12}
                                label={false}
                                name={this.props.name}
                                text={false}
                                put={this.props.put}
                            />
                        </Row>
                    </div>
                    <div style={{ display: this.isAction(this.state.type) ? BLOCK : NONE }}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{'No de  REQ ou nom de la compagnie'}</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_action_req'}
                                        value={this.state.req || ''}
                                        onChange={
                                            (e) => this.setState({ req: e.target.value })
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col style={{
                                        textAlign: 'left',
                                        fontSize: '16px'
                                    }}>
                                        <FormGroup>
                                            {/* // prévoir un champ dans formulaire client et avocat, état civil - Marié, Divorcé, Célibataire, Veuf(ve), conjoint de fait */}
                                            <Label for={this.props.name + '_action_type'}><em style={{
                                                textAlign: 'left',
                                                fontSize: '12px'
                                            }}>Vous léguez:</em></Label>
                                            <div>
                                                <input
                                                    type="radio"
                                                    value="0"
                                                    id={this.props.name + '_action_all'}
                                                    label={'Toutes les actions'}
                                                    checked={this.state.actionType === '0'}
                                                    name={this.props.name + '_action_type'}
                                                    onChange={(e) => {
                                                        this.setState({ actionType: e.target.value });
                                                    }} inline />
                                                <input
                                                    type="radio"
                                                    value="1"
                                                    id={this.props.name + '_action_particular'}
                                                    label={'Que les actions d\'un certain type'}
                                                    checked={this.state.actionType === '1'}
                                                    name={this.props.name + '_action_type'} onChange={(e) => {
                                                    this.setState({ actionType: e.target.value });
                                                }} inline />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ display: (this.state.actionType === '1') ? BLOCK : NONE }}>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{'Catégorie d\'action'}</Label>
                                    <Input
                                        type="select"
                                        name={this.props.name + '_action_category'}
                                        value={this.state.actionCategory || ''}
                                        onChange={
                                            (e) => this.setState({ actionCategory: e.target.value })
                                        }
                                    >
                                        <option value="NA"> -</option>
                                        <option value="ord">Ordinaire</option>
                                        <option value="a">a</option>
                                        <option value="b">b</option>
                                        <option value="c">c</option>
                                        <option value="d">d</option>
                                        <option value="e">e</option>
                                        <option value="f">f</option>
                                        <option value="g">g</option>
                                        <option value="h">h</option>
                                        <option value="i">i</option>
                                        <option value="j">j</option>
                                        <option value="k">k</option>
                                        <option value="l">l</option>
                                        <option value="m">m</option>
                                        <option value="n">n</option>
                                        <option value="o">o</option>
                                        <option value="p">p</option>
                                        <option value="q">q</option>
                                        <option value="r">r</option>
                                        <option value="s">s</option>
                                        <option value="t">t</option>
                                        <option value="u">u</option>
                                        <option value="v">v</option>
                                        <option value="w">w</option>
                                        <option value="x">x</option>
                                        <option value="y">y</option>
                                        <option value="z">z</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup className="tw-mt-3">
                                    <div>
                                        <input
                                            label={' Convention d\'actionaire'}
                                            checked={this.state.compliant === 'true'}
                                            type="checkbox"
                                            name={this.props.name + '_action_compliant'}
                                            id={this.props.name + '_action_compliant'}
                                            onChange={(e) => this.setState({ compliant: e.target.checked.toString() })}
                                            inline
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <div style={{ display: (this.state.compliant === 'true') ? BLOCK : NONE }}>
                            <legend
                                className="tw-mt-3 m-b-20 p-l-15 p-r-15"
                                style={{ marginBottom: '15px' }}
                            >
                                <span>{'Information sur le notaire'}</span>
                            </legend>
                            <User
                                put={this.props.put}
                                lang={this.state.lang}
                                trad={this.props.trad}
                                formData={{ ...this.props.formData }}
                                remove={false}
                                name={this.props.name + '_notary'}
                                target={'notaire'}
                            />
                            <Row>
                                <Col md={6}>
                                    <Label>Numéro de ses minutes</Label>
                                    <Input
                                        type="text"
                                        name={this.props.name + '_action_minute'}
                                        value={this.state.minute || ''}
                                        onChange={
                                            (e) => this.setState({ minute: e.target.value })
                                        }
                                    />
                                </Col>
                                <Col md={6}>

                                    <FormGroup tag={'fieldset'}>
                                        <Label>Date</Label>
                                        <InputMask
                                            mask="99/99/9999"
                                            maskChar=" "
                                            placeholder={'mm/jj/aaaa'}
                                            className="form-control"
                                            onChange={(e) => this.setState({ minuteDate: e.target.value })}
                                            value={this.state.minuteDate || ''}
                                            name={this.props.name + '_action_date'}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div style={this.isUnderResponsability(this.state.type)}>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col style={{
                                        textAlign: 'left',
                                        fontSize: '16px'
                                    }}>
                                        <FormGroup>
                                            {/* // prévoir un champ dans formulaire client et avocat, état civil - Marié, Divorcé, Célibataire, Veuf(ve), conjoint de fait */}
                                            <Label for={this.props.name + '_maintainResponsible'}><em style={{
                                                textAlign: 'left',
                                                fontSize: '12px'
                                            }}>Qui assumera les frais de maintenance/entretien?</em></Label>
                                            <div>
                                                <input
                                                    type="radio"
                                                    value="beneficiary"
                                                    id={this.props.name + '_beneficiary'}
                                                    label={'Le légataire'}
                                                    checked={this.state.beneficiary === 'beneficiary' || this.state.beneficiary === 'true'}
                                                    name={this.props.name + '_maintainResponsible'}
                                                    onChange={(e) => {
                                                        this.setState({ beneficiary: e.target.value });
                                                    }} inline />
                                                <input
                                                    type="radio"
                                                    value="succession"
                                                    id={this.props.name + '_succession'}
                                                    label={'La succession'}
                                                    checked={this.state.beneficiary === 'succession'}
                                                    name={this.props.name + '_maintainResponsible'} onChange={(e) => {
                                                    this.setState({ beneficiary: e.target.value });
                                                }} inline />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div style={this.needDebtField(this.state.type)}>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col style={{
                                        textAlign: 'left',
                                        fontSize: '16px'
                                    }}>
                                        <FormGroup>
                                            {/* // prévoir un champ dans formulaire client et avocat, état civil - Marié, Divorcé, Célibataire, Veuf(ve), conjoint de fait */}
                                            <Label for={this.props.name + '_debtResponsible'}><em style={{
                                                textAlign: 'left',
                                                fontSize: '12px'
                                            }}>Qui assumera la dette reliée au bien?</em></Label>
                                            <div>
                                                <input
                                                    type="radio"
                                                    value="legatee"
                                                    id={this.props.name + '_legatee'}
                                                    label={'Le légataire'}
                                                    checked={this.state.debt === 'legatee' || this.state.debt === 'true'}
                                                    name={this.props.name + '_debtResponsible'}
                                                    onChange={(e) => {
                                                        this.setState({ debt: e.target.value });
                                                    }} inline />
                                                <input
                                                    type="radio"
                                                    value="heir"
                                                    id={this.props.name + '_heir'}
                                                    label={'La succession'}
                                                    checked={this.state.debt === 'heir'}
                                                    name={this.props.name + '_debtResponsible'} onChange={(e) => {
                                                    this.setState({ debt: e.target.value });
                                                }} inline />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <div style={this.isFurniture(this.state.type)}>
                        <Row>
                            <Col md={12}>
                                <FormGroup className="tw-mt-3">
                                    <div>
                                        <input
                                            label={'Exclure du mobilier les œuvres d’art et de collection'}
                                            checked={this.state.excludeArt === 'true'}
                                            type="checkbox"
                                            name={this.props.name + '_excludeArt'}
                                            id={this.props.name + '_excludeArt'}
                                            onChange={(e) => this.setState({ excludeArt: e.target.checked.toString() })}
                                            inline />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col md={12}>
                            <FormGroup tag="fieldset" className="!tw-mb-0">
                                <legend><span>{this.genLangNew(this.props.target, this.state.lang)}</span></legend>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_name')}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_name'}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    value={this.state.name}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_firstname')}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_firstname'}
                                    onChange={(e) => this.setState({ firstname: e.target.value })}
                                    value={this.state.firstname}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2} style={{ display: !this.props.noRelation ? BLOCK : NONE }}>
                            <FormGroup>
                                <Label>{lang === fr ? 'Relation' : 'Relationship'}</Label>
                                <Select
                                    style={{ zIndex: 1000 }}
                                    id={this.props.name + '_relation'}
                                    name={this.props.name + '_relation'}
                                    defaultValue={_.find(options, (e) => {
                                        return e.value === this.state.relation;
                                    })}
                                    onChange={(e) => {
                                        this.setState({ relation: e.value });
                                        this.props.put({
                                            params: {
                                                key: this.props.name + '_relation',
                                                value: e.value
                                            }
                                        });
                                    }}
                                    options={lang === fr ? options : optionsEN}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label>{this.genLang('_title')}</Label>
                                <Input
                                    type="select"
                                    name={this.props.name + '_gender'}
                                    onChange={(e) => this.setState({ gender: e.target.value })}
                                    value={this.state.gender || '-'}>
                                    <option value="-">Autre</option>
                                    <option value="M">{this.genLang('_sir')}</option>
                                    <option value="F">{this.genLang('_madam')}</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <TextAreaInput
                                lang={this.state.lang}
                                version={this.state.version}
                                key={this.props.name}
                                put={this.props.put}
                                formData={{ ...this.props.formData }}
                                title={{ fr: 'description du bien' }}
                            />
                        </Col>
                    </Row>
                    {this.props.children}
                </Col>
            );
        }
    }
}
