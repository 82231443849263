import React from 'react';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import useHtml from '@/composables/html';

interface Props {
    html: TranslationObject;
}

export function HTML(props: NeoFormComponentProps & Props) {
    const { to } = useTranslation();
    const sanitizedHtml = useHtml(to(props.html));
    return (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }}></div>
    );
}
