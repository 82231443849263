import React, { Component } from 'react';

import { Input } from 'reactstrap';

import _ from 'lodash';

const STRING = 'string';
const OBJECT = 'object';

export class Hidden extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value || this.props.default
        };

        this.props.put(
            {
                params: {
                    key: props.forcedName || props.name,
                    value: this.genLang(this.state.value)
                }
            }
        );
    }

    componentDidUpdate(prevprops) {
        const props = this.props;
        const obj = {};

        if (props.version !== prevprops.version || props.lang !== prevprops.lang) {
            obj.value = this.props.value || this.props.default;
            this.props.put({
                params: {
                    key: this.props.name,
                    value: this.genLang(this.props.value)
                }
            });
        }

        if (props.lang !== prevprops.lang) {
            obj.lang = props.lang;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text) {
        const lang = (this.state.lang === '' || this.state.lang === null || this.state.lang === undefined) ? 'fr' : this.state.lang;

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        const props = this.props;

        return (
            <Input
                // {...props}
                key={props.name}
                value={this.genLang(this.state.value)}
                type={'hidden'}
                name={props.forcedName || props.name}
            />
        );
    }
}
