import React from 'react';
import { type TranslationObject, useTranslation } from '@/composables/translation';

import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader, DialogTitle
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

interface Props {
    open: boolean;
    title?: string | TranslationObject;
    message: string | TranslationObject;
    onOpenChange?: React.Dispatch<boolean>;
    children?: React.ReactNode;
}

export function InfoMessage(props: Props) {
    const { to, ct } = useTranslation();
    const title = to(props.title);
    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            {props.children}
            <DialogContent>
                <DialogHeader>
                    {title && <DialogTitle>{title}</DialogTitle>}
                    <DialogDescription>{to(props.message)}</DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button variant="secondary">
                            {ct('ok')}
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
