import type { LoaderFunction } from '@remix-run/router/utils';
import { loginPsj } from '@/composables/api';
import { useUserStore } from '@/store/user';
import { redirect } from 'react-router-dom';

export const LoginPsjLoader: LoaderFunction = async({ request, params }) => {
    const token = params.token ?? '';
    const url = new URL(request.url);
    const returnUrl = url.searchParams.get('redirect');
    try {
        const res = await loginPsj(token);
        useUserStore.getState().setToken(res.data.token, res.data.user.logo);
        return redirect(returnUrl ?? '/');
    } catch (err) {
        return redirect('/');
    }
};
