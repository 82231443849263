import { type FactoryOpts } from 'imask';

export const POSTAL_CODE_MASK: FactoryOpts = {
    mask: '@0_ 0_0',
    definitions: {
        '@': /[ABCEGHJ-NPRSTVXY]/,
        _: /[ABCEGHJ-NPRSTV-Z]/
    },
    prepareChar: (c) => c.toUpperCase()
};

export const PHONE_NUMBER_MASK: FactoryOpts = {
    mask: '(000) 000-0000'
};
