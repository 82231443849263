import React from 'react';
import 'react-quill/dist/quill.snow.css';

import { Col } from 'reactstrap';

import _ from 'lodash';

const STRING = 'string';
const OBJECT = 'object';

const DEFAULT_SIZE = 12;
const DEFAULT_STYLE = {};

export class HTML extends React.Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData;

        this.state = {
            display: this.props.defaultDisplay === false ? false : true,
            lang: this.props.lang ? this.props.lang : 'fr',
        };
    }

    componentDidUpdate(prevprops) {
        let props = this.props,
            state = this.state;
        let obj = {};

        if (props.display !== prevprops.display) {

            if (props.display === false && prevprops.display === true) {
                obj.display = false;
            } else if (props.display === true && prevprops.display === false) {
                obj.display = true;
            } else if (prevprops.display === undefined) {
                obj.display = props.display;
            }
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text) {
        const lang = (this.state.lang === '' || this.state.lang === null || this.state.lang === undefined) ? 'fr' : this.state.lang;

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            return (<></>);
        } else {
            delete style.display;
        }

        return (
            <div dangerouslySetInnerHTML={{ __html: this.genLang(this.props.html) }}>
            </div>
        );
    }
}
