import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';
import _ from 'lodash';

const DEFAULT_TYPE = 'number';
const DEFAULT_STYLE = {};

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class NumberInput extends Component {
    constructor(props) {
        super(props);
        let fd = this.props.formData;
        this.state = {
            value: fd && fd[this.props.name] ? fd[this.props.name] : 0,
            display: this.props.defaultDisplay !== false,
        };
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            obj['value'] = props.formData[props.name] || props.default || [];
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            obj['value'] = props.formData[props.name];
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }
        if (props.lang !== prevprops.lang)
            obj['lang'] = props.lang;

        if (!_.isEmpty(obj))
            this.setState(obj);
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;
        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;
        if (this.props.requestFields === false) {
            request = this.props.requestable === true;
        } else {
            request = this.props.requestable !== false;
        }

        return (
            <>
                <FormGroup>
                    <Input
                        tabIndex={props.tabindex}
                        value={this.state.value || ''}
                        required={this.props.required || undefined}
                        type={DEFAULT_TYPE}
                        name={props.name}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (this.props.put) {
                                this.props.put({ params: { key: props.name, value } });
                            }
                            this.setState({ value });
                        }}
                    />
                </FormGroup>
            </>
        );
    }
}
