import React from 'react';
import { Outlet } from 'react-router-dom';
import { type LoaderFunction } from '@remix-run/router/utils';
import { useUserStore } from '@/store/user';
import { redirectWithParams } from '@/composables/utils';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { Logo } from '@/components/ui/logo';

interface Props {
    children?: React.ReactNode;
}

export const PublicLayoutLoader: LoaderFunction = ({ request }) => {
    const url = new URL(request.url);
    const user = useUserStore.getState().user;
    if (user?.type === 'admin') {
        return redirectWithParams('/', url);
    } else if (user?.type === 'customer') {
        return redirectWithParams('/portal', url);
    }
    return null;
};

export function PublicLayout(props: Props) {
    return (
        <main className="container-sm tw-flex-1 tw-p-5 tw-flex tw-flex-col tw-justify-center">
            <Card className={cn(
                'tw-mx-auto tw-shadow-2xl tw-relative',
                'tw-w-full tw-max-w-lg tw-z-10 tw-my-[58px]'
            )}>
                {props.children
                    ? props.children
                    : <Outlet/>
                }
                <Logo
                    className="tw-absolute tw-inset-x-0 tw-top-[-58px] tw-h-[50px] tw-mx-auto tw-z-10"
                />
            </Card>
            <div
                className="tw-absolute !tw-inset-0 !tw-bottom-[50%] tw-z-0"
                style={{ background: 'linear-gradient(#0052BB 0, #003271 100%)' }}
            />
        </main>
    );
}
