import React from 'react';
import { cn, col } from '@/lib/utils';

interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function Row(props: RowProps) {
    return (
        <div
            {...props}
            className={cn(
                'tw-flex tw-flex-wrap tw-gap-y-6 tw-mx-[-0.5em] empty:tw-hidden',
                props.className
            )}
        >
            {props.children}
        </div>
    );
}

interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
    col?: string | number;
}

export function Col(props: ColProps) {
    // NOTE: Do not remove this comment, tailwind only includes
    // classes that appear as is in source code
    // md:tw-w-1/12 md:tw-w-2/12 md:tw-w-3/12
    // md:tw-w-4/12 md:tw-w-5/12 md:tw-w-6/12
    // md:tw-w-7/12 md:tw-w-8/12 md:tw-w-9/12
    // md:tw-w-10/12 md:tw-w-11/12 md:tw-w-12/12
    return (
        <div {...props} className={cn(
            `tw-px-2 tw-w-full md:tw-w-${col(props.col)}/12`,
            props.className
        )}>
            {props.children}
        </div>
    );
}
