import React, { useState } from 'react';
import { Button, type ButtonProps } from '@/components/ui/button';

interface Props extends ButtonProps {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
}

const ButtonAsync = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const [loading, setLoading] = useState(false);
    async function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setLoading(true);
        try {
            await props.onClick?.(event);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }
    return (
        <Button
            ref={ref}
            {...props}
            loading={!!props.loading || loading}
            onClick={handleClick}
        >
            {props.children}
        </Button>
    );
});
ButtonAsync.displayName = 'ButtonAsync';

export { ButtonAsync };
