export const SET_MENU = 'menu:set';

export function setMenu(menu) {
    return {
        type: SET_MENU,
        params: {
            ...menu
        }
    };
}
