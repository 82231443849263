import React, { Component } from 'react';
import _ from 'lodash';
import { Badge, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import axios from 'axios';

import { JSONForm } from '@/components/old/JSONForm';
import { AlertNotification } from '@/components/old/FormComponents/AlertNotification';
import { SearchTicket } from '@/components/old/FormComponents/SearchTicket';
import { connect } from 'react-redux';
import rootsActions from '@/store/old/_actions/root-actions';
import '@/assets/style/old/index.css';
import { clone, delay, parseJWT } from '@/composables/utils';
import { generateDocument, postFormData } from '@/composables/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faUsers } from '@fortawesome/free-solid-svg-icons';
import { DocumentStatus } from '@/types/document';
import { Separator } from '@/components/ui/separator';
import { getFormUrl } from '@/composables/neoform';
import { cn } from '@/lib/utils';

const REQUESTING = '_requesting';
const FORCE_FORM = false;
const EXTRA_LARGE = 9;
const SMALL = 3;
const MAX = 12;
const BLOCK = 'block';
const NONE = 'none';

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const IDENTITY = 'NEODOC_';
const DEFAULT_FORM = {
    header: {
        formTitle: '',
        identity: '',
        formType: '',
        formDescription: '',
        customStyle: '',
        body: []
    }
};

let currentDoc = '';

const NGROK = import.meta.env.VITE_API_URL;

class FormContainerComponent extends Component {
    constructor(props) {
        super(props);

        this.form = React.createRef();

        this.ticket_id = window.location;
        this.toggleLang = this.toggleLang.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.callback = this.callback.bind(this);
        this.init = this.init.bind(this);

        const token = parseJWT(this.props.token);
        let ticket;
        if (token.scope.ticket) ticket = token.scope.ticket;

        this.state = {
            formCounter: -1,
            formName: this.props.formName,
            lang: this.props.lang || 'fr',
            trad: this.props.trad,
            previewSize: SMALL,
            formSize: EXTRA_LARGE,
            data: [],
            formLayout: DEFAULT_FORM,
            display: BLOCK,
            ticketId: this.props.searchId || ticket,
            links: [],
            allFieldsNotes: [],
            allFieldsNotesInfo: {},
            bareFormNameFromRequest: '',
            pdf: '',
            pdfName: '',
            task_id: this.props.task_id || '',
            showPreview: false,
            previewLink: '',
            previewCount: 0,
            pdfOptions: []
        };

        document.body.classList.remove('AVENS');
        document.body.classList.remove('NEOLEGAL');
        document.body.classList.remove('BESSETTE');
        document.body.classList.add(this.props.identity);
    }

    componentDidMount() {
        this.props.toggleLoading();
        if (this.props.public) {
            this.props.logout();
            this.props.setToken(this.props.token);
            axios
                .get(NGROK + '/v1/form-public/' + this.props.token, {
                    params: {},
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((status) => {
                    this.props.toggleLoading();

                    let formJson = { header: {} };
                    if (status && status.data && status.data.json) {
                        formJson = status.data.json;
                    }
                    if (status?.data?.lang) this.setState({ lang: status.data.lang });
                    if (status?.data?.data?.task_id) {
                        this.setState({ task_id: status?.data?.data?.task_id });
                    }
                    if (status?.data?.data?.ticket_id) {
                        this.setState({ ticketId: status?.data?.data?.ticket_id });
                    }
                    this.setState({
                        formLayout: formJson, ...formJson.header
                    });
                }, (error) => this.errorHandler(error));
        } else {
            let reset = new URLSearchParams(this.props.location.search).get('reset');
            axios
                .get(NGROK + '/v1/form-data-by-id/' + this.props.task_id, {
                    params: {
                        form_name: this.props.formName,
                        auth_id: localStorage.getItem('user_id'),
                        reset: reset
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((status) => {
                    this.props.toggleLoading();
                    this.props.initRequest();
                    let formJson = { header: {} };
                    let formNamefromget = '';
                    if (status && status.data && status.data.result.json) {
                        formJson = status.data.result.json;
                        if (status?.data?.result?.name) {
                            formNamefromget = status.data.result.name;
                        }
                    }

                    if (status.data.result.ticket_id) {
                        this.setState({ ticketId: status.data.result.ticket_id });
                    }

                    this.setState({
                        pdfOptions: clone(formJson.header?.pdfOptions || []),
                        formLayout: formJson,
                        ...formJson.header,
                        bareFormNameFromRequest: formNamefromget
                    });
                })
                .catch((e) => {
                    if (e && e.response && e.response.status) {
                        if (e.response?.status === 401 || e.response?.status === 403) {
                            this.props.toggleLoading();
                            this.props.history.push('/logout');
                        }
                    }
                });
        }
        this.componentDidUpdate();
    }

    removeActionnaires = (key) => {
        const newFormData = { ...this.state.data };
        Object.keys(newFormData).map((i) => {
            if (typeof i === 'string' && !i.includes('_formJuridique') && i.includes(key)) {
                delete newFormData[i];
            }
        });
        this.setState({ data: newFormData });
    };

    componentDidUpdate() {
        this.handleSumActionVote();
        let update = false;
        this.state.pdfOptions.forEach(o => {
            const doc = this.props.documentStore?.documents.find(d => d.task_id === this.props.task_id);
            const meta = doc?.document_types[o.name];
            const status = meta?.status || DocumentStatus.COMPLETED;
            const url = meta?.url || undefined;
            if (status !== o.status || url !== o.url) {
                update = true;
            }
            o.status = status;
            o.url = url;
        });
        if (update) {
            const data = {};
            const option = this.state.pdfOptions.find(o => o.name === this.props.formName);
            if (option?.status === DocumentStatus.COMPLETED && option?.url) {
                data.previewLink = option.url;
                data.previewCount = this.state.previewCount + 1;
            }
            this.setState({
                ...data,
                pdfOptions: [...this.state.pdfOptions]
            });
        }
    }

    handleSumActionVote = () => {
        const newFormData = { ...this.state.data };
        const keys = Object.keys(this.state.data).filter((k) => k?.includes('_numberActionVote'));

        const oldSums = keys.map((k) => this.state.data[k]);

        const newSums = keys.map((k) => {
            k = k.replace('numberActionVote', '');
            const regex = new RegExp(`^${k}categoryActions_(.*)_actionVotantes$`);
            const sum = Object.keys(this.state.data)
                .filter((k) => regex.exec(k))
                .reduce((sum, k) => sum + Number(this.state.data[k]), 0);
            return (newFormData[`${k}numberActionVote`] = sum);
        });
        if (oldSums.length !== newSums.length || oldSums.some((s, i) => s !== newSums[i])) {
            this.setState({ data: newFormData });
            newSums.forEach((s, i) => {
                this.props.put({
                    params: {
                        key: keys[i],
                        value: s
                    }
                });
            });
        }
    };

    handleSetLayoutHeader = (formJson) => {
        this.setState({
            formLayout: formJson,
            ...formJson.header
        });
    };

    errorHandler(error) {
        let redirect;
        this.props.toggleLoading();
        if (error) {
            if (error.response && error.response.status === 401) {
                redirect = true;
            }
            this.props.userUpdate({
                type: this.props.rootsActions.LOGIN,
                params: {
                    logged: false
                },
                status: 200
            });
        }

        if (redirect) {
            localStorage.clear();
            this.setState({
                redirect: true
            });
        }
    }

    toggleLang() {
        this.setState({
            lang: this.state.lang === 'fr' ? 'en' : 'fr'
        }, () => this.props.put({
            params: {
                key: 'lang',
                value: this.state.lang
            }
        }));
    }

    init() {
        let fd = this.props.formData;
        this.props.init();
        this.setState({
            alert: '',
            lang: this.state.lang || 'fr',
            organs: this.props.formData['organs_don'],
            customText: fd['custom_text'],
            heirCataForm: [],
            tutorForm: [],
            liquidatorBackupForm: []
        });
    }

    submitForm() {
        const fd = this.props.formData;
        if (fd['']) delete fd[''];
        this.props.remove({ params: { key: 'allFiles' } });
        const header = this.state.formLayout.header || {};
        return postFormData(this.props.public, this.state.task_id, {
            ...fd,
            form: this.state.formType,
            formPrettyName: header.formTitle,
            client_id: this.state.client_id
        });
    }

    handleSubmit() {
        this.props.toggleLoading();
        return this.submitForm()
            .then(() => {
                if (this.props.public) {
                    this.props.history.push(this.buildRedirectLink());
                } else {
                    this.props.removeDelete();
                    toast('Formulaire mis à jour', { type: 'success' });
                }
            })
            .catch((err) => {
                toast('Échec lors de l\'envoi du formulaire', { type: 'error' });
                console.error(err);
            })
            .finally(() => {
                this.props.toggleLoading();
            });
    }

    async handleDocumentAction(docType) {
        this.props.toggleLoading();
        try {
            await this.submitForm();
            const res = await generateDocument(this.state.task_id, docType);
            if (Array.isArray(res.data?.data) && res.data.data.length === 1) {
                await delay(500);
                this.setState({
                    previewLink: res.data.data[0],
                    previewCount: this.state.previewCount + 1
                });
                toast('Formulaire mis à jour', { type: 'success' });
            } else if (res.data?.data?.length > 1) {
                this.props.documentStore.addDocument(this.state.task_id, docType);
                this.forceUpdate();
            } else {
                toast('Échec lors de la génération du document', { type: 'error' });
            }
        } catch (err) {
            this.props.error.handleNetworkError(err);
        } finally {
            this.props.toggleLoading();
        }
    }

    buildRedirectLink() {
        const link = '/merci';
        // if (this.state.lang) {
        //     link += '/' + this.state.lang;
        // } else {
        //     link += '/fr';
        // }
        // if (this.state.ticketId) {
        //     link += '/' + this.state.ticketId;
        // }
        // if (this.state.formType) {
        //     link += '/' + this.state.formType;
        // } else if (this.state.formName) {
        //     link += '/' + this.state.formName;
        // }
        return link;
    }

    genLangLegacy(key = 'missing') {
        if (!this.props.trad[key]) key = 'wrongKey';
        return this.props.trad[key][this.state.lang];
    }

    callback(status) {
        if (status && status.data && status.data.result) {
            let result = status.data.result;

            if (result.permission) {
                this.props.initRequest();
                let pdf = '';
                if (result.doc && result.doc.result) pdf = result.doc.result;

                let params = {
                    display: BLOCK, // alert: "Le ticket #" + this.state.ticketId + " a été généré",
                    // alertColor: "info",
                    formCounter: this.state.formCounter + 1,
                    pdf: pdf || '',
                    client_id: result.client_id,
                    inputFields: result.inputFields || [],
                    categories: {},
                    dataFiles: result.dataFiles || []
                };

                if (result.data) {
                    this.props.post({ params: result ? result.data : [] });
                    params.data = result.data;
                    let pdfName = '', data = result.data;

                    if (data.form && data.form.match(/penal/i)) {
                        pdfName = data.form === 'penal-other' ? 'PENAL' : 'PNC';
                        pdfName += '-';

                        if (pdfName.match(/PNC/)) {
                            pdfName += data.information_title;
                        } else {
                            pdfName += data.information_constat;
                        }

                        params.pdfName = pdfName;
                    }
                }
                if (result.AUTH_ID && this.props.public) {
                    localStorage.setItem('user_id', result.AUTH_ID);
                }
                // debugger;
                if (result.ticket) params.ticketId = result.ticket;

                if (result !== null && result.categories !== undefined) {
                    params.categories = result.categories;
                }

                if (result !== null && result.allFieldsNotes !== undefined) {
                    params.allFieldsNotes = result.allFieldsNotes;
                }
                if (result !== null && result.allFieldsNotesInfo !== undefined) {
                    params.allFieldsNotesInfo = result.allFieldsNotesInfo;
                }

                this.setState(params, () => {
                });
            } else if (FORCE_FORM !== true) {
                this.setState({
                    display: NONE,
                    data: [],
                    allFieldsNotes: [],
                    allFieldsNotesInfo: {},
                    alert: result.message || 'permission refusée',
                    alertColor: 'warning',
                    formCounter: this.state.formCounter + 1,
                    client_id: null,
                    inputFields: result.inputFields || []
                });
            }
        }
    }

    onSelectChange = (e, name) => {
        this.handleSumActionVote();
        if (!e || !e?.length) {
            e = [];
        }
        const newFormData = { ...this.state.data };
        const numberActionVote = name.replace(/_categoryActions$/, '_numberActionVote');
        if (!newFormData[numberActionVote]) {
            newFormData[numberActionVote] = '0';
        }
        let difference = this.state?.data[name]?.filter((x) => !e.find((obj) => obj.value === x));
        if (difference?.length) {
            difference.forEach((i) => {
                Object.keys(newFormData).forEach((k) => {
                    if (k.includes(`${name}_${i}`)) {
                        delete newFormData[k];
                    }
                });
                this.props.remove({
                    params: {
                        key: `${name}_${i}`
                    }
                });
            });
        }
        newFormData[name] = _.map(e, (f) => f.value);
        this.setState({ data: newFormData });
        this.props.put({
            params: {
                key: name,
                value: _.map(e, (f) => f.value)
            }
        });
    };

    onChange(e) {
        if (e.target) {
            let obj = {};
            if (!e.target.name || e.target.name.match(REQUESTING)) return;

            switch (e.target.type) {
            case 'checkbox':
                this.props.put({
                    params: {
                        key: e.target.name,
                        value: e.target.checked ? 'true' : 'false'
                    }
                });
                obj[e.target.name] = e.target.checked ? 'true' : 'false';

                this.setState({ data: { ...this.state.data, ...obj } });
                break;
            case 'select':
            case 'radio':
                this.props.put({
                    params: {
                        key: e.target.name,
                        value: e.target.value
                    }
                });
                obj[e.target.name] = e.target.value;

                this.setState({ data: { ...this.state.data, ...obj } });
                break;
            case 'text':
            case 'number':
            case 'email':
            default:
                this.props.put({
                    params: {
                        key: e.target.name,
                        value: e.target.value
                    }
                });
                obj[e.target.name] = e.target.value;

                if (e.target.name == 'information_typeDoc') {
                    obj['comment_emailTemplate'] = this.state.formTitle.emailTemplate[e.target.value];
                }
                this.setState({ data: { ...this.state.data, ...obj } });
                break;
            }
        }
    }

    renderHeader(state) {
        let tgt = {};
        if (state.targetBlank) tgt['target'] = '_blank';

        if (state.imagePath && this.props.public) {
            if (state.imageLink) {
                return (<a href={state.imageLink} {...tgt}>
                    <img
                        src={this.genLang(state.imagePath || '', this.state.lang)}
                        width={state.imageWidth}
                        title={this.genLang(state.formTitle || '', this.state.lang)}
                    />
                </a>);
            }
            return (<img
                src={this.genLang(state.imagePath || '', this.state.lang)}
                width={state.imageWidth}
            />);
        } else if (state.formTitle && this.props.public) {
            if (state.imageLink) {
                return (<h1>
                    <a href={state.imageLink} {...tgt}>
                        {this.genLang(state.formTitle || '', this.state.lang)}
                    </a>
                </h1>);
            }
            //    return(<h1>{this.genLang(state.formTitle||'',this.state.lang)}</h1>)

            return (<div
                dangerouslySetInnerHTML={{
                    __html: this.genLang(state.formTitle || '', this.state.lang)
                }}
            ></div>);
        }

        return <></>;
    }

    renderActionBar() {
        return (
            <nav 
                className={cn(
                    {
                        'tw-top-[60px]': !this.props.public,
                        'tw-top-[0px]': this.props.public
                    }, 
                    'tw-z-10 tw-sticky tw-flex tw-flex-wrap',
                    'tw-items-center tw-px-5 tw-py-3 tw-bg-white tw-gap-2'
                )} 
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}
            >
                <Badge className="!tw-px-3 tw-cursor-pointer"
                       onClick={this.toggleLang}>
                    {this.genLangLegacy('otherLang')}
                </Badge>
                <div
                    className="tw-ml-auto tw-flex tw-flex-wrap tw-items-center tw-justify-end tw-gap-2">
                    {!this.props.public &&
                        <>
                            <a
                                className="btn btn-success"
                                href={`${getFormUrl(this.props.formName, this.state.task_id)}/send`}
                            >
                                <FontAwesomeIcon className="tw-mr-2" icon={faUsers} />
                                {this.genLang({ en: 'Recipients', fr: 'Destinataires' })}
                            </a>
                            <Separator orientation="vertical" className="tw-self-stretch" />
                        </>
                    }
                    {!this.props.public && this.state.pdfOptions.filter(o => o.show).map(o => {
                        const loading = o.status === DocumentStatus.PENDING ||
                            o.status === DocumentStatus.PROCESSING;
                        return (
                            <button className={`btn btn-${o.color}`} disabled={loading} key={o.name}
                                    onClick={() => this.handleDocumentAction(o.name)}>
                                {loading
                                    ? <div className={
                                        'spinner-border tw-text-white ' +
                                        '!tw-h-[1rem] !tw-w-[1rem] !tw-border-2'
                                    } role="status" />
                                    : <>
                                        <i className={`${o.icon} tw-mr-2`}></i>
                                        {this.genLang(o.title, this.state.lang)}
                                    </>
                                }
                            </button>
                        );
                    })}
                    <button className="btn btn-primary" onClick={this.handleSubmit}>
                        <FontAwesomeIcon className="tw-mr-2" icon={faFloppyDisk} />
                        {this.genLang({
                            en: 'Save',
                            fr: 'Enregistrer'
                        }, this.state.lang)}
                    </button>
                </div>
            </nav>
        );
    }

    genLang(text, lang = FR) {
        if (this.state.lang) lang = this.state.lang;

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        const state = this.state;
        const props = this.props;

        if (state.redirect) {
            if (state.redirect === 'expiration') {
                this.props.history.push('/expiration');
                return (<></>);
            } else if (state.redirect === 'submited') {
                this.props.history.push('/submited');
                return (<></>);
            } else if (state.redirect.match && state.redirect.match(/merci/)) {
                this.props.history.push('/merci');
                return (<></>);
            }
            this.props.history.push('/login');
            return (<></>);
        }

        return (
            <>
                {this.renderActionBar()}
                <div
                    key="sourire"
                    className="tw-p-5 tw-flex-1 tw-flex tw-flex-col"
                >
                    {/* <Help links={state.links} /> */}
                    {/* <Row> */}
                    {/*     <Col */}
                    {/*         style={{ */}
                    {/*             textAlign: 'center', */}
                    {/*             marginBottom: '20px' */}
                    {/*         }} */}
                    {/*     > */}
                    {/*         {this.renderHeader(state)} */}
                    {/*     </Col> */}
                    {/* </Row> */}
                    <SearchTicket
                        col={6}
                        target={state.formType}
                        setTicket={() => {
                            this.setState({
                                data: [],
                                pdf: null
                            });
                        }}
                        public={props.public}
                        token={props.token}
                        task_id={this.state.task_id}
                        ticket={state.ticketId}
                        init={this.init}
                        callback={this.callback}
                        identity={props.identity}
                        lang={state.lang}
                        links={[] || state.links}
                        formName={props.formName}
                        layoutHeader={this.handleSetLayoutHeader}
                        bareFormName={state.bareFormNameFromRequest}
                        trad={props.trad}
                    />

                    {state.exceptions && Array.isArray(state.exceptions) &&
                        <Row>
                            <Col md={6}>
                                {_.map(state.exceptions, (elt) => {
                                    return elt.misingFile === 'true' ? (<AlertNotification
                                        key={elt.name + '324324'}
                                        color={'danger'}
                                        visible={state[elt.realName]}
                                        text={'Le document s\'est généré, mais un fichier est manquant. Veuillez vérifier manuellement.'}
                                        setParent={() => {
                                            let obj = {};
                                            obj[elt.realName] = false;
                                            this.setState(obj);
                                        }}
                                    />) : (<AlertNotification
                                        key={elt.name + '324324'}
                                        color={'danger'}
                                        visible={state[elt.realName]}
                                        text={'<b>' + elt.originalName + ' renommée: ' + elt.name + ' n\'est pas Compatible avec notre système, veuillez aviser le SC dans la section commentaires.</b>'}
                                        setParent={() => {
                                            let obj = {};
                                            obj[elt.realName] = false;
                                            this.setState(obj);
                                        }}
                                    />);
                                })}
                            </Col>
                        </Row>
                    }
                    <Row className="tw-flex-1">
                        <Col md={this.state.previewLink ? 7 : 12}
                             lg={this.state.previewLink ? 7 : 12}
                             sm={this.state.previewLink ? 8 : 12}>
                            <div style={{ display: state.display }}>
                                <JSONForm
                                    public={props.public}
                                    ticket={state.ticketId}
                                    target={IDENTITY + state.formType}
                                    version={state.formCounter}
                                    collections={{ lawyerCollection: state.lawyerCollection }}
                                    dataFiles={this.state.dataFiles || []}
                                    lang={state.lang}
                                    layout={state.formLayout}
                                    allFieldsNotes={state.allFieldsNotes}
                                    allFieldsNotesInfo={state.allFieldsNotesInfo}
                                    key={'keytobehappy'}
                                    inKey={'keytobehappygwaffafafafaf'}
                                    onChange={this.onChange}
                                    trad={props.trad}
                                    data={state.data || []}
                                    layoutHeader={this.handleSetLayoutHeader}
                                    formNameBase={state.formName}
                                    formMode={state.formMode}
                                    shouldDisplay={{ ...state.data }}
                                    token={props.token}
                                    onSelectChange={this.onSelectChange}
                                    removeActionnaires={this.removeActionnaires}
                                />
                            </div>
                        </Col>
                        {this.state.previewLink &&
                            <Col md={5} lg={5} sm={4} className="!tw-mt-12">
                                <iframe
                                    src={`${this.state.previewLink}&c=${this.state.previewCount}`}
                                    height="100%"
                                    width="100%"
                                />
                            </Col>
                        }
                    </Row>
                    {state.exceptions && Array.isArray(state.exceptions) &&
                        <Row>
                            <Col md={MAX} sm={MAX}>
                                {state.exceptions.map((elt) =>
                                    <AlertNotification
                                        key={elt.name + '324324'}
                                        color="danger"
                                        visible={state[elt.realName]}
                                        text={elt.originalName + ' renommée: ' + elt.name + ' n\'est pas Compatible avec notre système, veuillez aviser le SC dans la section commentaires.'}
                                        setParent={() => {
                                            let obj = {};
                                            obj[elt.realName] = false;
                                            this.setState(obj);
                                        }}
                                    />)
                                }
                            </Col>
                        </Row>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    rootsActions: rootsActions,
    formData: state.forms.will,
    loading: state.loading.loading,
    users: state.users,
    requests: state.request
});

const mapActionsToProps = {
    remove: rootsActions.formsActions.removeWill,
    put: rootsActions.formsActions.putWill,
    putField: rootsActions.requestActions.putField,
    putDocument: rootsActions.requestActions.putDocument,
    initRequest: rootsActions.requestActions.init,
    setListAttach: rootsActions.requestActions.setListAttach,
    post: rootsActions.formsActions.postWill,
    init: rootsActions.formsActions.initWill,
    toggleLoading: rootsActions.loadingActions.toggleLoading,
    userUpdate: rootsActions.usersActions.onLogin,
    removeDelete: rootsActions.formsActions.removeFileDocument
};

export const FormContainer = connect(mapStateToProps, mapActionsToProps)(FormContainerComponent);
