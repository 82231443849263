import React from 'react';
import ReactQuill from 'react-quill';
import { Editor } from '@tinymce/tinymce-react';
import { useNeoForm } from '@/composables/neoform';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { type ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { useTranslation } from '@/composables/translation';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { cn } from '@/lib/utils';
import { getFieldError } from '@/composables/validation';

const toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean']
];

interface Props {
    type?: 'quill' | 'tiny';
}

interface ContentEditorProps {
    id: string;
    type: 'quill' | 'tiny';
    field: ControllerRenderProps;
    error?: boolean;
    readonly?: boolean;
}

function ContentEditor(props: ContentEditorProps) {
    switch (props.type) {
    case 'quill':
        return (
            <ReactQuill
                id={props.id}
                theme="snow"
                modules={{ toolbar }}
                readOnly={props.readonly}
                className={cn(
                    props.error && '[&>.ql-toolbar]:tw-border-destructive [&>.ql-container]:tw-border-destructive'
                )}
                {...props.field}
                onChange={(value) => props.field.onChange(value)}
            />
        );
    case 'tiny': {
        const { ref, ...field } = props.field;
        return (
            <div className={cn(
                props.error && 'tw-border tw-border-destructive tw-rounded-lg'
            )}>
                <Editor
                    id={props.id}
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    disabled={props.readonly}
                    {...field}
                    onChange={undefined}
                    onEditorChange={(value) => props.field.onChange(value)}
                    init={{
                        elementpath: false,
                        plugins: 'link image code table fullscreen preview lists',
                        toolbar:
                            'insertfile a11ycheck undo redo | bold italic underline | ' +
                            'forecolor backcolor | template codesample | ' +
                            'alignleft aligncenter alignright alignjustify | bullist numlist | ' +
                            'link image tinydrive | table | fullscreen | preview',
                        setup: (editor) => {
                            editor.ui.registry.addButton('customInsertButton', {
                                icon: 'new-document',
                                tooltip: 'New Page Portrait',
                                onAction: () => {
                                    editor.insertContent('[*portrait*]');
                                }
                            });
                            editor.ui.registry.addButton('customInsertButton1', {
                                icon: 'orientation',
                                tooltip: 'New Page Paysage',
                                onAction: () => {
                                    editor.insertContent('[*paysage*]');
                                }
                            });
                        }
                    }}
                />
            </div>
        );
    }
    }
}

export function InputContentEditable(props: Props & NeoFormComponentProps) {
    const { to } = useTranslation();
    const { id, getValidationRules } = useNeoForm();
    const validate = getValidationRules(props);
    return (
        <FormField
            name={id}
            defaultValue={to(props.default)}
            render={({ field, formState }) =>
                <FormItem>
                    <FormControl>
                        <ContentEditor
                            id={id}
                            type={props.type ?? 'quill'}
                            field={{
                                ...field,
                                value: field.value ?? ''
                            }}
                            error={!!getFieldError(field.name, formState)}
                            readonly={props.readonly}
                        />
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            }
            rules={{ validate }}
        />
    );
}
