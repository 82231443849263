import React from 'react';
import { useUserStore } from '@/store/user';
import { instance } from '@/composables/api';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '@/composables/translation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faCalendar,
    faClock,
    faGear,
    faRightFromBracket,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import { MenuButton } from '@/components/MenuButton';
import { LanguageSelect } from '@/components/neoform/helper/LanguageSelect';
import { Button } from '@/components/ui/button';
import { SearchModal } from '@/components/psj/SearchModal';
import { Feature, useFeatures } from '@/composables/features';
import { cn } from '@/lib/utils';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { Permission, usePermissions } from '@/composables/permissions';

export function NavBar() {
    const {
        token,
        user
    } = useUserStore(state => ({
        token: state.token,
        user: state.user
    }));
    const { t, ct } = useTranslation('nav');
    const location = useLocation();
    const { isFeatureEnabled } = useFeatures();
    const { hasPermissions } = usePermissions();
    const logoURL = user?.logo
        ? instance.getUri({
            url: '/v1/get-image-gcloud',
            params: {
                filename: user?.logo,
                token
            }
        })
        : '';
    const items = [
        {
            title: t('home'),
            url: '/dashboard',
            feature: Feature.PSJ
        },
        {
            title: t('neodoc'),
            url: '/forms',
            feature: Feature.NEOFORM
        }
    ];
    const filteredItems = items.filter(i => isFeatureEnabled(i.feature));
    return (
        <nav className={cn(
            'tw-h-[60px] tw-fixed tw-inset-0',
            'tw-flex tw-flex-row tw-items-center ',
            'tw-px-4 tw-py-2 tw-drop-shadow-xl ',
            'tw-z-10 tw-bg-[#0166b3] print:tw-hidden'
        )}>
            <div className="tw-mr-2 tw-block sm:tw-hidden">
                <MenuButton
                    id="nav-links"
                    renderButton={(props) => (
                        <Button
                            {...props}
                            className={cn(
                                '!tw-size-12 tw-text-xl !tw-text-white',
                                '!tw-rounded-full hover:!tw-bg-black/15'
                            )}
                            variant="ghost"
                        >
                            <FontAwesomeIcon icon={faBars} width="1em" />
                        </Button>
                    )}
                    options={filteredItems.map(i => ({
                        value: i.url,
                        label: i.title,
                        url: i.url,
                        selected: location.pathname === i.url
                    }))}
                />
            </div>
            <Link to="/dashboard" className="tw-h-3/4 tw-w-[115px] tw-hidden sm:tw-block">
                {logoURL && <img src={logoURL} alt="logo" className="tw-h-full tw-w-[115px]" />}
            </Link>
            <ul className={cn(
                'tw-list-none tw-p-0 tw-mx-10',
                'tw-my-[-8px] tw-self-stretch',
                'tw-hidden sm:tw-flex'
            )}>
                {filteredItems.map(i => (
                    <Link
                        key={i.title} to={i.url}
                        style={{
                            '--nav-color': '0 0% 100%'
                        }}
                        className={cn(
                            'tw-h-full tw-font-semibold tw-relative',
                            'tw-flex tw-flex-col tw-justify-center tw-px-9',
                            'tw-transition-[background-color_0.5s] tw-no-underline',
                            'tw-text-[color:hsl(var(--nav-color))]',
                            '[&>a]:tw-text-[color:hsl(var(--nav-color))]',
                            'hover:tw-bg-[#00427c] hover:tw-no-underline',
                            'hover:tw-text-[color:hsl(var(--nav-color))]',
                            '[&.selected]:after:tw-block [&.selected]:after:tw-absolute',
                            '[&.selected]:after:tw-inset-x-0 [&.selected]:after:tw-bottom-0',
                            '[&.selected]:after:tw-content-[\'_\'] [&.selected]:after:tw-h-[4px]',
                            '[&.selected]:after:tw-bg-[hsl(var(--nav-color))]',
                            location.pathname === i.url && 'selected'
                        )}
                    >
                        <li>
                            {i.title}
                        </li>
                    </Link>
                ))}
            </ul>
            <div className="tw-ml-auto tw-flex tw-justify-center tw-gap-x-2.5">
                {isFeatureEnabled(Feature.PSJ) &&
                    <SearchModal />
                }
                {isFeatureEnabled(Feature.CALENDAR) &&
                    <MenuButton
                        id="calendar"
                        tooltip={t('calendar.tooltip')}
                        renderButton={props => (
                            <Button
                                className={cn(
                                    '!tw-size-12 tw-text-xl !tw-text-white',
                                    '!tw-rounded-full hover:!tw-bg-black/15'
                                )}
                                variant="ghost" size="icon"
                                {...props}
                            >
                                <FontAwesomeIcon className="tw-text-lg" icon={faCalendar} width="1em"/>
                            </Button>
                        )}
                        options={[
                            ...(
                                hasPermissions(Permission.CALENDAR_DISPONIBILITIES_SELECT)
                                    ? [{
                                        value: 'availabilities',
                                        label: t('calendar.availabilities'),
                                        icon: faCalendarDays,
                                        url: '/calendar/availabilities'
                                    }]
                                    : []
                            ),
                            ...(
                                hasPermissions(Permission.CALENDAR_APPOINTMENT_SCHEDULE)
                                    ? [{
                                        value: 'schedule',
                                        label: t('calendar.schedule'),
                                        icon: faClock,
                                        url: '/calendar/schedule'
                                    }]
                                    : []
                            )
                        ]}
                    />
                }
                <MenuButton
                    id="account"
                    tooltip={t('account')}
                    renderButton={props => (
                        <Button
                            className={cn(
                                '!tw-size-12 tw-text-xl !tw-text-white',
                                '!tw-rounded-full hover:!tw-bg-black/15'
                            )}
                            variant="ghost" size="icon"
                            {...props}
                        >
                            <FontAwesomeIcon icon={faUser} width="1em" />
                        </Button>
                    )}
                    options={[
                        {
                            value: 'settings',
                            label: ct('settings'),
                            icon: faGear,
                            url: '/settings/account'
                        },
                        {
                            value: 'logout',
                            label: ct('logout'),
                            icon: faRightFromBracket,
                            url: '/logout'
                        }
                    ]}
                />
                <LanguageSelect
                    className={cn(
                        '!tw-size-12 tw-text-xl !tw-text-white',
                        '!tw-rounded-full hover:!tw-bg-black/15'
                    )}
                />
            </div>
        </nav>
    );
}
