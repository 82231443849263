import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const badgeVariants = cva(
    'tw-inline-flex tw-items-center tw-rounded-md tw-border tw-font-semibold ' +
    'tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2',
    {
        variants: {
            variant: {
                default:
                    'tw-border-transparent tw-bg-primary tw-text-primary-foreground tw-shadow hover:tw-bg-primary/80',
                secondary:
                    'tw-border-transparent tw-bg-secondary tw-text-secondary-foreground hover:tw-bg-secondary/80',
                destructive:
                    'tw-border-transparent tw-bg-destructive tw-text-destructive-foreground ' +
                    'hover:tw-bg-destructive/80',
                success: 'tw-border-transparent tw-bg-success tw-text-success-foreground ' +
                    'hover:tw-bg-success/80',
                warning: 'tw-border-transparent tw-bg-warning tw-text-warning-foreground ' +
                    'hover:tw-bg-warning/80',
                outline: 'tw-text-foreground'
            },
            size: {
                sm: 'tw-px-2.5 tw-py-0.5 tw-text-xs',
                md: 'tw-px-2.5 tw-py-1 tw-text-sm'
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'sm'
        }
    }
);

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {
}

function Badge({
    className,
    variant,
    size,
    ...props
}: BadgeProps) {
    return (
        <div className={cn(badgeVariants({ variant, size }), className)} {...props} />
    );
}

export { Badge, badgeVariants };
