import React, { useState } from 'react';
import {
    Missing, NEO_FORM_COMPONENTS,
    NeoFormComponent,
    type NeoFormComponentProps
} from '@/components/neoform/NeoFormComponent';
import { useNeoForm } from '@/composables/neoform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClipboard, faCopy, faXmark } from '@fortawesome/free-solid-svg-icons';
import { readClipboardJSON, writeClipboardJSON } from '@/composables/clipboard';
import { useTranslation } from '@/composables/translation';
import { Row } from '@/components/ui/row';
import { Button } from '@/components/ui/button';

interface Props {
    componentCustom: string;
    copyPaste?: boolean;
}

export function DynamicComponent(props: NeoFormComponentProps & Props) {
    const { hookForm, form, id } = useNeoForm();
    const { t } = useTranslation('neoform.dynamic-component');
    form?.dynamicComponents.add(id);
    const [copied, setCopied] = useState(false);
    const json = form?.layout?.components?.[props.componentCustom];
    const isReactComponent = props.componentCustom in NEO_FORM_COMPONENTS;

    function handleCopy() {
        writeClipboardJSON(form?.get(id))
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 5000);
            });
    }

    function handlePaste() {
        readClipboardJSON()
            .then(data => form?.set(id, data));
    }

    function handleClear() {
        hookForm.resetField(id);
    }

    return (
        <div className="tw-flex tw-flex-col tw-gap-6">
            {json?.map((r, i) => (
                <Row key={`${id}.${i}`}>
                    {r.map((c, j) => (
                        <NeoFormComponent
                            {...c}
                            key={`${id}.${i}.${j}`}
                            last={i === (json?.length ?? 0) - 1}
                        />
                    ))}
                </Row>
            ))}
            {!json && isReactComponent && <Row>
                <NeoFormComponent
                    {...props}
                    name={props.name}
                    componentName={props.componentCustom as 'Missing'}
                    displayPreface={false}
                    last
                />
            </Row>}
            {!json && !isReactComponent && <Row>
                <NeoFormComponent
                    name="missing"
                    componentName={props.componentCustom as 'Missing'}
                    componentFunction={Missing}
                    last
                />
            </Row>}
            {props.copyPaste &&
                <div className="tw-flex tw-justify-end tw-gap-2 tw-mt-2">
                    <Button
                        type="button" variant="outline"
                        className="tw-text-destructive"
                        onClick={handleClear}
                        disabled={props.readonly}
                    >
                        <FontAwesomeIcon className="tw-mr-2" icon={faXmark}/>
                        {t('clear')}
                    </Button>
                    <Button
                        type="button" variant="outline"
                        className="tw-text-primary"
                        onClick={handleCopy}
                    >
                        <FontAwesomeIcon className="tw-mr-2" icon={copied ? faCheck : faCopy}/>
                        {copied ? t('copied') : t('copy')}
                    </Button>
                    <Button
                        type="button" variant="outline"
                        className="tw-text-primary"
                        onClick={handlePaste}
                        disabled={props.readonly}
                    >
                        <FontAwesomeIcon className="tw-mr-2" icon={faClipboard}/>
                        {t('paste')}
                    </Button>
                </div>
            }
        </div>
    );
}
