import { getDocumentStatus } from '@/composables/api';
import { useDocumentStore } from '@/store/document';
import { useEffect } from 'react';

export function DocumentStatusPolling() {
    const store = useDocumentStore();
    useEffect(() => {
        const interval = setInterval(async() => {
            try {
                const res = await getDocumentStatus();
                res.forEach(([taskId, data]) => {
                    store.updateDocumentStatus(taskId, data);
                });
            } catch (err) {}
        }, 5000);
        return () => clearInterval(interval);
    });
    return null;
}
