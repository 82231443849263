import React from 'react';
import { Footer } from '@/components/Footer';
import { Outlet } from 'react-router-dom';

export function FooterLayout() {
    return (
        <>
            <Outlet />
            <Footer />
        </>
    );
}
