import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { ButtonAsync } from '@/components/ui/button-async';
import React from 'react';
import { type GetConflictDto, type Party } from '@/types/api/party';
import { useTranslation } from '@/composables/translation';

interface Props {
    open?: boolean;
    onOpenChange?: React.Dispatch<boolean>;
    onSubmit?: () => Promise<void>;
    cases?: GetConflictDto['cases'];
    client?: Party;
}

export function PartyConflictDialog(props: Props) {
    const { ct, t } = useTranslation('psj.parties');
    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="tw-text-warning">
                        {t('conflict.title')}
                    </DialogTitle>
                </DialogHeader>
                <div className="tw-prose">
                    <p>
                        {t('conflict.message-0')}
                        {' '}<b>{props.client?.firstname} {props.client?.lastname}</b>{' '}
                        {t('conflict.message-1')}
                    </p>
                    <ul>
                        {props.cases?.map((c) =>
                            <li key={c._id}>#{c.folder_id} — {c.name}</li>
                        )}
                    </ul>
                    <p>{t('conflict.message-2')}</p>
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button variant="secondary">
                            {ct('cancel')}
                        </Button>
                    </DialogClose>
                    <ButtonAsync
                        variant="warning"
                        onClick={async() => {
                            try {
                                await props.onSubmit?.();
                            } catch {
                            } finally {
                                props.onOpenChange?.(false);
                            }
                        }}
                    >
                        {ct('continue')}
                    </ButtonAsync>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
