import React, { Component } from 'react';
import { Card, CardBody, CardText, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

import { DropContainer } from './DropContainer';
import { Address } from './Address';
import { CheckInput } from './CheckInput';
import { Calendar } from './Calendar';

const DEFAULT_STYLE = {};

const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const TEXT = 'text';

export class Admin extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '';
        this.state = {
            lang: this.props.lang || 'fr',
            name: fd[this.props.name + '_name'] || '',
            firstname: fd[this.props.name + '_firstname'] || '',
            gender: this.props.formData[this.props.name + '_gender'],
            display: this.props.defaultDisplay === false ? false : true,
            position: this.props.formData[this.props.name + '_position'],
            statut: this.props.formData[this.props.name + '_statut'],
            checkEndDate: (this.props.formData[this.props.name + '_checkEndDate'] == true || this.props.formData[this.props.name + '_checkEndDate'] == 'true') ? true : false,
            isAdminSign: (this.props.formData[this.props.name + '_isAdminSign'] == true || this.props.formData[this.props.name + '_isAdminSign']) ? true : false,
            data: fd
        };

        this.option = this.option.bind(this);
        this.genLang = this.genLang.bind(this);

    }

    genLang(key = 'missing') {

        if (!this.props.trad) {
            return key;
        }

        if (!this.props.trad[key]) {
            return this.props.trad['wrongKey'][this.state.lang] + ' ' + key;
        }
        return this.props.trad[key][this.state.lang];
    }

    genLangNew(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevProps) {
        let props = this.props,
            obj = {};

        if (prevProps.formData && props.formData || props.version !== prevProps.version) {

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }

            if (props.formData[props.name + '_name'] !== prevProps.formData[props.name + '_name']) {
                obj['name'] = props.formData[props.name + '_name'] || '';
            }

            if (props.formData[props.name + '_firstname'] !== prevProps.formData[props.name + '_firstname']) {
                obj['firstname'] = props.formData[props.name + '_firstname'] || '';
            }

            if (props.formData[props.name + '_assistant'] !== prevProps.formData[props.name + '_assistant']) {
                obj['assistant'] = props.formData[props.name + '_assistant'] || '';
            }

            if (props.formData[props.name + '_gender'] !== prevProps.formData[props.name + '_gender']) {
                obj['gender'] = props.formData[props.name + '_gender'] || '';
            }

            if (props.formData[props.name + '_position'] !== prevProps.formData[props.name + '_position']) {
                obj['position'] = props.formData[props.name + '_position'] || '';
            }

            if (props.formData[props.name + '_status'] !== prevProps.formData[props.name + '_status']) {
                obj['status'] = props.formData[props.name + '_status'] || '';
            }

            if (props.formData[props.name + '_startDate'] !== prevProps.formData[props.name + '_startDate']) {
                obj['startDate'] = props.formData[props.name + '_startDate'] || '';
            }

            if (props.formData[props.name + '_endDate'] !== prevProps.formData[props.name + '_endDate']) {
                obj['endDate'] = props.formData[props.name + '_endDate'] || '';
            }

            if (props.display !== prevProps.display) {

                if (props.display === false && prevProps.display === true) {
                    obj.display = false;
                } else if (props.display === true && prevProps.display === false) {
                    obj.display = true;
                } else if (prevProps.display === undefined) {
                    obj.display = props.display;
                }
            }
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    option() {

        if (this.props.assistant) {
            return (
                <Col md={6}>
                    <FormGroup>
                        <Label>Assistant</Label>
                        <Input
                            type="text"
                            name={this.props.name + '_assistant'}
                            onChange={(e) => this.setState({ assistant: e.target.value })}
                            value={this.state.assistant || ''}
                        />
                    </FormGroup>
                </Col>);
        }

    }

    checkEnddate(e) {
        this.setState({ checkEndDate: e.target.checked });
    }

    render() {

        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        if (this.props.noCard) {
            return (
                <Col md={this.props.col || 12} style={style}>

                    {this.props.remove ? <span className="remove" style={{ zIndex: 9999 }}
                                               onClick={() => this.props.remove(this.props.name)}>
                    {cross}
                </span> : ''}
                    {
                        <Row>
                            <Col md={12}>
                                <FormGroup tag="fieldset" className="!tw-mb-0">
                                    <legend><h5>{this.genLangNew(this.props.title, this.state.lang)}</h5></legend>
                                    {/* <legend><span>{this.props.target+ ' ' +this.props.counter}</span></legend> */}
                                </FormGroup>
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_name')}</Label>
                                <Input
                                    type={TEXT}
                                    name={this.props.name + '_name'}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    value={this.state.name}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_firstname')}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_firstname'}
                                    onChange={(e) => this.setState({ firstname: e.target.value })}
                                    value={this.state.firstname}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_title')}</Label>
                                <Input
                                    type="select"
                                    name={this.props.name + '_gender'}
                                    onChange={(e) => this.setState({ gender: e.target.value })}
                                    value={this.state.gender || '-'}>
                                    <option value="-">{this.genLang('_Autre')}</option>
                                    <option value="M">{this.genLang('_sir')}</option>
                                    <option value="F">{this.genLang('_madam')}</option>
                                    {/* <option value="NA" >Autre</option> */}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>{'Position (CEO,PDG...))'}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_position'}
                                    onChange={(e) => this.setState({ position: e.target.value })}
                                    value={this.state.position}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={4}>
                            <br /><br />
                            <CheckInput
                                col={12}
                                name={this.props.name + '_isAdminSign'}
                                title={this.genLang('_adminSign')}
                                formData={this.props.formData}
                                put={this.props.put}
                            />

                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Label>{'Statut'}</Label>
                                <Input
                                    type="select"
                                    name={this.props.name + '_statut'}
                                    onChange={(e) => this.setState({ statut: e.target.value })}
                                    value={this.state.statut || '-'}>
                                    <option value=""></option>
                                    <option value={this.genLang('_citizen')}>{this.genLang('_citizen')}</option>
                                    <option
                                        value={this.genLang('_permanentResident')}>{this.genLang('_permanentResident')}</option>
                                    <option value={this.genLang('_notResident')}>{this.genLang('_notResident')}</option>
                                    {/* <option value="NA" >Autre</option> */}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={3}>
                            <Label>{'<3' + this.genLang('_startdate')}</Label>
                            <Calendar
                                put={this.props.put}
                                remove={this.props.remove}
                                style={{ paddingLeft: '0px' }}
                                lang={this.state.lang}
                                version={this.state.version}
                                title={''}
                                key={this.props.name + '_startDate'}
                                name={this.props.name + '_startDate'}
                                // {...params}
                                formData={{ ...this.state.data }}
                            />
                        </Col>
                        <Col md={3} style={{ display: (this.props.isPrivate) ? 'BLOCK' : 'NONE' }}>
                            <br /><br />
                            <CheckInput
                                col={12}
                                name={this.props.name + '_checkEndDate'}
                                title={this.genLang('_startdate')}
                                formData={this.props.formData}
                                put={this.props.put}
                                callback={(e) => this.checkEnddate(e)}
                            />
                        </Col>
                        {(this.state.checkEndDate == true || this.state.checkEndDate == 'true') ?
                            <Col md={3} style={{ display: (this.props.isPrivate) ? 'BLOCK' : 'NONE' }}>
                                <Label>{this.genLang('_enddate')}</Label>
                                <Calendar
                                    put={this.props.put}
                                    remove={this.props.remove}
                                    style={{ paddingLeft: '0px' }}
                                    lang={this.state.lang}
                                    version={this.state.version}
                                    title={''}
                                    key={this.props.name + '_endDate'}
                                    name={this.props.name + '_endDate'}
                                    // {...params}
                                    formData={{ ...this.state.data }}
                                />
                            </Col> : <></>}
                    </Row>

                    <Row>
                        <Address
                            col={12}
                            put={this.props.put}
                            lang={this.state.lang}
                            trad={this.props.trad}
                            formData={{ ...this.props.formData }}
                            remove={this.removeHeirForm}
                            name={this.props.name}
                            version={this.state.version}
                            target={this.props.title}
                            country={true}
                        />
                    </Row>


                    <Row>
                        <DropContainer
                            removeFileFromGCloud={this.props.removeFileFromGCloud}
                            wow="wow"
                            public={this.props.public}
                            add={this.props.add}
                            col={12}
                            name={this.props.name + '_atta'}
                            title={this.genLang('_actdoclbl')}
                            formData={this.props.formData}
                            put={this.props.put}
                            ticket={this.props.ticket}
                            toggleLoading={this.props.toggleLoading}
                            target={this.props.target}
                            lang={this.state.lang}
                            version={this.state.version}
                            key={this.props.formData + '_atta'}
                            // onChange={this.onChange}
                            // formData={this.state.data}
                            remove={this.props.remove}
                            // removeFileFromGCloud={this.props.removeFileFromGCloud}

                            pushWaitingList={this.props.pushWaitingList}
                            pullWaitingList={this.props.pullWaitingList}
                        />
                    </Row>

                </Col>
            );
        }

        return (
            <Col md={this.props.col || 12} style={style}>
                <Card style={{ marginBottom: '5px' }}>
                    {this.props.remove ? <span className="remove" style={{ zIndex: 9999 }}
                                               onClick={() => this.props.remove(this.props.name)}>
                    {cross}
                </span> : ''}
                    <CardBody>
                        <CardText tag="div">
                            <Row>
                                <Col md={12}>
                                    <FormGroup tag="fieldset" className="!tw-mb-0">
                                        <legend><h5>{this.genLangNew(this.props.title, this.state.lang)}</h5></legend>
                                        {/* <legend><span>{this.props.target+ ' ' +this.props.counter}</span></legend> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_name')}</Label>
                                        <Input
                                            type={TEXT}
                                            name={this.props.name + '_name'}
                                            onChange={(e) => this.setState({ name: e.target.value })}
                                            value={this.state.name}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_firstname')}</Label>
                                        <Input
                                            type="text"
                                            name={this.props.name + '_firstname'}
                                            onChange={(e) => this.setState({ firstname: e.target.value })}
                                            value={this.state.firstname}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_title')}</Label>
                                        <Input
                                            type="select"
                                            name={this.props.name + '_gender'}
                                            onChange={(e) => this.setState({ gender: e.target.value })}
                                            value={this.state.gender || '-'}>
                                            <option value="-">{this.genLang('_Autre')}</option>
                                            <option value="M">{this.genLang('_sir')}</option>
                                            <option value="F">{this.genLang('_madam')}</option>
                                            {/* <option value="NA" >Autre</option> */}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{'Position (CEO,PDG...))'}</Label>
                                        <Input
                                            type="text"
                                            name={this.props.name + '_position'}
                                            onChange={(e) => this.setState({ position: e.target.value })}
                                            value={this.state.position}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <br /><br />
                                    <CheckInput
                                        col={12}
                                        name={this.props.name + '_isAdminSign'}
                                        title={this.genLang('_adminSign')}
                                        formData={this.props.formData}
                                        put={this.props.put}
                                    />
                                </Col>

                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{'Statut'}</Label>
                                        <Input
                                            type="select"
                                            name={this.props.name + '_statut'}
                                            onChange={(e) => this.setState({ statut: e.target.value })}
                                            value={this.state.statut || '-'}>
                                            <option value=""></option>
                                            <option value={this.genLang('_citizen')}>{this.genLang('_citizen')}</option>
                                            <option
                                                value={this.genLang('_permanentResident')}>{this.genLang('_permanentResident')}</option>
                                            <option
                                                value={this.genLang('_notResident')}>{this.genLang('_notResident')}</option>
                                            {/* <option value="NA" >Autre</option> */}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={3}>
                                    <Label>{this.genLang('_startdate')}</Label>
                                    <Calendar
                                        put={this.props.put}
                                        remove={this.props.remove}
                                        style={{ paddingLeft: '0px' }}
                                        lang={this.state.lang}
                                        version={this.state.version}
                                        title={''}
                                        key={this.props.name + '_startDate'}
                                        name={this.props.name + '_startDate'}
                                        // {...params}
                                        formData={{ ...this.state.data }}
                                    />
                                </Col>
                                <Col md={3} style={{ display: (this.props.isPrivate) ? 'BLOCK' : 'NONE' }}>
                                    <br /><br />
                                    <CheckInput
                                        col={12}
                                        name={this.props.name + '_checkEndDate'}
                                        title={this.genLang('_enddate')}
                                        formData={this.props.formData}
                                        put={this.props.put}
                                        callback={(e) => this.checkEnddate(e)}
                                    />
                                </Col>
                                {(this.state.checkEndDate == true || this.state.checkEndDate == 'true') ?
                                    <Col md={3} style={{ display: (this.props.isPrivate) ? 'BLOCK' : 'NONE' }}>
                                        <Label>{this.genLang('_enddate')}</Label>
                                        <Calendar
                                            put={this.props.put}
                                            remove={this.props.remove}
                                            style={{ paddingLeft: '0px' }}
                                            lang={this.state.lang}
                                            version={this.state.version}
                                            title={''}
                                            key={this.props.name + '_endDate'}
                                            name={this.props.name + '_endDate'}
                                            // {...params}
                                            formData={{ ...this.state.data }}
                                        />
                                    </Col> : <></>}
                            </Row>

                            <Row>
                                <Address
                                    col={12}
                                    put={this.props.put}
                                    lang={this.state.lang}
                                    trad={this.props.trad}
                                    formData={{ ...this.props.formData }}
                                    remove={this.removeHeirForm}
                                    name={this.props.name}
                                    version={this.state.version}
                                    target={this.props.title}
                                    country={true}
                                />
                            </Row>


                            <Row>
                                <DropContainer
                                    removeFileFromGCloud={this.props.removeFileFromGCloud}
                                    wow="wow"
                                    public={this.props.public}
                                    add={this.props.add}
                                    col={12}
                                    name={this.props.name + '_atta'}
                                    title={this.genLang('_actdoclbl')}
                                    formData={this.props.formData}
                                    put={this.props.put}
                                    ticket={this.props.ticket}
                                    toggleLoading={this.props.toggleLoading}
                                    target={this.props.target}
                                    lang={this.state.lang}
                                    version={this.state.version}
                                    key={this.props.formData + '_atta'}
                                    // onChange={this.onChange}
                                    // formData={this.state.data}
                                    remove={this.props.remove}
                                    // removeFileFromGCloud={this.props.removeFileFromGCloud}

                                    pushWaitingList={this.props.pushWaitingList}
                                    pullWaitingList={this.props.pullWaitingList}
                                />
                            </Row>

                        </CardText>
                    </CardBody>
                </Card>
            </Col>);
    }
}
