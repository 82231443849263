import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { FormGroup, Label, Col, Row } from 'reactstrap';

import { RequestThatField } from './RequestThatField';
import { RadioInput } from './RadioInput';
import { NumberInput } from './NumberInput';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const DEFAULT_STYLE = {
    textAlign: 'left'
};

export class CategoryActions extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData;
        this.options = [
            {
                label: 'A',
                value: 'a',
                key: this.props.name + '_' + 'a' + '_action'
            },
            {
                label: 'B',
                value: 'b',
                key: this.props.name + '_' + 'b' + '_action'
            },
            {
                label: 'C',
                value: 'c',
                key: this.props.name + '_' + 'c' + '_action'
            },
            {
                label: 'D',
                value: 'd',
                key: this.props.name + '_' + 'd' + '_action'
            },
            {
                label: 'E',
                value: 'e',
                key: this.props.name + '_' + 'e' + '_action'
            },
            {
                label: 'F',
                value: 'f',
                key: this.props.name + '_' + 'f' + '_action'
            },
            {
                label: 'G',
                value: 'g',
                key: this.props.name + '_' + 'g' + '_action'
            },
            {
                label: 'H',
                value: 'h',
                key: this.props.name + '_' + 'h' + '_action'
            },
            {
                label: 'I',
                value: 'i',
                key: this.props.name + '_' + 'i' + '_action'
            },
            {
                label: 'J',
                value: 'j',
                key: this.props.name + '_' + 'j' + '_action'
            },
            {
                label: 'K',
                value: 'k',
                key: this.props.name + '_' + 'k' + '_action'
            },
            {
                label: 'L',
                value: 'l',
                key: this.props.name + '_' + 'l' + '_action'
            },
            {
                label: 'M',
                value: 'm',
                key: this.props.name + '_' + 'm' + '_action'
            },
            {
                label: 'N',
                value: 'n',
                key: this.props.name + '_' + 'n' + '_action'
            },
            {
                label: 'O',
                value: 'o',
                key: this.props.name + '_' + 'o' + '_action'
            },
            {
                label: 'P',
                value: 'p',
                key: this.props.name + '_' + 'p' + '_action'
            },
            {
                label: 'Q',
                value: 'q',
                key: this.props.name + '_' + 'q' + '_action'
            },
            {
                label: 'R',
                value: 'r',
                key: this.props.name + '_' + 'r' + '_action'
            },
            {
                label: 'S',
                value: 's',
                key: this.props.name + '_' + 's' + '_action'
            },
            {
                label: 'T',
                value: 't',
                key: this.props.name + '_' + 't' + '_action'
            },
            {
                label: 'U',
                value: 'u',
                key: this.props.name + '_' + 'u' + '_action'
            },
            {
                label: 'V',
                value: 'v',
                key: this.props.name + '_' + 'v' + '_action'
            },
            {
                label: 'W',
                value: 'w',
                key: this.props.name + '_' + 'w' + '_action'
            },
            {
                label: 'X',
                value: 'x',
                key: this.props.name + '_' + 'x' + '_action'
            },
            {
                label: 'Y',
                value: 'y',
                key: this.props.name + '_' + 'y' + '_action'
            },
            {
                label: 'Z',
                value: 'z',
                key: this.props.name + '_' + 'z' + '_action'
            },
        ];

        let value = fd && fd[this.props.name] ? fd[this.props.name] : [];
        value = _.compact(value);
        for (let v in value) {
            value[v] = _.find(this.options, {
                value: this.genLang(value[v], this.props.lang),
            });
            if (value[v] && value[v].label) {
                value[v].label = this.genLang(value[v].label);
            }
        }
        this.state = {
            value: value || [],
            display: this.props.defaultDisplay === false ? false : true,
        };

        if (!props.name) console.error('!!! name is missing !!!');

        if (!props.title) console.error('!!! title is missing !!!');

        if (!props.title) console.error('!!! no options for a select O_o?? !!!');

        this.onChange = this.onChange.bind(this);
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            let form = _.filter(this.options, (e) => {
                for (let f in props.formData[props.name]) {
                    if (e.value === props.formData[props.name][f]) return e;
                }

                return false;
            });

            obj['value'] = form;
            for (let v in obj.value) {
                obj.value[v].label = this.genLang(obj.value[v].label, this.props.lang);
            }
        }

        if (
            JSON.stringify(props.formData[props.name]) !==
            JSON.stringify(prevprops.formData[props.name])
        ) {
            let form = _.filter(this.options, (e) => {
                for (let f in props.formData[props.name]) {
                    if (e.value === props.formData[props.name][f]) return e;
                }

                return false;
            });

            obj['value'] = form;
            // console.log('----====>',form);
            for (let v in obj.value) {
                obj.value[v].label = this.genLang(obj.value[v].label, this.props.lang);
            }

            props.put({
                params: {
                    key: props.name,
                    value: _.map(form, (f) => f.value),
                },
            });
        }

        if (props.lang !== prevprops.lang) obj['lang'] = props.lang;

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    onChange(e) {
        if (!e || !e?.length) {
            this.setState({ value: [] });
            this.props.put({
                params: {
                    key: this.props.name,
                    value: []
                },
            });
        } else {
            this.setState({
                value: e,
            });
            this.props.put({
                params: {
                    key: this.props.name,
                    value: _.map(e, (f) => f.value)
                },
            });
        }
    }

    render() {
        let props = this.props;
        let style = {};
        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        return (
            <>
                <FormGroup>
                    <Select
                        id={this.props.name}
                        tabIndex={props.tabindex}
                        onChange={(e) => {
                            console.log(e, this.props.name);
                            this.setState({ value: e });
                            this.props.onSelectChange(e, this.props.name);
                        }}
                        options={this.options}
                        value={this.options.filter((i) =>
                            this.props.formData[this.props.name]?.includes(i.value)
                        )}
                        name={this.props.name}
                        key={this.props.name}
                        isClearable
                        isSearchable
                        isMulti={!!this.props.isMulti}
                    />

                    {this.state.value?.map((i) => {
                        return (
                            <div className="tw-mt-5">
                                <h5>{this.genLang({ en: 'Category', fr: 'Catégorie' })} {i.label}</h5>
                                <Row>
                                    <Col md={3}>
                                        <RadioInput
                                            lang={this.props.lang}
                                            key={`${this.props.name}_${i.value}_action`}
                                            name={`${this.props.name}_${i.value}_action`}
                                            default={'no'}
                                            options={[
                                                {
                                                    value: 'yes',
                                                    label: 'Oui',
                                                },
                                                {
                                                    value: 'no',
                                                    label: 'Non',
                                                },
                                            ]}
                                            value={
                                                this.props.formData[
                                                    `${this.props.name}_${i.value}_action`
                                                ]
                                            }
                                            formData={this.props.formData}
                                            put={this.props.put}
                                        />
                                    </Col>

                                    {this.props.formData[
                                        `${this.props.name}_${i.value}_action`
                                    ] === 'yes' && (
                                        <Col className="!tw-mt-[-0.75rem]" md={3}>
                                            <NumberInput
                                                requestFields={false}
                                                onChange={this.props.onChange}
                                                requestable={false}
                                                title={this.props.trad['_numberAction']}
                                                formData={this.props.formData}
                                                handleSumActionVote={this.props.handleSumActionVote}
                                                put={this.props.put}
                                                lang={this.props.lang}
                                                key={`${this.props.name}_${i.value}_actionVotantes`}
                                                parentName={this.props.name}
                                                name={`${this.props.name}_${i.value}_actionVotantes`}
                                                value={
                                                    this.props.formData[
                                                        `${this.props.name}_${i.value}_actionVotantes`
                                                    ]
                                                }
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        );
                    })}
                </FormGroup>
            </>
        );
    }
}
