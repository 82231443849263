import React, { Component } from 'react';

import { FormGroup, Input } from 'reactstrap';

import _ from 'lodash';

import { NotesInput } from './NotesInput';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const DEFAULT_STYLE = {
    textAlign: 'left',
    resize: 'vertical !important',
    minHeight: '100px !important'
};

const DEFAULT_ROW = 3;
const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class TextAreaInput extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData;

        this.state = {
            disabled: this.props.disabled,
            value: fd && fd[this.props.name] ? fd[this.props.name] : this.genLang(props.formData[props.name]) || this.genLang(props.default, this.props.lang) || '',
            lang: this.props.lang || FR,
            row: this.props.defaultRows || DEFAULT_ROW,
            display: this.props.defaultDisplay === false ? false : true
        };

        if (!props.name) console.error('!!! name is missing !!!');

        if (!props.title) console.error('!!! title is missing !!!');
    }

    stripHtml(html) {
        let tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    }

    componentDidUpdate(prevprops) {
        let props = this.props, state = this.state;
        let obj = {};

        if (props.display !== prevprops.display) {
            if (props.display === false && prevprops.display === true) {
                obj.display = false;
            } else if (props.display === true && prevprops.display === false) {
                obj.display = true;
            } else if (prevprops.display === undefined) {
                obj.display = props.display;
            }
        }

        if (props.disabled !== prevprops.disabled) {
            obj['disabled'] = props.disabled;
        }

        if (props.forcedValue !== prevprops.forcedValue) {
            obj['value'] = props.forcedValue;
        }

        if (props.version !== prevprops.version) {
            obj['value'] = this.genLang(props.formData[props.name] || props.default, this.state.lang);

            this.props.put({
                params: {
                    key: this.props.name,
                    value: props.formData[props.name] || this.genLang(props.default, obj.lang),
                    title: this.props.title
                }
            });
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            obj['value'] = this.genLang(props.formData[props.name], this.state.lang);

            this.props.put({
                params: {
                    key: this.props.name,
                    value: props.formData[props.name] || this.genLang(props.default, obj.lang),
                    title: this.props.title
                }
            });
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
            if (this.stripHtml(state.value) === this.genLang(props.default || '', FR).replace(/\n+/g, ' ')) {
                obj.value = this.genLang(props.default, obj.lang);

                this.props.put({
                    params: {
                        key: this.props.name,
                        value: props.formData[props.name] || this.genLang(props.default, obj.lang),
                        title: this.props.title
                    }
                });
            }
        }

        if (!_.isEmpty(obj)) this.setState(obj);
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING || text == null) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        return (
            <>
                <FormGroup>
                    <NotesInput
                        {...props}
                        onChange={props.onChange}
                        formData={props.data}
                    />
                    <Input
                        className={'neotextarea'}
                        rows={this.state.row}
                        tabIndex={props.tabindex}
                        value={this.state.value || ''}
                        disabled={this.state.disabled}
                        type={'textarea'}
                        name={props.name}
                        onChange={(e) => {
                            let row;
                            row = e.target.value.split('\n');

                            if (!row.length || row < DEFAULT_ROW) {
                                row = DEFAULT_ROW;
                            } else {
                                row = row.length;
                            }

                            let value = e.target.value;
                            if (props.uppercase) {
                                value = value.toUpperCase();
                            }

                            this.setState({
                                value: value,
                                row: row
                            }, () => {
                                if (this.props.usePut && this.props.put) {
                                    this.props.put({
                                        params: {
                                            key: this.props.name,
                                            value: this.state.value,
                                            title: this.props.title
                                        }
                                    });
                                }
                            });
                        }}
                    />
                </FormGroup>
            </>
        );
    }
}
