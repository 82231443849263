export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export function onLogin({
    params,
    status
}) {
    const p = { status: 200, ...params };

    // p["email"] = params.result.email;
    // p["token"] = params.result.token;
    // // if login v1
    // p["id_users"] = (params.result.profile)?params.result.profile.id_users:0;
    // p["status"] = status;
    // p["userName"] = params.result.profile.firstname;
    // p =

    // console.log(p);
    return {
        type: LOGIN,
        params: p
    };
}

export function onLogout() {
    return {
        type: LOGOUT
    };
}
