import React, { useContext } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import { StatusChip } from '@/components/psj/StatusChip';
import { FolderContext } from '@/pages/psj/Folder';
import { cn } from '@/lib/utils';
import { Skeleton } from '@/components/ui/skeleton';
import { useOverrideConfig } from '@/composables/override';
import { EditFolderDialog } from '../folder/EditFolderDialog';
import { type CaseInfo } from '@/types/folder';
import { type Note } from '@/types/api/note';

export function FolderSummary() {
    const { t, to } = useTranslation('psj.summary');
    const context = useContext(FolderContext);
    const override = useOverrideConfig();
    const onUpdated = (name?: string, note?: Note) => {
        const newCaseInfo: CaseInfo = {
            ...context?.case,
            _id: context?.case?._id ?? '',
            name: name ?? '',
            status: context?.case?.status ?? 'open',
            groups: context?.case?.groups ?? [],
            folder_id: context?.case?.folder_id ?? '',
            updated_at: context?.case?.updated_at ?? '',
            created_at: context?.case?.created_at ?? ''
        };
        context?.setCaseInfo(newCaseInfo);
        if (note) {
            context?.setNotes([note, ...context?.notes ?? []]);
        }
    };
    return (
        <Card>
            <CardContent className={cn(
                '!tw-pb-3 !tw-pt-5 tw-grid tw-gap-y-2 tw-gap-x-6 md:tw-gap-x-10 lg:tw-gap-x-16',
                'tw-grid tw-grid-cols-[repeat(4,_auto)] tw-grid-rows-[repeat(2,_auto)]',
                'tw-grid-flow-col tw-justify-center tw-items-center'
            )}>
                <h6 className="tw-text-center tw-mb-0">{t('title')}</h6>
                <div className="tw-text tw-text-center tw-text-primary tw-font-semibold tw-text-md">
                    {context?.loading
                        ? <Skeleton className="tw-h-5 tw-w-[120px]" />
                        : `${context?.case?.name} - ${context?.folder_id}`}
                    {context?.case && <EditFolderDialog
                        case_id={context?.case._id}
                        case_name={context?.case?.name ?? ''}
                        onUpdated={onUpdated} />
                    }
                </div>
                {!override?.is_customer_portal && <>
                    <h6 className="tw-text-center tw-mb-0">{t('assign')}</h6>
                    <div className="tw-text tw-text-center tw-text-primary tw-font-semibold tw-text-md">
                        {context?.loading
                            ? <Skeleton className="tw-h-5 tw-w-[120px]"/>
                            : to(context?.case?.assign?.name)}
                    </div>
                    <h6 className="tw-text-center tw-mb-0">{t('step')}</h6>
                    <div className="tw-text tw-text-center tw-text-primary tw-font-semibold tw-text-md">
                        {context?.loading
                            ? <Skeleton className="tw-h-5 tw-w-[120px]"/>
                            : to(context?.case?.nextstep?.title)}
                    </div>
                </>}
                <h6 className="tw-text-center tw-mb-0">{t('status')}</h6>
                <div className="tw-text-center">
                    {context?.loading
                        ? <Skeleton className="tw-h-5 tw-w-[120px]"/>
                        : <StatusChip status={context?.case?.status ?? 'open'}/>}
                </div>
            </CardContent>
        </Card>
    );
}
