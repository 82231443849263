import React from 'react';

const trad = {
    formWitness: {
        en: 'Witnessed will',
        fr: 'Formulaire testament devant témoins'
    },
    formStep: {
        fr: 'Veuillez suivre les étapes suivantes afin de générer votre testament automatiquement :',
        en: 'Please complete the following steps to generate your will:'
    },
    formStepComplete: {
        fr: 'Complétez les champs du formulaire selon votre situation personnelle',
        en: 'Complete the fields according to your personal situation'
    },
    formStepPress: {
        fr: 'Appuyez sur le bouton vert en haut de la page à droite',
        en: 'Click on the green button located on the upper right-hand side '
    },
    formStepVisual: {
        fr: 'Visualisez votre testament et faites les modifications nécessaires',
        en: 'Preview your will and make adjustments as necessary'
    },
    formStepDownload: {
        fr: 'Télécharger votre testament en format Word ou PDF pour impression',
        en: 'Download your will as a DOC or PDF to print'
    },
    formStepSign: {
        fr: 'Signez votre testament devant 2 témoins',
        en: 'Sign your testament in front of two witnesses'
    },
    testatorDefinition: {
        fr: 'La personne qui à sa mort désire faire connaître ses volontés par le biais de ce formulaire',
        en: 'The author of the will'
    },
    position: {
        en: 'Position (CEO,PDG...)',
        fr: 'Position (CEO,PDG...)'
    },
    addHeirSousOrdre: {
        fr: 'Ajouter un héritier en sous-ordre',
        en: 'Add heirs (in order) '
    },
    mainLiquidator: {
        fr: 'Liquidateurs principaux ',
        en: 'Primary liquidators'
    },
    backupLiquidator: {
        fr: 'Liquidateur de remplacement',
        en: 'Secondary liquidators'
    },
    tutorDatif: {
        fr: 'Tuteur Datif (une seule personne)',
        en: 'Dative Tutorship (a single person)'
    },
    tutorDatifBackup: {
        fr: 'Remplaçants en cas d\'indisponibilité',
        en: 'Replacements in case of absence'
    },
    tutorDatifBackupText: {
        fr: 'Veuillez ajouter des remplacements au cas ou le la personne nommée en tant que tuteur datif ne puisse pas mener à bien ce mandat.',
        en: 'Please indicate substitutes in case the person appointed as the Dative Tutor cannot carry out this mandate.'
    },
    animal: {
        fr: 'Animaux de compagnie',
        en: 'Pets'
    },
    animalOwnership: {
        fr: 'Possédez-vous des animaux de compagnie ?',
        en: 'Do you have any pets?'
    },
    animalToWhom: {
        fr: 'À qui laissez-vous la garde ?',
        en: 'To whom does the responsibility of your pet go?'
    },
    endOfForm: {
        fr: 'Fin du formulaire',
        en: 'End of form'
    },
    documentLang: {
        fr: 'Langue du document',
        en: 'Document language'
    },
    heirDefinition: {
        fr: 'Liste des héritiers (legs universel)',
        en: 'List of heirs (universal bequest)'
    },
    heirDescendent: {
        fr: 'Les descendants des héritiers remplacent ceux-ci en cas de décès',
        en: 'The descendants of the heirs take over in the event of death'
    },
    heirSousOrdre: {
        fr: 'Héritiers en sous-ordre',
        en: 'Heirs (in order)'
    },
    Anglais: {
        fr: 'Anglais',
        en: 'English'
    },
    Francais: {
        fr: 'Français',
        en: 'French'
    },
    inheritChildren: {
        en: 'Their children',
        fr: 'Ses enfants'
    },
    legacyBackup: {
        en: 'Other',
        fr: 'Autre'
    },
    backup: {
        en: 'Backup',
        fr: 'Remplaçant'
    },
    sharedEqualy: {
        en: 'Shared equally amongst other heirs',
        fr: '"Partagé à part égale parmi les autres héritiers"'
    },
    diesBeforeInherit: {
        fr: 'Si cette personne décède avant vous, qui héritera de sa part ?',
        en: 'If this person dies before you, who will receive their portion of inheritance?'
    },
    foreignRegime: {
        fr: 'régime étranger',
        en: 'foreign regime'
    },
    goodsCommunity: {
        fr: 'Communautés de biens',
        en: 'Community of property'
    },
    universalShare: {
        fr: 'Partage du bien universel',
        en: 'Sharing of property',
    },
    equalShare: {
        fr: 'À parts égales',
        en: 'At equal share'
    },
    property: {
        en: 'Property',
        fr: 'Bien'
    },
    sum: {
        en: 'Sum ',
        fr: 'Somme'
    },
    vehicle: {
        en: 'Vehicles',
        fr: 'Tout automobile que je possède à mon décès'
    },
    vehicle_specific: {
        en: 'Specified Vehicle',
        fr: 'Automobile spécifique'
    },
    house: {
        en: 'Real estate asset',
        fr: 'Immeuble'
    },
    house_specific: {
        en: 'Specified real estate asset',
        fr: 'Immeuble spécifique'
    },
    vacant: {
        en: 'Vacant land',
        fr: 'Terrain vacant'
    },
    jewelAll: {
        en: 'Jewelry',
        fr: 'L\'ensemble des bijoux'
    },
    jewel: {
        en: 'A specific piece of jewelry',
        fr: 'Bijoux en particulier'
    },
    paintings: {
        en: 'Paintings',
        fr: 'Tableaux ou peinture'
    },
    tools: {
        en: 'Tools',
        fr: 'Outils'
    },
    RESPS: {
        en: 'RESPs',
        fr: 'REÉÉ'
    },
    registeredSaving: {
        en: 'Registered Retirement Savings Plan',
        fr: 'régime enregistré d’épargne retraite (REER)'
    },
    celi: {
        en: 'Tax-Free Savings Account',
        fr: 'compte d\'épargne libre d\'impôt (CELI)'
    },
    registeredRetirement: {
        en: 'Registered Retirement Income Fund',
        fr: 'fonds enregistré de revenu de retraite (REER)'
    },
    registeredPension: {
        en: 'Registered Pension Plans or Deferred Profit Sharing plan',
        fr: 'régime de pension agréé ou régime de participation différée'
    },
    universal: {
        fr: 'universel',
        en: 'universal'
    },
    particular: {
        fr: 'Particulier',
        en: 'Individual'
    },
    bequestHelp: {
        fr: <>le légataire universel bénéficie de tous les biens légués<br />le légataire à titre universel bénéficie
            d'un
            poucentage des biens légués le légataire particulier reçoit en héritage un bien spécifique et identifié de
            l'auteur du testament.</>,
        en: <>The universal legatee benefits from all bequeathed property <br /> the legatee on a universal basis
            benefits
            from a percentage of the bequeathed property the legatee receives in inheritance a specific and identified
            property from the author of the will.</>
    },
    bequestType: {
        en: 'Type of bequest ',
        fr: 'Type de Leg'
    },
    bequestCategory: {
        en: 'Category of bequest ',
        fr: 'Catégorie du Leg'
    },
    addBequest: {
        en: 'Add bequest',
        fr: 'Ajouter un Leg'
    },
    bequest: {
        en: 'Bequest',
        fr: 'Legs'
    },
    bequestInfo: {
        fr: 'Legs : Un don par testament. Un legs peut être à titre particulier, à titre universel ou universel.',
        en: 'Bequest: One donation per will. A bequest can be specific or universal.'
    },
    healthInsurance: {
        en: 'Insurance number',
        fr: 'Numéro d\'assurance maladie'
    },
    organTransplantation: {
        fr: 'Uniquement pour les transplantation ',
        en: 'Only for transplantation'
    },
    organScience: {
        fr: 'don d\'organes et recherche scientifiques',
        en: 'organ donation and scientific research'
    },
    organConsent: {
        en: 'Do you consent to organ and tissue donation?',
        fr: 'Consentez-vous au don d’organe et de tissus ? '
    },
    organInfo: {
        en: 'For more information',
        fr: 'Pour plus d\'informations '
    },
    organInfoLink: {
        fr: 'quebec.ca/sante/don-de-sang-de-tissus-et-d-organes/don-d-organes-et-de-tissus/',
        en: 'https://www.quebec.ca/en/health/blood-tissue-and-organ-donation/organ-and-tissue-donation/'
    },
    undecided: {
        fr: 'Indécis',
        en: 'Undecided'
    },
    stubborness: {
        en: 'Do you want to express your refusal in the face of any form of determination to extend your life - in the circumstances where there is no chance of recovering an acceptable quality of life?',
        fr: 'Voulez-vous exprimer votre refus face à toute forme d’acharnement à prolonger votre vie, en circonstance où il n’existe pas de chance de récupérer une qualité de vie acceptable ?'
    },
    specialWishes: {
        en: 'Organ donation',
        fr: 'Don d\'organes'
    },
    Spouse_children: {
        en: 'Spouse and children',
        fr: 'Époux et enfants'
    },
    nobody: {
        en: 'none of these options',
        fr: 'aucun de ces choix'
    },
    select: {
        en: 'Select',
        fr: 'Sélectionnez'
    },
    familySecurity: {
        en: 'I allow the use of my property for the benefit of',
        fr: 'Je permet l\'utilisation de mes biens au bénéfice de'
    },
    funeralCompany: {
        fr: 'Avec quelle compagnie',
        en: 'Which company'
    },
    funeralPrecision: {
        en: 'How would you like your body to be handled?',
        fr: 'Voulez-vous préciser vos volontées quant à la disposition de votre corps?'
    },
    funeralAllow: {
        fr: 'Je laisse le soin de mes funérailles à la discrétion de mon liquidateur',
        en: 'I allow my liquidator to organize my funeral'
    },
    heirBackupInfo: {
        fr: 'Héritiers de remplacement',
        en: 'Remplacement heir'
    },
    dativeInfo: {
        fr: 'Une seule personne peut prendre la charge de vos enfants au moment de votre décès',
        en: 'Only one person can be named to be the tutor'
    },
    etatCivilLink: {
        fr: 'http://www.etatcivil.gouv.qc.ca/fr/FAQ.html',
        en: 'http://www.etatcivil.gouv.qc.ca/en/FAQ.html'
    },
    dateContract: {
        fr: 'Date du contrat',
        en: 'Sign date'
    },
    since: {
        fr: 'Depuis le',
        en: 'since'
    },
    _dateFormat: {
        fr: 'jj/mm/aaaa',
        en: 'dd/mm/yyyy'
    },
    _dateFormat2: {
        fr: 'mm/aaaa',
        en: 'mm/yyyy'
    },
    funeralResponsible: {
        en: 'The person/people responsible for my funeral:',
        fr: 'Je laisse le soin de mes funérailles à la discrétion de :'
    },
    funeralSpouse: {
        en: 'My spouse',
        fr: 'Mon/Ma conjoint/e'
    },

    funeralChildren: {
        en: 'My children',
        fr: 'Mes enfants'
    },
    funeralLiquidator: {
        en: 'Liquidator',
        fr: 'Le liquidateur'
    },
    funeralDesire: {
        en: 'I would like to be:',
        fr: 'Je désire :'
    },
    cemeteryName: {
        en: 'Name of cemetery',
        fr: 'Nom du cimetière'
    },
    buried: {
        en: 'Buried',
        fr: 'Être inhumé (enterré)'
    },
    cremated: {
        en: 'Cremated',
        fr: 'Incinéré'
    },
    aquamated: {
        en: 'Alkaline hydrolysis',
        fr: 'Aquamation'
    },
    funeralCelebration: {
        en: 'The ceremony will be celebrated in:',
        fr: 'La cérémonie sera célébré dans:'
    },
    worshipPlace: {
        en: 'A place of worship',
        fr: 'Un lieu culte'
    },
    funeralHome: {
        en: 'funeralHome',
        fr: 'Maison funéraire'
    },
    liquidatorChoice: {
        en: 'At the choice of the liquidator',
        fr: 'Au choix du liquidateur'
    },
    funeralPlace: {
        fr: 'Définissez le lieu de la cérémonie, laissez vide si identique au lieu de l\'enterrement',
        en: 'Please specify where the ceremony will be held Leave this field empty if it is the same as the burying site '
    },
    funeralReligiousName: {
        fr: 'Nom du lieu culte (paroise ou autre)',
        en: 'Place of worship (parish or other)'
    },
    funeralCeremonyDetails: {
        fr: 'Précisions au sujet de la cérémonie',
        en: 'Details about the ceremony'
    },
    funeralExposition: {
        fr: 'Je désire que mon corps soit exposé pendant combien de jours',
        en: 'I would like the casket or urn to be exposed this many days',
    },
    funeralArrangments: {
        fr: 'Avez-vous contracté un arrangement funéraire préalable ?',
        en: 'Have you made a prior funeral arrangement?'
    },
    funeralContract: {
        fr: 'Votre numéro de contrat',
        en: 'Contract number'
    },
    withContract: {
        en: 'With contract',
        fr: 'Avec contrat'
    },
    withoutContract: {
        en: 'Without contract',
        fr: 'Sans contrat'
    },
    relationship: {
        en: 'Relationship with you',
        fr: 'Relation avec vous'
    },
    relationship2: {
        en: 'Relationship',
        fr: 'Relation'
    },
    inheritanceInPercentage: {
        en: 'Inheritance shares in percentage',
        fr: 'Parts d\'héritage en pourcentage'
    },
    beforeWedding: {
        fr: 'Au moment de notre mariage nous étions domicilié ailleurs',
        en: 'At the time of our marriage, we were domiciled elsewhere'
    },
    dateOfBirth: {
        fr: 'Date de naissance',
        en: 'Date of birth'
    },
    inheritAge: {
        fr: 'Âge éligible à la succession',
        en: 'Age eligible for inheritance'
    },
    afterWillContact: {
        fr: 'Contacter cette personne une fois le document officialisé',
        en: 'Contact this person once the document has been formalized'
    },

    _district: {
        en: 'District',
        fr: 'District'
    },
    _name: {
        en: 'Last name',
        fr: 'Nom'
    },
    _firstname: {
        en: 'First name',
        fr: 'Prénom'
    },
    _title: {
        en: 'Gender',
        fr: 'Sexe'
    },
    _sir: {
        en: 'Sir',
        fr: 'Monsieur'
    },
    _madam: {
        en: 'Madam',
        fr: 'Madame'
    },
    _phone: {
        en: 'Phone number',
        fr: 'Téléphone'
    },
    _email: {
        en: 'Email',
        fr: 'Courriel'
    },
    _street: {
        en: 'Street',
        fr: 'Rue'
    },
    _street2: {
        en: 'Street address',
        fr: 'Adresse'
    },
    _city: {
        en: 'City',
        fr: 'Ville'
    },
    _postal: {
        en: 'Postal code',
        fr: 'Code postal'
    },
    _province: {
        en: 'Province',
        fr: 'Province'
    },
    _country: {
        en: 'Country',
        fr: 'Pays'
    },
    _other: {
        fr: 'Autre',
        en: 'Other'
    },
    representativeBackup: {
        en: 'Replacement',
        fr: 'Remplaçant'
    },
    representativeAssets: {
        en: 'Responsible for my assets',
        fr: 'Responsable de mon patrimoine'
    },
    representativePerson: {
        en: 'Responsible of my person',
        fr: 'Responsable de ma personne'
    },
    divorceName: {
        en: 'Name',
        fr: 'Nom'
    },
    divorce: {
        en: 'Divorced',
        fr: 'Divorcé'
    },
    divorceFirstname: {
        en: 'Firstname',
        fr: 'Prénom'
    },
    dwellAt: {
        en: 'Residing at',
        fr: 'Réside à'
    },
    divorceCourt: {
        fr: 'District de la cour supérieure',
        en: 'Superior Court District'
    },
    divorceNumber: {
        fr: '# de dossier',
        en: 'file number'
    },
    divorceDate: {
        fr: 'Date du divorce',
        en: 'Divorce date'
    },

    deathDate: {
        fr: 'Date du décès',
        en: 'Date of death'
    },
    married: {
        en: 'Married',
        fr: 'Marié'
    },
    divorceDocuments: {
        en: 'We require all documents pertaining to your divorce during the appointment',
        fr: 'Nous avons besoin de tous les documents prouvant votre divorce lors de votre appel'
    },
    commonLawSpouse: {
        en: 'Common-law spouse',
        fr: 'Conjoint de fait'
    },
    widow: {
        en: 'widowed',
        fr: 'Veuf/ve'
    },
    single: {
        en: 'Single',
        fr: 'Célibataire'
    },
    beenDivorced: {
        en: 'Have you ever been divorced?',
        fr: 'Avez-vous déjà divorcé par le passé ?'
    },
    mariedContractNotary: {
        fr: 'Avez-vous signé un document devant notaire avant la célébration ?',
        en: 'Have you signed a document in front of notary prior to the ceremony?'
    },
    maritalStatus: {
        fr: 'État civil',
        en: 'Marital status'
    },
    copyEvery: {
        fr: <>Copier ce choix pour <em>tous</em> les enfants.</>,
        en: <>Copy for all children</>
    },
    heir: {
        fr: 'Héritiers',
        en: 'Heirs'
    },
    representative: {
        fr: 'Mandataires',
        en: 'Mandatary'
    },
    mandate: {
        fr: 'Mandat',
        en: 'Mandate'
    },
    testateur: {
        fr: 'Testateur',
        en: 'Testator'
    },
    liquidator: {
        fr: 'Liquidateur',
        en: 'Liquidator'
    },
    weddingNumberFile: {
        fr: 'Numéro de dossier',
        en: 'File number'
    },
    weddingNumber: {
        fr: 'Sous quel numéro de ses minutes ?',
        en: 'Under which minute number? '
    },
    vieCommune: {
        fr: 'Date de vie commune',
        en: 'Date of common-law'
    },
    conjointStatusExplain: {
        fr: 'Conjoint de fait (ne modifie pas votre état civil)',
        en: 'Common-law partner (does not change your marital status)'
    },
    matrimonialRegime: {
        fr: 'Sous quel régime matrimonial ?',
        en: 'Under which matrimonial regime?'
    },
    none: {
        fr: 'Aucun',
        en: 'None'
    },
    other: {
        fr: 'Autre',
        en: 'Other'
    },
    goodsSplit: {
        fr: 'séparation de biens',
        en: 'Separation of property'
    },
    factualSeparation: {
        fr: 'Séparé de fait',
        en: 'De facto separated'
    },

    infoConjoint: {
        fr: 'Information conjoint',
        en: 'Information of common-law partner'
    },

    infoConjointDead: {
        fr: 'Informations relatives au décès de votre conjoint',
        en: 'Relevant information of common-law partner\'s death'
    },

    partnershipAcquest: {
        fr: 'société d\'acquêts',
        en: 'Partnership of acquest'
    },
    law42: {
        en: 'We signed an agreement which excludes the application of the rules relating to family patrimony under article 42 before the notary (see below).',
        fr: ' Nous avons signé une convention excluant l’application des règles relatives au patrimoine familial en vertu de l’article 42 devant le notaire ci-bas.'
    },
    description: {
        en: 'Description',
        fr: 'Description'
    },
    spouse: {
        fr: 'Conjoint',
        en: 'Spouse'
    },
    lastDivorce: {
        fr: 'Prenant votre dernier divorce, identifiez votre ex-conjoint',
        en: 'Please identify your recent ex-spouse'
    },
    weddingContract: {
        fr: 'Nous avons besoin d\'une copie de votre dernier contrat de mariage, veuillez nous l\'envoyer par votre portail Neolegal.',
        en: 'Please provide a copy of your last marriage contract through your Neolegal portal.'
    },
    spouseLegacy: {
        fr: 'Je désire léguer tous les biens dont je suis copropriétaire à mon/ma conjoint/e',
        en: 'I wish to bequeath all property, of which I am co-owner, to my spouse.'
    },
    define: {
        fr: 'Précisez',
        en: 'Define'
    },
    _define: {
        fr: 'Précisez',
        en: 'Specify'
    },
    minuteBooks: {
        fr: 'livre des minutes',
        en: 'minute books'
    },
    representativeWatcher: {
        fr: 'Il devra rendre des comptes à quelqu\'un',
        en: 'Hold someone accountable'
    },
    representativeWatcherTarget: {
        fr: 'Personne à qui il devra des comptes',
        en: 'Mandatary supervisor'
    },
    registrationDivision: {
        en: 'Which registration division?',
        fr: 'Quelle circonscription foncière ?'
    },
    currentRelation: {
        fr: 'Vos informations',
        en: 'Your informations'
    },
    weddingDate: {
        fr: 'Date de la cérémonie',
        en: 'Celebration date'
    },
    whichNotary: {
        en: 'In front of which notary?',
        fr: 'Devant quel notaire ?'
    },
    yes: {
        fr: 'Oui',
        en: 'Yes'
    },
    no: {
        fr: 'Non',
        en: 'No'
    },
    funeralDisposition: {
        fr: 'Dispositions funéraires',
        en: 'Funeral arrangment'
    },
    mentalIncapacity: {
        fr: 'En cas d’inaptitude mentale',
        en: 'Protection mandate in case of incapacity'
    },
    addMandatory: {
        fr: 'Ajouter un mandataire',
        en: 'Add mandatory'
    },
    mandatory: {
        fr: 'Personne à qui est conféré un mandat',
        en: 'The person responsible of you in case of incapacity'
    },
    addHeir: {
        fr: 'Ajouter un héritier',
        en: 'Add heir'
    },
    liquidatorExpense: {
        fr: 'La somme reccomandée est de 1000$ à 10000$ selon l\'importance de votre patrimoine.',
        en: 'The suggested sum is between $1,000 and $10,000, according to the value of patrimony.'
    },

    liquidatorFrequency: {
        fr: 'À l\'aniverssaire du décès ou à chaque mois',
        en: 'Annualy or every month'
    },
    testateurHelp: {
        fr: 'Vous ou l\'auteur du testament',
        en: 'You or testator'
    },
    heirHelp: {
        fr: 'Personne qui doit recevoir ou qui reçoit des biens en héritage.',
        en: 'Person who is to receive or receives inheritance goods.'
    },
    liquidatorHelp: {
        fr: 'Personne ayant pour mission de procéder à la liquidation d\'une masse de biens.',
        en: 'The person tasked to execute the liquidation.'
    },
    liquidatorMarital: {
        fr: 'Mon conjoint ou ma conjointe est liquidataire.',
        en: 'My husband or spouse is my liquidator.'
    },
    maritalInfoLink: {
        fr: 'http://www.etatcivil.gouv.qc.ca/fr/FAQ.html',
        en: 'http://www.etatcivil.gouv.qc.ca/en/FAQ.html'
    },
    addLiquidator: {
        fr: 'Ajouter un liquidateur de remplacement',
        en: 'Add secondary liquidator'
    },
    liquidatorShareTask: {
        fr: 'Les liquidataires se partageront les tâches.',
        en: 'Liquidators will share equally all tasks.'
    },
    willForm: {
        en: 'Will form',
        fr: 'Formulaire testament'
    },
    willmandateForm: {
        en: 'Mandate form',
        fr: 'Formulaire mandat'
    },
    fillAll: {
        fr: 'S\'il vous plait veuillez renseigner tous ces champs',
        en: 'Please fill this form completely.'
    },
    firstPick: {
        en: 'First choice',
        fr: 'Premier choix'
    },
    wrongKey: {
        fr: <em style={{ color: 'red' }}>Mauvaise clef de traduction</em>,
        en: <em style={{ color: 'red' }}>Wrong translation key</em>
    },
    _wrongKey: {
        fr: 'Mauvaise clef de traduction',
        en: 'Wrong translation key'
    },
    missing: {
        fr: <em style={{ color: 'red' }}>la traduction est manquante</em>,
        en: <em style={{ color: 'red' }}>missing translation</em>
    },
    placeholder: {
        fr: 'Si connu, remplissez',
        en: 'To fill, if known'
    },
    h1: {
        fr: 'Formulaire incorporation',
        en: 'Incorporation Form'
    },
    answerAll: {
        fr: 'Merci de répondre à toutes les questions ci-dessous',
        en: 'Please answer all questions below'
    },
    fileslang: {
        en: 'In which langage do you want the incorporation documents?',
        fr: 'En quelle langue désirez-vous les documents d\'incorporation ?'
    },
    french: {
        en: 'French',
        fr: 'Français'
    },
    english: {
        en: 'English',
        fr: 'Anglais'
    },
    origin: {
        fr: 'Origine et explication du nom de l\'entreprise',
        en: 'Explaination and origin of company\'s name'
    },
    name: {
        fr: 'Le nom de l’entreprise',
        en: 'Name of the company'
    },
    restriction: {
        fr: 'Décrivez les activités exploitées par la société et ses restrictions, le cas échéant.',
        en: 'Describe the activities operated by the company and its restrictions, if any.'
    },
    maxAdmin: {
        fr: 'Le nombre minimal et maximal d\'administrateurs (minimum 1, nous reccomandons un maximum de 10)',
        en: 'Minimum and maximum number of directors (Between 1 and 10)'
    },
    maxAdminSmall: {
        fr: 'Nous vous suggérons un minimum de 1 et un maximum de 10, si applicable.',
        en: 'We suggest a minimum of 1 and a maximum of 10, when applicable.'
    },
    actionCategory: {
        fr: 'Les catégories et le nombre maximal d\'actions que la société est autorisée à émettre.',
        en: 'The categories and the maximum number of shares that the company is authorized to issue.'
    },
    actionCategorySmall: {
        fr: 'Nous pouvons fournir la description standard de capital-actions, soit des actions ordinaires et les différentes catégories d\'actions privilégiées',
        en: 'We can provide the standard share capital description, in essence common shares and different classes of preferred shares.'
    },
    admin: {
        fr: 'Le nombre minimal et maximal d\'administrateurs (nous vous suggérons un minimum de 1 et un maximum de 10, si applicable)',
        en: 'Minimum and maximum number of directors (We suggest a minimum of 1 and a maximum of 10, si applicable'
    },
    address: {
        fr: 'L\'adresse du siège social de la société',
        en: 'The address of the registered office of the corporation'
    },
    addressSmall: {
        fr: <br />,
        en: <br />
    },

    socialInsuranceNumber: {
        fr: 'Numéro d\'assurance sociale',
        en: 'Social insurance number'
    },
    postalAddress: {
        fr: 'L\'adresse postale de la société',
        en: 'The mailing address of the company'
    },
    postalSmall: {
        fr: 'Si différent de l’adresse du siège social',
        en: 'If different from the registered office address'
    },
    postForm: {
        fr: 'Suite à l\'incorporation de l\'entreprise, nous aurons besoin de l\'organiser, notamment par la création du livre de minutes. À cet effet, veuillez nous fournir les informations suivantes :',
        en: 'After incorporating, we will need to create the minute book. For this purpose, please provide the following information:'
    },
    shareHolders: {
        fr: 'Le nom et l\'adresse des actionnaires et le nombre (pourcentage) et la catégorie d’actions à souscrire par les actionnaires.',
        en: 'The name and address of the shareholders, along with their respective amount and the category of shares.'
    },
    shareHoldersSmall: {
        fr: 'Généralement cent actions de classe A pour une société de portefeuille',
        en: 'There are usually one hundred Class A shares class for a holding company.'
    },
    actionPrice: {
        fr: 'Le prix à payer par chaque actionnaire pour la souscription d\'actions',
        en: 'The price to be paid by each shareholder for the subscription of shares'
    },
    actionPriceSmall: {
        fr: 'Généralement un dollar par action pour une société de portefeuille',
        en: 'There is generally one dollar per share for a holding company.'
    },
    date: {
        fr: 'La date de la fin de l\'année financière de la société.',
        en: 'The financial year end date of the corporation.'
    },
    adminInfo: {
        fr: 'Le nom et l\'adresse des administrateurs de la société',
        en: 'The name and address of the directors of the corporation'
    },
    adminInfoSmall: {
        en: 'if not the same as mentioned in the incorporation',
        fr: 'Si cela diffère de celle(s) mentionnée(s) au moment de la constitution)'
    },
    importantPersonInfo: {
        en: 'The name and address of the officers of the corporation',
        fr: 'Le nom et l\'adresse des dirigeants de la société '
    },
    importantPersonInfoSmall: {
        en: 'President, Vice President, CEO, CFO, Secretary, etc.',
        fr: 'Président, le vice-président, CEO, CFO, secrétaire, etc'
    },
    audit: {
        fr: 'Le nom et l\'adresse des auditeurs/comptables de la société',
        en: 'The name and address of the auditors of the corporation'
    },
    auditSmall: {
        fr: 'Si aucun n’est nommé, le nom et l\'adresse du comptable',
        en: 'If no auditors are appointed, the name and address of the accountant'
    },
    employees: {
        en: 'The number of employees of the corporation, if any',
        fr: 'Le nombre d\'employés de la société'
    },
    hq: {
        en: 'If the registered office of the corporation is not in Québec, but the company has a location in Quebec, please specify the address.',
        fr: 'L\'adresse de l’entreprise au Québec'
    },

    hqSmall: {
        en: <span style={{ color: 'red' }}>**If the registered office of the corporation is not in Québec**</span>,
        fr: <span style={{ color: 'red' }}>**Si le siège social de l'entreprise n'est pas au Québec**</span>
    },
    otherName: {
        fr: 'Autres noms de la société qui peut être utilisé au Québec',
        en: 'Alternative company names'
    },
    submit: {
        fr: 'Soumettre',
        en: 'Submit'
    },
    otherLang: {
        fr: 'EN',
        en: 'FR'
    },

    tutor: {
        fr: 'Tuteurs',
        en: 'Tutors'
    },
    tutorNoS: {
        fr: 'Tuteur',
        en: 'Tutor'
    },
    principal: {
        fr: 'Mandant',
        en: 'Principal'
    },
    tutorForChildren: {
        fr: 'Tuteurs des enfants',
        en: 'Tutor for your children'
    },

    addTutor: {
        fr: 'Ajouter un tuteur de remplacement',
        en: 'Add a backup tutor'
    },

    child: {
        en: 'Child',
        fr: 'Enfant'
    },
    children: {
        en: 'Your children',
        fr: 'Vos enfants'
    },

    addChildren: {
        en: 'Add children',
        fr: 'Ajouter un enfant'
    },
    minorHeir: {
        en: 'Children',
        fr: 'L\'enfant'
    },
    wealth: {
        en: 'Wealth',
        fr: 'Fortune'
    },
    both: {
        en: 'Both',
        fr: 'Les deux'
    },
    childrenMinor: {
        en: 'Only children of minor age',
        fr: 'Seulement les enfants d\'âge mineur'
    },
    _certificatDate: {
        en: 'Date of Certificate',
        fr: 'Date de Certificat'
    },
    _certificatNumber: {
        en: 'Certificat Number',
        fr: 'Numéro de Certificat'
    },
    _actionNumber: {
        en: 'Actions Number',
        fr: 'Nombre d\'actions'
    },
    _serialNumber: {
        en: 'Serial number',
        fr: 'Numéro de série'
    },
    _categories: {
        en: 'Categorie of certificat',
        fr: 'Catégorie de la certificat'
    },

    _certificatList: {
        en: 'Those represented by this certificate are:',
        fr: 'Les représentées par ce certificat sont :'
    },

    _declarationList: {
        en: 'Declaration',
        fr: 'Déclaration'
    },

    _certificatInfos: {
        en: 'Certificat Informations',
        fr: 'Informations de la certificat'
    },

    _citizen: {
        en: 'Canadian citizen',
        fr: 'Citoyen canadien'
    },

    _permanentResident: {
        en: 'Permanent resident',
        fr: 'Résident permanent'
    },

    _notResident: {
        en: 'Not resident',
        fr: 'Non résident'
    },

    _startdate: {
        en: 'Registration date as a shareholder',
        fr: `Date d'enregistrement à titre d'actionnaire`
    },

    _enddate: {
        en: 'End of registration',
        fr: 'Fin d\'enregistrement'
    },

    zqwnamecny_x: {
        en: 'Company Name',
        fr: 'Nom de Compagnie'
    },
    _language: {
        en: 'Language',
        fr: 'Langue'
    },
    zqwend_financial_year_x: {
        en: 'End financial year',
        fr: 'Fin année fiscal'
    },
    zqwjurisdiction_x: {
        en: 'Jurisdiction',
        fr: 'Juridiction'
    },
    zqwcity_x: {
        en: 'City',
        fr: 'Ville'
    },
    zqwprovince_x: {
        en: 'Province',
        fr: 'Province'
    },
    zqwcp_x: {
        en: 'Postal Code',
        fr: 'Code Postal'
    },
    zqwaddress_x: {
        en: 'Address',
        fr: 'Adresse'
    },
    _Autre: {
        en: 'Other',
        fr: 'Autre'
    },
    _neomed: {
        en: 'Please chose a template when it applies, if there is no template, please select if template doesn\'t exist.',
        fr: 'Prière de choisir le modèles de mise en demeure applicable, et si celui-ci n\'est pas encore disponible ou créé, merci de choisir modèle de mise en demeure non existant.'
    },
    _domain: {
        en: 'Domain',
        fr: 'Domaine'
    },
    _subdomain: {
        en: 'Sub-Domain',
        fr: 'Sous-Domaine'
    },
    _modele: {
        en: 'Model',
        fr: 'Modèle'
    },
    _btncreer: {
        en: 'Create',
        fr: 'Créer'
    },
    _btnmodifier: {
        en: 'modify',
        fr: 'Modifier'
    },
    _btnsearch: {
        en: 'Search',
        fr: 'Recherche'
    },
    _actdoclbl: {
        en: ' Identification card (driver\'s license, health insurance card or passport)',
        fr: 'Pièce d\'identité (veuillez ajouter l\'un des documents suivants : permis de conduire, carte d\'assurance maladie ou passeport) '
    },
    _btnadd: {
        en: 'Add',
        fr: 'Ajouter'
    },
    Ajouter: {
        en: 'Add',
        fr: 'Ajouter'
    },
    _sample002: {
        en: 'Registrationdate',
        fr: 'Dateenregistrement'
    },
    _adminSign: {
        en: 'Signatory',
        fr: 'Signataire'
    },
    _neoChangeMedModel: {
        en: 'ATTENTION, cette opération permet de modifier le modèle de la mise en demeure',
        fr: 'ATTENTION, cette opération permet de modifier le modèle de la mise en demeure.'
    },
    _neoChangeModelCheckBox: {
        en: 'Changer le modèle de cette mise en demeure.',
        fr: 'Changer le modèle de cette mise en demeure.'
    },

    _maleGender: {
        en: 'Male',
        fr: 'Monsieur'
    },

    _femaleGender: {
        en: 'Female',
        fr: 'Madame'
    },
    _otherGender: {
        en: 'Prefer not to disclose',
        fr: 'Préfère ne pas divulguer'
    },
    _OtherPrecisions: {
        en: 'Other precision',
        fr: 'Autre precision'
    },
    _parentingTimeSharing: {
        en: 'Parenting time sharing',
        fr: 'Le partage du temps parental'
    },
    _time: {
        en: 'Time',
        fr: 'Temps'
    },
    _employer: {
        en: 'Name of employer',
        fr: 'Nom de l\'employeur'
    },
    _job: {
        en: 'Job title',
        fr: 'Titre d\'emploi'
    },
    _revenue: {
        en: 'Annual revenue',
        fr: 'Revenu annuel'
    },
    _personnePhysique: {
        en: 'Personne physique',
        fr: 'Personne physique'
    },

    _numberAction: {
        en: 'Nombre d\'actions (votantes)',
        fr: 'Nombre d\'actions (votantes)'
    },
    _numberActionTrueFalse: {
        en: 'Actions votantes',
        fr: 'Actions votantes'
    },
    _numberActionAssujetti: {
        en: 'Nombre d\'actions détenus',
        fr: 'Nombre d\'actions détenus'
    },
    _otherName: {
        fr: 'Autre nom',
        en: 'Other name'
    },

    _addressCanada: {
        fr: 'Adresse de domicile au Canada?',
        en: 'Adresse de domicile au Canada?'
    },

    _otherAddress: {
        fr: 'Autre adresse',
        en: 'Autre adresse'
    },

    _beneficiaryStartDate: {
        fr: 'Date début bénéficiaire ultime',
        en: 'Date début bénéficiaire ultime'
    },
    _beneficiaryRetraitDate: {
        fr: 'Date retrait bénéficiaire ultime',
        en: 'Date retrait bénéficiaire ultime'
    },
    status: {
        en: 'Status',
        fr: 'Statut'
    },

    _controlType: {
        fr: 'Type de contrôle',
        en: 'Type de contrôle'
    },
    _controlDirect: {
        fr: 'Contrôle direct',
        en: 'Contrôle direct'
    },
    _controlIndirect: {
        fr: 'Contrôle indirect',
        en: 'Contrôle indirect'
    },
    _defait: {
        en: 'De fait',
        fr: 'De fait'
    },
    _societeParActions: {
        en: 'Société par actions',
        fr: 'Société par actions'
    },
    _fiducie: {
        en: 'Fiducie',
        fr: 'Fiducie'
    },
    _societeEnNomCollectif: {
        en: 'Société en nom collectif',
        fr: 'Société en nom collectif'
    },
    _formeJuridique: {
        en: 'Forme juridique',
        fr: 'Forme juridique'
    },
    _conventionEntre: {
        en: 'Convention entre actionnaire',
        fr: 'Convention entre actionnaire'
    },
    _numberActionConvention: {
        en: 'Number action convention',
        fr: 'Number action convention'
    },
    _conventionVote: {
        en: 'Convention de vote',
        fr: 'Convention de vote'
    },
    _societeIndividuelle: {
        en: 'Société individuelle',
        fr: 'Société individuelle'
    },
    _societeCommandite: {
        en: 'Société en commandite',
        fr: 'Société en commandite'
    },

    _societeParticipation: {
        en: 'Société en participation',
        fr: 'Société en participation'
    },
    _personneMorale: {
        en: 'Personne morale sans but lucratif',
        fr: 'Personne morale sans but lucratif'
    },
    _syndicat: {
        en: 'Syndicat de copropriété',
        fr: 'Syndicat de copropriété'
    },
    _association: {
        en: 'Association',
        fr: 'Association'
    },
    _groupPeople: {
        en: ' Groupement de personnes',
        fr: ' Groupement de personnes'
    },
    _cooperative: {
        en: 'Coopérative',
        fr: 'Coopérative'
    },
    _fiducieNoUnit: {
        en: 'Fiducie exploitant une entreprise à caractère commercial (fiducie qui n’émet pas d’unité)',
        fr: 'Fiducie exploitant une entreprise à caractère commercial (fiducie qui n’émet pas d’unité)'
    },
    _fiducieWithUnit: {
        en: 'Fiducie exploitant une entreprise à caractère commercial (fiducie qui émet des unités)',
        fr: 'Fiducie exploitant une entreprise à caractère commercial (fiducie qui émet des unités)'
    },
    _justeValeurMarchandeTotale: {
        en: 'Juste valeur marchande totale',
        fr: 'Juste valeur marchande totale'
    },
    _nombreActionsTotales: {
        en: 'Nombre d\'action totales',
        fr: 'Nombre d\'action totales'
    },
    _controlefait: {
        en: 'Contrôle de fait',
        fr: 'Contrôle de fait'
    },
    _detail: {
        fr: 'Détails',
        en: 'Détails'
    },
    _addActionnaire: {
        fr: 'Ajouter actionnaire',
        en: 'Ajouter actionnaire'
    },

    portailAvocat: {
        reminder: {
            en: 'Reminder',
            fr: 'Rappel'
        },
        reminderTooltip: {
            en: 'Open Calendar',
            fr: 'Ouvrir Calendrier'
        },
        register: {
            en: 'Submit',
            fr: 'Enregistrer'
        },
        search: {
            en: 'Search',
            fr: 'Recherche'
        },
        loading: {
            en: 'Loading...',
            fr: 'En Cours...'
        },
        calendar: {
            en: 'Calendar',
            fr: 'Calendrier'
        },
        clientInfo: {
            en: 'Client Info',
            fr: 'Info Client'
        },
        contactInfo: {
            en: 'Contact Info',
            fr: 'Info Contact'
        },
        tasks: {
            en: 'Tasks',
            fr: 'Tâches'
        },
        nextCalls: {
            en: 'My next actions',
            fr: 'Mes prochains actions'
        },
        lastName: {
            en: 'Last name',
            fr: 'Nom'
        },
        email: {
            en: 'Email',
            fr: 'Courriel'
        },
        phone: {
            en: 'Phone',
            fr: 'Téléphone'
        },
        firstName: {
            en: 'First name',
            fr: 'Prénom'
        },
        clientPurchases: {
            en: 'Client Purchases',
            fr: 'Achats du Client'
        },
        noPurchases: {
            en: 'No Purchases',
            fr: 'Aucun Achats'
        },
        erase: {
            en: 'Clear',
            fr: 'Effacer'
        },
        noContactResults: {
            en: 'No results found, create a contact?',
            fr: 'Aucun résultat trouvé, créer un contact?'
        },
        subject: {
            en: 'Subject',
            fr: 'Sujet'
        },
        domain: {
            en: 'Domain',
            fr: 'Domaine'
        },
        cancel: {
            en: 'Cancel',
            fr: 'Annuler'
        },
        editContact: {
            en: 'Edit Contact Info',
            fr: 'Modifier Info du Contact'
        },
        english: {
            en: 'English',
            fr: 'Anglais'
        },
        french: {
            en: 'French',
            fr: 'Français'
        },
        validationError: {
            en: 'Please fill out all fields',
            fr: 'Veuillez remplir tous les champs'
        },
        downloading: {
            en: 'Downloading',
            fr: 'Chargement en cours'
        },
        reminders: {
            en: 'Reminders',
            fr: 'Rappels'
        },
        status: {
            en: 'Status',
            fr: 'Statut'
        },
        created: {
            en: 'Created',
            fr: 'Créé'
        },
        edit: {
            en: 'Edit',
            fr: 'Modifier'
        },
        close: {
            en: 'Close',
            fr: 'Fermer'
        },
        postSuccess: {
            en: 'Action handled successfully',
            fr: 'Action gérée avec succès'
        },
        postError: {
            en: 'Error, couldn\'t process request',
            fr: 'Erreur, impossible de traiter la demande'
        },
        supported: {
            en: 'Supported',
            fr: 'Pris en charge'
        },
        attributed: {
            en: 'Attributed',
            fr: 'Attribué'
        },
        closed: {
            en: 'Closed',
            fr: 'Fermé'
        },
        cancelled: {
            en: 'Cancelled',
            fr: 'Annuler'
        },
        language: {
            en: 'Language',
            fr: 'Langue'
        },
        languages: {
            en: 'Languages',
            fr: 'Langues'
        },
        domains: {
            en: 'Domain(s)',
            fr: 'Domaine(s)'
        },
        editUser: {
            en: 'Edit User',
            fr: 'Modifier User'
        },
        myNextLeads: {
            en: 'My Next Leads',
            fr: 'Mes Prochains Leads'
        },
        origin: {
            en: 'Origin',
            fr: 'Origine'
        },
        attribute: {
            en: 'Attribute',
            fr: 'Attribut'
        },
        leadInfo: {
            en: 'Lead Info',
            fr: 'Info Lead'
        },
        message: {
            en: 'Message',
            fr: 'Message'
        },
        ticketNumberZendesk: {
            en: 'Ticket # (Zendesk)',
            fr: '# Ticket (Zendesk)'
        },
        closeLead: {
            en: 'Close Lead',
            fr: 'Fermer Lead'
        },
        leadId: {
            en: 'Lead ID',
            fr: 'Lead ID'
        },
        showNotes: {
            en: 'Show Notes',
            fr: 'Afficher les Notes'
        },
        open: {
            en: 'Open',
            fr: 'Ouvrir'
        },
        filter: {
            en: 'Filter',
            fr: 'Filtre'
        },
        refresh: {
            en: 'Refresh',
            fr: 'Rafraîchir'
        },
        transferLead: {
            en: 'Transfer Lead to another Agent',
            fr: 'Transférer le Lead à un autre Agent'
        },
        empty: {
            en: 'Clear',
            fr: 'Vider'
        },
        fillOutAllFields: {
            en: 'Please fill out required fields',
            fr: 'Veuillez remplir toutes les données'
        },
        home: {
            en: 'Home',
            fr: 'Accueil'
        },
        navBilling: {
            en: 'Billing',
            fr: 'Facturation'
        },
        usage: {
            en: 'Platform Usage',
            fr: 'Utilisation de la plateforme'
        },
        formsSent: {
            en: 'Sent',
            fr: 'Envoyés'
        },
        formsCompleted: {
            en: 'Completed',
            fr: 'Complétés'
        },
        formsIncomplete: {
            en: 'Incomplete',
            fr: 'Incomplets'
        },
        pricing: {
            en: 'Pricing',
            fr: 'Tarifs'
        },
        unit_price: {
            en: 'Unit Price',
            fr: 'Prix unitaire'
        },
        total_price: {
            en: 'Total Price',
            fr: 'Prix total'
        },
        views: {
            en: 'Views',
            fr: 'Vues'
        },
        account: {
            en: 'Account',
            fr: 'Compte'
        },
        pinNote: {
            en: 'Pin note',
            fr: 'Épingler la note'
        },
        unpinNote: {
            en: 'Unpin note',
            fr: 'Désépingler la note'
        },
        transferNote: {
            en: 'Transfer Note',
            fr: 'Transférer la note'
        },
        logout: {
            en: 'Logout',
            fr: 'Déconnecter'
        },
        folder: {
            en: 'Folder',
            fr: 'Dossier'
        },
        preview: {
            en: 'Preview',
            fr: 'Aperçu'
        },
        download: {
            en: 'Download',
            fr: 'Télécharger'
        },
        privateNote: {
            en: 'Private note',
            fr: 'Note privée'
        },
        publicNote: {
            en: 'Public note',
            fr: 'Note publique'
        },
        pinnedNotes: {
            en: 'Pinned notes',
            fr: 'Notes épinglées'
        },
        summary: {
            en: 'Summary',
            fr: 'Sommaire'
        },
        openClientFolder: {
            en: 'Open client folder',
            fr: 'Ouvrir dossier client'
        },
        nextPage: {
            en: 'Next page',
            fr: 'Page suivante'
        },
        previousPage: {
            en: 'Previous Page',
            fr: 'Page précédente'
        },
        nextAppointment: {
            en: 'Next Appointment',
            fr: 'Prochain rendez-vous'
        },
        nextStep: {
            en: 'Next step',
            fr: 'L\'étape suivante'
        },
        fileType: {
            en: 'File type',
            fr: 'Type de fichier'
        },
        servicesSold: {
            en: 'Services sold',
            fr: 'Services vendues'
        },
        companyName: {
            en: 'Company name',
            fr: 'Nom de l\'entreprise'
        },
        companyAddress: {
            en: 'Company address',
            fr: 'Adresse de l\'entreprise'
        },
        companyPhone: {
            en: 'Company telephone',
            fr: 'Téléphone de l\'entreprise'
        },
        select: {
            en: 'Select...',
            fr: 'Sélectionner...'
        },
        clientError: {
            en: 'Unable to retrieve data because no records are associated with this client',
            fr: 'Impossible de récupérer les données car aucun dossier n\'est associé à ce client'
        },
        infoMissing: {
            en: 'Information missing',
            fr: 'Informations manquantes'
        },
        convertNote: {
            en: 'Convert note',
            fr: 'Convertir la note'
        },
        convertNoteConfirm: {
            en: 'Convert note to a private note?',
            fr: 'Convertir la note en note privée?'
        },
        cancelNote: {
            en: 'Cancel note',
            fr: 'Annuler la note'
        },
        confirm: {
            en: 'Confirm',
            fr: 'Confirmer'
        },
        typeToSearch: {
            en: 'Type to search...',
            fr: 'Tapez pour rechercher...'
        },
        affectNote: {
            en: 'Assign note',
            fr: 'Affecter la note'
        },
        opened: {
            en: 'Open',
            fr: 'Ouvert'
        },
        pending: {
            en: 'Pending',
            fr: 'En attente'
        },
        invalid: {
            en: 'Invalid',
            fr: 'Invalid'
        },
        expandSearch: {
            en: 'Expand search',
            fr: 'Étendre la recherche'
        },
        limit: {
            en: 'Limit',
            fr: 'Limite'
        },
        of: {
            en: 'of',
            fr: 'sur'
        },
        filterByAgentOrGroup: {
            en: 'Filter by agent/group',
            fr: 'Filtrer par agent/groupe'
        },
        filterByStatus: {
            en: 'Filter by status',
            fr: 'Filtrer par statut'
        },
        lawyerGroup: {
            en: 'Agent/Group',
            fr: 'Agent/Groupe'
        },
        masterDossier: {
            en: 'Master dossier',
            fr: 'Dossier maître'
        },
        clear: {
            en: 'Clear',
            fr: 'Vider'
        },
        addFile: {
            en: 'Add a file',
            fr: 'Ajouter un fichier'
        },
        quickActions: {
            en: 'Quick actions',
            fr: 'Actions rapides'
        },
        dueDate: {
            en: 'Due date',
            fr: 'Date d\'échéance'
        },
        datePrescription: {
            en: 'Statute of limitations',
            fr: 'Date de prescription'
        },
        lawyerInCharge: {
            en: 'Lawyer in charge',
            fr: 'Avocat en charge'
        },
        dossierFinalized: {
            en: 'Dossier finalized',
            fr: 'Dossier finalisé'
        },
        billing: {
            en: 'Billing',
            fr: 'À facturer'
        },
        RDVneeded: {
            en: 'RDV required to process the request',
            fr: 'RDV requis pour traiter la demande'
        },
        noActions: {
            en: 'No actions found',
            fr: 'Aucune action trouvée'
        },
        schedule: {
            en: 'Schedule',
            fr: 'Calendrier'
        },
        toggleLang: {
            en: 'Toggle Language',
            fr: 'Changer de langue'
        },
        suivi: {
            en: 'Suivi',
            fr: 'Suivi'
        },
        today: {
            en: 'Today',
            fr: 'Aujourd\'hui'
        },
        nextDays: {
            en: 'Next days',
            fr: 'Prochains jours'
        },
        yesterday: {
            en: 'Yesterday',
            fr: 'Hier'
        },
        loginTo: {
            en: 'Login',
            fr: 'Connectez-vous'
        },
        login: {
            en: 'Login',
            fr: 'Connexion'
        },
        password: {
            en: 'Password',
            fr: 'Mot de passe'
        },
        send: {
            en: 'Send',
            fr: 'Envoyer'
        },
        form: {
            en: 'Form',
            fr: 'Formulaires'
        },
        copy: {
            en: 'Copy',
            fr: 'Copier'
        },
        copied: {
            en: 'Copied!',
            fr: 'Copié!'
        },
        formSent: {
            en: 'Form sent!',
            fr: 'Formulaire envoyé!'
        },
        forms: {
            en: 'Forms',
            fr: 'Formulaires'
        },
        completed: {
            en: 'Completed',
            fr: 'Complété'
        },
        pageNotFound: {
            en: 'Page Not Found',
            fr: 'Page Non Trouvée'
        },
        pageNotFoundText: {
            en: 'This page does not exist, please redirect to another page',
            fr: 'Cette page n\'existe pas, merci de rediriger vers une autre page'
        },
        title: {
            en: 'Title',
            fr: 'Titre'
        },
        sendForm: {
            en: 'Send form',
            fr: 'Envoyer un formulaire'
        },
        enterOTP: {
            en: 'A unique password has been sent to your email, please enter the code',
            fr: 'Un mot de passe unique a été envoyé à votre adresse e-mail, veuillez rentrer le code:'
        },
        twoFA: {
            en: 'Two-factor authentication',
            fr: 'Authentification à deux facteurs'
        },
        resendOTP: {
            en: 'Resend code',
            fr: 'Renvoyer le code'
        },
        settings: {
            en: 'Settings',
            fr: 'Paramètres'
        },
        changePassword: {
            en: 'Change password',
            fr: 'Changer mot de passe'
        },
        currentPassword: {
            en: 'Current password',
            fr: 'Mot de passe actuel'
        },
        newPassword: {
            en: 'New password',
            fr: 'Nouveau mot de passe'
        },
        confirmNewPassword: {
            en: 'Confirm new password',
            fr: 'Confirmer le nouveau mot de passe'
        },
        returnToSettings: {
            en: 'Return to settings',
            fr: 'Retour aux paramètres'
        },
        passwordsDontMatch: {
            en: 'Passwords don\'t match',
            fr: 'Les mots de passe ne correspondent pas'
        },
        forgotPassword: {
            en: 'Forgot password',
            fr: 'Mot de passe oublié'
        },
        returnToLogin: {
            en: 'Return to login',
            fr: 'Revenir à la connexion'
        },
        sendLink: {
            en: 'Send link',
            fr: 'Envoyer le lien'
        },
        enterEmailForgotPassword: {
            en: 'Please enter your account password and we will send a link to reset your password',
            fr: 'Veuillez saisir l\'e-mail de votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe'
        },
        passwordReset: {
            en: 'Password reset',
            fr: 'Réinitialisation du mot de passe'
        },
        returnToDashboard: {
            en: 'Return to dashboard',
            fr: 'Retour au tableau de bord'
        },
        resendLink: {
            en: 'Resend',
            fr: 'Renvoyer'
        },
        confirmResendLink: {
            en: 'Resend link to client?',
            fr: 'Renvoyer le lien au client ?'
        },
        linkSent: {
            en: 'Link sent!',
            fr: 'Lien envoyer!'
        },
        linkNotSent: {
            en: 'Link not sent',
            fr: 'Lien non envoyé'
        },
        rowsPerPage: {
            en: 'rows per page',
            fr: 'éléments par page'
        }
    }
};

export default trad;
