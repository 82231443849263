import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Spinner } from '@/components/ui/spinner';

const buttonVariants = cva(
    'tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-md tw-text-sm tw-font-medium ' +
    'tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring ' +
    'disabled:tw-pointer-events-none disabled:tw-opacity-50 ' +
    'aria-invalid:tw-border-destructive aria-invalid:focus-visible:tw-ring-destructive',
    {
        variants: {
            variant: {
                default:
                    'tw-bg-primary tw-text-primary-foreground tw-shadow hover:tw-bg-primary/90',
                success: 'tw-bg-success tw-text-success-foreground tw-shadow-sm hover:tw-bg-success/90',
                warning: 'tw-bg-warning tw-text-warning-foreground tw-shadow-sm hover:tw-bg-warning/90',
                destructive:
                    'tw-bg-destructive tw-text-destructive-foreground tw-shadow-sm hover:tw-bg-destructive/90',
                outline:
                    'tw-border tw-border-input tw-bg-background tw-shadow-sm hover:tw-bg-accent ' +
                    'hover:tw-text-accent-foreground disabled:tw-bg-slate-100',
                secondary:
                    'tw-bg-secondary tw-text-secondary-foreground tw-shadow-sm hover:tw-bg-secondary/80',
                ghost: 'hover:tw-bg-accent hover:tw-text-accent-foreground',
                link: 'tw-text-primary tw-underline-offset-4 hover:tw-underline'
            },
            size: {
                default: 'tw-h-9 tw-px-4 tw-py-2',
                sm: 'tw-h-8 tw-rounded-md tw-px-3 tw-text-sm',
                xs: 'tw-h-4 tw-rounded-md tw-px-1 tw-text-xs',
                lg: 'tw-h-10 tw-rounded-md tw-px-8',
                icon: 'tw-h-9 tw-w-9'
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default'
        }
    }
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    loading?: boolean;
    spinnerClassName?: string;
    asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({
        className,
        variant,
        size,
        loading = false,
        spinnerClassName,
        asChild = false,
        ...props
    }, ref) => {
        const Comp = asChild ? Slot : 'button';
        return (
            <Comp
                type="button"
                className={cn(buttonVariants({
                    variant,
                    size,
                    className
                }))}
                ref={ref}
                {...props}
                disabled={loading || props.disabled}
            >
                {loading
                    ? <Spinner size="sm" className={spinnerClassName} />
                    : props.children}
            </Comp>
        );
    }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
