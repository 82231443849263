import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import _ from 'lodash';

import { FormGroup, Input } from 'reactstrap';

export function MaterialTable(props) {
    const {
        onSelectAllClick,
        columns,
        data,
        numSelected,
        rowCount,
        onRequestSort
    } = props;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [text, setText] = React.useState('');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        if (onRequestSort) {
            onRequestSort(event, property);
        } else {
            handleRequestSort(event, property);
        }
    };

    let columnsX = _.map(columns, (e) => {
        e.id = e.accessor;
        return e;
    });

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);

    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function stableSort(array, comparator, text) {
        let stabilizedThis = array.map((el, index) => [el, index]);

        if (text && text.length > 2) {
            stabilizedThis = _.filter(stabilizedThis, (e) => {
                let found = false;
                for (let f in e[0]) {

                    if (typeof e[0][f] === 'string' && e[0][f].match(new RegExp(text, 'i'))) {
                        found = true;
                    }
                }
                return found;
            });
        }

        // debugger;
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });

        return stabilizedThis.map((el) => el[0]);
    }

    return (
        <>
            <FormGroup>
                <Input
                    type={'text'}
                    placeholder={'recherche'}
                    value={text}
                    onChange={(e) => {
                        setText(e.target.value);
                    }}
                />
            </FormGroup>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {_.map(
                                columnsX,
                                (e) => {
                                    return <TableCell
                                        id={e.id}
                                        sortDirection={orderBy === e.id ? order : false}
                                        align={'left'}
                                    >
                                        <TableSortLabel
                                            active={orderBy === e.id}
                                            direction={orderBy === e.id ? order : 'asc'}
                                            onClick={createSortHandler(e.id)}
                                        >
                                            {e.Header}
                                        </TableSortLabel>
                                    </TableCell>;
                                }
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            _.map(
                                stableSort(
                                    data,
                                    getComparator(order, orderBy),
                                    text), (row) => {
                                    return (

                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >

                                            {_.map(
                                                columnsX,
                                                (e, i) => {

                                                    if (i === 0) {
                                                        return (
                                                            <TableCell component="th" scope="row"
                                                                       style={{ textAlign: 'left' }}>
                                                                {row[e.accessor]}
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        return (<TableCell align="right">{row[e.accessor]}</TableCell>);
                                                    }

                                                })
                                            }
                                        </TableRow>
                                    );
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
