import React, { useEffect, useState } from 'react';
import { type UpdateDialogProps } from '@/components/ui/crud-table';
import { type Case, type CaseUserGroup } from '@/types/folder';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { Input } from '@/components/ui/input';
import { useTranslation } from '@/composables/translation';
import { Button } from '@/components/ui/button';
import { Row, Col } from '@/components/ui/row';
import { Checkbox } from '@/components/ui/checkbox';
import { Combobox } from '@/components/ui/combobox';
import type { User, UserGroup } from '@/types/api/user';
import { getClientConflict, getProductList } from '@/composables/api';
import { type GetConflictDto, type Party } from '@/types/api/party';
import { getFieldError, useValidation } from '@/composables/validation';
import { useGroups } from '@/composables/groups';
import { useUserStore } from '@/store/user';
import { type Product } from '@/types/api/product';
import { clone } from '@/composables/utils';
import { CrudNextSteps } from '@/components/psj/process/CrudNextSteps';
import type { CalendarConfig } from '@/types/api/calendar';
import { type NextStepInstance } from '@/types/api/process';
import { type Macro } from '@/types/api/macro';
import { PartyConflictDialog } from '@/components/psj/PartyConflictDialog';
import { PartySelect } from '@/components/psj/party/PartySelect';

export type CaseType = Omit<Case, 'product' | 'groups'> & {
    disable_fid?: boolean;
    client?: Party;
    domain?: string;
    price?: string;
    fees?: string;
    groups?: string[] | CaseUserGroup[];
    ns_list?: NextStepInstance[];
    product?: Product;
};

export interface Props extends UpdateDialogProps<CaseType> {
    folder_id?: string;
    client?: Party;
    users: User[];
    groups: UserGroup[];
    macros: Macro[];
    calendarConfig?: CalendarConfig;
}

export function CreateCaseDialog(props: Props) {
    const { t, to, ct } = useTranslation('psj.case.create-dialog');
    const { lang } = useUserStore(state => ({ lang: state.lang }));
    const form = useForm<CaseType>({
        defaultValues: {
            folder_id: props.folder_id,
            client: props.client,
            domain: '',
            name: '',
            price: '',
            fees: '',
            groups: []
        }
    });
    const { required, requiredFieldArray } = useValidation();
    const disable_fid = form.watch('disable_fid');
    const groups = props.groups ?? [];
    const { specialties, groupBy } = useGroups({ groups });
    const [conflictOpen, setConflictOpen] = useState(false);
    const [conflictClient, setConflictClient] = useState<Party>();
    const [conflictCases, setConflictCases] = useState<GetConflictDto['cases']>();

    useEffect(() => {
        if (props.folder_id) {
            form.setValue('folder_id', props.folder_id);
        }
        if (props.client) {
            form.setValue('client', props.client);
        }
    }, [props.folder_id, props.client]);

    async function onSubmit(data: any) {
        const { client } = data;
        try {
            const res = await getClientConflict({ party_type_name: 'client', party_id: client._id });
            if (res.data.has_conflict_of_interest) {
                setConflictCases(res.data.cases);
                setConflictClient(client);
                setConflictOpen(true);
                return;
            }
        } catch {}
        return await props.onSubmit?.(data);
    }

    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            <DialogContent className="md:tw-max-w-5xl">
                <DialogHeader>
                    <DialogTitle>{t('title')}</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="tw-pb-6 tw-flex tw-flex-col tw-gap-3">
                            <FormField
                                name="folder_id"
                                render={({ field }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.folder-id')}
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                disabled={disable_fid}
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                                rules={{
                                    validate: {
                                        ...(!disable_fid && { required })
                                    }
                                }}
                            />
                            <FormField
                                name="disable_fid"
                                render={({ field }) => (
                                    <FormItem className="tw-flex tw-items-center tw-gap-2">
                                        <FormControl>
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onCheckedChange={field.onChange}
                                            />
                                        </FormControl>
                                        <FormLabel className="!tw-my-0">
                                            {t('fields.suggest-folder-id')}
                                        </FormLabel>
                                    </FormItem>
                                )}
                            />
                            <Row>
                                <Col col={6}>
                                    <FormField
                                        name="client"
                                        render={(props) => PartySelect({
                                            ...props,
                                            label: t('fields.client')
                                        })}
                                    />
                                </Col>
                                <Col col={6}>
                                    <FormField
                                        name="product"
                                        render={({ field: { ref, ...field }, formState }) => (
                                            <FormItem>
                                                <FormLabel>{t('fields.product')}</FormLabel>
                                                <FormControl>
                                                    <Combobox<Product>
                                                        getOptionsAsync={
                                                            (title) =>
                                                                getProductList({ title, lang })
                                                                    .then(res => res.data)
                                                        }
                                                        getOptionLabel={(opt) => to(opt.title)}
                                                        getValueLabel={(value) => to(value.title)}
                                                        {...field}
                                                        innerRef={ref}
                                                        onChange={(value) => {
                                                            if (value != null) {
                                                                form.setValue('name', to(value.title));
                                                                form.setValue('price', value.price);
                                                                form.setValue('fees', value.fees);
                                                                form.setValue('groups', clone(value.groups));
                                                                form.setValue('ns_list', clone(value.process.ns_list));
                                                            }
                                                            field.onChange(value);
                                                        }}
                                                        error={!!getFieldError(field.name, formState)}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{ validate: { required } }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col col={6}>
                                    <FormField
                                        name="name"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>
                                                    {t('fields.name')}
                                                </FormLabel>
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                        placeholder={'Nouveau dossier'}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                        rules={{ validate: { required } }}
                                    />
                                </Col>
                                <Col col={3}>
                                    <FormField
                                        name="price"
                                        render={({ field }) =>
                                            <FormItem>
                                                <FormLabel>
                                                    {t('fields.price')}
                                                </FormLabel>
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        }
                                        rules={{ validate: { required } }}
                                    />
                                </Col>
                                <Col col={3}>
                                    <FormField
                                        name="fees"
                                        render={({ field }) =>
                                            <FormItem>
                                                <FormLabel>
                                                    {t('fields.fees')}
                                                </FormLabel>
                                                <FormControl>
                                                    <Input
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        }
                                        rules={{ validate: { required } }}
                                    />
                                </Col>
                            </Row>
                            <FormField
                                name="groups"
                                render={({
                                    field: {
                                        ref,
                                        ...field
                                    }
                                }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.groups')}
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox
                                                className="!tw-flex tw-w-full"
                                                options={specialties}
                                                getOptionValue={opt => opt._id.$oid}
                                                getOptionLabel={opt => to(opt.name)}
                                                groupBy={groupBy}
                                                multiple
                                                clearable
                                                {...field}
                                                innerRef={ref}
                                            />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                            />
                            <Row>
                                <Col>
                                    <FormField
                                        name="ns_list"
                                        render={(context) => (
                                            <FormItem>
                                                <FormLabel>
                                                    {t('fields.steps')}
                                                </FormLabel>
                                                <FormControl>
                                                    <CrudNextSteps
                                                        context={context}
                                                        users={props.users}
                                                        groups={groups}
                                                        macros={props.macros}
                                                        calendarConfig={props.calendarConfig}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{ validate: { requiredFieldArray } }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary">
                                    {ct('cancel')}
                                </Button>
                            </DialogClose>
                            <ButtonSubmit>
                                {ct('create')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
                <PartyConflictDialog
                    open={conflictOpen}
                    onOpenChange={setConflictOpen}
                    client={conflictClient}
                    cases={conflictCases}
                    onSubmit={() => props.onSubmit?.(form.getValues())}
                />
            </DialogContent>
        </Dialog>
    );
}
