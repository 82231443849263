import React, { Component } from 'react';

import { Col, FormGroup, Input, Label } from 'reactstrap';

//   import _ from "lodash";
import _ from 'lodash';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
// const DEFAULT_TYPE = 'text';
const TRUE = 'true';

const FALSE = 'false';

const DEFAULT_STYLE = { textAlign: 'left' };

const ELAWYER = 'elawyer';
const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class CheckInput extends Component {
    constructor(props) {
        super(props);

        const fd = this.props.formData;

        let checked;

        if (fd && fd[this.props.name]) {
            checked = fd[this.props.name];
        } else if (this.props.checked) {
            checked = TRUE;
        } else {
            checked = FALSE;
        }

        this.state = {
            value: checked,
            display: this.props.defaultDisplay !== false,
            lawyers: [],
            lang: this.props.lang || FR
        };

        this.props.put(
            {
                params: {
                    key: encodeURI(this.props.name),
                    value: checked
                }
            }
        );
        // console.log('value',checked)
        // debugger;
        // if(!props.onChange)
        //     console.error('!!! onChange is missing !!!');

        // if(!props.name)
        //     console.error('!!! name is missing !!!');

        // if(!props.title)
        //     console.error('!!! title is missing !!!');
    }

    componentDidUpdate(prevprops) {
        const props = this.props;
        const obj = {};

        if (props.version !== prevprops.version) {
            props.put({
                params: {
                    key: props.name,
                    value: props.formData[props.name] || props.checked
                }
            });
            obj.value = props.formData[props.name] || props.checked;
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            props.put({
                params: {
                    key: props.name,
                    value: props.formData[props.name]
                }
            });
            obj.value = props.formData[props.name];
            console.log('obj.value formdata', obj.value);
        }

        if (props.lang !== prevprops.lang) {
            obj.lang = props.lang;
        }

        if (props.lawyers !== prevprops.lawyers) {
            obj.lawyers = props.lawyers;
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        const props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.props.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }
        let disabled = false;

        // debugger;
        // pas de facon de savoir si externe ou pasd

        if (this.props.extern === false) {
            const lawyer = _.find(this.props.lawyers, {
                value: localStorage.getItem('user_id')
            });

            if (!lawyer || lawyer.type === ELAWYER) {
                disabled = true;
            }
            // debugger;
            // // let JWT = localStorage.getItem('token');
            // // JWT = window.parseJWT(JWT);
            // // console.log(JWT)
            // debugger;
        }

        return (
            <>
                <FormGroup check>
                    <Label check>
                        <Input
                            disabled={disabled || props.disabled}
                            tabIndex={props.tabindex}
                            checked={this.state.value === TRUE}
                            type="checkbox"
                            {...this.props.required ? { required: true } : {}}
                            name={encodeURI(props.name)}
                            onChange={(e) => {
                                const checked = e.target.checked.toString();
                                this.setState({ value: checked });
                                if (this.props.callback) {
                                    this.props.callback({ ...e, ...props });
                                }
                            }}
                        />{' '}
                        {this.genLang(props.title || DEFAULT_TITLE, this.state.lang)}
                    </Label>
                </FormGroup>
            </>
        );
    }
}
