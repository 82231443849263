import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Row, Col } from '@/components/ui/row';
import { Button } from '@/components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import {
    type IntervalObject,
    type IntervalType,
    type TypeID,
    WeekSelection
} from '@/components/WeekSelection/WeekSelection';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
    getCalendarConfig,
    getCalendarDispos,
    getOrgUsers,
    postCalendarDispos
} from '@/composables/api';
import { type User } from '@/types/api/user';
import { type CalendarConfig, type Category } from '@/types/api/calendar';
import { useTranslation } from '@/composables/translation';
import { ActionBar } from '@/components/ActionBar';
import {
    transformCalendarWeek, transformConfig,
    transformIntervals,
    transformUsersConfig
} from '@/composables/calendar';
import { toast } from 'react-toastify';
import { useError } from '@/composables/error';
import { useUserStore } from '@/store/user';
import { ConfirmDialogButton } from '@/components/ConfirmDialogButton';
import { ButtonAsync } from '@/components/ui/button-async';
import { DatePicker } from '@/components/ui/date-picker';
import { Label } from '@/components/ui/label';
import { Combobox } from '@/components/ui/combobox';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import _ from 'lodash';

export function LawyerAvailabilities() {
    const { t, to, ct } = useTranslation('availabilities');
    const { handleNetworkError } = useError();
    const user_id = useUserStore(state => state.user?.user_id);
    const [startDate, setStartDate] = useState(DateTime.now().startOf('day'));
    const [newStartDate, setNewStartDate] = useState<DateTime>(startDate);

    const [config, setConfig] = useState<CalendarConfig>();
    const [users, setUsers] = useState<User[]>([]);
    const [intervalTypes, setIntervalTypes] = useState<IntervalType[]>([]);
    const [originalUserIds, setOriginalUserIds] = useState<string[]>([]);

    const [availabilities, setAvailabilities] = useState<IntervalObject[][]>([]);
    const [intervalTypeID, setIntervalTypeID] = useState<TypeID | null>(null);
    const [intervalVariantID, setIntervalVariantID] = useState<TypeID | null>(null);
    const [usersFilter, setUsersFilter] = useState<User[] | null>([]);
    const [variantsFilter, setVariantsFilter] = useState<string[] | null>([]);
    const [loading, setLoading] = useState(false);

    const [openUnsaved, setOpenUnsaved] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const weekStartDate = startDate.startOf('week');
    const weekEndDate = weekStartDate.plus({ week: 1 }).minus({ second: 1 });

    function handleSaveAvailabilities(permanent = false) {
        setLoading(true);
        const calendar = transformIntervals(availabilities);
        const users = _.union(_.uniq(calendar.map(a => a.user as string)), originalUserIds);
        return postCalendarDispos({
            calendar,
            start: weekStartDate.toISO({ suppressMilliseconds: true }),
            end: weekEndDate.toISO({ suppressMilliseconds: true }),
            permanent: permanent ? 18 : undefined,
            users
        })
            .then(() => {
                setHasChanges(false);
                toast(ct('messages.saved'), { type: 'success' });
                setOriginalUserIds(users);
            })
            .catch(handleNetworkError)
            .finally(() => setLoading(false));
    }

    function handleDiscardChanges() {
        setOpenUnsaved(false);
        setStartDate(newStartDate);
        setAvailabilities([]);
    }

    function handleChangeStartDate(value: DateTime | null) {
        const start = (value ?? DateTime.now()).startOf('day');
        const isSameWeek = start.startOf('week').equals(weekStartDate);
        if (isSameWeek) {
            setStartDate(start);
        } else if (!isSameWeek && hasChanges) {
            setNewStartDate(start);
            setOpenUnsaved(true);
        } else if (!isSameWeek && !hasChanges) {
            setStartDate(start);
            setAvailabilities([]);
        }
    }

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getOrgUsers(),
            getCalendarConfig()
        ])
            .then(([users, config]) => {
                setConfig(transformConfig(config.data));
                setUsers(users.data);
                setIntervalTypes(transformUsersConfig(users.data, config.data, to));
                setIntervalTypeID(user_id ?? users.data[0]?.id.$oid);
                setIntervalVariantID(config.data?.categories?.[0]?.id ?? 0);
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true);
        getCalendarDispos(weekStartDate, weekEndDate)
            .then((dispos) => {
                setAvailabilities(
                    transformCalendarWeek(
                        weekStartDate,
                        dispos.data,
                        d => ({
                            type: `${d.user.$oid}-${d.type}`
                        })
                    )
                );
                setHasChanges(false);
                setOriginalUserIds(_.uniq(dispos.data.map(d => d.user.$oid)));
            })
            .finally(() => setLoading(false));
    }, [weekStartDate.toFormat('yyyy-MM-dd')]);

    return (
        <>
            <ActionBar className="tw-top-[60px] tw-sticky" loading={loading}>
                <ButtonAsync
                    className="tw-ml-auto"
                    disabled={loading}
                    onClick={() => handleSaveAvailabilities()}
                >
                    <FontAwesomeIcon className="tw-mr-2" icon={faFloppyDisk} />
                    {t('save')}
                </ButtonAsync>
                <ConfirmDialogButton
                    title={t('permanent-save-confirm.title')}
                    message={t('permanent-save-confirm.message')}
                    confirmText={t('permanent-save-confirm.confirm')}
                    onConfirm={() => handleSaveAvailabilities(true)}
                >
                    <Button
                        variant="warning"
                        disabled={loading}
                    >
                        <FontAwesomeIcon className="tw-mr-2" icon={faFloppyDisk}/>
                        {t('permanent-save')}
                    </Button>
                </ConfirmDialogButton>
            </ActionBar>
            <main className="tw-flex tw-flex-col tw-gap-4 container-fluid !tw-p-3 md:!tw-p-6">
                <Card>
                    <CardContent className="tw-flex tw-flex-col tw-gap-y-4 !tw-p-6">
                        <CardTitle>{t('filters.title')}</CardTitle>
                        <Row className="tw-items-end">
                            <Col col={4}>
                                <Label htmlFor="filters.date">
                                    {t('filters.date')}
                                </Label>
                                <DatePicker
                                    id="filters.date"
                                    disabled={loading}
                                    value={startDate}
                                    onChange={handleChangeStartDate}
                                />
                            </Col>
                            <Col col={4}>
                                <Label htmlFor="filters.user-filter">
                                    {t('filters.user-filter')}
                                </Label>
                                <Combobox<User, User>
                                    id="filters.user-filter"
                                    options={users}
                                    getOptionLabel={opt => `${opt.firstname} ${opt.lastname}`}
                                    getValueLabel={value => `${value.firstname} ${value.lastname}`}
                                    value={usersFilter}
                                    onChange={setUsersFilter}
                                    disabled={loading}
                                    multiple
                                />
                            </Col>
                            <Col col={4}>
                                <Label htmlFor="filters.type-filter">
                                    {t('filters.type-filter')}
                                </Label>
                                <Combobox<Category, string>
                                    id="filters.type-filter"
                                    options={config?.categories ?? []}
                                    getOptionValue={opt => String(opt.id)}
                                    getOptionLabel={opt => to(opt.name)}
                                    value={variantsFilter}
                                    onChange={setVariantsFilter}
                                    disabled={loading}
                                    multiple
                                />
                            </Col>
                        </Row>
                        <CardTitle>{t('selection.title')}</CardTitle>
                        <Row>
                            <Col col={6}>
                                <Label htmlFor="selection.user">{t('selection.user')}</Label>
                                <Combobox<User, TypeID>
                                    id="selection.user"
                                    options={users}
                                    getOptionValue={opt => opt.id.$oid}
                                    getOptionLabel={opt => `${opt.firstname} ${opt.lastname}`}
                                    value={intervalTypeID}
                                    onChange={setIntervalTypeID}
                                    disabled={loading}
                                />
                            </Col>
                            <Col col={6}>
                                <Label htmlFor="selection.type">{t('selection.type')}</Label>
                                <Combobox<Category, TypeID>
                                    id="selection.type"
                                    options={config?.categories ?? []}
                                    getOptionValue={opt => opt.id}
                                    getOptionLabel={opt => to(opt.name)}
                                    value={intervalVariantID}
                                    onChange={setIntervalVariantID}
                                    disabled={loading}
                                />
                            </Col>
                        </Row>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle>{t('calendar')}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <WeekSelection
                            intervalMinutes={15}
                            dayStartHour={6}
                            dayEndHour={23}
                            useTypeLabels
                            intervalTypes={intervalTypes}
                            intervalFilter={(i) => {
                                const [uid, cid] = String(i.type).split('-');
                                if (
                                    Number(usersFilter?.length) > 0 &&
                                    !usersFilter?.find(u => u.id.$oid === uid)
                                ) {
                                    return false;
                                }
                                if (
                                    Number(variantsFilter?.length) > 0 &&
                                    !variantsFilter?.includes(cid)
                                ) {
                                    return false;
                                }
                                return true;
                            }}
                            currentIntervalType={`${intervalTypeID}-${intervalVariantID}`}
                            startDate={startDate}
                            headerClassName="tw-sticky tw-top-[120px]"
                            value={availabilities}
                            onChange={(value) => {
                                setAvailabilities(value);
                                setHasChanges(true);
                            }}
                        />
                    </CardContent>
                </Card>
                <Dialog open={openUnsaved} onOpenChange={setOpenUnsaved}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>{t('discard-changes.title')}</DialogTitle>
                        </DialogHeader>
                        <div>
                            {t('discard-changes.description-0')}
                            <strong>
                                {weekStartDate.toFormat('d MMMM')}
                                {' '}&mdash;{' '}
                                {weekEndDate.toFormat('d MMMM')}
                            </strong>
                            {t('discard-changes.description-1')}
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary">
                                    {ct('no')}
                                </Button>
                            </DialogClose>
                            <Button variant="destructive" onClick={handleDiscardChanges}>
                                {ct('discard')}
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </main>
        </>
    );
}
