import type React from 'react';

interface Props<T> {
    getProps: () => T;
    render: (props: T) => React.ReactElement | null;
}

export function WithHooks<T>(props: Props<T>) {
    const childProps = props.getProps();
    return props.render(childProps);
}
