import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { MenuButton } from '@/components/MenuButton';
import { type Language, useTranslation } from '@/composables/translation';
import { useLanguage } from '@/composables/language';
import { useUserStore } from '@/store/user';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useNeoForm } from '@/composables/neoform';

interface Props {
    className?: string;
    label?: boolean;
}

export function LanguageSelect(componentProps: Props) {
    const { t } = useTranslation('nav');
    const { lang, setLang } = useUserStore(state => ({
        lang: state.lang,
        setLang: state.setLang
    }));
    const { options } = useLanguage();
    const { form } = useNeoForm();
    const allowedLanguages = form?.layout?.header?.formLanguage;
    let langOptions = options.map(o => (
        {
            ...o,
            selected: o.value === lang,
            onClick: () => setLang(o.value as Language)
        }
    ));
    if (allowedLanguages) {
        langOptions = langOptions.filter((lang) => allowedLanguages.includes(lang.value as Language));
    }
    return (
        <MenuButton
            id="language"
            tooltip={t('language')}
            renderButton={(props) => (
                <Button
                    {...props}
                    className={componentProps.className}
                    variant={componentProps.label ? 'outline' : 'ghost'}
                    size={!componentProps.label ? 'icon' : undefined}
                >
                    <FontAwesomeIcon
                        className={cn(componentProps.label && 'tw-mr-2')}
                        icon={faLanguage}
                    />
                    {componentProps.label && t('language')}
                </Button>
            )}
            options={langOptions}
        />
    );
}
