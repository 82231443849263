import React, { useCallback, useContext, useState } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { type Note } from '@/types/api/note';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { getFolders, postTransferNote } from '@/composables/api';
import { Combobox } from '@/components/ui/combobox';
import { useTranslation } from '@/composables/translation';
import { getFieldError, useValidation } from '@/composables/validation';
import { useError } from '@/composables/error';
import { FolderContext } from '@/pages/psj/Folder';
import { toast } from 'react-toastify';
import { useUserStore } from '@/store/user';
import { type Case } from '@/types/folder';

interface Props {
    note: Note;
}

export function NoteTransferDialog(props: Props) {
    const { ct, t } = useTranslation('psj.notes.transfer');
    const folder = useContext(FolderContext);
    const form = useForm<{ case_id: string }>({});
    const { lang } = useUserStore(state => ({ lang: state.lang }));
    const { required } = useValidation();
    const { handleNetworkError } = useError();

    const getOptions = useCallback(
        (search: string) => getFolders({ lang, case_name: search, folder_id: search, or_filter: true })
            .then((res) => res.data.data),
        [lang]
    );

    const [open, setOpen] = useState(false);

    function handleSubmit({ case_id }: { case_id: string }) {
        return postTransferNote(props.note._id, case_id)
            .then(() => {
                folder?.setNotes((folder?.notes ?? []).filter(n => n._id !== props.note._id));
                setOpen(false);
                toast(ct('messages.success'), { type: 'success' });
            })
            .catch(handleNetworkError);
    }

    function onOpenChange(open: boolean) {
        if (!open) {
            form.reset();
        }
        setOpen(open);
    }

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <Tooltip>
                <TooltipTrigger asChild>
                    <DialogTrigger asChild>
                        <Button
                            type="button"
                            variant="ghost" size="icon"
                            className="!tw-rounded-full tw-text-primary"
                        >
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                    </DialogTrigger>
                </TooltipTrigger>
                <TooltipContent>
                    {t('tooltip')}
                </TooltipContent>
            </Tooltip>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {t('title')}
                    </DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)}>
                        <div className="tw-flex tw-flex-col tw-gap-3 tw-pb-6">
                            <FormField
                                name="case_id"
                                render={({ formState, field: { ref, ...field } }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {ct('folder')}
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox<Case, string>
                                                id="case_id"
                                                getOptionsAsync={getOptions}
                                                getOptionValue={(opt) => opt._id}
                                                getOptionLabel={(opt) => `#${opt.folder_id} — ${opt.name}`}
                                                {...field}
                                                innerRef={ref}
                                                error={!!getFieldError('case_id', formState)}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                                rules={{ validate: { required } }}
                            />
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button type="button" variant="secondary">
                                    {ct('cancel')}
                                </Button>
                            </DialogClose>
                            <ButtonSubmit>
                                {ct('transfer')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
