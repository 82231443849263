import React from 'react';
import { Badge, type BadgeProps } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

interface Props extends Omit<BadgeProps, 'content'> {
    content?: React.ReactNode;
    children?: React.ReactNode;
}

export function BadgeCounter({ content, children, ...props }: Props) {
    return (
        <div className="tw-relative">
            {children}
            {content &&
                <Badge
                    {...props}
                    className={cn(
                        'tw-absolute tw-size-2 !tw-rounded-full !tw-p-2 tw-top-0 tw-right-0',
                        'tw-flex tw-items-center tw-justify-center tw-mt-[-2px] tw-mr-[-2px]',
                        props.className
                    )}>
                    {content}
                </Badge>
            }
        </div>
    );
}
