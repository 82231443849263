import React from 'react';
import { Row } from '@/components/ui/row';
import {
    NeoFormComponent,
    type NeoFormComponentProps
} from '@/components/neoform/NeoFormComponent';
import { combine } from '@/composables/translation';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { useNeoForm } from '@/composables/neoform';
import { ADMIN_POSITIONS } from '@/components/utils/translations';

export function Admin(props: NeoFormComponentProps) {
    const {
        form,
        getChildFieldName
    } = useNeoForm();
    const isPositionOther = form?.watch(getChildFieldName('position')) === 'other';
    return (
        <div className="tw-flex tw-flex-col tw-gap-1">
            <Row>
                <NeoFormComponent
                    name="name"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.last-name')}
                    col={4}
                />
                <NeoFormComponent
                    name="firstname"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.first-name')}
                    col={4}
                />
                <NeoFormComponent
                    name="gender"
                    componentName="InputSelect"
                    col={4}
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.gender')}
                    options={[
                        {
                            value: 'M',
                            label: combine('neoform.sir')
                        },
                        {
                            value: 'F',
                            label: combine('neoform.madam')
                        },
                        {
                            value: '-',
                            label: combine('neoform.other')
                        }
                    ]}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="position"
                    componentName="InputSelect"
                    title={combine('neoform.position.title')}
                    titleType={NeoFormTitleType.LABEL}
                    options={ADMIN_POSITIONS}
                    col={isPositionOther ? 2 : 6}
                />
                <NeoFormComponent
                    name="positionValue"
                    componentName="InputText"
                    title={combine('neoform.position.other')}
                    titleType={NeoFormTitleType.LABEL}
                    display={isPositionOther}
                    required
                    col={4}
                />
                <NeoFormComponent
                    name="status"
                    componentName="InputSelect"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.status')}
                    col={4}
                    options={[
                        {
                            value: 'citizen',
                            label: combine('neoform.canadian-citizen')
                        },
                        {
                            value: 'permanent_resident',
                            label: combine('neoform.permanent-resident')
                        },
                        {
                            value: 'not_resident',
                            label: combine('neoform.non-resident')
                        }
                    ]}
                />
                <NeoFormComponent
                    name="signatory"
                    componentName="InputCheckbox"
                    label={combine('neoform.signatory')}
                    size="small"
                    col={2}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="date_start"
                    componentName="InputDate"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.administrator.date-start')}
                    col={4}
                />
                <NeoFormComponent
                    name="date_end_check"
                    componentName="InputCheckbox"
                    title={combine('neoform.date-end')}
                    size="small"
                    col={4}
                />
                <NeoFormComponent
                    name="date_end"
                    componentName="InputDate"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.date-end')}
                    display={!!form?.watch(getChildFieldName('date_end_check'))}
                    col={4}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="address"
                    componentName="Address"
                    title={combine('neoform.address.address')}
                    displayPreface={false}
                    country
                />
            </Row>
            <Row className="!tw-mt-2">
                <NeoFormComponent
                    name="files"
                    componentName="InputDropContainer"
                    title={combine('neoform.common.id.title')}
                    description={combine('neoform.common.id.description')}
                    last
                />
            </Row>
        </div>
    );
}
