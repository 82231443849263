import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { faCheckCircle, faExclamationCircle, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const alertVariants = cva(
    'tw-relative tw-w-full tw-rounded-lg tw-border tw-px-4 tw-py-3 tw-text-sm ' +
    '[&>svg+div]:tw-translate-y-[-3px] [&>svg]:tw-absolute [&>svg]:tw-left-4 ' +
    '[&>svg]:tw-top-4 [&>svg]:tw-text-foreground [&>svg~*]:tw-pl-7',
    {
        variants: {
            variant: {
                default: 'tw-bg-background tw-text-foreground',
                destructive:
                    'tw-border-destructive/50 tw-text-destructive ' +
                    'dark:tw-border-destructive [&>svg]:tw-text-destructive',
                warning: 'tw-border-warning/50 tw-text-warning ' +
                    'dark:tw-border-warning [&>svg]:tw-text-warning',
                info: 'tw-border-info/50 tw-text-info ' +
                    'dark:tw-border-info [&>svg]:tw-text-info',
                success: 'tw-border-success/50 tw-text-success ' +
                    'dark:tw-border-success [&>svg]:tw-text-success'
            }
        },
        defaultVariants: {
            variant: 'default'
        }
    }
);

const variantIcons: Record<string, IconDefinition | null> = {
    default: null,
    destructive: faTimesCircle,
    warning: faExclamationCircle,
    info: faInfoCircle,
    success: faCheckCircle
};

const Alert = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({
    className,
    variant,
    ...props
}, ref) => {
    const Icon = variantIcons[variant ?? 'default'];
    return (
        <div
            ref={ref}
            role="alert"
            className={cn(alertVariants({ variant }), className)}
            {...props}
        >
            {Icon && (
                <span className="tw-absolute tw-left-4 tw-top-1/2
                tw-transform tw--translate-y-1/2 tw-flex tw-items-center">
                    <FontAwesomeIcon icon={Icon} size='lg' />
                </span>
            )}
            <div className="tw-pl-7">
                {props.children}
            </div>
        </div>
    );
});
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLHeadingElement>
>(({
    className,
    ...props
}, ref) => (
    <h5
        ref={ref}
        className={cn('tw-mb-1 tw-font-medium tw-leading-none tw-tracking-tight', className)}
        {...props}
    />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement>
>(({
    className,
    ...props
}, ref) => (
    <div
        ref={ref}
        className={cn('tw-text-sm [&_p]:tw-leading-relaxed', className)}
        {...props}
    />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription };
