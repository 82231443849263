import React from 'react';
import { NeoFormComponent } from '@/components/neoform/NeoFormComponent';
import { combine, useTranslation } from '@/composables/translation';
import {
    LEGACY_ACTION_TYPES, LEGACY_DEBT_RESPONSIBILITY, LEGACY_MAINTENANCE_RESPONSIBILITY,
    LEGACY_TYPES,
    LEGACY_TYPES_CA,
    SHARE_CATEGORIES
} from '@/components/utils/translations';
import { useNeoForm } from '@/composables/neoform';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { Row } from '@/components/ui/row';

interface Props {
    isCa?: boolean;
}

export function Good(props: Props) {
    const { t, ct } = useTranslation('neoform.good');
    const { hookForm, getChildFieldName } = useNeoForm();
    const [legacyType, actionType, actionCompliant] = hookForm?.watch([
        getChildFieldName('legacyType'),
        getChildFieldName('action_type'),
        getChildFieldName('action_compliant')
    ]) ?? [];
    const isSum = legacyType === '1';
    const isFurniture = legacyType === '18';
    const isCharity = legacyType === '19';
    const isDebt = legacyType === '20';
    const isCompany = legacyType === '21';
    const isRight = legacyType === '22';
    const isAction = legacyType === '23';
    const isSpouse = legacyType === '24';
    const isOther = legacyType === '999';
    const isUnderResponsibility = ['3', '4', '16', '17', '18'].includes(legacyType ?? '');
    const needDebtField = ['4', '16', '18', '21'].includes(legacyType ?? '');
    const showDescription = ['20', '16', '999'].includes(legacyType ?? '');

    let descriptionLabel = combine('neoform.good.description');
    if (isDebt) {
        descriptionLabel = combine('neoform.good.debt-description');
    } else if (isOther) {
        descriptionLabel = combine('neoform.good.other-description-label');
    }

    let addressTitle = combine('neoform.good.address-property-1');
    if (isFurniture) {
        addressTitle = combine('neoform.good.address-property-2');
    }

    const legacy_optn = props.isCa ? LEGACY_TYPES_CA : LEGACY_TYPES;
    const req_company_name = props.isCa
        ? 'neoform.good.action-company-name'
        : 'neoform.good.action-req';

    return (
        <div className="tw-flex tw-flex-col tw-gap-1">
            <Row>
                <NeoFormComponent
                    name="legacyType"
                    componentName="InputSelect"
                    title={t('bequest-category')}
                    titleType={NeoFormTitleType.LABEL}
                    placeholder={ct('select.hint')}
                    options={legacy_optn}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="primary"
                    componentName="User"
                    display={!isCharity && !isSpouse}
                    last
                />
            </Row>
            {isOther && <em><strong>
                {t('other-description')}
            </strong></em>}
            {isCompany && <Row>
                <NeoFormComponent
                    col={6}
                    name="company_name"
                    componentName="InputText"
                    title={combine('neoform.good.company-name')}
                    titleType={NeoFormTitleType.LABEL}
                />
            </Row>}
            {isSum && <Row>
                <NeoFormComponent
                    col={6}
                    name="sum"
                    componentName="InputNumber"
                    title={combine('neoform.good.sum')}
                    titleType={NeoFormTitleType.LABEL}
                    scale={2}
                />
                <NeoFormComponent
                    col={6}
                    name="currency"
                    componentName="InputText"
                    title={combine('neoform.good.currency')}
                    titleType={NeoFormTitleType.LABEL}
                    mask="aaa"
                    uppercase
                />
            </Row>}
            {isCharity && <Row>
                <NeoFormComponent
                    col={4}
                    name="charity_name"
                    componentName="InputText"
                    title={combine('neoform.good.charity-name')}
                    titleType={NeoFormTitleType.LABEL}
                />
                <NeoFormComponent
                    col={4}
                    name="sum"
                    componentName="InputNumber"
                    title={combine('neoform.good.sum')}
                    titleType={NeoFormTitleType.LABEL}
                    scale={2}
                />
                <NeoFormComponent
                    col={4}
                    name="currency"
                    componentName="InputText"
                    title={combine('neoform.good.currency')}
                    titleType={NeoFormTitleType.LABEL}
                    mask="aaa"
                    uppercase
                />
            </Row>}
            <Row>
                <NeoFormComponent
                    name="legacyDescription"
                    componentName="InputTextArea"
                    title={descriptionLabel}
                    titleType={NeoFormTitleType.LABEL}
                    display={showDescription}
                />
            </Row>
            {(isFurniture || isRight) &&
                <Row>
                    <NeoFormComponent
                        name="address"
                        componentName="Address"
                        country
                        contact={false}
                        title={addressTitle}
                        last
                    />
                </Row>
            }
            {isAction && <>
                <Row>
                    <NeoFormComponent
                        name="action_req"
                        componentName="InputText"
                        title={combine(req_company_name)}
                        titleType={NeoFormTitleType.LABEL}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="action_type"
                        componentName="InputRadio"
                        title={combine('neoform.good.action-type')}
                        options={LEGACY_ACTION_TYPES}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        col={6}
                        name="action_category"
                        componentName="InputSelect"
                        title={combine('neoform.good.action-category')}
                        titleType={NeoFormTitleType.LABEL}
                        default="NA"
                        options={[
                            { value: 'NA', label: '-' },
                            { value: 'ord', label: { en: 'Ordinary', fr: 'Ordinaire' } },
                            ...SHARE_CATEGORIES
                        ]}
                        display={actionType === '1'}
                    />
                    <NeoFormComponent
                        name="action_compliant"
                        componentName="InputCheckbox"
                        title={combine('neoform.good.action-compliant')}
                        display={actionType === '1'}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="notary"
                        componentName="User"
                        title={combine('neoform.good.action-professional')}
                        display={actionCompliant === true}
                        noRelation
                        last
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        col={6}
                        name="action_minute"
                        componentName="InputText"
                        title={combine('neoform.good.action-minute')}
                        titleType={NeoFormTitleType.LABEL}
                        display={actionCompliant === true}
                    />
                    <NeoFormComponent
                        col={6}
                        name="action_date"
                        componentName="InputDate"
                        title={combine('neoform.good.action-date')}
                        titleType={NeoFormTitleType.LABEL}
                        display={actionCompliant === true}
                    />
                </Row>
            </>}
            {isUnderResponsibility && <Row>
                <NeoFormComponent
                    name="maintainResponsible"
                    componentName="InputRadio"
                    title={combine('neoform.good.maintain-responsible')}
                    options={LEGACY_MAINTENANCE_RESPONSIBILITY}
                />
            </Row>}
            {needDebtField && <Row>
                <NeoFormComponent
                    name="debtResponsible"
                    componentName="InputRadio"
                    title={combine('neoform.good.debt-responsible')}
                    options={LEGACY_DEBT_RESPONSIBILITY}
                />
            </Row>}
            {isFurniture && <Row>
                <NeoFormComponent
                    name="excludeArt"
                    componentName="InputCheckbox"
                    title={combine('neoform.good.exclude-art')}
                />
            </Row>}
        </div>
    );
}
