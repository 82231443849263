import React, { useEffect, useState } from 'react';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { useNeoForm } from '@/composables/neoform';
import { instance as api } from '@/composables/api';
import { overrideUrlDomain } from '@/composables/url';
import { FormField, FormItem, FormControl, FormMessage } from '@/components/ui/form';
import { Combobox } from '@/components/ui/combobox';
import { getFieldError } from '@/composables/validation';

export interface SelectOption {
    value: string;
    label: string | TranslationObject;
}

interface Props {
    placeholder?: string | TranslationObject;
    options?: SelectOption[];
    url?: string;
    multiple?: boolean;
    sorted?: boolean;
    clearable?: boolean;
    creatable?: boolean;
    uppercase?: boolean;
}

export function InputSelect(props: NeoFormComponentProps & Props) {
    const { to } = useTranslation();
    const { form, id, getValidationRules } = useNeoForm();
    const validate = getValidationRules(props);
    const placeholder = props.placeholder != null ? to(props.placeholder) : undefined;
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<SelectOption[]>(props.options ?? []);
    function getOptions(url: string) {
        setLoading(true);
        return api.get<SelectOption[]>(url)
            .then((res) => setOptions(res.data))
            .finally(() => setLoading(false));
    }
    useEffect(() => {
        if (props.url) {
            getOptions(overrideUrlDomain(props.url)).then();
        }
    }, [props.url]);
    return (
        <FormField
            name={id}
            defaultValue={props.default}
            render={({ field: { ref: fieldRef, ...field }, formState }) => {
                const error = !!getFieldError(id, formState);
                const value = field.value ?? null;
                return (
                    <FormItem>
                        <FormControl>
                            <Combobox
                                id={id}
                                className="tw-w-full"
                                multiple={props.multiple ?? false}
                                options={options}
                                getOptionLabel={option => to(option.label, String(option))}
                                getOptionValue={option => option.value}
                                // filterOptions={props.creatable ? filterOptions : undefined}
                                // readOnly={props.readonly}
                                // disableClearable={props.required}
                                placeholder={placeholder}
                                disabled={loading}
                                clearable={!props.required && props.clearable}
                                creatable={props.creatable}
                                uppercase={props.uppercase}
                                error={error}
                                // filterSelectedOptions
                                // autoSelect
                                // autoHighlight
                                // freeSolo={props.creatable}
                                {...field}
                                innerRef={(ref) => {
                                    fieldRef(ref);
                                    form?.ref(id, ref);
                                }}
                                value={value}
                                onChange={(value: any) => {
                                    if (props.multiple && props.sorted && Array.isArray(value)) {
                                        value?.sort((a, b) => a.toString().localeCompare(b.toString()));
                                    }
                                    field.onChange(value);
                                    form?.set(id, value);
                                }}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                );
            }}
            rules={{ validate }}
        />
    );
}
