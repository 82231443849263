import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import Resizer from 'react-image-file-resizer';

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const resizeFile = (file, cb) =>
    new Promise(() => {
        Resizer.imageFileResizer(
            file,
            700,
            900,
            'JPEG',
            60,
            0,
            (uri) => {
                cb(uri, file);
            },
            'base64'
        );
    });
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: 'dark-grey',
    fontWeight: '600',
    fontSize: '20px',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#0076cf',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};
const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const BLOCK = 'block';
const NONE = 'none';
const DEFAULT_DROP_TEXT = 'Téléversez vos fichiers ici';

export function DropZoneGcloud(props) {

    const onDrop = useCallback((acceptedFiles) => {
        if (props.onDropAccepted) {
            props.onDropAccepted(acceptedFiles);
        }

        let idx = 0;

        const length = acceptedFiles.length;

        var axiosArray = [];
        var initiatedAxios = false;

        var initAxios = () => {
            return;
            if (initiatedAxios && !axiosArray.length) {
                initiatedAxios = false;
            } else if (axiosArray.length) {
                initiatedAxios = true;
                // debugger;
                axiosArray[0]()
                    .then((status) => {
                        axiosArray.shift();
                        initAxios();
                        console.log('=====---->»»»»', status);
                    });
            }
        };
        acceptedFiles.forEach((file) => {

            let index = idx++;
            const reader = new FileReader();
            reader.fileName = file.name;
            reader.onabort = () => console.error('file reading was aborted');
            reader.onerror = () => console.error('file reading has failed');

            if (file.type.match('image')) {

                if (file.size > 1000000) {

                    reader.onload = resizeFile(file, (upload, original) => {
                        if (props && props.handleFile) {
                            props.handleFile({
                                content: upload,
                                id: props.id,
                                name: original.name,
                                realName: original.name,
                                index: props.index + index
                            }, length);
                        } else {
                            console.log('***********\n\n Handle file missing in dropzone component \n\n ********');
                        }
                    });

                } else {

                    reader.onload = (upload) => {
                        if (props && props.handleFile) {
                            props.handleFile({
                                content: upload.target.result,
                                id: props.id,
                                name: upload.target.fileName,
                                realName: upload.target.fileName,
                                index: props.index + index
                            }, length);
                            //   axiosArray.push(

                        } else {
                            console.log('***********\n\n Handle file missing in dropzone component \n\n ********');
                        }
                    };
                }
            } else {
                reader.onload = (upload) => {
                    if (props && props.handleFile) {
                        props.handleFile({
                            content: upload.target.result,
                            id: props.id,
                            name: upload.target.fileName,
                            realName: upload.target.fileName,
                            index: props.index + index
                        }, length);
                    } else {
                        console.log('***********\n\n Handle file missing in dropzone component \n\n ********');
                    }
                };
            }
            reader.readAsDataURL(file);
        });
    }, []);

    let params = {
        id: props.id,
        index: props.index,
        onDrop: onDrop,
        accept: props.accept || '',
        maxSize: props.maxSize || 500000,
        onDropRejected: (e) => console.log(e),
        maxFiles: props.maxFiles || 0,
    };

    if (props.multiple === false) {
        params.multiple = false;
    }

    // debugger;
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        acceptedFiles,
        fileRejections,
        isDragReject,
        index
    } = useDropzone(params);

    const acceptedFilesItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.name}
        </li>
    ));

    const rejectedFilesItems = fileRejections.map(file => (
        <li key={file.path}>
            {file.name} - {file.size} bytes
        </li>
    ));

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    function accepted(file) {
        if (file.length) {
            return (<ul>
                    {file}
                </ul>
            );
        } else {
            return (<></>);
        }
    }

    function rejected(file) {
        if (file.length) {
            return (
                <h4>Rejeté, fichier trop volumineux ou dans un format non supporté</h4>
            );
        } else {
            return (<></>);
        }
    }

    function genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    return (
        <div className="container">
            <div {...getRootProps({ style })} className={'my-4 btn btn-primary'}>
                <input  {...getInputProps()} tabIndex={props.tabindex} />
                <p>{genLang(props.dropMessage || DEFAULT_DROP_TEXT, props.lang)}</p>
                <aside style={{ display: props.displayFiles ? BLOCK : NONE }}>
                    {accepted(acceptedFilesItems)}
                    {rejected(rejectedFilesItems)}
                </aside>
            </div>
        </div>
    );
}
