import React, { useRef, useState } from 'react';
import { useValidation } from '@/composables/validation';
import { useTranslation } from '@/composables/translation';
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle
} from '@/components/ui/card';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@/components/ui/form';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp';

interface Props {
    resend?: boolean;
    onSubmit?: SubmitHandler<{ otp: string }>;
    onResend?: () => void | Promise<void>;
}

export function TwoFA(props: Props) {
    const { t } = useTranslation('login.2fa');
    const { required } = useValidation();
    const form = useForm({
        defaultValues: {
            otp: ''
        }
    });
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [loading, setLoading] = useState(false);
    function handleSubmit(data: { otp: string }) {
        return props.onSubmit?.(data);
    }
    async function handleResend() {
        setLoading(true);
        try {
            await props.onResend?.();
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
                {!props.resend && <CardDescription>{t('description')}</CardDescription>}
                {props.resend && <>
                    <CardDescription>{t('description-resend-0')}</CardDescription>
                    <CardDescription>{t('description-resend-1')}</CardDescription>
                </>}
            </CardHeader>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <CardContent>
                        <FormField
                            name="otp"
                            rules={{ validate: { required } }}
                            render={({ field }) =>
                                <FormItem className="tw-flex tw-flex-col tw-items-center">
                                    <div className="tw-flex tw-flex-col tw-gap-3">
                                        <FormLabel>{t('code')}</FormLabel>
                                        <FormControl>
                                            <InputOTP
                                                pattern="^[A-Za-z0-9]+$"
                                                maxLength={6}
                                                {...field}
                                                onComplete={() => buttonRef.current?.click()}
                                            >
                                                <InputOTPGroup>
                                                    <InputOTPSlot index={0} />
                                                    <InputOTPSlot index={1} />
                                                    <InputOTPSlot index={2} />
                                                    <InputOTPSlot index={3} />
                                                    <InputOTPSlot index={4} />
                                                    <InputOTPSlot index={5} />
                                                </InputOTPGroup>
                                            </InputOTP>
                                        </FormControl>
                                    </div>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />
                    </CardContent>
                    <CardFooter className="tw-flex-col tw-gap-2">
                        <Button
                            variant="link"
                            className="!tw-px-0 tw-self-start"
                            asChild
                            onClick={handleResend}
                        >
                            <Link to="#">
                                {t('resend')}
                            </Link>
                        </Button>
                        <ButtonSubmit
                            ref={buttonRef}
                            className="tw-self-stretch"
                            loading={loading ? true : undefined}
                        >
                            {t('submit')}
                        </ButtonSubmit>
                    </CardFooter>
                </form>
            </Form>
        </>
    );
}
