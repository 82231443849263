import { useEffect } from 'react';

type BeforeUnloadHandler = (event: BeforeUnloadEvent) => boolean | string | undefined;

export function useBeforeUnload(handler: BeforeUnloadHandler) {
    useEffect(() => {
        const listener = (event: BeforeUnloadEvent) => {
            const returnValue = handler(event);
            if (returnValue) {
                event.preventDefault();
                return (event.returnValue = returnValue);
            }
        };
        window.addEventListener('beforeunload', listener);
        return () => window.removeEventListener('beforeunload', listener);
    }, [handler]);
}
