export type CellCoords = [number, number];

export const NUM_WEEKDAYS = 7;
export const FIRST_CELL_GRID_OFFSET: CellCoords = [2, 2];

export function beginCellRange(
    first: CellCoords,
    second: CellCoords,
    invert?: boolean
): CellCoords {
    if (first[0] < second[0] && first[1] < second[1]) {
        return invert ? second : first;
    } else if (second[0] < first[0] && second[1] < first[1]) {
        return invert ? first : second;
    }
    return invert
        ? [Math.max(first[0], second[0]), Math.max(first[1], second[1])]
        : [Math.min(first[0], second[0]), Math.min(first[1], second[1])];
}

export function endCellRange(first: CellCoords, second: CellCoords): CellCoords {
    return beginCellRange(first, second, true);
}

export function toGridRow(start: number, end: number, offset = true): string {
    const first = start + (offset ? FIRST_CELL_GRID_OFFSET[1] : 0);
    const second = end + (offset ? FIRST_CELL_GRID_OFFSET[1] : 0);
    return `${first} / ${second}`;
}

export function toGridCol(start: number, end: number, offset = true): string {
    const first = start + (offset ? FIRST_CELL_GRID_OFFSET[0] : 0);
    const second = end + (offset ? FIRST_CELL_GRID_OFFSET[0] : 0);
    return `${first} / ${second}`;
}

export function clampCellCoords(
    coords: CellCoords,
    min: CellCoords,
    max: CellCoords
): CellCoords {
    return [
        Math.max(Math.min(coords[0], max[0]), min[0]),
        Math.max(Math.min(coords[1], max[1]), min[1])
    ];
}
