import React, { Component } from 'react';
import { Card, CardBody, CardText, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import Select from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_STYLE = {};

const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;
const fr = 'fr';
const NONE = 'none', BLOCK = 'block';
const lang = fr;
const options = [
    {
        value: '',
        label: '-'
    },
    {
        value: 'fils',
        label: 'fils/fille'
    },
    {
        value: 'conjoint',
        label: 'Conjoint'
    },
    {
        value: 'conjoint-de-fait',
        label: 'Conjoint.e de fait'
    },
    {
        value: 'petit-fils',
        label: 'petit-fils/ petite-fille'
    },
    {
        value: 'frère',
        label: 'frère/soeur'
    },
    {
        value: 'père',
        label: 'père/mère'
    },
    {
        value: 'neveu',
        label: 'neveu/nièce'
    },
    {
        value: 'petit-neveu',
        label: 'petit-neveu/petite-nièce'
    },
    {
        value: 'oncle',
        label: 'oncle/tante'
    },
    {
        value: 'cousin',
        label: 'cousin/cousine'
    },
    {
        value: 'cousin germain',
        label: 'cousin/cousine germain.e'
    },
    {
        value: 'cousin issu de germain',
        label: 'cousin issu de germain'
    },
    {
        value: 'grand-père',
        label: 'grand-père/grand-mère'
    },
    {
        value: 'beau-frère',
        label: 'belle soeur/beau frère'
    },
    {
        value: 'beau-père',
        label: 'beau-père/belle-mère'
    },
    {
        value: 'beau-fils',
        label: 'beau-fils/belle-fille'
    },
    {
        value: 'autre',
        label: 'Autre'
    }
];

const optionsEN = [
    {
        value: '',
        label: '-'
    },
    {
        value: 'fils',
        label: 'Son/daughter'
    },
    {
        value: 'conjoint',
        label: 'Spouse'
    },
    {
        value: 'petit-fils',
        label: 'Grandson/granddaughter'
    },
    {
        value: 'frère',
        label: 'Brother/sister'
    },
    {
        value: 'père',
        label: 'Father/mother'
    },
    {
        value: 'neveu',
        label: 'Nephew/niece'
    },
    {
        value: 'petit-neveu',
        label: 'Little nephew/ little niece'
    },
    {
        value: 'oncle',
        label: 'Uncle/Aunt'
    },
    {
        value: 'cousin',
        label: 'Cousin'
    },
    {
        value: 'cousin germain',
        label: 'First cousin'
    },
    {
        value: 'cousin issu de germain',
        label: 'Second cousin'
    },
    {
        value: 'grand-père',
        label: 'Grandfather/Grandmother'
    },
    {
        value: 'beau-frère',
        label: 'Brother-in-law/sister-in-law'
    },
    {
        value: 'beau-père',
        label: 'Stepfather/Stepmother'
    },
    {
        value: 'beau-fils',
        label: 'Stepson/Stepdaughter'
    },
    {
        value: 'autre',
        label: 'Other'
    }
];

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class UserPercent extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '';
        this.state = {
            lang: this.props.lang || 'fr',
            name: fd[this.props.name + '_name'] || '',
            firstname: fd[this.props.name + '_firstname'] || '',
            gender: this.props.formData[this.props.name + '_gender'],
            relation: this.props.formData[this.props.name + '_relation'],
            display: this.props.defaultDisplay === false ? false : true,
            percent: this.props.percent
        };

        this.option = this.option.bind(this);
        this.genLang = this.genLang.bind(this);

    }

    genLang(key = 'missing') {

        if (!this.props.trad) {
            return key;
        }

        if (!this.props.trad[key]) {
            return this.props.trad['wrongKey'][this.state.lang] + ' ' + key;
        }
        return this.props.trad[key][this.state.lang];
    }

    genLangNew(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevProps) {
        let props = this.props,
            obj = {};

        console.log('wow ok man');

        if (props.percent !== prevProps.percent) {
            obj['percent'] = props.percent || 'false';
        }

        if (prevProps.formData && props.formData || props.version !== prevProps.version) {

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }

            if (props.formData[props.name + '_name'] !== prevProps.formData[props.name + '_name']) {
                obj['name'] = props.formData[props.name + '_name'] || '';
            }

            if (props.formData[props.name + '_firstname'] !== prevProps.formData[props.name + '_firstname']) {
                obj['firstname'] = props.formData[props.name + '_firstname'] || '';
            }

            if (props.formData[props.name + '_assistant'] !== prevProps.formData[props.name + '_assistant']) {
                obj['assistant'] = props.formData[props.name + '_assistant'] || '';
            }

            if (props.formData[props.name + '_gender'] !== prevProps.formData[props.name + '_gender']) {
                obj['gender'] = props.formData[props.name + '_gender'] || '';
            }

            if (props.formData[props.name + '_relation'] !== prevProps.formData[props.name + '_relation']) {
                obj['relation'] = props.formData[props.name + '_relation'] || '';
            }

            if (props.display !== prevProps.display) {

                if (props.display === false && prevProps.display === true) {
                    obj.display = false;
                } else if (props.display === true && prevProps.display === false) {
                    obj.display = true;
                } else if (prevProps.display === undefined) {
                    obj.display = props.display;
                }
            }

            if (props.percent !== prevProps.percent) {
                // debugger;
                obj['percent'] = props.percent || 'false';
            }
            if (props.formData[props.name + '_percent'] !== prevProps.formData[props.name + '_percent']) {
                obj['percent'] = props.formData[props.name + '_percent'];
            }
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    option() {

        if (this.props.assistant) {
            return (
                <Col md={6}>
                    <FormGroup>
                        <Label>Assistant</Label>
                        <Input
                            type="text"
                            name={this.props.name + '_assistant'}
                            onChange={(e) => this.setState({ assistant: e.target.value })}
                            value={this.state.assistant || ''}
                        />
                    </FormGroup>
                </Col>);
        }

    }

    render() {

        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        if (this.props.noCard) {
            return (
                <Col md={this.props.col || 12} style={style}>
                    {this.props.remove ? <span className="remove" onClick={() => this.props.remove(this.props.name)}>
                        {cross}
                    </span> : ''}
                    <div style={{ display: this.state.percent === 'true' ? BLOCK : NONE }}>
                        <Row>
                            <Col md={4}>
                                <FormGroup className="tw-mt-3">
                                    <Label
                                        for="heir_percent"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {'Parts de l\'héritage en pourcentage'}
                                    </Label>
                                    <Input
                                        type="number"
                                        min={0}
                                        max={100}
                                        value={this.state.part}
                                        name={this.props.name + '_percent'}
                                        onChange={(e) =>
                                            this.setState({ part: e.target.value })
                                        }

                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </div>
                    <Row>
                        <Col md={12}>
                            <FormGroup tag="fieldset" className="!tw-mb-0">
                                <legend><span>{this.genLangNew(this.props.target, this.state.lang)}</span></legend>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_name')}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_name'}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    value={this.state.name}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_firstname')}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_firstname'}
                                    onChange={(e) => this.setState({ firstname: e.target.value })}
                                    value={this.state.firstname}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2} style={{ display: !this.props.noRelation ? BLOCK : NONE }}>
                            <FormGroup>
                                <Label>{lang === fr ? 'Relation' : 'Relationship'}</Label>
                                <Select
                                    style={{ zIndex: 1000 }}
                                    id={this.props.name + '_relation'}
                                    name={this.props.name + '_relation'}
                                    defaultValue={_.find(options, (e) => {
                                        return e.value === this.state.relation;
                                    })}
                                    onChange={(e) => {
                                        this.setState({ relation: e.value });
                                        this.props.put({
                                            params: {
                                                key: this.props.name + '_relation',
                                                value: e.value
                                            }
                                        });
                                    }}
                                    options={lang === fr ? options : optionsEN}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label>{this.genLang('_title')}</Label>
                                <Input
                                    type="select"
                                    name={this.props.name + '_gender'}
                                    onChange={(e) => this.setState({ gender: e.target.value })}
                                    value={this.state.gender || '-'}>
                                    <option value="-">Autre</option>
                                    <option value="M">{this.genLang('_sir')}</option>
                                    <option value="F">{this.genLang('_madam')}</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    {this.props.children ? this.props.children : ''}
                </Col>
            );
        }

        return (
            <Col md={this.props.col || 12} style={style}>
                <Card
                    style={{ marginBottom: '5px' }}
                >

                    {this.props.remove ? <span className="remove" onClick={() => this.props.remove(this.props.name)}>
                    {cross}
                </span> : ''}
                    <CardBody
                    >
                        <CardText tag="div">

                            <div style={{ display: this.state.percent === 'true' ? BLOCK : NONE }}>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup className="tw-mt-3">
                                            <Label
                                                for="heir_percent"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                {'Parts de l\'héritage en pourcentage'}
                                            </Label>
                                            <Input
                                                type="number"
                                                min={0}
                                                max={100}
                                                value={this.state.part}
                                                name={this.props.name + '_percent'}
                                                onChange={(e) =>
                                                    this.setState({ part: e.target.value })
                                                }

                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>

                            <Row>
                                <Col md={12}>
                                    <FormGroup tag="fieldset" className="!tw-mb-0">
                                        <legend>
                                            <span>{this.genLangNew(this.props.target, this.state.lang)}</span>
                                        </legend>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_name')}</Label>
                                        <Input
                                            type="text"
                                            name={this.props.name + '_name'}
                                            onChange={(e) => this.setState({ name: e.target.value })}
                                            value={this.state.name}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_firstname')}</Label>
                                        <Input
                                            type="text"
                                            name={this.props.name + '_firstname'}
                                            onChange={(e) => this.setState({ firstname: e.target.value })}
                                            value={this.state.firstname}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2} style={{ display: !this.props.noRelation ? BLOCK : NONE }}>
                                    <FormGroup>
                                        <Label>{lang === fr ? 'Relation' : 'Relationship'}</Label>
                                        <Select
                                            style={{ zIndex: 1000 }}
                                            id={this.props.name + '_relation'}
                                            name={this.props.name + '_relation'}
                                            defaultValue={_.find(options, (e) => {
                                                return e.value === this.state.relation;
                                            })}
                                            onChange={(e) => {
                                                this.setState({ relation: e.value });
                                                this.props.put({
                                                    params: {
                                                        key: this.props.name + '_relation',
                                                        value: e.value
                                                    }
                                                });
                                            }}
                                            options={lang === fr ? options : optionsEN}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label>{this.genLang('_title')}</Label>
                                        <Input
                                            type="select"
                                            name={this.props.name + '_gender'}
                                            onChange={(e) => this.setState({ gender: e.target.value })}
                                            value={this.state.gender || '-'}>
                                            <option value="-">Autre</option>
                                            <option value="M">{this.genLang('_sir')}</option>
                                            <option value="F">{this.genLang('_madam')}</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {this.props.children ? this.props.children : ''}
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}
