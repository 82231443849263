import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { CaseStatusCell, DateCell, PortalFolderIdCell } from '@/components/ui/cells';
import { useTranslation } from '@/composables/translation';
import { type Case } from '@/types/folder';
import { instance } from '@/composables/api';

export function Dashboard() {
    const { t } = useTranslation('dashboard');

    const schema = useMemo<CrudSchema<Case>>(() => [
        {
            id: 'folder_id',
            type: CrudInputType.TEXT,
            name: t('table.id'),
            col: 6,
            create: true,
            columnDef: {
                id: 'folder_id',
                header: t('table.id'),
                accessorKey: 'folder_id',
                cell: PortalFolderIdCell
            }
        },
        {
            id: 'name',
            type: CrudInputType.TEXT,
            name: t('table.name'),
            col: 6,
            columnDef: {
                id: 'name',
                header: t('table.name'),
                accessorKey: 'name'
            }
        },
        {
            id: 'status',
            type: CrudInputType.TEXT,
            name: t('table.status'),
            columnDef: {
                id: 'status',
                header: t('table.status'),
                accessorKey: 'status',
                cell: CaseStatusCell
            }
        },
        {
            id: 'updated_at',
            type: CrudInputType.TEXT,
            name: t('table.updated_at'),
            columnDef: {
                id: 'updated_at',
                header: t('table.updated_at'),
                accessorKey: 'updated_at',
                cell: DateCell
            }
        },
        {
            id: 'created_at',
            type: CrudInputType.TEXT,
            name: t('table.created_at'),
            columnDef: {
                id: 'created_at',
                header: t('table.created_at'),
                accessorKey: 'created_at',
                cell: DateCell
            }
        }
    ], []);

    return (
        <main className="tw-p-6">
            <Card>
                <CardHeader>
                    <CardTitle>{t('title')}</CardTitle>
                </CardHeader>
                <CardContent>
                    <CrudTable<Case, '_id'>
                        idKey="_id"
                        schema={schema}
                        onRead={() =>
                            instance.get('/v1/customer/folder/folders')
                                .then(res => res.data.folders)
                        }
                    />
                </CardContent>
            </Card>
        </main>
    );
}
