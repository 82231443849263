import React from 'react';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { DialogTrigger } from '@/components/ui/dialog';

interface Props {
    title?: string;
    message: string;
    cancelText?: string;
    confirmText?: string;
    onConfirm?: () => void;
    children?: React.ReactNode;
}

export function ConfirmDialogButton(props: Props) {
    return (
        <ConfirmDialog
            title={props.title}
            message={props.message}
            confirmText={props.confirmText}
            cancelText={props.cancelText}
            onConfirm={props.onConfirm}
        >
            {props.children &&
                <DialogTrigger asChild>
                    {props.children}
                </DialogTrigger>
            }
        </ConfirmDialog>
    );
}
