import {
    GEN_PDF_PREVIEW,
    INIT_WILL,
    PDF_CHANGED,
    PDF_QUEUE_PUSH,
    POST_FORM_WILL,
    PUT_FORM_WILL,
    REMOVE_BUNDLE,
    REMOVE_DELETE_DOCUMENT,
    REMOVE_FILE_GCLOUD,
    REMOVE_FORM_WILL,
    ZENDESK_PDF_REQUEST,
    ZENDESK_TICKET,
    ZENDESK_TICKET_INIT
} from '../_actions/forms-actions';
import _ from 'lodash';

import { form } from '../_formsDefault/will';

const DELETE_DOCUMENT = '_deleteDocument';
const UPLOAD_DOCUMENT = '_uploadDocument';

export default function formsReducer(state = '', {
    type,
    params
}) {
    switch (type) {
    case INIT_WILL:
        return {
            will: {
                ...form
            }
        };
    case REMOVE_BUNDLE:
        // console.log('remove params.key',params.key)
        var result = _.pickBy(state.will, function (value, key) {
            return !_.startsWith(key, params.key);
        });

        return { will: result };
    case REMOVE_FORM_WILL:
        var result = _.pickBy(state.will, function (value, key) {
            return !_.startsWith(key, params.key);
        });

        return { will: result };
    case POST_FORM_WILL:
        // console.log({will:{...params}})
        if (_.isEmpty(params)) {
            return {
                will: {
                    ...form
                }
            };
        }

        return { will: { ...params } };
    case PUT_FORM_WILL:
        const will = state.will;
        const titles = { ...will.formTitles };
        if (will && params && params.key) {
            if (params.title) {
                titles[params.key] = params.title;
            }
            will[params.key] = params.value;
            will.formTitles = titles;
        }
        return { will, ...state };

    case REMOVE_FILE_GCLOUD:
        let will2 = state.will;
        if (will2 && params && params.key) {
            let tempKey = params.key;
            // delete will2[params.key];
            tempKey = tempKey.replace(UPLOAD_DOCUMENT, DELETE_DOCUMENT);
            will2[tempKey] = 'true';
        }
        return { will: will2, ...state };

    case REMOVE_DELETE_DOCUMENT:

        let tempTest = [];
        let stateWill = state.will;

        for (const w in stateWill) {
            if (w.match(DELETE_DOCUMENT)) {
                tempTest.push(w.replace(DELETE_DOCUMENT, ''));
            }
        }
        // debugger;
        for (const w in stateWill) {
            for (const t in tempTest) {
                if (w.match(tempTest[t].replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))) {
                    delete stateWill[w];
                }
                continue;
            }
        }
        return {
            ...state,
            will: stateWill
        };
        // return{...state,will:_.pickBy(state.will,(value,key) => {
        //   return !key.match(DELETE_DOCUMENT);
        // })};
    case PDF_QUEUE_PUSH:
        let PDF_QUEUE = state.PDF_QUEUE;
        editId = PDF_QUEUE.findIndex((e) => e.ticket == params.ticket);

        if (editId > -1) {
            PDF_QUEUE[editId] = params;
            PDF_QUEUE[editId].status = 'INCOMPLETE';
        } else {
            PDF_QUEUE.push(params);
        }

        return {
            PDF_QUEUE: PDF_QUEUE,
            ...state
        };

    case ZENDESK_PDF_REQUEST:
        if (document.getElementById('neolegalForm')) {
            document
                .getElementById('neolegalForm')
                .dispatchEvent(new Event('submit', { cancelable: true }));
        }
        return {
            ...state,
            PDF_REQUEST: true
        };
    case ZENDESK_TICKET_INIT:
        localStorage.removeItem('PC_save');
        return {
            pdf: '',
            ticket: {},
            reset: true,
            PDF_QUEUE: state.PDF_QUEUE
        };

    case GEN_PDF_PREVIEW:
        let queue = state.PDF_QUEUE;
        let editId = queue.findIndex((e) => e.ticket == params.ticket);

        if (editId > -1) {
            queue[editId].url = params.url;
            queue[editId].status = 'COMPLETE';
        }

        return {
            ...state,
            pdf: params.url,
            exceptions: params.exceptions,
            PDF_REQUEST: false,
            changed: params.changed,
            PDF_QUEUE: queue
        };

    case PDF_CHANGED:
        return {
            ...state,
            changed: true,
            PDF_REQUEST: false
        };

    case ZENDESK_TICKET:
        return {
            ...state,
            pdf: '',
            ticket: params
        };
    default:
        return state;
    }
}
