export const GEN_PDF_PREVIEW = 'PDF:get';
export const PDF_CHANGED = 'PDF:changed';
export const ZENDESK_TICKET = 'ticket:get';
export const ZENDESK_TICKET_INIT = 'ticket:init';
export const ZENDESK_PDF_REQUEST = 'PDF:request';
export const PDF_QUEUE_PUSH = 'PDF:QUEUE_PUSH';

export const POST_FORM_WILL = 'POST_FORM_WILL';
export const PUT_FORM_WILL = 'PUT_FORM_WILL';
export const REMOVE_FORM_WILL = 'REMOVE_FORM_WILL';

export const REMOVE_FILE_GCLOUD = 'REMOVE_FILE_GCLOUD';

export const REMOVE_DELETE_DOCUMENT = 'REMOVE_DELETE_DOCUMENT';

export const REMOVE_BUNDLE = 'REMOVE_BUNDLE';
export const INIT_WILL = 'INIT_WILL';

// export function getWill({params}){
//     return {
//         type:GET_BUNDLE,
//         params:params
//     }
// }
export function initWill() {
    // debugger;
    return {
        type: INIT_WILL
    };
}

export function removeBundle({ params }) {
    // debugger;

    return {
        type: REMOVE_BUNDLE,
        params
    };
}

export function removeWill({ params }) {
    // debugger;
    return {
        type: REMOVE_FORM_WILL,
        params
    };
}

export function removeFileDocument() {
    // debugger;
    return {
        type: REMOVE_DELETE_DOCUMENT
        // params:params
    };
}

export function removeFileFromGCloud({ params }) {
    // debugger;
    return {
        type: REMOVE_FILE_GCLOUD,
        params
    };
}

export function putWill({ params }) {
    return {
        type: PUT_FORM_WILL,
        params
    };
}

export function postWill({ params }) {
    return {
        type: POST_FORM_WILL,
        params
    };
}

export function queuePDF({
    type,
    data,
    status
}) {
    return {
        type: PDF_QUEUE_PUSH,
        params: {
            type,
            ticket: data.ticket,
            data,
            status
        }
    };
}

export function requestPDF() {
    return {
        type: ZENDESK_PDF_REQUEST
    };
}

export function genPDF({ params }) {
    return {
        type: GEN_PDF_PREVIEW,
        params
    };
}

export function PDFChanged({ params }) {
    return {
        type: PDF_CHANGED,
        params
    };
}

export function setTicket({ params }) {
    return {
        type: ZENDESK_TICKET,
        params
    };
}

export function init() {
    return {
        type: ZENDESK_TICKET_INIT
    };
}
