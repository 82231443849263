import React, { Component } from 'react';

import { Button, Col, FormGroup, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import _ from 'lodash';
import { Legend } from './Legend';
import { User } from './User';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Question = <FontAwesomeIcon icon={faQuestionCircle} className="avensRed" />;

const OBJECT = 'object';

const DEFAULT_STYLE = {};

export class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: this.props.counter || 0,
            lang: this.props.lang || 'fr',
            display: this.props.defaultDisplay === false ? false : true,
            users: [],
            usersList: [],
        };
        this.addHeirForm = this.addHeirForm.bind(this);
        this.removeHeirForm = this.removeHeirForm.bind(this);
    }

    genLang(key = 'missing') {
        const lang = (this.state.lang === '' || this.state.lang === null || this.state.lang === undefined) ? 'fr' : this.state.lang;

        if (typeof key === OBJECT) {
            if (lang && key[lang]) {
                return key[lang];
            } else {
                return '';
            }
        } else {
            if (!this.props.trad) {
                return key;
            }

            if (!this.props.trad[key]) {
                return this.props.trad['wrongKey'][lang] + ' ' + key;
            }

            return this.props.trad[key][lang];
        }

    }

    componentDidUpdate(prevProps) {
        let props = this.props;
        let obj = {};

        if (props.display !== prevProps.display) {

            if (props.display === false && prevProps.display === true) {
                obj.display = false;
            } else if (props.display === true && prevProps.display === false) {
                obj.display = true;
            } else if (prevProps.display === undefined) {
                obj.display = props.display;
            }
        }

        if (props.formData && JSON.stringify(props.formData) !== JSON.stringify(prevProps.formData) || this.state.force) {
            let hamza = {};
            let fd = this.props.formData;
            let reg = new RegExp('^' + this.props.name);
            const usersList = [];

            for (let f in fd) {
                if (f.match(reg)) {
                    let split = f.split(reg);
                    hamza[split[1].match(/\d+/)[0]] = true;
                }
            }

            let arr = [];

            for (let h in hamza) {
                let key = this.props.name + '_' + h;
                usersList.push({
                    key: key,
                    counter: h
                });

                arr.push(
                    <div key={key}>
                        <Row>
                            <User
                                removeFileFromGCloud={this.props.removeFileFromGCloud}
                                add={this.props.add}
                                public={this.props.public}
                                ticket={this.props.ticket}
                                target={this.props.target}
                                address={this.props.address}
                                version={this.props.version}
                                put={this.props.put}
                                lang={this.state.lang}
                                trad={this.props.trad}
                                formData={{ ...this.props.formData }}
                                remove={this.removeHeirForm}
                                name={key} removeHeirForm
                                counter={h}
                                toggleLoading={this.props.toggleLoading}
                                pushWaitingList={
                                    this.props.pushWaitingList
                                }
                                pullWaitingList={
                                    this.props.pullWaitingList
                                }
                                questionned={this.props.misEnCause || []}
                                title={this.props.title}
                                noRelation={this.props.noRelation ? false : true}
                                noCard={this.props.noCard}
                                position={this.props.position}
                                birthday={this.props.birthday}
                                noContact={this.props.noContact}
                                noGender={this.props.noGender ? false : true}
                                noName={this.props.noName ? false : true}
                                noFirstname={this.props.noFirstname ? false : true}
                            >
                            </User>
                        </Row>
                    </div>
                );
            }
            // debugger;
            obj = {
                ...obj,
                users: arr,
                counter: arr.length + 10,
                force: false,
                usersList: usersList

            };
        }

        if (props.lang !== prevProps.lang) {

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }

        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    componentDidMount() {
        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);
        const usersList = [];

        for (let f in fd) {
            if (f.match(reg)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }

        let arr = [];
        for (let h in obj) {
            let key = this.props.name + '_' + h;
            usersList.push({
                key: key,
                counter: h
            });

            arr.push(
                <Row key={key}>
                    <User
                        removeFileFromGCloud={this.props.removeFileFromGCloud}
                        add={this.props.add}
                        public={this.props.public}
                        ticket={this.props.ticket}
                        target={this.props.target}
                        address={this.props.address}
                        put={this.props.put}
                        lang={this.state.lang}
                        trad={this.props.trad}
                        formData={{ ...this.props.formData }}
                        remove={this.removeHeirForm}
                        name={key}
                        counter={h}
                        noRelation={this.props.noRelation ? false : true}
                        noCard={this.props.noCard ? false : true}
                        position={this.props.position}
                        birthday={this.props.birthday}
                        noContact={this.props.noContact}
                        noGender={this.props.noGender ? false : true}
                        noName={this.props.noName ? false : true}
                        noFirstname={this.props.noFirstname ? false : true}
                        title={this.props.title}
                        toggleLoading={this.props.toggleLoading}
                        pushWaitingList={
                            this.props.pushWaitingList
                        }
                        pullWaitingList={
                            this.props.pullWaitingList
                        }
                    />
                </Row>
            );
        }

        //this.setState({users:arr});
        this.setState({
            users: arr,
            usersList: usersList
        });

    }

    addHeirForm() {

        this.setState(state => {

            let counter = this.state.counter;
            let key = this.props.name + '_' + counter++;

            state.counter = counter;
            state.usersList.push({
                key: key,
                counter: counter
            });

            return state;
        });
    }

    removeHeirForm(id) {
        // this.props.formData['remove_'+id]=true;
        this.props.remove({ params: { key: id } });
        this.setState({
            users: _.reject(this.state.users, { key: id }),
            usersList: _.reject(this.state.usersList, { key: id }),
        });
    }

    renderInfo(info, id) {
        if (!info) {
            return '';
        }

        return (
            <>
                <b id={id}>{Question}</b>
                <UncontrolledPopover placement="top" target={id}>
                    <PopoverBody>{info}</PopoverBody>
                </UncontrolledPopover>
            </>
        );
    }

    render() {
        let props = this.props;
        const usersList = this.state.usersList;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        return (
            <>
                {usersList.map(form => (
                    <div key={form.key}>
                        <User
                            removeFileFromGCloud={this.props.removeFileFromGCloud}
                            add={this.props.add}
                            public={this.props.public}
                            ticket={this.props.ticket}
                            target={this.props.target}
                            noRelation={!this.props.noRelation}
                            noCard={this.props.noCard}
                            position={this.props.position}
                            birthday={this.props.birthday}
                            noContact={this.props.noContact}
                            noGender={!this.props.noGender}
                            noName={!this.props.noName}
                            noFirstname={!this.props.noFirstname}
                            address={this.props.address}
                            put={this.props.put}
                            lang={this.state.lang}
                            trad={this.props.trad}
                            formData={{ ...this.props.formData }}
                            remove={this.removeHeirForm}
                            name={form.key}
                            counter={form.counter}
                            title={this.props.title}
                            toggleLoading={this.props.toggleLoading}
                            pushWaitingList={
                                this.props.pushWaitingList
                            }
                            pullWaitingList={
                                this.props.pullWaitingList
                            }

                        />
                    </div>
                ))}
                {this.props.children}
                <FormGroup className="buttonHolder">
                    <Button
                        className="tw-mt-3"
                        block
                        outline
                        color="primary"
                        onClick={() => this.addHeirForm()}
                    >
                        {this.genLang('_btnadd') || 'Ajouter'}
                    </Button>
                </FormGroup>
            </>
        );
    }
}
