const ipHostnameRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(?::[0-9]{1,5})?$/;
const orgRegex = /^([a-z0-9-]+)(\.|$)/i;

export function getDefaultOrg(url: URL) {
    const orgOverride = url.searchParams.get('org') ?? undefined;
    if (orgOverride) {
        return orgOverride;
    }

    const hostname = url.hostname;
    if (hostname === 'localhost') {
        return 'neolegal';
    }

    if (ipHostnameRegex.test(hostname)) {
        return '';
    }

    const subdomain = hostname.match(orgRegex)?.[1];
    if (!subdomain) return '';
    if (subdomain === 'www' || subdomain === 'stg') {
        return '';
    }
    if (subdomain === 'neodoc') {
        return 'neolegal';
    }
    return subdomain;
}

export function useOrg() {
    const org = getDefaultOrg(new URL(window.location.href));
    const showOrg = !org;
    return {
        org,
        showOrg
    };
}
