import { LOGIN } from '../_actions/users-actions';

export default function usersReducer(
    state = '',
    {
        type,
        params
    }
) {
    switch (type) {
    case LOGIN:
        if (params.status === 200) {
            return {
                logged: params.logged,
                connectedUser: params
            };
        } else {
            localStorage.clear();
            return state;
        }
    default:
        return state;
    }
}
