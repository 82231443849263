import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faDotCircle } from '@fortawesome/free-solid-svg-icons';

export function LegendHeader(props) {
    let style = {
        marginBottom: '15px',
        cursor: 'pointer',
    };

    if (props.style) {
        style = {
            style,
            ...props.style
        };
    }

    style['color'] = (props.color) ?? '#0052BB';
    style['fontSize'] = (props.size) ?? '18pt';
    style['paddingLeft'] = '15px';
    style['paddingTop'] = '15px';

    return (
        <div className="neoHLMenu" style={{
            padding: '10px',
            marginBottom: '5px',
            marginTop: '15px'
        }}
             onClick={
                 () => {
                     props.callback?.call(undefined, props.target);
                 }
             }
        >
            <div className="tw-cursor-pointer tw-select-none">
                <FontAwesomeIcon
                    style={{ color: style.color }}
                    icon={!props.forceDisplay
                        ? props.active
                            ? faAngleDown
                            : faAngleRight
                        : faDotCircle
                    } />
                <span style={style}>{props.children || ''} </span>
            </div>
        </div>
    );
}
