import React from 'react';
import { cn } from '@/lib/utils';
import _ from 'lodash';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
    variant?: 'indeterminate' | 'determinate';
    value?: number;
}

export function Linear(props: Props) {
    const variant = props.variant ?? props.value !== undefined ? 'determinate' : 'indeterminate';
    const value = _.clamp((props.value ?? 0) / 100, 0, 1);
    return (
        <span
            {...props}
            className={cn(
                'tw-relative tw-h-[0.25rem] tw-overflow-hidden tw-text-primary',
                props.className
            )}
        >
            <span className={cn(
                'tw-absolute tw-inset-0 tw-bg-[currentColor] tw-opacity-15 tw-grayscale'
            )}/>
            {variant === 'determinate' &&
                <span
                    className={cn(
                        'tw-absolute tw-inset-0 tw-bg-[currentColor]',
                        'tw-origin-left tw-transition-transform'
                    )}
                    style={{ transform: `scaleX(${value})` }}
                />
            }
            {variant === 'indeterminate' && <>
                <span className={cn(
                    'tw-absolute tw-h-full tw-bg-[currentColor]',
                    'tw-animate-linear-progress-1',
                    'tw-origin-left tw-transition-[transform_0.2s_linear]'
                )}/>
                <span className={cn(
                    'tw-absolute tw-h-full tw-bg-[currentColor]',
                    'tw-animate-linear-progress-2',
                    'tw-origin-left tw-transition-[transform_0.2s_linear]'
                )} style={{ animationDelay: '1.15s' }}/>
            </>}
        </span>
    );
}
