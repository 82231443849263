import { useMemo } from 'react';
import Fuse, { type FuseResult, type FuseSortFunctionItem, type IFuseOptions } from 'fuse.js';

interface FilterOptions<T> {
    list: T[];
    search?: string;
    options?: IFuseOptions<T>;
    dependencies?: any[];
}

export function useFilter<T>({ list, search, options, dependencies }: FilterOptions<T>): Array<FuseResult<T>> {
    const fuse = useMemo(() => new Fuse(list, options), [list]);
    return useMemo(
        () => {
            if (search) {
                return fuse.search(search);
            }
            if (options?.sortFn) {
                return list.map((item, index) => ({
                    idx: index,
                    score: 0,
                    item: item as FuseSortFunctionItem
                }))
                    .sort(options.sortFn)
                    .map(({ idx, item }) => ({ item: item as T, refIndex: idx }));
            }
            return list.map((i, index) => ({ item: i, refIndex: index }));
        },
        [fuse, search, ...(dependencies ?? [])]
    );
}
