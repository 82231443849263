import React, { Component } from 'react';

import _ from 'lodash';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { DropZoneGcloud } from './DropZoneGcloud';

import { Legend } from './Legend';

import { SortableContainer } from './SortableContainerTest';
import { v4 as uuid } from 'uuid';

import axios from 'axios';

const NGROK = import.meta.env.VITE_API_URL;

const BUNDLE = '_bundle';
const CUSTOM = '_custom';

const DEFAULT_SIZE = 12;
const BOOLEAN = '_boolean';
const DEFAULT_STYLE = { textAlign: 'left' };
const REAL_NAME = '_realName';
const ORIGINAL_NAME = '_originalName';
const DEFAULT_REAL_NAME = 'Liasse';
const AMMEND = '_ammend';
const UUID_REGEXP = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/;
const TIMEOUT_DURATION = 1000;
const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const UNDERSCORE_REPLACEMENT = '*$?$*';
const UNDERSCORE = /_/g;
const SPACE_REPLACEMENT = '!(#)!';
const SPACE = /\s/g;

const INDEX = '_index';
const PJ = '_pj_list';

export class DropContainerLiasse extends Component {

    constructor(props) {
        super(props);

        let fd = this.props.formData;

        this.state = {
            lang: props.lang || FR,
            value: fd && fd[this.props.name] ? fd[this.props.name] : [],
            pjList: fd[this.props.name + PJ] ? fd[this.props.name + PJ] : '',
            ticket: this.props.ticket || '',
            xyz: 'dropPC'
        };
        this.fileCounter = 0;
        this.fileArray = [];

        this.state[props.name + '_party'] = 'P';

        this.dropName = '_' + ((props.name).split('_'))[1];

        this.addBundle = this.addBundle.bind(this);
        this.setFile = this.setFile.bind(this);
        this.addCustomPiece = this.addCustomPiece.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.setJointFilesList = this.setJointFilesList.bind(this);
        this.putElementName = this.putElementName.bind(this);
        this.changeLiasse = this.changeLiasse.bind(this);
    }

    setJointFilesList(innerValue) {
        // return;

        // debugger;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        let self = this;

        this.timeout = setTimeout(() => {
            let str = '';

            let value;

            if (innerValue) {
                value = innerValue;
                self.savedValue = value;
            } else if (self.savedValue) {
                self.value = self.savedValue;
                delete self.savedValue;
            } else {
                value = self.state.value;
            }

            let data = self.state;
            const PREFIX = data[self.props.name + '_party'];
            for (let v in value) {

                let convertedName = value[v].key.replace(BOOLEAN, REAL_NAME);
                if (value[v].bundle) {
                    convertedName = value[v].bundleId;
                }

                let convertedReal = value[v].name;

                if (value[v].bundle && !value[v].name) {
                    convertedReal = 'Liasse';
                }

                str += PREFIX + '-' + (parseInt(v) + 1) + ' ';
                str += convertedReal;

                if (value[v].ammend === 'true') {

                    str += ' a_';
                }

                str += '\n';

            }

            if (innerValue) {

                self.setState({
                    pjList: str,
                    value: innerValue
                });
            } else {
                if (self.savedValue) {
                    self.setState({
                        pjList: str,
                        value: self.savedValue
                    });
                    self.savedValue = null;
                } else {
                    self.setState({
                        pjList: str,
                    });
                }

            }

            self.props.put({
                params: {
                    key: self.props.name + PJ,
                    value: str
                }
            });
        }, TIMEOUT_DURATION);
    }

    addBundle({
        value,
        key,
        index
    }) {

        this.setState(state => {
            let obj;
            let arr = this.state.value;
            let max = 1;
            if (Array.isArray(arr)) {
                max = Math.max(...arr.map(o => o.index)) + 1;
            }

            obj = {
                name: '',
                id: max.toString(),
                index: max.toString(),
                checked: true,
                ammend: false,
                key: key,
                loading: true,
                bundle: true,
                bundleId: uuid(),
                files: value,
            };
            obj[this.props.name + '_' + obj.bundleId + REAL_NAME] = DEFAULT_REAL_NAME;

            state.value.push(obj);

            let root = this.props.name + '_' + obj.bundleId;

            this.props.put({
                params: {
                    key: root + REAL_NAME,
                    value: DEFAULT_REAL_NAME
                }
            });

            this.props.put({
                params: {
                    key: root + INDEX,
                    value: (state.value.length - 1).toString()
                }
            });

            let arr2 = [];
            for (let f in obj.files) {
                arr2.push({
                    base64: obj.files[f].content,
                    name: root + BUNDLE + '_' + f + this.dropName,
                });
                this.props.put({
                    params: {
                        key: root + BUNDLE + '_' + f + this.dropName,
                        value: obj.files[f].content
                    }
                });

                this.props.put({
                    params: {
                        key: root + BUNDLE + '_' + f + REAL_NAME,
                        value: obj.files[f].name
                    }
                });
            }

            for (let a in arr2) {

                this.props.pushWaitingList({
                    params: {
                        key: arr2[a].name
                    }
                });

                axios.post(
                    NGROK + '/v1/add-file-gcloud', {
                        ticket_id: this.state.ticket,
                        auth_id: localStorage.getItem('user_id'),
                        target: this.props.target,
                        data: arr2[a],
                        name: arr2[a].name,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        }
                    }
                )
                    .then(
                        (status) => {

                            let data = status.data.result;

                            this.props.put({
                                params: {
                                    key: status.data.name,
                                    value: status.data.result
                                }
                            });

                            this.props.pullWaitingList({
                                key: status.data.name
                            });

                        },
                        (error) => {
                            this.props.toggleLoading();
                            console.log(error);
                        }
                    );
            }

            return state;

        }, (e) => {
            this.setJointFilesList(this.state.value);
        });
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevprops) {

        let props = this.props;
        let obj = {};

        let name = props.name;
        let value = [];
        let bundlePack = {};

        if (prevprops.ticket !== props.ticket) {
            obj['ticket'] = props.ticket;
        }

        if (props.version !== prevprops.version) {
            for (let g in this.props.formData) {
                let temp = g.split(this.props.name + '_')[1];

                if (g.match(BUNDLE) && temp != undefined) {

                    if (g.match(REAL_NAME)) {
                        temp = temp.split(BUNDLE);
                        if (bundlePack[temp[0]]) {
                            if (bundlePack[temp[0]][temp[1].match(/\d+/)[0]]) {

                                bundlePack[temp[0]][temp[1].match(/\d+/)[0]].name = this.props.formData[g];

                            } else {

                                let obj = {};
                                obj = { name: this.props.formData[g] };
                                bundlePack[temp[0]][temp[1].match(/\d+/)[0]] = obj;
                            }
                        } else {
                            bundlePack[temp[0]] = {};
                            bundlePack[temp[0]][temp[1].match(/\d+/)[0]] = {
                                name: this.props.formData[g]
                            };
                        }
                    } else if (g.match(this.dropName)) {

                        temp = temp.split(BUNDLE);
                        if (bundlePack[temp[0]]) {
                            if (bundlePack[temp[0]][temp[1].match(/\d+/)[0]]) {

                                bundlePack[temp[0]][temp[1].match(/\d+/)[0]].content = this.props.formData[g];
                            } else {
                                let obj = {};
                                obj = { content: this.props.formData[g] };
                                bundlePack[temp[0]][temp[1].match(/\d+/)[0]] = obj;
                            }
                        } else {

                            bundlePack[temp[0]] = {};
                            bundlePack[temp[0]][temp[1].match(/\d+/)[0]] = {
                                content: this.props.formData[g]
                            };
                        }
                    }

                }
            }

            let match = 0;
            for (let i in props.formData) {
                if (i.match(name) && i.match(BOOLEAN)) {

                    if (props.formData[i]) {

                        let decoded = i.split(name + '_')[1];

                        decoded = decodeURIComponent(decoded);

                        let temp = i.replace(BOOLEAN, INDEX);

                        let isUUID = i.replace(this.props.name + '_', '').replace(INDEX, '');
                        isUUID = isUUID.match(UUID_REGEXP);
                        let obj = {};
                        //will always as at least one item in it
                        let isBundle = i.replace(BOOLEAN, BUNDLE + '_0_realName');

                        if (props.formData[isBundle] && isBundle.match(BUNDLE)) {
                            let name = i.replace(BOOLEAN, REAL_NAME);

                            obj = {
                                id: props.formData[temp] || (match).toString(),
                                index: props.formData[temp] || (match).toString(),
                                key: i,
                                ammend: props.formData[temp.replace(INDEX, AMMEND)] || (match).toString(),
                                checked: props.formData[i] === 'true',
                                value: props.formData[i + this.dropName],
                                name: props.formData[name],

                                realName: props.formData[name],
                                custom: !!props.formData[temp.replace(BOOLEAN, CUSTOM)]
                                // files:[...bundlePack]
                            };

                            if (isUUID && isUUID[0]) {
                                obj.bundleId = isUUID[0];
                            } else {
                                obj.bundleId = '999999999999999999999999999999999999999999999';
                            }

                            let rn = i.replace(BOOLEAN, REAL_NAME);
                            obj[rn] = props.formData[name] || '';

                            if (bundlePack && bundlePack[obj.bundleId]) {
                                obj.files = _.map(bundlePack && bundlePack[obj.bundleId], (elt) => {
                                    return elt;
                                });
                            }

                        } else {

                            let rn = i.replace(BOOLEAN, REAL_NAME);
                            obj = {
                                id: props.formData[temp] || (match).toString(),
                                index: props.formData[temp] || (match).toString(),
                                key: i,
                                ammend: props.formData[temp.replace(INDEX, AMMEND)] || (match).toString(),
                                checked: props.formData[i] === 'true',
                                realName: props.formData[i.replace(BOOLEAN, ORIGINAL_NAME)],
                                value: props.formData[i.replace(BOOLEAN, REAL_NAME)],
                                name: this.props.formData[rn],
                            };

                            obj[rn] = this.props.formData[rn] || '';
                        }

                        value.push(obj);
                        this.props.put({
                            params: {
                                key: temp,
                                value: (props.formData[temp] || (match).toString()).toString()
                            }
                        });
                        match++;
                    }
                } else if (i.match(REAL_NAME)) {
                    obj[i] = props.formData[i];
                } else if (i.match(AMMEND)) {
                    obj[i] = props.formData[i];
                }
            }

            obj['value'] = _.sortBy(value, [(e) => {
                return parseInt(e.id);
            }]);
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj, () => {
                this.setJointFilesList(this.state.value);
            });
        }
    }

    setFile({
        value,
        key,
        index
    }) {

        this.setState(state => {

            if (state.value && Array.isArray(state.value)) {

                for (let v in value) {

                    let noAccent = this.accentsTidy(value[v].name);
                    let key = this.props.name + '_' + uuid();

                    let encoded = encodeURI(noAccent.replaceAll(UNDERSCORE, UNDERSCORE_REPLACEMENT).replaceAll(SPACE, SPACE_REPLACEMENT));
                    let obj = {
                        ...value[v],
                        name: noAccent,
                        id: (parseInt(this.state.value.length) + value[v].index).toString(),
                        checked: true,
                        ammend: false,
                        key: key + BOOLEAN,
                        encoded: encoded
                    };

                    obj[key + REAL_NAME] = noAccent;
                    state[key + REAL_NAME] = noAccent;
                    state.value.push(obj);

                    this.props.put({
                        params: {
                            key: key + ORIGINAL_NAME,
                            value: noAccent
                        }
                    });

                    // console.log('i dont have an accent',noAccent)
                    this.props.put({
                        params: {
                            key: key + REAL_NAME,
                            value: noAccent
                        }
                    });

                    this.props.put({
                        params: {
                            key: key + this.dropName,
                            value: value[v].content
                        }
                    });

                    this.props.put({
                        params: {
                            key: key + INDEX,
                            value: (state.value.length - 1).toString()
                        }
                    });

                }
            } else {
                console.error('value is not an array in dropfile');
            }

            return state;

        }, () => {
            this.setJointFilesList(this.state.value);
        });
    }

    accentsTidy(s) {
        var r = s.toLowerCase();
        r = r.replace(new RegExp(/\s/g), '');
        r = r.replace(new RegExp(/[àáâãäå]/g), 'a');
        r = r.replace(new RegExp(/æ/g), 'ae');
        r = r.replace(new RegExp(/ç/g), 'c');
        r = r.replace(new RegExp(/[èéêë]/g), 'e');
        r = r.replace(new RegExp(/[ìíîï]/g), 'i');
        r = r.replace(new RegExp(/ñ/g), 'n');
        r = r.replace(new RegExp(/[òóôõö]/g), 'o');
        r = r.replace(new RegExp(/œ/g), 'oe');
        r = r.replace(new RegExp(/[ùúûü]/g), 'u');
        r = r.replace(new RegExp(/[ýÿ]/g), 'y');
        return r;
    };

    sendAllFilesToBackend(state) {

        state.map((item, i) => {

            if (state[i].files) {
                state[i].files.map((item2, j) => {
                    if (item2.content.length > 256) {
                        state[i]['files'][j]['content'] = '';
                    }
                });
            }

        });

        this.props.put({
            params: {
                key: 'allFiles',
                value: state
            }
        });

    }

    changeLiasse(e, nbFileFromLiasse) {

        this.sendAllFilesToBackend(this.state.value);

        if (nbFileFromLiasse == 1) {
            var keyFrom = this.props.name + '_' + e.idFromLiasse + '_bundle_' + e.index;
            var keyArr = keyFrom.split('_');
            var key = this.props.name + '_' + keyArr[keyArr.length - 3];
            this.removeBundle(key, keyArr[keyArr.length - 3]);

        }
    }

    removeBundle(key, notencoded) {

        this.props.removeFileFromGCloud({
            params: {
                key: key + this.dropName,
                value: ' '
            }
        });

        this.props.remove({ params: { key: key + BOOLEAN } });
        this.props.remove({ params: { key: key + INDEX } });
        this.props.remove({ params: { key: key + REAL_NAME } });
        this.props.remove({ params: { key: key + ORIGINAL_NAME } });
        this.props.remove({ params: { key: key + PJ } });
        this.props.remove({ params: { key: key + AMMEND } });
        this.props.remove({ params: { key: key + CUSTOM } });

        this.setState(
            (state) => {

                let { value } = state;

                _.remove(value, {
                    name: notencoded
                });

                _.remove(value, {
                    bundleId: notencoded
                });

                state.value = value;
                return state;

            }, () => this.setJointFilesList(this.state.value));
    }

    removeItem(e) {
        var key = e.currentTarget.getAttribute('removekey');
        var notencoded = e.currentTarget.getAttribute('notencoded');
        this.props.removeFileFromGCloud({
            params: {
                key: key + this.dropName,
                value: ' '
            }
        });

        this.props.remove({ params: { key: key + BOOLEAN } });
        this.props.remove({ params: { key: key + INDEX } });
        this.props.remove({ params: { key: key + REAL_NAME } });
        this.props.remove({ params: { key: key + ORIGINAL_NAME } });
        this.props.remove({ params: { key: key + PJ } });
        this.props.remove({ params: { key: key + AMMEND } });
        this.props.remove({ params: { key: key + CUSTOM } });

        this.setState(
            (state) => {

                let { value } = state;

                _.remove(value, {
                    name: notencoded
                });

                _.remove(value, {
                    bundleId: notencoded
                });

                state.value = value;
                return state;

            }, () => this.setJointFilesList(this.state.value));
    }

    putElementName({
        value,
        name
    }) {

        let obj = {};

        obj[name] = value;

        let found = _.findIndex(this.state.value, (elt) => {
                let keys = _.keysIn(elt);
                for (let i in keys) {
                    if (keys[i] === name) {
                        return elt;
                    }
                }
            }
        );

        if (0 > found) {
            return console.error('found... not found', name);
        }

        let value2 = this.state.value;

        if (!value2[found] || value2[found][name] === undefined) {

            return console.error('name not found in value', found, name, value, value2);
        }
        value2[found][name] = value;

        obj.value = value2;
        obj[name] = value;

        this.setState(obj, () => {
            this.setJointFilesList(this.state.value);
        });
        this.props.put({
            params: {
                key: name,
                value: value
            }
        });
    }

    addCustomPiece({
        value,
        key,
        index
    }) {

        this.setState(state => {

            if (this.savedValue) {
                state.value = this.savedValue;
                delete this.savedValue;
            }

            let id = uuid();

            if (state.value && Array.isArray(state.value)) {

                let obj = {
                    ...value,
                    name: '',
                    id: index.toString(),
                    checked: true,
                    ammend: false,
                    bundle: true,
                    custom: true,
                    bundleId: id,
                    key: key
                };

                obj[this.props.name + '_' + id + REAL_NAME] = '';
                state[this.props.name + '_' + id + REAL_NAME] = '';

                state.value.push(obj);
            } else {
                console.error('value is not an array in dropfile');
            }

            this.props.put({
                params: {
                    key: this.props.name + '_' + id + REAL_NAME,
                    value: ''
                }
            });

            this.props.put({
                params: {
                    key: this.props.name + '_' + id + INDEX,
                    value: (state.value.length - 1).toString()
                }
            });

            this.props.put({
                params: {
                    key: this.props.name + '_' + id + CUSTOM,
                    value: 'true'
                }
            });

            return state;

        }, () => this.setJointFilesList(this.state.value));
    }

    render() {
        let props = this.props;
        let state = this.state;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        return (
            <Col md={props.col || DEFAULT_SIZE}>
                <Row>
                    <Legend
                        key={props.name + '_title_legend'}
                        target={props.name + '_title_legend'}
                    >
                        Pièces
                    </Legend>
                </Row>
                <Row>

                    <Col
                        key={props.name + BUNDLE}
                        style={style}
                        col={12}
                    >
                        <FormGroup>
                            <DropZoneGcloud
                                index={this.state.value.length}
                                lang={this.state.lang}
                                tabindex={props.tabindex}
                                name={props.name}
                                id="files-BUNDLE"
                                maxSize={Infinity}
                                accept={'.pdf, .jpg, .jpeg, .png'}
                                dropMessage={props.dropMessage}
                                handleFile={(e, length) => {
                                    if (!this.fileCounter) {
                                        this.props.toggleLoading();
                                    }
                                    this.fileCounter++;
                                    this.fileArray.push(e);
                                    if (this.fileCounter >= length) {

                                        this.addBundle({
                                            value: _.sortBy(this.fileArray, ['index']),
                                            key: 'files',
                                            index: 0
                                        });
                                        this.fileCounter = 0;
                                        this.fileArray = [];
                                    }

                                }}
                                displayFiles={false}
                                onDropAccepted={(e) => {
                                    if (!this.props.add) {
                                        this.setState({ value: [] });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SortableContainer
                            token={this.props.token}
                            ticket={this.state.ticket}
                            pushWaitingList={
                                this.props.pushWaitingList
                            }
                            pullWaitingList={
                                this.props.pullWaitingList
                            }
                            putElementName={this.putElementName}
                            setJointFilesList={(e) => {
                                this.setState({ value: e }, () => {
                                    this.setJointFilesList(this.state.value);
                                });
                            }}
                            value={state.value}
                            removeItem={this.removeItem}
                            changeLiasse={this.changeLiasse}
                            {...props}
                        />
                    </Col>
                </Row>
            </Col>
        );
    }
}
