import React from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

export function useQuery<T>(): Partial<T> {
    const { search } = useLocation();
    return React.useMemo(() => {
        const params = qs.parse(search, { arrayFormat: 'bracket' });
        return params as T;
    }, [search]);
}
