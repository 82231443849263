import React, { Component } from 'react';
import User from './UserEndos';

import { Button, Col, FormGroup, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Question = <FontAwesomeIcon icon={faQuestionCircle} className="avensRed" />;

const MIS_EN_CAUSE_OPTIONS = [{
    label: 'Non',
    value: 0
}, {
    label: 'Procureure générale du Québec',
    value: 1,
    name: 'PROCUREURE GÉNÉRALE DU QUÉBEC',
    title: 'F',
    street: '1200, route de l\'Église, 2e étage',
    city: 'Québec',
    state: 'Québec',
    postal: 'G1V 4M1',
    district: 'Québec',
    preposition: 0,
    bussiness: 'true'

}, {

    label: 'directeur de l’état civil',
    value: 2,
    name: 'DIRECTEUR DE L\'ÉTAT CIVIL',
    title: 'M',
    street: '2050, rue De Bleury',
    city: 'Montréal',
    state: 'Québec',
    postal: 'H3A 2J5',
    district: 'Montréal',
    preposition: 0,
    bussiness: 'true'

}, {

    label: 'revenu Québec (pension alimentaire)',
    value: 3,
    name: 'REVENU QUÉBEC (PENSION ALIMENTAIRE)',
    title: 'M',
    street: 'Complexe Desjardins, Tour Nord, basilaire 1, 150 Saint-Catherine O',
    city: 'Montréal',
    state: 'Québec',
    postal: 'H2X 3Y2;',
    district: 'Montréal',
    preposition: 0,
    bussiness: 'true'

}];
const LABEL = 'label';
const VALUE = 'value';

export class UsersEndos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: this.props.counter || 0,
            lang: this.props.lang || 'fr',
            users: []
        };
        this.addHeirForm = this.addHeirForm.bind(this);
        this.removeHeirForm = this.removeHeirForm.bind(this);
        this.setSpecialParty = this.setSpecialParty.bind(this);
        this.resetUsers = this.resetUsers.bind(this);
    }

    genLang() {
        // to Implement
    }

    setSpecialParty(value, name) {
        let current = _.find(MIS_EN_CAUSE_OPTIONS, { value: parseInt(value) });

        name.split(this.props.name);
        for (let c in current) {
            if (c === LABEL || c === VALUE) {
                continue;
            }

            this.props.put({
                params: {
                    key: name + '_' + c,
                    value: current[c]
                }
            });

        }

        this.resetUsers();
    }

    resetUsers() {
        let { props } = this;

        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);

        for (let f in fd) {
            if (f.match(reg)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }

        let arr = [];
        for (let h in obj) {
            let key = this.props.name + '_' + h;
            arr.push(<div key={key}>
                <Row>
                    <User
                        put={this.props.put}
                        lang={this.state.lang}
                        trad={this.props.trad}
                        formData={{ ...this.props.formData }}
                        remove={this.removeHeirForm}
                        name={key}
                        counter={h}

                        questionned={this.props.misEnCause || []}
                        target={this.props.title}
                        noRelation={true}
                    >
                    </User>
                </Row>
            </div>);

        }

        this.setState({
            users: arr,
            counter: arr.length,
            force: false
        });
    }

    componentDidUpdate(prevProps) {
        // console.log(this)

        let props = this.props;
        // debugger;

        let bigObject = {};

        if (prevProps.lang !== props.lang) {
            // debugger;
            bigObject.lang = props.lang;
        }

        if (props.formData && JSON.stringify(props.formData) !== JSON.stringify(prevProps.formData) || this.state.force) {
            let fd = this.props.formData;

            let obj = {};
            let reg = new RegExp('^' + this.props.name);

            for (let f in fd) {
                if (f.match(reg)) {
                    let split = f.split(reg);
                    obj[split[1].match(/\d+/)[0]] = true;
                }
            }

            let arr = [];
            for (let h in obj) {
                let key = this.props.name + '_' + h;

                arr.push(<div key={key}>
                    <Row>
                        <User
                            put={this.props.put}
                            lang={this.state.lang}
                            trad={this.props.trad}
                            formData={{ ...this.props.formData }}
                            remove={this.removeHeirForm}
                            name={key}
                            counter={h}
                            target={this.props.title}
                            noRelation={true}
                            questionned={this.props.misEnCause || []}
                        >
                        </User>
                    </Row>
                </div>);
                bigObject.users = arr;
                bigObject.counter = arr.length;
                bigObject.force = false;
            }
            // debugger;

        }

        // this.setState({
        //     users:arr,
        //     counter:arr.length,
        //     force:false
        // });
        if (!_.isEmpty(bigObject)) {
            this.setState(bigObject);
        }

    }

    componentDidMount() {
        // set Default Data in here :)
        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);

        for (let f in fd) {
            if (f.match(reg)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }
        ;let arr = [];

        for (let h in obj) {
            let key = this.props.name + '_' + h;
            arr.push(<>
                <div key={key}>
                    <Row>
                        <User
                            put={this.props.put}
                            lang={this.state.lang}
                            trad={this.props.trad}
                            formData={{ ...this.props.formData }}
                            remove={this.removeHeirForm}
                            name={key}
                            counter={h}
                            target={this.props.title}
                            questionned={this.props.misEnCause || []}
                            noRelation={true}
                        >
                        </User>
                    </Row>
                </div>
            </>);
        }

        this.setState({
            users: arr,
            counter: arr.length + 10
        });

    }

    addHeirForm() {
        this.setState(state => {

            let counter = this.state.counter;
            let key = this.props.name + '_' + counter++;

            state.counter = counter;
            state.users.push(<div key={key}>
                {/* <Row>
                <Col md={this.props.col} key={this.props.name} >
                    <FormGroup >
                        <Label><h5>{this.genLang(this.props.title,this.state.lang)}</h5></Label>
                        <Input
                            // bsSize={'lg'}
                            value={this.state.lol||''}
                            type={'select'}
                            name={this.props.name+'_fdaesfsdf'}
                            onChange={(e) => {
                                e.persist();
                                // console.log('E',e.target.value)
                                // debugger;
                                this.setState({lol:parseInt(e.target.value)},
                                    () => {
                                        // console.log(this);
                                        this.props.put({params:{key:this.props.name,value:e.target.value}})
                                    })
                                // props.onChange?
                                //     props.onChange({key:props.name,value:e.target.value}):
                                //     console.error('Jay, onChange is not there, don\'t even try!')
                                }
                            }
                        >
                            <option>DPJ</option>

                            <option>reve ne QC</option>
                        </Input>
                    </FormGroup>
                </Col>
                </Row> */}

                <Row>
                    <User
                        put={this.props.put}
                        lang={this.state.lang}
                        trad={this.props.trad}
                        formData={{ ...this.props.formData }}
                        remove={this.removeHeirForm}
                        name={key}
                        counter={counter}
                        target={this.props.title}
                        questionned={this.props.misEnCause || []}
                        noRelation={true}
                    >
                    </User>

                </Row>
            </div>);

            let province = key + '_state', country = key + '_country';

            this.props.put({
                params: {
                    key: province,
                    value: 'Québec'
                }
            });
            this.props.put({
                params: {
                    key: country,
                    value: 'Canada'
                }
            });
            //   this.props.put({params:{key:'heirForm_count',value:counter}});
            // this.props.put({params:{key:key+"_child_count",value:0}});

            return state;
        });
    }

    removeHeirForm(id) {
        // this.props.formData['remove_'+id]=true;
        this.props.remove({ params: { key: id } });
        this.setState({
            users: _.reject(this.state.users, { key: id })
        });
    }

    renderInfo(info, id) {
        if (!info) {
            return '';
        }

        return (<>
            <b id={id}>{Question}</b>
            <UncontrolledPopover placement="top" target={id}>
                <PopoverBody>{info}</PopoverBody>
            </UncontrolledPopover>
        </>);
    }

    render() {
        let users = this.state.users;
        return (
            <>
                <FormGroup>
                    {users.map(form => (<div key={form.key}>{form}</div>))}
                </FormGroup>
                <FormGroup className="buttonHolder">
                    <Button
                        className="tw-mt-3"
                        block
                        outline
                        color="primary"
                        onClick={() => this.addHeirForm()}
                    >
                        {this.props.buttonText || 'Ajouter'}
                    </Button>
                </FormGroup>
            </>
        );
    }
}
