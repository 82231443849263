import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '@/lib/utils';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({
    className,
    sideOffset = 4,
    ...props
}, ref) => (
    <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
            ref={ref}
            sideOffset={sideOffset}
            className={cn(
                'tw-z-50 tw-overflow-hidden tw-rounded-md tw-bg-neutral tw-px-3 tw-py-1.5 tw-text-xs',
                'tw-text-neutral-foreground tw-animate-in tw-fade-in-0 tw-zoom-in-95',
                'data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0',
                'data-[state=closed]:tw-zoom-out-95 data-[side=bottom]:tw-slide-in-from-top-2',
                'data-[side=left]:tw-slide-in-from-right-2 data-[side=right]:tw-slide-in-from-left-2',
                'data-[side=top]:tw-slide-in-from-bottom-2',
                className
            )}
            {...props}
        />
    </TooltipPrimitive.Portal>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
