import React, { Component } from 'react';
import { Card, CardBody, CardText, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import Select from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

import { CheckInput } from './CheckInput';
import { Address } from './Address';
import { Select2Creatable } from './Select2Creatable';
import { SelectBootstrap } from './SelectBootstrap';

const TYPE_OPTIONS_FR = [{
    label: 'Demandeur',
    value: 0,
}, {
    label: 'Demanderesse',
    value: 1,
}, {
    label: 'Défendeur',
    value: 2,
}, {
    label: 'Défenderesse',
    value: 3,
}, {
    label: 'Mis en cause',
    value: 4,
}
];

const TYPE_OPTIONS_EN = [{
    label: 'Applicant (M)',
    value: 0,
}, {
    label: 'Applicant (F)',
    value: 1,
}, {
    label: 'Defendant (M)',
    value: 2,
}, {
    label: 'defendant (F)',
    value: 3,
}, {
    label: 'Impleaded party',
    value: 4,
}
];
const PREPOSITION_OPTIONS_FR = [{
    label: '-et-',
    value: 0
}, {
    label: 'c.',
    value: 1,
}];
const PREPOSITION_DEFAULT = 0;

const CLIENT_TYPE_DEFAULT = 0;
const LABEL = 'label';
const VALUE = 'value';
const QUEBEC = 'Québec';
const CANADA = 'Canada';

const MIS_EN_CAUSE_OPTIONS = [
    {
        label: 'Non',
        value: 0,
    }, {
        label: 'Procureure générale du Québec',
        value: 1,
        name: 'PROCUREURE GÉNÉRALE DU QUÉBEC',
        title: 'F',
        street: '1200, route de l\'Église, 2e étage',
        city: 'Québec',
        state: 'Québec',
        postal: 'G1V 4M1',
        district: 'Québec',
        preposition: 0,
        bussiness: 'true'

    }, {

        label: 'directeur de l’état civil',
        value: 2,
        name: 'DIRECTEUR DE L\'ÉTAT CIVIL',
        title: 'M',
        street: '2050, rue De Bleury',
        city: 'Montréal',
        state: 'Québec',
        postal: 'H3A 2J5',
        district: 'Montréal',
        preposition: 0,
        bussiness: 'true'

    }
    , {

        label: 'revenu Québec (pension alimentaire)',
        value: 3,
        name: 'REVENU QUÉBEC',
        title: 'M',
        street: 'Complexe Desjardins, Tour Nord, basilaire 1, 150 Saint-Catherine O',
        city: 'Montréal',
        state: 'Québec',
        postal: 'H2X 3Y2;',
        district: 'Montréal',
        preposition: 0,
        bussiness: 'true'

    }
];
const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;
const fr = 'fr';
const NONE = 'none', BLOCK = 'block';
const lang = fr;
const options = [
    {
        value: '',
        label: '-'
    },
    {
        value: 'fils',
        label: 'fils/fille'
    },
    {
        value: 'conjoint',
        label: 'Conjoint'
    },
    {
        value: 'conjoint-de-fait',
        label: 'Conjoint.e de fait'
    },
    {
        value: 'petit-fils',
        label: 'petit-fils/ petite-fille'
    },
    {
        value: 'frère',
        label: 'frère/soeur'
    },
    {
        value: 'père',
        label: 'père/mère'
    },
    {
        value: 'neveu',
        label: 'neveu/nièce'
    },
    {
        value: 'petit-neveu',
        label: 'petit-neveu/petite-nièce'
    },
    {
        value: 'oncle',
        label: 'oncle/tante'
    },
    {
        value: 'cousin',
        label: 'cousin/cousine'
    },
    {
        value: 'cousin germain',
        label: 'cousin/cousine germain.e'
    },
    {
        value: 'cousin issu de germain',
        label: 'cousin issu de germain'
    },
    {
        value: 'grand-père',
        label: 'grand-père/grand-mère'
    },
    {
        value: 'beau-frère',
        label: 'belle soeur/beau frère'
    },
    {
        value: 'beau-père',
        label: 'beau-père/belle-mère'
    },
    {
        value: 'beau-fils',
        label: 'beau-fils/belle-fille'
    },
    {
        value: 'autre',
        label: 'Autre'
    }
];

const optionsEN = [
    {
        value: '',
        label: '-'
    },
    {
        value: 'fils',
        label: 'Son/daughter'
    },
    {
        value: 'conjoint',
        label: 'Spouse'
    },
    {
        value: 'petit-fils',
        label: 'Grandson/granddaughter'
    },
    {
        value: 'frère',
        label: 'Brother/sister'
    },
    {
        value: 'père',
        label: 'Father/mother'
    },
    {
        value: 'neveu',
        label: 'Nephew/niece'
    },
    {
        value: 'petit-neveu',
        label: 'Little nephew/ little niece'
    },
    {
        value: 'oncle',
        label: 'Uncle/Aunt'
    },
    {
        value: 'cousin',
        label: 'Cousin'
    },
    {
        value: 'cousin germain',
        label: 'First cousin'
    },
    {
        value: 'cousin issu de germain',
        label: 'Second cousin'
    },
    {
        value: 'grand-père',
        label: 'Grandfather/Grandmother'
    },
    {
        value: 'beau-frère',
        label: 'Brother-in-law/sister-in-law'
    },
    {
        value: 'beau-père',
        label: 'Stepfather/Stepmother'
    },
    {
        value: 'beau-fils',
        label: 'Stepson/Stepdaughter'
    },
    {
        value: 'autre',
        label: 'Other'
    }
];

export default class WillUserLess extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '';
        this.state = {
            lang: this.props.lang || 'fr',
            name: fd[this.props.name + '_name'] || '',
            firstname: fd[this.props.name + '_firstname'] || '',
            gender: this.props.formData[this.props.name + '_gender'],
            relation: this.props.formData[this.props.name + '_relation'],
            questionned: parseInt(props.formData[props.name + '_specialParty'] || 0),
            version: 0,
            district: this.props.formData[this.props.name + '_district'],
            juridicalPerson: this.props.formData[this.props.name + '_juridicalPerson_boolean'] || false,
            juridicalPersonName: this.props.formData[this.props.name + '_juridicalPerson_name'] || false,

            job: this.props.formData[this.props.name + '_job'],
            employer: this.props.formData[this.props.name + '_employer'],
            revenue: this.props.formData[this.props.name + '_revenue'],

        };

        this.genLang = this.genLang.bind(this);
        this.setSpecialParty = this.setSpecialParty.bind(this);
        this.resetUsers = this.resetUsers.bind(this);

    }

    genLang(key = 'missing') {

        if (!this.props.trad) {
            return key;
        }

        if (!this.props.trad[key]) {
            return this.props.trad['wrongKey'][this.state.lang] + ' ' + key;
        }
        return this.props.trad[key][this.state.lang];
    }

    componentDidUpdate(prevProps) {
        if (prevProps && prevProps.formData && this.props && this.props.formData) {

            let props = this.props, obj = {};

            if (prevProps.lang !== this.props.lang) {
                // debugger;
                obj.lang = this.props.lang;
            }

            if (prevProps.trad !== this.props.trad) {
                obj.trad = this.props.trad;
            }

            if (props.formData[props.name + '_name'] !== prevProps.formData[props.name + '_name']) {
                obj['name'] = props.formData[props.name + '_name'] || '';
            }

            if (props.formData[props.name + '_firstname'] !== prevProps.formData[props.name + '_firstname']) {
                obj['firstname'] = props.formData[props.name + '_firstname'] || '';
            }

            if (props.formData[props.name + '_assistant'] !== prevProps.formData[props.name + '_assistant']) {
                obj['assistant'] = props.formData[props.name + '_assistant'] || '';
            }

            if (props.formData[props.name + '_gender'] !== prevProps.formData[props.name + '_gender']) {
                obj['gender'] = props.formData[props.name + '_gender'] || '';
            }

            if (props.formData[props.name + '_relation'] !== prevProps.formData[props.name + '_relation']) {
                obj['relation'] = props.formData[props.name + '_relation'] || '';
            }

            if (props.formData[props.name + '_specialParty'] !== prevProps.formData[props.name + '_specialParty']) {
                obj['questionned'] = parseInt(props.formData[props.name + '_specialParty'] || 0);
            }

            if (props.formData[props.name + '_district'] !== prevProps.formData[props.name + '_district']) {
                obj['district'] = props.formData[props.name + '_district'];
            }

            if (props.formData[props.name + '_province'] !== prevProps.formData[props.name + '_province']) {
                obj['province'] = props.formData[props.name + '_province'] || QUEBEC;
            }

            if (props.formData[props.name + '_country'] !== prevProps.formData[props.name + '_country']) {
                obj['country'] = props.formData[props.name + '_country'] || CANADA;
            }

            if (props.formData[props.name + '_job'] !== prevProps.formData[props.name + '_job']) {
                obj['job'] = props.formData[props.name + '_job'];
            }

            if (props.formData[props.name + '_employer'] !== prevProps.formData[props.name + '_employer']) {
                obj['employer'] = props.formData[props.name + '_employer'];
            }

            if (props.formData[props.name + '_revenue'] !== prevProps.formData[props.name + '_revenue']) {
                obj['revenue'] = props.formData[props.name + '_revenue'];
            }

            if (props.formData[props.name + '_juridicalPerson_boolean'] !== prevProps.formData[props.name + '_juridicalPerson_boolean']) {
                // debugger;
                obj['juridicalPerson'] = props.formData[props.name + '_juridicalPerson_boolean'];
            }

            if (props.formData[props.name + '_juridicalPerson_name'] !== prevProps.formData[props.name + '_juridicalPerson_name']) {
                // debugger;
                obj['juridicalPersonName'] = props.formData[props.name + '_juridicalPerson_name'];
            }

            if (!_.isEmpty(obj)) {
                this.setState(obj);
            }
        }

    }

    resetUsers() {
        let { props } = this;

        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);

        for (let f in fd) {
            if (f.match(reg)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }

        let arr = [];
        for (let h in obj) {
            let key = this.props.name + '_' + h;
            arr.push(<></>
                // <div key={key}>
                //     <Row >
                //         <User
                //             put={this.props.put}
                //             lang={this.state.lang}
                //             trad={this.props.trad}
                //             formData={{...this.props.formData}}
                //             remove={this.removeHeirForm}
                //             name={key}
                //             counter={h}
                //             target={this.props.title}
                //             noRelation={true}
                //         >
                //             <Address
                //                 col={12}
                //                 put={this.props.put}
                //                 lang={this.state.lang}
                //                 trad={this.props.trad}
                //                 formData={{...this.props.formData}}
                //                 remove={this.removeHeirForm}
                //                 name={key}
                //                 counter={h}
                //                 target={this.props.title}
                //             />
                //             <Select2Creatable
                //                 wongalala={true}
                //                 default={1}
                //                 options={PREPOSITION_OPTIONS_FR}
                //                 zIndex={999999999}
                //                 lang={this.state.lang}
                //                 version={this.state.version}
                //                 name={key+'_preposition'}
                //                 put={this.props.put}
                //                 title={'Préposition'}
                //                 // onChange={this.onChange}
                //                 formData={{...this.state.data}}
                //             />
                //             <Select2Creatable
                //                 wongalala={true}
                //                 default={1}
                //                 options={TYPE_OPTIONS_FR}
                //                 lang={this.state.lang}
                //                 version={this.state.version}
                //                 name={key+'_clientType'}
                //                 put={this.props.put}
                //                 title={'type de client'}
                //                 // onChange={this.onChange}
                //                 formData={{...this.state.data}}
                //             />
                //         </User>
                //     </Row >
                // </div>
            );

        }

        this.setState({
            users: arr,
            counter: arr.length,
            force: false,
            name: '',
            firstname: '',
            assistant: '',
            gender: '',
            relation: '',
            questionned: '',
            juridicalPerson: 'false',
            juridicalPersonName: ''
        });
    }

    setSpecialParty(value, name) {
        let current = _.find(MIS_EN_CAUSE_OPTIONS, { value: parseInt(value) });

        name.split(this.props.name);
        name = name.replace(/_specialParty/, '');
        for (let c in current) {
            if (c === LABEL || c === VALUE) {
                continue;
            }

            this.props.put({
                params: {
                    key: name + '_' + c,
                    value: current[c]
                }
            });

        }

        this.props.put({
            params: {
                key: name + '_preposition',
                value: 0
            }
        });
        this.props.put({
            params: {
                key: name + '_firstname',
                value: ''
            }
        });
        this.props.put({
            params: {
                key: name + '_lastname',
                value: ''
            }
        });
        this.props.put({
            params: {
                key: name + '_clientType',
                value: 'WOWOWOWOWOOWOWOOWOW'
            }
        });
        this.props.put({
            params: {
                key: name + '_neoClient',
                value: 'false'
            }
        });
        this.props.put({
            params: {
                key: name + '_bussiness',
                value: 'true'
            }
        });

        this.setState({
            questionned: parseInt(value),
            force: false,
            name: '',
            firstname: '',
            assistant: '',
            gender: '',
            relation: '',
            misencause: 4,
            juridicalPerson: 'false',
            juridicalPersonName: '',
            version: this.state.version + 1
        });
    }

    render() {

        return (
            <Col
                md={this.props.col || 12}>
                <Row>
                    <SelectBootstrap
                        lang={this.state.lang}
                        options={this.props.questionned}
                        title={'Mis-en-cause'}
                        name={this.props.name + '_specialParty'}
                        onChange={(e, f) => this.setSpecialParty(e, f)}
                        formData={this.props.formData}
                        put={this.props.put}
                    />
                </Row>
                <Row>
                    <Col md={12}>
                        <Card
                            style={{
                                display: this.state.questionned ? NONE : BLOCK,
                                marginBottom: '5px'
                            }}
                        >

                            {this.props.remove ?
                                <span className="remove" onClick={() => this.props.remove(this.props.name)}>
                                {cross}
                            </span> : ''}
                            <CardBody
                            >
                                <CardText tag="div">
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup tag="fieldset" className="!tw-mb-0">
                                                <legend><span>{this.props.target}</span></legend>
                                                {/* <legend><span>{this.props.target+ ' ' +this.props.counter}</span></legend> */}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <CheckInput
                                            col={12}
                                            callback={(e) => {
                                                this.setState({
                                                    juridicalPerson: e.target.checked.toString()
                                                });
                                                // if(!e.target.checked){
                                                //     this.props.remove({
                                                //         params:{
                                                //             key:name+'_uploadDocument',
                                                //         }
                                                //     })
                                                // }
                                            }}
                                            name={this.props.name + '_juridicalPerson_boolean'}
                                            // name={props.name+'_'+name}
                                            title={'Personne morale'}
                                            // checked={e.checked||false}
                                            formData={this.props.formData}
                                            put={this.props.put}
                                            // style={{
                                            //     whiteSpace: 'nowrap',
                                            //     overflow: 'hidden',
                                            //     textOverflow: 'ellipsis'
                                            // }}
                                        />
                                    </Row>
                                    <div style={{ display: this.state.juridicalPerson === 'true' ? NONE : BLOCK }}>

                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label>{this.genLang('_name')}</Label>
                                                    <Input
                                                        type="text"
                                                        name={this.props.name + '_name'}
                                                        onChange={(e) => this.setState({ name: e.target.value })}
                                                        value={this.state.name}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label>{this.genLang('_firstname')}</Label>
                                                    <Input
                                                        type="text"
                                                        name={this.props.name + '_firstname'}
                                                        onChange={(e) => this.setState({ firstname: e.target.value })}
                                                        value={this.state.firstname}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2} style={{ display: !this.props.noRelation ? BLOCK : NONE }}>
                                                <FormGroup>
                                                    <Label>{lang === fr ? 'Relation' : 'Relationship'}</Label>
                                                    <Select
                                                        style={{ zIndex: 1000 }}
                                                        id={this.props.name + '_relation'}
                                                        name={this.props.name + '_relation'}
                                                        defaultValue={_.find(options, (e) => {
                                                            return e.value === this.state.relation;
                                                        })}
                                                        onChange={(e) => {
                                                            this.setState({ relation: e.value });
                                                            this.props.put({
                                                                params: {
                                                                    key: this.props.name + '_relation',
                                                                    value: e.value
                                                                }
                                                            });
                                                        }}
                                                        options={lang === fr ? options : optionsEN}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>{this.genLang('_title')}</Label>
                                                    <Input
                                                        type="select"
                                                        name={this.props.name + '_gender'}
                                                        onChange={(e) => this.setState({ gender: e.target.value })}
                                                        value={this.state.gender || '-'}>
                                                        <option value="-">Autre</option>
                                                        <option value="M">{this.genLang('_sir')}</option>
                                                        <option value="F">{this.genLang('_madam')}</option>
                                                        {/* <option value="NA" >Autre</option> */}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{ display: this.state.juridicalPerson === 'true' ? BLOCK : NONE }}>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label>{'Nom de la personne morale'}</Label><br></br>
                                                    <label style={{ color: 'red' }}>Veuillez mettre une virgule (,) là
                                                        où les majuscules et le gras terminent</label>
                                                    <Input
                                                        type="text"
                                                        name={this.props.name + '_juridicalPerson_name'}
                                                        onChange={(e) => this.setState({ juridicalPersonName: e.target.value })}
                                                        value={this.state.juridicalPersonName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Address
                                        col={12}
                                        put={this.props.put}
                                        lang={this.state.lang}
                                        trad={this.props.trad}
                                        formData={{ ...this.props.formData }}
                                        remove={this.removeHeirForm}
                                        name={this.props.name}
                                        version={this.state.version}
                                        target={this.props.title}
                                        country={true}
                                    />
                                    <Row>

                                        <Select2Creatable
                                            col={6}
                                            wongalala={true}
                                            default={PREPOSITION_DEFAULT}
                                            options={PREPOSITION_OPTIONS_FR}
                                            zIndex={999999999}
                                            lang={this.state.lang}
                                            version={this.state.version}
                                            name={this.props.name + '_preposition'}
                                            put={this.props.put}
                                            title={'Préposition'}
                                            // onChange={this.onChange}
                                            formData={{ ...this.props.formData }}
                                        />
                                        <Select2Creatable
                                            col={6}
                                            wongalala={true}
                                            default={this.state.misencause || CLIENT_TYPE_DEFAULT}
                                            options={this.state.lang === 'en' ? TYPE_OPTIONS_EN : TYPE_OPTIONS_FR}
                                            lang={this.state.lang}
                                            version={this.state.version}
                                            name={this.props.name + '_clientType'}
                                            put={this.props.put}
                                            title={'Type de client'}
                                            // onChange={this.onChange}
                                            formData={{ ...this.props.formData }}
                                        />

                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>{this.genLang('_district')}</Label>
                                                <Input
                                                    type="text"
                                                    name={this.props.name + '_district'}
                                                    onChange={(e) => this.setState({ district: e.target.value })}
                                                    value={this.state.district}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <CheckInput

                                                    callback={(e) => {
                                                        // if(!e.target.checked){
                                                        //     this.props.remove({
                                                        //         params:{
                                                        //             key:name+'_uploadDocument',
                                                        //         }
                                                        //     })
                                                        // }
                                                    }}
                                                    name={this.props.name + '_bussiness'}
                                                    title={'Place d\'affaires'}
                                                    formData={this.props.formData}
                                                    put={this.props.put}
                                                    style={{
                                                        paddingTop: '38px'
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <CheckInput
                                            col={4}
                                            callback={(e) => {
                                                // if(!e.target.checked){
                                                //     this.props.remove({
                                                //         params:{
                                                //             key:name+'_uploadDocument',
                                                //         }
                                                //     })
                                                // }
                                            }}
                                            name={this.props.name + '_neoClient'}
                                            title={'Notre client'}
                                            formData={this.props.formData}
                                            put={this.props.put}
                                            style={{
                                                paddingTop: '38px'
                                            }}
                                        />
                                    </Row>

                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>{this.genLang('_job')}</Label>
                                                <Input
                                                    type="text"
                                                    name={this.props.name + '_job'}
                                                    onChange={(e) => this.setState({ job: e.target.value })}
                                                    value={this.state.job}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>{this.genLang('_employer')}</Label>
                                                <Input
                                                    type="text"
                                                    name={this.props.name + '_employer'}
                                                    onChange={(e) => this.setState({ employer: e.target.value })}
                                                    value={this.state.employer}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>{this.genLang('_revenue')}</Label>
                                                <Input
                                                    type="text"
                                                    name={this.props.name + '_revenue'}
                                                    onChange={(e) => this.setState({ revenue: e.target.value })}
                                                    value={this.state.revenue}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
        );
    }
}
