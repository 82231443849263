import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from '@/composables/translation';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Input, type InputProps } from './ui/input';
import { Pencil2Icon } from '@radix-ui/react-icons';

interface Props extends Omit<InputProps, 'value' | 'onChange'> {
    value: string;
    disabled?: boolean;
    editRight?: boolean;
    formatter?: (value: string) => React.ReactNode;
    onChange?: (value: string) => Promise<void> | void;
}

export function TextFieldEdit({ disabled, editRight, formatter, onChange, ...props }: Props) {
    const { ct } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState('');
    const button = useRef<HTMLButtonElement>(null);
    const input = useRef<HTMLInputElement>(null);
    const buttonTemplate = (
        <Tooltip>
            <TooltipTrigger asChild>
                <Button
                    type="button" variant="ghost" size="icon"
                    className={cn(
                        '!tw-rounded-full tw-text-primary',
                        disabled && '',
                        !editRight && '!tw-mr-1',
                        editRight && '!tw-ml-1'
                    )}
                    onClick={(e) => {
                        e.stopPropagation();
                        setValue(props.value);
                        setIsEditing(true);
                        setTimeout(() => {
                            input.current?.select();
                        }, 0);
                    }}
                >
                    <Pencil2Icon className="tw-size-4" />
                </Button>
            </TooltipTrigger>
            <TooltipContent>
                {ct('edit')}
            </TooltipContent>
        </Tooltip>
    );
    return (
        <div className="tw-flex tw-items-center">
            <Input
                ref={input}
                className={cn(!isEditing && '!tw-hidden')}
                value={value}
                appendPadless
                appendIcon={
                    <Button
                        ref={button}
                        type="button" variant="ghost" size="icon"
                        className="tw-rounded-l-none tw-text-success"
                        loading={isLoading}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsLoading(true);
                            const res = onChange?.(value);
                            if (res instanceof Promise) {
                                res.finally(() => {
                                    setIsLoading(false);
                                    setIsEditing(false);
                                });
                            } else {
                                setIsLoading(false);
                                setIsEditing(false);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                }
                onChange={e => setValue(e.target.value)}
                onClick={e => e.stopPropagation()}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        button.current?.click();
                    } else if (e.key === 'Escape') {
                        e.preventDefault();
                        setIsEditing(false);
                    }
                }}
            />
            {!isEditing && <>
                {!editRight && buttonTemplate}
                <span>
                    {formatter
                        ? formatter(props.value)
                        : props.value
                    }
                </span>
                {editRight && buttonTemplate}
            </>}
        </div>
    );
}
