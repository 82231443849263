import React, { useContext, useEffect, useMemo } from 'react';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Combobox } from '@/components/ui/combobox';
import { useTranslation } from '@/composables/translation';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { FolderContext } from '@/pages/psj/Folder';
import { getFieldError, useValidation } from '@/composables/validation';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { Button } from '@/components/ui/button';
import { RequiredMarker } from '@/components/ui/required-marker';
import { useControllableState } from '@/composables/controllable';

interface Props {
    children?: React.ReactNode;
    onSubmit?: (values: FormValues) => (Promise<void> | void);
    value?: FormValues;
    open?: boolean;
    onOpenChange?: React.Dispatch<boolean>;
}

interface FormValues {
    to: string[] | null;
    cc: string[] | null;
    subject: string;
}

export function NoteEmailDialog(props: Props) {
    const { ct, t } = useTranslation('psj.notes');
    const folder = useContext(FolderContext);
    const form = useForm<FormValues>({
        defaultValues: {
            subject: '',
            to: [],
            cc: []
        }
    });
    const { required } = useValidation();
    const [open, setOpen] = useControllableState(false, props.open, props.onOpenChange as any);
    const emailOptions = useMemo(
        () => folder?.parties.map(p => p.email) ?? [],
        [folder?.parties]
    );
    useEffect(() => {
        form.reset(props.value);
    }, [props.value, open]);

    async function onSubmit(values: FormValues) {
        await props.onSubmit?.(values);
        setOpen(false);
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            {props.children}
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>{t('email-dialog.title')}</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="tw-flex tw-flex-col tw-gap-3 tw-pb-6">
                            <FormField
                                name="subject"
                                rules={{ validate: { required } }}
                                render={({ field }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('email-dialog.fields.subject')}
                                            <RequiredMarker />
                                        </FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                            <FormField
                                name="to"
                                rules={{ validate: { required } }}
                                render={({ field: { ref, ...field }, formState }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('email-dialog.fields.recipients')}
                                            <RequiredMarker />
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox
                                                options={emailOptions}
                                                multiple
                                                creatable
                                                clearable
                                                error={!!getFieldError(field.name, formState)}
                                                {...field}
                                                innerRef={ref}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                            <FormField
                                name="cc"
                                render={({ field: { ref, ...field } }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('email-dialog.fields.cc')}
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox
                                                id={field.name}
                                                options={emailOptions}
                                                multiple
                                                creatable
                                                clearable
                                                {...field}
                                                innerRef={ref}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary">
                                    {ct('cancel')}
                                </Button>
                            </DialogClose>
                            <ButtonSubmit>
                                {ct('save')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
