import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface State {
    default: boolean;
    logo?: string;
    templates: {
        '2FA'?: {
            html?: string;
        };
        thks?: {
            html?: string;
            btnRedirect?: {
                text?: string;
                url?: string;
            };
        };
        assistance_footer?: {
            html?: string;
        };
    };
}

interface Actions {
    setSettings: (settings: Partial<State>) => void;
    reset: () => void;
}

export const useSettingsStore = create<State & Actions>()(
    devtools(
        persist(
            (set) => ({
                default: true,
                logo: '',
                templates: {},
                setSettings: (settings) => set({
                    ...settings
                }),
                reset: () => set({
                    default: true,
                    logo: '',
                    templates: {}
                })
            }),
            {
                name: 'settings'
            }
        )
    )
);
