import React, { Component } from 'react';
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    Collapse
} from 'reactstrap';

import _ from 'lodash';

export class DashboardElement extends Component {

    constructor(props) {
        super(props);
        let state = {};
        state = {
            active: props.defaultActive
        };

        this.state = state;
        this.handleNav = this.handleNav.bind(this);
    }

    handleNav(active) {
        this.setState({ 'active': active });
    }

    render() {
        let props = this.props;
        let nav = '';
        if (props.nav && props.nav.length) {
            nav = [];

            nav = <Nav pills>{
                [<NavItem

                    key={'test'}
                ><NavLink
                    className="m-l-5 p-t-5 p-b-5"
                    active={this.state.active === false}
                    color={'primary'}
                    onClick={
                        () => {
                            this.setState({
                                active: false
                            });
                        }
                    }
                    tag={'span'}
                >
                    {props.title}</NavLink>
                </NavItem>,
                    ..._.map(this.props.nav, (e) => {
                        return <NavItem key={'nav-' + e.target}>
                            <NavLink
                                className={'m-l-5 p-t-5 p-b-5'}
                                active={e.target === this.state.active}
                                tag={'span'}
                                color={'primary'}
                                data-target={e.target}
                                onClick={(f) => {
                                    this.handleNav(f.target.getAttribute('data-target'));
                                }}
                            >
                                {e.name}
                            </NavLink>
                        </NavItem>;
                    })
                ]}</Nav>;
        }

        let collapse;
        if (props.collapse && props.collapse.length) {
            collapse = [];
            collapse = _.map(this.props.collapse, (g) => {
                return <Collapse key={'collapse-' + g.target}
                                 isOpen={g.target === this.state.active}>{g.component}</Collapse>;
            });
        }

        let header = nav || this.props.title || 'titre manquant';

        return (
            <Col md={this.props.col || 6} style={{
                overflowX: 'hidden',
                overflowY: 'hidden',
                textOverflow: 'elipsis',
                ...(this.props.innerStyle || {})
            }}
                 className=" m-t-10 m-r-0 p-0 dashBoardElement"
            >

                <Card className={(this.props.className) ? 'neo-card ' + this.props.className : 'neo-card'}>
                    <CardHeader tag="h3" id={this.props.toggler} className={'cardheader padding-10'}>
                        {header}
                    </CardHeader>
                    {this.state.active !== false ?
                        <CardBody
                            className={'cardbody ' + this.props.classNameBody}
                            style={{
                                overflowY: 'scroll',
                                height: this.props.height || 'initial',
                                maxHeight: this.props.maxHeight || 'initial',
                            }}
                        >
                            {collapse ? collapse : this.props.children}
                        </CardBody> : <></>}
                </Card>
            </Col>);
    }
}
