import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { combine, useTranslation } from '@/composables/translation';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Cross1Icon, MagnifyingGlassIcon, OpenInNewWindowIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { CaseStatusCell, DateCell, FolderIdCellOptions, LinkCell } from '@/components/ui/cells';
import type { Party } from '@/types/api/party';
import { type ColumnDef } from '@tanstack/react-table';
import { DataTable } from '@/components/ui/data-table';
import { useDebounced } from '@/composables/debounce';
import { getFolders, getOrgParties } from '@/composables/api';
import { useError } from '@/composables/error';
import axios, { type AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';
import { type PaginatedApiResponse } from '@/types/api/response';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';

const SEARCH_TYPES = {
    folders: {
        baseURL: '/dashboard',
        label: combine('common.folders')
    },
    clients: {
        baseURL: '/clients',
        label: combine('common.clients')
    }
};

type SearchType = keyof typeof SEARCH_TYPES;

interface SearchOptions {
    type: SearchType;
    exact?: boolean;
}

export function SearchModal() {
    const { ct, t: t_dashboard, to } = useTranslation('dashboard');
    const { t: t_client } = useTranslation('settings.clients');
    const { handleNetworkError } = useError();
    const [search, setSearch] = useState('');
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState<SearchType>('folders');
    const [exact, setExact] = useState(false);

    const searchTypeOptions = SEARCH_TYPES[type];
    const searchFn = useCallback(_handleSearch, [setData]);
    const handleSearch = useDebounced({ func: searchFn, timeout: 1000 });

    const searchUrl = useMemo(() => axios.getUri({
        baseURL: searchTypeOptions.baseURL,
        params: {
            ...searchParams(search, { type, exact }),
            tab: `${to(searchTypeOptions.label)}: "${search}"`
        }
    }), [type, search]);

    const FolderIdCell = useMemo(() => LinkCell({
        ...FolderIdCellOptions,
        onClick: () => setOpen(false)
    }), [setOpen]);

    const PartyNameCell = useMemo(() => LinkCell({
        link: ({ row }) => axios.getUri({
            baseURL: '/dashboard',
            params: {
                party_id: row.original._id,
                my_folders: false,
                tab: `${ct('folders')} — ${row.original.fullname}`
            }
        }),
        onClick: () => setOpen(false)
    }), [setOpen]);

    useEffect(() => {
        if (!search) {
            setData([]);
        } else {
            handleSearch(search, { type, exact });
        }
    }, [search]);

    useEffect(() => {
        if (search) {
            searchFn(search, { type, exact });
        }
    }, [type, exact]);

    const columns: Array<ColumnDef<any>> = useMemo(() => ({
        folders: [
            {
                id: 'folder_id',
                accessorKey: 'folder_id',
                header: t_dashboard('table.id'),
                cell: FolderIdCell
            },
            {
                id: 'name',
                header: t_dashboard('table.name'),
                accessorKey: 'name'
            },
            {
                id: 'status',
                header: t_dashboard('table.status'),
                accessorKey: 'status',
                cell: CaseStatusCell
            },
            {
                id: 'main_client',
                header: t_dashboard('table.client'),
                accessorKey: 'main_client',
                cell: ({ cell }) => cell.getValue<Party | undefined>()?.fullname
            },
            {
                id: 'updated_at',
                header: t_dashboard('table.updated_at'),
                accessorKey: 'updated_at',
                cell: DateCell
            },
            {
                id: 'created_at',
                header: t_dashboard('table.created_at'),
                accessorKey: 'created_at',
                cell: DateCell
            }
        ],
        clients: [
            {
                id: 'fullname',
                header: t_client('table.full-name'),
                accessorKey: 'fullname',
                cell: PartyNameCell
            },
            {
                id: 'company',
                header: t_client('table.company'),
                accessorKey: 'company'
            },
            {
                id: 'phone',
                header: t_client('table.phone'),
                accessorKey: 'phone'
            },
            {
                id: 'email',
                header: t_client('table.email'),
                accessorKey: 'email'
            }
        ]
    } as Record<SearchType, Array<ColumnDef<any>>>)[type], [type]);

    function searchParams(search: string, opts: SearchOptions): any {
        return {
            search_index: true,
            search_type: opts.exact ? 'phrase' : 'text',
            search_value: search
        };
    }

    function initiateSearch(
        search: string,
        opts: SearchOptions
    ): Promise<AxiosResponse<PaginatedApiResponse<any>>> {
        const params = searchParams(search, opts);
        switch (opts.type) {
        case 'folders':
            return getFolders(params);
        case 'clients':
            return getOrgParties(params);
        }
    }

    function _handleSearch(search: string, opts: SearchOptions) {
        setLoading(true);
        initiateSearch(search, opts)
            .then((res) => setData(res.data.data))
            .catch(handleNetworkError)
            .finally(() => setLoading(false));
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button
                    variant="ghost" size="icon"
                    className={cn(
                        '!tw-size-12 tw-text-xl !tw-text-white',
                        '!tw-rounded-full hover:!tw-bg-black/15'
                    )}
                >
                    <FontAwesomeIcon icon={faSearch}/>
                </Button>
            </DialogTrigger>
            <DialogContent className="tw-flex tw-flex-col md:tw-max-w-5xl">
                <DialogHeader>
                    <DialogTitle>
                        {to({
                            en: 'Search',
                            fr: 'Recherche'
                        })}
                    </DialogTitle>
                </DialogHeader>
                <div className="tw-flex tw-gap-2">
                    <Select
                        value={type}
                        onValueChange={(value) => setType(value as SearchType)}
                    >
                        <SelectTrigger className="!tw-max-w-[120px]">
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {Object.entries(SEARCH_TYPES).map(([k, opts]) => (
                                    <SelectItem key={k} value={k}>
                                        {to(opts.label)}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                    <Input
                        className="tw-flex-1"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        placeholder={to({
                            en: 'Type to search...',
                            fr: 'Tapez pour rechercher...'
                        })}
                        prependIcon={<MagnifyingGlassIcon/>}
                        prependBorder={false}
                        appendIcon={
                            <Cross1Icon
                                className={cn(
                                    'tw-cursor-pointer tw-text-muted-foreground hover:tw-text-foreground',
                                    !search && 'tw-hidden'
                                )}
                                onClick={() => setSearch('')}
                            />
                        }
                        appendBorder={false}
                    />
                    <Button asChild>
                        <Link
                            to={searchUrl}
                            className={cn(!search && 'tw-pointer-events-none tw-opacity-50')}
                            onClick={() => setOpen(false)}
                        >
                            <OpenInNewWindowIcon className="tw-mr-2" />
                            Ouvrir comme vue
                        </Link>
                    </Button>
                </div>
                <div className="tw-ml-auto tw-flex tw-items-center">
                    <Checkbox
                        id="exact-search"
                        checked={exact}
                        onCheckedChange={(value) => setExact(Boolean(value))}
                    />
                    <Label className="tw-font-semibold tw-pl-2" htmlFor="exact-search">
                        {to({
                            en: 'Search entire exact phrase',
                            fr: 'Rechercher la phrase entière exacte'
                        })}
                    </Label>
                </div>
                <div className="tw-overflow-x-auto">
                    <DataTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        enableGlobalFilter={false}
                        enableColumnFilters={false}
                        enableHiding={false}
                        numSkeletonRows={3}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
}
