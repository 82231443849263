import React, { type HTMLAttributeAnchorTarget } from 'react';
import { type CellContext } from '@tanstack/table-core/src/core/cell';
import { Checkbox } from '@/components/ui/checkbox';
import { type ColumnDef } from '@tanstack/react-table';
import { Badge, type BadgeProps } from '@/components/ui/badge';
import _ from 'lodash';
import { type Language, type TranslationObject, useTranslation } from '@/composables/translation';
import { cn } from '@/lib/utils';
import { type CaseStatus } from '@/types/folder';
import { StatusChip } from '@/components/psj/StatusChip';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Switch } from '@/components/ui/switch';

interface BadgeListCellOptions<TData, TValue> {
    getter: (props: CellContext<TData, unknown>) => TValue[];
    className?: string;
    badgeProps?: BadgeProps;
    getOptionLabel?: (opt: TValue) => string;
    getOptionValue?: (opt: TValue) => string;
    truncateCount?: number;
    slots?: {
        empty?: React.ReactNode;
    };
}

interface TranslateCellOptions {
    to: (value?: Partial<TranslationObject> | string, fallback?: string, language?: Language) => string;
}

interface LinkCellOptions<TData> {
    link: (context: CellContext<TData, unknown>) => string | undefined;
    content?: (context: CellContext<TData, unknown>) => React.ReactNode | undefined;
    target?: HTMLAttributeAnchorTarget;
    onClick?: () => void;
}

export function TranslateCell<TData>({ to }: TranslateCellOptions): ColumnDef<TData>['cell'] {
    return ({ cell }) => to(cell.getValue<TranslationObject>());
}

export function BadgeListCell<TData, TValue>(opts: BadgeListCellOptions<TData, TValue>): ColumnDef<TData>['cell'] {
    const count = opts.truncateCount ?? 2;
    // eslint-disable-next-line react/display-name
    return (context) => {
        const { ct } = useTranslation();
        const list = opts.getter(context);
        const listStart = list.length > (count + 1) ? list.slice(0, count) : list;
        return (
            <div className={cn('tw-flex tw-gap-2 tw-flex-wrap', opts.className)}>
                {listStart.map((opt) => (
                    <Badge
                        key={opts.getOptionValue ? opts.getOptionValue(opt) : _.toString(opt)}
                        {...opts.badgeProps}
                    >
                        {opts.getOptionLabel ? opts.getOptionLabel(opt) : _.toString(opt)}
                    </Badge>
                ))}
                {list.length > (count + 1) &&
                    <Badge {...opts.badgeProps} className={cn('tw-lowercase', opts.badgeProps?.className)}>
                        {`+${list.length - count} ${ct('others')}`}
                    </Badge>
                }
                {list.length <= 0 && opts.slots?.empty}
            </div>
        );
    };
}

export function LinkCell<TData = any>(opts: LinkCellOptions<TData>): ColumnDef<TData>['cell'] {
    // eslint-disable-next-line react/display-name
    return (context) => {
        const link = opts.link(context);
        const content = opts.content?.(context) ?? context.cell.getValue<any>();
        return (
            <Button variant="link" asChild={!!link}>
                {link
                    ? <Link to={link} target={opts.target} onClick={opts.onClick}>
                        {content}
                    </Link>
                    : content}
            </Button>
        );
    };
}

export const CheckboxCell: ColumnDef<any>['cell'] = ({ cell }) => {
    const checked = cell.getValue<boolean>();
    return (
        <div className="tw-px-2">
            <Checkbox
                className="!tw-cursor-not-allowed"
                checked={checked ?? false}
            />
        </div>
    );
};

export const SwitchCell: ColumnDef<any>['cell'] = ({ cell }) => {
    const checked = cell.getValue<boolean>();
    return (
        <div className="tw-px-2">
            <Switch
                className="!tw-cursor-not-allowed"
                checked={checked ?? false}
            />
        </div>
    );
};

export const DateCell: ColumnDef<any>['cell'] = ({ cell }) => {
    const date = DateTime.fromISO(cell.getValue<string>());
    if (!date.isValid) {
        return null;
    }
    return date.toFormat('ff');
};

export const CaseStatusCell: ColumnDef<any>['cell'] = ({ cell }) => {
    const status = cell.getValue<CaseStatus>();
    return (
        <StatusChip size="sm" status={status} />
    );
};

export const FolderIdCellOptions: LinkCellOptions<any> = {
    link: ({ cell }) => `/folder/${cell.getValue<string>()}`
};

export const PortalFolderIdCellOptions: LinkCellOptions<any> = {
    link: ({ cell }) => `/portal/folder/${cell.getValue<string>()}`
};

export const FolderIdCell = LinkCell<any>(FolderIdCellOptions);

export const PortalFolderIdCell = LinkCell<any>(PortalFolderIdCellOptions);
