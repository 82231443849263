import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { RequestThatField } from './RequestThatField';
import { NotesInput } from './NotesInput';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const DEFAULT_STYLE = {
    textAlign: 'left',
    zIndex: 999999
};

export class SelectFixedOptions extends Component {

    constructor(props) {
        super(props);

        let fd = this.props.formData;

        let value = fd && fd[this.props.name] ? fd[this.props.name] : [];

        value = _.compact(value);
        for (let v in value) {
            value[v] = _.find(this.props.options, { value: this.genLang(value[v], this.props.lang) });
            if (value[v] && value[v].label) {
                value[v].label = this.genLang(value[v].label);
            }
        }

        this.state = {
            lang: this.props.lang || 'fr',
            value: value,
            display: this.props.defaultDisplay === false ? false : true,
            followupPrecision: this.props.formData[this.props.name + '_followupPrecision'],
            showPrecision: false
        };

        if (!props.name) {
            console.error('!!! name is missing !!!');
        }

        if (!props.title) {
            console.error('!!! title is missing !!!');
        }

        if (!props.title) {
            console.error('!!! no options for a select O_o?? !!!');
        }

        this.onChange = this.onChange.bind(this);
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevprops) {

        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            let form = _.filter(props.options, (e) => {

                for (let f in props.formData[props.name]) {
                    if (e.value === props.formData[props.name][f]) {
                        return e;
                    }
                }
                return false;

            });

            if (props.formData[props.name + '_followupPrecision'] !== prevprops.formData[props.name + '_followupPrecision']) {
                obj['followupPrecision'] = props.formData[props.name + '_followupPrecision'] || '';
            }

            obj['value'] = form;

            if (props.formData[props.name]) {
                obj.value = props.formData[props.name];
            }
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    showPrecition(boolean) {
        this.setState({
            showPrecision: boolean
        });
    }

    onChange(e) {
        this.setState({
            value: e
        });

        this.props.formData[this.props.name] = e;
        this.props.put({
            params: {
                key: this.props.name,
                value: e
            }
        });

        let other = false;
        let currentOpt = _.map(e, (f) => {
            if (f.value == '455') other = true;
        });

        this.showPrecition(other);

    }

    render() {
        let props = this.props;
        this.props.put({
            params: {
                key: this.props.name,
                value: this.state.value
            }
        });
        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        let options = props.options;

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }
        options = _.map(options, (e) => {

            return {
                ...e,
                key: this.props.name + '_option' + e.value,
                label: this.genLang(e.label, this.state.lang),
                value: this.genLang(e.value, this.state.lang)
            };
        });

        let showPrecition = false;
        for (let f in props.formData[props.name]) {
            if (props.formData[props.name][f].value == '455') showPrecition = true;
        }

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        return (

            <>
                <Row>
                    <Col md={props.col || DEFAULT_SIZE} key={props.name} style={style} className="select2">
                        <FormGroup>
                            <NotesInput  {...props} onChange={props.onChange} formData={props.data} />
                            <Select
                                id={this.props.name}
                                tabIndex={props.tabindex}
                                onChange={(e) => this.onChange(e)}
                                options={options}
                                noOptionsMessage={this.genLang(this.props.noOptionsMessage || 'no data')}
                                value={this.state.value}
                                name={this.props.name}
                                key={this.props.name}
                                isClearable={false}
                                isSearchable
                                isMulti={!!this.props.isMulti}
                                required={this.props.required}
                            />
                            <Input
                                required={(!((this.state.value) && (this.state.value).length > 0))}
                                autoComplete="off"
                                style={{
                                    opacity: 0,
                                    width: '100%',
                                    height: 0,
                                    position: 'absolute'
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12}
                         style={{ display: (this.state.showPrecision === true || showPrecition) ? 'block' : 'none' }}>
                        <FormGroup>
                            <Label><h5>{(this.state.lang == 'fr') ? 'Autre precision' : 'Other precision'}</h5></Label>
                            <Input
                                type="text"
                                name={this.props.name + '_followupPrecision'}
                                onChange={(e) => this.setState({ followupPrecision: e.target.value })}
                                value={this.state.followupPrecision}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    }

}
