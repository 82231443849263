import { PULL_WAITING_LIST, PUSH_WAITING_LIST, TOGGLE_LOADING } from '../_actions/loading-actions.js';

export default function loadingReducer(
    state = {
        waitingList: {},
        loading: false
    }, {
        type,
        params = {}
    }
) {
    // let loading = this.state.loading;
    let innerParams = {};
    if (params && params.params) {
        innerParams = params.params;
    } else if (params) {
        innerParams = params;
    }

    switch (type) {
    case TOGGLE_LOADING:
        return {
            ...state,
            loading: !state.loading
        };
    case PUSH_WAITING_LIST:
        if (innerParams.key) {
            state.loading = true;
            state.waitingList[innerParams.key] = true;
        }
        return { ...state };
    case PULL_WAITING_LIST:
        if (innerParams.key) {
            delete state.waitingList[innerParams.key];
        }

        if (!Object.keys(state.waitingList).length) {
            state.loading = false;
        }

        return { ...state };
    default:
        return state;
    }
}
