import type React from 'react';
import { createContext } from 'react';

export interface ComponentContext {
    name: string;
    path: Array<string | number>;
    dataPath?: Array<string | number>;
    fields?: Record<string, any>;
    readonly?: boolean;
    required?: boolean;
    encrypted?: boolean;
    setEncrypted?: React.Dispatch<boolean>;
}

export const NeoFormComponentContext = createContext<ComponentContext | undefined>(undefined);
