import React, { type ReactElement } from 'react';
import useResizeObserver from 'use-resize-observer';

interface Props {
    children: (dimensions: { width: number; height: number }) => ReactElement;
}

export function FillFlexParent(props: Props) {
    const { ref, width, height } = useResizeObserver();
    return (
        <div className="tw-flex-1 tw-w-full tw-h-full tw-min-h-0 tw-min-w-0" ref={ref}>
            {width && height ? props.children({ width, height }) : null}
        </div>
    );
}
