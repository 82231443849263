import React from 'react';

import { BeatLoader } from 'react-spinners';
import _ from 'lodash';
import { Button, Col } from 'reactstrap';

import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAlt, faBan, faDownload, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

import { CheckInput } from './CheckInput';
import { TextInput } from './TextInput';
import { PreviewModal } from '../../neoform/PreviewModal';

const trash = <FontAwesomeIcon color={'#bb4444'} icon={faTrash} />;
const download = <FontAwesomeIcon color={'blue'} icon={faDownload} />;
const mouve = <FontAwesomeIcon color={'light'} icon={faArrowsAlt} />;
const eye = <FontAwesomeIcon color={'blue'} icon={faEye} />;
const eyeDisabled = <FontAwesomeIcon color={'light'} icon={faEye} />;
const ban = <FontAwesomeIcon color={'#bb4444'} icon={faBan} />;

const NGROK = import.meta.env.VITE_API_URL;
const NONE = 'none', BLOCK = 'block';
const REAL_NAME = '_realName';

export class SortableElementSimple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || [],
            loading: true,
            ticket: this.props.ticket || ''
        };

        this.swap = this.props.swap.bind(this);
        this.setJointFilesList = this.props.setJointFilesList;
        this.removeItem = this.props.removeItem;

    }

    componentDidMount() {

        if (this.props.content && this.props.content.match(/data:/)) {

            const fileName = this.props.keyUpload;
            this.props.pushWaitingList({
                params: {
                    key: this.props.keyUpload
                }
            });
            if (this.props.public) {
                axios.post(
                    NGROK + '/v1/add-file-gcloud', {
                        name: fileName,
                        ticket_id: this.state.ticket,
                        auth_id: '',
                        target: this.props.target,
                        data: {
                            base64: this.props.content
                        },
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        }
                    }
                )
                    .then(
                        (status) => {
                            this.props.put({
                                params: {
                                    key: this.props.keyUpload,
                                    value: status.data.result
                                }
                            });

                            this.props.pullWaitingList({
                                key: this.props.keyUpload
                            });
                            this.setState({ loading: false });
                        },
                        (error) => {
                            // debugger;
                            this.props.pullWaitingList({
                                key: this.props.keyUpload
                            });
                            this.setState({
                                loading: false,
                                error: true
                            });
                            console.log(error);
                        }
                    );
            } else {
                axios.post(
                    NGROK + '/v1/add-file-gcloud', {
                        ticket_id: this.state.ticket,
                        auth_id: localStorage.getItem('user_id'),
                        target: this.props.target,
                        data: {
                            base64: this.props.content
                        },
                        name: fileName,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        }
                    }
                )
                    .then(
                        (status) => {
                            this.props.put({
                                params: {
                                    key: this.props.keyUpload,
                                    value: status.data.result
                                }
                            });

                            this.props.pullWaitingList({
                                key: this.props.keyUpload
                            });
                            this.setState({ loading: false });
                        },
                        (error) => {
                            this.props.pullWaitingList({
                                key: this.props.keyUpload
                            });
                            this.setState({
                                loading: false,
                                error: true
                            });
                            console.log(error);
                        }
                    );
            }
        } else {
            this.setState({ loading: false });
        }
    }

    componentDidUpdate(prevProps) {
        let obj = {};

        if (JSON.stringify(prevProps.ticket) !== JSON.stringify(this.props.ticket)) {
            obj['ticket'] = this.props.ticket;
        }

        if (JSON.stringify(this.state.value) !== JSON.stringify(this.props.value)) {

            obj['value'] = this.props.value;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    previewAttachment(e) {
        if (this.state.url || this.state.data) {
            return this.setState({
                open: true
            });
        }

        if (this.props.document) {
            let linkSource = this.props.document;
            let bonBagai = linkSource.split(';base64,');
            let mime = bonBagai[0].replace('data:', '').replace(/\\/, '');

            this.setState({
                loading: false,
                data: bonBagai[1],
                mime: mime,
                fileName: this.props.realName,
                open: true
            }, () => {
                // debugger
            });
            return;
        }
        let path = this.props.pathGCloud;
        if (this.props.public) {

            axios.get(
                NGROK + '/v1/get-file-gcloud',
                {
                    params: {
                        auth_id: '',
                        path: path,
                        output: 'base64'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            )
                .then(
                    (status) => {
                        let linkSource = status.data.result;
                        let bonBagai = linkSource.split(';base64,');
                        let mime = bonBagai[0].replace('data:', '').replace(/\\/, '');

                        this.setState({
                            loading: false,
                            data: bonBagai[1],
                            mime: mime,
                            fileName: this.props.realName,
                            open: true,
                            text: status.data.text,
                            json: status.data.json
                        });

                    },
                    (error) => {
                        this.props.pullWaitingList({
                            key: this.props.keyUpload
                        });
                        this.setState({
                            loading: false,
                            error: true
                        });
                        console.log(error);
                    }
                );
        } else {
            axios.get(
                NGROK + '/v1/get-file-gcloud',
                {
                    params: {
                        auth_id: localStorage.getItem('user_id'),
                        path: path,
                        output: 'base64'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            )
                .then(
                    (status) => {
                        let linkSource = status.data.result;
                        let bonBagai = linkSource.split(';base64,');
                        let mime = bonBagai[0].replace('data:', '').replace(/\\/, '');

                        this.setState({
                            loading: false,
                            data: bonBagai[1],
                            mime: mime,
                            fileName: this.props.realName,
                            open: true,
                            text: status.data.text,
                            json: status.data.json
                        });

                    },
                    (error) => {
                        this.props.pullWaitingList({
                            key: this.props.keyUpload
                        });
                        this.setState({
                            loading: false,
                            error: true
                        });
                        console.log(error);
                    }
                );
        }
    }

    downloadAttachment(e) {
        let path = this.props.pathGCloud;

        axios.get(
            NGROK + '/v1/get-file-gcloud',
            {
                params: {
                    auth_id: localStorage.getItem('user_id'),
                    path: path,
                    output: 'base64'
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }
        )
            .then(
                (status) => {

                    let linkSource = status.data.result;

                    const downloadLink = document.createElement('a');
                    downloadLink.href = linkSource;
                    downloadLink.download = this.props.realName;
                    downloadLink.click();

                    this.setState({ loading: false });
                },
                (error) => {
                    this.props.pullWaitingList({
                        key: this.props.keyUpload
                    });
                    this.setState({
                        loading: false,
                        error: true
                    });
                    console.log(error);
                }
            );

    }

    render() {
        let state = this.state;
        let props = this.props;
        let e = this.props;

        return (
            <div>

                <div class="neoHLMenu" style={{ marginBottom: '2px' }}>
                    <ul>
                        <li class="neoHLli"><Button color={'light'} className="handle"
                                                    style={{ 'color': '#454599' }}>{mouve}</Button></li>
                        <li class="neoHLli" style={{ width: '70%' }}>
                            <CheckInput
                                callback={(e) => {
                                }}
                                name={props.keyBoolean}

                                title={props.realName}
                                checked={e.checked || false}
                                formData={props.temp}
                                put={this.props.put}
                                style={{
                                    padding: '7px 0 0 5px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'

                                }}

                                disabled={(this.props.pathGCloud != undefined && !(['PDF', 'JPG', 'JPEG', 'PNG', 'GIF'].includes((this.props.pathGCloud.split('.').pop()).toUpperCase())) ? true : false)}

                            />
                        </li>
                        <li class="neoHLli" style={{ display: (this.props.hidePJ === false) ? 'block' : 'none' }}>

                            <TextInput
                                lang={this.state.lang}
                                placeholder={{
                                    fr: 'Nom de la pj',
                                    en: 'Joint file name'
                                }}
                                noFormGroup={true}
                                name={props.keyBase + REAL_NAME}
                                col={7}
                                formData={state}
                                put={this.props.put}
                                usePut={true}
                                callback={({
                                    value,
                                    name
                                }) => {
                                    let obj = {};
                                    obj[name] = value;
                                    this.setState(obj);
                                }}
                            />

                        </li>
                        <li class="neoHLli">
                            <BeatLoader size={3} margin={1} loading={this.state.loading} color="#0052BB" />
                        </li>
                        <li class="neoHLli" style={{ float: 'right' }}>
                            <Button
                                disabled={this.state.loading}
                                color={'light'}
                                removekey={props.keyBase}
                                notencoded={e.name}
                                onClick={(e) => this.removeItem(e)}
                                // style={{height:'12px',width:'12px'}}
                            >{trash}</Button>
                        </li>


                        <li class="neoHLli" md={1} style={{
                            float: 'right',
                            display: (this.props.pathGCloud && this.props.public != true) ? 'block' : 'none'
                        }}>
                            <Button
                                disabled={this.state.loading}
                                color={'light'}
                                removekey={props.keyBase}
                                notencoded={e.name}
                                onClick={(e) => this.downloadAttachment(this.state)}
                            >{download}</Button>
                        </li>
                        <li class="neoHLli" style={{ float: 'right' }}>

                            <Button
                                color={'light'}
                                onClick={(e) => this.previewAttachment(this.state)}

                                disabled={(this.props.pathGCloud != undefined && !(['PDF', 'JPG', 'JPEG', 'PNG', 'GIF'].includes((this.props.pathGCloud.split('.').pop()).toUpperCase())) ? true : false)}
                            >
                                {(this.props.pathGCloud != undefined && !['PDF', 'JPG', 'JPEG', 'PNG', 'GIF'].includes((this.props.pathGCloud.split('.').pop()).toUpperCase())) ? eyeDisabled : eye}
                            </Button>
                        </li>
                        <li class="neoHLli" style={{
                            float: 'right',
                            paddingTop: '7px',
                            color: '#7B7A83'
                        }}>

                            <small width={'30px'}>
                                {(this.props.pathGCloud === undefined) ? '' : (this.props.pathGCloud.split('.').pop()).toUpperCase()}
                            </small>
                        </li>
                        <li class="neoHLli" style={{ float: 'right' }}>
                            {state.open && <PreviewModal
                                title={{
                                    fr: 'Prévisualiser',
                                    en: 'Preview'
                                }}
                                url={state.url}
                                mime={state.mime}
                                data={state.data}
                                name={state.fileName}
                                meta={{
                                    text: state.text,
                                    json: state.json
                                }}
                                hideControls={true}
                                open={!!(state.url || state.data)}
                                onChange={(open) => this.setState({ open })}
                            />}
                        </li>

                        <li class="neoHLli" style={{ display: this.state.error ? BLOCK : NONE }}>
                            <Col md={1}>
                                {ban}
                            </Col>
                        </li>


                    </ul>
                </div>


            </div>
        );
    }
}
