import type React from 'react';
import { useState } from 'react';
import _ from 'lodash';

import { type Subset } from '@/types/utils';
import { useForceUpdate } from '@/composables/forceUpdate';
import { newStateFromAction } from '@/composables/utils';

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>;
type StateMerger<T> = (newState: React.SetStateAction<Subset<T>>) => void;

export function useMergeState<T extends object>(initialState: T):
[T, StateMerger<T>, StateSetter<T>, () => void, number] {
    const [forceUpdate, count] = useForceUpdate();
    const [value, setValue] = useState<T>(initialState);

    const mergeState: StateMerger<T> = (action) => {
        const newState = newStateFromAction(value, action);
        _.assign(value, newState);
        forceUpdate();
    };

    return [value, mergeState, setValue, forceUpdate, count];
}
