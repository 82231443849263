import React, { useContext } from 'react';
import { CardCollapse } from '@/components/CardCollapse';
import { CardContent } from '@/components/ui/card';
import { FolderContext } from '@/pages/psj/Folder';
import { useTranslation } from '@/composables/translation';
import { Badge } from '@/components/ui/badge';
import { Spinner } from '@/components/ui/spinner';
import { MetadataTags } from '@/components/psj/MetadataTags';

export function FolderGroups() {
    const { t, to } = useTranslation('psj.summary');
    const folder = useContext(FolderContext);
    return (
        <CardCollapse innerTitle title={t('properties')}>
            {folder?.loading
                ? <div className="tw-flex tw-justify-center tw-pt-2 tw-pb-8">
                    <Spinner className="tw-text-primary" />
                </div>
                : <CardContent className="!tw-p-4 !tw-pt-0 tw-flex tw-flex-col tw-gap-2">
                    <div className="tw-flex tw-flex-wrap tw-gap-2">
                        {folder?.case?.groups.map((g) =>
                            <Badge key={g._id} size="sm">{to(g.name)}</Badge>
                        )}
                    </div>
                    {folder?.case?.metadata && <MetadataTags value={folder.case.metadata} />}
                </CardContent>
            }
        </CardCollapse>
    );
}
