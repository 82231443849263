import React, { useState } from 'react';
import { InputFile, type UploadedFile } from '@/components/ui/input-file';
import _ from 'lodash';
import * as pdfjs from 'pdfjs-dist';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { ReactSortable } from 'react-sortablejs';
import { Badge } from '@/components/ui/badge';
import { Cross1Icon, DownloadIcon, Link2Icon, UploadIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { DialogTrigger } from '@/components/ui/dialog';
import { CrudDialog, CrudInputType } from '@/components/ui/crud-table';
import ObjectId from 'bson-objectid';
import axios from 'axios';
import { useError } from '@/composables/error';
import { readClipboardText } from '@/composables/clipboard';
import { downloadArrayBuffer, isCallable, isURL, toStringNumbersAsRanges } from '@/composables/utils';
import { ButtonAsync } from '@/components/ui/button-async';
import { ConfirmDialogButton } from '@/components/ConfirmDialogButton';
import { useTranslation } from '@/composables/translation';
import {
    generatePdfChapters,
    generatePdfFromPages, generatePreviewForPage,
    loadDocument,
    type PdfChapter as PdfChapterType,
    type PdfFile,
    type PdfItem,
    type PdfPage
} from '@/composables/pdf';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { type SortableItem } from '@/types/sortable';
import { Label } from '@/components/ui/label';
import { Combobox } from '@/components/ui/combobox';
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from '@/components/ui/context-menu';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { useForceUpdate } from '@/composables/forceUpdate';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const FILE_BORDER_COLORS = [
    '4 80% 48%',
    '84 81% 44%',
    '199 89% 48%',
    '293 69% 49%'
] as const;

const FileUrlDialog = CrudDialog<{ url: string }, 'url'>({
    idKey: 'url',
    schema: [
        {
            id: 'url',
            type: CrudInputType.TEXT,
            name: 'URL',
            create: true,
            required: true
        }
    ]
});

interface PdfChapterProps {
    chapter: SortableItem<PdfChapterType>;
    files: PdfFile[];
    setList: React.Dispatch<React.SetStateAction<Array<SortableItem<PdfItem>>>>;
    preferPreview?: boolean;
    vertical?: boolean;
}

function PdfChapter({ files, chapter, setList, preferPreview, vertical }: PdfChapterProps) {
    const { ct } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const file = files.find(f => f.id === chapter.file_id)!;
    function handleToggle() {
        chapter.collapsed = !chapter.collapsed;
        setList((state) => [...state]);
    }
    function handleDelete(id: string) {
        chapter.pages = chapter.pages.filter(p => p.id !== id);
        setList((state) => state.filter(i => i.id !== id));
    }
    let inner;
    if (chapter.collapsed) {
        const pageNumber = toStringNumbersAsRanges(chapter.pages.map(p => p.page_number), false);
        inner = (
            <PdfThumbnail
                id={chapter.id}
                title={chapter.title}
                pageNumber={pageNumber || '—'}
                thumbnailUrl={chapter.thumbnail_url}
                previewUrl={chapter.preview_url}
                color={file.color}
                selected={chapter.selected}
                preferPreview={preferPreview}
                onDelete={() => handleDelete(chapter.id)}
            />
        );
    } else {
        inner = (
            <div
                className={cn(
                    'tw-relative',
                    'tw-cursor-move tw-select-none',
                    'tw-rounded-lg tw-border-2 tw-border-dashed',
                    'tw-border-[color:hsl(var(--color)_/_0.4)] tw-pt-8 tw-p-6'
                )}
                style={{
                    '--color': file.color
                }}
            >
                <div
                    className="tw-absolute tw-top-0 tw-left-0 tw-text-sm tw-font-medium tw-py-1.5 tw-px-2"
                >
                    {chapter.title}
                </div>
                <ConfirmDialogButton
                    title="Suppression de page"
                    message="Êtes-vous sûr de vouloir supprimer cette page ?"
                    confirmText={ct('delete')}
                    onConfirm={() => handleDelete(chapter.id)}
                >
                    <Button
                        variant="ghost"
                        className={cn(
                            '!tw-size-8 tw-absolute tw-top-0 tw-right-0',
                            '!tw-p-0 tw-m-1.5 tw-text-destructive',
                            'hover:tw-text-destructive'
                        )}
                    >
                        <Cross1Icon width={20} height={20}/>
                    </Button>
                </ConfirmDialogButton>
                <ReactSortable
                    id={chapter.id}
                    key={chapter.id}
                    className={cn(
                        'tw-flex tw-flex-wrap tw-gap-2',
                        'hover:tw-border-[color:hsl(var(--color))]',
                        'tw-min-w-56 tw-min-h-80',
                        vertical && 'tw-flex-col'
                    )}
                    selectedClass="sortable-selected"
                    ghostClass="sortable-ghost"
                    dragClass="sortable-drag"
                    fallbackClass="sortable-fallback"
                    group={{
                        name: 'pdf',
                        put: (to, from, item) => item.id.includes('_p')
                    }}
                    animation={150}
                    forceFallback
                    list={chapter.pages}
                    setList={(currentList) => {
                        chapter.pages = [...currentList].filter(Boolean);
                        setList((state) => [...state]);
                    }}
                >
                    {chapter.pages.map(page => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const file = files.find(f => f.id === page.file_id)!;
                        return (
                            <PdfThumbnail
                                id={page.id}
                                key={page.id}
                                title={file.filename}
                                pageNumber={page.page_number}
                                thumbnailUrl={page.thumbnail_url}
                                previewUrl={page.preview_url}
                                color={file.color}
                                selected={page.selected}
                                onDelete={() => handleDelete(page.id)}
                                preferPreview={preferPreview}
                            />
                        );
                    })}
                </ReactSortable>
            </div>
        );
    }
    return (
        <ContextMenu>
            <ContextMenuTrigger>
                {inner}
            </ContextMenuTrigger>
            <ContextMenuContent>
                <ContextMenuItem inset onClick={handleToggle}>
                    {chapter.collapsed ? 'Explode' : 'Collapse'}
                </ContextMenuItem>
            </ContextMenuContent>
        </ContextMenu>
    );
}

interface PdfThumbnailProps {
    id: string;
    title: string;
    pageNumber: number | string;
    thumbnailUrl: string;
    previewUrl?: string;
    preferPreview?: boolean;
    color: string;
    selected?: boolean;
    onDelete?: React.Dispatch<void>;
    onClick?: () => void;
}

function PdfThumbnail(props: PdfThumbnailProps) {
    const { ct } = useTranslation();
    return (
        <div
            id={props.id}
            style={{
                '--color': props.color
            }}
            className={cn(
                'tw-rounded-lg tw-border-[1px] tw-border-solid',
                'tw-cursor-move tw-select-none',
                'tw-border-[color:hsl(var(--color)_/_0.4)] tw-p-2 tw-relative',
                'hover:tw-border-[color:hsl(var(--color))]',
                '[&.sortable-ghost]:tw-opacity-0',
                '[&.sortable-fallback]:tw-border-[color:hsl(var(--color))]',
                '[&.sortable-fallback]:!tw-opacity-100',
                '[&.sortable-fallback]:tw-border-[color:hsl(var(--color))]',
                '[&.sortable-fallback]:[--num-items-opacity:1]',
                '[&.sortable-selected]:!tw-border-[color:hsl(var(--color))]'
            )}
        >
            <img
                src={
                    props.preferPreview
                        ? (props.previewUrl ?? props.thumbnailUrl)
                        : props.thumbnailUrl
                }
                width="100%"
                alt="thumbnail"
                className="tw-mx-auto"
            />
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className={cn(
                        'tw-absolute tw-top-0 tw-left-0',
                        'tw-m-2 tw-max-w-[calc(100%_-_48px)]'
                    )} onClick={props.onClick}>
                        <Badge
                            variant="secondary"
                            className={cn(
                                '!tw-rounded-full !tw-text-sm tw-overflow-x-hidden',
                                '!tw-flex tw-items-center tw-gap-1.5',
                                'hover:!tw-bg-secondary'
                            )}
                        >
                            <span className="tw-translate-y-[-1px] tw-truncate">
                                {props.title}
                            </span>
                        </Badge>
                    </div>
                </TooltipTrigger>
                <TooltipContent>
                    {props.title}
                </TooltipContent>
            </Tooltip>
            <Badge
                variant="secondary"
                className={cn(
                    'tw-absolute tw-bottom-0 tw-right-0 tw-m-2 !tw-rounded-full',
                    'tw-pointer-events-none'
                )}
            >
                {props.pageNumber}
            </Badge>
            <Tooltip>
                <ConfirmDialogButton
                    title="Suppression de page"
                    message="Êtes-vous sûr de vouloir supprimer cette page ?"
                    confirmText={ct('delete')}
                    onConfirm={props.onDelete}
                >
                    <TooltipTrigger asChild>
                        <Button
                            variant="ghost"
                            className={cn(
                                '!tw-size-8 tw-absolute tw-top-0 tw-right-0',
                                '!tw-p-0 tw-m-1.5 tw-text-destructive',
                                'hover:tw-text-destructive'
                            )}
                        >
                            <Cross1Icon width={20} height={20}/>
                        </Button>
                    </TooltipTrigger>
                </ConfirmDialogButton>
                <TooltipContent>
                    {ct('delete')}
                </TooltipContent>
            </Tooltip>
        </div>
    );
}

interface PdfItemWrapperProps {
    files: PdfFile[];
    item: SortableItem<PdfItem>;
    setList: React.Dispatch<React.SetStateAction<Array<SortableItem<PdfItem>>>>;
    preferPreview?: boolean;
    vertical?: boolean;
}

function PdfItemWrapper({ files, item, setList, preferPreview, vertical }: PdfItemWrapperProps) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const file = files.find(f => f.id === item.file_id)!;
    if (item.type === 'page') {
        return (
            <PdfThumbnail
                id={item.id}
                key={item.id}
                title={file.filename}
                pageNumber={item.page_number}
                thumbnailUrl={item.thumbnail_url}
                previewUrl={item.preview_url}
                color={file.color}
                selected={item.selected}
                onDelete={() => setList((state) => state.filter(i => i.id !== item.id))}
                preferPreview={preferPreview}
            />
        );
    } else {
        return (
            <PdfChapter
                key={item.id}
                chapter={item}
                files={files}
                setList={setList}
                preferPreview={preferPreview}
                vertical={vertical}
            />
        );
    }
}

export function PdfMerger() {
    const { handleNetworkError } = useError();

    const [clipboardUrl, setClipboardUrl] = useState('');
    const [open, setOpen] = useState(false);
    const [destinationFile, setDestinationFile] = useState<string | null>(null);
    const [files, setFiles] = useState<PdfFile[]>([]);
    const [sourceItems, setSourceItems] = useState<Array<SortableItem<PdfItem>>>([]);
    const [destinationItems, setDestinationItems] = useState<Array<SortableItem<PdfItem>>>([]);
    const [forceUpdate] = useForceUpdate();

    function handleOpenChange(open: boolean) {
        if (open) {
            readClipboardText().then((text) => {
                if (isURL(text)) {
                    setClipboardUrl(text);
                }
            });
        }
        setOpen(open);
    }

    async function generatePreview(item: PdfItem): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const file = files.find(f => f.id === item.file_id)!;
        if (item.preview_url) {
            return;
        }
        if (item.type === 'page') {
            item.preview_url = await generatePreviewForPage(file, item);
            return;
        }
        item.preview_url = await generatePreviewForPage(file, file.pages[item.page_range.start - 1]);
        await Promise.all(item.pages.map(generatePreview));
    }

    async function addFiles(...newFiles: PdfFile[]) {
        for (const file of newFiles) {
            const [chapters, pages] = await generatePdfChapters(file);
            chapters.forEach((c) => { c.collapsed = true; });
            setFiles([...files, { ...file, pages }]);
            if (chapters.length > 0) {
                setSourceItems([...sourceItems, ...chapters]);
            } else {
                setSourceItems([...sourceItems, ...pages]);
            }
        }
    }

    function handleAddFileFromUrl(url: string) {
        return axios.get<Blob>(url, { responseType: 'blob' })
            .then(async(res) => {
                const { document, proxy } = await loadDocument(res.data);
                return await addFiles({
                    id: ObjectId().toHexString(),
                    filename: res.data.name ? res.data.name : new URL(url).hostname,
                    content_type: res.data.type,
                    data: res.data,
                    color: FILE_BORDER_COLORS[files.length % FILE_BORDER_COLORS.length],
                    document,
                    proxy,
                    pages: []
                });
            })
            .then(() => setOpen(false))
            .catch(handleNetworkError);
    }

    async function handleChangeFiles(list: UploadedFile[]) {
        const newFiles: PdfFile[] = await Promise.all(
            _.differenceWith(list, files, (a, b) => a.id === b.id)
                .map(async(file, idx) => {
                    const { document, proxy } = await loadDocument(file.data);
                    return {
                        ...file,
                        color: FILE_BORDER_COLORS[(
                            files.length + idx
                        ) % FILE_BORDER_COLORS.length],
                        document,
                        proxy,
                        pages: []
                    };
                })
        );
        await addFiles(...newFiles);
    }

    async function handleGeneratePdf() {
        const items = destinationFile ? destinationItems : sourceItems;
        const pages = items.reduce<Array<SortableItem<PdfPage>>>((acc, item) => {
            if (item.type === 'page') {
                acc.push(item);
            } else if (item.type === 'chapter') {
                acc.push(...item.pages);
            }
            return acc;
        }, []);
        const result = await generatePdfFromPages(files, pages);
        const bytes = await result.save();
        downloadArrayBuffer(bytes, 'result.pdf');
    }

    function handleSetDestinationItems(items: React.SetStateAction<Array<SortableItem<PdfItem>>>) {
        const newItems = isCallable<(state: Array<SortableItem<PdfItem>>) => Array<SortableItem<PdfItem>>>(items)
            ? items(destinationItems)
            : items;
        setDestinationItems(newItems);
        newItems.map((item) => generatePreview(item).then(() => {
            setTimeout(() => {
                forceUpdate();
            }, 1000);
        }));
    }

    function handleSelectDestinationFile(id: string | null) {
        const items = [...sourceItems, ...destinationItems];
        const [dst, src] = _.partition(items, (item) => item.file_id === id);
        setDestinationFile(id);
        setSourceItems(src);
        handleSetDestinationItems(dst);
    }

    return (
        <Card className="tw-flex-1 tw-flex tw-flex-col tw-overflow-auto !tw-rounded-none tw-border-0">
            <CardHeader className={cn(files.length > 0 ? 'tw-pb-0' : 'tw-pb-3')}>
                <CardTitle>Outil pdf</CardTitle>
            </CardHeader>
            <CardContent className="tw-flex-auto tw-flex tw-flex-col tw-gap-3 tw-overflow-auto">
                <div className="tw-flex tw-items-end tw-flex-wrap tw-gap-3">
                    <FileUrlDialog
                        value={clipboardUrl ? { url: clipboardUrl } : undefined}
                        open={open}
                        onOpenChange={handleOpenChange}
                        onSubmit={({ url }) => handleAddFileFromUrl(url)}
                    >
                        <DialogTrigger asChild>
                            <Button variant="outline" className="tw-text-primary">
                                <Link2Icon className="tw-mr-2"/>
                                Ajouter à partir d&apos;un lien
                            </Button>
                        </DialogTrigger>
                    </FileUrlDialog>
                    {files.length > 0 &&
                        <Button variant="outline" className="tw-text-primary" asChild>
                            <label className="tw-cursor-pointer" htmlFor="files">
                                <UploadIcon className="tw-mr-2"/>
                                Téléverser un ficher
                            </label>
                        </Button>
                    }
                    {files.length > 0 &&
                        <div className="tw-min-w-96 tw-ml-auto">
                            <Label>Fichier de destination</Label>
                            <Combobox<PdfFile, string>
                                options={files}
                                getOptionValue={opt => opt.id}
                                getOptionLabel={opt => opt.filename}
                                clearable
                                value={destinationFile}
                                onChange={handleSelectDestinationFile}
                            />
                        </div>
                    }
                    <ButtonAsync
                        className={cn(files.length <= 0 && 'tw-ml-auto')}
                        onClick={handleGeneratePdf}
                    >
                        <DownloadIcon className="tw-mr-2"/>
                        Télécharger le résultat
                    </ButtonAsync>
                </div>
                <InputFile
                    id="files"
                    className={cn(files.length > 0 && 'tw-hidden')}
                    multiple
                    value={files}
                    onChange={handleChangeFiles}
                />
                <ResizablePanelGroup
                    className="tw-flex-auto"
                    direction="horizontal"
                >
                    <ResizablePanel
                        defaultSize={75}
                    >
                        <div className={cn(
                            'tw-h-full tw-overflow-auto tw-p-1',
                            'tw-border-2 tw-border-input tw-border-dashed tw-rounded-lg'
                        )}>
                            <ReactSortable
                                className={cn(
                                    'tw-flex tw-items-center tw-justify-center',
                                    'tw-flex-wrap tw-gap-2 tw-min-h-full'
                                )}
                                selectedClass="sortable-selected"
                                ghostClass="sortable-ghost"
                                dragClass="sortable-drag"
                                fallbackClass="sortable-fallback"
                                animation={150}
                                forceFallback
                                group="pdf"
                                list={sourceItems}
                                setList={setSourceItems}
                            >
                                {sourceItems.map((item) =>
                                    <PdfItemWrapper
                                        key={item.id}
                                        files={files}
                                        item={item}
                                        setList={setSourceItems}
                                    />
                                )}
                            </ReactSortable>
                        </div>
                    </ResizablePanel>
                    {destinationFile && <ResizableHandle
                        withHandle
                        className="tw-mx-3 tw-w-[3px] tw-bg-slate-300"
                    />}
                    {destinationFile && <ResizablePanel
                        defaultSize={25}
                    >
                        <div className={cn(
                            'tw-h-full tw-overflow-auto tw-p-1',
                            'tw-border-2 tw-border-input tw-border-dashed tw-rounded-lg'
                        )}>
                            <ReactSortable
                                className="tw-flex tw-flex-col tw-gap-3 tw-items-center"
                                selectedClass="sortable-selected"
                                ghostClass="sortable-ghost"
                                dragClass="sortable-drag"
                                fallbackClass="sortable-fallback"
                                animation={150}
                                forceFallback
                                group="pdf"
                                list={destinationItems}
                                setList={handleSetDestinationItems}
                            >
                                {destinationItems.map((item) =>
                                    <PdfItemWrapper
                                        key={item.id}
                                        files={files}
                                        item={item}
                                        setList={handleSetDestinationItems}
                                        preferPreview
                                        vertical
                                    />
                                )}
                            </ReactSortable>
                        </div>
                    </ResizablePanel>}
                </ResizablePanelGroup>
            </CardContent>
        </Card>
    );
}
