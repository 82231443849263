import React, { useCallback, useEffect, useState } from 'react';
import { Link, type Location, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTabStore } from '@/store/tabs';
import { PSJTab } from '@/components/psj/PSJTab';
import { type Tab, iconMap } from '@/types/tabs';
import { combine, useTranslation } from '@/composables/translation';
import { useQuery } from '@/composables/query';
import { cn } from '@/lib/utils';
import { PlusIcon } from '@radix-ui/react-icons';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuPortal,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faHistory, faListCheck, faNoteSticky, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Feature, useFeatures } from '@/composables/features';
import qs from 'query-string';
import { type View } from '@/types/api/views';
import { getViews } from '@/composables/api';
import { getViewIcon, useViews } from '@/composables/views';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

type TabFunction<TParams = any, TQuery = any> =
    (location: Location, params: TParams, query: TQuery) => Partial<Tab>;

const TAB_TYPES: Record<string, TabFunction> = {
    '/dashboard': () => ({
        type: 'view',
        label: combine('common.folders')
    }),
    '/clients': () => ({
        type: 'view',
        label: combine('common.clients')
    }),
    '/forms': () => ({
        type: 'view',
        label: combine('common.forms')
    }),
    '/folder': (_, { folder_id }) => ({
        type: 'folder',
        label: folder_id
    }),
    '/unassigned': () => ({
        type: 'notes',
        label: combine('common.unassigned-notes')
    })
};

export function PSJLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const query = useQuery<any>();
    const { to, ct } = useTranslation();
    const { isFeatureEnabled } = useFeatures();
    const { buildViewUrl } = useViews();
    const { tabs, addTab, removeTab, reset } = useTabStore((state) => ({
        tabs: state.tabs,
        addTab: state.addTab,
        removeTab: state.removeTab,
        reset: state.reset,
        history: state.history
    }));
    const [, setSearch] = useSearchParams();
    const [views, setViews] = useState<View[]>([]);

    useEffect(() => {
        getViews({ limit: 10000, active: true })
            .then(res => setViews(res.data));
    }, []);

    useEffect(() => {
        addTab(getTab(location, params, query));
    }, [location.pathname, query]);

    function getTab(location: Location, params: any, query: any): Tab {
        const TAB_TITLE_KEY = 'tab';
        const title = query[TAB_TITLE_KEY];
        delete query[TAB_TITLE_KEY];
        let search = qs.stringify(query);
        search = search ? `?${search}` : search;
        if (search !== location.search) {
            setSearch(search, { replace: true });
        }
        const entry = Object.entries(TAB_TYPES)
            .find(([path]) => location.pathname.startsWith(path));
        return {
            type: 'view',
            label: location.pathname,
            url: location.pathname + search,
            ...entry?.[1]?.(location, params, query),
            ...(title && { label: title })
        };
    }

    const onDeleteTab = useCallback((url: string) => {
        const nextTab = removeTab(url);
        if (url === location.pathname + location.search) {
            const nextUrl = nextTab?.url ?? '/dashboard';
            navigate(nextUrl, { replace: true });
        }
    }, [location.pathname, location.search, navigate, removeTab]);

    const closeAllTabs = useCallback(() => {
        if (tabs.length > 1) {
            reset();
            location.pathname === '/dashboard' ? addTab(getTab(location, params, query)) : navigate('/dashboard');
        }
    }, [tabs.length, reset, location.pathname, addTab, getTab, navigate, params, query]);

    return (
        <main className="tw-flex-1 tw-flex tw-flex-col !tw-p-0 !tw-pt-5">
            <div className="tw-flex tw-flex-wrap tw-gap-4 tw-px-5 tw-items-center print:tw-hidden">
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant="secondary" size="lg"
                            className={cn(
                                'tw-border-2 tw-border-dashed !tw-border-slate-300 !tw-rounded-full',
                                '!tw-px-4 hover:tw-bg-slate-200 tw-cursor-pointer'
                            )}
                        >
                            <PlusIcon className="tw-size-4 tw-mr-2" />
                            Nouvelle vue
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent side="bottom" align="start">
                        <DropdownMenuGroup>
                            {isFeatureEnabled(Feature.PSJ) && <DropdownMenuSub>
                                <DropdownMenuSubTrigger>
                                    <FontAwesomeIcon
                                        className="tw-text-muted-foreground tw-mr-2"
                                        width="1em"
                                        icon={faFolderOpen}
                                    />
                                    Dossiers
                                </DropdownMenuSubTrigger>
                                <DropdownMenuPortal>
                                    <DropdownMenuSubContent>
                                        <DropdownMenuItem asChild>
                                            <Link to="/dashboard?tab=Mes dossiers">
                                                Assignés à moi
                                            </Link>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem asChild>
                                            <Link to="/dashboard?tab=Tous les dossiers&my_folders=false">
                                                Tous
                                            </Link>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem asChild>
                                            <Link to="/dashboard/external?tab=Dossiers externes">
                                                Externes
                                            </Link>
                                        </DropdownMenuItem>
                                    </DropdownMenuSubContent>
                                </DropdownMenuPortal>
                            </DropdownMenuSub>}
                            {isFeatureEnabled(Feature.PSJ) && <DropdownMenuItem asChild>
                                <Link to="/clients">
                                    <FontAwesomeIcon
                                        className="tw-text-muted-foreground tw-mr-2"
                                        width="1em"
                                        icon={faUser}
                                    />
                                    Clients
                                </Link>
                            </DropdownMenuItem>}
                            <DropdownMenuItem asChild>
                                <Link to="/forms">
                                    <FontAwesomeIcon
                                        className="tw-text-muted-foreground tw-mr-2"
                                        width="1em"
                                        icon={faListCheck}
                                    />
                                    Formulaires
                                </Link>
                            </DropdownMenuItem>
                            {isFeatureEnabled(Feature.PSJ) && <DropdownMenuItem asChild>
                                <Link to="/unassigned">
                                    <FontAwesomeIcon
                                        className="tw-text-muted-foreground tw-mr-2"
                                        width="1em"
                                        icon={faNoteSticky}
                                    />
                                    Notes non-assignées
                                </Link>
                            </DropdownMenuItem>}
                            {views.length > 0 && <DropdownMenuSub>
                                <DropdownMenuSubTrigger>
                                    {ct('others')}
                                </DropdownMenuSubTrigger>
                                <DropdownMenuPortal>
                                    <DropdownMenuSubContent>
                                        {views.map(v => {
                                            const icon = getViewIcon(v);
                                            return (
                                                <DropdownMenuItem key={v._id} asChild>
                                                    <Link to={buildViewUrl(v)}>
                                                        {icon &&
                                                            <FontAwesomeIcon
                                                                className="tw-text-muted-foreground tw-mr-2"
                                                                width="1em"
                                                                icon={icon}
                                                            />
                                                        }
                                                        {to(v.title)}
                                                    </Link>
                                                </DropdownMenuItem>
                                            );
                                        })}
                                    </DropdownMenuSubContent>
                                </DropdownMenuPortal>
                            </DropdownMenuSub>}
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
                {tabs.map((t) => (
                    <PSJTab key={t.url} {...t} onDelete={() => onDeleteTab(t.url)} />
                ))}
                <CloseAllTabs tabs={tabs} closeAllTabs={closeAllTabs} />
                <HistoryMenuButton />
            </div>
            <div className="tw-flex-1 tw-flex tw-flex-col">
                <Outlet />
            </div>
        </main>
    );
}

interface CloseAllTabsProps {
    tabs: Tab[];
    closeAllTabs: () => void;
}

const CloseAllTabs: React.FC<CloseAllTabsProps> = ({ tabs, closeAllTabs }) => {
    const { to } = useTranslation();
    return (
        <Tooltip>
            <TooltipTrigger asChild>
                <Button
                    disabled={tabs.length <= 1}
                    onClick={closeAllTabs} variant="secondary" size="icon" className={cn(
                        'tw-border-2 tw-border-dashed !tw-border-slate-300 !tw-rounded-full',
                        '!tw-px-4 hover:tw-bg-slate-200 tw-cursor-pointer'
                    )}>
                    <FontAwesomeIcon icon={faXmark} style={{ color: 'grey' }} />
                </Button>
            </TooltipTrigger>
            <TooltipContent>
                {to({ en: 'Close all tabs', fr: 'Fermer tous les onglets' })}
            </TooltipContent>
        </Tooltip>
    );
};

function HistoryMenuButton() {
    const { to } = useTranslation();
    const { history } = useTabStore((state) => ({
        history: state.history
    }));
    return (
        <DropdownMenu>
            <Tooltip>
                <DropdownMenuTrigger asChild>
                    <TooltipTrigger asChild>
                        <Button
                            disabled={history.length === 0}
                            variant="secondary" size="icon" className={cn(
                                'tw-border-2 tw-border-dashed !tw-border-slate-300 !tw-rounded-full',
                                '!tw-px-4 hover:tw-bg-slate-200 tw-cursor-pointer'
                            )}>
                            <FontAwesomeIcon icon={faHistory} style={{ color: 'grey' }}/>
                        </Button>
                    </TooltipTrigger>
                </DropdownMenuTrigger>
                <TooltipContent>
                    {to({ en: 'Recently closed', fr: 'Récemment supprimé' })}
                </TooltipContent>
            </Tooltip>
            <DropdownMenuContent side="bottom" align='start' >
                {history.map((t) => {
                    const icon = iconMap[t.type];
                    return <DropdownMenuItem key={t.url} asChild>
                        <Link to={t.url}>
                            <FontAwesomeIcon icon={icon} className="tw-text-muted-foreground tw-mr-2" width="1em"/>
                            {to(t.label)}
                        </Link>
                    </DropdownMenuItem>;
                })}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
