import React from 'react';

import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { SortableElement } from './SortableElementTest';

import { SortableElementSimple } from './SortableElementSimple';

import { ReactSortable } from 'react-sortablejs';

const UPLOAD_DOCUMENT = '_uploadDocument';
const BOOLEAN = '_boolean';
const BUCKET = '_bucket';
const REAL_NAME = '_realName';
const ORIGINAL_NAME = '_originalName';
const AMMEND = '_ammend';

const INDEX = '_index';

export class SortableContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || [],
            ticket: this.props.ticket,
            xyz: 'sortCont'
        };

        this.swap = this.swap.bind(this);
        this.setJointFilesList = this.props.setJointFilesList;
        this.removeItem = this.props.removeItem;
        this.changeLiasse = this.props.changeLiasse;

    }

    componentDidUpdate(prevProps) {
        let obj = {},
            props = this.props;

        if (JSON.stringify(this.state.value) !== JSON.stringify(this.props.value)) {

            obj['value'] = this.props.value;
        }

        if (prevProps.ticket !== props.ticket) {
            obj['ticket'] = props.ticket;
        }

        if (!_.isEmpty(obj)) {
            // debugger;
            this.setState(obj);
        }
    }

    swap(dragIndex, dropIndex) {
        // debugger
        let swappedTodo = [...this.state.value];
        let test = swappedTodo.splice(dragIndex, 1);
        swappedTodo.splice(dropIndex, 0, test[0]);
        // debugger;

        // let swappedTodo = swapArrayPositions(this.state.value, dragIndex, dropIndex);

        for (let s in swappedTodo) {
            if (!swappedTodo[s]) {
                return;
            }

            swappedTodo[s].index = s;
        }

        // debugger;
        this.setState({
                value: swappedTodo
            }, () => {
                for (let v in this.state.value) {
                    // if(this.state.value[v].name||this.state.value[v].bundleId){

                    let key;

                    if (this.state.value[v].bundleId) {
                        key = this.props.name + '_' + this.state.value[v].bundleId + INDEX;
                    } else {
                        key = this.state.value[v].key.replace(BOOLEAN, INDEX);
                    }

                    this.props.put({
                        params: {
                            key: key,
                            value: v.toString()
                        }
                    });
                    // }

                }

                this.setJointFilesList(this.state.value);
            }
        );
    }

    accentsTidy(s) {

        var r = s;

        // r = r.replace(new RegExp(/\s/g),"");
        r = r.replace(new RegExp(/[àáâãäå]/g), 'a');
        r = r.replace(new RegExp(/æ/g), 'ae');
        r = r.replace(new RegExp(/ç/g), 'c');
        r = r.replace(new RegExp(/[èéêë]/g), 'e');
        r = r.replace(new RegExp(/[ìíîï]/g), 'i');
        r = r.replace(new RegExp(/ñ/g), 'n');
        r = r.replace(new RegExp(/[òóôõö]/g), 'o');
        r = r.replace(new RegExp(/œ/g), 'oe');
        r = r.replace(new RegExp(/[ùúûü]/g), 'u');
        r = r.replace(new RegExp(/[ýÿ]/g), 'y');
        // r = r.replace(new RegExp(/\W/g),"");
        // console.log('>>>',r)
        return r;
    }

    testFunction(params) {
        // console.log(params);
    }

    handleCallback = (childData) => {

        this.setState(state => {

            if (childData.idToLiasse == 'newLiasse') {

                let index = Math.max(...state.value.map(o => o.index)) + 1;
                var newUuid = uuid();

                let NewLiasse = {
                    id: index.toString(),
                    index: index.toString(),
                    key: 'print_jointDocuments_' + newUuid + '_boolean',
                    ammend: 'false',
                    checked: true,
                    name: 'Liasse',
                    realName: 'Liasse',
                    custom: true,
                    bundleId: newUuid,
                    files: []
                };

                NewLiasse.files.push(childData.infoMoveDoc);
                NewLiasse['print_jointDocuments_' + newUuid + '_realName'] = 'Liasse';

                state.value.push(NewLiasse);

            } else {

                var nbFileFromLiasse = 0;
                state.value.map((item, i) => {
                    if (item.bundleId && item.bundleId == childData.idFromLiasse) {
                        nbFileFromLiasse = state.value[i]['files'].length;
                    }
                });

                var nbFileToLiasse = 0;
                state.value.map((item, i) => {
                    if (item.bundleId && item.bundleId == childData.idToLiasse) {
                        nbFileToLiasse = state.value[i]['files'].length;
                        state.value[i]['files'].push(childData.infoMoveDoc);
                    }
                });

            }

            var indexState = 0;
            state.value.map((item, i) => {
                if (item.bundleId && item.bundleId == childData.idFromLiasse) {
                    state.value[i]['files'] = state.value[i]['files'].filter((_, index) => index !== childData.index);
                    indexState = i;
                }
            });

            childData['state'] = state;
            this.changeLiasse(childData, nbFileFromLiasse);

            return state;

        }, (e) => this.setJointFilesList(this.state.value));

    };

    render() {
        let state = this.state;
        let props = this.props;
        let counter = 0;
        let index = 0;
        let indexPiece = 0;

        if (state.value.length > 0) {
            state.value.map((item, i) => {
                state.value[i]['indexPiece'] = indexPiece++;
            });
        }

        if (!this.props.simpleList) {
            return (
                <div key={props.name + 'testmagique99999'}
                >
                    <ReactSortable

                        list={state.value}
                        setList={this.testFunction}
                        style={{ marginBottom: '15px' }}
                        handle=".handle"
                        onEnd={(evt) => {

                            this.swap(evt.oldIndex, evt.newIndex);
                        }}
                    >
                        {_.map(state.value, (e) => {
                            let key = '';
                            let keyAmmend = '';

                            let keyOriginal = '';
                            let keyBoolean = '';
                            let keyRemove = '';
                            let keyUpload = '';
                            let keyBucket = '';

                            let name = '';
                            if (e.bundleId) {
                                name = e.bundleId;
                            } else {
                                // debugger;
                                key = e.key.replace(BOOLEAN, REAL_NAME);
                                keyRemove = key.replace(REAL_NAME, '');
                                keyAmmend = e.key.replace(BOOLEAN, AMMEND);
                                keyOriginal = e.key.replace(BOOLEAN, ORIGINAL_NAME);
                                keyBoolean = e.key;

                                keyUpload = e.key.replace(BOOLEAN, UPLOAD_DOCUMENT);
                                name = e[key];
                            }
                            let temp = {};

                            if (e.bundleId) {
                                temp[props.name + '_' + e.bundleId + REAL_NAME] = e[props.name + '_' + e.bundleId + REAL_NAME];
                            } else if (key) {
                                temp[key] = e[key];
                            } else {
                                temp[props.name + '_' + name + REAL_NAME] = e[props.name + '_' + name + REAL_NAME];
                            }

                            if (!key) {

                                key = props.name + '_' + name + REAL_NAME;
                                keyAmmend = props.name + '_' + name + AMMEND;
                                keyBoolean = props.name + '_' + name + BOOLEAN;
                                keyRemove = props.name + '_' + name;
                                keyUpload = props.name + '_' + name + UPLOAD_DOCUMENT;
                                keyBucket = props.name + '_' + name + BUCKET;

                            }

                            return (
                                <SortableElement
                                    // data={}
                                    public={this.props.public}
                                    pushWaitingList={
                                        this.props.pushWaitingList
                                    }
                                    pullWaitingList={
                                        this.props.pullWaitingList
                                    }
                                    putElementName={this.props.putElementName}
                                    toggleLoading={this.props.toggleLoading}
                                    className="sortHover"
                                    items={state.value}
                                    id={e.id}
                                    key={props.name + '_' + e.name + '_' + counter++}
                                    keyBase={key}
                                    keyUpload={keyUpload}

                                    liasseList={state.value}
                                    parentCallback={this.handleCallback}
                                    indexPiece={index++}

                                    pathGCloud={this.props.formData[keyUpload]}
                                    bucket={this.props.formData[keyBucket]}
                                    keyOriginal={keyOriginal}
                                    keyRemove={keyRemove}
                                    keyAmmend={keyAmmend}
                                    removeItem={this.removeItem}

                                    changeLiasse={this.changeLiasse}
                                    keyBoolean={keyBoolean}
                                    setAmend={({
                                        target,
                                        name
                                    }) => {
                                        let obj = {};

                                        let found = _.findIndex(this.state.value, (elt) => {
                                            if (elt.id === e.id) {
                                                return elt;
                                            }
                                        });

                                        let value2 = this.state.value;

                                        value2[found].ammend = target.checked.toString();

                                        obj.value = value2;

                                        this.setState(obj, () => {
                                            // console.log(this.state.xyz,'YOUKAI FRIENDLY',this.state.value)
                                            this.setJointFilesList(this.state.value);
                                        });

                                    }}
                                    put={props.put}
                                    document={e.content}
                                    {...e}
                                    name={name}
                                    swap={this.swap}
                                    temp={temp}
                                    target={this.props.target}
                                    ticket={this.props.ticket}
                                />
                            );
                        })}
                    </ReactSortable></div>);
        } else {

            if (!this.state.value || !this.state.value.length) {
                return <></>;
            }
            return (
                <ReactSortable
                    key={props.name + 'hahahah'}
                    list={state.value}
                    setList={this.testFunction}
                    handle=".handle"
                    style={{ marginBottom: '15px' }}
                    onEnd={(evt) => {

                        this.swap(evt.oldIndex, evt.newIndex);
                    }}
                >
                    {_.map(state.value,
                        (e) => {
                            let name = e.encoded;
                            let keyBoolean = e.key;

                            let keyUpload = e.key.replace(BOOLEAN, UPLOAD_DOCUMENT);
                            let keyBucket = e.key.replace(BOOLEAN, BUCKET);
                            let keyBase = e.key.replace(BOOLEAN, '');
                            let temp = {};
                            temp[props.name + '_' + name + REAL_NAME] = e[props.name + '_' + name + REAL_NAME];

                            return (
                                <SortableElementSimple
                                    token={this.props.token}
                                    public={this.props.public}
                                    toggleLoading={this.props.toggleLoading}
                                    pushWaitingList={
                                        this.props.pushWaitingList
                                    }
                                    pullWaitingList={
                                        this.props.pullWaitingList
                                    }
                                    items={state.value}
                                    id={parseInt(e.id)}
                                    key={keyBase + '_' + counter++}
                                    keyBase={keyBase}
                                    keyUpload={keyUpload}
                                    removeItem={this.removeItem}
                                    keyBoolean={keyBoolean}
                                    put={props.put}
                                    pathGCloud={this.props.formData[keyUpload]}
                                    bucket={this.props.formData[keyBucket]}
                                    document={e.content}
                                    {...e}
                                    name={e.name}
                                    swap={this.swap}
                                    temp={temp}
                                    target={this.props.target}
                                    ticket={this.props.ticket}
                                    liasseList={this.state.value}
                                    setAmend={({
                                        target,
                                        name
                                    }) => {
                                        let obj = {};

                                        let found = _.findIndex(this.state.value, (elt) => {
                                            if (elt.id === e.id) {
                                                return elt;
                                            }
                                        });

                                        let value2 = this.state.value;

                                        value2[found].ammend = target.checked.toString();

                                        obj.value = value2;

                                        this.setState(obj, () => {
                                            this.setJointFilesList(this.state.value);
                                        });

                                    }}
                                />
                            );
                        })
                    }
                </ReactSortable>);
        }
    }
}
