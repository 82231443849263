import React, { Component } from 'react';
import { Card, CardBody, CardText, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { CheckInput } from './CheckInput';
import { DropContainer } from './DropContainer';
import { Address } from './Address';
import { Calendar } from './Calendar';

import moment from 'moment';

moment.locale('fr-CA');
const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;
const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class UserChildrenDivorce extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '', years;

        if (this.props.formData && this.props.formData[this.props.name + '_dateOfBirth']) {
            let a = moment();
            let b = moment(this.props.formData[this.props.name + '_dateOfBirth'], 'DD/MM/YYYY');

            let age = moment.duration(a.diff(b));
            years = age.years();
        }

        this.state = {
            lang: this.props.lang || 'fr',
            name: fd[this.props.name + '_name'] || '',
            firstname: fd[this.props.name + '_firstname'] || '',
            gender: this.props.formData[this.props.name + '_gender'],
            relation: this.props.formData[this.props.name + '_relation'],
            displayTribunal: this.props.formData[this.props.name + '_tribunalRestriction'],
            certificateLang: this.props.formData[this.props.name + '_birthCertificateLang'],
            parentingTimeGroup: (this.props.formData[this.props.name + '_parentingTimeGroup']) ?? '1',
            age: years,
            data: fd
        };
        this.genLang = this.genLang.bind(this);
    }

    genLang(key = 'missing') {
        const lang = (this.state.lang === '' || this.state.lang === null || this.state.lang === undefined) ? 'fr' : this.state.lang;

        if (typeof key === OBJECT) {
            if (lang && key[lang]) {
                return key[lang];
            } else {
                return '';
            }
        } else {
            if (!this.props.trad) {
                return key;
            }

            if (!this.props.trad[key]) {
                return this.props.trad['wrongKey'][lang] + ' ' + key;
            }

            return this.props.trad[key][lang];
        }

    }

    genLangNew(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevProps) {
        let props = this.props;
        // debugger;
        if (prevProps.formData && props.formData && props.version !== prevProps.version) {

            let obj = {};

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }

            if (props.formData[props.name + '_name'] !== prevProps.formData[props.name + '_name']) {
                obj['name'] = props.formData[props.name + '_name'] || '';
            }

            if (props.formData[props.name + '_firstname'] !== prevProps.formData[props.name + '_firstname']) {
                obj['firstname'] = props.formData[props.name + '_firstname'] || '';
            }

            if (props.formData[props.name + '_assistant'] !== prevProps.formData[props.name + '_assistant']) {
                obj['assistant'] = props.formData[props.name + '_assistant'] || '';
            }

            if (props.formData[props.name + '_gender'] !== prevProps.formData[props.name + '_gender']) {
                obj['gender'] = props.formData[props.name + '_gender'] || '';
            }

            if (props.formData[props.name + '_relation'] !== prevProps.formData[props.name + '_relation']) {
                obj['relation'] = props.formData[props.name + '_relation'] || '';
            }

            if (props.formData[props.name + '_tribunalRestriction'] !== prevProps.formData[props.name + '_tribunalRestriction']) {
                obj['displayTribunal'] = props.formData[props.name + '_tribunalRestriction'] || '';
            }

            if (props.formData[props.name + '_birthCertificateLang'] !== prevProps.formData[props.name + '_birthCertificateLang']) {
                obj['certificateLang'] = props.formData[props.name + '_birthCertificateLang'] || '';
            }

            if (props.formData[props.name + '_parentingTimeGroup'] !== prevProps.formData[props.name + '_parentingTimeGroup']) {
                obj['parentingTimeGroup'] = props.formData[props.name + '_parentingTimeGroup'] || '1';
            }

            if (prevProps.formData !== props.formData) {
                obj.data = this.props.formData;
            }

            if (!_.isEmpty(obj)) {
                this.setState(obj,
                    () => {
                        if (obj.data && props.formData[props.name + '_dateOfBirth']) {
                            this.isAdult(props.formData[props.name + '_dateOfBirth']);
                        }
                    });
            }
        }

        if (props.lang !== prevProps.lang) {

            let obj = {};

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }
            if (!_.isEmpty(obj)) {
                this.setState(obj);
            }
        }

    }

    isAdult(e) {
        var a = moment();
        var b = moment(e, 'DD/MM/YYYY');
        var age = moment.duration(a.diff(b));
        var years = age.years();
        this.setState({ age: years });
    }

    render() {
        const lang = this.state.lang;

        return (
            <Col md={this.props.col || 12}>
                <Card
                    style={{ marginBottom: '5px' }}
                >
                    {this.props.remove ? (
                        <span
                            className="remove"
                            onClick={() => this.props.remove(this.props.name)}
                        >
                  {cross}
                </span>
                    ) : (
                        ''
                    )}
                    <CardBody>
                        <CardText tag="div">
                            <Row>
                                <Col md={12}>
                                    <FormGroup tag="fieldset" className="!tw-mb-0">
                                        <legend>
                          <span>
                            {this.genLangNew(
                                this.props.title,
                                this.state.lang
                            )}
                          </span>
                                        </legend>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_name')}</Label>
                                        <Input
                                            type="text"
                                            name={this.props.name + '_name'}
                                            onChange={(e) =>
                                                this.setState({ name: e.target.value })
                                            }
                                            value={this.state.name}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_firstname')}</Label>
                                        <Input
                                            type="text"
                                            name={this.props.name + '_firstname'}
                                            onChange={(e) =>
                                                this.setState({ firstname: e.target.value })
                                            }
                                            value={this.state.firstname}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>{this.genLang('_title')}</Label>
                                        <Input
                                            type="select"
                                            name={this.props.name + '_gender'}
                                            onChange={(e) =>
                                                this.setState({ gender: e.target.value })
                                            }
                                            value={this.state.gender || '-'}
                                        >
                                            <option value="-">{this.genLang('_Autre')}</option>
                                            <option value="M">{'M'}</option>
                                            <option value="F">{'F'}</option>
                                            {/* <option value="NA" >Autre</option> */}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Calendar
                                    col={12}
                                    put={this.props.put}
                                    remove={this.props.remove}
                                    lang={this.state.lang}
                                    version={this.state.version}
                                    title={this.genLang('dateOfBirth')}
                                    key={this.props.name + '_dateOfBirth'}
                                    name={this.props.name + '_dateOfBirth'}
                                    callback={(e) => this.isAdult(e)}
                                    formData={{ ...this.state.data }}
                                />
                            </Row>
                            <div
                                style={{ display: this.props.forceBirthCertificate || (this.state.age !== undefined && this.state.age < 18) ? 'BLOCK' : 'NONE' }}>

                                <Row>
                                    <DropContainer
                                        removeFileFromGCloud={this.props.removeFileFromGCloud}
                                        wow="wow"
                                        public={this.props.public}
                                        multiple={false}
                                        maxFiles={1}
                                        col={12}
                                        name={this.props.name + '_birthCertificate'}
                                        title={
                                            'certificat de naissance'
                                        }
                                        ticket={this.props.ticket}
                                        toggleLoading={this.props.toggleLoading}
                                        target={this.props.target}
                                        lang={this.state.lang}
                                        version={this.state.version}
                                        key={this.props.formData + '_birthCertificate'}
                                        formData={this.state.data}
                                        put={this.props.put}
                                        remove={this.props.remove}
                                        token={this.props.token}
                                        pushWaitingList={this.props.pushWaitingList}
                                        pullWaitingList={this.props.pullWaitingList}
                                    />
                                </Row>

                                <Row>
                                    <CheckInput
                                        col={12}
                                        callback={(e) => {
                                            this.setState({
                                                certificateLang: e.target.checked.toString()
                                            });
                                        }}
                                        name={this.props.name + '_birthCertificateLang'}
                                        title={
                                            'Le certiticat est dans une autre langue que le fraçais ou l\'anglais'
                                        }
                                        formData={this.props.formData}
                                        put={this.props.put}
                                    />
                                </Row>
                                <div style={{ display: this.state.certificateLang === 'true' ? 'BLOCK' : 'NONE' }}>
                                    <Row>
                                        <DropContainer
                                            removeFileFromGCloud={this.props.removeFileFromGCloud}
                                            wow="wow"
                                            public={this.props.public}
                                            multiple={false}
                                            maxFiles={1}
                                            col={12}
                                            name={this.props.name + '_tradBirthCertificate'}
                                            title={
                                                'certificat de naissance traduit'
                                            }
                                            ticket={this.props.ticket}
                                            toggleLoading={this.props.toggleLoading}
                                            target={this.props.target}
                                            lang={this.state.lang}
                                            version={this.state.version}
                                            key={this.props.formData + '_tradBirthCertificate'}
                                            formData={this.state.data}
                                            put={this.props.put}
                                            remove={this.props.remove}
                                            token={this.props.token}
                                            pushWaitingList={this.props.pushWaitingList}
                                            pullWaitingList={this.props.pullWaitingList}
                                        />
                                    </Row>
                                </div>

                                <Row>
                                    <CheckInput
                                        col={12}
                                        callback={(e) => {
                                            this.setState({
                                                displayTribunal: e.target.checked.toString()
                                            });
                                        }}
                                        name={this.props.name + '_tribunalRestriction'}
                                        title={
                                            'Cet enfant fait l\'objet d\'un jugement passé ou en cours (DPJ)'
                                        }
                                        formData={this.props.formData}
                                        put={this.props.put}
                                    />
                                </Row>
                                <div style={{ display: this.state.displayTribunal === 'true' ? 'BLOCK' : 'NONE' }}>
                                    <Row>
                                        <DropContainer
                                            removeFileFromGCloud={this.props.removeFileFromGCloud}
                                            multiple={false}

                                            public={this.props.public}
                                            maxFiles={1}
                                            col={12}
                                            name={this.props.name + '_tribunalRestrictionDocument'}
                                            title={
                                                'Document(s) juridique(s) concerné(s)'
                                            }
                                            ticket={this.props.ticket}
                                            toggleLoading={this.props.toggleLoading}
                                            target={this.props.target}
                                            lang={this.state.lang}
                                            version={this.state.version}
                                            key={this.props.formData + '_tribunalRestrictionDocument'}
                                            formData={this.state.data}
                                            put={this.props.put}
                                            remove={this.props.remove}
                                            pushWaitingList={this.props.pushWaitingList}
                                            pullWaitingList={this.props.pullWaitingList}
                                            token={this.props.token}
                                        />
                                    </Row>
                                </div>
                            </div>
                            <div
                                style={{ display: this.state.age !== undefined && this.state.age >= 18 && !this.props.noAdultOptions ? 'BLOCK' : 'NONE' }}>
                                <Row>
                                    <CheckInput
                                        col={12}
                                        callback={(e) => {
                                        }}
                                        name={this.props.name + '_autonomous'}
                                        title="Cet enfant est autonome financièrement"
                                        formData={this.props.formData}
                                        put={this.props.put}
                                    />
                                </Row>
                                <Row>
                                    <CheckInput
                                        col={12}
                                        callback={(e) => {
                                        }}
                                        name={this.props.name + '_graduated'}
                                        title="Cet enfant a terminé ses études"
                                        formData={this.props.formData}
                                        put={this.props.put}
                                    />
                                </Row>

                                <Row>
                                    <CheckInput
                                        col={12}
                                        callback={() => {
                                        }}
                                        name={this.props.name + '_working'}
                                        title="Cet enfant travaille"
                                        formData={this.props.formData}
                                        put={this.props.put}
                                    />
                                </Row>
                                <Row>
                                    <Address
                                        col={12}
                                        put={this.props.put}
                                        lang={this.state.lang}
                                        trad={this.props.trad}
                                        formData={{ ...this.props.formData }}
                                        name={this.props.name}
                                        version={this.state.version}
                                        country={true}
                                    />
                                </Row>
                            </div>
                            {this.props.children ? this.props.children : ''}

                            <div
                                style={{ display: (this.props.parentingTimeGroup == true || this.props.parentingTimeGroup == 'true') ? 'BLOCK' : 'NONE' }}>
                                <hr />
                                <Row>

                                    <Col md={4}>
                                        <FormGroup>
                                            <Label><h5>{this.genLang('_parentingTimeSharing')}</h5></Label>
                                            <Input
                                                type="select"
                                                name={this.props.name + '_parentingTimeGroup'}
                                                onChange={(e) =>
                                                    this.setState({ parentingTimeGroup: e.target.value })
                                                }
                                                value={this.state.parentingTimeGroup || '1'}
                                            >
                                                <option value="1">{this.genLang('_time')} - 1</option>
                                                <option value="2">{this.genLang('_time')} - 2</option>
                                                <option value="3">{this.genLang('_time')} - 3</option>
                                                <option value="4">{this.genLang('_time')} - 4</option>
                                                <option value="5">{this.genLang('_time')} - 5</option>
                                                <option value="6">{this.genLang('_time')} - 6</option>

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </div>
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}
