import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import { useTranslation } from '@/composables/translation';

export function ScrollControls() {
    const { t } = useTranslation('common');
    const [atTop, setAtTop] = useState(true);

    const checkScrollPosition = useCallback(() => {
        const scrollTop = window.scrollY;
        setAtTop(scrollTop === 0);
    }, []);

    useEffect(() => {
        checkScrollPosition();
        window.addEventListener('scroll', checkScrollPosition);
        return () => {
            window.removeEventListener('scroll', checkScrollPosition);
        };
    }, [checkScrollPosition]);

    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const scrollToBottom = useCallback(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, []);

    const directions = useMemo(() => [
        ...(!atTop ? [{ icon: faArrowUp, action: scrollToTop, tooltip: t('scroll.up') }] : []),
        { icon: faArrowDown, action: scrollToBottom, tooltip: t('scroll.down') }
    ], [atTop, t, scrollToTop, scrollToBottom]);

    return (
        <div className="tw-fixed tw-left-2 tw-bottom-2 tw-flex tw-flex-col tw-gap-2">
            {directions.map(dir => (
                <Tooltip key={dir.tooltip}>
                    <TooltipTrigger asChild>
                        <Button
                            variant='outline'
                            size="icon"
                            className='tw-cursor-pointer !tw-rounded-full
                            tw-px-4 tw-flex tw-gap-3 tw-opacity-70
                            hover:!tw-opacity-100 print:tw-hidden'
                            onClick={dir.action}
                        >
                            <FontAwesomeIcon icon={dir.icon} size="lg"/>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent side='right'>{dir.tooltip}</TooltipContent>
                </Tooltip>))}
        </div>
    );
}
