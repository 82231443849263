import { useEffect, useMemo } from 'react';
import _ from 'lodash';

interface Options<F extends (...args: any[]) => any> {
    func: F;
    deps?: any[];
    timeout?: number;
}

export function useDebounced<F extends (...args: any[]) => any>(
    opts: Options<F>
): _.DebouncedFunc<F> {
    const fn = useMemo(
        () => _.debounce(opts.func, opts.timeout, { leading: false }),
        [opts.func]
    );
    useEffect(() => {
        fn.cancel();
    }, [fn]);
    return fn;
}
