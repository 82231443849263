import React, { Component } from 'react';

import { Col, FormGroup, Label, Input } from 'reactstrap';

import _ from 'lodash';

const DEFAULT_TITLE = '';
const DEFAULT_STYLE = { textAlign: 'left' };

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class RadioInput extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData;

        let value = this.props.default;

        if (fd && fd[this.props.name]) {
            value = fd[this.props.name];
        } else if (!value) value = '';

        this.props.put({
            params: {
                key: encodeURI(this.props.name),
                value: value,
                title: this.props.title,
            },
        });

        this.state = {
            value: value,
            display: this.props.defaultDisplay === false ? false : true,
            lang: this.props.lang || FR,
        };

        this.renderRadio = this.renderRadio.bind(this);
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            obj['value'] = props.formData[props.name] || props.default || [];
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            obj['value'] = props.formData[props.name] || this.props.default;
        }

        if (obj.value) {
            this.props.put({
                params: {
                    key: encodeURI(this.props.name),
                    value: obj.value,
                    title: this.props.title,
                },
            });
        }
        if (props.lang !== prevprops.lang) obj['lang'] = props.lang;
        // console.log(this.props.formData)

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (!_.isEmpty(obj)) this.setState(obj);
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    renderRadio() {
        let arr = [];
        let options = this.props.options;
        let props = this.props;

        for (let o in options) {
            let value = options[o].value;

            arr.push(
                <div className="tw-inline-flex" key={value}>
                    <Input
                        type="radio"
                        tabIndex={props.tabindex}
                        value={options[o].value || ''}
                        key={props.name + '_' + o}
                        id={props.name + '_' + o}
                        checked={this.state.value === value}
                        name={props.name}
                        onChange={(e) => {
                            this.setState(
                                {
                                    value: e.target.value,
                                },
                                () => {
                                    if (this.props.callback) {
                                        this.props.callback({
                                            value: this.state.value,
                                            name: props.name,
                                        });
                                    }
                                }
                            );
                        }}
                        className="tw-mr-2"
                        inline={(!props.inline).toString()}
                    />
                    <Label for={props.name + '_' + o}>
                        {this.genLang(options[o].label, this.state.lang)}
                    </Label>
                </div>
            );
        }

        return <div className="tw-flex tw-gap-6" key={props.name}>{arr}</div>;
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }
        return (
            <>
                <FormGroup>
                    <div className="radioList">{this.renderRadio(props)}</div>
                </FormGroup>
            </>
        );
    }
}
