import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import {
    getAppointmentSchedule,
    getCalendarAvailabilities,
    getCalendarConfig,
    getOrgGroups,
    getOrgUsers,
    postAppointmentUrl,
    postBookAppointment
} from '@/composables/api';
import { type User, type UserGroup } from '@/types/api/user';
import _ from 'lodash';
import {
    type CalendarAvailability,
    CalendarAvailabilityType,
    type CalendarConfig, type ScheduleTaken,
    type Type
} from '@/types/api/calendar';
import { useTranslation } from '@/composables/translation';
import { useQuery } from '@/composables/query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useError } from '@/composables/error';
import { faCalendarCheck, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonAsync } from '@/components/ui/button-async';
import { isIFrame, parseJSON, parseJWT } from '@/composables/utils';
import { useUserStore } from '@/store/user';
import { usePermissions } from '@/composables/permissions';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Col, Row } from '@/components/ui/row';
import { Combobox } from '@/components/ui/combobox';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { useGroups } from '@/composables/groups';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { CalendarIcon, Cross1Icon, Pencil2Icon } from '@radix-ui/react-icons';
import { DatePicker } from '@/components/ui/date-picker';
import { InputUrl } from '@/components/ui/input-url';
import { Feature, useFeatures } from '@/composables/features';
import {
    filterCalendarUsers,
    getScheduleIntervalTooltip,
    transformConfig,
    transformUsersConfigSchedule
} from '@/composables/calendar';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { type Taken, UpdateTakenDialog } from '@/components/calendar/UpdateTakenDialog';
import { type IntervalType } from '@/components/WeekSelection/WeekSelection';
import { useForceUpdate } from '@/composables/forceUpdate';

interface Query {
    title: string;
    description: string;
    where: string;
}

export function Booking() {
    const { t, to, ct } = useTranslation('booking');
    const navigate = useNavigate();
    const { hasPermissions } = usePermissions();
    const {
        token: currentToken,
        lang,
        setToken,
        setLang
    } = useUserStore(state => ({
        token: state.token,
        lang: state.lang,
        setToken: state.setToken,
        setLang: state.setLang
    }));
    const {
        ticket_id: param_ticket_id,
        token
    } =
        useParams() as { ticket_id: string; token: string };
    if (token && token !== currentToken) {
        setToken(token);
    }
    const query = useQuery<Query>();
    const params: any = {
        ticket_id: param_ticket_id,
        ...(!token ? query : undefined),
        ...(token ? parseJWT(token)?.scope : undefined)
    };
    const { handleNetworkError } = useError();
    const { isFeatureEnabled } = useFeatures();

    if (params.lang && lang !== params.lang) {
        setLang(params.lang);
    }

    const minDate = params.st_date ? DateTime.fromISO(params.st_date) : undefined;
    const maxDate = params.nd_date ? DateTime.fromISO(params.nd_date) : undefined;
    const now = DateTime.now();
    const initialDate = minDate ? (now < minDate ? minDate : now) : now;
    const globalService = useMemo<Type | undefined>(
        () => typeof params.global_service === 'string'
            ? parseJSON(params.global_service, undefined)
            : undefined,
        [params.global_service]
    );
    const [date, setDate] = useState<DateTime | null>(initialDate);
    const [selectedAvailability, setSelectedAvailability] = useState<CalendarAvailability | null>();
    const [users, setUsers] = useState<User[]>([]);
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const [config, setConfig] = useState<CalendarConfig>();
    const [availabilities, setAvailabilities] =
        useState<Record<string, CalendarAvailability[]>>({});
    const [intervalTypes, setIntervalTypes] = useState<IntervalType[]>([]);
    const [ticketSchedule, setTicketSchedule] = useState<ScheduleTaken[]>([]);
    const [ticketTaken, setTicketTaken] = useState<Taken>();
    const [ticketTakenOpen, setTicketTakenOpen] = useState(false);
    const [forceUpdate, counter] = useForceUpdate();
    const { groupBy } = useGroups({ groups });

    const [loading, setLoading] = useState(false);
    const [timesLoading, setTimesLoading] = useState(false);

    const [domain, setDomain] = useState<string | null>(
        params.domain_id ?? params.domain?.id ?? null
    );
    const [type, setType] = useState<string | null>(
        params.service_id ?? params.domain?.type?.id ?? null
    );
    const [category, setCategory] = useState(
        params.category ?? params.domain?.type?.category ?? null
    );
    const [user, setUser] = useState<string | null>(
        params.user_id ?? params?.domain?.users?.[0] ?? null
    );
    const [useUser, setUseUser] = useState(!!user);
    const [specialties, setSpecialties] =
        useState<string[] | null>(
            typeof params.groups === 'string'
                ? parseJSON(params.groups, [])
                : Array.isArray(params.groups)
                    ? params.groups
                    : params?.domain?.groups ?? []
        );
    const [duration, setDuration] = useState<number | null>(
        params.duration
            ? Number(params.duration)
            : params?.domain?.duration
                ? Number(params.domain.duration)
                : 15
    );
    const [description, setDescription] = useState<string>(params.description ?? '');
    const [where, setWhere] = useState<string>(params.where ?? 'Montréal');
    const [link, setLink] = useState<string>('');
    const [clients] = useState(
        typeof params.clients === 'string'
            ? parseJSON(params.clients, [])
            : Array.isArray(params.clients)
                ? params.clients
                : []
    );
    const [excludeUsers] = useState(
        typeof params.exclude_users === 'string'
            ? parseJSON(params.exclude_users, [])
            : Array.isArray(params.exclude_users)
                ? params.exclude_users
                : []
    );

    const categoryList = useMemo(
        () => config?.categories ?? [],
        [config]
    );
    const domainList = useMemo(
        () => _.uniqBy(config?.domains ?? [], (d) => d._id),
        [config]
    );
    const domainObj = useMemo(
        () => domainList.find(d => d._id === domain),
        [domain, domainList]
    );
    const typeList = useMemo(
        () => getTypeList(domain),
        [config, globalService, domain]
    );
    const typeObj = useMemo(
        () => typeList?.find(t => t._id === type),
        [type, typeList]
    );
    const durationList = useMemo(
        () => (config?.durations ?? [])
            .sort((a, b) => a.value - b.value),
        [config]
    );
    const filteredUserList = useMemo(
        () => users.filter((u) =>
            specialties && specialties.length > 0 &&
            specialties.every(s => u.groups.find(g => g._id.$oid === s))
        ),
        [specialties, users]
    );
    const specialtyError = !useUser && filteredUserList.length <= 0;
    const title = useMemo(
        () =>
            `${domainObj?.title ? `${to(domainObj?.title)} — ` : ''}${
                to(params.title || typeObj?.title)
            }`,
        [domainObj, typeObj, params.title]
    );

    const selectedDate = date ?? DateTime.now().startOf('day');
    const monthStartDate = selectedDate.startOf('month').startOf('week');
    const monthEndDate = selectedDate.endOf('month')
        .startOf('week')
        .plus({ weeks: 1 })
        .minus({ second: 1 });
    const weekStartDate = selectedDate.startOf('week');
    const weekEndDate = weekStartDate.plus({ weeks: 1 }).minus({ second: 1 });
    const weekAvailabilities = useMemo(
        () => [...Array(7)].map((_, i) => {
            const day = weekStartDate.plus({ days: i });
            const list = availabilities[day.toFormat('yyyy-MM-dd')]
                ?.filter((a) => DateTime.fromISO(a.start) > DateTime.now()) ?? [];
            if (!token && typeObj?.type === CalendarAvailabilityType.UNAVAILABLE && hasPermissions()) {
                list.unshift(
                    {
                        type: CalendarAvailabilityType.UNAVAILABLE,
                        user: user ?? '',
                        busy: 0,
                        busyservice: {},
                        start: day.toISO({ suppressMilliseconds: true }),
                        end: day.plus({ minutes: 15 }).toISO({ suppressMilliseconds: true }),
                        duration: 15,
                        label: 'AM',
                        danger: true,
                        multi: true
                    },
                    {
                        type: CalendarAvailabilityType.UNAVAILABLE,
                        user: user ?? '',
                        busy: 0,
                        busyservice: {},
                        start: day.plus({ hours: 12 }).toISO({ suppressMilliseconds: true }),
                        end: day.plus({ hours: 12, minutes: 15 }).toISO({ suppressMilliseconds: true }),
                        duration: 15,
                        label: 'PM',
                        danger: true,
                        multi: true
                    },
                    {
                        type: CalendarAvailabilityType.UNAVAILABLE,
                        user: user ?? '',
                        busy: 0,
                        busyservice: {},
                        start: day.plus({ hours: 16, minutes: 30 }).toISO({ suppressMilliseconds: true }),
                        end: day.plus({ hours: 16, minutes: 45 }).toISO({ suppressMilliseconds: true }),
                        duration: 15,
                        label: 'EV',
                        danger: true,
                        multi: true
                    }
                );
            }
            return {
                day,
                availabilities: list.filter((a) => DateTime.fromISO(a.start) > DateTime.now())
            };
        }),
        [typeObj, weekStartDate.toISO(), availabilities]
    );
    const weekAvailabilitiesFlat = useMemo(
        () => weekAvailabilities.flatMap(({ availabilities }) => availabilities),
        [weekAvailabilities]
    );

    function getTypeList(domain_id: string | null) {
        const domainObj = domainList.find(d => d._id === domain_id);
        return _.uniqBy(
            [
                ...(domainObj?.types ?? []),
                ...(globalService ? [globalService] : []),
                ...(config?.global_types ?? [])
            ],
            t => t._id
        );
    }

    function getAppointmentPayload() {
        return {
            domain: {
                id: domain,
                groups: specialties,
                users: (useUser && user) ? [user] : undefined,
                type: {
                    id: type,
                    type: typeObj?.type ?? params.domain?.type?.type,
                    category,
                    duration,
                    max_book: typeObj?.max_book ?? params.domain?.type?.max_book
                }
            },
            exclude_users: excludeUsers,
            search_interval: {
                start: monthStartDate.toISO({ suppressMilliseconds: true }),
                end: monthEndDate.toISO({ suppressMilliseconds: true })
            }
        };
    }

    function handleBookAppointment() {
        if (selectedAvailability == null) {
            return;
        }
        setLoading(true);
        const userObj = users.find(u => u.id.$oid === selectedAvailability?.user);
        const dataObj = {
            timezone: DateTime.local().zoneName,
            metadata: {
                client: navigator.userAgent
            },
            users: [{
                id: selectedAvailability?.user ?? '',
                external_id: userObj?.external_id ?? undefined,
                email: userObj?.username,
                role: userObj?.role,
                name: userObj ? `${userObj.firstname} ${userObj.lastname}` : undefined
            }],
            domain_id: domain ?? undefined,
            service_id: type ?? undefined,
            category: category ?? 1,
            service: typeObj?.title ? to(typeObj?.title) : params.service,
            type: selectedAvailability?.type,
            title,
            description,
            where,
            start: selectedAvailability.start,
            end: selectedAvailability.end,
            duration: selectedAvailability.duration,
            multi: selectedAvailability.multi,
            ticket_id: params.ticket_id,
            case_id: isFeatureEnabled(Feature.PSJ) ? params.case_id : undefined,
            clients
        };
        return postBookAppointment(dataObj, !!token)
            .then(() => {
                if (token) {
                    navigate('/thanks');
                } else {
                    const user = users.find(u => u.id.$oid === selectedAvailability.user);
                    toast(
                        <>
                            {t('messages.success')} <b><u>{user?.firstname} {user?.lastname}</u></b>
                        </>,
                        {
                            type: 'success',
                            autoClose: false
                        }
                    );
                }
                if (isIFrame()) {
                    window.parent.postMessage(JSON.stringify({ ...dataObj, name: 'booking' }), '*');
                }
            })
            .catch(handleNetworkError)
            .finally(() => setLoading(false));
    }

    function handleGenerateUrl() {
        setLoading(true);
        return postAppointmentUrl({
            ...getAppointmentPayload(),
            type: typeObj?.type,
            title,
            description,
            where,
            clients,
            ticket_id: params.ticket_id,
            case_id: isFeatureEnabled(Feature.PSJ) ? params.case_id : undefined,
            service: typeObj?.title ? to(typeObj?.title) : params.service,
            service_id: type ?? undefined,
            domain_id: domain ?? undefined,
            category: category ?? 1
        })
            .then((res) => {
                setLink(res.data.link);
                toast(t('messages.link-generated'), { type: 'success' });
                if (isIFrame()) {
                    window.parent.postMessage(JSON.stringify({
                        name: 'link',
                        link: res.data.link
                    }), '*');
                }
            })
            .catch(handleNetworkError)
            .finally(() => setLoading(false));
    }

    function handleSelectDomain(value: string | null) {
        setDomain(value);
        const domain = domainList.find(d => d._id === value);
        if (domain?.groups) {
            setSpecialties(domain.groups);
        }
        if (domain?.types?.[0]?._id) {
            handleSelectType(domain.types[0]._id, domain._id);
        } else if (value == null) {
            const typeList = getTypeList(null);
            handleSelectType(typeList?.[0]?._id ?? null, null);
        }
    }

    function handleSelectType(value: string | null, domain_id: string | null) {
        setType(value);
        const typeList = getTypeList(domain_id);
        const type = typeList.find(t => t._id === value);
        if (type) {
            setCategory(type.category);
            setDuration(Number(type.duration ?? 15));
            if (type.type !== CalendarAvailabilityType.AVAILABLE) {
                setUseUser(true);
            }
        }
    }

    useEffect(() => {
        if (token) {
            return;
        }
        setLoading(true);
        Promise.all([
            getOrgUsers(),
            getOrgGroups(),
            getCalendarConfig()
        ])
            .then(([users, groups, config]) => {
                setUsers(filterCalendarUsers(users.data));
                setGroups(groups.data);
                setConfig(transformConfig(config.data));
                setIntervalTypes(transformUsersConfigSchedule(users.data, config.data));
                let newDomain = domain;
                let newType = type;
                if (params.domain_id && !params.service_id) {
                    const domain = config.data.domains.find(d => d._id === params.domain_id);
                    newDomain = domain?._id ?? null;
                    newType = domain?.types?.[0]?._id ?? null;
                } else if (!params.service_id) {
                    if (params.domain_id) {
                        const domain = config.data.domains.find(d => d._id === params.domain_id);
                        newType = domain?.types?.[0]?._id ?? null;
                    } else {
                        newType = config.data.global_types?.[0]?._id ?? null;
                    }
                }
                if (newDomain !== domain) {
                    setDomain(newDomain);
                }
                if (newType !== type) {
                    setType(newType);
                }
                const dom = config.data.domains.find(d => d._id === newDomain);
                const ty = dom?.types.concat(config.data.global_types ?? []).find(t => t._id === newType);
                if (ty && !params.category) {
                    setCategory(ty.category);
                } else if (!params.category) {
                    setCategory(config.data.categories?.[0]?.id ?? null);
                }
                if (ty && !params.duration && ty.duration != null) {
                    setDuration(Number(ty.duration));
                } else if (!params.duration) {
                    setDuration(config.data.durations?.[0]?.value ?? 15);
                }
                if (params.external_user_id) {
                    const u = users.data.find(u => u.external_id === params.external_user_id);
                    if (u) {
                        setUser(u.id.$oid);
                        setUseUser(true);
                    }
                }
                if (!params.groups || params.groups.length <= 0) {
                    const domainObj = config.data.domains.find(d => d._id === newDomain);
                    if (domainObj) {
                        setSpecialties(domainObj.groups);
                    }
                }
            })
            .catch(handleNetworkError)
            .finally(() => setLoading(false));
    }, []);

    useEffect(
        () => {
            if (!token && (
                (!useUser && filteredUserList.length <= 0) ||
                (useUser && !user)
            )) {
                setAvailabilities({});
                return;
            }
            if ((!token && !config) || !type) {
                return;
            }
            setTimesLoading(true);
            getCalendarAvailabilities(getAppointmentPayload(), token)
                .then(res => {
                    res.data.availabilities
                        .sort((a, b) =>
                            (DateTime.fromISO(a.start) as unknown as number) -
                        (DateTime.fromISO(b.start) as unknown as number)
                        );
                    const data = _.groupBy(
                        res.data.availabilities,
                        a => DateTime.fromISO(a.start).toFormat('yyyy-MM-dd')
                    );
                    setAvailabilities(data);
                })
                .catch((err) => {
                    if (token) {
                        navigate('/not-found');
                    } else {
                        handleNetworkError(err);
                    }
                    setAvailabilities({});
                })
                .finally(() => setTimesLoading(false));
        },
        [
            config,
            domain,
            specialties,
            user,
            useUser,
            type,
            category,
            duration,
            monthStartDate.toISODate()
        ]
    );

    useEffect(() => {
        if (!params.ticket_id || token) {
            return;
        }
        getAppointmentSchedule({
            ticket_id: params.ticket_id
        })
            .then((res) => {
                setTicketSchedule(res.data.takens.filter(t => DateTime.fromISO(t.start) > DateTime.now()));
            })
            .catch(handleNetworkError);
    }, [params.ticket_id, counter]);

    return (
        <Card className={cn('tw-w-full tw-max-w-6xl sm:tw-rounded-none', token && 'tw-max-w-3xl')}>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <Row className="tw-mb-3 md:tw-mb-5">
                    <Col col={6} className={cn(token && 'tw-hidden')}>
                        <section>
                            {ticketSchedule.map(taken => (
                                <div key={taken._id.$oid} className="tw-flex tw-items-center tw-gap-2">
                                    <div className="tw-inline-flex tw-items-center">
                                        <CalendarIcon className="tw-mr-2 tw-size-[1.1rem]" />
                                        <span>
                                            {DateTime.fromISO(taken.start).toFormat('ff')}
                                            {' '}&mdash;{' '}
                                            {taken.title}
                                            {' '}&mdash;{' '}
                                            {taken.users?.[0]?.name}
                                        </span>
                                    </div>
                                    <div className="tw-ml-auto tw-flex tw-items-center tw-gap-1">
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Button
                                                    variant="ghost" size="icon"
                                                    onClick={() => {
                                                        setTicketTaken({
                                                            start_date: DateTime.fromISO(taken.start),
                                                            duration: taken.duration,
                                                            interval_user_id: taken.users[0].id.$oid,
                                                            interval_id: taken._id.$oid,
                                                            ticket_id: taken.ticket_id ?? '',
                                                            title: taken.title ?? '',
                                                            description: taken.description ?? '',
                                                            location: taken.where ?? ''
                                                        });
                                                        setTicketTakenOpen(true);
                                                    }}
                                                >
                                                    <Pencil2Icon />
                                                </Button>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                {ct('edit')}
                                            </TooltipContent>
                                        </Tooltip>
                                    </div>
                                </div>
                            ))}
                        </section>
                        <section className="tw-flex tw-flex-col tw-gap-2">
                            <div>
                                <Label htmlFor="domain">
                                    {t('fields.domain')}
                                </Label>
                                <Combobox
                                    id="domain"
                                    options={domainList}
                                    getOptionValue={opt => opt._id}
                                    getOptionLabel={opt => to(opt.title)}
                                    value={domain}
                                    onChange={handleSelectDomain}
                                    disabled={loading}
                                    clearable
                                />
                            </div>
                            <div>
                                <Label htmlFor="specialties">
                                    {t('fields.specialties')}
                                </Label>
                                <Combobox<UserGroup, string>
                                    id="specialties"
                                    options={groups}
                                    multiple
                                    getOptionValue={opt => opt._id.$oid}
                                    getOptionLabel={opt => to(opt.name)}
                                    groupBy={groupBy}
                                    value={specialties}
                                    onChange={setSpecialties}
                                    error={specialtyError}
                                    disabled={loading || useUser}
                                />
                                {specialtyError && <p className="tw-text-xs tw-text-destructive">
                                    {t('errors.no-users-found')}
                                </p>}
                            </div>
                            <div className={cn(
                                'tw-flex tw-flex-col tw-gap-1 tw-py-1'
                            )}>
                                <div className="tw-flex tw-items-center tw-gap-2 tw-mb-1">
                                    <Checkbox
                                        id="use-user"
                                        disabled={loading}
                                        checked={useUser}
                                        onCheckedChange={(checked) => setUseUser(Boolean(checked))}
                                    />
                                    <Label htmlFor="use-user">
                                        {t('fields.use-user')}
                                    </Label>
                                </div>
                                <div>
                                    <Combobox
                                        id="user"
                                        options={users}
                                        getOptionValue={opt => opt.id.$oid}
                                        getOptionLabel={opt => `${opt.firstname} ${opt.lastname}`}
                                        value={user}
                                        onChange={setUser}
                                        disabled={loading || !useUser}
                                    />
                                </div>
                            </div>
                            <div>
                                <Label htmlFor="type">{t('fields.service')}</Label>
                                <Combobox<Type, string>
                                    id="type"
                                    options={typeList}
                                    getOptionValue={opt => opt._id}
                                    getOptionLabel={opt => to(opt.title)}
                                    value={type}
                                    onChange={(value) => handleSelectType(value, domain)}
                                    disabled={loading}
                                />
                            </div>
                            <div>
                                <Label htmlFor="category">{t('fields.category')}</Label>
                                <Combobox
                                    id="category"
                                    options={categoryList}
                                    getOptionValue={opt => opt.id}
                                    getOptionLabel={opt => to(opt.name)}
                                    value={category}
                                    onChange={setCategory}
                                    disabled={loading}
                                />
                            </div>
                            <div>
                                <Label htmlFor="duration">{t('fields.duration')}</Label>
                                <Combobox
                                    id="duration"
                                    options={durationList}
                                    getOptionValue={opt => opt.value}
                                    getOptionLabel={opt => to(opt.label)}
                                    value={duration}
                                    onChange={setDuration}
                                    disabled={loading}
                                />
                            </div>
                            <div>
                                <Label htmlFor="location">{t('fields.location')}</Label>
                                <Input
                                    id="location"
                                    disabled={loading}
                                    value={where}
                                    onChange={(e) => setWhere(e.target.value)}
                                />
                            </div>
                            <div>
                                <Label htmlFor="description">{t('fields.description')}</Label>
                                <Textarea
                                    id="description"
                                    disabled={loading}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                            {link &&
                                <div>
                                    <Label htmlFor="url">URL</Label>
                                    <InputUrl
                                        id="url"
                                        value={link}
                                    />
                                </div>
                            }
                        </section>
                    </Col>
                    <Col col={token ? 12 : 6} className="tw-flex-1 tw-flex tw-flex-col tw-gap-3 md:tw-max-h-[580px]">
                        <div>
                            <Label htmlFor="date">{t('fields.week')}</Label>
                            <DatePicker
                                id="date"
                                value={date}
                                onChange={setDate}
                                calendarProps={{
                                    fromDate: minDate?.toJSDate() ?? new Date(),
                                    toDate: maxDate?.toJSDate()
                                }}
                            />
                        </div>
                        <h5 className="tw-mb-2 tw-font-semibold">
                            {weekStartDate.toFormat('DDD')} &mdash; {weekEndDate.toFormat('DDD')}
                        </h5>
                        {timesLoading
                            ? <div className={cn(
                                'tw-flex-1 tw-flex tw-flex-col tw-p-6',
                                'tw-justify-center tw-items-center'
                            )}>
                                <Spinner size="lg" className="tw-text-primary"/>
                            </div>
                            : weekAvailabilitiesFlat.length > 0
                                ? <>
                                    <div
                                        className={cn(
                                            'tw-relative tw-grid tw-grid-cols-[repeat(7,_minmax(50px,_1fr))]',
                                            'tw-grid-rows-[auto_1fr] tw-flex-1 tw-overflow-auto tw-px-2 tw-gap-x-2',
                                            'tw-max-h-[450px] md:tw-max-h-none'
                                        )}
                                    >
                                        <div className={cn(
                                            'tw-grid tw-col-span-7 tw-grid-cols-subgrid',
                                            'tw-sticky tw-top-0 tw-w-full tw-mb-2 tw-bg-card',
                                            'tw-gap-x-2 tw-pb-1 tw-border-b tw-border-input'
                                        )}>
                                            {weekAvailabilities.map(({ day }, i) => (
                                                <div
                                                    key={i}
                                                    className={cn(
                                                        'tw-text-center',
                                                        day.equals(selectedDate) && 'tw-text-primary tw-font-bold'
                                                    )}
                                                >
                                                    {day.toFormat('EEEEE d')}
                                                </div>
                                            ))}
                                        </div>
                                        {weekAvailabilities.map(({ day, availabilities }) => (
                                            <div
                                                key={day.toFormat('yyyy-MM-dd')}
                                                className={cn('tw-flex tw-flex-col tw-gap-1')}
                                            >
                                                {availabilities.map((a, i) => {
                                                    const isSelected = _.isEqual(a, selectedAvailability);
                                                    const isAvailable = a.type === CalendarAvailabilityType.AVAILABLE;
                                                    const isDestructive = a.danger ?? !isAvailable;
                                                    // TODO: Make this logic more generic
                                                    const user = users.find(u => u.id.$oid === a.user);
                                                    const userName = user
                                                        ? `${user.firstname} ${user.lastname}`
                                                        : undefined;
                                                    const isExternalUser = user?.role === 'elawyer';
                                                    const variant = !isSelected
                                                        ? 'outline'
                                                        : isDestructive
                                                            ? 'destructive'
                                                            : 'default';
                                                    const button = (<Button
                                                        key={i}
                                                        variant={variant}
                                                        size="sm"
                                                        className={cn(
                                                            !isSelected && isDestructive &&
                                                            '!tw-border-destructive tw-text-destructive ' +
                                                            'hover:tw-text-destructive',
                                                            !isSelected && !isDestructive &&
                                                            '!tw-border-primary tw-text-primary ' +
                                                            'hover:tw-text-primary',
                                                            !isSelected && !isDestructive &&
                                                            isExternalUser &&
                                                            '!tw-border-zinc-950 tw-text-zinc-950 ' +
                                                            'hover:tw-text-zinc-950 hover:tw-bg-zinc-200',
                                                            isSelected && !isDestructive &&
                                                            isExternalUser &&
                                                            'tw-bg-zinc-950 hover:tw-bg-zinc-700'
                                                        )}
                                                        onClick={() =>
                                                            setSelectedAvailability(isSelected
                                                                ? null
                                                                : a)
                                                        }
                                                    >
                                                        {
                                                            a.label ??
                                                            DateTime.fromISO(a.start).toFormat('HH:mm')
                                                        }
                                                    </Button>);
                                                    return (
                                                        userName
                                                            ? <Tooltip key={i}>
                                                                <TooltipTrigger asChild>
                                                                    {button}
                                                                </TooltipTrigger>
                                                                <TooltipContent>
                                                                    {user?.firstname} {user?.lastname}
                                                                </TooltipContent>
                                                            </Tooltip>
                                                            : button
                                                    );
                                                })}
                                                {availabilities.length <= 0 &&
                                                    <div className={cn(
                                                        'tw-h-8 tw-rounded-md tw-text-slate-300',
                                                        'tw-border-2 tw-border-dashed tw-border-slate-300',
                                                        'tw-flex tw-justify-center tw-items-center'
                                                    )}>
                                                        <Cross1Icon />
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </>
                                : <div className={cn(
                                    'tw-flex-1 tw-flex tw-flex-col tw-p-6',
                                    'tw-justify-center tw-items-center'
                                )}>
                                    <i>{t('no-availability')}</i>
                                </div>
                        }
                    </Col>
                </Row>
            </CardContent>
            <CardFooter className="tw-flex tw-justify-end tw-gap-2 tw-flex-wrap">
                {!token && <ButtonAsync
                    className="btn btn-outline-primary"
                    disabled={loading}
                    onClick={handleGenerateUrl}
                >
                    <FontAwesomeIcon
                        className="tw-mr-2"
                        icon={faLink}
                    />
                    {t('generate-link')}
                </ButtonAsync>}
                <ButtonAsync
                    className="btn btn-primary"
                    disabled={loading || selectedAvailability == null}
                    onClick={handleBookAppointment}
                >
                    <FontAwesomeIcon
                        className="tw-mr-2"
                        icon={faCalendarCheck}
                    />
                    {t('book')}
                </ButtonAsync>
            </CardFooter>
            <UpdateTakenDialog
                config={config}
                intervalTypes={intervalTypes}
                getIntervalTooltip={(t) => getScheduleIntervalTooltip(users, t)}
                value={ticketTaken}
                open={ticketTakenOpen}
                onOpenChange={(value) => {
                    setTicketTakenOpen(value);
                    forceUpdate();
                }}
            />
        </Card>
    );
}
