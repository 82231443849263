import React, { Component } from 'react';
import DateTimePicker from 'react-date-picker';
import { Col, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import { RequestThatField } from './RequestThatField';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const INVALID_DATE = 'Invalid Date';
const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const DEFAULT_STYLE = {
    textAlign: 'left'
};

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const DEFAULT_FORMAT = 'DD/MM/YYYY';
moment.locale('fr');
let m = moment().year();

let date = new Date().toISOString();
const years = [
    <option key={date + '_date' + m} value={m + 1}>
        {m + 1}
    </option>,
];

for (let i = 100; m--; i--) {
    if (i === 100) {
        years.push(
            <option key={date + '_date' + m} value={m}>
                {m}
            </option>
        );
    } else {
        years.push(
            <option value={m} key={date + '_date' + m}>
                {m}
            </option>
        );
    }
}

export class Calendar extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData;

        let value;

        if (fd && fd[this.props.name]) {
            value = new Date(
                moment(fd[this.props.name], this.props.format || DEFAULT_FORMAT)
                    .utc()
                    .format()
            );
        } else {
            value = new Date();
            this.props.put({
                params: {
                    key: this.props.name,
                    value: moment(value, this.props.format || DEFAULT_FORMAT),
                    title: this.props.title,
                },
            });
        }

        this.state = {
            value: value,
            lang: this.props.lang || FR,
            placeholder: this.props.placeholder
                ? this.genLang(props.placeholder, props.lang)
                : '',
            focused: false,
            display: this.props.defaultDisplay === false ? false : true,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};
        let fd = this.props.formData || [];

        if (props.display !== prevprops.display) {
            if (props.display === false && prevprops.display === true) {
                obj.display = false;
            } else if (props.display === true && prevprops.display === false) {
                obj.display = true;
            } else if (prevprops.display === undefined) {
                obj.display = props.display;
            }
        }
        if (props.version !== prevprops.version) {
            if (fd && fd[this.props.name]) {
                obj['value'] = new Date(
                    moment(fd[this.props.name], this.props.format || DEFAULT_FORMAT)
                        .utc()
                        .format()
                );
            } else {
                obj['value'] = new Date();
            }

            this.props.put({
                params: {
                    key: this.props.name,
                    value: moment(
                        obj['value'],
                        this.props.format || DEFAULT_FORMAT
                    ).format(this.props.format || DEFAULT_FORMAT),
                    title: this.props.title,
                },
            });
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            if (fd && fd[this.props.name]) {
                obj['value'] = new Date(
                    moment(fd[this.props.name], this.props.format || DEFAULT_FORMAT)
                        .utc()
                        .format()
                );
            } else {
                obj['value'] = new Date();
            }

            this.props.put({
                params: {
                    key: this.props.name,
                    value: moment(
                        obj['value'],
                        this.props.format || DEFAULT_FORMAT
                    ).format(this.props.format || DEFAULT_FORMAT),
                    title: this.props.title,
                },
            });
        }

        if (props.placeholder !== prevprops.placeholder) {
            obj['placeholder'] = props.placeholder;
        }

        if (props.lang !== prevprops.lang) obj['lang'] = props.lang;

        if (!_.isEmpty(obj)) this.setState(obj);
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    onChange(e) {
        this.setState(
            {
                value: e,
            },
            () => {
                if (!this.state.value || !moment(this.state.value).isValid()) {
                    this.props.remove({
                        params: {
                            key: this.props.name,
                        },
                    });
                } else {
                    this.props.put({
                        params: {
                            key: this.props.name,
                            value: moment(this.state.value)
                                .utc()
                                .format(this.props.format || DEFAULT_FORMAT),
                            title: this.props.title,
                        },
                    });
                }

                if (this.props.callback) this.props.callback(e);
            }
        );
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        // toki cest temps en japonais ^^
        let toki = this.state.value;

        if (new Date(toki) === INVALID_DATE) {
            toki = null;
            console.log('would be tokiin but not really');
        }

        return (
            <>
                <FormGroup>
                    <DateTimePicker
                        id={this.props.name}
                        name={this.props.name}
                        style={{ zIndex: 999999999 }}
                        onChange={this.onChange}
                        locale={'fr'}
                        value={
                            moment(this.state.value).isValid() ? this.state.value : undefined
                        }
                    />
                </FormGroup>
            </>
        );
    }
}
