import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Col, Row } from '@/components/ui/row';
import { Alert } from '@/components/ui/alert';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { DateTime, Interval } from 'luxon';
import {
    type IntervalObject,
    type IntervalType,
    type TypeID,
    WeekSelection
} from '@/components/WeekSelection/WeekSelection';
import { Button } from '@/components/ui/button';
import { ConfirmDialogButton } from '@/components/ConfirmDialogButton';
import { useTranslation } from '@/composables/translation';
import { useForm } from 'react-hook-form';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { type Duration, type CalendarConfig, type ScheduleTaken } from '@/types/api/calendar';
import { DatePicker } from '@/components/ui/date-picker';
import { Combobox } from '@/components/ui/combobox';
import { Textarea } from '@/components/ui/textarea';
import { deleteAppointment, getAppointmentSchedule, postUpdateAppointment } from '@/composables/api';
import { toast } from 'react-toastify';
import { useError } from '@/composables/error';
import { TimePicker } from '@/components/ui/time-picker';
import { transformCalendarWeek } from '@/composables/calendar';
import { cn } from '@/lib/utils';
import { ExternalLinkIcon } from '@radix-ui/react-icons';
import { Link } from 'react-router-dom';
import { Permission, usePermissions } from '@/composables/permissions';

export interface Taken {
    ticket_id: string;
    interval_id: string;
    interval_user_id: string;
    start_date: DateTime | null;
    duration: number;
    title: string;
    description: string;
    location: string;
}

interface Props {
    config?: CalendarConfig;
    intervalTypes: IntervalType[];
    getIntervalTooltip: (taken: ScheduleTaken) => string;
    open?: boolean;
    onOpenChange?: React.Dispatch<boolean>;
    value?: Taken;
}

export function UpdateTakenDialog(props: Props) {
    const { t, ct } = useTranslation('schedule');
    const { handleNetworkError } = useError();
    const { hasPermissions } = usePermissions();
    const form = useForm<Taken>({
        defaultValues: props.value
    });

    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [schedule, setSchedule] = useState<IntervalObject[][]>([]);
    const interval = useMemo(
        () => schedule.flat().find(i => i.id === props.value?.interval_id),
        [props.value, schedule]
    );
    const showWarningEdit = useMemo(
        () => {
            if (!interval) {
                return false;
            }
            const start = form.getValues('start_date');
            let editIntervalDayIndex = interval.interval.start
                ?.diff(start?.startOf('week') ?? DateTime.now().startOf('week'), 'days').days;
            editIntervalDayIndex = editIntervalDayIndex ? Math.floor(editIntervalDayIndex) : -1;
            return schedule[editIntervalDayIndex]
                ?.filter(i => i.type === form.getValues('interval_user_id') && i.id !== form.getValues('interval_id'))
                ?.some(i => i.interval.overlaps(interval.interval));
        },
        [interval, schedule]
    );

    const durations = props.config?.durations ?? [];
    const startDate = form.watch('start_date');
    const externalUrl = props.config?.org_settings?.external_folder_url && props.value?.ticket_id
        ? props.config.org_settings.external_folder_url.replace('{folder_id}', props.value.ticket_id)
        : undefined;

    useEffect(() => {
        if (props.value) {
            form.reset(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if (!startDate) {
            return;
        }
        setLoading(true);
        getAppointmentSchedule({
            start_date: startDate,
            end_date: startDate.plus({ day: 1 }).minus({ second: 1 }),
            ...(props.value?.interval_user_id && { users: [props.value.interval_user_id] })
        }).then((res) => {
            const schedule = transformCalendarWeek(
                startDate.startOf('week'),
                res.data.takens,
                t => ({
                    id: t._id.$oid,
                    type: t.users[0]?.id?.$oid,
                    variant: t.service_id,
                    label: t.title,
                    tooltip: props.getIntervalTooltip(t),
                    zIndex: t.holiday ? -1 : 0
                })
            );
            let interval = schedule.flat().find(i => i.id === form.getValues('interval_id'));
            if (interval) {
                interval.class = '!tw-border-dashed !tw-border-4';
                interval.interval = Interval.fromDateTimes(
                    startDate, startDate.plus({ minutes: form.getValues('duration') })
                );
            } else {
                interval = {
                    type: form.getValues('interval_user_id') as TypeID,
                    variant: form.getValues('interval_id') as TypeID,
                    interval: Interval.fromDateTimes(
                        startDate, startDate.plus({ minutes: form.getValues('duration') })
                    ),
                    class: '!tw-border-dashed !tw-border-4'
                };
                const index = Math.floor(
                    startDate.diff(startDate.startOf('week'), 'days').days
                );
                schedule[index].push(interval);
            }
            setSchedule(schedule);
        }).finally(() => setLoading(false));
    }, [startDate?.toISODate(), form.getValues('interval_id')]);

    function handleChangeStartDate(date: DateTime | null) {
        const duration = form.getValues('duration');
        if (interval && date) {
            interval.interval = Interval.fromDateTimes(
                date, date.plus({ minutes: duration })
            );
        }
        setSchedule([...schedule]);
    }

    function handleChangeDuration(duration: number) {
        const start = form.getValues('start_date');
        if (interval && start) {
            interval.interval = Interval.fromDateTimes(
                start, start.plus({ minutes: duration })
            );
        }
        setSchedule([...schedule]);
    }

    function handleDeleteTaken() {
        setLoading(true);
        return deleteAppointment(props.value?.interval_id ?? '')
            .then(() => {
                toast(ct('messages.success'), { type: 'success' });
                props.onOpenChange?.(false);
            })
            .catch(handleNetworkError)
            .finally(() => {
                setLoading(false);
                setSchedule([]);
            });
    }

    function handleSubmit(value: Taken) {
        return postUpdateAppointment({
            id: value.interval_id,
            where: value.location,
            title: value.title,
            description: value.description,
            start: value.start_date?.toISO({ suppressMilliseconds: true }),
            end: value.start_date?.plus({ minutes: value.duration })
                .toISO({ suppressMilliseconds: true }),
            duration: value.duration
        })
            .then(() => {
                toast(ct('messages.saved'), { type: 'success' });
                props.onOpenChange?.(false);
            })
            .catch(handleNetworkError)
            .finally(() => {
                setLoading(false);
                setSchedule([]);
            });
    }

    return (
        <Dialog
            open={props.open}
            onOpenChange={props.onOpenChange}
        >
            <DialogContent className="!tw-min-h-[90vh] !tw-flex !tw-flex-col">
                <DialogHeader className="tw-mb-4">
                    <DialogTitle>{t('update-taken.title')}</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        className="tw-flex tw-flex-col tw-flex-1 tw-gap-4"
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <Row className={cn(
                            'tw-flex-1 tw-relative',
                            '!tw-flex-nowrap tw-flex-col tw-gap-y-4 md:tw-flex-row'
                        )}>
                            <Col className="tw-flex tw-flex-col tw-self-start tw-gap-4" col={7}>
                                {showWarningEdit &&
                                    <Alert variant="warning">
                                        {t('update-taken.warning')}
                                    </Alert>
                                }
                                {hasPermissions(Permission.CALENDAR_APPOINTMENT_UPDATE) &&
                                    <div className="tw-flex tw-gap-2">
                                        <Checkbox
                                            id="edit-toggle"
                                            checked={toggle}
                                            onCheckedChange={(value) => setToggle(Boolean(value))}
                                        />
                                        <Label htmlFor="edit-toggle">
                                            {t('update-taken.edit-toggle')}
                                        </Label>
                                    </div>
                                }
                                <FormField
                                    name="ticket_id"
                                    render={({ field }) =>
                                        <FormItem>
                                            <FormLabel>
                                                {t('update-taken.folder')}
                                            </FormLabel>
                                            <FormControl>
                                                <div className="tw-flex tw-gap-2">
                                                    <Input
                                                        disabled
                                                        {...field}
                                                    />
                                                    {externalUrl && <Button variant="outline" asChild>
                                                        <Link
                                                            to={externalUrl}
                                                            target="_blank"
                                                        >
                                                            <ExternalLinkIcon className="tw-mr-2"/>
                                                            {ct('open')}
                                                        </Link>
                                                    </Button>}
                                                </div>
                                            </FormControl>
                                        </FormItem>
                                    }
                                />
                                <FormField
                                    name="start_date"
                                    render={({ field }) =>
                                        <FormItem>
                                            <FormLabel>
                                                {t('update-taken.start')}
                                            </FormLabel>
                                            <FormControl>
                                                <div className="tw-flex tw-gap-2">
                                                    <DatePicker
                                                        {...field}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                            if (value) {
                                                                handleChangeStartDate(value);
                                                            }
                                                        }}
                                                        disabled={!toggle || loading}
                                                    />
                                                    <TimePicker
                                                        {...field}
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                            if (value) {
                                                                handleChangeStartDate(value);
                                                            }
                                                        }}
                                                        disabled={!toggle || loading}
                                                    />
                                                </div>
                                            </FormControl>
                                        </FormItem>
                                    }
                                />
                                <FormField
                                    name="duration"
                                    render={({ field: { ref, ...field } }) =>
                                        <FormItem>
                                            <FormLabel>
                                                {t('update-taken.duration')}
                                            </FormLabel>
                                            <FormControl>
                                                <Combobox<Duration, number>
                                                    options={durations}
                                                    getOptionValue={opt => opt.value}
                                                    getOptionLabel={opt => opt.label}
                                                    {...field}
                                                    innerRef={ref}
                                                    onChange={(value) => {
                                                        field.onChange(value);
                                                        if (value != null) {
                                                            handleChangeDuration(value);
                                                        }
                                                    }}
                                                    disabled={!toggle || loading}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    }
                                />
                                <FormField
                                    name="title"
                                    render={({ field }) =>
                                        <FormItem>
                                            <FormLabel>
                                                {t('update-taken.taken-title')}
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    disabled={!toggle || loading}
                                                    {...field}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    }
                                />
                                <FormField
                                    name="location"
                                    render={({ field }) =>
                                        <FormItem>
                                            <FormLabel>
                                                {t('update-taken.location')}
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    disabled={!toggle || loading}
                                                    {...field}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    }
                                />
                                <FormField
                                    name="description"
                                    render={({ field }) =>
                                        <FormItem>
                                            <FormLabel>
                                                {t('update-taken.description')}
                                            </FormLabel>
                                            <FormControl>
                                                <Textarea
                                                    disabled={!toggle || loading}
                                                    {...field}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    }
                                />
                            </Col>
                            <Col className="tw-relative tw-flex-1 md:tw-flex-none" col={5}>
                                <div className="!tw-h-full tw-absolute tw-overflow-auto tw-inset-0">
                                    <WeekSelection
                                        readonly
                                        startDate={startDate?.startOf('day') ?? DateTime.now()}
                                        intervalTypes={props.intervalTypes}
                                        intervalMinutes={15}
                                        dayStartHour={6}
                                        dayEndHour={23}
                                        weekLength={1}
                                        headerClassName="tw-sticky tw-top-0"
                                        value={schedule}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary">
                                    {ct('cancel')}
                                </Button>
                            </DialogClose>
                            {hasPermissions(Permission.CALENDAR_APPOINTMENT_DELETE) &&
                                <ConfirmDialogButton
                                    title={t('update-taken.delete.title')}
                                    message={t('update-taken.delete.message')}
                                    confirmText={ct('remove')}
                                    cancelText={ct('cancel')}
                                    onConfirm={handleDeleteTaken}
                                >
                                    <Button
                                        variant="destructive"
                                        disabled={loading}
                                    >
                                        {ct('remove')}
                                    </Button>
                                </ConfirmDialogButton>
                            }
                            {hasPermissions(Permission.CALENDAR_APPOINTMENT_UPDATE) &&
                                <ButtonSubmit disabled={!toggle || loading || showWarningEdit}>
                                    {ct('save')}
                                </ButtonSubmit>
                            }
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
