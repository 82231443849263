import React, { useState } from 'react';
import {
    NeoFormComponent,
    type NeoFormComponentProps
} from '@/components/neoform/NeoFormComponent';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { Row } from '@/components/ui/row';
import { combine, useTranslation } from '@/composables/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClipboard, faCopy, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNeoForm } from '@/composables/neoform';
import {
    writeClipboardJSON, readClipboardJSON
} from '@/composables/clipboard';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

const ADDRESS_KEYS = ['street',
    'city', 'postal',
    'province', 'country',
    'email', 'phone'];

interface AddressContents {
    street: string;
    city: string;
    postal: string;
    province: string;
    country: string;
    email: string;
    phone: string;
}

interface Props {
    noStreet?: boolean;
    noCity?: boolean;
    postal?: boolean;
    country?: boolean;
    contact?: boolean;
    noActions?: boolean;
}

export function Address(props: Props & NeoFormComponentProps) {
    const { t } = useTranslation('neoform.address');
    const { hookForm, id } = useNeoForm();
    const [copied, setCopied] = useState(false);

    const handleClear = () => {
        const value = ADDRESS_KEYS.reduce((obj, k) => ({
            ...obj,
            [k]: ''
        }), {});
        hookForm.setValue(id, value);
    };

    const handleCopy = () => {
        const address: Partial<AddressContents> = hookForm.getValues(id) ?? {};
        writeClipboardJSON(address)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 5000);
            });
    };

    const handlePaste = () => {
        readClipboardJSON()
            .then((address) => {
                const value = Object.entries(address)
                    .reduce((obj, [k, v]) => {
                        if (!ADDRESS_KEYS.includes(k) || !v) { return obj; }
                        return { ...obj, [k]: v };
                    }, {});
                hookForm.setValue(id, value);
            });
    };

    return (
        <div className="tw-flex tw-flex-col tw-gap-2">
            <Row>
                <NeoFormComponent
                    name="street"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.address.street-address')}
                    display={!props.noStreet}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="city"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.address.city')}
                    display={!props.noCity}
                    col={6}
                />
                <NeoFormComponent
                    name="postal"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.address.postal-code')}
                    mask="@0_ 0_0"
                    definitions={{
                        '@': /[ABCEGHJ-NPRSTVXY]/,
                        _: /[ABCEGHJ-NPRSTV-Z]/
                    }}
                    uppercase
                    validationRules={['postalCode']}
                    display={props.postal}
                    col={6}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="province"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.address.province')}
                    default="Québec"
                    display={!!props.country}
                    col={6}
                />
                <NeoFormComponent
                    name="country"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('neoform.address.country')}
                    default="Canada"
                    display={!!props.country}
                    col={6}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="email"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.email')}
                    validationRules={['email']}
                    display={props.contact}
                    col={6} last="md"
                />
                <NeoFormComponent
                    name="phone"
                    componentName="InputText"
                    titleType={NeoFormTitleType.LABEL}
                    title={combine('common.phone')}
                    mask="(000) 000-0000"
                    validationRules={['phone']}
                    display={props.contact}
                    col={6} last
                />
            </Row>
            <div className={cn(
                'tw-flex tw-justify-end tw-gap-2 tw-mt-2',
                { 'tw-hidden': props.noActions }
            )}>
                <Button
                    type="button" variant="outline"
                    className="tw-text-destructive"
                    onClick={handleClear}
                    disabled={props.readonly}
                >
                    <FontAwesomeIcon className="tw-mr-2" icon={faXmark} />
                    {t('clear')}
                </Button>
                <Button
                    type="button" variant="outline"
                    className="tw-text-primary"
                    onClick={handleCopy}
                >
                    <FontAwesomeIcon className="tw-mr-2" icon={copied ? faCheck : faCopy} />
                    {copied ? t('copied') : t('copy')}
                </Button>
                <Button
                    type="button" variant="outline"
                    className="tw-text-primary"
                    onClick={handlePaste}
                    disabled={props.readonly}
                >
                    <FontAwesomeIcon className="tw-mr-2" icon={faClipboard} />
                    {t('paste')}
                </Button>
            </div>
        </div>
    );
}
