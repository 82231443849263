import React from 'react';
import { Row } from '@/components/ui/row';
import {
    NeoFormComponent,
    type NeoFormComponentProps
} from '@/components/neoform/NeoFormComponent';
import { combine } from '@/composables/translation';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';

interface Props {
    splitPercentage?: boolean;
}

export function UserPercent(props: NeoFormComponentProps & Props) {
    return (
        <>
            <Row>
                <NeoFormComponent
                    name=""
                    componentName="User"
                    last
                />
            </Row>
            <Row>
                <NeoFormComponent
                    col={4}
                    name="percent"
                    componentName="InputNumber"
                    title={combine('neoform.user-percentage.percentage')}
                    titleType={NeoFormTitleType.LABEL}
                    display={!!props.splitPercentage}
                    percentage
                />
            </Row>
        </>
    );
}
