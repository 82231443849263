import React from 'react';
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { genLang } from './UsersChildrenInfoDivorce';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RadioInput } from './RadioInput';
import { NumberInput } from './NumberInput';

const RESIDENCE_OPTIONS = [
    { value: 'propre', label: 'Sa propre demeure' },
    { value: 'partie0', label: 'Chez partie 1' },
    { value: 'partie1', label: 'Chez partie 2' }
];

export class UserChildInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            child: ''
        };
    }

    render() {
        const childName = `${this.props.name}_child`;
        const residenceName = `${this.props.name}_residence`;
        const incomeName = `${this.props.name}_income`;
        const child = this.props.formData[childName];
        return (
            <Card style={{ marginBottom: 5 }}>
                {this.props.removeChild &&
                    <span
                        className="remove"
                        onClick={() => this.props.removeChild(this.props.name)}
                    >
                        {<FontAwesomeIcon icon={faWindowClose} className="avensRed" />}
                    </span>
                }
                <CardBody className="!tw-pb-0">
                    <h5>{genLang(this.props.title, this.props.lang)}</h5>
                    <FormGroup >
                        <Label>Enfant</Label>
                        <Input
                            type="select"
                            name={childName}
                            value={child}
                            onChange={(e) => this.props.put({
                                params: { key: childName, value: e.target.value }
                            })}
                        >
                            {Object.entries(this.props.childrenList.data)
                                .map(([k]) => {
                                    const name = this.props.formData[`${k}_name`];
                                    const firstname = this.props.formData[`${k}_firstname`];
                                    return (<option key={k} value={k}>{firstname} {name}</option>);
                                })}
                            <option value="">Sélectionner</option>
                        </Input>
                    </FormGroup>
                    {this.props.residence && <Row>
                        <RadioInput
                            title={{ en: 'Residence', fr: 'Résidence' }}
                            put={this.props.put}
                            name={residenceName}
                            formData={this.props.formData}
                            options={RESIDENCE_OPTIONS}
                        />
                    </Row>}
                    {this.props.income && <Row style={{ marginTop: '1rem' }}>
                        <NumberInput
                            title={{ en: 'Income', fr: 'Rémunération' }}
                            requestable={false}
                            put={this.props.put}
                            name={incomeName}
                            formData={this.props.formData}
                        />
                    </Row>}
                </CardBody>
            </Card>
        );
    }
}
