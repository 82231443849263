import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { TwoFA } from '@/pages/login/TwoFA';

import { useTranslation } from '@/composables/translation';
import { getTemplateSettings, instance } from '@/composables/api';
import { useValidation } from '@/composables/validation';
import { useOrg } from '@/composables/org';
import { useError } from '@/composables/error';
import { popReturnUrl } from '@/composables/utils';

import { useUserStore } from '@/store/user';
import { CardHeader, CardContent, CardTitle, CardDescription, CardFooter } from '@/components/ui/card';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { toast } from 'react-toastify';
import { EnvelopeClosedIcon, LockClosedIcon } from '@radix-ui/react-icons';
import { useSettingsStore } from '@/store/settings';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOverrideConfig } from '@/composables/override';

export function Login() {
    const { t, ct } = useTranslation('login');
    const { handleNetworkError } = useError();
    const { required, email } = useValidation();
    const navigate = useNavigate();
    const override = useOverrideConfig();

    const { org, showOrg } = useOrg();
    const form = useForm({
        defaultValues: {
            org,
            username: '',
            password: '',
            otp: ''
        }
    });

    const [show2FA, setShow2FA] = useState(false);
    const {
        setLang,
        setToken
    } = useUserStore(state => ({
        setLang: state.setLang,
        setToken: state.setToken
    }));
    const setSettings = useSettingsStore(state => state.setSettings);

    function handleSubmit(data: { org: string; username: string; password: string; otp: string }) {
        return instance.post(override?.urls?.post_login ?? '/v1/login', data)
            .then((res) => {
                if (!res.data?.success) {
                    handleNetworkError();
                } else if (res.data?.token) {
                    setLang(res.data.user?.lang || 'en');
                    setToken(res.data.token, res.data.user?.logo || '');
                    if (override?.is_customer_portal) {
                        navigate('/portal/dashboard');
                        return;
                    }
                    return getTemplateSettings()
                        .then((res) => {
                            setSettings({
                                ...res.data,
                                default: false
                            });
                            navigate(popReturnUrl());
                        });
                } else if (!res.data?.otp) {
                    if (show2FA) {
                        toast(ct('messages.success'), { type: 'success' });
                    } else {
                        setShow2FA(true);
                    }
                }
            })
            .catch(handleNetworkError);
    }

    if (show2FA) {
        return (
            <TwoFA
                onSubmit={({ otp }) => handleSubmit({
                    ...form.getValues(),
                    otp
                })}
                onResend={() => handleSubmit({
                    ...form.getValues(),
                    otp: 'request'
                })}
            />
        );
    }

    return (
        <>
            <CardHeader>
                <CardTitle className="!tw-text-2xl">
                    {override?.is_customer_portal ? t('client-portal-title') : t('title')}
                </CardTitle>
                <CardDescription>{t('description')}</CardDescription>
            </CardHeader>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <CardContent className="tw-flex tw-flex-col tw-gap-2">
                        {showOrg && <FormField
                            name="org"
                            rules={{ validate: { required } }}
                            render={({ field }) =>
                                <FormItem>
                                    <FormLabel>{ct('org')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="org"
                                            prependIcon={<FontAwesomeIcon width="1em" icon={faBuilding}/>}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />}
                        <FormField
                            name="username"
                            rules={{ validate: { required, email } }}
                            render={({ field }) =>
                                <FormItem>
                                    <FormLabel>{ct('email')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            prependIcon={<EnvelopeClosedIcon/>}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />
                        <FormField
                            name="password"
                            rules={{ validate: { required } }}
                            render={({ field }) =>
                                <FormItem>
                                    <FormLabel>{ct('password')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="password"
                                            prependIcon={<LockClosedIcon/>}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />
                    </CardContent>
                    <CardFooter className="tw-flex-col tw-gap-2">
                        <Button variant="link" className="!tw-px-0 tw-self-start" asChild>
                            <Link to={`${override?.is_customer_portal ? '/portal' : ''}/forgot-password`}>
                                {t('forgot-password')}
                            </Link>
                        </Button>
                        <ButtonSubmit className="tw-self-stretch">
                            {t('login')}
                        </ButtonSubmit>
                        <p className="tw-text-sm">
                            {t(
                                override?.is_customer_portal
                                    ? 'bottom-text.message-admin'
                                    : 'bottom-text.message-client'
                            )},
                            <Button variant="link" className="tw-ml-1 !tw-px-0 tw-lowercase" asChild>
                                <Link to={override?.is_customer_portal ? '/login' : '/portal/login'}>
                                    {ct('click-here')}
                                </Link>
                            </Button>
                        </p>
                    </CardFooter>
                </form>
            </Form>
        </>
    );
}
