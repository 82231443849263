import React, { Component } from 'react';
import { Col, Form, Row } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

import { faDownload, faEye, faLink } from '@fortawesome/free-solid-svg-icons';

import { AlertNotification } from '@/components/old/FormComponents/AlertNotification';
import { LegendHeader } from '@/components/old/FormComponents/LegendHeader';
import { TextInput } from '@/components/old/FormComponents/TextInput';

import { Hidden } from '@/components/old/FormComponents/Hidden';
import { RadioInput } from '@/components/old/FormComponents/RadioInput';
import { CheckInput } from '@/components/old/FormComponents/CheckInput';
import { CheckInputColor } from '@/components/old/FormComponents/CheckInputColor';

import { ContentEditable } from '@/components/old/FormComponents/ContentEditable';
import { ContentEditableTiny } from '@/components/old/FormComponents/ContentEditableTiny';

import { UsersEmail } from '@/components/old/FormComponents/UsersEmail';
import { UserPercent } from '@/components/old/FormComponents/UserPercent';
import { UsersPercent } from '@/components/old/FormComponents/UsersPercent';
import { UsersEndos } from '@/components/old/FormComponents/UsersEndos';
import { ContentEditableTemplate } from '@/components/old/FormComponents/ContentEditableTemplate';
import { Users } from '@/components/old/FormComponents/Users';

import { Actionnaires2 } from '@/components/old/FormComponents/Actionnaires2';
import { CategoryActions } from '@/components/old/FormComponents/CategoryActions';

import { UsersChildrenDivorce } from '@/components/old/FormComponents/UsersChildrenDivorce';
import { UsersChildrenInfoDivorce } from '@/components/old/FormComponents/UsersChildrenInfoDivorce';
import { User } from '@/components/old/FormComponents/User';
import { NumberInput } from '@/components/old/FormComponents/NumberInput';
import { DateInput } from '@/components/old/FormComponents/DateInput';

import { Select2 } from '@/components/old/FormComponents/Select2';
import { SelectFixedOptions } from '@/components/old/FormComponents/SelectFixedOptions';

import { Select2Creatable } from '@/components/old/FormComponents/Select2Creatable';
import { SelectBootstrap } from '@/components/old/FormComponents/SelectBootstrap';
import { SelectBootstrapAsync } from '@/components/old/FormComponents/SelectBootstrapAsync';
import { TextAreaInput } from '@/components/old/FormComponents/TextAreaInput';

import { HTML } from '@/components/old/FormComponents/HTML';
import { Help } from '@/components/old/FormComponents/Help';
import { Informative } from '@/components/old/FormComponents/Informative';
import { DropContainerPC } from '@/components/old/FormComponents/DropContainerPC';
import { DropContainerLiasse } from '@/components/old/FormComponents/DropContainerLiasse';

import { DropContainer } from '@/components/old/FormComponents/DropContainer';

import { Calendar } from '@/components/old/FormComponents/Calendar';
import { Address } from '@/components/old/FormComponents/Address';
import { Time } from '@/components/old/FormComponents/Time';
import { Goods } from '@/components/old/FormComponents/Goods';

import { Phone } from '@/components/old/FormComponents/Phone';

import { Actionnaires } from '@/components/old/FormComponents/Actionnaires';
import { Admins } from '@/components/old/FormComponents/Admins';

import { MaterialTable } from '@/components/old/FormComponents/MaterialTable';
import { TableIcon } from '@/components/old/FormComponents/TableIcon';
import { InputFields } from '@/components/old/FormComponents/InputFields';
import { EventsByDate } from '@/components/old/FormComponents/EventsByDate';
import { ParentalTimes } from '@/components/old/FormComponents/ParentalTimes';
import { RequestedDocuments } from '@/components/old/FormComponents/RequestedDocuments';
import { AbstractComponent } from '@/components/old/FormComponents/AbstractComponent';
import { Section } from '@/components/old/FormComponents/Section';
import { DashboardElement } from '@/components/old/FormComponents/DashboardElement';
import { WizardBeltElement } from '@/components/old/FormComponents/WizardBeltElement';
import { PreviewModal } from '@/components/neoform/PreviewModal';
import { NeoFormComponentPreface } from '@/components/neoform/NeoFormComponentPreface';

import rootsActions from '@/store/old/_actions/root-actions';
import { connect } from 'react-redux';

const ABSTRACT = 'Abstract';
const TEXT_INPUT = 'TextInput';
const TEXT_AREA = 'TextAreaInput';
const USER_DYNAMIC = 'Users';
const USER = 'User';
const CONTENT_EDITABLE = 'ContentEditable';
const CONTENT_EDITABLE_TINY = 'ContentEditableTiny';
const CONTENT_EDITABLE_TEMPLATE = 'ContentEditableTemplate';
const INFORMATIVE = 'Informative';
const CHECK_INPUT = 'CheckInput';
const USERS_ENDOS = 'UsersEndos';
const USERS_EMAIL = 'UsersEmail';
const USERS_CHILDREN_DIVORCE = 'UsersChildrenDivorce';
const USERS_CHILDREN_INFO_DIVORCE = 'UsersChildrenInfoDivorce';
const CHECK_INPUT_COLOR = 'CheckInputColor';
const DROP_CONTAINER = 'DropContainer';
const DROP_CONTAINER_PC = 'DropContainerPC';
const DROP_CONTAINER_LIASSE = 'DropContainerLiasse';
const TIME = 'Time';
const CALENDAR = 'Calendar';
const SELECT_2 = 'Select2';
const SELECT_FIXED_OPTIONS = 'SelectFixedOptions';
const GOODS = 'Goods';
const HTML_COMPONENT = 'HTML';
const ADDRESS = 'Address';
const PHONE = 'Phone';
const SELECT_2_CREATABLE = 'Select2Creatable';
const SELECT_BOOTSTRAP = 'SelectBootstrap';
const SELECT_BOOTSTRAP_ASYNC = 'SelectBootstrapAsync';
const DATE_INPUT = 'DateInput';
const HELP = 'Help';
const BLOCK = 'block';
const NONE = 'none';
const HIDDEN = 'Hidden';
const USERS_PERCENT = 'UsersPercent';
const USER_PERCENT = 'UserPercent';
const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

const NGROK = import.meta.env.VITE_API_URL;

const NUMBER_INPUT = 'NumberInput';
const RADIO_INPUT = 'RadioInput';
const HEADER_SECTION = 'headerSection_';
const ACTIONNAIRES = 'Actionnaires';
const ACTIONNAIRES2 = 'Actionnaires2';
const ADMINS = 'Admins';
const INPUTFIELDS = 'InputFeilds';
const EVENTSBYDATE = 'EventsByDate';
const PARENTALTIMES = 'ParentalTimes';
const CATEGORY_ACTIONS = 'CategoryActions';
const $OR = '$or';

moment.locale('fr');

class JSONFormComponent extends Component {
    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.functions = {};

        this.ticket_id = window.location;
        this.toggleLang = this.toggleLang.bind(this);
        this.genLang = this.genLang.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.renderRow = this.renderRow.bind(this);

        this.renderDocuments = this.renderDocuments.bind(this);
        this.state = {
            lang: this.props.lang || 'fr',
            trad: this.props.trad,
            data: this.props.data || [],
            version: this.props.version || 0,
            ticket: this.props.ticket,
            open: false,
            dataFiles: this.props.dataFiles || [],
            dirtyFields: new Set(),
            put: ({ params }) => {
                this.props.put({ params });
                this.state.dirtyFields.add(params.key);
            },
            remove: ({ params }) => {
                this.props.remove({ params });
                this.state.dirtyFields.add(params.key);
            }
        };
    }

    componentDidMount() {
        let data = this.props.layout;
        data = [];
        if (data.header) {
            this.setState({
                ...data.header,
                body: data.body || []
            });
        }

        if (!this.state.ticket || this.props.public) {
            return;
        }
    }

    componentDidUpdate(prevProps) {
        let obj = {};
        let props = this.props;

        if (JSON.stringify(prevProps.data) !== JSON.stringify(props.data)) {
            obj['data'] = props.data;
        }

        if (prevProps.ticket !== props.ticket) {
            obj['ticket'] = props.ticket;
        }

        if (JSON.stringify(prevProps.layout) !== JSON.stringify(props.layout)) {
            ///////////////////////////////////////////////////////////////////////////////
            // Gestion de l'affichage conditionnel
            ///////////////////////////////////////////////////////////////////////////////
            let body = props.layout.body;
            let first = 0;

            //ici on mappe les différentes sections ex: partie adverse, mise en demeure signataire etc
            // on l'utilise pour l'affichage plus loin, mais aussi pour chercher plus vite dans la JSON des display

            for (let t in body) {
                if (!first) {
                    //si première section, afficher ouvert, les autres sont fermées
                    first++;
                    obj[HEADER_SECTION + body[t].header.sectionPrefix] = true;
                } else {
                    obj[HEADER_SECTION + body[t].header.sectionPrefix] = false;
                }
            }

            //clairement eu des problèmes de scopes, à vos risques et périls
            if (this) {
                //pour chaque section
                let mappedBody = _.map(body, function (e) {
                    // pour chaque rows de chaque section
                    return _.map(e.rows, function (f) {
                        //ne retourner que celles qui ont la valeur display (affichage conditionnel)
                        return _.filter(
                            // pour toutes les objects dans les rows, retourner un objet avec la fonction instanciée
                            _.map(f, function (g) {
                                for (let d in g.display) {
                                    if (d === $OR) {
                                        // debugger;
                                        let arr = [];
                                        for (let o in g.display[d]) {
                                            arr.push({
                                                func: new Function(
                                                    ...g.display[d][o].params,
                                                    g.display[d][o].body
                                                ),
                                                name: o
                                            });
                                        }

                                        let self = this;
                                        for (let o in g.display[d]) {
                                            g.display[o] = arr;
                                        }

                                        delete g.display[$OR];
                                    } else {
                                        g.display[d] = new Function(
                                            ...g.display[d].params,
                                            g.display[d].body
                                        );
                                    }
                                }

                                return {
                                    // target:,
                                    name: e.header.sectionPrefix + '_' + g.name,
                                    display: g.display
                                };
                            }),
                            //si display est rempli, retourner display
                            function (z) {
                                if (_.has(z, 'display') && !_.isEmpty(z.display)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        );
                    });
                });

                // à chaque changement de valeur, l'application vérifie si quelque chose change dans mapped body
                mappedBody = _.flattenDeep(mappedBody);

                obj.mappedBody = mappedBody;
            }

            obj = {
                mappedBody: [],
                ...obj,
                ...props.layout.header,
                body: props.layout.body || []
            };
        }

        if (
            props.shouldDisplay &&
            JSON.stringify(props.shouldDisplay) !==
            JSON.stringify(prevProps.shouldDisplay)
        ) {
            for (let s in props.shouldDisplay) {
                let temp = {};
                let found = _.filter(this.state.mappedBody, (e) => {
                    temp = {};
                    temp[s] = true;

                    return !!e.display[s];
                });

                //found c'est pour trouver tous les champs affectés par le changement de display
                if (found && found.length) {
                    for (let f in found) {
                        for (let d in found[f].display) {
                            if (!!found[f].display[d]) {
                                if (obj.hasOwnProperty(found[f].name + '_display')) {
                                    if (obj[found[f].name + '_display']) {
                                        if (Array.isArray(found[f].display[d])) {
                                            let res;

                                            for (let espoir in found[f].display[d]) {
                                                let tempo = found[f].display[d][espoir].func(
                                                    props.shouldDisplay[found[f].display[d][espoir].name],
                                                    props.shouldDisplay
                                                );

                                                if (tempo) res = true;
                                            }
                                            obj[found[f].name + '_display'] = res;
                                        } else {
                                            obj[found[f].name + '_display'] = found[f].display[d](
                                                props.shouldDisplay[d],
                                                props.shouldDisplay
                                            );
                                        }
                                    }
                                } // fin if has property
                                else {
                                    // debugger;
                                    if (Array.isArray(found[f].display[d])) {
                                        let res = false;

                                        for (let espoir in found[f].display[d]) {
                                            // debugger;
                                            // if(res)
                                            //   break;

                                            let tempo;
                                            tempo = found[f].display[d][espoir].func(
                                                props.shouldDisplay[found[f].display[d][espoir].name],
                                                props.shouldDisplay
                                            );

                                            if (tempo) res = true;
                                        }

                                        obj[found[f].name + '_display'] = res;
                                    } else {
                                        obj[found[f].name + '_display'] = found[f].display[d](
                                            props.shouldDisplay[d],
                                            props.shouldDisplay
                                        );
                                    }
                                } //fin else
                            } // end for found
                        } //for in display
                    } //fin for in found
                } //fin du if found
            } //fin du for should display
        }

        if (props.version !== prevProps.version) {
            obj.version = props.version;
        }

        if (prevProps.lang !== props.lang) {
            obj['lang'] = props.lang;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    moreThan25 = (key, val) => {
        const isPerson = key.replace('_numberActionAssujetti', '_personnePhysique');
        const regex = /_\d+_/g;
        const arr = key.match(regex);
        const informations_numberActionVote = parseInt(
            this.state.data['informations_numberActionVote']
        );
        const obj = [];
        if (arr.length === 1 && informations_numberActionVote > 0) {
            const percent = parseInt(val) / informations_numberActionVote;
            if (percent >= 0.25 && percent <= 1) {
                if (this.state.data[isPerson] === 'true') {
                    const person = isPerson.replace('_personnePhysique', '');
                    obj.push(person);
                    this.setState({ beneficiary: obj });
                }
                return true;
            }
        } else {
            let count = arr.length - 1;
            let percent;
            let t = key;
            const p = [
                { numberActionVote: this.state.data['informations_numberActionVote'] }
            ];
            while (count !== 0) {
                const lastIndexOfActionnaire = t.lastIndexOf(
                    `_actionnaire${arr[count]}`
                );
                if (lastIndexOfActionnaire !== -1) {
                    t = t.substring(0, lastIndexOfActionnaire) + '_';
                    const obj = {
                        [`numberActionVote`]: parseInt(
                            this.state.data[t + 'numberActionVote']
                        ),
                        [`numberActionAssujetti`]: parseInt(
                            this.state.data[t + 'numberActionAssujetti']
                        )
                    };
                    p.unshift(obj);
                    count = count - 1;
                } else {
                }

                p.forEach((i, idx) => {
                    if (idx === 0) {
                        percent =
                            (val / i['numberActionVote']) * i['numberActionAssujetti'];
                    } else if (idx !== p.length - 1) {
                        percent =
                            (percent / i['numberActionVote']) * i['numberActionAssujetti'];
                    } else {
                        percent = percent / i['numberActionVote'];
                    }
                });
            }
            if (percent >= 0.25 && percent <= 1) {
                if (this.state.data[isPerson] === 'true') {
                    const person = isPerson.replace('_personnePhysique', '');
                    // const obj = {...this.state.beneficiary, person}
                    obj.push(person);
                    this.setState({ beneficiary: obj });
                }
                return true;
            }
        }
        return true;
    };

    toggleLang() {
        this.setState(
            {
                lang: this.state.lang === 'fr' ? 'en' : 'fr'
            },
            () => this.props.put({
                params: {
                    key: 'lang',
                    value: this.state.lang
                }
            })
        );
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                if (
                    lang !== null &&
                    lang !== '' &&
                    (text[lang] === null || text[lang] === '')
                ) {
                    return text[lang];
                }
                return '';
            }
        }

        return '';
    }

    parseConditionalFunction(name, value, args) {
        const functions = this.functions;
        if (functions[name]) {
            return functions[name];
        }

        const src = typeof value === 'string'
            ? `return ${value};`
            : value
                .map(o => {
                    if (o.condition) {
                        return `if (${o.condition}) { return ${o.value}; }`;
                    }
                    return `{ return ${o.value}; }`;
                })
                .join(' else ');
        functions[name] = new Function(...args, src);
        return functions[name];
    }

    renderComponentTitle(name, params) {
        if ([INFORMATIVE, HTML_COMPONENT, CHECK_INPUT, CHECK_INPUT_COLOR].includes(name)) {
            return (<></>);
        }
        return (
            <NeoFormComponentPreface
                lang={this.state.lang}
                visible={this.state[`${params.name}_display`] !== false}
                titleType={params.title_type || 'default'}
                title={params.title}
                description={params.description}
                info={params.info}
            />
        );
    }

    renderComponent(name, params) {
        let props = this.props;
        let self = this;
        params.allFieldsNotes = props.allFieldsNotes;
        params.allFieldsNotesInfo = props.allFieldsNotesInfo;

        let requestFields = !!props.requestField && !props.public;
        params.requestFields = requestFields;

        if (props.layout && props.layout.header && props.layout.header.afterLabel) {
            params.afterLabel = true;
        }

        if (params.conditional_props) {
            Object.entries(params.conditional_props)
                .forEach(([name, value]) => {
                    const fnName = `${params.name}_prop_${name}`;
                    const fn = this.parseConditionalFunction(fnName, value, ['data']);
                    if (fn) {
                        params[name] = fn({ ...this.props.data });
                    }
                });
        }

        // Trigger component onChange
        const isDirty = this.state.dirtyFields.has(params.name);
        if (isDirty && params.onChange) {
            const fnName = `${params.name}_onchange`;
            const fn = this.parseConditionalFunction(fnName, params.onChange, ['value', 'data', 'set']);
            const data = this.props.formData;
            const set = (key, value) => {
                this.props.put({
                    params: {
                        key,
                        value
                    }
                });
            };
            const value = data[params.name];
            if (fn) {
                fn(value, data, set);
            }
            this.state.dirtyFields.delete(params.name);
        }

        const display = this.state[`${params.name}_display`];
        if (display === false) return (<></>);

        switch (name) {
            case ABSTRACT:
                return (
                    <AbstractComponent
                        put={props.put}
                        remove={props.remove}
                        formData={{ ...this.state.data }}
                        label={this.state[params.name + '_display']}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                        key={params.name}
                        title={params.title}
                        // formData={{...this.state.data}}
                        {...params}
                        renderRow={this.renderRow}
                    />
                );
            case TIME:
                return (
                    <Time
                        put={props.put}
                        remove={props.remove}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={{ ...this.state.data }}
                        label={this.state[params.name + '_display']}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case ADDRESS:
                return (
                    <Address
                        trad={props.trad}
                        name={props.name}
                        remove={props.remove}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={{ ...this.state.data }}
                        display={this.state[params.name + '_display']}
                        put={props.put}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case CALENDAR:
                return (
                    <Calendar
                        put={props.put}
                        remove={props.remove}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        public={props.public}
                        defaultDisplay={params.defaultDisplay}
                        formData={{ ...this.state.data }}
                        label={this.state[params.name + '_display']}
                        useLabel={true}
                        display={this.state[params.name + '_display']}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case TEXT_INPUT:
                return (
                    <TextInput
                        put={props.put}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        usePut={true}
                        {...params}
                        formData={{ ...this.state.data }}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case USERS_PERCENT:
                return (
                    <UsersPercent
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        token={props.token}
                    />
                );
            case USERS_EMAIL:
                return (
                    <UsersEmail
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        token={props.token}
                    />
                );
            case HIDDEN:
                return <Hidden key={params.name} {...params} put={props.put} />;
            case USER_PERCENT:
                return (
                    <UserPercent
                        remove={props.remove}
                        target={params.title}
                        key={params.name}
                        {...params}
                        put={props.put}
                        lang={this.state.lang}
                        version={this.state.version}
                        trad={props.trad}
                        formData={this.state.data || []}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        percent={props.percent}
                        token={props.token}
                    />
                );
            case USER:
                return (
                    <User
                        remove={props.remove}
                        target={params.title}
                        key={params.name}
                        {...params}
                        put={props.put}
                        lang={this.state.lang}
                        version={this.state.version}
                        trad={props.trad}
                        formData={this.state.data || []}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        token={props.token}
                    />
                );
            case USERS_CHILDREN_DIVORCE:
                return (
                    <UsersChildrenDivorce
                        public={props.public}
                        ticket={props.ticket}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data || []}
                        pushWaitingList={props.pushWaitingList}
                        pullWaitingList={props.pullWaitingList}
                        title={props.title || {
                            fr: 'Enfant',
                            en: 'Child'
                        }}
                        removeFileFromGCloud={props.removeFileFromGCloud}
                        toggleLoading={props.toggleLoading}
                        target={props.target}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        token={props.token}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case PARENTALTIMES:
                return (
                    <ParentalTimes
                        ticket={props.ticket}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data || []}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                    />
                );
            case INPUTFIELDS:
                return (
                    <InputFields
                        lang={this.state.lang}
                        version={this.state.version}
                        title={props.title}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case ADMINS:
                return (
                    <Admins
                        public={props.public}
                        ticket={props.ticket}
                        target={props.target}
                        title={props.title}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data || []}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        pushWaitingList={
                            props.pushWaitingList
                        }
                        pullWaitingList={
                            props.pullWaitingList
                        }
                        removeFileFromGCloud={props.removeFileFromGCloud}
                        toggleLoading={props.toggleLoading}
                    />
                );
            case USER_DYNAMIC:
                return (
                    <Users
                        public={props.public}
                        ticket={props.ticket}
                        target={props.target}
                        title={props.title}
                        userTitle={props.userTitle}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data || []}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        pushWaitingList={
                            props.pushWaitingList
                        }
                        pullWaitingList={
                            props.pullWaitingList
                        }
                        removeFileFromGCloud={props.removeFileFromGCloud}
                        toggleLoading={props.toggleLoading}
                        token={props.token}
                        blabla={true}
                    />

                );
            case USERS_CHILDREN_INFO_DIVORCE:
                return (
                    <UsersChildrenInfoDivorce
                        lang={this.state.lang}
                        title={props.title || {
                            fr: 'Enfant',
                            en: 'Child'
                        }}
                        formData={this.props.formData || {}}
                        version={this.state.version}
                        {...params}
                        remove={props.remove}
                        put={props.put}
                        requestFields={props.layout.header.requestFields}
                        display={this.state[params.name + '_display']}
                    />
                );
            case HTML_COMPONENT:
                return (
                    <HTML
                        key={params.name}
                        lang={this.state.lang}
                        {...params}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                    />
                );
            case USERS_ENDOS:
                return (
                    <UsersEndos
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data || []}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        token={props.token}
                    />
                );
            case ACTIONNAIRES:
                return (
                    <Actionnaires
                        public={props.public}
                        ticket={props.ticket}
                        target={props.target}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data || []}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        pushWaitingList={props.pushWaitingList}
                        pullWaitingList={props.pullWaitingList}
                        removeFileFromGCloud={props.removeFileFromGCloud}
                        toggleLoading={props.toggleLoading}
                    />
                );
            case ACTIONNAIRES2:
                return (
                    <Actionnaires2
                        onSelectChange={this.props.onSelectChange}
                        removeWill={this.props.removeWill}
                        removeActionnaires={this.props.removeActionnaires}
                        moreThan25={this.moreThan25}
                        changeFormData={this.changeFormData}
                        public={props.public}
                        ticket={props.ticket}
                        target={props.target}
                        lang={this.state.lang}
                        result={this.state.beneficiary}
                        version={this.state.version}
                        informationNBAction={this.state.informationNBAction}
                        informations_name={this.state.informations_name}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        onChange={this.props.onChange}
                        formData={this.state.data || []}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        pushWaitingList={props.pushWaitingList}
                        pullWaitingList={props.pullWaitingList}
                        removeFileFromGCloud={props.removeFileFromGCloud}
                        toggleLoading={props.toggleLoading}
                    />
                );
            case EVENTSBYDATE:
                return (
                    <EventsByDate
                        ticket={props.ticket}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data || []}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                    />
                );
            case GOODS:
                return (
                    <Goods
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        put={props.put}
                        remove={props.remove}
                        trad={this.state.trad}
                        formData={this.state.data}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                    />
                );
            case TEXT_AREA:
                return (
                    <TextAreaInput
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        put={props.put}
                        {...params}
                        formData={this.state.data}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case PHONE:
                return (
                    <Phone
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        put={props.put}
                        {...params}
                        formData={this.state.data}
                        display={this.state[params.name + '_display']}
                    />
                );
            case SELECT_BOOTSTRAP_ASYNC:
                return (
                    <SelectBootstrapAsync
                        fetchedCollection={props.collections[params.collection]}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case SELECT_BOOTSTRAP:
                return (
                    <SelectBootstrap
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case SELECT_2_CREATABLE:
                return (
                    <Select2Creatable
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                        remove={props.remove}
                        display={this.state[params.name + '_display']}
                    />
                );
            case SELECT_2:
                return (
                    <Select2
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case CATEGORY_ACTIONS:
                return (
                    <CategoryActions
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        onChange={this.props.onChange}
                        onSelectChange={this.props.onSelectChange}
                        put={props.put}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        trad={this.state.trad}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                        removeWill={this.props.removeWill}
                    />
                );
            case SELECT_FIXED_OPTIONS:
                return (
                    <SelectFixedOptions
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case DATE_INPUT:
                return (
                    <DateInput
                        public={props.public}
                        put={props.put}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        requestFields={props.layout.header.requestFields}
                        putField={props.putField}
                    />
                );
            case NUMBER_INPUT:
                return (
                    <NumberInput
                        col={props.col}
                        tabIndex={props.tabIndex}
                        title={props.title}
                        put={this.state.put}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={{ ...this.props.formData }}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case CONTENT_EDITABLE_TEMPLATE:
                return (
                    <ContentEditableTemplate
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case CONTENT_EDITABLE:
                return (
                    <ContentEditable
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case CONTENT_EDITABLE_TINY:
                return (
                    <ContentEditableTiny
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                        public={props.public}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                    />
                );
            case INFORMATIVE:
                return (
                    <Informative
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                    />
                );
            case HELP:
                return (
                    <Help
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                    />
                );
            case CHECK_INPUT:
                return (
                    <CheckInput
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        lawyers={props.collections['lawyerCollection']}
                        defaultDisplay={params.defaultDisplay}
                        display={params.display}
                    />
                );
            case CHECK_INPUT_COLOR:
                return (
                    <CheckInputColor
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                    />
                );
            case RADIO_INPUT:
                return (
                    <RadioInput
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        defaultDisplay={params.defaultDisplay}
                        display={this.state[params.name + '_display']}
                    />
                );
            case DROP_CONTAINER:
                return (
                    <DropContainer
                        ticket={props.ticket}
                        toggleLoading={props.toggleLoading}
                        target={props.target}
                        lang={this.state.lang}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        remove={props.remove}
                        removeFileFromGCloud={props.removeFileFromGCloud}
                        public={props.public}
                        multiple={params.multiple}
                        getState={() => self.state}
                        pushWaitingList={props.pushWaitingList}
                        pullWaitingList={props.pullWaitingList}
                        display={this.state[params.name + '_display']}
                        putField={props.putField}
                        requestFields={props.layout.header.requestFields}
                        token={props.token}
                    />
                );
            case DROP_CONTAINER_PC:
                return (
                    <DropContainerPC
                        ticket={props.ticket}
                        target={props.target}
                        lang={this.state.lang}
                        toggleLoading={props.toggleLoading}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        remove={props.remove}
                        removeBundle={props.removeBundle}
                        removeFileFromGCloud={props.removeFileFromGCloud}
                        getState={() => self.state}
                        pushWaitingList={props.pushWaitingList}
                        pullWaitingList={props.pullWaitingList}
                        display={this.state[params.name + '_display']}
                    />
                );
            case DROP_CONTAINER_LIASSE:
                return (
                    <DropContainerLiasse
                        ticket={props.ticket}
                        target={props.target}
                        lang={this.state.lang}
                        toggleLoading={props.toggleLoading}
                        version={this.state.version}
                        key={params.name}
                        {...params}
                        formData={this.state.data}
                        put={props.put}
                        remove={props.remove}
                        removeBundle={props.removeBundle}
                        removeFileFromGCloud={props.removeFileFromGCloud}
                        getState={() => self.state}
                        pushWaitingList={props.pushWaitingList}
                        pullWaitingList={props.pullWaitingList}
                        display={this.state[params.name + '_display']}
                    />
                );
            default:
                return <></>;
        }
    }

    renderDocuments(collection) {
        collection = collection || [];

        if (!collection.length) {
            return (
                <div>
                    {this.genLang({
                        en: 'There are no other file created from this ticket',
                        fr: 'Aucun autre document n\'a été généré à partir de ce ticket'
                    })}
                </div>
            );
        }

        const COLUMNS_DOCUMENT = [
            {
                Header: 'ticket',
                accessor: 'ticket_id'
            },
            {
                Header: 'formulaire',
                accessor: 'doc_name'
            },

            {
                Header: 'client',
                accessor: 'client'
            },
            {
                Header: 'avocat',
                accessor: 'name'
            },
            {
                Header: 'date',
                accessor: 'date_createdLabel'
            },
            {
                Header: 'édition',
                accessor: 'edit'
            },
            {
                Header: '💾',
                accessor: 'download'
            }
        ];

        let tableEdit;
        let download;

        collection = _.map(collection, (elt) => {
            tableEdit = (
                <TableIcon
                    icon={faLink}
                    callback={() => {
                        window.open(elt.url, '_newtab');
                    }}
                />
            );

            download = (
                <TableIcon
                    icon={faDownload}
                    callback={() => {
                        axios
                            .get(NGROK + '/desk/ticket', {
                                params: elt.get,
                                auth: {
                                    username: 'admin',
                                    password:
                                        '6d21b4833368d61c31275092886a4d9add8f7801abd7892d017b3f19a37b30c8'
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    'x-jwt-token': localStorage.getItem('token')
                                }
                            })
                            .then(
                                (response) => {
                                    if (
                                        response &&
                                        response.data &&
                                        response.data.result &&
                                        response.data.result.doc
                                    ) {
                                        let pdf = response.data.result.doc.result;

                                        let byte, blob;
                                        byte = atob(pdf);
                                        let buffer = new ArrayBuffer(byte.length);
                                        let view = new Uint8Array(buffer);
                                        for (let i = 0; i < byte.length; i++) {
                                            view[i] = byte.charCodeAt(i);
                                        }

                                        blob = URL.createObjectURL(
                                            new Blob([view], { type: 'application/pdf' })
                                        );

                                        this.setState(
                                            {
                                                blob: blob
                                            },
                                            () => document.getElementById('downloadAnchor').click()
                                        );
                                    } else {
                                        console.error('no pdf');
                                    }
                                },
                                () => {
                                    console.error('erreur pdf....');
                                }
                            );
                    }}
                />
            );

            elt.doc_name = this.genLang(elt.doc_name);
            moment.locale(this.state.lang || 'fr');
            elt.date_createdLabel = moment(elt.date_created).format('LLLL');

            return {
                ...elt,
                edit: tableEdit,
                download: download
            };
        });

        return <MaterialTable data={collection} columns={COLUMNS_DOCUMENT} />;
    }

    renderClientDocuments(collection) {
        collection = collection || [];

        if (!collection.length) {
            return (
                <div>
                    {this.genLang({
                        en: 'There are no file provided from customer',
                        fr: 'Aucun document n\'a été envoyé par le client'
                    })}
                </div>
            );
        }

        const COLUMNS_DOCUMENT = [
            {
                Header: 'Fichier',
                accessor: 'name'
            },
            {
                Header: 'date',
                accessor: 'date_createdLabel'
            },
            {
                Header: '👀',
                accessor: 'preview'
            }
        ];

        let tablePreview;
        collection = _.map(collection, (elt) => {
            tablePreview = (
                <TableIcon
                    icon={faEye}
                    callback={() => {
                        let { path } = elt;

                        axios
                            .get(NGROK + '/desk/getfilebase64', {
                                params: {
                                    AUTH_ID: localStorage.getItem('user_id'),
                                    url: path
                                },
                                auth: {
                                    username: 'admin',
                                    password:
                                        '6d21b4833368d61c31275092886a4d9add8f7801abd7892d017b3f19a37b30c8'
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    'x-jwt-token': localStorage.getItem('token')
                                }
                            })
                            .then(
                                (status) => {
                                    let linkSource = status.data.result;
                                    let bonBagai = linkSource.split(';base64,');
                                    let mime = bonBagai[0].replace('data:', '').replace(/\\/, '');

                                    this.setState({
                                        loading: false,
                                        data: bonBagai[1],
                                        mime: mime,
                                        fileName: this.props.realName,
                                        open: true
                                    });
                                },
                                (error) => {
                                    this.props.pullWaitingList({
                                        key: this.props.keyUpload
                                    });
                                    this.setState({
                                        loading: false,
                                        error: true
                                    });
                                }
                            );
                    }}
                />
            );

            elt.doc_name = this.genLang(elt.doc_name);
            moment.locale(this.state.lang || 'fr');
            elt.date_createdLabel = moment(elt.date_created).format('LLLL');

            return {
                ...elt,
                preview: tablePreview
            };
        });

        return <MaterialTable data={collection} columns={COLUMNS_DOCUMENT} />;
    }

    renderNotes(collection) {
        return (
            <div className="p-3 neotextarea" style={{ 'border-radius': '5px' }}>
                <h5>Informations (Avocat)</h5>
                <hr />
                {Object.keys(collection['allFieldsNotes']).map((k, i) => {
                    let data = collection['allFieldsNotes'][k];
                    if (data.source == 0) {
                        return (
                            <div>
                                <b
                                    className="text-primary"
                                    style={{ display: data.label == '' ? 'none' : 'block' }}
                                >
                                    {data.label}
                                </b>
                                <br />
                                <div
                                    style={{
                                        backgroundColor: data.color,
                                        padding: '10px',
                                        'margin-bottom': '10px',
                                        'border-radius': '5px'
                                    }}
                                >
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: data.data_raw.replace('<br>', '')
                                        }}
                                        className="p-l-5"
                                    ></p>
                                    <small style={{ float: 'right' }}>
                    <span class="btnuser">
                      <i class="fas fa-user" aria-hidden="true"></i>{' '}
                        {data.author} | {data.creation_date}
                    </span>
                                    </small>
                                    <br />
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }

    renderDataForm(collection) {
        return (
            <div className="p-3" style={{ 'border-radius': '5px' }}>
                <h5>Informations (Client)</h5>
                <hr />
                {Object.keys(collection['allFieldsNotes']).map((k, i) => {
                    let data = collection['allFieldsNotes'][k];
                    if (data.source == 1) {
                        return (
                            <div>
                                <b
                                    className="text-primary"
                                    style={{ display: data.label == '' ? 'none' : 'block' }}
                                >
                                    {data.label}
                                </b>
                                <br />
                                <div
                                    style={{
                                        backgroundColor: data.color,
                                        padding: '10px',
                                        'margin-bottom': '10px',
                                        'border-radius': '5px'
                                    }}
                                >
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: data.data_raw.replace('<br>', '')
                                        }}
                                        className="p-l-5"
                                    ></p>
                                    <small style={{ float: 'right' }}>
                                        <span class="btnuser">
                                          <i class="fas fa-user" aria-hidden="true"></i>{' '}
                                            {data.author} | {data.creation_date}
                                        </span>
                                    </small>
                                    <br />
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }

    renderRow(rows, prefix = 'prefixTest', b, tabIndex = 1) {
        let arr = [];
        for (let r in rows) {
            let row = [];
            for (let c in rows[r]) {
                const name = rows[r][c].componentName;
                const params = {
                    ...rows[r][c],
                    name: prefix + '_' + rows[r][c].name,
                    tabindex: tabIndex++
                };
                const component = this.renderComponent(name, params);
                const title = this.renderComponentTitle(name, params);
                row.push(
                    <Col md={params.col || 12}>
                        {title}
                        {component}
                    </Col>
                );
            }
            arr.push(<Row key={'col_' + b + '_row_' + r}>{row}</Row>);
        }
        return arr || [];
    }

    renderBody(body) {
        let template = [];
        let b;
        let header;
        let rows;
        let col = [];
        let r;
        let TempTag;

        let temp = [];

        let section = [];
        let tabIndex = 0;
        let obj = undefined;

        let wizardBelt = [];
        let { forceDisplay } = this.props.layout
            ? this.props.layout.header
            : { forcedDisplay: false };

        for (b in body) {
            temp = [];
            header = body[b].header;
            rows = body[b].rows;
            const mode = this.props.formMode;
            if (header) {
                if (this.props.public && header?.private === true) break;
                if (mode !== 'wizard') {
                    section.push(
                        <LegendHeader
                            key={header.sectionPrefix + '_legend'}
                            target={HEADER_SECTION + header.sectionPrefix}
                            active={
                                this.state[HEADER_SECTION + header.sectionPrefix] || false
                            }
                            forceDisplay={forceDisplay}
                            callback={(key) => {
                                let obj = {};
                                obj[key] = !this.state[key];
                                this.setState(obj);
                            }}
                        >
                            {this.genLang(header.legend, this.state.lang)}
                        </LegendHeader>
                    );
                }

                let we = this.renderRow(rows, header.sectionPrefix, b) || [];

                if (mode === 'wizard') {
                    temp.push(
                        <LegendHeader
                            key={header.sectionPrefix + '_wizardry'}
                        >
                            <h2>{this.genLang(header.legend, this.state.lang)}</h2>
                        </LegendHeader>
                    );

                    wizardBelt.push(
                        <WizardBeltElement
                            key={header.sectionPrefix + '_legend'}
                            target={HEADER_SECTION + header.sectionPrefix}
                            active={
                                this.state[HEADER_SECTION + header.sectionPrefix] || false
                            }
                            callback={(key) => {
                                this.setState(
                                    (state) => {
                                        for (let s in state) {
                                            if (s.match(HEADER_SECTION)) {
                                                if (s.match(key)) {
                                                    state[s] = true;
                                                } else {
                                                    state[s] = false;
                                                }
                                            }
                                        }
                                        return state;
                                    },
                                    () => {
                                        debugger;
                                    }
                                );
                            }}
                        >
                            {this.genLang(header.legend, this.state.lang)}
                        </WizardBeltElement>
                    );
                }

                temp.push(...we);

                let title = this.genLang(header.sectionName || '', this.state.lang);
                let description = this.genLang(
                    header.sectionDescription || '',
                    this.state.lang
                );

                section.push(
                    <Section
                        ticket={this.props.ticket}
                        display={
                            forceDisplay || this.state[HEADER_SECTION + header.sectionPrefix]
                        }
                        key={HEADER_SECTION + header.sectionPrefix || 'whatever'}
                        title={title}
                        description={description}
                    >
                        {temp}
                    </Section>
                );
            }
        }
        let wiz = wizardBelt.length ? (
            <Row>
                <Col md={12}>
                    <div className="multisteps-form__progress m-t-20">{wizardBelt}</div>
                </Col>
            </Row>
        ) : (
            ''
        );

        return (
            <div>
                {wiz}
                <br />
                {section || []}
            </div>
        );
    }

    render() {
        let state = this.state;
        let customStyle = state && state.customStyle ? state.customStyle : '';

        let requestDoc = false;
        if (this.props.layout && this.props.layout.header) {
            requestDoc = this.props.layout.header.requestDocuments;
        }

        return (
            <>
                {customStyle &&
                    <style dangerouslySetInnerHTML={{ __html: customStyle }} />
                }

                <Form
                    id={'ultimateNeoForm'}
                    onInvalid={(e) => {
                        let state = this.state;
                        let obj = {};
                        for (let s in state) {
                            if (s.match(HEADER_SECTION)) obj[s] = true;
                        }
                        e.persist();
                        this.setState(obj);
                    }}
                    className="styled"
                    onChange={this.props.onChange}
                    ref={(el) => (this.form = el)}
                    action="post"
                >
                    <Row>
                        <Col md={12}>
                            <h3 className="form-header">
                                {this.genLang(state.formSubTitle || '', this.state.lang)}
                            </h3>
                            {((this.props.formNameBase === 'med_form' &&
                                        this.props.data !== undefined &&
                                        this.props.data.sign_date !== undefined) ||
                                    this.props.formNameBase !== 'med_form') &&
                                <div
                                    className="form-desc"
                                    dangerouslySetInnerHTML={{
                                        __html: this.genLang(
                                            state.formDescription || '',
                                            this.state.lang
                                        )
                                    }}
                                ></div>
                            }
                        </Col>
                    </Row>

                    <div
                        style={{
                            display:
                                !this.props.public &&
                                ((this.state.collection && this.state.collection.length) ||
                                    (this.props.dataFiles && this.props.dataFiles.length))
                                    ? BLOCK
                                    : NONE
                        }}
                    >
                        <Row>
                            <Col md={12}>
                                <DashboardElement
                                    col={12}
                                    title={this.genLang({
                                        fr: 'Rédaction',
                                        en: 'Redaction'
                                    })}
                                    nav={[
                                        {
                                            target: 'ticket',
                                            name: 'Tickets'
                                        },
                                        {
                                            target: 'document2',
                                            name: 'Documents'
                                        },
                                        {
                                            target: 'formulaire',
                                            name: 'Formulaires'
                                        },
                                        {
                                            target: 'notes',
                                            name: 'Notes'
                                        }
                                    ]}
                                    defaultActive={false}
                                    collapse={[
                                        {
                                            target: 'ticket',
                                            component: this.renderDocuments(this.state.collection)
                                        },
                                        {
                                            target: 'document2',
                                            component: this.renderClientDocuments(
                                                this.props.dataFiles
                                            )
                                        },
                                        {
                                            target: 'formulaire',
                                            component: this.renderDataForm(this.props)
                                        },
                                        {
                                            target: 'notes',
                                            component: this.renderNotes(this.props)
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>
                    {this.renderBody(state.body)}
                    <Row className="tw-mt-3">
                        <Col md={12}>
                            <AlertNotification
                                setParent={(data) => this.setState(data)}
                                color={state.alertColor}
                                visible={!!state.alert}
                                text={state.alert}
                            />
                        </Col>
                    </Row>
                    {requestDoc && !this.props.public ? (
                        <RequestedDocuments
                            options={this.props.listAttach}
                            lang={this.state.lang}
                            version={this.props.version}
                            allFieldsNotes={this.props.allFieldsNotes || {}}
                            allFieldsNotesInfo={
                                this.props.allFieldsNotesInfo || {
                                    ticket: this.state.ticket,
                                    form_name: 'med_form'
                                }
                            }
                            onChange={this.props.onChange}
                            token={this.props.token || localStorage.getItem('token')}
                        />
                    ) : (
                        ''
                    )}
                </Form>
                {state.open ? (
                    <PreviewModal
                        title={{
                            fr: 'Prévisualiser',
                            en: 'Preview'
                        }}
                        url={state.url}
                        mime={state.mime}
                        data={state.data}
                        name={state.fileName}
                        hideControls={true}
                        open={state.open}
                        callback={() => {
                            this.setState({
                                open: false
                            });
                        }}
                    />
                ) : (
                    ''
                )}
                <a
                    id="downloadAnchor"
                    style={{ display: NONE }}
                    href={this.state.blob}
                    download
                >
                    empty
                </a>
            </>
        );
    }
}

const mapStateToProps = state => ({
    rootsActions: rootsActions,
    loading: state.loading.loading,
    formData: state.forms.will
});

const mapActionsToProps = {
    removeBundle: rootsActions.formsActions.removeBundle,
    remove: rootsActions.formsActions.removeWill,
    put: rootsActions.formsActions.putWill,
    putField: rootsActions.requestActions.putField,
    putDocument: rootsActions.requestActions.putDocument,
    post: rootsActions.formsActions.postWill,
    toggleLoading: rootsActions.loadingActions.toggleLoading,
    removeFileFromGCloud: rootsActions.formsActions.removeFileFromGCloud,
    pushWaitingList: rootsActions.loadingActions.pushWaitingList,
    pullWaitingList: rootsActions.loadingActions.pullWaitingList
};

export const JSONForm = connect(mapStateToProps, mapActionsToProps)(JSONFormComponent);
