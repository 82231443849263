import React from 'react';
import { Link } from 'react-router-dom';

import { PublicLayout } from '@/layouts/PublicLayout';

import { useTranslation } from '@/composables/translation';
import { HomeIcon } from '@radix-ui/react-icons';
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';

export function NotFound() {
    const {
        ct,
        t
    } = useTranslation('not-found');
    return (
        <PublicLayout>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CardDescription>{t('description')}</CardDescription>
            </CardContent>
            <CardFooter>
                <Button className="!tw-p-0 !tw-h-4" variant="link" asChild>
                    <Link to="/">
                        <HomeIcon className="tw-mr-2" />
                        {ct('home')}
                    </Link>
                </Button>
            </CardFooter>
        </PublicLayout>
    );
}
