import React, { useMemo, useState } from 'react';
import { FormControl, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Combobox } from '@/components/ui/combobox';
import type { Party, UpdatePartyDto } from '@/types/api/party';
import { getOrgParties, postCreateParty, putUpdateParty } from '@/composables/api';
import { getFieldError } from '@/composables/validation';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { useClientSchema } from '@/pages/psj/Clients';
import { CrudDialog } from '@/components/ui/crud-table';
import { useTranslation } from '@/composables/translation';
import { toast } from 'react-toastify';
import { useError } from '@/composables/error';
import { getPartyName } from '@/composables/party';
import { Button } from '@/components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { cn } from '@/lib/utils';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Pencil2Icon } from '@radix-ui/react-icons';

interface Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends React.ComponentProps<ControllerProps<TFieldValues, TName>['render']> {
    label: string;
}

export function PartySelect<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ field: { ref, ...field }, formState, label }: Props<TFieldValues, TName>) {
    const { ct } = useTranslation();
    const { handleNetworkError } = useError();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<UpdatePartyDto>();
    const schema = useClientSchema();
    const CreatePartyDialog = useMemo(
        () => CrudDialog({ idKey: '_id', schema }),
        [schema]
    );
    const isEdit = !!field.value;
    return (
        <FormItem>
            <FormLabel>{label}</FormLabel>
            <FormControl>
                <div className="tw-flex">
                    <Combobox<Party, Party>
                        className="!tw-rounded-r-none"
                        getOptionsAsync={
                            (search_value) => getOrgParties({
                                search_index: true,
                                search_type: 'phrase',
                                search_value
                            })
                                .then((res) => res.data.data)
                        }
                        getOptionLabel={(opt) => getPartyName(opt, { include_phone: true, include_company: false })}
                        getValueLabel={(value) => getPartyName(value, { include_phone: true, include_company: false })}
                        creatable
                        clearable
                        onCreate={(value) => {
                            const [firstname, lastname] = value.split(/\s+/);
                            setValue({
                                firstname,
                                lastname
                            } as UpdatePartyDto);
                            setOpen(true);
                        }}
                        {...field}
                        innerRef={ref}
                        error={!!getFieldError(field.name, formState)}
                    />
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button
                                type="button" variant="outline"
                                className={cn(
                                    '!tw-px-3 tw-h-[37.6px] !tw-rounded-l-none',
                                    'tw-relative tw-left-[-1px] tw-text-muted-foreground'
                                )}
                                onClick={() => {
                                    setValue(field.value ?? undefined);
                                    setOpen(true);
                                }}
                            >
                                {isEdit
                                    ? <Pencil2Icon />
                                    : <FontAwesomeIcon icon={faUserPlus}/>
                                }
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {isEdit ? ct('edit') : ct('add')}
                        </TooltipContent>
                    </Tooltip>
                </div>
            </FormControl>
            <FormMessage />
            <CreatePartyDialog
                open={open}
                onOpenChange={setOpen}
                value={value}
                isUpdateDialog={isEdit}
                onSubmit={(value) => (value._id ? putUpdateParty : postCreateParty)({
                    ...value,
                    fullname: value.firstname || value.lastname
                        ? `${value.firstname ?? ''} ${value.lastname ?? ''}`
                        : ''
                })
                    .then((res) => {
                        field.onChange(res.data.party);
                        setOpen(false);
                        setValue(undefined);
                        toast(ct('messages.success'), { type: 'success' });
                    })
                    .catch(handleNetworkError)}
            />
        </FormItem>
    );
}
