import { useTranslation } from '@/composables/translation';
import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle
} from '@/components/ui/card';
import { postRequestAccessCode, postValidateAccessCode, postValidateJwt } from '@/composables/api';
import { useError } from '@/composables/error';
import { useNavigate, useParams } from 'react-router-dom';
import { TwoFA } from '@/pages/login/TwoFA';
import { ButtonAsync } from '@/components/ui/button-async';
import { Spinner } from '@/components/ui/spinner';
import { useSettingsStore } from '@/store/settings';
import { cn } from '@/lib/utils';

export function NeoFormPublicAuth() {
    const { t } = useTranslation('neoform.auth');
    const navigate = useNavigate();
    const { token } = useParams<{ token: string }>();
    const { handleNetworkError } = useError();
    const { logo, templates } = useSettingsStore(state => ({
        logo: state.logo,
        templates: state.templates
    }));

    const [loading, setLoading] = useState(false);
    const [show2FA, setShow2FA] = useState(false);
    const [isResend, setIsResend] = useState(false);

    function handleRequestCode() {
        return postRequestAccessCode(token ?? '')
            .then(() => {
                setShow2FA(true);
            })
            .catch(handleNetworkError);
    }

    function handleResendCode() {
        setLoading(true);
        return handleRequestCode()
            .finally(() => {
                setLoading(false);
                setIsResend(true);
            });
    }

    function handleSubmitCode({ otp }: { otp: string }) {
        return postValidateAccessCode(token ?? '', otp)
            .then((res) => {
                const token = res.data.token;
                navigate(`/public/${token}`);
            })
            .catch(handleNetworkError);
    }

    useEffect(() => {
        setLoading(true);
        postValidateJwt(token ?? '')
            .catch(() => navigate('/not-found'))
            .finally(() => setLoading(false));
    }, []);

    return (
        <div
            className={cn(
                'container-sm tw-py-3 tw-flex-1',
                'tw-flex tw-flex-col tw-justify-center tw-items-center'
            )}
        >
            {!loading && logo && typeof logo === 'string' && (
                <div className="tw-flex tw-justify-center tw-mb-8">
                    <img className="tw-h-[85px]" src={logo} alt="logo" />
                </div>
            )}
            {loading && <Spinner size="lg" className="tw-text-primary"/>}
            {!loading && <Card className="md:!tw-max-w-lg tw-w-[calc(100%_-_1.5rem)]">
                {!show2FA
                    ? <>
                        <CardHeader>
                            <CardTitle>{t('title')}</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="tw-prose tw-text-muted-foreground">
                                {templates?.['2FA']?.html
                                    ? <div dangerouslySetInnerHTML={{
                                        __html: templates['2FA'].html
                                    }}/>
                                    : <>
                                        <p>{t('description-0')}</p>
                                        <p>{t('description-1')}</p>
                                    </>
                                }
                            </div>
                        </CardContent>
                        <CardFooter>
                            <ButtonAsync
                                type="button"
                                className="tw-w-full"
                                onClick={handleRequestCode}
                            >
                                {t('send')}
                            </ButtonAsync>
                        </CardFooter>
                    </>
                    : <TwoFA
                        resend={isResend}
                        onResend={handleResendCode}
                        onSubmit={handleSubmitCode}
                    />
                }
            </Card>}
        </div>
    );
}
