import React, { Component } from 'react';
import { Col, Form, Row } from 'reactstrap';
import _ from 'lodash';
import { AlertNotification } from './AlertNotification';
import axios from 'axios';

import { redirect } from 'react-router-dom';
import rootsActions from '@/store/old/_actions/root-actions';
import { connect } from 'react-redux';

const PATH = import.meta.env.VITE_API_URL;

class SearchTicketComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ticket: this.props.ticket,
            alert: '',
            lang: this.props.lang,
            links: this.props.links || [],
            medcats: {},
            medcatCollection: [],
            medsubcatCollection: [],
            medsubcatHCollection: [],
            modele_type: '',
            topsel_domaine: '',
            topsel_subdomaine: '',
            newDoc: false,
            newMedForm: '',
            newFormNamePicked: '',
            medTicketData: null,
            medTicketLoadForm: null,
        };

        this.search = this.search.bind(this);
        this.newFormNamePicked = '';
    }

    componentDidMount() {
        //console.log('MYPROPS---', this.props);

        if (this.props.ticket && !this.props.public) {
            this.search();
        }
    }

    componentDidUpdate(prevProps) {
        let props = this.props;

        if (
            props.ticket !== prevProps.ticket &&
            props.ticket !== this.state.ticket &&
            !props.public
        ) {
            this.search();
        } else if (
            props.target !== prevProps.target &&
            this.props.formName !== 'med_form'
        ) {
            this.search();
        }
        let obj = {};
        if (props.links !== prevProps.links) {
            obj['links'] = props.links;
        }

        if (props.lang !== prevProps.lang) obj['lang'] = props.lang;

        if (!_.isEmpty(obj)) this.setState(obj);
    }

    search(e) {
        this.props.init();
        this.props.setTicket(this.state.ticket);
        let isItMED = false;
        let bareFormName = '';

        let target = this.props.target;

        if (
            this.props.formName !== undefined &&
            this.props.formName !== null &&
            this.props.formName === 'med_form'
        ) {
            isItMED = true;
            target = 'med';
        }
        if (
            this.props.bareFormName !== undefined &&
            this.props.bareFormName !== null
        ) {
            bareFormName = this.props.bareFormName;
        }
        let desk = this.props.controller || 'desk';

        this.props.toggleLoading();

        if (this.props.public && this.props.token) {
            axios
                .get(PATH + '/v1/form-data-by-id/' + this.props.task_id, {
                    params: {
                        auth_id: '',
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                })
                .then(
                    (e) => {
                        if (e.data.error === true) {
                            return this.setState({
                                redirect: 'expiration',
                            });
                        }
                        this.props.toggleLoading();
                        this.props.callback(e);
                    },
                    (error) => {
                        this.setState({
                            redirect: 'expiration',
                        });
                        this.props.toggleLoading();
                        if (error && error.response && error.response.status === 401) {
                            this.props.userUpdate({
                                type: this.props.rootsActions.LOGIN,
                                params: {
                                    logged: false,
                                },
                                status: 200,
                            });
                            this.setState({
                                redirect: true,
                            });
                        } else {
                            this.setState({ alert: 'ticket introuvable' });
                            this.props.init();
                        }
                    }
                );
        } else {
            axios
                .get(PATH + '/v1/form-data-by-id/' + this.props.task_id, {
                    params: {
                        form_name: this.props.formName,
                        auth_id: localStorage.getItem('user_id'),
                        // ticket_id: this.state.ticket,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                })
                .then(
                    (e) => {
                        this.props.toggleLoading();
                        this.props.callback(e);
                    },
                    (error) => {
                        this.props.toggleLoading();
                        if (error && error.response && error.response.status === 401) {
                            this.props.userUpdate({
                                type: this.props.rootsActions.LOGIN,
                                params: {
                                    logged: false,
                                },
                                status: 200,
                            });
                            this.setState({
                                redirect: true,
                            });
                        } else {
                            this.setState({ alert: 'ticket introuvable' });
                            this.props.init();
                        }
                    }
                );
        }
    }

    render() {
        if (this.state.redirect) {
            if (this.state.redirect === 'expiration') {
                redirect('/expiration');
                return (<></>);
            }

            redirect('/login');
            return (<></>);
        }

        if (this.props.public) {
            return <></>;
        }

        return (
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    this.search();
                }}
            >
                <Row>
                    <Col md={this.props.col}>
                        {this.state.alert &&
                            <AlertNotification
                                color={'danger'}
                                text={this.state.alert}
                                setParent={(data) => this.setState(data)}
                                visible={!!this.state.alert}
                            />
                        }
                    </Col>
                </Row>
            </Form>
        );
    }

    genLang = (key = 'missing') => {
        const lang =
            this.state.lang === '' ||
            this.state.lang === null ||
            this.state.lang === undefined
                ? 'fr'
                : this.state.lang;
        if (!this.props.trad) return key;

        if (!this.props.trad[key]) {
            return this.props.trad['_wrongKey'][lang] + ' ' + key;
        }

        return this.props.trad[key][lang];
    };
}

const mapStateToProps = (state) => ({
    rootsActions: rootsActions,
    users: state.users,
});

const mapActionsToProps = {
    userUpdate: rootsActions.usersActions.onLogin,
    toggleLoading: rootsActions.loadingActions.toggleLoading,
};

export const SearchTicket = connect(mapStateToProps, mapActionsToProps)(SearchTicketComponent);
