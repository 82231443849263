import React from 'react';
import { NeoFormComponent } from '@/components/neoform/NeoFormComponent';
import { useNeoForm } from '@/composables/neoform';
import { combine } from '@/composables/translation';
import { type UserChild } from '@/components/neoform/complex/UserChildDivorce';
import { type SelectOption } from '@/components/neoform/inputs/InputSelect';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { Row } from '@/components/ui/row';

interface Props {
    childrenListTarget: string;
    residence?: boolean;
    income?: boolean;
}

export function UserChildInfoDivorce(props: Props) {
    const { form } = useNeoForm();
    const children = form?.watch<UserChild[]>(props.childrenListTarget) ?? [];
    const childrenOptions = children
        .map<[string, UserChild]>((child, index) => [
            [props.childrenListTarget, index].join('.'),
            child
        ])
        .filter(([_, child]) => {
            const isAdult = (child.dateOfBirth?.diffNow('years').years ?? 0) < -18;
            return isAdult && !child.autonomous && !child.working && !child.graduated;
        })
        .map<SelectOption>(([id, child]) => ({
            value: id,
            label: `${child.user?.firstname ?? ''} ${child.user?.name ?? ''}`
        }))
        .concat([{ value: '-', label: combine('common.none') }]);
    return (
        <>
            <Row>
                <NeoFormComponent
                    col={6}
                    name="child"
                    componentName="InputSelect"
                    title={combine('neoform.user-child-info.child')}
                    titleType={NeoFormTitleType.LABEL}
                    options={childrenOptions}
                    default="-"
                    last="md"
                />
                <NeoFormComponent
                    col={6}
                    name="residence"
                    componentName="InputSelect"
                    title={combine('neoform.user-child-info.residence')}
                    titleType={NeoFormTitleType.LABEL}
                    options={[
                        { value: 'propre', label: combine('neoform.user-child-info.residence-0') },
                        { value: 'partie0', label: combine('neoform.user-child-info.residence-1') },
                        { value: 'partie1', label: combine('neoform.user-child-info.residence-2') }
                    ]}
                    display={!!props.residence}
                    last
                />
                <NeoFormComponent
                    col={6}
                    name="income"
                    componentName="InputNumber"
                    title={combine('neoform.user-child-info.income')}
                    titleType={NeoFormTitleType.LABEL}
                    amount
                    display={!!props.income}
                    last
                />
            </Row>
        </>
    );
}
