import React, { Component } from 'react';

import _ from 'lodash';
import InputMask from 'react-input-mask';
import { Col, FormGroup, Label } from 'reactstrap';

const DEFAULT_MASK_VALUE = '(   )    -    ';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const DEFAULT_STYLE = { textAlign: 'left' };

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class Phone extends Component {
    constructor(props) {
        super(props);
        let fd = this.props.formData;

        this.state = {
            value: fd
                ? fd[this.props.name]
                    ? fd[this.props.name]
                    : this.props.default
                : this.props.value,
            display: this.props.defaultDisplay === false ? false : true,
        };
    }

    componentDidUpdate(prevprops) {
        let obj = {},
            props = this.props;

        if (this.props.value !== prevprops.value) {
            obj.value = this.props.value;
        }

        if (
            props.formData[props.name] &&
            props.formData[props.name] !== prevprops.formData[props.name]
        ) {
            obj['value'] = this.genLang(props.formData[props.name], this.state.lang);
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (!_.isEmpty(obj)) this.setState(obj);
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;

        let style = {};
        // if(this.state.display ===false)
        //     return <></>;

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        if (this.props.noCol === true) {
            return (
                <InputMask
                    name={this.props.name}
                    value={this.state.value || ''}
                    mask={this.props.mask || '(999) 999-9999'}
                    maskChar=" "
                    className="form-control"
                    onChange={(e) => {
                        this.props.put({
                            params: {
                                key: this.props.name,
                                value: '',
                                title: this.props.title,
                            },
                        });
                        if (e.target.value.match(DEFAULT_MASK_VALUE)) {
                            this.setState({
                                value: '',
                            });
                        } else {
                            this.setState({
                                value: e.target.value,
                            });
                        }
                    }}
                />
            );
        }
        return (
            <>
                <FormGroup>
                    <InputMask
                        name={this.props.name}
                        value={this.state.value || ''}
                        mask={this.props.mask || '(999) 999-9999'}
                        maskChar=" "
                        className="form-control"
                        onChange={(e) => {
                            this.props.put({
                                params: {
                                    key: this.props.name,
                                    value: '',
                                    title: this.props.title,
                                },
                            });
                            if (e.target.value.match(DEFAULT_MASK_VALUE)) {
                                this.setState({
                                    value: '',
                                });
                            } else {
                                this.setState({
                                    value: e.target.value,
                                });
                            }
                        }}
                    />
                </FormGroup>
            </>
        );
    }
}
