import React, { Component } from 'react';

import { Button, Col, FormGroup, Label } from 'reactstrap';

import _ from 'lodash';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;

const DEFAULT_STYLE = { textAlign: 'left' };

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

const NGROK = import.meta.env.VITE_API_URL;

export class AbstractComponent extends Component {

    constructor(props) {
        super(props);

        let fd = this.props.formData;
        this.state = {
            abstracts: [],
            abstractList: [],
            value: fd && fd[this.props.name] ? fd[this.props.name] : '',
            lang: this.props.lang || FR,
            placeholder: this.props.placeholder ? this.genLang(props.placeholder, props.lang) : '',
            display: this.props.defaultDisplay !== false,
            counter: 0
        };

        if (!props.name) {
            console.error('!!! name is missing !!!');
        }

        this.addAbstract = this.addAbstract.bind(this);
        this.removeAbstract = this.removeAbstract.bind(this);

    }

    componentDidMount() {
        if (!this.props.json) {
            axios
                .get(
                    NGROK + '/desk/jsoncomponents', {
                        params: {
                            id: this.props.id,
                            collection: this.props.collection,
                            AUTH_ID: 38 || localStorage.getItem('auth_id')
                        },
                        auth: {
                            username: 'admin',
                            password: '6d21b4833368d61c31275092886a4d9add8f7801abd7892d017b3f19a37b30c8'
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'x-jwt-token': localStorage.getItem('token')
                        }
                    })
                .then(
                    (status) => {
                        this.setState({
                            json: (status && status.data && status.data.result) ? status.data.result.json : ''
                        });
                    },
                    (error) => {
                        console.log('error');
                    }
                );
        }
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            obj['value'] = props.formData[props.name] || props.default;
            this.props.put({
                params: {
                    key: this.props.name,
                    value: props.formData[props.name] || props.default
                }
            });
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            obj['value'] = props.formData[props.name];
        }

        if (props.placeholder !== prevprops.placeholder) {
            obj['placeholder'] = props.placeholder;
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (props.display !== prevprops.display) {

            if (props.display === false && prevprops.display === true) {
                obj.display = false;
            } else if (props.display === true && prevprops.display === false) {
                obj.display = true;
            } else if (prevprops.display === undefined) {
                obj.display = props.display;
            }
        }

        if (props.formData && JSON.stringify(props.formData) !== JSON.stringify(prevprops.formData) || this.state.force) {
            let hamza = {};
            let fd = this.props.formData;
            let reg = new RegExp('^' + this.props.name);
            const abstractList = [];
            for (let f in fd) {
                if (f.match(reg)) {
                    let split = f.split(reg);
                    hamza[split[1].match(/\d+/)[0]] = true;
                }
            }

            let arr = [];

            for (let h in hamza) {
                let key = this.props.name + '_' + h;
                abstractList.push({
                    key: key,
                    counter: h
                });

                arr.push(
                    <div key={key}>
                        <span className="remove" style={{ zIndex: 9999 }} onClick={() => this.removeAbstract(key)}>
                            {cross}
                        </span>
                        {this.props.renderRow ? this.props.renderRow(this.props.json || [], key) : 'oupsy'}
                    </div>
                );
            }
            obj = {
                ...obj,
                abstracts: arr,
                counter: arr.length + 10,
                force: false,
                abstractList: abstractList

            };
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    addAbstract() {

        this.setState(state => {

            let counter = this.state.counter;
            let key = this.props.name + '_' + counter++;

            state.counter = counter;
            state.abstractList.push({
                key: key,
                counter: counter
            });
            state.abstracts.push(
                <div key={key}>
                    {this.props.renderRow(this.props.json || [], key)}
                </div>
            );
            return state;
        });
    }

    removeAbstract(id) {
        this.props.remove({ params: { key: id } });
        this.setState({
            abstracts: _.reject(this.state.abstracts, { key: id }),
            abstractList: _.reject(this.state.abstractList, { key: id }),
        });
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true && window.location.host.match('localhost')) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        if (this.props.multi === true) {
            const abstractList = this.state.abstractList || [];

            return (
                <>
                    {abstractList.map(form => (
                        // <div key={form.key}>{form}</div>
                        <div key={form.key}>
                        <span className="remove" style={{ zIndex: 9999 }} onClick={() => this.removeAbstract(form.key)}>
                            {cross}
                        </span>
                            {this.props.renderRow ? this.props.renderRow(this.props.json || [], form.key) : 'oupsy'}
                        </div>
                    ))}

                    <FormGroup className="buttonHolder">
                        <Button
                            className="tw-mt-3"
                            block
                            outline
                            color="primary"
                            onClick={() => this.addAbstract()}
                        >
                            {this.genLang(this.props.buttonName || 'Ajouter')}
                        </Button>
                    </FormGroup>
                </>
            );
        }

        return (
            <Col md={props.col}>
                <FormGroup>
                    {props.title ? <Label><h5>{this.genLang(props.title)}</h5></Label> : ''}
                </FormGroup>
                {/* state => du serveur props de prop json :) ne communiquent pas ensemble et le prop override le state  */}
                {this.props.renderRow ? this.props.renderRow(this.state.json || this.props.json || [], this.props.name) : 'oupsy'}
            </Col>
        );
    }

}
