import React, { Component } from 'react';
import {
    FormGroup,
    Input,
    Label,
    Row,
    Col,
    Card,
    CardBody,
    CardText,
} from 'reactstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { Address } from './Address';
import { CheckInput } from './CheckInput';
import { Calendar } from './Calendar';
import { RadioInput } from './RadioInput';
import { User } from './User';
import { Actionnaires2 } from './Actionnaires2';
import { CategoryActions } from './CategoryActions';

const DEFAULT_STYLE = {};

const cross = <FontAwesomeIcon icon={faWindowClose} className="avensRed" />;

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class Actionnaire2 extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData ? this.props.formData : '';
        this.state = {
            lang: this.props.lang || 'fr',
            // adressYesNo: fd[this.props.name+"_adressYesNo"]||'yes',
            actionnaire: fd[this.props.name + '_actionnaire'],
            data: fd,
            moreThan25: false,
        };

        this.option = this.option.bind(this);
        this.genLang = this.genLang.bind(this);
    }

    genLang(key = 'missing') {
        if (!this.props.trad) return key;

        if (!this.props.trad[key]) {
            return this.props.trad['wrongKey'][this.state.lang] + ' ' + key;
        }
        return this.props.trad[key][this.state.lang];
    }

    genLangNew(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidMount() {
        console.log(this.props);
        const percent = this.props.moreThan25(
            this.props.name + '_numberActionAssujetti',
            this.props.formData[this.props.name + '_numberActionAssujetti']
        );
        if (percent === true) this.setState({ moreThan25: true });
    }

    componentDidUpdate(prevProps) {
        // console.log(this.props)
        let props = this.props,
            obj = {};

        if (
            (prevProps.formData && props.formData) ||
            props.version !== prevProps.version
        ) {
            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }

            if (
                props.formData[props.name + '_name'] !==
                prevProps.formData[props.name + '_name']
            ) {
                obj['name'] = props.formData[props.name + '_name'] || '';
            }

            if (
                props.formData[props.name + '_actionnaire'] !==
                prevProps.formData[props.name + '_actionnaire']
            ) {
                obj['actionnaire'] = props.formData[props.name + '_actionnaire'] || '';
            }

            if (props.display !== prevProps.display) {
                if (props.display === false && prevProps.display === true) {
                    obj.display = false;
                } else if (props.display === true && prevProps.display === false) {
                    obj.display = true;
                } else if (prevProps.display === undefined) {
                    obj.display = props.display;
                }
            }

            if (prevProps.formData !== props.formData) {
                obj.data = this.props.formData;
            }
        }

        if (!_.isEmpty(obj)) this.setState(obj);

        if (props.lang !== prevProps.lang) {
            let obj = {};

            if (prevProps.lang !== props.lang) {
                obj.lang = props.lang;
            }

            if (prevProps.trad !== props.trad) {
                obj.trad = props.trad;
            }
            if (!_.isEmpty(obj)) {
                this.setState(obj);
                //console.log("aaaaaaaaa7777777777777777777777777.222");
            }
            //console.log("aaaaaaaaa7777777777777777777777777bbbbbbbbbb222", obj);
        }
    }

    option() {
        if (this.props.assistant) {
            return (
                <Col md={6}>
                    <FormGroup>
                        <Label>Assistant</Label>
                        <Input
                            type="text"
                            name={this.props.name + '_assistant'}
                            onChange={(e) => this.setState({ assistant: e.target.value })}
                            value={this.state.assistant || ''}
                        />
                    </FormGroup>
                </Col>
            );
        }
    }

    updateDate(e) {
        var date = moment().format('DD/MM/YYYY');
        this.setState({
            Birthday: date,
            certificatDate: date
        });
    }

    checkPersonne(e) {
        this.setState({ personnePhysique: e.target.checked });
    }

    render() {
        const genderOptions = [
            {
                label: this.genLang('_Autre'),
                value: '-'
            },
            {
                label: this.genLang('_sir'),
                value: 'M'
            },
            {
                label: this.genLang('_madam'),
                value: 'F'
            },
        ];

        let props = this.props;
        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        if (this.props.noCard) {
            return (
                <Col md={this.props.col || 12} style={style}>
                    {this.props.remove ? (
                        <span
                            className="remove"
                            onClick={() => {
                                this.props.removeActionnaires(this.props.name);
                                this.props.remove(this.props.name);
                            }}
                        >
              {cross}
            </span>
                    ) : (
                        ''
                    )}
                    {
                        <Row>
                            <Col md={12}>
                                <FormGroup tag="fieldset" className="!tw-mb-0">
                                    <legend>
                                        <h5>
                                            {this.genLangNew(this.props.title, this.state.lang)}
                                        </h5>
                                    </legend>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>{this.genLang('_name')}</Label>
                                <Input
                                    type="text"
                                    name={this.props.name + '_name'}
                                    onChange={(e) => {
                                        this.props.put({
                                            params: {
                                                key: e.target.name,
                                                value: e.target.value,
                                                title: this.props.trad['_name'],
                                            },
                                        });
                                        this.setState({ name: e.target.value });
                                    }}
                                    value={this.state.name}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            );
        }

        return (
            <Col md={this.props.col || 12} style={style}>
                <Card style={{ marginBottom: '5px' }}
                >
                    {this.props.remove ? (
                        <span
                            className="remove"
                            onClick={() => {
                                this.props.removeActionnaires(this.props.name);
                                this.props.remove(this.props.name);
                            }}
                        >
              {cross}
            </span>
                    ) : (
                        ''
                    )}
                    <CardBody>
                        <CardText tag="div">
                            <Row>
                                <Col md={12}>
                                    <FormGroup tag="fieldset" className="!tw-mb-0">
                                        <legend>
                                            <h5>
                                                {this.genLangNew(this.props.title, this.state.lang)}
                                            </h5>
                                        </legend>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <CheckInput
                                            col={12}
                                            name={this.props.name + '_personnePhysique'}
                                            title={this.genLang('_personnePhysique')}
                                            formData={this.props.formData}
                                            value={
                                                this.props.formData[
                                                this.props.name + '_personnePhysique'
                                                    ]
                                            }
                                            put={this.props.put}
                                            callback={(e) => this.checkPersonne(e)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {this.props.formData[this.props.name + '_personnePhysique'] ==
                            'true' ? (
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>
                                                <h5>{this.genLang('_numberAction')}</h5>
                                            </Label>
                                            <Input
                                                type="number"
                                                // key={}
                                                name={this.props.name + '_numberActionAssujetti'}
                                                onChange={(e) => {
                                                    this.props.put({
                                                        params: {
                                                            key: e.target.name,
                                                            value: e.target.value,
                                                            title: this.props.trad['_numberAction'],
                                                        },
                                                    });
                                                    if (
                                                        this.props.moreThan25(
                                                            e.target.name,
                                                            e.target.value
                                                        ) === true
                                                    ) {
                                                        this.setState({ moreThan25: true });
                                                    } else {
                                                        this.setState({ moreThan25: false });
                                                    }
                                                    this.setState({
                                                        numberActionAssujetti: e.target.value,
                                                    });
                                                }}
                                                // onChange={(e) => {
                                                //     // console.log(this.props.parent)
                                                //     // this.props.moreThan25(e.target.name);
                                                //     this.setState({numberActionNo:e.target.value})}}
                                                // value={this.state.numberAction}
                                                value={
                                                    this.state.data[
                                                    this.props.name + '_numberActionAssujetti'
                                                        ]
                                                }
                                                // value={this.props.formData[this.props.name+"_numberActionAssujetti"]}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>{this.genLang('_name')}</Label>
                                            <Input
                                                type="text"
                                                name={this.props.name + '_lastname'}
                                                onChange={(e) => {
                                                    this.props.put({
                                                        params: {
                                                            key: e.target.name,
                                                            value: e.target.value,
                                                            title: this.props.trad['_name'],
                                                        },
                                                    });
                                                    this.setState({ lastname: e.target.value });
                                                }}
                                                value={this.state.lastname}
                                                defaultValue={
                                                    this.props.formData[this.props.name + '_lastname']
                                                }
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>{this.genLang('_firstname')}</Label>
                                            <Input
                                                type="text"
                                                name={this.props.name + '_firstname'}
                                                onChange={(e) => {
                                                    this.props.put({
                                                        params: {
                                                            key: e.target.name,
                                                            value: e.target.value,
                                                            title: this.props.trad['_firstname'],
                                                        },
                                                    });
                                                    this.setState({ firstname: e.target.value });
                                                }}
                                                value={this.state.firstname}
                                                defaultValue={
                                                    this.props.formData[this.props.name + '_firstname']
                                                }
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>{this.genLang('_title')}</Label>
                                            <Input
                                                type="select"
                                                name={this.props.name + '_gender'}
                                                onChange={(e) => {
                                                    this.props.put({
                                                        params: {
                                                            key: e.target.name,
                                                            value: e.target.value,
                                                            title: this.props.trad['_title'],
                                                        },
                                                    });
                                                    this.setState({ gender: e.target.value });
                                                }}
                                                value={this.props.formData[this.props.name + '_gender']}
                                            >
                                                <option value="-">{this.genLang('_Autre')}</option>
                                                <option value="M">{this.genLang('_sir')}</option>
                                                <option value="F">{this.genLang('_madam')}</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col md={3}>
                                        <Label>{this.genLang('dateOfBirth')}</Label>
                                        <Calendar
                                            put={this.props.put}
                                            remove={this.props.remove}
                                            style={{ paddingLeft: '0px' }}
                                            lang={this.state.lang}
                                            version={this.state.version}
                                            title={this.props.trad['dateOfBirth']}
                                            key={this.props.name + '_Birthday'}
                                            name={this.props.name + '_Birthday'}
                                            callback={(e) => this.updateDate(e)}
                                            formData={{ ...this.state.data }}
                                        />
                                    </Col>

                                    <Col md={12}>
                                        <Address
                                            col={12}
                                            put={this.props.put}
                                            lang={this.state.lang}
                                            trad={this.props.trad}
                                            formData={{ ...this.props.formData }}
                                            remove={this.removeHeirForm}
                                            name={this.props.name}
                                            version={this.state.version}
                                            target={this.props.title}
                                            country={true}
                                        />
                                    </Col>

                                    {this.state.moreThan25 && (
                                        <>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label>
                                                        <h5>{this.genLang('_otherName')}</h5>
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        name={this.props.name + '_otherName'}
                                                        onChange={(e) => {
                                                            this.props.put({
                                                                params: {
                                                                    key: e.target.name,
                                                                    value: e.target.value,
                                                                    title: this.props.trad['_otherName'],
                                                                },
                                                            });
                                                            this.setState({ name: e.target.value });
                                                        }}
                                                        value={this.state.name}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label
                                                        style={{
                                                            marginBottom: '-9rem',
                                                            marginTop: '1rem'
                                                        }}
                                                    >
                                                        <h5>{this.genLang('_addressCanada')}</h5>
                                                    </Label>
                                                    <RadioInput
                                                        lang={this.state.lang}
                                                        key={this.props.name + '_adressYesNo'}
                                                        name={this.props.name + '_adressYesNo'}
                                                        default={'yes'}
                                                        options={[
                                                            {
                                                                value: 'yes',
                                                                label: 'Oui',
                                                            },
                                                            {
                                                                value: 'no',
                                                                label: 'Non',
                                                            },
                                                        ]}
                                                        value={
                                                            this.props.formData[
                                                            this.props.name + '_adressYesNo'
                                                                ]
                                                        }
                                                        formData={this.props.formData}
                                                        put={this.props.put}
                                                        callback={(e) => {
                                                            this.props.put({
                                                                params: {
                                                                    key: e.target.name,
                                                                    value: e.target.value,
                                                                    title: this.props.trad['_addressCanada'],
                                                                },
                                                            });
                                                            this.setState({ adressYesNo: e.value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            {this.props.formData[this.props.name + '_adressYesNo'] ==
                                            'yes' ? (
                                                <Col md={12}>
                                                    <Address
                                                        col={12}
                                                        put={this.props.put}
                                                        lang={this.state.lang}
                                                        trad={this.props.trad}
                                                        formData={{ ...this.props.formData }}
                                                        remove={this.removeHeirForm}
                                                        name={this.props.name + '_addressCompany'}
                                                        key={this.props.name + '_addressCompany'}
                                                        version={this.state.version}
                                                        target={this.props.title}
                                                        country={true}
                                                    />
                                                </Col>
                                            ) : (
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label>{this.genLang('_otherAddress')}</Label>
                                                        <Input
                                                            type="textarea"
                                                            name={this.props.name + '_otherAddress'}
                                                            rows={4}
                                                            defaultValue={
                                                                this.props.formData[
                                                                this.props.name + '_otherAddress'
                                                                    ] || ''
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            )}

                                            <Col md={6}>
                                                <Label>{this.genLang('dateOfBirth')}</Label>
                                                <Calendar
                                                    put={this.props.put}
                                                    remove={this.props.remove}
                                                    style={{ paddingLeft: '0px' }}
                                                    lang={this.state.lang}
                                                    version={this.state.version}
                                                    title={this.props.trad['dateOfBirth']}
                                                    key={this.props.name + '_Birthday'}
                                                    name={this.props.name + '_Birthday'}
                                                    callback={(e) => this.updateDate(e)}
                                                    formData={{ ...this.state.data }}
                                                />
                                            </Col>

                                            <Col md={6}>
                                                <Label>{this.genLang('_beneficiaryStartDate')}</Label>
                                                <Calendar
                                                    put={this.props.put}
                                                    remove={this.props.remove}
                                                    style={{ paddingLeft: '0px' }}
                                                    lang={this.state.lang}
                                                    version={this.state.version}
                                                    title={this.props.trad['dateOfBirth']}
                                                    key={this.props.name + '_beneficiaryStartDate'}
                                                    name={this.props.name + '_beneficiaryStartDate'}
                                                    callback={(e) => this.updateDate(e)}
                                                    formData={{ ...this.state.data }}
                                                />
                                            </Col>

                                            <Col md={6}>
                                                <Label>{this.genLang('_beneficiaryRetraitDate')}</Label>
                                                <Calendar
                                                    put={this.props.put}
                                                    remove={this.props.remove}
                                                    style={{ paddingLeft: '0px' }}
                                                    lang={this.state.lang}
                                                    version={this.state.version}
                                                    title={this.props.trad['_beneficiaryRetraitDate']}
                                                    key={this.props.name + '_beneficiaryRetraitDate'}
                                                    name={this.props.name + '_beneficiaryRetraitDate'}
                                                    callback={(e) => this.updateDate(e)}
                                                    formData={{ ...this.state.data }}
                                                />
                                            </Col>

                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>{this.genLang('_controlType')}</Label>
                                                    <Input
                                                        type="select"
                                                        name={this.props.name + '_controlType'}
                                                        onChange={(e) => {
                                                            this.props.put({
                                                                params: {
                                                                    key: e.target.name,
                                                                    value: e.target.value,
                                                                    title: this.props.trad['_controlType'],
                                                                },
                                                            });
                                                            this.setState({ controlType: e.value });
                                                        }}
                                                        value={
                                                            this.props.formData[
                                                            this.props.name + '_controlType'
                                                                ]
                                                        }
                                                    >
                                                        <option value="direct">
                                                            {this.genLang('_controlDirect')}
                                                        </option>
                                                        <option value="indirect">
                                                            {this.genLang('_controlIndirect')}
                                                        </option>
                                                        <option value="defait">
                                                            {this.genLang('_defait')}
                                                        </option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            ) : (
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>
                                                <h5>{this.genLang('_formeJuridique')}</h5>
                                            </Label>
                                            <Input
                                                type="select"
                                                key={this.props.name + '_formeJuridique'}
                                                name={this.props.name + '_formJuridique'}
                                                value={
                                                    this.props.formData[
                                                    this.props.name + '_formJuridique'
                                                        ]
                                                }
                                                onChange={(e) => {
                                                    this.props.put({
                                                        params: {
                                                            key: e.target.name,
                                                            value: e.target.value,
                                                            title: this.props.trad['_formeJuridique'],
                                                        },
                                                    });
                                                    this.setState({ formJuridique: e.target.value });
                                                }}
                                                //  value={this.state.formJuridique||'-'}
                                            >
                                                <option value=""></option>
                                                <option value="societeIndividuelle">
                                                    {this.genLang('_societeIndividuelle')}
                                                </option>
                                                <option value="direct">
                                                    {this.genLang('_societeParActions')}
                                                </option>
                                                <option value="defait">
                                                    {this.genLang('_societeEnNomCollectif')}
                                                </option>

                                                <option value="societeCommandite">
                                                    {this.genLang('_societeCommandite')}
                                                </option>

                                                <option value="societeParticipation">
                                                    {this.genLang('_societeParticipation')}
                                                </option>

                                                <option value="personneMorale">
                                                    {this.genLang('_personneMorale')}
                                                </option>

                                                <option value="syndicat">
                                                    {this.genLang('_syndicat')}
                                                </option>

                                                <option value="association">
                                                    {this.genLang('_association')}
                                                </option>

                                                <option value="groupPeople">
                                                    {this.genLang('_groupPeople')}
                                                </option>

                                                <option value="cooperative">
                                                    {this.genLang('_cooperative')}
                                                </option>

                                                <option value="fiducieNoUnit">
                                                    {this.genLang('_fiducieNoUnit')}
                                                </option>

                                                <option value="fiducieWithUnit">
                                                    {this.genLang('_fiducieWithUnit')}
                                                </option>

                                                {/* <option value="NA" >Autre</option> */}
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    {this.props.formData[this.props.name + '_formJuridique'] ==
                                        'direct' && (
                                            <>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Label>
                                                            <h5>{this.genLang('_numberActionAssujetti')}</h5>
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            key={this.props.name + '_numberActionAssujetti'}
                                                            name={this.props.name + '_numberActionAssujetti'}
                                                            onChange={(e) => {
                                                                this.props.put({
                                                                    params: {
                                                                        key: e.target.name,
                                                                        value: e.target.value,
                                                                        title: this.props.trad['_formeJuridique'],
                                                                    },
                                                                });
                                                                if (
                                                                    this.props.moreThan25(
                                                                        e.target.name,
                                                                        e.target.value
                                                                    ) === true
                                                                ) {
                                                                    this.setState({ moreThan25: true });
                                                                } else {
                                                                    this.setState({ moreThan25: false });
                                                                }
                                                                this.setState({
                                                                    numberActionAssujetti: e.target.value,
                                                                });
                                                            }}
                                                            defaultValue={
                                                                this.props.formData[
                                                                this.props.name + '_numberActionAssujetti'
                                                                    ]
                                                            }
                                                            value={this.state.numberActionAssujetti}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                {this.state.moreThan25 && (
                                                    <>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label>
                                                                    <h5>{this.genLang('_name')}</h5>
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    key={this.props.name + '_companyname'}
                                                                    name={this.props.name + '_companyname'}
                                                                    onChange={(e) => {
                                                                        this.props.put({
                                                                            params: {
                                                                                key: e.target.name,
                                                                                value: e.target.value,
                                                                                title: this.props.trad['_name'],
                                                                            },
                                                                        });
                                                                        this.setState({
                                                                            companyname: e.target.value,
                                                                        });
                                                                    }}
                                                                    defaultValue={
                                                                        this.props.formData[
                                                                        this.props.name + '_companyname'
                                                                            ]
                                                                    }
                                                                    value={this.state.companyname}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <CategoryActions
                                                            onSelectChange={this.props.onSelectChange}
                                                            lang={this.state.lang}
                                                            version={this.state.version}
                                                            isMulti={true}
                                                            key={this.props.name + '_categoryActions'}
                                                            name={this.props.name + '_categoryActions'}
                                                            formData={this.props.formData}
                                                            put={this.props.put}
                                                            defaultDisplay={true}
                                                            display={this.props.display}
                                                            trad={this.props.trad}
                                                            title={{
                                                                en: 'Category Actions',
                                                                fr: 'Categorie d\'actions',
                                                            }}
                                                            removeSingleActionVote={
                                                                this.props.removeSingleActionVote
                                                            }
                                                            removeAllActionVote={this.props.removeAllActionVote}
                                                            removeAllCategoryAction={
                                                                this.props.removeAllCategoryAction
                                                            }
                                                            sumAllCategoryAction={
                                                                this.props.sumAllCategoryAction
                                                            }
                                                            removeWill={this.props.removeWill}
                                                            handleSumActionVote={this.props.handleSumActionVote}
                                                            public={this.props.public}
                                                        />

                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label>
                                                                    <h5>{this.genLang('_numberAction')}</h5>
                                                                </Label>
                                                                <Input
                                                                    key={this.props.name + '_numberActionVote'}
                                                                    type="number"
                                                                    name={this.props.name + '_numberActionVote'}
                                                                    onChange={(e) => {
                                                                        this.props.put({
                                                                            params: {
                                                                                key: e.target.name,
                                                                                value: e.target.value,
                                                                                title: this.props.trad['_numberAction'],
                                                                            },
                                                                        });
                                                                        this.props.onChange(e);
                                                                        this.setState({
                                                                            numberActionVote: e.target.value,
                                                                        });
                                                                    }}
                                                                    value={
                                                                        this.props.formData[
                                                                        this.props.name + '_numberActionVote'
                                                                            ] || 0
                                                                    }
                                                                    // defaultValue={
                                                                    //   this.props.formData[
                                                                    //     this.props.name + "_numberActionVote"
                                                                    //   ] || 0
                                                                    // }
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <RadioInput
                                                                    lang={this.state.lang}
                                                                    title={this.genLang('_conventionEntre')}
                                                                    key={this.props.name + '_conventionEntre'}
                                                                    name={this.props.name + '_conventionEntre'}
                                                                    default={'no'}
                                                                    options={[
                                                                        {
                                                                            value: 'yes',
                                                                            label: 'Oui',
                                                                        },
                                                                        {
                                                                            value: 'no',
                                                                            label: 'Non',
                                                                        },
                                                                    ]}
                                                                    formData={this.props.formData}
                                                                    value={
                                                                        this.props.formData[
                                                                        this.props.name + '_conventionEntre'
                                                                            ]
                                                                    }
                                                                    put={this.props.put}
                                                                    callback={(e) => {
                                                                        this.setState({ conventionEntre: e.value });
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        {this.props.formData[
                                                        this.props.name + '_conventionEntre'
                                                            ] == 'yes' && (
                                                            <>
                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <Label>{this.genLang('_detail')}</Label>
                                                                        <Input
                                                                            type="textarea"
                                                                            name={this.props.name + '_infosEntreVote'}
                                                                            rows={4}
                                                                            onChange={(e) => {
                                                                                this.props.put({
                                                                                    params: {
                                                                                        key: e.target.name,
                                                                                        value: e.target.value,
                                                                                        title: this.props.trad['_detail'],
                                                                                    },
                                                                                });
                                                                            }}
                                                                            value={
                                                                                this.props.formData[
                                                                                this.props.name + '_infosEntreVote'
                                                                                    ]
                                                                            }
                                                                            // defaultValue={this.props.formData[this.props.name+"_infosEntreVote"]||''}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <Label>
                                                                            <h5>{this.genLang('_numberAction')}</h5>
                                                                        </Label>
                                                                        <Input
                                                                            type="number"
                                                                            name={
                                                                                this.props.name +
                                                                                '_numberActionConventionEntre'
                                                                            }
                                                                            onChange={(e) => {
                                                                                this.props.put({
                                                                                    params: {
                                                                                        key: e.target.name,
                                                                                        value: e.target.value,
                                                                                        title:
                                                                                            this.props.trad['_numberAction'],
                                                                                    },
                                                                                });
                                                                                this.setState({
                                                                                    numberActionConventionEntre:
                                                                                    e.target.value,
                                                                                });
                                                                            }}
                                                                            value={
                                                                                this.state.numberActionConventionEntre
                                                                            }
                                                                            defaultValue={
                                                                                this.props.formData[
                                                                                this.props.name +
                                                                                '_numberActionConventionEntre'
                                                                                    ]
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </>
                                                        )}

                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <RadioInput
                                                                    title={this.genLang('_conventionVote')}
                                                                    lang={this.state.lang}
                                                                    key={this.props.name + '_conventionVote'}
                                                                    name={this.props.name + '_conventionVote'}
                                                                    default={'no'}
                                                                    options={[
                                                                        {
                                                                            value: 'yes',
                                                                            label: 'Oui',
                                                                        },
                                                                        {
                                                                            value: 'no',
                                                                            label: 'Non',
                                                                        },
                                                                    ]}
                                                                    formData={this.props.formData}
                                                                    put={this.props.put}
                                                                    callback={(e) => {
                                                                        this.setState({ conventionVote: e.value });
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        {this.props.formData[
                                                        this.props.name + '_conventionVote'
                                                            ] == 'yes' && (
                                                            <>
                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <Label>{this.genLang('_detail')}</Label>
                                                                        <Input
                                                                            type="textarea"
                                                                            onChange={(e) => {
                                                                                this.props.put({
                                                                                    params: {
                                                                                        key: e.target.name,
                                                                                        value: e.target.value,
                                                                                        title: this.props.trad['_detail'],
                                                                                    },
                                                                                });
                                                                            }}
                                                                            name={this.props.name + '_infosVote'}
                                                                            rows={4}
                                                                            value={
                                                                                this.props.formData[
                                                                                this.props.name + '_infosVote'
                                                                                    ]
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <Label>
                                                                            <h5>{this.genLang('_numberAction')}</h5>
                                                                        </Label>
                                                                        <Input
                                                                            type="number"
                                                                            name={
                                                                                this.props.name +
                                                                                '_numberActionConventionVote'
                                                                            }
                                                                            onChange={(e) => {
                                                                                this.props.put({
                                                                                    params: {
                                                                                        key: e.target.name,
                                                                                        value: e.target.value,
                                                                                        title:
                                                                                            this.props.trad['_numberAction'],
                                                                                    },
                                                                                });
                                                                                this.setState({
                                                                                    numberActionConventionVote:
                                                                                    e.target.value,
                                                                                });
                                                                            }}
                                                                            value={
                                                                                this.state.numberActionConventionVote
                                                                            }
                                                                            defaultValue={
                                                                                this.props.formData[
                                                                                this.props.name +
                                                                                '_numberActionConventionVote'
                                                                                    ]
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </>
                                                        )}

                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label>
                                                                    <h5>
                                                                        {this.genLang('_justeValeurMarchandeTotale')}
                                                                    </h5>
                                                                </Label>
                                                                <Input
                                                                    type="number"
                                                                    name={
                                                                        this.props.name +
                                                                        '_justeValeurMarchandeTotale'
                                                                    }
                                                                    onChange={(e) => {
                                                                        this.props.put({
                                                                            params: {
                                                                                key: e.target.name,
                                                                                value: e.target.value,
                                                                                title:
                                                                                    this.props.trad[
                                                                                        '_justeValeurMarchandeTotale'
                                                                                        ],
                                                                            },
                                                                        });
                                                                        this.setState({
                                                                            justeValeurMarchandeTotale: e.target.value,
                                                                        });
                                                                    }}
                                                                    value={this.state.justeValeurMarchandeTotale}
                                                                    defaultValue={
                                                                        this.props.formData[
                                                                        this.props.name +
                                                                        '_justeValeurMarchandeTotale'
                                                                            ]
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label>
                                                                    <h5>{this.genLang('_nombreActionsTotales')}</h5>
                                                                </Label>
                                                                <Input
                                                                    type="number"
                                                                    name={this.props.name + '_numberActionTotales'}
                                                                    onChange={(e) => {
                                                                        this.props.put({
                                                                            params: {
                                                                                key: e.target.name,
                                                                                value: e.target.value,
                                                                                title:
                                                                                    this.props.trad[
                                                                                        '_nombreActionsTotales'
                                                                                        ],
                                                                            },
                                                                        });
                                                                        this.setState({
                                                                            numberActionTotales: e.target.value,
                                                                        });
                                                                    }}
                                                                    value={this.state.numberActionTotales}
                                                                    defaultValue={
                                                                        this.props.formData[
                                                                        this.props.name + '_numberActionTotales'
                                                                            ]
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <RadioInput
                                                                    title={this.genLang('_controlefait')}
                                                                    lang={this.state.lang}
                                                                    key={this.props.name + '_controlefait'}
                                                                    name={this.props.name + '_controlefait'}
                                                                    default={'no'}
                                                                    options={[
                                                                        {
                                                                            value: 'yes',
                                                                            label: 'Oui',
                                                                        },
                                                                        {
                                                                            value: 'no',
                                                                            label: 'Non',
                                                                        },
                                                                    ]}
                                                                    value={
                                                                        this.props.formData[
                                                                        this.props.name + '_controlefait'
                                                                            ]
                                                                    }
                                                                    formData={this.props.formData}
                                                                    put={this.props.put}
                                                                    callback={(e) => {
                                                                        this.setState({ controlefait: e.value });
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        {this.props.formData[
                                                        this.props.name + '_controlefait'
                                                            ] == 'yes' && (
                                                            <>
                                                                <Col md={12}>
                                                                    <User
                                                                        put={this.props.put}
                                                                        lang={this.state.lang}
                                                                        trad={this.props.trad}
                                                                        title={{
                                                                            en: 'Test',
                                                                            fr: 'Test'
                                                                        }}
                                                                        formData={{ ...this.props.formData }}
                                                                        // remove={this.removeHeirForm}
                                                                        remove={this.props.remove}
                                                                        name={this.props.name + '_userControleFait'}
                                                                        ticket={this.props.ticket}
                                                                        parentingTimeGroup={
                                                                            this.props.parentingTimeGroup || false
                                                                        }
                                                                        toggleLoading={this.props.toggleLoading}
                                                                        pushWaitingList={this.props.pushWaitingList}
                                                                        pullWaitingList={this.props.pullWaitingList}
                                                                        questionned={this.props.misEnCause || []}
                                                                        target={this.props.target}
                                                                        noRelation={true}
                                                                        {...this.props}
                                                                    ></User>
                                                                </Col>
                                                            </>
                                                        )}

                                                        <Actionnaires2
                                                            onChange={this.props.onChange}
                                                            onSelectChange={this.props.onSelectChange}
                                                            removeWill={this.props.removeWill}
                                                            removeActionnaires={this.props.removeActionnaires}
                                                            display={this.props.display}
                                                            defaultDisplay={this.props.defaultDisplay}
                                                            moreThan25={this.props.moreThan25}
                                                            public={this.props.public}
                                                            ticket={this.props.ticket}
                                                            target={this.props.target}
                                                            lang={this.state.lang || 'fr'}
                                                            version={this.state.version}
                                                            key={this.props.name + '_actionnaire'}
                                                            name={this.props.name + '_actionnaire'}
                                                            put={this.props.put}
                                                            remove={this.props.remove}
                                                            trad={this.props.trad}
                                                            // onChange={this.onChange}
                                                            formData={this.props.formData || []}
                                                            fromChild={true}
                                                            // defaultDisplay={this.state.defaultDisplay}
                                                            // display={this.state[this.props.name+'_display']}
                                                            pushWaitingList={this.props.pushWaitingList}
                                                            pullWaitingList={this.props.pullWaitingList}
                                                            removeFileFromGCloud={
                                                                this.props.removeFileFromGCloud
                                                            }
                                                            toggleLoading={this.props.toggleLoading}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                </Row>
                            )}
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}
