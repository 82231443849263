import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export class TableIcon extends Component {

    render() {
        return (
            <div
                style={{ cursor: 'pointer' }}
                onClick={
                    (e) => {
                        if (this.props.callback) {
                            this.props.callback();
                        } else {
                            console.error('callback is missing in TableIcon');
                        }
                    }
                }>
                <FontAwesomeIcon
                    icon={this.props.icon || faEdit}
                    color={this.props.color || 'blue'}

                />
            </div>
        );
    }
}
