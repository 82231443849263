import React, { type Ref } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import {
    NeoFormComponent,
    type NeoFormComponentProps
} from '@/components/neoform/NeoFormComponent';
import { useNeoForm } from '@/composables/neoform';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { useUserStore } from '@/store/user';
import { useFieldArray } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Row } from '@/components/ui/row';
import { Cross1Icon } from '@radix-ui/react-icons';
import { getFieldError, useValidation } from '@/composables/validation';
import { v4 as uuid } from 'uuid';
import { clone } from '@/composables/utils';
import { cn } from '@/lib/utils';
import _ from 'lodash';

interface Options {
    noCard?: boolean;
    noPreface?: boolean;
    noClose?: boolean;
    noAdd?: boolean;
    isDynamic?: boolean;
    fixedNumEntries?: number;
    titleType?: NeoFormTitleType;
    renderAddButton?: React.FC<{ ref: Ref<any>; onClick: () => void }>;
    newValue?: () => any;
}

interface ListProps {
    min?: number;
    max?: number;
    itemTitle?: string | TranslationObject;
    default?: any[];
}

export function List<Props extends object>(
    component: React.FC<Props>,
    {
        noCard,
        noPreface,
        noClose,
        noAdd,
        isDynamic,
        fixedNumEntries,
        titleType,
        newValue,
        renderAddButton
    }: Options = {},
    componentProps?: Partial<Props>
) {
    if (fixedNumEntries != null) {
        fixedNumEntries = _.clamp(fixedNumEntries, 0, 30);
    }
    const ListComponent = (props: NeoFormComponentProps & ListProps & Props) => {
        const lang = useUserStore(state => state.lang);
        const { ct } = useTranslation();
        const { minArrayLength, maxArrayLength } = useValidation();
        const {
            hookForm,
            form,
            id,
            getChildFieldName,
            hasFieldPermission,
            hasChildFieldPermission
        } = useNeoForm();
        const {
            fields,
            append,
            replace,
            remove
        } = useFieldArray({
            name: id,
            rules: {
                validate: {
                    ...(typeof props.min === 'number' && {
                        min: minArrayLength(props.min)
                    }),
                    ...(typeof props.max === 'number' && {
                        max: maxArrayLength(props.max)
                    })
                }
            }
        });
        const hasStrictPermission = hasFieldPermission(true);
        const deletedFields = fields.map((_, i) => getChildFieldName(i, 'deleted'));
        const list: Array<boolean | undefined> = hookForm.watch(deletedFields);
        const error = getFieldError(`${id}.root`, hookForm.formState);
        const array = hookForm.getValues(id);
        const isFixed = fixedNumEntries != null;
        const defaultValue: any[] | undefined = props.default ?? (
            isFixed
                ? [...Array(fixedNumEntries)].map(() => ({}))
                : undefined
        );
        if (!array && defaultValue) {
            const value = clone(defaultValue);
            if (Array.isArray(value) && typeof value[0] === 'object') {
                value.forEach((obj) => {
                    obj.id = uuid();
                });
            }
            replace(value);
        }

        const filteredFields = fields
            .map((field, index) => [field, index] as const)
            .filter(([_, index]) => !list?.[index]);
        const isBelowMax = typeof props.max !== 'number' || filteredFields.length < props.max;
        if (isFixed && filteredFields.length !== fixedNumEntries) {
            if (filteredFields.length < Number(fixedNumEntries)) {
                replace(
                    filteredFields
                        .map(([_, idx]) => array[idx])
                        .concat([...Array(Number(fixedNumEntries) - filteredFields.length)])
                );
            } else {
                replace(
                    filteredFields
                        .map(([_, idx]) => array[idx])
                        .slice(0, fixedNumEntries)
                );
            }
        }

        function handleAdd() {
            const value = newValue?.() ?? {};
            if (typeof value === 'object') {
                value.id = uuid();
            }
            append(value);
        }

        function handleRemove(index: number) {
            const value = hookForm.getValues(getChildFieldName(index));
            if (typeof value === 'object') {
                hookForm.setValue(
                    getChildFieldName(index, 'deleted'),
                    true
                );
            } else {
                remove(index);
            }
        }

        const renderListItem = (id: string, index: number, children: React.ReactNode) => {
            if (!noCard) {
                return (
                    <Card key={id} className={cn(
                        'tw-mb-4 tw-relative tw-shadow-none',
                        error && 'tw-border-destructive'
                    )}>
                        {!noClose && !isFixed && hasStrictPermission &&
                            <Button
                                type="button" variant="ghost" size="icon"
                                className={cn(
                                    '!tw-absolute tw-top-0 tw-right-0 !tw-m-1 !tw-size-7',
                                    'tw-text-destructive'
                                )}
                                onClick={() => handleRemove(index)}
                            >
                                <Cross1Icon />
                            </Button>
                        }
                        <CardContent className="!tw-p-2 !tw-pb-4">
                            {children}
                        </CardContent>
                    </Card>
                );
            } else {
                return (
                    <div key={id} className="tw-flex tw-mb-2 tw-relative">
                        <Row className="tw-flex-1">
                            {children}
                        </Row>
                        {!noClose && !isFixed && hasStrictPermission &&
                            <Button
                                type="button" variant="ghost" size="icon"
                                className={cn(
                                    '!tw-m-1 !tw-flex-[0] tw-self-start tw0 tw-text-destructive'
                                )}
                                color="error" onClick={() => handleRemove(index)}
                            >
                                <Cross1Icon />
                            </Button>
                        }
                    </div>
                );
            }
        };

        return (
            <>
                {filteredFields
                    .map(([field, index], effectiveIndex) => {
                        const name = String(index);
                        const hasPermission = hasChildFieldPermission([name]);
                        const {
                            // @ts-expect-error S2339
                            conditionalProps: _conditional,
                            ...otherProps
                        } = props;
                        const finalProps = {
                            ...otherProps,
                            ...componentProps
                        } as any;
                        if (!hasPermission) return null;
                        const title = Object.entries(props.itemTitle ?? props.title ?? {})
                            .map(([k, v]) => [k, `${v} (${effectiveIndex + 1})`])
                            .reduce((obj, [k, v]) => ({
                                ...obj,
                                [k]: v
                            }), {});
                        return renderListItem(
                            field.id, index,
                            <NeoFormComponent
                                lang={lang}
                                visible={!noPreface}
                                titleType={titleType ?? NeoFormTitleType.DEFAULT}
                                {...finalProps}
                                name={name}
                                componentName={component.name as 'Missing'}
                                componentFunction={component}
                                title={title}
                                isDynamic={isDynamic}
                            />
                        );
                    })}
                {!noAdd && !isFixed && hasStrictPermission && isBelowMax &&
                    (renderAddButton
                        ? renderAddButton({
                            ref: (ref) => form?.ref(`${id}.root`, ref),
                            onClick: handleAdd
                        })
                        : <Button
                            ref={ref => form?.ref(`${id}.root`, ref)}
                            type="button" variant="outline"
                            className="tw-text-primary tw-w-full"
                            onClick={handleAdd}
                        >
                            {ct('add')}
                        </Button>
                    )
                }
                {error && <span className="tw-text-xs tw-text-danger">{error}</span>}
            </>
        );
    };
    ListComponent.displayName = 'ListComponent';
    return ListComponent;
}
