import React, { useEffect, useMemo, useState } from 'react';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { type Product, type UpdateProductDto } from '@/types/api/product';
import { CheckboxCell, DateCell, TranslateCell } from '@/components/ui/cells';
import {
    deleteProduct,
    getCalendarConfig, getMacrosList,
    getOrgGroups, getOrgUsers,
    getProductList,
    postCreateProduct,
    putUpdateProduct
} from '@/composables/api';
import { BindProps } from '@/components/utils/BindProps';
import type { User, UserGroup } from '@/types/api/user';
import type { CalendarConfig } from '@/types/api/calendar';
import { UpdateProductDialog } from '@/components/psj/product/UpdateProductDialog';
import { type WithOptional } from '@/types/utils';
import { toast } from 'react-toastify';
import { useError } from '@/composables/error';
import { type Macro } from '@/types/api/macro';
import { transformConfig } from '@/composables/calendar';

export function Products() {
    const { t, to, ct } = useTranslation('settings.products');
    const { handleNetworkError } = useError();

    const schema = useMemo<CrudSchema<Product>>(() => [
        {
            id: '_id',
            type: CrudInputType.TEXT,
            name: 'ID',
            columnDef: {
                id: '_id',
                header: 'ID',
                accessorKey: '_id'
            }
        },
        {
            id: 'active',
            type: CrudInputType.CHECKBOX,
            name: 'Actif',
            columnDef: {
                id: 'active',
                header: 'Actif',
                accessorKey: 'active',
                cell: CheckboxCell
            }
        },
        {
            id: 'title',
            type: CrudInputType.TEXT,
            translate: true,
            required: true,
            columnDef: {
                id: 'title',
                header: 'Titre',
                accessorKey: 'title',
                cell: TranslateCell({ to })
            }
        },
        {
            id: 'updated_at',
            type: CrudInputType.TEXT,
            name: 'Date de modification',
            columnDef: {
                id: 'updated_at',
                header: 'Date de modification',
                accessorKey: 'updated_at',
                cell: DateCell
            }
        },
        {
            id: 'created_at',
            type: CrudInputType.TEXT,
            name: 'Date de création',
            columnDef: {
                id: 'created_at',
                header: 'Date de création',
                accessorKey: 'created_at',
                cell: DateCell
            }
        }
    ], []);
    const [users, setUsers] = useState<User[]>([]);
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const [macros, setMacros] = useState<Macro[]>([]);
    const [config, setConfig] = useState<CalendarConfig>();
    const DialogComponent = useMemo(
        () => BindProps(UpdateProductDialog, {
            users,
            groups,
            macros,
            calendarConfig: config
        }),
        [users, groups, macros, config]
    );

    useEffect(() => {
        getOrgUsers().then((res) => setUsers(res.data));
        getOrgGroups().then((res) => setGroups(res.data));
        getMacrosList({ limit: 10000 }).then((res) => setMacros(res.data));
        getCalendarConfig().then((res) => setConfig(transformConfig(res.data)));
    }, []);

    function onSubmit(product: WithOptional<Product, '_id'>) {
        const isUpdateDialog = !!product._id;
        const data = {
            ...product,
            process: {
                _id: product.process._id,
                ns_list: product.process.ns_list
            }
        };
        const promise = isUpdateDialog
            ? putUpdateProduct(data as UpdateProductDto)
            : postCreateProduct(data);
        return promise
            .then(() => {
                toast(ct('messages.success'), { type: 'success' });
            })
            .catch(handleNetworkError);
    }

    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CrudTable<Product, '_id'>
                    singleItemDeletion
                    idKey="_id"
                    schema={schema}
                    initialState={{ columnVisibility: { _id: false } }}
                    onRead={() => getProductList({}).then((res) => res.data)}
                    onDelete={([value]) => deleteProduct(value._id).then()}
                    onUpdate={onSubmit}
                    onCreate={onSubmit}
                    dialogComponent={DialogComponent}
                />
            </CardContent>
        </>
    );
}
