import React, { useEffect, useMemo, useRef } from 'react';
import { IMask, useIMask } from 'react-imask';
import { DateTime } from 'luxon';
import { Input, type InputProps } from './input';
import { ClockIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { assignRef, combineDateTime } from '@/composables/utils';
import { useControllableState } from '@/composables/controllable';
import { cn } from '@/lib/utils';

type TimeFormat = '24h' | '12h';

interface Props extends Omit<InputProps, 'onChange' | 'value'> {
    value?: DateTime | null;
    onChange?: React.Dispatch<DateTime | null>;
    format?: TimeFormat;
    calendarProps?: any;
}

const TIME_REGEX = /^([0-9_]{1,2})?:([0-9_]{2})( (:?[Aa_]|[Pp_])[Mm_])?$/;

const TimePicker = React.forwardRef<HTMLInputElement, Props>(({ calendarProps, ...props }, inputRef) => {
    const input = useRef<HTMLInputElement>(null);
    const [value, setValue] = useControllableState(
        null, props.value,
        props.onChange as React.Dispatch<React.SetStateAction<DateTime | null>>
    );
    const format = props.format ?? '12h';
    const is24Hour = format === '24h';
    const formatString = is24Hour ? 'HH:mm' : 'h:mm a';
    const hoursRef = useRef<HTMLDivElement | null>(null);
    const minutesRef = useRef<HTMLDivElement | null>(null);
    const meridiemRef = useRef<HTMLDivElement | null>(null);
    const mask = useIMask(
        {
            lazy: false,
            overwrite: true,
            autofix: false,
            mask: is24Hour ? 'HH{:}mm' : 'hh{:}mm A',
            placeholderChar: '_',
            blocks: {
                HH: {
                    mask: Number,
                    placeholderChar: '_',
                    min: 0,
                    max: 23,
                    maxLength: 2
                },
                hh: {
                    mask: Number,
                    placeholderChar: '_',
                    min: 0,
                    max: 12,
                    maxLength: 2
                },
                mm: {
                    mask: IMask.MaskedRange,
                    placeholderChar: '_',
                    from: 0,
                    to: 59,
                    maxLength: 2
                },
                A: {
                    mask: IMask.MaskedEnum,
                    enum: ['AM', 'am', 'PM', 'pm', 'aM', 'Am', 'pM', 'Pm']
                }
            }
        },
        {
            onAccept: (newValue) => {
                newValue = newValue
                    .toUpperCase();
                if (!is24Hour) {
                    newValue = newValue.replace(/__$/, 'AM')
                        .replace(/A_$/, 'AM')
                        .replace(/P_$/, 'PM')
                        .replace(/_M$/, 'AM')
                        .replace(/M_$/, 'AM');
                }
                const time = DateTime.fromFormat(newValue, formatString);
                let dateValue = time.isValid ? time : null;
                if (dateValue && value?.isValid === true) {
                    dateValue = combineDateTime(value, time);
                }
                setValue(dateValue);
                if (dateValue) {
                    mask.setValue(newValue);
                }
            }
        }
    );

    const match = useMemo(
        () => mask.value.match(TIME_REGEX),
        [mask.value]
    );
    let hour = match?.[1] ? Number(match[1]) : null;
    const minute = match?.[2] ? Number(match[2]) : null;
    const meridiem = match?.[3]?.trim()?.toLowerCase() ?? 'am';
    if (typeof hour === 'number' && !is24Hour && meridiem === 'pm') {
        hour += 12;
    }
    if (typeof hour === 'number' && !is24Hour) {
        hour %= 12;
    }
    const numHours = is24Hour ? 24 : 12;

    function scrollValue(
        ref: React.MutableRefObject<HTMLDivElement | null>,
        value: number,
        behavior?: ScrollBehavior
    ) {
        ref.current?.scrollTo({
            top: value * 40,
            behavior
        });
    }

    function handleOpenPopover() {
        input.current?.select();
        setTimeout(() => {
            if (typeof hour === 'number') {
                scrollValue(hoursRef, hour);
            }
            if (typeof minute === 'number') {
                scrollValue(minutesRef, minute);
            }
            let index;
            if ((index = ['am', 'pm'].indexOf(meridiem)) >= 0) {
                scrollValue(meridiemRef, index);
            }
        }, 0);
    }

    function handleSelectHour(i: number) {
        const value = mask.value.replace(
            TIME_REGEX,
            `${String(!is24Hour && i === 0 ? 12 : i).padStart(2, '0')}:$2$3`
        );
        mask.setValue(value);
        scrollValue(hoursRef, i, 'smooth');
    }

    function handleSelectMinute(i: number) {
        const value = mask.value.replace(
            TIME_REGEX,
            `$1:${String(i).padStart(2, '0')}$3`
        );
        mask.setValue(value);
        scrollValue(minutesRef, i, 'smooth');
    }

    function handleSelectMeridiem(meridiem: string) {
        const value = mask.value.replace(TIME_REGEX, `$1:$2 ${meridiem.toUpperCase()}`);
        mask.setValue(value);
        scrollValue(meridiemRef, ['am', 'pm'].indexOf(meridiem), 'smooth');
    }

    useEffect(() => {
        if (value) {
            mask.setValue(value.toFormat(formatString));
        } else {
            mask.setUnmaskedValue('');
        }
    }, [value]);
    return (
        <Popover modal>
            <PopoverTrigger className="tw-w-full">
                <Input
                    {...props}
                    ref={(ref) => {
                        mask.ref.current = ref;
                        assignRef(inputRef, ref);
                        assignRef(input, ref);
                    }}
                    value={mask.value}
                    onChange={(e) => mask.setUnmaskedValue(e.target.value)}
                    prependIcon={<ClockIcon />}
                />
            </PopoverTrigger>
            <PopoverContent
                className="!tw-p-0 tw-flex tw-h-[206px] tw-w-auto"
                align="start" side="bottom"
                onOpenAutoFocus={(e) => {
                    handleOpenPopover();
                    e.preventDefault();
                }}
            >
                <ScrollArea ref={hoursRef}>
                    <div className="tw-w-[60px] tw-p-1 !tw-inline-flex tw-flex-col tw-gap-1 tw-border-r">
                        {[...Array(numHours)].map((_, i) =>
                            <Button
                                key={i} type="button"
                                variant={i === hour ? 'default' : 'ghost'}
                                className={cn('tw-flex-1', i === numHours - 1 && 'tw-mb-[160px]')}
                                onClick={() => handleSelectHour(i)}
                            >
                                {!is24Hour && i === 0 ? 12 : i}
                            </Button>
                        )}
                    </div>
                </ScrollArea>
                <ScrollArea ref={minutesRef}>
                    <div className="tw-w-[60px] tw-p-1 tw-inline-flex tw-flex-col tw-gap-1 tw-border-r">
                        {[...Array(60)].map((v, i) =>
                            <Button
                                key={i} type="button"
                                variant={i === minute ? 'default' : 'ghost'}
                                className={cn('tw-flex-1', i === 59 && 'tw-mb-[160px]')}
                                onClick={() => handleSelectMinute(i)}
                            >
                                {String(i).padStart(2, '0')}
                            </Button>
                        )}
                    </div>
                </ScrollArea>
                {!is24Hour && <ScrollArea ref={meridiemRef}>
                    <div className="tw-w-[60px] tw-p-1 tw-inline-flex tw-flex-col tw-gap-1">
                        <Button
                            type="button" className="tw-flex-1"
                            variant={meridiem === 'am' ? 'default' : 'ghost'}
                            onClick={() => handleSelectMeridiem('am')}
                        >
                            AM
                        </Button>
                        <Button
                            type="button" className="tw-flex-1 tw-mb-[160px]"
                            variant={meridiem === 'pm' ? 'default' : 'ghost'}
                            onClick={() => handleSelectMeridiem('pm')}
                        >
                            PM
                        </Button>
                    </div>
                </ScrollArea>}
            </PopoverContent>
        </Popover>
    );
});
TimePicker.displayName = 'TimePicker';

export { TimePicker };
