import React, { Component } from 'react';

export class WizardBeltElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.active
        };
    }

    render() {
        let style = {
            marginBottom: '15px',
            cursor: 'pointer',
        };

        if (this.props.style) {
            style = {
                style,
                ...this.props.style
            };
        }

        if (this.props.color) {
            style['color'] = this.props.color;
        }

        if (this.props.size) {
            style['fontSize'] = this.props.size;
        }
        let { active } = this.state;
        return (
            <button
                type="button"
                onClick={
                    () => {
                        this.setState({ 'active': true }, () => {
                                debugger;
                                if (this.props.callback) {
                                    this.props.callback(this.props.target);
                                } else {
                                    console.log('pas de callback dans legend :(');
                                }
                            }
                        );
                    }
                }
                className={(active) ? 'multisteps-form__progress-btn js-active' : 'multisteps-form__progress-btn'}>{this.props.children || ''}
            </button>
        );
    }
}
