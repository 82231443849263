import { type DateTime } from 'luxon';
import React, { type MutableRefObject } from 'react';
import { NUM_WEEKDAYS } from '@/components/WeekSelection/utils';
import { Cell } from '@/components/WeekSelection/Cell';
import { cn } from '@/lib/utils';

interface Props {
    hour: DateTime;
    index: number;
    numWeekdays?: number;
    debugDayStartUtc?: boolean;
    dashed?: boolean;
    cellRef?: MutableRefObject<HTMLDivElement | null>;
}

export function Row({ index, hour, numWeekdays, debugDayStartUtc, dashed, cellRef }: Props) {
    return (
        <>
            <div
                className={cn(
                    'tw-text-center tw-border-0 tw-border-x tw-border-b tw-border-black/30',
                    'tw-select-none tw-px-2 tw-flex tw-justify-center tw-items-center'
                )}
                style={{
                    gridRow: index,
                    gridColumn: 1,
                    borderStyle: `none solid ${dashed ? 'dashed' : 'solid'} solid`,
                    borderTop: debugDayStartUtc ? '2px solid rgba(0, 0, 0, 1)' : undefined
                }}
            >
                {hour.toFormat('H:mm')}
            </div>
            {[...Array(numWeekdays ?? NUM_WEEKDAYS)].map((_, i) =>
                <Cell
                    key={i}
                    cellRef={i === 0 ? cellRef : undefined}
                    index={i + 2}
                    rowIndex={index}
                    debugDayStartUtc={debugDayStartUtc}
                    dashed={dashed}
                />
            )}
        </>
    );
}
