import React from 'react';
import { type WithOptional } from '@/types/utils';

export function BindProps<T extends object, K extends keyof T>(
    component: React.FC<T>,
    boundProps: Record<K, T[K]>
): React.FC<WithOptional<T, K>> {
    const BoundComponent = (props: WithOptional<T, K>) => {
        const finalProps = { ...props, ...boundProps } as T;
        return React.createElement<T>(component, finalProps);
    };
    BoundComponent.displayName = component.displayName;
    return BoundComponent;
}
