import { type User, type UserGroup } from '@/types/api/user';
import { useMemo } from 'react';
import { useTranslation } from '@/composables/translation';

interface Options {
    users: User[];
    groups: UserGroup[];
}

const ASSIGN_LABELS = {
    user: {
        fr: 'Utilisateurs',
        en: 'Users'
    },
    group: {
        fr: 'Groupes',
        en: 'Groups'
    }
} as const;

export function useAssignment(opts: Options) {
    const { to } = useTranslation();
    const options = useMemo(
        () => [
            ...opts.groups
                .filter(g => g.category !== 'speciality')
                .map((g) => ({
                    type: 'group' as const,
                    value: g._id.$oid,
                    label: to(g.name)
                })),
            ...opts.users
                .map((u) => ({
                    type: 'user' as const,
                    value: u.id.$oid,
                    label: `${u.firstname} ${u.lastname}`
                }))
        ],
        [opts.users, opts.groups]
    );
    type OptionType = typeof options[number];
    return {
        options,
        getOptionLabel: (opt: OptionType) => opt.label,
        getOptionValue: (opt: OptionType) => opt.value,
        groupBy: (opt: OptionType) => to(ASSIGN_LABELS[opt.type])
    };
}
