import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from '@/composables/translation';
import { instance } from '@/composables/api';
import { useOrg } from '@/composables/org';
import { useValidation } from '@/composables/validation';
import { useError } from '@/composables/error';
import {
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle
} from '@/components/ui/card';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { EnvelopeClosedIcon, HomeIcon } from '@radix-ui/react-icons';
import { useOverrideConfig } from '@/composables/override';
import type { PostForgotPasswordResponse } from '@/types/api/user';

export function ForgotPassword() {
    const { ct, t } = useTranslation('forgot-password');
    const { org, showOrg } = useOrg();
    const form = useForm({
        defaultValues: {
            org,
            email: ''
        }
    });
    const { required, email } = useValidation();
    const { handleNetworkError } = useError();
    const override = useOverrideConfig();

    const handleSubmit = ({ org, email }: { org: string; email: string }) => {
        return instance.post<PostForgotPasswordResponse>(
            override?.urls?.post_forgot_password ?? '/v1/forgot-password',
            {
                email,
                org
            }
        )
            .then(() => {
                toast(ct('messages.success'), { type: 'success' });
            })
            .catch(handleNetworkError);
    };

    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
                <CardDescription>{t('description')}</CardDescription>
            </CardHeader>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <CardContent className="tw-flex tw-flex-col tw-gap-3">
                        {showOrg && <FormField
                            name="org"
                            rules={{ validate: { required } }}
                            render={({ field }) =>
                                <FormItem>
                                    <FormLabel>{ct('org')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="org"
                                            prependIcon={<HomeIcon/>}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />}
                        <FormField
                            name="email"
                            rules={{ validate: { required, email } }}
                            render={({ field }) =>
                                <FormItem>
                                    <FormLabel>{ct('email')}</FormLabel>
                                    <FormControl>
                                        <Input
                                            id="email"
                                            type="email"
                                            prependIcon={<EnvelopeClosedIcon/>}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />
                    </CardContent>
                    <CardFooter className="tw-flex-col tw-gap-2">
                        <Button variant="link" className="!tw-px-0 tw-self-start" asChild>
                            <Link to={`${override?.is_customer_portal ? '/portal' : ''}/login`}>
                                {ct('return-to-login')}
                            </Link>
                        </Button>
                        <ButtonSubmit className="tw-self-stretch">
                            {t('submit')}
                        </ButtonSubmit>
                    </CardFooter>
                </form>
            </Form>
        </>
    );
}
