import _ from 'lodash';
import en from '@/assets/dictionaries/en.json';
import fr from '@/assets/dictionaries/fr.json';
import { useUserStore } from '@/store/user';
import type { DeepIndex, Leaves, Paths } from '@/types/utils';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

export const LANGUAGES = ['en', 'fr'] as const;
export type Language = typeof LANGUAGES[number];

type Translation = typeof en;

export type TranslationObject = Partial<Record<Language, string>>;

// *NOTE: This will fail if all dictionaries do not have the same structure
const dictionaries: Record<Language, Translation> = {
    en,
    fr
} as const;

export type TranslateObjectFn =
    (value?: Partial<TranslationObject> | string, fallback?: string, language?: Language) => string;

export function useTranslation<K extends Paths<Translation>>(scope: K = '' as K, lang?: Language) {
    if (!lang) {
        lang = useUserStore(state => state.lang);
    }
    const dict = _.get(dictionaries[lang], scope, dictionaries[lang]);
    return {
        ct: (key: Leaves<DeepIndex<Translation, 'common'>>) => {
            return _.get(dictionaries[lang as Language].common, key, '');
        },
        t: (key: Leaves<DeepIndex<Translation, K>>, replacements?: Record<string, string | number>) => {
            let result = _.get(dict, key, '') as string;
            if (replacements) {
                Object.entries(replacements)
                    .forEach(([key, value]) => {
                        result = result.replaceAll(`{${key}}`, String(value));
                    });
            }
            return result;
        },
        to: (value?: Partial<TranslationObject> | string, fallback = '', language?: Language) => {
            if (!language) {
                language = lang;
            }
            if (typeof value === 'string') { return value; }
            if (!value) { return fallback; }
            return value[language as Language] ?? value[Object.keys(value)[0] as Language] ?? fallback;
        },
        muiTableLocalization: lang === 'fr' ? MRT_Localization_FR : MRT_Localization_EN
    };
}

export function combine(key: Leaves<Translation>): TranslationObject {
    const result: any = {};
    LANGUAGES.forEach(lang => {
        result[lang] = _.get(dictionaries[lang], key, '');
    });
    return result;
}
