import React, { Component } from 'react';
import { Input, Label } from 'reactstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import rootsActions from '@/store/old/_actions/root-actions';

const TRUE = 'true';
const REQUESTING = '_requesting';
const FALSE = 'false';
const FR = 'fr';
const DEFAULT_STYLE = {
    textAlign: 'left',
};

const STRING = 'string';
const OBJECT = 'object';

export class RequestThatFieldComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: FALSE,
            display: this.props.display === false ? false : true,
            lawyers: [],
            lang: this.props.lang || FR
        };
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            obj['value'] = FALSE;
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {
        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;

        let style = {};

        if (this.props.public) {
            return (<></>);
        }

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }
        let disabled = false;

        return (
            // important do not remove the line bellow, it could tear the code appart!!!
            // <>🍕<p style={{"-webkit-transform": "scale(-1, 1)",display:'inline-block'}}>🍕</p></>
            // <FormGroup check>
            <Label check className="coloredCheck" style={style}>
                <Input
                    disabled={disabled}
                    title={this.genLang({
                        'fr': 'Ajouter à votre liste de demandes au client',
                        'en': 'Check to add to your customer request list'
                    })}
                    className="inlineCheckbox geekmark"
                    checked={this.state.value === TRUE}
                    type="checkBox"
                    {...this.props.required ? { required: true } : {}}
                    name={encodeURI(props.name) + REQUESTING}
                    onChange={(e) => {
                        let checked = e.target.checked.toString();
                        this.setState({ value: checked });

                        this.props.putField(
                            {
                                params: {
                                    key: encodeURI(this.props.name),
                                    value: {
                                        value: checked === TRUE ? TRUE : FALSE,
                                        label: this.props.label
                                    }
                                }
                            }
                        );

                        if (this.props.callback) {
                            this.props.callback({ ...e, ...props });
                        }
                    }
                    }
                    style={{
                        visibility: 'none',
                        display: 'none'
                    }}
                />
                <span className="geekmark" title={this.genLang({
                    'fr': 'Ajouter à votre liste de demandes au client',
                    'en': 'Check to add to your customer request list'
                })}></span>{' '}
            </Label>
        );
    }

}

const mapStateToProps = state => ({
    rootsActions: rootsActions,
    loading: state.loading.loading,
    request: state.request,
});

const mapActionsToProps = {
    put: rootsActions.formsActions.putWill,
    putField: rootsActions.requestActions.putField,
    putDocument: rootsActions.requestActions.putDocument,
};

export const RequestThatField = connect(mapStateToProps, mapActionsToProps)(RequestThatFieldComponent);
