import React, { Component } from 'react';

import Select from 'react-select';

import _ from 'lodash';
import { NotesInput } from './NotesInput';
import { Col, FormGroup, Label, } from 'reactstrap';

import { RequestThatField } from './RequestThatField';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const DEFAULT_STYLE = {
    textAlign: 'left',
    zIndex: 999999
};

export class Select2 extends Component {

    constructor(props) {
        super(props);

        let fd = this.props.formData;

        let value = fd && fd[this.props.name] ? fd[this.props.name] : [];

        value = _.compact(value);
        for (let v in value) {
            value[v] = _.find(this.props.options, { value: this.genLang(value[v], this.props.lang) });
            if (value[v] && value[v].label) {
                value[v].label = this.genLang(value[v].label);
            }
        }

        this.state = {
            value: value,
            display: this.props.defaultDisplay === false ? false : true
        };

        if (!props.name) {
            console.error('!!! name is missing !!!');
        }

        if (!props.title) {
            console.error('!!! title is missing !!!');
        }

        if (!props.title) {
            console.error('!!! no options for a select O_o?? !!!');
        }

        this.onChange = this.onChange.bind(this);
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    componentDidUpdate(prevprops) {

        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            let form = _.filter(props.options, (e) => {

                for (let f in props.formData[props.name]) {
                    if (e.value === props.formData[props.name][f]) {
                        return e;
                    }
                }

                return false;

            });

            obj['value'] = form;
            for (let v in obj.value) {
                obj.value[v].label = this.genLang(obj.value[v].label, this.props.lang);
            }

        }

        if (JSON.stringify(props.formData[props.name]) !== JSON.stringify(prevprops.formData[props.name])) {
            let form = _.filter(props.options, (e) => {

                for (let f in props.formData[props.name]) {
                    if (e.value === props.formData[props.name][f]) {
                        return e;
                    }
                }

                return false;

            });

            obj['value'] = form;
            // console.log('----====>',form);
            for (let v in obj.value) {
                obj.value[v].label = this.genLang(obj.value[v].label, this.props.lang);
            }

            props.put({
                params: {
                    key: props.name,
                    value: _.map(form, (f) => f.value)
                }
            });

        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    onChange(e) {
        this.setState({
            value: e
        });
        this.props.put({
            params: {
                key: this.props.name,
                value: _.map(e, (f) => f.value)
            }
        });
    }

    render() {
        let props = this.props;
        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        let options = [...props.options];

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }
        options = _.map(options, (e) => {

            return {
                ...e,
                key: this.props.name + '_option' + e.value,
                label: this.genLang(e.label, this.state.lang),
                value: this.genLang(e.value, this.state.lang)
            };
        });

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        return (
            <>
                <FormGroup>
                    <Select
                        id={this.props.name}
                        tabIndex={props.tabindex}
                        onChange={(e) => this.onChange(e)}
                        options={options}
                        noOptionsMessage={this.genLang(this.props.noOptionsMessage || 'no data')}
                        value={this.state.value || []}
                        name={this.props.name}
                        key={this.props.name}
                        isClearable
                        isSearchable
                        isMulti={!!this.props.isMulti}
                    />
                </FormGroup>
            </>
        );
    }

}
