import { useTranslation } from '@/composables/translation';
import { toast } from 'react-toastify';
import axios from 'axios';

export function useError() {
    const { t } = useTranslation('error');

    const getResponseError = (response?: any) => {
        let text = '';
        if (typeof response?.data?.detail === 'string') {
            text = response.data.detail;
        } else if (Array.isArray(response?.data?.detail)) {
            text = response.data.detail[0]?.msg;
        } else if (typeof response?.data?.detail === 'object') {
            text = response.data.detail.messages?.[0];
            text ??= response.data.detail.message;
        } else if (response?.data?.message) {
            text = response.data.message;
        } else if (response?.statusText) {
            text = response.statusText;
        } else if (response?.message) {
            text = response.message;
        }
        text ||= t('generic');
        return text;
    };

    return {
        getResponseError,
        handleNetworkError: (err?: any) => {
            if (axios.isCancel(err)) {
                return;
            }
            console.error(err);
            const message = getResponseError(err?.response);
            toast(message, { type: 'error', closeOnClick: false });
        }
    };
}
