import React, { Component } from 'react';

import { Button, Col, FormGroup, Label, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ParentalTime } from './ParentalTime';

const Question = <FontAwesomeIcon icon={faQuestionCircle} className="avensRed" />;

export class ParentalTimes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: this.props.counter || 0,
            lang: 'fr',
            parentalTime: []
        };
        this.addHeirForm = this.addHeirForm.bind(this);
        this.removeHeirForm = this.removeHeirForm.bind(this);
    }

    genLang() {
        // to Implement
    }

    componentDidUpdate(prevProps) {
        let props = this.props;
        if (props.formData && JSON.stringify(props.formData) !== JSON.stringify(prevProps.formData) || this.state.force) {

            let fd = this.props.formData;
            let obj = {};
            let reg = new RegExp('^' + this.props.name);

            for (let f in fd) {
                if (f.match(reg)) {
                    let split = f.split(reg);
                    obj[split[1].match(/\d+/)[0]] = true;
                }
            }

            let arr = [];
            for (let h in obj) {
                let key = this.props.name + '_' + h;

                arr.push();
                arr.push(
                    <div key={key}>
                        <Row>
                            <ParentalTime

                                version={this.props.version}
                                put={this.props.put}
                                lang={this.state.lang}
                                trad={this.props.trad}
                                formData={{ ...this.props.formData }}
                                remove={this.removeHeirForm}
                                name={key}
                                counter={h}
                                ticket={this.props.ticket}
                                toggleLoading={this.props.toggleLoading}
                                pushWaitingList={
                                    this.props.pushWaitingList
                                }
                                pullWaitingList={
                                    this.props.pullWaitingList
                                }
                                noCard={this.props.noCard}
                                target={this.props.target}
                            >
                            </ParentalTime>
                        </Row>
                    </div>
                );
            }
            this.setState({
                parentalTime: arr,
                counter: arr.length + 10,
                force: false
            });

        }
    }

    componentDidMount() {
        // set Default Data in here :)
        let fd = this.props.formData;
        let obj = {};
        let reg = new RegExp('^' + this.props.name);

        for (let f in fd) {
            if (f.match(reg)) {
                let split = f.split(reg);
                obj[split[1].match(/\d+/)[0]] = true;
            }
        }

        let arr = [];
        for (let h in obj) {
            let key = this.props.name + '_' + h;
            arr.push(
                <Row key={key}>
                    <ParentalTime
                        put={this.props.put}
                        lang={this.state.lang}
                        trad={this.props.trad}
                        formData={{ ...this.props.formData }}
                        remove={this.removeHeirForm}
                        name={key}
                        counter={h}
                        target={this.props.target}
                        noCard={this.props.noCard}
                    />
                </Row>
            );
        }

        this.setState({ parentalTime: arr });

    }

    addHeirForm() {
        this.setState(state => {

            let counter = this.state.counter;
            let key = this.props.name + '_' + counter++;

            state.counter = counter;
            state.parentalTime.push(
                <div key={key}>
                    <Row>
                        <ParentalTime
                            noCard={this.props.noCard}
                            put={this.props.put}
                            lang={this.state.lang}
                            trad={this.props.trad}
                            formData={{ ...this.props.formData }}
                            remove={this.removeHeirForm}
                            name={key}
                            counter={counter}
                            target={this.props.title}
                            toggleLoading={this.props.toggleLoading}
                            pushWaitingList={
                                this.props.pushWaitingList
                            }
                            pullWaitingList={
                                this.props.pullWaitingList
                            }
                        />
                    </Row>
                </div>
            );

            return state;
        });
    }

    removeHeirForm(id) {
        // this.props.formData['remove_'+id]=true;
        this.props.remove({ params: { key: id } });
        this.setState({
            parentalTime: _.reject(this.state.parentalTime, { key: id })
        });
    }

    renderInfo(info, id) {
        if (!info) {
            return '';
        }

        return (
            <>
                <b id={id}>{Question}</b>
                <UncontrolledPopover placement="top" target={id}>
                    <PopoverBody>{info}</PopoverBody>
                </UncontrolledPopover>
            </>
        );
    }

    render() {
        let parentalTime = this.state.parentalTime;
        return (
            <>
                <FormGroup>
                    {parentalTime.map(form => (
                        <div key={form.key}>{form}</div>
                    ))}
                </FormGroup>
                <FormGroup>
                    <Button
                        className="tw-mt-3"
                        block
                        outline
                        color="primary"
                        onClick={() => this.addHeirForm()}
                    >
                        {this.props.buttonText || 'Ajouter'}
                    </Button>
                </FormGroup>
            </>
        );
    }
}
