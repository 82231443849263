import { createContext, useContext } from 'react';

const URL_LIST = [
    'post_login',
    'put_reset_password',
    'post_forgot_password',
    'get_folder',
    'get_folder_list',
    'get_note_list'
] as const;

export interface OverrideConfiguration {
    is_customer_portal?: boolean;
    urls: Partial<Record<typeof URL_LIST[number], string>>;
}

export const OverrideConfigurationContext = createContext<OverrideConfiguration | undefined>(undefined);

export function useOverrideConfig() {
    return useContext(OverrideConfigurationContext);
}
