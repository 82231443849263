import React from 'react';

import { BeatLoader } from 'react-spinners';
import _ from 'lodash';
import { Button, Col, Input, Row } from 'reactstrap';
import { CheckInput } from './CheckInput';
import { TextInput } from './TextInput';
import { PreviewModal } from '../../neoform/PreviewModal';

import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAlt, faBan, faDownload, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

const eye = <FontAwesomeIcon color={'blue'} icon={faEye} />;
const trash = <FontAwesomeIcon color={'#bb4444'} icon={faTrash} />;
const download = <FontAwesomeIcon color={'blue'} icon={faDownload} />;
const mouve = <FontAwesomeIcon color={'light'} icon={faArrowsAlt} />;
const ban = <FontAwesomeIcon color={'#bb4444'} icon={faBan} style={{ verticalAlign: '-webkit-baseline-middle' }} />;
const NGROK = import.meta.env.VITE_API_URL;
const NONE = 'none', BLOCK = 'block';

export class SortableElement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || [],
            xyz: 'sortelement',
            loading: true,
            ticket: this.props.ticket || '',
        };

        this.swap = this.props.swap.bind(this);

        this.setJointFilesList = this.props.setJointFilesList;
        this.removeItem = this.props.removeItem;

    }

    componentDidMount() {

        if (this.props.content && this.props.content.match(/data:/)) {

            this.props.pushWaitingList({
                params: {
                    key: this.props.keyUpload
                }
            });

            axios.post(
                NGROK + '/v1/add-file-gcloud', {
                    name: this.props.keyUpload,
                    ticket_id: this.state.ticket,
                    auth_id: localStorage.getItem('user_id'),
                    target: this.props.target,
                    data: {
                        base64: this.props.content
                    },
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }
            )
                .then(
                    (status) => {
                        this.props.put({
                            params: {
                                key: this.props.keyUpload,
                                value: status.data.result
                            }
                        });

                        this.props.pullWaitingList({
                            key: this.props.keyUpload
                        });
                        this.setState({ loading: false });

                    },
                    (error) => {
                        this.props.pullWaitingList({
                            key: this.props.keyUpload
                        });
                        this.setState({
                            loading: false,
                            error: true
                        });
                        console.log(error);
                    }
                );
        } else {
            this.setState({ loading: false },);
        }
    }

    componentDidUpdate(prevProps) {
        let obj = {};

        if (JSON.stringify(prevProps.ticket) !== JSON.stringify(this.props.ticket)) {
            obj['ticket'] = this.props.ticket;
        }

        if (JSON.stringify(this.state.value) !== JSON.stringify(this.props.value)) {

            obj['value'] = this.props.value;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    previewAttachment(e) {
        if (this.state.url || this.state.data) {
            return this.setState({
                open: true
            });
        }

        if (this.props.document) {

            let linkSource = this.props.document;
            let bonBagai = linkSource.split(';base64,');
            let mime = bonBagai[0].replace('data:', '').replace(/\\/, '');

            this.setState({
                loading: false,
                data: bonBagai[1],
                mime: mime,
                fileName: this.props.realName,
                open: true
            }, () => {
                debugger
            });
            return;
        }
        let path = this.props.pathGCloud;

        axios.get(
            NGROK + '/v1/get-file-gcloud',
            {
                params: {
                    auth_id: localStorage.getItem('user_id'),
                    path: path,
                    output: 'base64'
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }
        )
            .then(
                (status) => {
                    let linkSource = status.data.result;
                    let bonBagai = linkSource.split(';base64,');
                    let mime = bonBagai[0].replace('data:', '').replace(/\\/, '');

                    this.setState({
                        loading: false,
                        data: bonBagai[1],
                        mime: mime,
                        fileName: this.props.realName,
                        open: true
                    });

                },
                (error) => {
                    this.props.pullWaitingList({
                        key: this.props.keyUpload
                    });
                    this.setState({
                        loading: false,
                        error: true
                    });
                    console.log(error);
                }
            );
    }

    downloadAttachment() {
        let path = this.props.pathGCloud;
        let bucket = (this.props.bucket == undefined) ? '' : this.props.bucket;

        axios.get(
            NGROK + '/v1/get-file-gcloud',
            {
                params: {
                    auth_id: localStorage.getItem('user_id'),
                    path: path,
                    output: 'base64'
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }
        )
            .then(
                (status) => {
                    console.log(status.data);
                    let linkSource = status.data.result;

                    const downloadLink = document.createElement('a');
                    downloadLink.href = linkSource;
                    downloadLink.download = this.props.realName;
                    downloadLink.click();

                    this.setState({
                        loading: false,
                    });

                },
                (error) => {
                    this.props.pullWaitingList({
                        key: this.props.keyUpload
                    });
                    this.setState({
                        loading: false,
                        error: true
                    });
                    console.log(error);
                }
            );

    }

    dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    changeLiasse(e, idFromLiasse, flq, index) {

        this.props.parentCallback({
            idToLiasse: e.currentTarget.value,
            idFromLiasse: idFromLiasse,
            infoMoveDoc: flq,
            index: index
        });
    }

    render() {
        let state = this.state;
        let props = this.props;
        let e = this.props;

        return (
            <div>

                <div class="neoHLMenu" style={{ marginBottom: '2px' }}>
                    <ul>
                        <li class="neoHLli"><Button color={'light'} className="handle"
                                                    style={{ 'color': '#454599' }}>{mouve}</Button></li>
                        <li class="neoHLli" style={{ width: '30%' }}>
                            <CheckInput
                                callback={(e) => {
                                }}
                                name={props.keyBoolean}
                                // name={props.name+'_'+name}
                                title={'P-' + ((parseInt(props.indexPiece) + 1) * 1) + '. ' + ((props.bundleId) ? 'LIASSE' : (props.realName) ? props.realName : 'Autre')}
                                checked={props.checked || false}
                                formData={state}
                                put={this.props.put}
                                style={{
                                    padding: '7px 0 0 5px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                                disabled={(this.props.pathGCloud != undefined && !(['PDF', 'JPG', 'JPEG', 'PNG', 'GIF'].includes((this.props.pathGCloud.split('.').pop()).toUpperCase())) ? true : false)}
                            />
                        </li>

                        <li class="neoHLli" style={{ width: '30%' }}>

                            <TextInput
                                lang={this.state.lang}
                                placeholder={{
                                    fr: 'Nom de la pj',
                                    en: 'Joint file name'
                                }}
                                noFormGroup={true}
                                name={props.keyBase}
                                style={{

                                    // padding:'10px 0 0 0',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                                formData={
                                    props.temp
                                }
                                put={this.props.put}
                            />

                        </li>

                        <li class="neoHLli">

                            <CheckInput

                                callback={(e) => {
                                    if (this.props.setAmend) {
                                        this.props.setAmend(e);
                                    } else {
                                        console.error('setamend not defined');
                                    }
                                }}
                                name={props.keyAmmend}
                                // name={props.name+'_'+name}
                                title={{
                                    fr: 'modif',
                                    en: 'ammend'
                                }}
                                checked={props.ammend === 'true' || false}
                                formData={state}
                                style={{

                                    padding: '7px 0 0 5px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                                put={this.props.put}
                            />
                        </li>

                        <li class="neoHLli">
                            <BeatLoader size={3} margin={1} loading={this.state.loading} color="#0052BB" />
                        </li>
                        <li class="neoHLli" style={{ float: 'right' }}>
                            <Button
                                disabled={this.state.loading}
                                color={'light'}
                                removekey={props.keyRemove}
                                notencoded={props.bundleId || props.name}
                                onClick={(e) => {
                                    this.props.removeItem(e);
                                }}
                            >
                                {trash}
                            </Button>
                        </li>

                        <li class="neoHLli" style={{
                            float: 'right',
                            display: (this.props.pathGCloud) ? 'block' : 'none'
                        }}>
                            <Button
                                disabled={this.state.loading}
                                color={'light'}
                                removekey={props.keyBase}
                                notencoded={e.name}
                                onClick={(e) => this.downloadAttachment(this.state)}
                            >{download}</Button>
                        </li>

                        <li class="neoHLli" style={{
                            float: 'right',
                            display: (this.props.pathGCloud) ? 'block' : 'none'
                        }}>
                            <Button
                                color={'light'}
                                onClick={(e) => this.previewAttachment(this.state)}
                                disabled={(this.props.pathGCloud != undefined && !(['PDF', 'JPG', 'JPEG', 'PNG', 'GIF'].includes((this.props.pathGCloud.split('.').pop()).toUpperCase())) ? true : false)}
                            >
                                {eye}
                            </Button>
                        </li>
                        <li class="neoHLli" style={{
                            float: 'right',
                            display: this.state.error ? BLOCK : NONE
                        }}>
                            {ban}
                        </li>


                    </ul>
                </div>


                {state.open ? <PreviewModal
                    title={{
                        fr: 'Prévisualiser',
                        en: 'Preview'
                    }}
                    url={state.url}
                    mime={state.mime}
                    data={state.data}
                    name={state.fileName}
                    hideControls={true}
                    open={!!(state.url || state.data)}
                    onChange={(open) => {
                        this.setState({
                            open
                        });
                    }}
                /> : ''}
                {props.bundleId ? <Row>
                    <Col md={12}>
                        <ol>
                            {_.map(e.files, (flq, index) => {
                                if (flq) {

                                    return <li class="list-group-item" key={uuid()}>

                                        <Row>

                                            <Col md={6}>
                                                {index + 1} - {flq.name}
                                            </Col>

                                            <Col md={6}>
                                                <Input type={'select'} style={{ display: 'inline-block' }}
                                                       name={'changeDocLiasse'} onChange={(e) => {
                                                    this.changeLiasse(e, props.bundleId, flq, index);
                                                }}>
                                                    <option value={''}></option>
                                                    {this.props.liasseList.map((option) => {

                                                        if (option.bundleId && option.bundleId != this.props.bundleId) {
                                                            return <option
                                                                value={option.bundleId}>{'Déplacer vers -----> [ P-' + (parseInt(option.indexPiece) + 1) + ' ]'}</option>;
                                                        }
                                                    })}
                                                    <option disabled>______________________________________</option>
                                                    <option disabled></option>
                                                    <option style={{ color: 'blue' }} value={'newLiasse'}>Déplacer dans
                                                        une nouvelle liasse
                                                    </option>

                                                </Input>

                                            </Col>

                                        </Row>


                                    </li>;

                                }
                            })}
                        </ol>
                    </Col>
                </Row> : ''}
            </div>
        );
    }
}
