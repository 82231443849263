import React from 'react';
import { Linear } from '@/components/ui/linear';
import { cn } from '@/lib/utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    loading?: boolean;
    children?: React.ReactNode;
}

export function ActionBar(props: Props) {
    return (
        <nav
            className={cn(
                'tw-sticky tw-px-5 tw-py-3 tw-bg-white tw-gap-2',
                'tw-z-10 tw-flex tw-items-center tw-flex-wrap',
                'tw-border-solid tw-border-0 tw-border-b tw-border-black/20',
                props.className
            )}
        >
            {props.children}
            {props.loading &&
                <Linear className="!tw-absolute tw-inset-x-0 tw-bottom-[-4px]" />
            }
        </nav>
    );
}
