import React, { Component } from 'react';

import _ from 'lodash';
import InputMask from 'react-input-mask';
import { Col, FormGroup, Label, } from 'reactstrap';

import { RequestThatField } from './RequestThatField';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const DEFAULT_TYPE = 'text';
const DEFAULT_MASK = '99/99/9999';

const DEFAULT_STYLE = {};

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

export class DateInput extends Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData;

        this.state = {
            value: fd ? fd[this.props.name] : '',
            lang: this.props.lang || FR,
            display: this.props.defaultDisplay === false ? false : true
        };

        if (!props.name) {
            console.error('!!! name is missing !!!');
        }

        if (!props.title) {
            console.error('!!! title is missing !!!');
        }
    }

    componentDidUpdate(prevprops) {
        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            obj['value'] = props.formData[props.name] || props.default;
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            obj['value'] = props.formData[props.name];
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;
        let name = props.name ? props.name : '';

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }
        return (
            <>
                <FormGroup>
                    <InputMask
                        {...this.props.required ? { required: true } : {}}
                        value={this.state.value || ''}
                        type={props.type || DEFAULT_TYPE}
                        name={name}
                        tabIndex={props.tabindex}
                        onChange={(e) => {
                            this.setState({ value: e.target.value });
                        }}
                        mask={props.mask || DEFAULT_MASK}
                        maskChar=" "
                        className="form-control"
                    />
                </FormGroup>
            </>
        );
    }
}
