import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Col, FormGroup, Input, Label, } from 'reactstrap';

import _ from 'lodash';

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;
const QL_CURSOR = /\<span class=["']ql-cursor["']>\s*<\/span>/gm;
const DEFAULT_STYLE = {};

var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    // ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
];

export class ContentEditableTemplate extends React.Component {
    constructor(props) {
        super(props);

        let fd = this.props.formData;

        this.state = {
            value: fd && fd[this.props.name] ? fd[this.props.name] : this.props.options[this.props.default || 0].template || '<p></p>',
            label: this.props.label || '',
            display: this.props.defaultDisplay === false ? false : true
        };

        this.props.put({
            params: {
                key: this.props.name,
                value: this.state.value
            }
        });
        if (!props.name) {
            console.error('!!! name is missing !!!');
        }

        if (!props.title) {
            console.error('!!! title is missing !!!');
        }

        if (!props.put) {
            console.error('put is missing');
        }
    }

    stripHtml(html) {
        let tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    }

    componentDidUpdate(prevprops) {
        let props = this.props,
            state = this.state;
        let obj = {};

        if (props.label !== prevprops.label) {
            obj.label = this.props.label;
        }

        if (props.version !== prevprops.version) {
            obj['value'] = this.genLang(props.formData[props.name] || this.props.options[props.default || 0].template, this.state.lang);

            this.props.put({
                params: {
                    key: this.props.name,
                    value: props.formData[props.name] || this.genLang(this.props.options[props.default || 0].template, obj.lang)
                }
            });
        }

        if (props.formData[props.name] !== prevprops.formData[props.name]) {
            obj['value'] = this.genLang(props.formData[props.name], this.state.lang);

            this.props.put({
                params: {
                    key: this.props.name,
                    value: props.formData[props.name] || this.genLang(this.props.options[props.default || 0].template, obj.lang)
                }
            });
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
            // debugger;
            if (this.stripHtml(state.value) === this.genLang(props.default, props.formData['pa_lang']).replace(/\n+/g, ' ')) {
                obj.value = this.genLang(props.default, obj.lang);

                this.props.put({
                    params: {
                        key: this.props.name,
                        value: props.formData[props.name] || this.genLang(props.default, obj.lang)
                    }
                });
            }
        }

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    renderOptions() {
        if (!this.props.options || !this.props.options.length) {
            return (<option>Aucun choix disponible</option>);
        }

        return (<>
            {_.map(this.props.options, (e) => {
                let f = { ...e };
                f.label = this.genLang(f.label || '', this.state.lang);

                if (f.default && this.state.value !== 0 && !this.state.value) {
                    setTimeout(() => {
                        this.props.put({
                            params: {
                                key: this.props.name,
                                value: f.value
                            }
                        });
                    }, 500);
                }
                return (<option {...f} key={this.props.name + '_' + f.value}>{f.label}</option>);
            })}
        </>);

    }

    render() {

        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        return (
            <>
                <FormGroup>
                    <Label><h5>Gabarit</h5></Label>
                    <Input
                        tabIndex={props.tabindex}
                        value={this.state.template || ''}
                        type={'select'}
                        name={props.name + 'Select'}
                        onChange={(e) => {

                            let obj = {};
                            if (this.state.template != e.target.value) {
                                obj.template = e.target.value;
                                obj.value = _.find(this.props.options, { value: parseInt(e.target.value) }).template;
                            }

                            if (!_.isEmpty(obj)) {
                                this.setState(obj,
                                    () => {

                                        if (props.onChange) {
                                            props.onChange(e.target.value, props.name);
                                        }
                                        this.props.put({
                                            params: {
                                                key: this.props.name,
                                                value: obj.value
                                            }
                                        });
                                    });
                            }
                        }
                        }
                    >
                        {this.renderOptions()}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <ReactQuill
                        readOnly={this.props.disabled}
                        name={props.name}
                        theme="snow"
                        modules={{ 'toolbar': toolbarOptions }}
                        value={this.state.value || this.props.options[this.props.default || 0].template}
                        onChange={() => {
                            let clean = e.replace(QL_CURSOR, '');

                            this.props.put({
                                params: {
                                    key: this.props.name,
                                    value: clean
                                }
                            });
                            this.setState({ value: clean });
                        }}
                    />
                </FormGroup>
            </>
        );
    }
}
