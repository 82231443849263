import { CrudInputType, type CrudSchema, FormInput, type UpdateDialogProps } from '@/components/ui/crud-table';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { Row } from '@/components/ui/row';
import { Button } from '@/components/ui/button';
import { ButtonSubmit } from '@/components/ui/button-submit';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { combine, useTranslation } from '@/composables/translation';
import { type View } from '@/types/api/views';
import { CASE_STATUS_LIST } from '@/types/folder';

export const VIEW_TYPES = [
    { url: '/dashboard', label: combine('settings.views.crud-dialog.type.folders') },
    { url: '/clients', label: combine('settings.views.crud-dialog.type.clients') },
    { url: '/forms', label: combine('settings.views.crud-dialog.type.forms') }
];

const VIEW_FIELDS: Record<string, CrudSchema<View>> = {
    '/dashboard': [
        {
            id: 'definition.params.folder_id',
            type: CrudInputType.TEXT,
            name: combine('settings.views.crud-dialog.fields.filters.folder-id'),
            col: 4
        },
        {
            id: 'definition.params.case_name',
            type: CrudInputType.TEXT,
            name: combine('settings.views.crud-dialog.fields.filters.case-name'),
            col: 4
        },
        {
            id: 'definition.params.case_status',
            type: CrudInputType.SELECT,
            name: combine('settings.views.crud-dialog.fields.filters.case-status'),
            col: 4,
            options: CASE_STATUS_LIST.map(s => ({ value: s, label: s })),
            getOptionValue: (opt) => opt.value,
            getOptionLabel: (opt) => opt.label
        },
        {
            id: 'definition.params.case_created_at_start',
            type: CrudInputType.DATE,
            name: combine('settings.views.crud-dialog.fields.filters.case-created-at-start'),
            col: 6
        },
        {
            id: 'definition.params.case_created_at_end',
            type: CrudInputType.DATE,
            name: combine('settings.views.crud-dialog.fields.filters.case-created-at-end'),
            col: 6
        },
        {
            id: 'definition.params.case_updated_at_start',
            type: CrudInputType.DATE,
            name: combine('settings.views.crud-dialog.fields.filters.case-updated-at-start'),
            col: 6
        },
        {
            id: 'definition.params.case_updated_at_end',
            type: CrudInputType.DATE,
            name: combine('settings.views.crud-dialog.fields.filters.case-updated-at-end'),
            col: 6
        },
        {
            id: 'definition.params.my_folders',
            type: CrudInputType.CHECKBOX,
            name: combine('settings.views.crud-dialog.fields.filters.my-folders'),
            defaultValue: true
        }
    ],
    '/clients': [
        {
            id: 'definition.params.search_value',
            type: CrudInputType.TEXT,
            name: combine('settings.views.crud-dialog.fields.filters.search-value')
        }
    ],
    '/forms': [
        {
            id: 'definition.params.ticket_id',
            type: CrudInputType.TEXT,
            name: combine('settings.views.crud-dialog.fields.filters.folder-id')
        }
    ]
};

interface Props extends UpdateDialogProps<View> {
}

export function UpdateViewDialog(props: Props) {
    const { ct, to, t } = useTranslation('settings.views.crud-dialog');
    const form = useForm<View>({
        defaultValues: props.value
    });
    const isUpdateDialog = props.isUpdateDialog ?? false;
    const url = form.watch('definition.url');
    const dialogFields = VIEW_FIELDS[url] ?? [];
    useEffect(() => {
        form.reset(props.value);
    }, [props.value]);
    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {to(props.translation?.title, isUpdateDialog ? ct('edit') : ct('create'))}
                    </DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={(event) => {
                        event.stopPropagation();
                        form.handleSubmit(props.onSubmit)(event);
                    }}>
                        <Row className="tw-gap-y-3 tw-pb-6">
                            {isUpdateDialog && <FormInput
                                id="active"
                                type={CrudInputType.CHECKBOX}
                                name={t('fields.active')}
                            />}
                            <FormInput
                                id="title"
                                type={CrudInputType.TEXT}
                                col={6}
                                name={t('fields.name')}
                                translate
                            />
                            <FormInput
                                id="definition.url"
                                type={CrudInputType.SELECT}
                                col={6}
                                name={t('fields.type')}
                                options={VIEW_TYPES}
                                getOptionValue={opt => opt.url}
                                getOptionLabel={opt => to(opt.label)}
                            />
                            {dialogFields.length > 0 && <FormInput
                                id="definition"
                                type={CrudInputType.HEADER}
                                name={t('fields.filters.title')}
                            />}
                            {dialogFields.map((options) =>
                                <FormInput
                                    key={options.id}
                                    {...options}
                                />
                            )}
                        </Row>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button type="button" variant="secondary">
                                    {to(props.translation?.cancel, ct('cancel'))}
                                </Button>
                            </DialogClose>
                            <ButtonSubmit>
                                {to(props.translation?.submit, isUpdateDialog ? ct('edit') : ct('create'))}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
