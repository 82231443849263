import React, { Component } from 'react';

import { Col, FormGroup, Input, Label, } from 'reactstrap';

import { RequestThatField } from './RequestThatField';

import _ from 'lodash';

const DEFAULT_TITLE = '';
const DEFAULT_SIZE = 12;

const FR = 'fr';
const STRING = 'string';
const OBJECT = 'object';
const DEFAULT_STYLE = {};
const CURRENT_LAWYER = 'CURRENT_LAWYER';

export class SelectBootstrapAsync extends Component {

    constructor(props) {
        super(props);

        let fd = this.props.formData;

        let def = '';

        if (this.props.default === CURRENT_LAWYER && !this.props.alwaysNew) {
            def = fd[this.props.name] || localStorage.getItem('user_id');
        } else if (this.props.default === CURRENT_LAWYER && this.props.alwaysNew) {
            def = localStorage.getItem('user_id');
        } else if (this.props.alwaysNew) {
            def = '';
        } else {
            def = fd[this.props.name] || this.props.default;
        }

        this.state = {
            options: props.fetchedCollection || this.props.options || [],
            value: def,
            lang: this.props.lang || FR
        };

        this.state.display = this.props.defaultDisplay === false ? false : true;

        if (!props.name) {
            console.error('!!! name is missing !!!');
        }

        if (!props.title) {
            console.error('!!! title is missing !!!');
        }

        if (!props.options) {
            console.error('!!! no options for a select O_o?? !!!');
        }

        this.renderOptions = this.renderOptions.bind(this);
    }

    renderOptions() {
        if (!this.props.options || !this.props.options.length || !this.props.fetchedCollection || !this.props.fetchedCollection.length) {
            return (<option>Aucun choix disponible</option>);
        }

        let current = this.props.fetchedCollection || this.state.options || [];
        let dft = this.props.default;

        return (<>
            {_.map(current, (e) => {
                let f = { ...e };
                f.label = this.genLang(f.label || '', this.state.lang);

                if ((dft === f.value || f.default) && !this.state.value) {
                    setTimeout(() => this.props.put({
                        params: {
                            key: this.props.name,
                            value: f.value
                        }
                    }), 500);
                }
                return (<option {...f} key={this.props.name + '_' + f.value}>{f.label}</option>);
            })}
        </>);

    }

    componentDidUpdate(prevprops) {

        let props = this.props;
        let obj = {};

        if (props.version !== prevprops.version) {
            if (props.default === CURRENT_LAWYER) {
                props.put({
                    params: {
                        key: props.name,
                        value: localStorage.getItem('user_id')
                    }
                });
                obj['value'] = localStorage.getItem('user_id') || props.formData[props.name];
            } else {
                props.put({
                    params: {
                        key: props.name,
                        value: props.default
                    }
                });
                obj['value'] = props.formData[props.name] || props.default;
            }
        }

        if (props.formData[props.name] !== prevprops.formData[props.name] && props.default !== CURRENT_LAWYER) {
            obj['value'] = props.formData[props.name];

            props.put({
                params: {
                    key: props.name,
                    value: props.formData[props.name] || props.default
                }
            });

        }

        if (props.fetchedCollection !== prevprops.fetchedCollection) {
            obj['options'] = props.fetchedCollection;
        }

        if (props.lang !== prevprops.lang) {
            obj['lang'] = props.lang;
        }

        if (props.display === false && prevprops.display !== false) {
            obj.display = false;
        } else if (props.display === true && prevprops.display !== true) {
            obj.display = true;
        }

        this.state.value = (props.formData.sign_lawyer !== undefined && props.formData.sign_lawyer) ? props.formData.sign_lawyer : localStorage.getItem('user_id');

        if (!_.isEmpty(obj)) {
            this.setState(obj);
        }
    }

    genLang(text, lang = FR) {

        if (typeof text === STRING) {
            return text;
        }

        if (typeof text === OBJECT) {
            if (lang && text[lang]) {
                return text[lang];
            } else {
                return '';
            }
        }

        return '';
    }

    render() {
        let props = this.props;

        let style = {};

        if (props.style && props.style.override) {
            style = { ...props.style };
        } else if (props.style) {
            style = { ...DEFAULT_STYLE, ...props.style };
        } else {
            style = { ...DEFAULT_STYLE };
        }

        if (this.state.display === false) {
            style.display = 'none';
        } else {
            delete style.display;
        }

        let request;

        if (this.props.requestFields === false) {
            if (this.props.requestable === true) {
                request = true;
            } else {
                request = false;
            }
        } else {
            if (this.props.requestable === false) {
                request = false;
            } else {
                request = true;
            }
        }

        return (
            <>
                <FormGroup>
                    <Input
                        disabled={this.props.disabled}
                        tabIndex={props.tabindex}
                        value={this.state.value || ''}
                        type={'select'}
                        name={props.name}
                        onChange={(e) => {
                            e.persist();
                            this.setState({ value: e.target.value },
                                () => props.put({
                                    params: {
                                        key: props.name,
                                        value: e.target.value
                                    }
                                }));
                        }}>
                        {this.renderOptions()}
                    </Input>
                </FormGroup>
            </>
        );
    }

}
